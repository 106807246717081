import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuRingil = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={59} height={24} fill="none" {...props}>
    <path fill="#FE5000" d="M59 0H0v24h59z" />
    <path
      fill="#fff"
      d="M25.918 11.31h-.095L21.82 3.54h-3.527v11.892h2.669V7.377h.19l4.099 8.056h3.3360000000000003V3.541h-2.67zM49.268 3.541H46.6v11.892h8.006V12.94h-5.338zM16.77 3.541h-2.86v11.892h2.86zM45.076 3.541h-2.86v11.892h2.86zM40.882 7.57s-.286-4.412-5.338-4.412c-5.051 0-5.813 3.549-5.813 5.946 0 2.398.762 6.618 4.956 6.618 2.096 0 3.05-.671 3.43-1.343v1.055h2.67V8.913h-5.243v2.11h2.574v1.726H32.78V6.227h5.052V7.57zM12.67 7.57c0-2.878-1.335-4.029-3.527-4.029H3.14v11.892h2.668v-4.22l3.527 4.22h3.336l-3.431-4.22c.571-.095 3.43-.479 3.43-3.644M9.81 8.815H5.807V5.939H9.81z"
    />
  </svg>
);
export default SvgMenuRingil;
