import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';
import { mapFromAPIToDateLong } from '@utils/date';

interface ComponentProps {
  fullName: string;
  sentAt: string;
}

const WeightBadge: React.FC<ComponentProps> = ({ fullName, sentAt }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(fullName) && (
        <Badge filled={false} value={`${fullName}, ${mapFromAPIToDateLong(sentAt)}`} highlightedValue={false} label={tr(`SentBadge.sent`, `Sent`)}></Badge>
      )}
    </>
  );
};

export default WeightBadge;
