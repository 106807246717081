import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHeadingIconBus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={27} fill="none" {...props}>
    <path
      fill="#B2B2B2"
      fillRule="evenodd"
      d="M3.25 5.375A4.875 4.875 0 0 1 8.125.5h9.75a4.875 4.875 0 0 1 4.875 4.875v3.25H26v8.125h-3.25v9.75H19.5v-3.25h-13v3.25H3.25v-9.75H0V8.625h3.25zm3.25 0h13V13.5h-13zM8.125 20a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25M19.5 18.375a1.625 1.625 0 1 1-3.25 0 1.625 1.625 0 0 1 3.25 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHeadingIconBus;
