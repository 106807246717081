import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import Label from '@components/obsolete//Form/Label/Label';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useContext, useEffect } from 'react';
import { UserCompanyWebAccessEmailInDTO } from '@api/logsteo-api.v2.tsx';
import { useImmer } from 'use-immer';

import * as yup from 'yup';
import useValidation from '../../../hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '@api/api.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: () => void;
  userId: string;
}

export const AddWebAccessSidebar: React.FC<ComponentProps> = ({ visible, onHide, onComplete, userId }) => {
  const { t } = useTranslation('common');

  const { addWebAccessToVirtualUser } = useContext(ApiContext);
  const [state, setState] = useImmer<UserCompanyWebAccessEmailInDTO>({
    email: '',
  });

  useEffect(() => {
    if (visible) {
      clear();
    }
  }, [visible]);

  const save = () => {
    addWebAccessToVirtualUser(userId, state, () => {
      onComplete();
    });
  };

  const validationScheme = yup.object().shape({
    email: yup.string().email().required(),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(validationScheme, () => {
    save();
  });

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} onHide={onHide} position="right" baseZIndex={1000000} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        <h3 className="p-mb-4">{t('AddWebAccessSidebar.title', 'Přidat aplikační přístup')}</h3>
        {state && (
          <>
            <div className="p-field p-fluid">
              <Label title={`${t('EditUserSidebar.email', 'Email')}`} />
              <InputText
                type="text"
                value={state.email}
                name="email"
                onChange={e => {
                  const { name, value } = e.target as HTMLInputElement;
                  setState(draft => {
                    draft.email = value;
                  });
                }}
              />
              <ValidationDiv errors={validationErrors} path={'email'} />
            </div>
            <ButtonLayout>
              <Button label={t(`AddWebAccessSidebar.save`, `Uložit`)} className="p-mr-2" onClick={() => validateAndSend(state)} />
              <Button label={t(`EditUserSidebar.cancel`, `Zrušit`)} className="p-button-text" onClick={onHide} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};
