import React from 'react';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2.tsx';
import CFRApplicationId from '@app/pages/timeslots/fragments/CFRApplicationId.tsx';
import CFRName from '@app/pages/timeslots/fragments/CFRName.tsx';
import CFRCarrierInfo from '@app/pages/timeslots/fragments/CFRCarrierInfo.tsx';
import CFRSlotDuration from '@app/pages/timeslots/fragments/CFRSlotDuration.tsx';
import CFRCargoItem from '@app/pages/timeslots/fragments/CFRCargoItem.tsx';
import CFRLabels from '@app/pages/timeslots/fragments/CFRLabels.tsx';
import CFRCreated from '@app/pages/timeslots/fragments/CFRCreated.tsx';
import CFRUpdated from '@app/pages/timeslots/fragments/CFRUpdated.tsx';
import CFRWorkflowIcon from '@app/pages/timeslots/fragments/CFRWorkflowIcon.tsx';

interface ComponentProps {
  fragment: string;
  reservation: ReservationsDataOutDTO;
}

const RenderFragment: React.FC<ComponentProps> = ({ fragment, reservation }) => {
  // render all possible ['CFRWorkflowIcon', 'CFRName', 'CFRCarrierInfo', 'CFRSlotDuration', 'CFRCargoItem', 'CFRLabels', 'CFRCreated', 'CFRUpdated'];

  if (fragment === 'CFRApplicationId') return <CFRApplicationId reservation={reservation} />;
  if (fragment === 'CFRWorkflowIcon') return <CFRWorkflowIcon reservation={reservation} />;
  if (fragment === 'CFRName') return <CFRName reservation={reservation} />;
  if (fragment === 'CFRCarrierInfo') return <CFRCarrierInfo reservation={reservation} />;
  if (fragment === 'CFRSlotDuration') return <CFRSlotDuration reservation={reservation} />;
  if (fragment === 'CFRCargoItem') return <CFRCargoItem reservation={reservation} />;
  if (fragment === 'CFRLabels') return <CFRLabels reservation={reservation} />;
  if (fragment === 'CFRCreated') return <CFRCreated reservation={reservation} />;
  if (fragment === 'CFRUpdated') return <CFRUpdated reservation={reservation} />;

  return <b>Not known fragment</b>;
};

export default RenderFragment;
