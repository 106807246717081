import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Layout from '@app/layout/Layout.tsx';
import React from 'react';
import LocalityDetail from '@app/pages/customer/LocalityDetail/LocalityDetail.tsx';

const LocationDetailPage = () => {
  const { t } = useTranslation('common');
  const { id } = useParams();

  console.log(`Rendering detail: ${id}`);

  return <>{id && <Layout title={'Detail lokace'}>{<LocalityDetail localityId={id as string} />}</Layout>}</>;
};

export default withAuthenticationRequired(LocationDetailPage);
