import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Calendar } from 'primereact/calendar';
import { shortDateFormat } from '@utils/date.tsx';
import dayjs from 'dayjs';
import { isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  value: string;
  onChange: (localDate: string) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
}

const RingilCalendar: React.FC<ComponentProps> = ({ value, onChange, disabled = false, fullWidth = false }) => {
  const { tr } = useTranslationLgs();

  const convert = () => {
    if (isNullOrUndefined(value)) return null;
    return dayjs(value).toDate();
  };

  const convertToUI = (value: Date | Date[]) => {
    return dayjs(value as Date).format('YYYY-MM-DD');
  };

  const style = fullWidth ? { width: '100%' } : {};

  return (
    <>
      <Calendar
        locale={'cs'}
        value={convert()}
        showIcon={true}
        dateFormat={shortDateFormat}
        onChange={e => onChange(convertToUI(e.target.value))}
        disabled={disabled}
        style={style}
        showButtonBar={true}
        onClearButtonClick={() => onChange(null)}
      />
    </>
  );
};

export default RingilCalendar;
