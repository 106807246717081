import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { formatPrice } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { DefaultFormPanel } from '@components/obsolete/DefaultFormPanel/DefaultFormPanel.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import RejectDialogComponent from '@app/pages/carrier/demand-detail/RejectDialog/RejectDialogComponent.tsx';

interface ComponentProps {
  price: number;
  currency: string;
  onAccept: () => void;
  onReject: () => void;
  enableActions: boolean;
  loading: boolean;
}

const AcceptOrRejectComponent: React.FC<ComponentProps> = ({ price, currency, onReject, onAccept, enableActions, loading }) => {
  const { t } = useTranslation('common');
  const [checked, setChecked] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  return (
    <>
      <div className="p-col-12 p-lg-6">
        <DefaultFormPanel>
          <h3 style={{ fontWeight: 'normal' }} className="p-mt-0 p-mb-4">
            {t(`FixedPriceAcceptReject.requestedPrice`, `Requested price`)}
          </h3>

          <div className="text-lg p-mb-4 requested-price">
            <strong>{formatPrice(price, currency)}</strong>
          </div>

          <div className="p-fluid p-field-checkbox p-mb-4">
            <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
            <label htmlFor="binary">
              <small>
                {t(
                  `FixedPriceAcceptReject.weConfirmThatWeAreAbleToFulfillRequiredTimeSlotsForThisShipment`,
                  `We confirm that we are able to fulfill required time slots for this shipment`,
                )}
              </small>
            </label>
          </div>
          <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
            <Button
              label={t(`FixedPriceAcceptReject.acceptShipment`, `Accept shipment`)}
              className="p-mr-3"
              onClick={() => onAccept()}
              disabled={!checked || !enableActions}
              loading={loading}
            />
            <Button
              label={t(`FixedPriceAcceptReject.reject`, `Reject`)}
              className="p-button-outlined"
              icon="pi pi-times"
              onClick={() => setConfirmVisible(true)}
              disabled={!enableActions}
            />
          </ButtonLayout>
        </DefaultFormPanel>
      </div>
      <RejectDialogComponent visible={confirmVisible} onHide={() => setConfirmVisible(false)} onReject={() => onReject()} disabled={!enableActions} />
    </>
  );
};

export default AcceptOrRejectComponent;
