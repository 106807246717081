import React, { useId } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { deepEqual, isNullOrUndefined } from '@utils/utils';
import styled from 'styled-components';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  options: any[];
  value: any;
  optionValue?: string;
  optionLabel: string;
  onChange: (newValue: any) => void;
}

const RadioButtons: React.FC<ComponentProps> = ({ options, optionValue, optionLabel, value, onChange }) => {
  const name = useId().replace(/:/g, '');

  const resolveItemValue = (item: any, optionValue: string) => {
    if (isNullOrUndefined(optionValue)) return item;
    return item[optionValue];
  };

  return (
    <RadioButtonWrapper>
      {options?.map((item, key) => {
        return (
          <Row key={key}>
            <RadioButton
              name={`${name}${key}`}
              checked={deepEqual(value, resolveItemValue(item, optionValue))}
              onChange={e => onChange(resolveItemValue(item, optionValue))}
            />
            <Label title={item[optionLabel]}></Label>
          </Row>
        );
      })}
    </RadioButtonWrapper>
  );
};

const RadioButtonWrapper = styled.div`
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;

const Row = styled.div`
  display: flex;
  label {
    margin-left: 0.5rem;
  }
`;

export default RadioButtons;
