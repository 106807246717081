import React, { useContext, useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { validate } from '@utils/validation';
import { isNullOrUndefined } from '@utils/utils';
import useTranslation from '@hooks/i18n/useTranslation.tsx';

const useValidation = (validationScheme: any, onSuccessMethod: () => void) => {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const { tr } = useTranslation();
  const validateAndSend = (validatingData: any) => {
    const validationErrors = validate(validationScheme, validatingData) || [];
    setValidationErrors(validationErrors);
    if (validationErrors.length === 0) onSuccessMethod();
  };

  const find = (path: string) => {
    const error = validationErrors.find(e => e.path === path);
    if (isNullOrUndefined(error)) return undefined;
    return tr(`validation.${error.type}`);
  };

  const clear = () => {
    setValidationErrors([]);
  };

  return { validationErrors, validateAndSend, clear, find };
};

export default useValidation;
