import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { Card } from 'primereact/card';
import { DetailHTTPRequestResponse } from '@api/logsteo-api.v2';
import { InputTextarea } from 'primereact/inputtextarea';
import { RingilHeading } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  requestId: string;
}

const HTTPDetail: React.FC<ComponentProps> = ({ requestId }) => {
  const { adminGetHTTPRequest } = useContext(ApiContext);
  const [data, setData] = useState<DetailHTTPRequestResponse>();

  useEffect(() => {
    adminGetHTTPRequest(requestId, d => {
      setData(d);
    });
  }, [requestId]);

  if (!data) return <></>;

  return (
    <>
      {/*// @ts-ignore*/}
      <Card>
        <RingilHeading>HTTP Request detail</RingilHeading>
        <Row>
          <HorizontalFieldValue label={'Request ID'} value={data.id} />
          <HorizontalFieldValue label={'Request UUID'} value={data.trcUUID} />
          <HorizontalFieldValue label={'Date'} value={data.trcRequestDate} />
          <HorizontalFieldValue label={'Duration'} value={data.durationInMS} />
          <HorizontalFieldValue label={'Status'} value={data.status} />
          <HorizontalFieldValue label={'Method'} value={data.httpMethod} />
        </Row>
        <Row>
          <Col>
            <HorizontalFieldValue label={'URI'} value={data.uri} />
          </Col>
        </Row>

        <Row>
          <InputTextarea value={data.request} style={{ width: '100%' }} rows={15} />
          <InputTextarea value={data.response} style={{ width: '100%' }} rows={15} />
        </Row>
        <Row>
          <InputTextarea value={data.exception} style={{ width: '100%' }} rows={15} />
        </Row>
      </Card>
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
`;

export default HTTPDetail;
