import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation';
import SvgTagPlus from '@components/ringil3/icons/TagPlus';
import { InputTextarea } from 'primereact/inputtextarea';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';

interface ComponentProps {
  note?: string;
  onChangeNote?: (note: string) => void;
  label?: string;
  formated?: boolean;
}

const NoteTag: React.FC<ComponentProps> = ({ note, onChangeNote, label, formated = false }) => {
  const { tr } = useTranslationLgs();
  const [internalNote, setInternalNote] = useState(note);
  const [open, setOpen] = useState(false);

  const actionPane = () => {
    return (
      <>
        <InputTextarea value={internalNote ? internalNote : ''} onChange={e => setInternalNote(e.target.value)} rows={6} style={{ width: '400px' }} />
      </>
    );
  };
  return (
    <>
      {isNotNullOrUndefined(note) && (
        <ButtonTag
          label={label ? label : tr(`NoteTag.title`, `Note`)}
          labelNote={formated ? <pre>{note}</pre> : note}
          icon={<SvgYellowExclamation />}
          actionIcon={<SvgTagEdit />}
          actionPane={actionPane()}
          onSave={() => {
            setOpen(false);
            onChangeNote(internalNote);
          }}
          setOpen={setOpen}
          open={open}
        />
      )}
      {isNullOrUndefined(note) && (
        <ButtonTag
          variant={'empty'}
          label={label ? label : tr(`NoteTag.title`, `Note`)}
          labelNote={note}
          actionIcon={<SvgTagPlus />}
          actionPane={actionPane()}
          onSave={() => {
            setOpen(false);
            onChangeNote(internalNote);
          }}
          setOpen={setOpen}
          open={open}
        />
      )}
    </>
  );
};

export default NoteTag;
