import { OrderViewData, PageID, RouteType } from '../new-expedition/types';
import {
  DistributionTypeEnum,
  ExpeditionTypeEnum,
  FindCarrierCarrierOrGroupDTO,
  LoadingTypesEnum,
  OpeningHourDefinitionsDTO,
  PartnerLocationContactPersonInDTO,
  UserLovOutDTO,
} from '@api/logsteo-api.v2';
import { ValidationError } from 'yup';

export interface CreateTemplateHandlerView {
  currentPage: PageID;
  templateName?: string;
  routeType: RouteType;
  locations?: Location[];
  expeditionType?: ExpeditionTypeEnum;
  truckType?: string;
  manipulationTypes?: LoadingTypesEnum[];
  specialRequirements?: string[];
  cargoType?: string;
  carrierNote?: string;
  unloadAllLocationId?: string;
  distribution?: DistributionView;
  distributionTemplate?: DistributionView;
  responsiblePerson?: UserLovOutDTO;
  subscribers?: UserLovOutDTO[];
  internalNote?: string;
  validationErrors?: ValidationError[];
}

export interface DistributionView {
  enabled: boolean;
  distributionType?: DistributionTypeEnum;
  price?: number;
  selectedItems?: FindCarrierCarrierOrGroupDTO[];
  intervalHour?: number;
  currency?: string;
  isPublished: boolean;
}

export interface Location {
  // index
  id: number;
  fromAddressBookId: string;
  name?: string;
  streetNr?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  carrierCode?: string;
  loadingLocation: boolean;
  unloadingLocation: boolean;
  unloadAll: boolean;
  loadingOrders: OrderViewData[];
  timeslots?: IntervalDefinitionView[];
  enabledEmailNotificationForContactPerson: boolean;
  additionalNotificationPersons?: PartnerLocationContactPersonInDTO[];
  openingHourDefinitions?: OpeningHourDefinitionsDTO;
}

interface FromTo {
  sinceTimeUtc: string;
  tillTimeUtc: string;
}

interface IntervalDefinitionView {
  dayOfWeek: string;
  intervals: FromTo[];
}

export const createEmptyLocation = (locationIndex: number, loadingLocation: boolean = false, unloadingLocation: boolean = false): Location => {
  return {
    id: locationIndex,
    name: null,
    fromAddressBookId: null,
    loadingLocation,
    unloadingLocation,
    loadingOrders: [],
    unloadAll: false,
    enabledEmailNotificationForContactPerson: true,
  };
};
