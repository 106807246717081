import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  code: string;
  onChange: (code: string) => void;
  style?: any;
  disabled?: boolean;
}

const InputIncoterm: React.FC<ComponentProps> = ({ onChange, code, style, disabled = false }) => {
  const { tr } = useTranslationLgs();
  const model: LovWithoutIdDTO[] = [
    { name: tr('InputIncoterm.exw', 'EXW (Ex Works)'), code: 'EXW' },
    { name: tr('InputIncoterm.fca', 'FCA (Free Carrier)'), code: 'FCA' },
    { name: tr('InputIncoterm.cpt', 'CPT (Carriage Paid To)'), code: 'CPT' },
    {
      name: tr('InputIncoterm.cip', 'CIP (Carriage and Insurance Paid to)'),
      code: 'CIP',
    },
    { name: tr('InputIncoterm.dap', 'DAP (Delivered At Place)'), code: 'DAP' },
    {
      name: tr('InputIncoterm.dpu', 'DPU (Delivered At Place Unloaded)'),
      code: 'DPU',
    },
    { name: tr('InputIncoterm.ddp', 'DDP (Delivery Duty Paid)'), code: 'DDP' },
    { name: tr('InputIncoterm.fas', 'FAS (Free Alongside Ship)'), code: 'FAS' },
    { name: tr('InputIncoterm.fob', 'FOB (Free On Board)'), code: 'FOB' },
    { name: tr('InputIncoterm.cfr', 'CFR (Cost and Freight)'), code: 'CFR' },
    {
      name: tr('InputIncoterm.cif', 'CIF (Cost, Insurance and Freight)'),
      code: 'CIF',
    },
  ];
  return (
    <>
      <Dropdown options={model} optionValue={'code'} optionLabel={'name'} value={code} onChange={e => onChange(e.value)} style={style} disabled={disabled} />
    </>
  );
};

export default InputIncoterm;
