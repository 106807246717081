import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  onChange: (value: number) => void;
  value: number;
  disabled?: boolean;
}

const model = [
  { label: 'Petea (HU)', value: 1 },
  { label: 'Borș(HU)', value: 2 },
  { label: 'Vărșand(HU)', value: 3 },
  { label: 'Nădlac(HU)', value: 4 },
  { label: 'Calafat (BG)', value: 5 },
  { label: 'Bechet(BG)', value: 6 },
  { label: 'Turnu Măgurele(BG)', value: 7 },
  { label: 'Zimnicea(BG)', value: 8 },
  { label: 'Giurgiu(BG)', value: 9 },
  { label: 'Ostrov(BG)', value: 10 },
  { label: 'Negru Vodă(BG)', value: 11 },
  { label: 'Vama Veche(BG)', value: 12 },
  { label: 'Călărași(BG)', value: 13 },
  { label: 'Corabia(BG)', value: 14 },
  { label: 'Oltenița(BG)', value: 15 },
  { label: 'Carei  (HU)', value: 16 },
  { label: 'Cenad (HU)', value: 17 },
  { label: 'Episcopia Bihor (HU)', value: 18 },
  { label: 'Salonta (HU)', value: 19 },
  { label: 'Săcuieni (HU)', value: 20 },
  { label: 'Turnu (HU)', value: 21 },
  { label: 'Urziceni (HU)', value: 22 },
  { label: 'Valea lui Mihai (HU)', value: 23 },
  { label: 'Vladimirescu (HU)', value: 24 },
  { label: 'Porțile de Fier 1 (RS)', value: 25 },
  { label: 'Naidăș(RS)', value: 26 },
  { label: 'Stamora Moravița(RS)', value: 27 },
  { label: 'Jimbolia(RS)', value: 28 },
  { label: 'Halmeu (UA)', value: 29 },
  { label: 'Stânca Costești (MD)', value: 30 },
  { label: 'Sculeni(MD)', value: 31 },
  { label: 'Albița(MD)', value: 32 },
  { label: 'Oancea(MD)', value: 33 },
  { label: 'Galați Giurgiulești(MD)', value: 34 },
  { label: 'Constanța Sud Agigea', value: 35 },
  { label: 'Siret  (UA)', value: 36 },
  { label: 'Nădlac 2 - A1 (HU)', value: 37 },
  { label: 'Borș 2 - A3 (HU)', value: 38 },
];

export const eTransportBorderCrossingItemLabel = (value: number) => {
  return model.find(item => item.value === value)?.label;
};

const EtransportBorderCrossingDropdown: React.FC<ComponentProps> = ({ value, onChange, disabled = false }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <Dropdown
        options={model}
        onChange={e => onChange(e.target.value)}
        value={value}
        optionLabel={'label'}
        optionValue={'value'}
        style={{ width: '100%' }}
        disabled={disabled}
      />
    </>
  );
};

export default EtransportBorderCrossingDropdown;
