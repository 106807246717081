import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  reservation: ReservationsDataOutDTO;
}

const CFRCarrierInfo: React.FC<ComponentProps> = ({ reservation }) => {
  const { tr } = useTranslationLgs();

  const printCarrierInfo = (reservation: ReservationsDataOutDTO) => {
    return [reservation.carrierName, reservation.carPlate, reservation.driverInfo.driverName].filter(t => isNotNullOrUndefined(t)).join(' - ');
  };

  return (
    <>
      <CalendarItemRow>{printCarrierInfo(reservation)}</CalendarItemRow>
    </>
  );
};

const CalendarItemRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export default CFRCarrierInfo;
