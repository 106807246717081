// @ts-nocheck
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  pdfData: string;
  onHide: () => void;
}

const PDFViewerDialog: React.FC<ComponentProps> = ({ pdfData, onHide }) => {
  return (
    <>
      <Dialog onHide={onHide} style={{ width: '60%', height: '100%' }} visible={isNotNullOrUndefined(pdfData)}>
        <embed src={pdfData} height={'100%'} width={'100%'}></embed>
      </Dialog>
    </>
  );
};

export default PDFViewerDialog;
