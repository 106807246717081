import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import AllMessagesPanel from './panels/AllMessagesPanel';
import NewMessagePanel from './panels/NewMessagePanel';
import ThreadPanel from './panels/ThreadPanel';
import { isNullOrUndefined } from '@utils/utils';
import { EntityTypeEnum } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  visible: boolean;
  selectedCarrierId: string;
  onHide: () => void;
  companyId: string;
  entityId: string;
  entityType: EntityTypeEnum;
  onAddedMessage?: () => void;
  readOnly?: boolean;
}

export enum ChatPane {
  ALL_MESSAGES,
  NEW_MESAGE,
  SELECTED_THREAD,
}

interface ChatState {
  mode: ChatPane;
  selectedCarrier?: string;
}

const CustomerChatComponent: React.FC<ComponentProps> = ({ visible, onHide, companyId, entityId, entityType, selectedCarrierId, readOnly = false }) => {
  const { t } = useTranslation('common');
  const [chatState, setChatState] = useState<ChatState>({
    mode: ChatPane.ALL_MESSAGES,
    selectedCarrier: null,
  });

  const switchToCarrierMessageThread = (carrierId: string) => {
    setChatState({
      mode: ChatPane.SELECTED_THREAD,
      selectedCarrier: carrierId,
    });
  };

  const switchToNewMessage = () => {
    setChatState({ mode: ChatPane.NEW_MESAGE, selectedCarrier: null });
  };

  const switchToAllMessagesPanel = () => {
    setChatState({ mode: ChatPane.ALL_MESSAGES, selectedCarrier: null });
  };

  useEffect(() => {
    if (!isNullOrUndefined(selectedCarrierId)) {
      setChatState({
        mode: ChatPane.SELECTED_THREAD,
        selectedCarrier: selectedCarrierId,
      });
    } else {
      setChatState({ mode: ChatPane.ALL_MESSAGES, selectedCarrier: null });
    }
  }, [selectedCarrierId]);

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        style={{ width: '750px' }}
        onHide={() => {
          setChatState({ mode: ChatPane.ALL_MESSAGES, selectedCarrier: null });
          onHide();
        }}
        blockScroll={true}
        className="sidebar-modal sidebar-modal-lg sidebar-move-header sidebar-modal-chat-customer">
        {chatState?.mode === ChatPane.ALL_MESSAGES && (
          <AllMessagesPanel
            companyId={companyId}
            entityId={entityId}
            entityType={entityType}
            switchToCarrierMessageThread={switchToCarrierMessageThread}
            switchToNewMessage={switchToNewMessage}
            readOnly={readOnly}
          />
        )}

        {chatState?.mode === ChatPane.NEW_MESAGE && (
          <NewMessagePanel companyId={companyId} entityId={entityId} entityType={entityType} switchToAllMessagesPanel={switchToAllMessagesPanel} readOnly={readOnly} />
        )}

        {chatState?.mode === ChatPane.SELECTED_THREAD && (
          <ThreadPanel
            companyId={companyId}
            entityId={entityId}
            entityType={entityType}
            switchToAllMessagesPanel={switchToAllMessagesPanel}
            carrierId={chatState.selectedCarrier}
            readOnly={readOnly}
          />
        )}
      </Sidebar>
    </>
  );
};

export default CustomerChatComponent;
