import { InputSwitch } from 'primereact/inputswitch';
import React, { useContext, useId } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { CreateTemplateHandlerContext } from '../CreateTemplateHandler';
import { OrderViewData } from '@components/obsolete/new-expedition/types.tsx';
import { useTranslation } from 'react-i18next';
import { Panel } from 'primereact/panel';
import PanelCard from '@components/obsolete/PanelCard/PanelCard.tsx';

interface ComponentProps {
  unloadAll: boolean;
  ltlOrders: OrderViewData[];
  collapsed: boolean;
  locationId: number;
  isLastLocation: boolean;
}

export const UnloadOrderComponent: React.FC<ComponentProps> = ({ collapsed, unloadAll, ltlOrders, locationId, isLastLocation }) => {
  const { t } = useTranslation('common');
  const { unloadOrder, toggleUnloading, toggleUnloadAll } = useContext(CreateTemplateHandlerContext);

  const uniqId = useId().replace(/:/g, '');

  return (
    <>
      {/*// @ts-ignore*/}
      <Panel className="p-mt-5">
        <PanelCard>
          <div className="p-sm-6 p-d-flex p-ai-center">
            <InputSwitch checked={collapsed} onChange={e => toggleUnloading(locationId)} disabled={isLastLocation} />
            <label htmlFor="binary-2" className="p-ml-2">
              {t(`UnloadOrderComponent.dropoff`, `Dropoff`)}
              <small className="p-pt-1 p-d-block">{t(`loadDetailsPage.panelPickUp.${collapsed ? 'whatToUnload' : 'description'}`)}</small>
            </label>
          </div>
          <div className="p-sm-6 p-d-flex p-jc-end">
            <img src="/images/icon_dropoff.png" alt="" style={{ width: '60px' }} />
          </div>
          {collapsed && (
            <div className="p-col-12">
              <div className="p-field-checkbox">
                <Checkbox
                  inputId="unloadAll"
                  checked={unloadAll}
                  onChange={() => {
                    toggleUnloadAll(locationId, !unloadAll);
                  }}
                />
                <label htmlFor="unloadAll">{t(`UnloadOrderComponent.allOrders`, `All orders`)}</label>
              </div>
              <div>
                {ltlOrders.map((order, index) => {
                  return (
                    <div className="p-field-checkbox p-ml-5" key={index}>
                      <Checkbox
                        inputId={`${uniqId}${index}`}
                        checked={order.unloadingLocationId === locationId}
                        onChange={e => unloadOrder(order.internalId, locationId, e.target.checked)}
                      />
                      <label htmlFor={`${uniqId}${index}`}>{order.name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PanelCard>
      </Panel>
    </>
  );
};
