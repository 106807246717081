import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { Button } from 'primereact/button';
import { CarrierGroupListOutDTO, PageResponseCarrierGroupListOutDTO } from '@api/logsteo-api.v2';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import { Dialog } from 'primereact/dialog';
import CreateOrUpdateCarrierGroup from './sidebars/CreateOrUpdateCarrierGroup';
import { useDebounce } from 'use-debounce';
import { InputText } from 'primereact/inputtext';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import useNavigation from '@hooks/useNavigation.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { Link } from 'react-router-dom';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';

interface FilterType {
  q?: string;
}

const AllCarrierGroups = () => {
  const { t } = useTranslation('common');
  const { cuListCarrierGroups, cuDeleteCarrierGroup } = useContext(ApiContext);
  const [carrierGroupsData, setCarrierGroupsData] = useState<PageResponseCarrierGroupListOutDTO>(undefined);
  const [deleteCarrierGroupId, setDeleteCarrierGroupId] = useState(undefined);
  const [visibleCreateGroup, setVisibleCreateGroup] = useState(false);

  const navigation = useNavigation();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<FilterType>((page, filter) => {
    load(page.pageSize, page.pageNumber, filter.q);
  });

  const load = (pageSize: number, pageNumber: number, q = '') => {
    setLoading(true);
    cuListCarrierGroups({ pageSize, pageNumber: pageNumber }, q, data => {
      setCarrierGroupsData(data);
      setLoading(false);
    });
  };

  const deleteGroup = () => {
    cuDeleteCarrierGroup(deleteCarrierGroupId, () => {
      load(page.pageSize, page.pageNumber, filter.q);
      setDeleteCarrierGroupId(undefined);
    });
  };

  const navBodyTemplate = (rowData: CarrierGroupListOutDTO) => {
    return (
      <div>
        <ButtonLayout>
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierGroupDetail(rowData.carrierGroupId))}>
            <a className="btn-detail p-button p-button-rounded p-button-outlined secondary-btn">{t(`AllCarrierGroups.detail`, `Detail`)}</a>
          </Link>

          <SplitButton
            icon="pi pi-ellipsis-h"
            model={[
              {
                label: t(`AllCarrierGroups.delete`, `Smazat`),
                icon: 'pi pi-trash',
                command: e => {
                  setDeleteCarrierGroupId(rowData.carrierGroupId);
                },
              },
            ]}
            className="btn-more-info p-button-rounded p-button-outlined p-ml-3"
          />
        </ButtonLayout>
      </div>
    );
  };

  const nameBodyTemplate = (rowData: CarrierGroupListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t('labelName')} />
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierGroupDetail(rowData.carrierGroupId))}>
          <a>{rowData.name}</a>
        </Link>
      </>
    );
  };
  const addressBodyTemplate = (rowData: CarrierGroupListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarrierGroups.numberOfCarriers`, `Number of carriers`)} />
        <ColumnContent content={rowData.carriersCount} />
      </>
    );
  };
  const createByBodyTemplate = (rowData: CarrierGroupListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarrierGroups.createdBy`, `Created by`)} />
        <ColumnContent content={rowData.createdBy} />
      </>
    );
  };
  const createdBodyTemplate = (rowData: CarrierGroupListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarrierGroups.created`, `Created`)} />
        <ColumnContent content={dateFormatDateTime(mapFromAPIDateTime(rowData.createdAt))} />
      </>
    );
  };

  const [loading, setLoading] = useState(false);

  return (
    <>
      <NavTitle wrapper="PADDING-0">
        <h2 className="h2-title p-m-0">{t(`AllCarrierGroups.allCarrierGroups`, `Skupiny dopravců`)}</h2>
        <Button
          label={t(`AllCarrierGroups.newCarrierGroup`, `Nová skupina`)}
          icon="icon pi pi-plus"
          className="p-btn-edit p-button-rounded"
          onClick={() => setVisibleCreateGroup(true)}
        />
      </NavTitle>
      <div className="filter-table p-mt-3 p-mb-2">
        <InputText
          type="text"
          placeholder={t(`AllCarrierGroups.searchGroups`, `Search groups`)}
          style={{ width: '100%' }}
          value={filter?.q}
          onChange={e => applyFilterValue('q', (e.target as HTMLInputElement).value)}
        />
      </div>
      {carrierGroupsData && (
        <>
          {/*
        // @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            value={carrierGroupsData.data}
            rows={carrierGroupsData.size}
            totalRecords={carrierGroupsData.total}
            className="same-table responsive-table p-mt-4"
            loading={loading}
            onPage={onPage}
            paginator={true}
            first={(carrierGroupsData.page - 1) * carrierGroupsData.size}
            paginatorPosition={'both'}
          >
            <Column className="same-table__name" field="name" header={t(`AllCarrierGroups.name`, `Název`)} body={nameBodyTemplate}></Column>
            <Column className="same-table__address" field="address" header={t(`AllCarrierGroups.numberOfCarriers`, `Počet dopravců`)} body={addressBodyTemplate}></Column>
            <Column className="same-table__createBy" field="createBy" header={t(`AllCarrierGroups.createdBy`, `Vytvořil`)} body={createByBodyTemplate}></Column>
            <Column className="same-table__created" field="created" header={t(`AllCarrierGroups.created`, `Vytvořeno`)} body={createdBodyTemplate}></Column>
            <Column className="same-table__nav" header={t(`AllCarrierGroups.actions`, `Akce`)} body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}
      {/*
        // @ts-ignore*/}
      <Dialog
        header={t(`AllCarrierGroups.deleteThisCarrierGroup`, `Smazat tuto skupinu`)}
        visible={deleteCarrierGroupId}
        className="modal-sm"
        onHide={() => setDeleteCarrierGroupId(undefined)}
      >
        <div>
          <p className="p-mt-0">{t(`AllCarrierGroups.byDeleting`, `Smazáním této skupiny budou smazány všechny navazující informace.`)}</p>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`AllCarrierGroups.delete`, `Smazat`)} className="p-mr-2 p-button-danger" onClick={deleteGroup} />
          <Button label={t(`AllCarrierGroups.cancel`, `Zrušit`)} className="p-button-text" onClick={() => setDeleteCarrierGroupId(undefined)} />
        </ButtonLayout>
      </Dialog>

      <CreateOrUpdateCarrierGroup
        visible={visibleCreateGroup}
        onHide={() => setVisibleCreateGroup(false)}
        onSuccess={() => {
          load(page.pageSize, page.pageNumber, filter?.q);
          setVisibleCreateGroup(false);
        }}
      />
    </>
  );
};

export default AllCarrierGroups;
