import classNames from 'clsx';
import clsx from 'clsx';

interface ComponentProps {
  children?: any;
  label?: any;
  className?: any;
}

const ButtonWrapper: React.FC<ComponentProps> = ({ children, label, className }) => {
  const classes = clsx(className);

  return (
    <div className={'button-wrapper ' + classes}>
      {label && (
        <label className="inputLabel p-d-block">
          <strong>{label}</strong>
        </label>
      )}
      {children}
    </div>
  );
};

export default ButtonWrapper;
