import { OrdersConfig } from '@components/framework/orders/validation.ts';

export const reservationOrdersConfig: OrdersConfig = {
  orderName: {
    visibility: 'always',
    required: false,
  },
  cargo: {
    visibility: 'optional',
    required: false,
  },
  cargoDimensions: {
    visibility: 'never',
    required: true,
    items: {
      width: {
        required: true,
      },
      height: {
        required: true,
      },
      length: {
        required: true,
      },
      weight: {
        required: true,
      },
    },
  },
  cargoDetails: {
    visibility: 'never',
    required: true,
    items: {
      orderAmount: {
        required: true,
      },
      orderDescription: {
        required: true,
      },
      orderValue: {
        required: true,
      },
    },
  },
  totalWeight: {
    render: false,
  },
};
