import { RadioButton } from 'primereact/radiobutton';
import { useId } from 'react';

interface ComponentProps {
  inputValue?: string;
  inputName?: string;
  onChange?: any;
  checked?: any;
  children?: any;
}

const TimeslotsProgressInputSelect: React.FC<ComponentProps> = ({ inputValue, inputName, onChange, checked, children }) => {
  const uniqID = useId().replace(/:/g, '');
  return (
    <div className="timeslotsProgress-item">
      <div className="p-field-radiobutton">
        <RadioButton inputId={uniqID} name={inputName} value={inputValue} onChange={onChange} checked={checked} />
        <div className="p-d-flex p-flex-wrap">{children}</div>
      </div>
    </div>
  );
};
export default TimeslotsProgressInputSelect;
