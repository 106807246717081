import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { dumpVars, formatInterval, isNullOrUndefined } from '@utils/utils';
import { mapFromAPIToDateShort } from '@utils/date';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import { ReservationRampDashboardOutDTO } from '@api/logsteo-api.v2.tsx';
import { useParams } from 'react-router-dom';

interface ComponentProps {}

const LocationPanelPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { getReservationForRampDashboard } = useContext(ApiContext);
  const [data, setData] = useState<ReservationRampDashboardOutDTO[]>();

  const { locationId } = useParams();

  useEffect(() => {
    load();
    const timer = setInterval(() => load(), 15000);
    return () => clearInterval(timer);
  }, [locationId]);

  console.log('locationId', locationId);

  const load = () => {
    getReservationForRampDashboard(locationId, d => {
      const newData = d.map(t => {
        return {
          reservations: t.reservations.filter((_, index) => index < 3),
          name: t.name,
          calendarId: t.calendarId,
          rampId: t.rampId,
        } as ReservationRampDashboardOutDTO;
      });
      setData(newData.filter(t => t.reservations.length > 0));
    });
  };

  if (isNullOrUndefined(data)) return <></>;

  return (
    <>
      <RampsWrapper>
        <Background>
          <table style={{ width: '100%', height: '100%' }}>
            <tbody>
              {data.map((r, rIndex) => {
                return (
                  <tr key={rIndex}>
                    <RampName>{r.name}</RampName>
                    {r.reservations.map((reservation, resId) => {
                      return (
                        <RampOperation key={resId}>
                          <div>
                            {reservation.reservationName} {reservation.orderId && <>({reservation.orderId})</>}
                          </div>
                          <div>
                            {mapFromAPIToDateShort(reservation.timeslot.since)} {formatInterval(false, reservation.timeslot.since, reservation.timeslot.till, tr)}
                          </div>
                        </RampOperation>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Background>
      </RampsWrapper>
    </>
  );
};

const RampOperation = styled.td`
  line-height: 24pt;
  font-size: 24pt;
  color: black;
  padding: 0 1rem;
`;

const RampName = styled.th`
  line-height: 48pt;
  font-size: 48pt;
  color: black;
  width: 350px;
`;

const RampWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  height: 100vh;

  background-color: white;
  opacity: 0.87;
`;

const RampsWrapper = styled.div`
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-size: contain;
`;

export default withAuthenticationRequired(LocationPanelPage);
