import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import AdminCompanyDetailPage from '@components/pages/admin/companies/AdminCompanyDetailPage';
import { useParams } from 'react-router-dom';
import AdminLayout from '@app/layout/AdminLayout.tsx';

interface ComponentProps {}

const CompanyDetailPage: React.FC<ComponentProps> = () => {
  const { companyId } = useParams();

  return (
    <AdminLayout title={'Company detail'}>
      <AdminCompanyDetailPage companyId={companyId} />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(CompanyDetailPage);
