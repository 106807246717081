import React, { useContext, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import styled from 'styled-components';
import { SelectButton } from 'primereact/selectbutton';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import AddToCarrierGroupAutocomplete, { CarrierGroup } from '@components/obsolete/AddToCarrierGroupAutocomplete/AddToCarrierGroupAutocomplete.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  carrierId: string;
  onAdded: () => void;
}
enum AddMode {
  GROUP,
  STANDALONE,
}
interface LabelValueDTO {
  label: string;
  value: AddMode;
}

const AddCarrierSidebar: React.FC<ComponentProps> = ({ visible, onHide, carrierId, onAdded }) => {
  const { tr } = useTranslationLgs();
  const { cuAddCatalogCarrierGroupForCarrier, cuAddCatalogCarrierToMyCompany } = useContext(ApiContext);

  const addModeMenus: LabelValueDTO[] = [
    {
      label: tr('AddCarrierSidebar.STANDALONE', 'Bez skupiny'),
      value: AddMode.STANDALONE,
    },
    {
      label: tr('AddCarrierSidebar.GROUP', 'Do skupiny dopravců'),
      value: AddMode.GROUP,
    },
  ];

  const [addMode, setAddMode] = useState<AddMode>(AddMode.STANDALONE);
  const [groups, setGroups] = useState<CarrierGroup[]>([]);

  const addCarrier = () => {
    if (addMode === AddMode.GROUP) {
      cuAddCatalogCarrierGroupForCarrier(
        carrierId,
        {
          carrierGroupIds: groups.map(t => {
            return { id: t.id, name: t.name };
          }),
        },
        () => {
          onAdded();
        },
      );
    } else {
      cuAddCatalogCarrierToMyCompany(carrierId, () => {
        onAdded();
      });
    }
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar onHide={onHide} visible={visible} blockScroll={true} position={'right'} style={{ minWidth: 450 }}>
        <SidebarContent>
          <h2>{tr(`AddCarrierSidebar.addTitle`, `Přidat dopravce`)}</h2>
          <SelectButton
            unselectable={false}
            options={addModeMenus}
            optionLabel={'label'}
            optionValue={'value'}
            value={addMode}
            onChange={e => {
              setAddMode(e.value);
            }}
          />
          {addMode === AddMode.STANDALONE && (
            <>
              <Note>{tr(`AddCarrierSidebar.groupModeDescription`, `Dopravce bude přidán bez přidání do skupiny`)}</Note>
              <Buttons>
                <Button label={tr(`AddCarrierSidebar.add`, `Přidat`)} className={'main'} onClick={() => addCarrier()}></Button>
                <Button label={tr(`AddCarrierSidebar.back`, `Zpět`)} className={'p-button-text'} onClick={onHide}></Button>
              </Buttons>
            </>
          )}
          {addMode === AddMode.GROUP && (
            <>
              <Field>
                <FieldLabel>{tr(`AddCarrierSidebar.carrierGroupName`, `Název skupiny dopravce`)} *</FieldLabel>
                <AddToCarrierGroupAutocomplete groups={groups} onChanged={e => setGroups(e)}></AddToCarrierGroupAutocomplete>
              </Field>
              <Note>
                {tr(`AddCarrierSidebar.carrierAddToGroupNote`, `Dopravce bude přidán do zvolené skupiny. Pokud skupina neexistuje, bude automaticky založena.`)}
              </Note>
              <Buttons>
                <Button label={tr(`AddCarrierSidebar.add`, `Přidat`)} className={'main'} disabled={groups?.length == 0} onClick={() => addCarrier()}></Button>
                <Button label={tr(`AddCarrierSidebar.back`, `Zpět`)} className={'p-button-text'} onClick={onHide}></Button>
              </Buttons>
            </>
          )}
        </SidebarContent>
      </Sidebar>
    </>
  );
};

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
const FieldLabel = styled.div`
  font-weight: bold;
`;

const Buttons = styled.div`
  margin-top: 35px;
  display: flex;
  button.main {
    margin-right: 10px;
    span {
      padding: 0px 25px 0px 25px;
    }
  }
`;

const Note = styled.div`
  margin-top: 15px;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AddCarrierSidebar;
