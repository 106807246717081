import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
  text: string;
}

const COSelect: React.FC<ComponentProps> = ({ text }) => {
  return <COSelectInner>{text}</COSelectInner>;
};

const COSelectInner = styled.div`
  display: inline-flex;
  border-radius: 20px;
  padding: 1rem;
  background-color: var(--ringil3-content-quaternary);
  white-space: nowrap;

  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
`;

export default COSelect;
