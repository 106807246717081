import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PermissionTypesEnum } from '@api/logsteo-api.v2';
import Layout from '@app/layout/Layout.tsx';
import { TimeslotCalendar } from '@app/pages/timeslots/Calendar.tsx';

interface ComponentProps {}

const CalendarPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Layout title={tr(`calendar.calendar`, `Calendar`)} oneOfPermission={[PermissionTypesEnum.PERMISSION_TIMESLOTS, PermissionTypesEnum.PERMISSION_TIMESLOT_CALENDAR]}>
        <TimeslotCalendar />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(CalendarPage);
