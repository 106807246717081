import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Tab from '@components/framework/tabs/Tab.tsx';
import SvgTabInternalInfo from '@components/ringil3/icons/TabInternalInfo.tsx';
import SvgTabAttachments from '@components/ringil3/icons/TabAttachments.tsx';
import SvgCalendar from '@app/pages/dap/icons/Calendar.tsx';
import styled from 'styled-components';
import SvgGlobe from '@icons/Globe.tsx';

interface ComponentProps {
  onPaneChange?: (pane: RingilDapTabs) => void;
  isOwner: boolean;
  showCustoms: boolean;
}

export type RingilDapTabs = 'detail' | 'internalinfo' | 'attachments' | 'messages' | 'customs';

export const useCreateDAPTabs = () => {
  const [selectedPanel, setSelectedPanel] = useState<RingilDapTabs>('detail');

  const DapTables: React.FC<ComponentProps> = ({ isOwner, showCustoms, onPaneChange = () => {} }) => {
    const { tr } = useTranslationLgs();
    return (
      <TabsInner>
        <Tab
          icon={<SvgCalendar />}
          label={tr(`RingilDapTabs.detail`, `Avizace`)}
          active={selectedPanel === 'detail'}
          onClick={() => {
            setSelectedPanel('detail');
            onPaneChange('detail');
          }}></Tab>
        {isOwner && (
          <Tab
            icon={<SvgTabInternalInfo />}
            label={tr(`RingilDapTabs.internalinfo`, `Internal info`)}
            active={selectedPanel === 'internalinfo'}
            onClick={() => {
              setSelectedPanel('internalinfo');
              onPaneChange('internalinfo');
            }}></Tab>
        )}

        {isOwner && showCustoms && (
          <Tab
            icon={<SvgGlobe />}
            label={tr(`RingilDapTabs.customs`, `Clo`)}
            active={selectedPanel === 'customs'}
            onClick={() => {
              setSelectedPanel('customs');
              onPaneChange('customs');
            }}></Tab>
        )}

        <Tab
          icon={<SvgTabAttachments />}
          label={tr(`RingilDapTabs.attachments`, `Attachments`)}
          active={selectedPanel === 'attachments'}
          onClick={() => {
            setSelectedPanel('attachments');
            onPaneChange('attachments');
          }}></Tab>
      </TabsInner>
    );
  };
  return { DapTables, selectedPanel };
};

const TabsInner = styled.div`
  display: flex;
  margin: 1.5rem 0 0.5rem 0;
  gap: 0.5rem;
  border-bottom: 2px solid var(--ringil3-brand-color);
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--ringil3-base-tertiary-color);
`;
