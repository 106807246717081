import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import ShowPowerBIReport from '@components/ringil3/Features/reports/ShowPowerBIReport.tsx';

interface ComponentProps {}

const ShowReportPage: React.FC<ComponentProps> = () => {
  const { reportId } = useParams();

  return <>{reportId && <ShowPowerBIReport reportId={reportId} />}</>;
};

export default withAuthenticationRequired(ShowReportPage);
