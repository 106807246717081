import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { mapFromAPIToDateShort } from '@utils/date';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  reservation?: ReservationsDataOutDTO;
}

const CFRCreated: React.FC<ComponentProps> = ({ reservation }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <div>
        {tr(`calendar.created`, `C: {{createdByFullName}} ({{createdAt}})`, {
          createdByFullName: reservation.createdByFullName,
          createdAt: mapFromAPIToDateShort(reservation.createdAt),
        })}
      </div>
    </>
  );
};

export default CFRCreated;
