import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { TransportationTypeEnum } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import CreateSupplierNotificationDap, { SupplierNotificationDapForm } from '@app/pages/dap/CreateSupplierNotificationDap.tsx';
import { emptyOrder } from '@components/framework/orders/common.tsx';

interface ComponentProps {}

const CreateSupplierNotificationDapPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const initialData: SupplierNotificationDapForm = {
    transportationType: TransportationTypeEnum.GROUND,
    attachments: [],
    orders: [emptyOrder(true)],
    companyId: null,
    supplierId: null,
    supplierLocationId: null,
    customerLocationId: null,
    notifyData: {
      notifyUsersDependsOnRole: true,
      responsiblePerson: null,
      userToNotify: [],
    },
    loadingGlobalTimeslots: { dayWithInterval: [] },
    unloadingGlobalTimeslots: { dayWithInterval: [] },
    carrierName: null,
    shipmentNumber: null,
  };

  return (
    <>
      <Layout title={tr(`CreateSupplierNotificationDapPage.createSupplierNotificationDap`, `Vytvořit DAP avizaci`)}>
        <CreateSupplierNotificationDap initialData={initialData} />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(CreateSupplierNotificationDapPage);
