import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAuditlog = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={27} fill="none" {...props}>
    <path
      fill="#B2B2B2"
      d="M7.649 21.149 13 26.5l5.351-5.351L13 15.799zM5.351 18.851 10.7 13.5l-5.35-5.35L0 13.5zM7.649 5.851 13 .5l5.351 5.351L13 11.2zM20.649 8.15l-5.35 5.35 5.35 5.351L26 13.5z"
    />
  </svg>
);
export default SvgAuditlog;
