import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInternalInfoHeadling = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
    <path
      fill="#B2B2B2"
      fillRule="evenodd"
      d="M30 15c0 8.284-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0s15 6.716 15 15m-16.875 0H11.25v-3.75h5.625v9.375h1.875v3.75h-5.625zm3.75-5.625v-3.75h-3.75v3.75z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInternalInfoHeadling;
