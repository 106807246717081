import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Col } from '@components/styles';
import { GrayText } from '@components/ringil3/styles';
import RingilButton from '@components/ringil3/Buttons/RingilButton';

interface ComponentProps {
  label?: string | React.ReactNode;
  labelNote?: string | ReactNode;
  icon?: JSX.Element;
  actionIcon?: JSX.Element;
  actionPane?: React.ReactNode;
  onClose?: () => void;
  onSave?: (onSuccess: () => void) => void;
  variant?: 'empty' | 'defined';
  open: boolean;
  setOpen: (open: boolean) => void;
  hideButtons?: boolean;
  autoClose?: boolean;
}

const ButtonTag: React.FC<ComponentProps> = ({
  label,
  icon,
  actionIcon,
  actionPane,
  onClose = () => {},
  labelNote,
  onSave = () => {},
  variant,
  open,
  setOpen,
  hideButtons = false,
  autoClose = false,
}) => {
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const { tr } = useTranslationLgs();

  const handleClickOutside = (event: any) => {
    console.log(event.target, detailsRef.current && !detailsRef.current.contains(event.target));

    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      setOpen(false);
      onClose();
    }
  };

  useEffect(() => {
    if (open && autoClose) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <Details
      ref={detailsRef}
      open={open}
      onClick={e => {
        /*        if (open) {
          e.preventDefault();
          return;
        }
        setOpen(true);*/
        e.preventDefault();
      }}>
      <Summary variant={variant ? variant : 'defined'}>
        {icon && <div>{icon}</div>}
        {label && typeof label == 'string' && (
          <Col>
            <GrayText>{label}</GrayText>
            {labelNote && <TagValue>{labelNote}</TagValue>}
          </Col>
        )}
        {label && typeof label != 'string' && (
          <Col>
            <span>{label}</span>
          </Col>
        )}
        {actionIcon && (
          <div
            onClick={e => {
              setOpen(true);
            }}>
            {actionIcon}
          </div>
        )}
      </Summary>
      {actionPane && open && (
        <FloatContent>
          {actionPane}
          {!hideButtons && (
            <TagPaneButtonRow>
              <RingilButton
                label={tr(`RingilSidebar.continue`, `Continue`)}
                onClick={() => {
                  onSave(() => setOpen(false));
                }}
                mode={'filled'}
                size={'big'}
                loading={false}
              />
              <RingilButton
                label={tr(`RingilSidebar.back`, `Back`)}
                onClick={() => {
                  onClose ? onClose() : {};
                  setOpen(false);
                }}
                mode={'text-only'}
                size={'big'}
                loading={false}
              />
            </TagPaneButtonRow>
          )}
        </FloatContent>
      )}
    </Details>
  );
};

const TagPaneButtonRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const Details = styled.details`
  position: relative;

  &[open] summary {
    border: 1px solid var(--content-brand);
    background-color: var(--base-basesecondary);
  }
`;

const FloatContent = styled.div`
  display: flex;
  padding: 1.5rem;
  background-color: var(--base-basesecondary);
  position: absolute;
  top: 100%;
  z-index: 1000;
  margin: 0.5rem 0;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 1rem;
`;

const Summary = styled.summary<{ variant: 'empty' | 'defined' }>`
  cursor: pointer;
  list-style: none;
  display: flex;
  border-radius: 8px;
  padding: 12px 14px;
  gap: 8px;
  align-content: center;
  align-items: center;
  background-color: ${props => (props && props.variant === 'defined' ? 'var(--base-basesecondary)' : 'var(--content-quaternary)')};
  border: 1px solid var(--ringil3-brand-light-color);
  border: ${props => (props && props.variant === 'defined' ? '1px solid var(--ringil3-brand-light-color)' : '1px solid var(--content-secondary)')};

  &::marker {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-details-marker {
    display: none;
  }
`;

const TagValue = styled.div`
  font-weight: 600;
`;

export default ButtonTag;
