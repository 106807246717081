import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ExpeditionStatusEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  expeditionStage: string;
  isManual: boolean;
}

const CarrierExpeditionStateSpan: React.FC<ComponentProps> = ({ expeditionStage, isManual }) => {
  let stage = null;
  const { tr } = useTranslationLgs();

  switch (expeditionStage) {
    case ExpeditionStatusEnum.ASSIGNED:
      stage = <span className="">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;

    case ExpeditionStatusEnum.ASSIGNING:
      stage = <span className="">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;

    case ExpeditionStatusEnum.NEW:
      stage = <span className="">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;

    case ExpeditionStatusEnum.CANCELED:
      stage = <span className="">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;

    case ExpeditionStatusEnum.READY_TO_SHIPMENT:
      stage = <span className="">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;

    case ExpeditionStatusEnum.ACTIVE:
      stage = <span className="">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;

    case ExpeditionStatusEnum.WAITING_FOR_DOCS:
      stage = <span className="text-green">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;

    case ExpeditionStatusEnum.FINISHED:
      stage = <span className="">{tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}</span>;
      break;
  }

  if (isManual) stage = <span className="">{tr(`Shipments.MANUAL`, `Shipments.MANUAL`)}</span>;

  return <>{stage}</>;
};

export default CarrierExpeditionStateSpan;
