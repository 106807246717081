import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { TruckTypeOutDTO } from '@api/logsteo-api.v2';
import Tag from '@components/ringil3/Tags/Tag';
import { formatWeight } from '@utils/utils';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgTrucktype from '@components/ringil3/icons/Trucktype';

interface ComponentProps {
  truckType?: TruckTypeOutDTO;
}

const TruckTag: React.FC<ComponentProps> = ({ truckType }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      {truckType && (
        <ButtonTag
          label={formatWeight(truckType.loadingCapacity)}
          labelNote={tr(`global.truckCode.${truckType.code}`, `global.truckCode.${truckType.code}`)}
          icon={<SvgTrucktype />}
          setOpen={setOpen}
          open={open}
        />
      )}
    </>
  );
};

export default TruckTag;
