import { useContext, useEffect, useRef, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import styled from 'styled-components';
import { ApiContext } from '@api/api';

interface ComponentProps {
  onChanged: (groups: CarrierGroup[]) => void;
  groups: CarrierGroup[];
}

export interface CarrierGroup {
  name: string;
  isNew: boolean;
  id?: string;
}

const AddToCarrierGroupAutocomplete: React.FC<ComponentProps> = ({ onChanged, groups }) => {
  const { tr } = useTranslationLgs();
  const { cuLoVCatalogCarrierGroupsWithoutExisting, cuCheckIfGroupAlreadyExists } = useContext(ApiContext);

  useEffect(() => {
    cuLoVCatalogCarrierGroupsWithoutExisting(res => {
      setBackendData(
        res.map(t => {
          return { name: t.name, isNew: false, id: t.id };
        }),
      );
    });
  }, []);

  const findCarrierGroup = (q: string) => {
    cuLoVCatalogCarrierGroupsWithoutExisting(res => {
      setBackendData(
        res
          .filter(t => t.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) >= 0)
          .map(t => {
            return { name: t.name, isNew: false, id: t.id };
          }),
      );
    });
  };

  const [backendData, setBackendData] = useState<CarrierGroup[]>([]);
  const inputRef = useRef(undefined);

  const keyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      const groupName = inputRef.current.value;
      const addingGroup = { name: groupName, isNew: false };
      onChanged([...groups, addingGroup]);
      cuCheckIfGroupAlreadyExists({ groupName }, res => {
        if (!res.isExists) {
          addingGroup.isNew = true;
        }
      });
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Fluid>
        <AutoComplete
          suggestions={backendData}
          value={groups}
          inputRef={inputRef}
          multiple={true}
          onKeyPress={e => {}}
          onSelect={e => {
            onChanged([...groups, e.value]);
          }}
          selectedItemTemplate={(e: CarrierGroup) => {
            return (
              <>
                {e.isNew && (
                  <div style={{ color: 'green' }}>
                    {e.name} [{tr(`AddToCarrierGroupAutocomplete.willBeCreated`, `bude vytvořena`)}]
                  </div>
                )}
                {!e.isNew && <div>{e.name}</div>}
              </>
            );
          }}
          itemTemplate={e => <div>{e.name}</div>}
          completeMethod={e => findCarrierGroup(e.query)}
          onKeyUp={e => keyUp(e)}
          onUnselect={e => {
            onChanged(groups.filter(t => t !== e.value));
          }}
        />
      </Fluid>
      <Note>{tr(`AddToCarrierGroupAutocomplete.pushEnter`, `Pro vytvoreni nove skupiny stisknete enter`)}</Note>
    </>
  );
};

const Note = styled.div`
  margin-top: 5px;
`;

const Fluid = styled.span`
  span.p-autocomplete {
    display: flex;
  }
  .p-inputtext {
    width: 100%;
  }
`;

export default AddToCarrierGroupAutocomplete;
