import clsx from 'clsx';

interface ComponentProps {
  children?: any;
  label?: any;
  className?: any;
}

const CheckboxLayout: React.FC<ComponentProps> = ({ children, label, className }) => {
  const classes = clsx(className);
  return (
    <div className={classes}>
      {label && (
        <label className="p-d-block p-mb-2">
          <strong>{label}</strong>
        </label>
      )}
      <div className="input-row"> {children}</div>
    </div>
  );
};

export default CheckboxLayout;
