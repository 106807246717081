import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import InputUnitPlain from '../InputUnitPlain/InputUnitPlain';

interface ComponentProps {
  quantity: number;
  quantityUnitCode: string;
  onChangeQuantity: (value: number) => void;
  onChangeQuantityUnitCode: (value: string) => void;
  disabled?: boolean;
}

const InputQuantity: React.FC<ComponentProps> = ({ quantity, quantityUnitCode, onChangeQuantity, onChangeQuantityUnitCode, disabled = false }) => {
  return (
    <>
      <InputNumber
        minFractionDigits={0}
        maxFractionDigits={2}
        locale={'cs'}
        value={quantity}
        disabled={disabled}
        onChange={v => onChangeQuantity(v.value)}
        inputStyle={{ width: '100px' }}
      />
      <InputUnitPlain code={quantityUnitCode} onChange={v => onChangeQuantityUnitCode(v)} disabled={disabled} />
    </>
  );
};

export default InputQuantity;
