import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import AdminAllUsers from '@components/pages/admin/users/AdminAllUsers';

const AdminAllCompanies = () => {
  return (
    <AdminLayout title={'Usrs'}>
      <AdminAllUsers />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(AdminAllCompanies);
