import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import Demands from '@app/pages/carrier/demand-list/Demands.tsx';

const PageDemands = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t('Demands.demands', 'Poptávky')}>
      {/*// @ts-ignore*/}

      <Card>
        <Demands />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(PageDemands);
