import React from 'react';
import { InputText } from 'primereact/inputtext';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const InputHour: React.FC<ComponentProps> = ({ onChange, value, disabled, readOnly }) => {
  const guestTime = (time: string) => {
    const [hours, minutes] = time.split(':');
    if (time.length == 1) return guestTime('0' + time + ':00');
    if (time.length == 2) return guestTime(time + ':00');
    if (time.length === 4) return guestTime('0' + hours + ':' + minutes);
    if (Number(hours) > 23) {
      return '24:00';
    }
    if (Number(minutes) > 59) {
      return '24:00';
    }
    return time;
  };

  const onBlur = () => {
    onChange(guestTime(value));
  };

  const change = (time: string) => {
    if (time.match(/[0-9:]*/)[0] === time) {
      onChange(time);
    }
  };

  return (
    <>
      <InputText
        onChange={e => change(e.currentTarget.value)}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        onBlur={e => onBlur()}
        onKeyUp={event => {
          if (event.key === 'Enter') {
            onBlur();
          }
        }}
      />
    </>
  );
};

export default InputHour;
