import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {}

interface CompanyInfo {
  crn?: string;
  companyName?: string;
  city?: string;
  zipCode?: string;
  streetNr?: string;
  vat?: string;
  country?: string;
  adminFirstName?: string;
  adminLastName?: string;
  adminEmail?: string;
  adminPhone?: string;
  localityName?: string;
  localityCountry?: string;
  localityZipCode?: string;
  localityCity?: string;
  localityStreetNr?: string;
  localityContactName?: string;
  localityContactPhone?: string;
  localityContactEmail?: string;
}

const AdminImportCompanies: React.FC<ComponentProps> = () => {
  const [textArea, setTextArea] = useState<string>();
  const [data, setData] = useState<CompanyInfo[]>();
  const { convertCsvToListObject /*importCompanyAndLocation*/ } = useContext(ApiContext);

  useEffect(() => {
    convertCsvToListObject({ data: textArea }, d => {
      setData(d);
    });
  }, [textArea]);

  const importCompanies = () => {
    // @ts-ignore
    alert('Not implemented yet');
    /* importCompanyAndLocation({ companyInfoList: data }, () => {
      alert('Asi se to povedelo');
    });*/
  };

  return (
    <>
      <h1>Import companies</h1>
      <HorizontalFieldValue
        label={'Import CSV'}
        value={<InputTextarea style={{ width: '100%' }} rows={20} value={textArea} onChange={e => setTextArea(e.target.value)} />}
      />
      {/*// @ts-ignore*/}
      <DataTable value={data} responsiveLayout={'scroll'}>
        <Column field="crn" header="CRN" />
        <Column field="companyName" header="Company name" />
        <Column field="city" header="city" />
        <Column field="zipCode" header="zipCode" />
        <Column field="streetNr" header="streetNr" />
        <Column field="vat" header="vat" />
        <Column field="country" header="country" />
        <Column field="adminFirstName" header="adminFirstName" />
        <Column field="adminLastName" header="adminLastName" />
        <Column field="adminEmail" header="adminEmail" />
        <Column field="adminPhone" header="adminPhone" />
        <Column field="localityName" header="localityName" />
        <Column field="localityCountry" header="localityCountry" />
        <Column field="localityZipCode" header="localityZipCode" />
        <Column field="localityCity" header="localityCity" />
        <Column field="localityStreetNr" header="localityStreetNr" />
        <Column field="localityContactName" header="localityContactName" />
        <Column field="localityContactPhone" header="localityContactPhone" />
        <Column field="localityContactEmail" header="localityContactEmail" />
      </DataTable>

      <Button label={'Importuj'} onClick={e => importCompanies()}></Button>
    </>
  );
};

export default AdminImportCompanies;
