import React, { useId } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'primereact/tooltip';

interface ComponentProps {
  text?: any;
  icon?: any;
  color?: string;
  hoverComponent?: any;
}

const Badge: React.FC<ComponentProps> = ({ color, icon, text, hoverComponent }) => {
  const id = useId().replace(/:/g, '');
  return (
    <div>
      {/*
        // @ts-ignore*/}
      {hoverComponent && <Tooltip target={`#${id}`}>{hoverComponent}</Tooltip>}
      <BadgeWrapper id={`${id}`} style={{ color: color || 'none' }}>
        {icon && <>{icon}</>}
        {text && <>{text}</>}
      </BadgeWrapper>
    </div>
  );
};

const BadgeWrapper = styled.span`
  font-size: 0.8rem;
  white-space: nowrap;
  display: flex;
  padding: 0rem 0.5rem;
  background-color: var(--ringil-base-color);
  border: 1px solid var(--ringil-base-penitentiary-color);
  border-radius: 3px;
  align-items: center;
  gap: 0.5rem;
`;

export const BadgeRow = styled.div`
  flex-direction: row;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
`;

export default Badge;
