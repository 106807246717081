import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { LovTransportationTypeOutDTO, TransportationTypeEnum } from '@api/logsteo-api.v2';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';

interface ComponentProps {
  value: TransportationTypeEnum;
  onChange: (code: TransportationTypeEnum) => void;
  disabled?: boolean;
}

const InputTransportationType: React.FC<ComponentProps> = ({ value, onChange, disabled }) => {
  const { lovTransportationTypes } = useContext(ApiContext);
  const [transportationTypes, setTransportationTypes] = useState<LovTransportationTypeOutDTO[]>([]);

  useEffect(() => {
    lovTransportationTypes(d => {
      setTransportationTypes(d);
    });
  }, []);

  return (
    <>
      <InputSelect
        disabled={disabled}
        model={transportationTypes.map(item => ({ code: item.transportationType, label: item.name }))}
        selectedItemCode={value}
        onSelect={v => {
          onChange(v as TransportationTypeEnum);
        }}
      />
    </>
  );
};

export default InputTransportationType;
