import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { mapFromAPIToDateShort } from '@utils/date';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';

interface CFRUpdatedProps {
  reservation?: ReservationsDataOutDTO;
}

const CFRUpdated: React.FC<CFRUpdatedProps> = ({ reservation }: CFRUpdatedProps) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <div>
        {tr(`calendar.updated`, `U: {{updatedByFullName}} ({{updatedAt}})`, {
          updatedByFullName: reservation.updatedByFullName,
          updatedAt: mapFromAPIToDateShort(reservation.updatedAt),
        })}
      </div>
    </>
  );
};

export default CFRUpdated;
