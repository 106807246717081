import React, { useContext, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { OpeningEntityTypeEnum, PartnerLocationInDTO } from '@api/logsteo-api.v2';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import { findValidationMessage } from '@utils/validation';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import OpeningHourDefinitionForm, { emptyOpeningHours, openingHoursValidationSchema } from '@components/obsolete/OpeningHoursDefinition/OpeningHourDefinitionForm.tsx';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import InputWrapper from '@components/obsolete/Form/InputWrapper/InputWrapper.tsx';
import InputCountry from '@components/obsolete/Form/InputCountry/InputCountry.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  partnerLocationId?: string;
  partnerId: string;
  onSuccess: (partnerLocation: PartnerLocationInDTO) => void;
  partnerAddressDetail: PartnerAddressDetail;
}

interface PartnerAddressDetail {
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  streetNr: string;
  city: string;
  zipCode: string;
  country: string;
}

const CreateOrEditPartnerLocation: React.FC<ComponentProps> = ({ visible, onHide, partnerId, partnerLocationId, onSuccess, partnerAddressDetail }) => {
  const { t } = useTranslation('common');
  const { cuUpdatePartnerLocation, cuCreatePartnerLocation, cuGetEditPartnerLocation } = useContext(ApiContext);
  const [partnerLocation, setPartnerLocation] = useImmer<PartnerLocationInDTO>(undefined);

  const { tr } = useTranslationLgs();

  useEffect(() => {
    if (visible) {
      if (partnerLocationId) {
        cuGetEditPartnerLocation(partnerId, partnerLocationId, data => {
          setPartnerLocation(draft => {
            return data;
          });
        });
      } else {
        setPartnerLocation(draft => {
          return {
            name: '',
            city: '',
            timeslots: [],
            country: '',
            streetNr: '',
            zipCode: '',
            contacts: [],
            openingHourDefinitions: emptyOpeningHours(OpeningEntityTypeEnum.PARTNER_LOCATION),
          };
        });
      }
      clear();
    }
  }, [visible]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    country: yup.string().required(),
    zipCode: yup.string().required(),
    city: yup.string().required(),
    streetNr: yup.string().required(),
    contactEmail: yup.string().email().nullable(),
    contacts: yup
      .array()
      .of(
        yup.object().shape({
          contactEmail: yup.string().email().nullable(),
        }),
      )
      .nullable(),
    openingHourDefinitions: openingHoursValidationSchema,
  });

  const { clear, validationErrors, validateAndSend } = useValidation(validationSchema, () => onSuccess(partnerLocation));

  const copyFromPartner = () => {
    setPartnerLocation(draft => {
      draft.country = partnerAddressDetail.country;
      draft.zipCode = partnerAddressDetail.zipCode;
      draft.streetNr = partnerAddressDetail.streetNr;
      draft.city = partnerAddressDetail.city;
    });
  };

  const removeContactPerson = (index: number) => {
    setPartnerLocation(draft => {
      draft.contacts = draft.contacts.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} style={{ minWidth: '650px' }}>
        {partnerLocation && (
          <>
            <HeaderRow>
              <h3 className="p-mb-4">
                {partnerLocationId
                  ? t(`CreateOrEditPartnerLocation.editPartnerLocation`, `Upravit lokaci`)
                  : t(`CreateOrEditPartnerLocation.createPartnerLocation`, `Vytvořit lokaci`)}
              </h3>
              <ButtonWrapper>
                <Button label={tr(`CreateOrEditPartnerLocation.copyFromPartner`, `Copy from partner`)} onClick={e => copyFromPartner()} />
              </ButtonWrapper>
            </HeaderRow>
            <InputLayout>
              <InputWrapper
                label={`${t(`CreateOrEditPartnerLocation.locationName`, `Název lokace`)} *`}
                type="text"
                classNameWrapper="p-col-12 p-md-6"
                value={partnerLocation.name}
                onChange={e =>
                  setPartnerLocation(draft => {
                    draft.name = (e.target as HTMLInputElement).value;
                  })
                }
                error={findValidationMessage(validationErrors, 'name', t)}
              />
              <InputCountry
                label={`${t(`CreateOrEditPartnerLocation.country`, `Stát`)} *`}
                value={partnerLocation.country}
                onChange={e =>
                  setPartnerLocation(draft => {
                    draft.country = e;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'country', t)}
              />
              <InputWrapper
                label={`${t(`CreateOrEditPartnerLocation.postalCode`, `PSČ`)} *`}
                type="text"
                value={partnerLocation.zipCode}
                onChange={e =>
                  setPartnerLocation(draft => {
                    draft.zipCode = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'zipCode', t)}
              />
              <InputWrapper
                label={`${t(`CreateOrEditPartnerLocation.city`, `Město`)} *`}
                type="text"
                value={partnerLocation.city}
                onChange={e =>
                  setPartnerLocation(draft => {
                    draft.city = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'city', t)}
              />
              <InputWrapper
                label={`${t(`CreateOrEditPartnerLocation.streetNr`, `Ulice a číslo`)} *`}
                type="text"
                value={partnerLocation.streetNr}
                onChange={e =>
                  setPartnerLocation(draft => {
                    draft.streetNr = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'streetNr', t)}
              />
            </InputLayout>
            <Label title={t(`CreateOrEditPartnerLocation.contacts`, `Contacts`)} />
            {partnerLocation.contacts.map((contact, contactIndex) => {
              return (
                <Panel key={contactIndex}>
                  <Row>
                    <HorizontalFieldValue
                      label={`${t(`CreateOrEditPartnerLocation.contactName`, `Jmeno`)}`}
                      value={
                        <InputText
                          value={contact.contactName}
                          onChange={e =>
                            setPartnerLocation(draft => {
                              draft.contacts[contactIndex].contactName = e.target.value;
                            })
                          }
                        />
                      }
                    />
                    <HorizontalFieldValue
                      label={`${t(`CreateOrEditPartnerLocation.contactPhone`, `Kontaktní telefon`)}`}
                      value={
                        <InputText
                          value={contact.contactPhone}
                          onChange={e =>
                            setPartnerLocation(draft => {
                              draft.contacts[contactIndex].contactPhone = e.target.value;
                            })
                          }
                        />
                      }
                    />
                    <HorizontalFieldValue
                      label={`${t(`CreateOrEditPartnerLocation.contactEmail`, `Kontaktní email`)}`}
                      validationError={findValidationMessage(validationErrors, `contacts[${contactIndex}].contactEmail`, t)}
                      value={
                        <InputText
                          value={contact.contactEmail}
                          onChange={e =>
                            setPartnerLocation(draft => {
                              draft.contacts[contactIndex].contactEmail = e.target.value;
                            })
                          }
                        />
                      }
                    />
                  </Row>
                  <Row>
                    <div>
                      <Button icon="pi pi-trash" className=" p-button-outlined" onClick={() => removeContactPerson(contactIndex)} />
                    </div>
                  </Row>
                </Panel>
              );
            })}
            <Row>
              <Button
                label={tr(`CreateOrEditPartnerLocation.addContactPerson`, `Add contact person`)}
                onClick={e => {
                  setPartnerLocation(draft => {
                    draft.contacts.push({
                      contactName: '',
                      contactPhone: '',
                      contactEmail: '',
                    });
                  });
                }}
              ></Button>
            </Row>
            <OpeningHourDefinitionForm
              openingHours={partnerLocation.openingHourDefinitions}
              onChange={v =>
                setPartnerLocation(draft => {
                  draft.openingHourDefinitions = v;
                })
              }
              validationErrors={validationErrors}
              validationPrefix={'openingHourDefinitions.'}
            />
            <ButtonLayout wrapper="WITH_PADDING_TOP_4">
              <Button label={t(`CreateOrEditPartnerLocation.save`, `Uložit`)} className="p-mr-2" onClick={() => validateAndSend(partnerLocation)} />
              <Button label={t(`CreateOrEditPartnerLocation.cancel`, `Zrušit`)} className="p-button-text" onClick={() => onHide()} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  background-color: #f7f6f7;
  gap: 0.5rem;
  align-items: center;
`;

const ButtonWrapper = styled.div``;

export default CreateOrEditPartnerLocation;
