import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { SelectButton } from 'primereact/selectbutton';
import { BsTruck } from 'react-icons/bs';
import { IoAirplaneOutline, IoBoatOutline, IoTrainOutline } from 'react-icons/io5';
import { LovTransportationTypeOutDTO, TransportationTypeEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  code: TransportationTypeEnum;
  onChange: (code: string) => void;
  disabled: boolean;
  singleOption: boolean;
}

interface SelectButtonWithIcon {
  icon: any;
  label: string;
  code: string;
}

export const InputTransportationTypeTruckIcon = () => <BsTruck />;
export const InputTransportationTypeTrainIcon = () => <IoTrainOutline />;
export const InputTransportationTypeAirIcon = () => <IoAirplaneOutline />;
export const InputTransportationTypeBoatIcon = () => <IoBoatOutline />;

export const inputTransporationTypeConvertToIcon = (code: string) => {
  switch (code) {
    case 'AIR':
      return <InputTransportationTypeAirIcon />;
    case 'GROUND':
      return <InputTransportationTypeTruckIcon />;
    case 'BOAT':
      return <InputTransportationTypeBoatIcon />;
    case 'TRAIN':
      return <InputTransportationTypeTrainIcon />;
    default:
      return <div>bug</div>;
  }
};

const InputTransportationType: React.FC<ComponentProps> = ({ code, onChange, singleOption, disabled }) => {
  const { lovTransportationTypes } = useContext(ApiContext);
  const [transportationTypes, setTransportationTypes] = useState<LovTransportationTypeOutDTO[]>([]);

  useEffect(() => {
    lovTransportationTypes(d => {
      setTransportationTypes(d);
    });
  }, []);

  const data: SelectButtonWithIcon[] = transportationTypes.map(item => {
    return {
      label: item.name,
      code: item.transportationType,
      icon: inputTransporationTypeConvertToIcon(item.transportationType),
    };
  });

  return (
    <>
      <SelectButton
        value={code}
        onChange={e => onChange(e.value)}
        optionValue={'code'}
        unselectable={false}
        itemTemplate={d => (
          <WithIcon>
            {d.icon}
            {d.label}
          </WithIcon>
        )}
        options={singleOption ? data.filter(t => t.code === code) : data}
        disabled={disabled}
      />
    </>
  );
};

const WithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: var(--ringil-red-gap);
`;

export default InputTransportationType;
