import { useContext, useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { isNullOrUndefined } from '@utils/utils';
import { getIn, setIn } from '@utils/forms.ts';
import useTranslationLgs from '../i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { validate } from '@utils/validation.tsx';
import { useStateCallback2 } from '@hooks/useStateCallback/useStateCallback2.tsx';

interface FormValues {
  [key: string]: any;
}

export declare type FormikErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? Values[K][number] extends object
      ? FormikErrors<Values[K][number]>[] | string | string[]
      : string | string[]
    : Values[K] extends object
    ? FormikErrors<Values[K]>
    : string;
};

export const proxiedPropertiesOf = <TObj,>(obj?: TObj) => {
  return new Proxy(
    {},
    {
      get: (_, prop) => prop,
      set: () => {
        throw Error('Set not supported');
      },
    },
  ) as {
    [P in keyof TObj]?: P;
  };
};

const useForm = <T extends FormValues>(
  validationScheme: any,
  initialValues: T,
  onSuccessMethod: (values: T, onSuccess?: () => void) => void,
  validateOnChange: boolean,
  validateOnMount: boolean,
  runValidations: () => void = () => {},
  errorMode: 'toast' | 'scroll' | 'none' = 'toast',
) => {
  const [formData, setFormData] = useStateCallback2<T>(initialValues);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation('common');
  const [errors, setErrors] = useState<FormikErrors<T>>({});
  const [calledSetValues, setCalledSetValues] = useState(false);
  const [ready, setReady] = useState(true);

  /*  useEffect(() => {
    if (validateOnChange) {
      validateForm();
    }
  }, [formData]);*/

  useEffect(() => {
    if (validateOnMount) {
      validateForm(formData);
    }
  }, []);

  const { tr } = useTranslationLgs();
  const { showToastMessage } = useContext(ToastContext);

  useEffect(() => {
    if (validationErrors && validationErrors.length > 0) {
      if (errorMode === 'toast')
        showToastMessage(
          tr('CreateSupplierNotification.errorOnThePage', 'Error on the page'),
          tr(`CreateSupplierNotification.errorOnThePageDetail`, `There are errors on the page that prevent the form from being submitted`),
          'error',
        );
    } else if (errorMode === 'scroll') {
      const element = document.querySelector('.hasError');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [validationErrors]);

  const names = proxiedPropertiesOf<T>();

  const validateForm = (formData: T) => {
    const validationErrors = validate(validationScheme, formData, { formData }) || [];
    console.log('validationErrors', validationErrors);
    setIsValid(validationErrors.length == 0);
    setValidationErrors(validationErrors);
    setErrors(convertToErrors(validationErrors));
    if (runValidations) runValidations();
  };

  const validateAndSend = (validatingData: any = formData) => {
    const validationErrors = validate(validationScheme, validatingData, { formData }) || [];
    if (runValidations) runValidations();
    console.log('validationErrors', validationErrors, validatingData);
    setValidationErrors(validationErrors);
    setErrors(convertToErrors(validationErrors));
    setIsValid(validationErrors.length == 0);
    if (validationErrors.length === 0) onSuccessMethod(formData);
  };
  const validateAndSendWithSuccess = (onSuccess: () => void, validatingData: any = formData) => {
    const validationErrors = validate(validationScheme, validatingData, { formData }) || [];
    if (runValidations) runValidations();
    console.log('validationErrors', validationErrors, validatingData);
    setValidationErrors(validationErrors);
    setErrors(convertToErrors(validationErrors));
    setIsValid(validationErrors.length == 0);
    if (validationErrors.length === 0) onSuccessMethod(formData, onSuccess);
  };

  const find = (path: string, startsWith: boolean = false) => {
    const error = validationErrors.find(e => (startsWith ? e.path.startsWith(path) : e.path === path));
    if (isNullOrUndefined(error)) return undefined;
    return t(`validation.${error.type}`);
  };

  const clear = () => {
    setValidationErrors([]);
  };

  const setFieldValue = (fieldName: string, value: any) => {
    setFormData(
      d => {
        return setIn(isNullOrUndefined(d) ? {} : d, fieldName, value);
      },
      state => {
        if (validateOnChange) validateForm(state);
      },
    );
  };

  const setValues = (partialValues: FormValues) => {
    setFormData(
      d => {
        return { ...d, ...partialValues };
      },
      state => {
        if (validateOnChange) {
          validateForm(state);
        }
        setCalledSetValues(true);
      },
    );
  };

  const overwriteValues = (values: T) => {
    setFormData(
      v => values,
      state => {},
    );
  };

  function convertToErrors<Values>(yupError: ValidationError[]): FormikErrors<Values> {
    let errors: FormikErrors<Values> = {};
    yupError.forEach(yupError => {
      if (yupError.inner.length === 0) {
        errors = {
          ...errors,
          ...setIn(errors, yupError.path, yupError.message),
        };
      }
      for (const err of yupError.inner) {
        if (!getIn(errors, err.path)) {
          errors = { ...errors, ...setIn(errors, err.path, err.message) };
        }
      }
    });
    return errors;
  }

  return {
    validationErrors,
    validateAndSend,
    validateAndSendWithSuccess,
    clear,
    find,
    isValid,
    setValues,
    setFieldValue,
    values: formData,
    errors,
    validateForm,
    overwriteValues,
    names,
    calledSetValues,
    setReady,
    ready,
  } as Form<T>;
};

export interface Form<T extends FormValues> {
  validationErrors: ValidationError[];
  validateAndSend: (validatingData?: any) => void;
  validateAndSendWithSuccess: (onSuccess: () => void, validatingData?: any) => void;
  clear: () => void;
  find: (path: string, startsWith?: boolean) => string;
  isValid: boolean;
  setValues: (partialValues: Partial<T>) => void;
  setFieldValue: (fieldName: string, value: any) => void;
  values: T;
  errors: FormikErrors<T>;
  validateForm: (formData: T) => void;
  overwriteValues: (values: T) => void;
  names: { [P in keyof T]?: P };
  calledSetValues: boolean;
  setReady: (ready: boolean) => void;
  ready: boolean;
}

export default useForm;
