import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';
import { CarInfoPreviewOutDTO } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  carInfo?: CarInfoPreviewOutDTO;
  applicationId: string;
}

const CarDriverIcon: React.FC<ComponentProps> = ({ carInfo, applicationId }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      {carInfo && (
        <Icon>
          <span id={`carddriver_${applicationId}`} className={'pi pi-car'} />
          {/*
        // @ts-ignore*/}
          <Tooltip target={`#carddriver_${applicationId}`} style={{ maxWidth: '530px' }}>
            <div>
              <div>
                {tr(`CarDriverIcon.carPlate`, `Car plate: {{carPlate}}`, {
                  carPlate: carInfo.carPlate,
                })}
              </div>
              {carInfo.driverFullName && (
                <div>
                  {tr(`AllExpedition.driverFullName`, `Driver: {{driverFullName}}`, {
                    driverFullName: carInfo.driverFullName,
                  })}
                </div>
              )}
            </div>
          </Tooltip>
        </Icon>
      )}
    </>
  );
};

const Icon = styled.span`
  padding: 3px;
`;

export default CarDriverIcon;
