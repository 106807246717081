import React, { PropsWithChildren, useContext } from 'react';
import { isNullOrUndefined } from '@utils/utils';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {}

export const OnlyCompanyAdmin: React.FC<PropsWithChildren<ComponentProps>> = ({ children }) => {
  const { isCustomerAdmin, isCarrierAdmin, loggedUser, isCompanyAdmin } = useContext(AuthenticatedUserContext);

  if (!isNullOrUndefined(loggedUser)) {
    if (!(isCustomerAdmin() || isCarrierAdmin() || isCompanyAdmin())) return <>403 - Forbidden</>;

    return <>{(isCustomerAdmin() || isCarrierAdmin() || isCompanyAdmin()) && children}</>;
  }

  return <></>;
};
