import React from 'react';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { LovDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';

interface ComponentProps {
  subscribers: LovDTO[];
  onChange: (subscribers: LovDTO[]) => void;
  style?: any;
  disabled?: boolean;
}

const InputSubscriberPlain: React.FC<ComponentProps> = ({ subscribers, onChange, style, disabled = false }) => {
  const responsiblePersons = useResponsiblePersons();
  return (
    <>
      <MultiSelect
        value={subscribers}
        dataKey="id"
        options={responsiblePersons}
        onChange={e => onChange(e.target.value)}
        optionLabel="name"
        style={style}
        disabled={disabled}
        filter={true}
        itemTemplate={(option: LovDTO) => (
          <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
        )}
      />
    </>
  );
};

export default InputSubscriberPlain;
