import React, { useContext } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { NotificationEntityInDTO } from '@api/logsteo-api.v2';
import { Form } from '@/hooks/useForm/useForm';
import { RingilH3, RowWithIcon } from '@components/styles';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { shortDateFormat } from '@utils/date';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useButtonEnablers } from '@components/obsolete/Buttons/ButtonEnablers/ButtonEnablers.tsx';
import InputResponsiblePersonAndSubscribers from '@components/obsolete/Form/InputResponsiblePersonAndSubscribers/InputResponsiblePersonAndSubscribers.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { ClearButton } from '@components/obsolete/Buttons/ClearButton/ClearButton.tsx';
import InputIncoterm from '@components/obsolete/Form/InputIncoterm/InputIncoterm.tsx';

export interface SupplierNotificationInternalInfoData {
  notifyData: NotificationEntityInDTO;
  internalInvoiceNumber?: string;
  invoiceDueDate?: Date;
  deliveryConditionsIncotermCode?: string;
}

interface ComponentProps {
  form: Form<SupplierNotificationInternalInfoData>;
  readonly companyId: string;
  inSidebar: boolean;
  disabled?: boolean;
}

export const supplierNotificationValidation = yup.object().shape({});

const SupplierNotificationInternalInfoForm: React.FC<ComponentProps> = ({ form, companyId, inSidebar, disabled = false }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  enum InternalInfoButtons {
    INTERNAL_INVOICE_NUMBER = 'INTERNAL_INVOICE_NUMBER',
    INVOICE_DUE_DATE = 'INVOICE_DUE_DATE',
    INCOTERM = 'INCOTERM',
  }

  const InternalInfoSection = useButtonEnablers<InternalInfoButtons>(
    [
      {
        sectionCode: InternalInfoButtons.INTERNAL_INVOICE_NUMBER,
        label: tr('CreateSupplierNotification.internalInvoiceNumber', 'Internal invoice number'),
        expanded: false, //|| isNotNullOrUndefined(form.values.internalInvoiceNumber),
        closable: true,
      },
      {
        sectionCode: InternalInfoButtons.INVOICE_DUE_DATE,
        label: tr('CreateSupplierNotification.invoiceDueDate', 'Invoice due date'),
        expanded: false, // || isNotNullOrUndefined(form.values.invoiceDueDate),
        closable: true,
      },
      {
        sectionCode: InternalInfoButtons.INCOTERM,
        label: tr('CreateSupplierNotification.deliveryConditionIncoterms', 'Delivery condition Incoterms'),
        expanded: false /*||
          isNotNullOrUndefined(form.values.deliveryConditionsIncotermCode)*/,
        closable: true,
      },
    ],
    (section, visible) => {
      switch (section) {
        case InternalInfoButtons.INVOICE_DUE_DATE:
          form.values.invoiceDueDate = null;
          break;
        case InternalInfoButtons.INTERNAL_INVOICE_NUMBER:
          form.values.internalInvoiceNumber = null;
          break;
        case InternalInfoButtons.INCOTERM:
          form.values.deliveryConditionsIncotermCode = null;
          break;
      }
    },
  );

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      <RingilH3>{tr(`CreateSupplierNotification.internalInfo`, `Internal information`)}</RingilH3>
      <InputResponsiblePersonAndSubscribers
        responsiblePersonId={form.values.notifyData.responsiblePersonId}
        subscribersIds={form.values.notifyData.userIdsToNotify}
        notifyUsersDependsOnRole={form.values.notifyData.notifyUsersDependsOnRole}
        onChangeNotifyUsersDependsOnRole={value => {
          form.setFieldValue('notifyData.notifyUsersDependsOnRole', value);
        }}
        onChangeResponsiblePerson={value => {
          form.setFieldValue('notifyData.responsiblePersonId', value);
        }}
        onChangeUubscribersIds={value => {
          form.setFieldValue('notifyData.userIdsToNotify', value);
        }}
        notifyRole={'ROLE_SN_NOTIFY'}
        disabled={disabled}
      />
      {InternalInfoSection.expandedSections.find(t => t.sectionCode === InternalInfoButtons.INTERNAL_INVOICE_NUMBER).expanded && (
        <RR>
          <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              label={tr('CreateSupplierNotification.intervalInvoiceNumber', 'Internal invoice number')}
              value={
                <RowWithIcon>
                  <InputText
                    value={form.values.internalInvoiceNumber}
                    onChange={v => form.setFieldValue('internalInvoiceNumber', v.target.value)}
                    style={{ width: '100%' }}
                    disabled={disabled}
                  />
                  <ClearButton onClick={() => InternalInfoSection.hideSection(InternalInfoButtons.INTERNAL_INVOICE_NUMBER)} disabled={disabled} />
                </RowWithIcon>
              }
            />
          </RC>
        </RR>
      )}
      {InternalInfoSection.expandedSections.find(t => t.sectionCode === InternalInfoButtons.INVOICE_DUE_DATE).expanded && (
        <RR>
          <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              label={tr('CreateSupplierNotification.invoiceDueDate', 'Internal invoice due date')}
              value={
                <RowWithIcon>
                  <Calendar
                    locale={'cs'}
                    value={form.values.invoiceDueDate}
                    showIcon={true}
                    dateFormat={shortDateFormat}
                    onChange={e => form.setFieldValue('invoiceDueDate', e.value)}
                    disabled={disabled}
                  />
                  <ClearButton onClick={() => InternalInfoSection.hideSection(InternalInfoButtons.INVOICE_DUE_DATE)} disabled={disabled} />
                </RowWithIcon>
              }
            />
          </RC>
        </RR>
      )}
      {InternalInfoSection.expandedSections.find(t => t.sectionCode === InternalInfoButtons.INCOTERM).expanded && (
        <RR>
          <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              label={tr('CreateSupplierNotification.deliveryConditionIncoterm', 'Delivery condition Incoterm')}
              value={
                <RowWithIcon>
                  <InputIncoterm
                    code={form.values.deliveryConditionsIncotermCode}
                    onChange={v => form.setFieldValue('deliveryConditionsIncotermCode', v)}
                    style={{ width: '100%' }}
                    disabled={disabled}
                  />
                  <ClearButton onClick={() => InternalInfoSection.hideSection(InternalInfoButtons.INCOTERM)} disabled={disabled} />
                </RowWithIcon>
              }
            />
          </RC>
        </RR>
      )}
      <InternalInfoSection.ButtonEnablersComponent disabled={disabled} />
    </>
  );
};

export default SupplierNotificationInternalInfoForm;
