import React, { PropsWithChildren, ReactElement } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  heading: string;
  icon: ReactElement<{ color: string }>;
}

const SectionPanel: React.FC<PropsWithChildren<ComponentProps>> = ({ heading, icon, children }) => {
  const { tr } = useTranslationLgs();
  return (
    <div className={'bg-base-base px-[16px] py-[21px] flex flex-col gap-4'}>
      <COHeadline variant={'h2'} title={heading} icon={icon} />
      {children}
    </div>
  );
};

export default SectionPanel;
