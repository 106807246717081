import React, { useContext, useEffect, useRef, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { ApiContext } from '@api/api';
import { dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import ThreadPanel from './ThreadPanel';
import { EntityTypeEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  companyId: string;
  entityId: string;
  entityType: EntityTypeEnum;
  visible: boolean;
  onHide: () => void;
  customerName: string;
  readOnly?: boolean;
}

const CarrierChatComponent: React.FC<ComponentProps> = ({ companyId, entityId, entityType, visible, onHide, customerName, readOnly = false }) => {
  const [messages, setMessages] = useState(undefined);
  const { caGetMessagesV2, caSendMessageV2 } = useContext(ApiContext);
  const [message, setMessage] = useState<string>();
  const messagesEndRef = useRef(null);

  const reload = () => {
    caGetMessagesV2(companyId, entityId, entityType, data => {
      console.log(
        data.messages.reduce((r, a) => {
          r[dateFormatOnlyDateShort(mapFromAPIDateTime(a.created))] = [...(r[dateFormatOnlyDateShort(mapFromAPIDateTime(a.created))] || []), a];
          return r;
        }, {} as any),
      );
      setMessages(
        data.messages.reduce((r, a) => {
          r[dateFormatOnlyDateShort(mapFromAPIDateTime(a.created))] = [...(r[dateFormatOnlyDateShort(mapFromAPIDateTime(a.created))] || []), a];
          return r;
        }, {} as any),
      );
    });
  };

  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    if (messagesEndRef && messagesEndRef.current) messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <>
      {/*//
      @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal-chat sidebar-modal-lg sidebar-modal-chat-carrier"
        style={{ width: 700 }}
      >
        {/*       <div className="chat chat-carrier">
          <div className="chat-panel">
            <ChatMessagePanelTo customerName={customerName} />
          </div>

          <Chat>
            <ChatMessageWrapper>
              {messages &&
                Object.keys(messages).map((date, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="message-service-date">
                        <small>{date}</small>
                      </div>
                      {messages[date].map((message, messageIndex) => {
                        return (
                          <ChatMessage
                            key={messageIndex}
                            isOtherSide={message.isOtherSide}
                            time={getTime(mapFromAPIDateTime(message.created))}
                            author={message.fullName}
                            text={message.message}
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              <div ref={messagesEndRef} />
            </ChatMessageWrapper>
            <div className="new-message">
              <div className="message-input">
                <InputTextarea
                  autoResize
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
              <div className="message-send">
                <Button
                  icon="pi pi-send"
                  className="p-button-text"
                  onClick={(e) => {
                    caSendMessageV2(companyId, applicationId, { message }, () => {
                      setMessage('');
                      reload();
                    });
                  }}
                />
              </div>
            </div>
          </Chat>
        </div>*/}
        <ThreadPanel entityType={entityType} entityId={entityId} companyId={companyId} readOnly={readOnly} />
      </Sidebar>
    </>
  );
};

export default CarrierChatComponent;
