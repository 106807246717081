import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import Label from '@components/obsolete/Form/Label/Label';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useContext, useEffect, useState } from 'react';
import { LovDTO, LovWithoutIdDTO, UserCompanyUserInDTO } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';

import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import { ApiContext } from '@api/api';
import useForm from '../../../hooks/useForm/useForm';
import InputPickSecurityRole from '@components/obsolete/Form/InputPickSecurityRole/InputPickSecurityRole';
import InputPickSecurityGroup from '@components/obsolete/Form/InputPickSecurityGroup/InputPickSecurityGroup';
import useTranslation from '@hooks/i18n/useTranslation';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { Row } from '@components/styles.tsx';
import { RoleEnum } from '@type/enums.ts';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: () => void;
  userId?: string;
  companyId: string;
}

export const EditUserSidebar: React.FC<ComponentProps> = ({ visible, onHide, onComplete, userId, companyId }) => {
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { tr } = useTranslation();
  const { getCompanyUserDetail, createUserUnderCompany, adminCreateUserUnderCompany, updateUserUnderCompany, lovSecurityRoles } = useContext(ApiContext);
  const [roles, setRoles] = useState<LovWithoutIdDTO[]>();
  const [securityRoles, setSecurityRoles] = useState<LovDTO[]>();

  const doNotValidIfOnlyDriverRole = (isEmail: boolean) => {
    return (roles: any, schema: any) => {
      return roles.includes(RoleEnum.ROLE_DRIVER) && roles.length === 1
        ? schema //do nothing
        : isEmail
        ? yup.string().email().required()
        : yup.string().required(); //required
    };
  };

  const validationScheme = yup.object().shape({
    firstname: yup.string().defined().required(),
    email: yup.string().when('roles', doNotValidIfOnlyDriverRole(true)),
    phone: yup.string().when('roles', doNotValidIfOnlyDriverRole(false)),
    surname: yup.string().defined().required(),
  });

  const form = useForm<UserCompanyUserInDTO>(validationScheme, null, d => save(d), false, false);

  useEffect(() => {
    if (visible) {
      form.clear();
      lovSecurityRoles(data => {
        setSecurityRoles(data);
      });
      if (isNullOrUndefined(userId)) {
        form.setValues({
          firstname: '',
          email: '',
          phone: '',
          surname: '',
          roles: [],
          securityRolesIds: [],
          userId: null,
          securityGroupsIds: [],
          updateUserInAuth0: true,
        } as UserCompanyUserInDTO);
      } else {
        getCompanyUserDetail(userId, data => {
          form.setValues({
            firstname: data.firstname,
            email: data.email,
            phone: data.phone,
            surname: data.surname,
            roles: data.roles,
            securityRolesIds: data.securityRoles.map(r => r.id),
            userId: data.userId,
            securityGroupsIds: data.securityGroups.map(r => r.id),
            updateUserInAuth0: true,
          } as UserCompanyUserInDTO);
        });
      }
    }
  }, [visible]);

  const save = (data: UserCompanyUserInDTO) => {
    if (userId) {
      updateUserUnderCompany(userId, form.values, () => {
        onComplete();
      });
    } else {
      if (companyId != loggedUser.companyId) {
        adminCreateUserUnderCompany(companyId, form.values, () => {
          onComplete();
        });
      } else {
        createUserUnderCompany(form.values, () => {
          onComplete();
        });
      }
    }
  };

  const [locked, setLocked] = useState(true);

  const isLocked = () => {
    return !isNullOrUndefined(userId) && locked;
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} onHide={onHide} position="right" baseZIndex={1000000} style={{ width: '50%' }} dismissable={false} blockScroll={true}>
        <h3 className="p-mb-4">{userId ? tr('EditUserSidebar.editUser', 'Upravit uživatele') : tr('EditUserSidebar.createUser', 'Vytvořit uživatele')}</h3>
        {form.values && (
          <>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.firstName', 'Křestní jméno')}*`} />
              <InputText
                type="text"
                value={form.values.firstname}
                name="firstname"
                onChange={e => {
                  const { name, value } = e.target as HTMLInputElement;
                  form.setFieldValue(form.names.firstname, value);
                }}
              />
              <ValidationDiv errors={form.validationErrors} path={'firstname'} />
            </div>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.surName', 'Příjmení')}*`} />
              <InputText
                type="text"
                value={form.values.surname}
                name="surName"
                onChange={e => {
                  const { name, value } = e.target as HTMLInputElement;
                  form.setFieldValue(form.names.surname, value);
                }}
              />
              <ValidationDiv errors={form.validationErrors} path={'surname'} />
            </div>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.email', 'Email')}*`} />
              <Row>
                <InputText
                  type="text"
                  value={form.values.email}
                  name="email"
                  onChange={e => {
                    const { name, value } = e.target as HTMLInputElement;
                    form.setFieldValue(form.names.email, value);
                  }}
                  disabled={isLocked()}
                />
                {isLocked() && <Button onClick={v => setLocked(false)}>{tr(`EditUserSidebar.odemknout`, `Odemknout`)}</Button>}
              </Row>

              <ValidationDiv errors={form.validationErrors} path={'email'} />
            </div>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.phone', 'Telefon')}*`} />
              <InputText
                type="text"
                value={form.values.phone}
                name="phoneNumber"
                onChange={e => {
                  const { name, value } = e.target as HTMLInputElement;
                  form.setFieldValue(form.names.phone, value);
                }}
              />
              <ValidationDiv errors={form.validationErrors} path={'phone'} />
            </div>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.role', 'Role')}`} />
              Old roles
              {/*<MultiSelect
              value={state.roles?.filter((t) => t !== 'ROLE_ADMIN')}
              options={roles || []}
              onChange={(e) => {
                setState((draft) => {
                  draft.roles = e.value;
                });
              }}
              optionLabel="name"
              optionValue="code"
              disabled={state.isVirtual}
            />*/}
              <ValidationDiv errors={form.validationErrors} path={'roles'} />
            </div>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.securityRoles', 'Security roles')}`} />
              <InputPickSecurityRole value={form.values.securityRolesIds} onChange={v => form.setFieldValue(form.names.securityRolesIds, v)} />
              {/* <ValidationDiv errors={form.validationErrors} path={'roles'} />*/}
            </div>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.securityGroups', 'Security groups')}`} />
              <InputPickSecurityGroup
                value={form.values.securityGroupsIds}
                onChange={v =>
                  form.setFieldValue(
                    form.names.securityGroupsIds,
                    v.map(t => t.id),
                  )
                }
              />
              {/*<ValidationDiv errors={form.validationErrors} path={'securityRolesIds'} />*/}
            </div>
            <div className="p-field p-fluid">
              <Label title={`${tr('EditUserSidebar.updateInAuth0', 'Update external user')}`} />
              <InputYesNoPlain value={form.values.updateUserInAuth0} onChange={v => form.setFieldValue(form.names.updateUserInAuth0, v)} />
              {/*<ValidationDiv errors={form.validationErrors} path={'securityRolesIds'} />*/}
            </div>
            <ButtonLayout>
              <Button label={tr(`EditUserSidebar.save`, `Uložit`)} className="p-mr-2" onClick={() => form.validateAndSend()} />
              <Button label={tr(`EditUserSidebar.cancel`, `Zrušit`)} className="p-button-text" onClick={onHide} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};
