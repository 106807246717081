import React from 'react';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  reservation?: ReservationsDataOutDTO;
}

const CFRCargoItem: React.FC<ComponentProps> = ({ reservation }) => {
  const reducedItems = reservation.orders
    .map(t => t.items)
    .flat(1)
    .reduce((acc, val) => {
      if (acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code) === -1) {
        acc.push({ cargoTemplate: val.cargoTemplate, amount: val.amount });
      } else {
        const index = acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code);
        acc[index].amount += val.amount;
      }
      return acc;
    }, [])
    .map(t => {
      return `${t.amount} x ${t.cargoTemplate.name}`;
    })
    .join(', ');

  return <>{reducedItems}</>;
};

export default CFRCargoItem;
