import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import SupplierNotificationList from '@app/pages/customer/supplier-notification/SupplierNotificationList.tsx';

interface ComponentProps {}

const SupplierNotificationListPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Layout title={tr(`create-shipment.SupplierNotificationListPage`, `Supplier notification list`)}>
        <SupplierNotificationList />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(SupplierNotificationListPage);
