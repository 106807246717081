import React from 'react';
import styled from 'styled-components';
import { Ringil3H3 } from '@components/ringil3/styles';
import { isNotNullOrUndefined } from '@utils/utils';
import EditButton from '@components/ringil3/Buttons/EditButton';

interface ComponentProps {
  icon?: JSX.Element;
  title: string;
  editable: boolean;
  onEditClick?: () => void;
}

const EditableHeading3: React.FC<ComponentProps> = ({ title, icon, editable, onEditClick = () => {} }) => {
  return (
    <RingilRowWithButtons>
      <ButtonRow>
        {isNotNullOrUndefined(icon) && icon}
        <Ringil3H3>{title}</Ringil3H3>
      </ButtonRow>
      {editable && <EditButton onClick={() => onEditClick()} />}
    </RingilRowWithButtons>
  );
};

const RingilRowWithButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: space-between;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export default EditableHeading3;
