import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import { LovDTO, CompanyModuleEnum } from '@api/logsteo-api.v2';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  companyId: string;
  inheriterId: string;
  onChange: (inheriterId: string) => void;
  module: CompanyModuleEnum;
  style?: any;
  placeholder?: string;
  showClear?: boolean;
  readonly?: boolean;
}

/**
 * Entity owner is the paying customer of the functionality or the inherited party that creates the entity for the paying customer
 * @param inheriterId - company that inherites the permission
 * @param onChange
 * @param style
 * @constructor
 */
const InputInheriterCompany: React.FC<ComponentProps> = ({ inheriterId, module, companyId, onChange, style, placeholder, showClear = false, readonly = false }) => {
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [options, setOptions] = useState<LovDTO[]>([]);
  const { lovAllCompaniesByInheritModule } = useContext(ApiContext);

  useEffect(() => {
    if (loggedUser) {
      if (companyId) {
        if (amIOwner) {
          lovAllCompaniesByInheritModule(module, d => {
            setOptions(d);
          });
        } else {
          setOptions([
            {
              id: loggedUser.companyId,
              name: loggedUser.company,
              active: true,
            },
          ]);
          onChange(loggedUser.companyId);
        }
      } else {
        setOptions([]);
      }
    }
  }, [companyId, loggedUser]);

  const amIOwner = companyId === loggedUser?.companyId;
  return (
    <>
      {loggedUser && (
        <Dropdown
          style={style}
          value={inheriterId}
          options={options}
          optionLabel={'name'}
          optionValue={'id'}
          placeholder={placeholder}
          disabled={readonly}
          showClear={showClear}
          onChange={e => {
            onChange(e.value);
          }}
        />
      )}
    </>
  );
};

export default InputInheriterCompany;
