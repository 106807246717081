import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dropdown } from 'primereact/dropdown';
import useDispatchers from '@hooks/dispatchers/dispatchers';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  expeditionId: string;
}

export const InputDispatcher: React.FC<ComponentProps> = ({ value, onChange, expeditionId, placeholder }) => {
  const dispatchers = useDispatchers(expeditionId);
  const { tr } = useTranslationLgs();

  return (
    <>
      <Dropdown
        value={value}
        options={dispatchers}
        dataKey={'id'}
        onChange={e => onChange(e.value)}
        optionLabel="name"
        optionValue="id"
        placeholder={tr('InputDispatcher.choose', 'Vyberte')}
      />
    </>
  );
};
