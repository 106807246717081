import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRepeatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#616061"
      d="m6 7 1-1-2.293-2.293.586-.586a3.83 3.83 0 0 1 5.414 0l.086.086A4.12 4.12 0 0 1 12 6.121V7h2v-.879c0-1.623-.645-3.18-1.793-4.328l-.086-.086a5.83 5.83 0 0 0-8.242 0l-.586.586L1 0 0 1v6zM10.707 12.879a3.83 3.83 0 0 1-5.414 0l-.086-.086A4.12 4.12 0 0 1 4 9.879V9H2v.879c0 1.623.645 3.18 1.793 4.328l.086.086a5.83 5.83 0 0 0 8.242 0l.586-.586L15 16l1-1V9h-6l-1 1 2.293 2.293z"
    />
  </svg>
);
export default SvgRepeatIcon;
