import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import * as yup from 'yup';
import CalendarInput from '@pages/-[lang]/test/newCalendar.tsx';
import { dumpVars } from '@utils/utils.tsx';

interface ComponentProps {}

const CalendarPage: React.FC<ComponentProps> = () => {
  const [s1, setS1] = useState(null);
  const [s2, setS2] = useState(null);
  const [s3, setS3] = useState(null);

  return (
    <>
      <div className={'flex flex-row gap-6'}>
        <CalendarInput enableRange={true} includeTime={false} currentLocalDate={s1} onChange={v => setS1(v)} />
        <CalendarInput enableRange={false} includeTime={false} currentLocalDate={s2} onChange={v => setS2(v)} />
        <CalendarInput enableRange={false} includeTime={true} currentLocalDate={s3} onChange={v => setS3(v)} />
      </div>
      {dumpVars({ s1, s2, s3 })}
    </>
  );
};

export default CalendarPage;
