import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import useForm from '@hooks/useForm/useForm';
import { CarrierGroupInDTO } from '@api/logsteo-api.v2';
import { Button } from 'primereact/button';
import { RingilH2 } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { SelectCarriers } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  onCompleted: () => void;
  groupDemandId: string;
}

interface FormData {
  selectedCarriersAndGroups: CarrierGroupInDTO[];
}
export const useCreateAddMoreCarrierMDDialog = () => {
  const [visible, setVisible] = useState(false);

  const AddMoreCarrierMDDialog: React.FC<ComponentProps> = ({ groupDemandId, onCompleted }) => {
    const { tr } = useTranslationLgs();
    const { cuAddMoreCarriersToGDDistribution } = useContext(ApiContext);

    const form = useForm<FormData>(yup.object().shape({}), { selectedCarriersAndGroups: [] }, data => save(data), true, false);

    const save = (data: FormData) => {
      cuAddMoreCarriersToGDDistribution(
        groupDemandId,
        {
          selectedItems: form.values.selectedCarriersAndGroups,
        },
        () => {
          setVisible(false);
          onCompleted();
        },
      );
    };
    return (
      <>
        {/*
        // @ts-ignore*/}
        <Dialog onHide={() => setVisible(false)} visible={visible}>
          <RingilH2>{tr(`AddMoreCarrierMDDialog.addAnotherCarrier`, `Add another carrier`)}</RingilH2>
          <HorizontalFieldValue
            label={tr(`AddMoreCarrierMDDialog.chooseCarrierOrGroup`, `Choose carrier or group`)}
            value={
              <SelectCarriers
                value={form.values.selectedCarriersAndGroups}
                onSelected={v => form.setFieldValue('selectedCarriersAndGroups', v)}
                onlyCarriers={false}
                isDisabled={false}
                placeholder={''}
              />
            }
          ></HorizontalFieldValue>

          <ButtonLayout wrapper="WITH_PADDING_TOP_0">
            <Button label={tr(`AddMoreCarrierMDDialog.confirm`, `Potvrdit`)} className="p-mr-3" onClick={() => form.validateAndSend()} />
            <Button label={tr(`AddMoreCarrierMDDialog.cancel`, `Zrušit`)} className="p-button-outlined p-button-text" onClick={() => setVisible(false)} />
          </ButtonLayout>
        </Dialog>
      </>
    );
  };

  return { AddMoreCarrierMDDialog, setVisible };
};
