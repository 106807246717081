import React from 'react';

interface Location {
  locationName: string;
  streetNr: string;
  zipCode: string;
  country: string;
  city: string;
}

interface ComponentProps {
  location: Location;
  locationDisplayPattern: string;
}

export enum DisplayLocationRenderMode {
  NAME_STREET_CITY_ZIP_COUNTRY = 'FULL_NAME_STREET_CITY_ZIP_COUNTRY',
  ZIP_CITY_COUNTRY = 'ZIP_CITY_COUNTRY',
}

const DisplayLocation: React.FC<ComponentProps> = ({ location, locationDisplayPattern }) => {
  switch (locationDisplayPattern) {
    case DisplayLocationRenderMode.NAME_STREET_CITY_ZIP_COUNTRY:
      return <>{[location.locationName, location.streetNr, location.city, location.zipCode, location.country].filter(t => t !== null).join(', ')}</>;
      break;
    default:
      return <>{[location.zipCode, location.city, location.country].filter(t => t !== null).join(', ')}</>;
      break;
  }
};

export default DisplayLocation;
