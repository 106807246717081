import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Sidebar } from 'primereact/sidebar';
import useForm from '@hooks/useForm/useForm';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import {
  InternalInfoPanelForm,
  internalInfoPanelInitialData,
  internalInfoPanelValidationSchema,
  InternetInformationFormData,
} from '@app/pages/customer/expedition-detail/InternalInfoTab/InternalInfoPanelForm.tsx';
import InfoNote from '@components/obsolete/InfoNote/InfoNote.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ExpeditionInfo {
  applicationId: string;
  expeditionId: string;
}

interface ComponentProps {
  onComplete: () => void;
  selectedExpeditions: ExpeditionInfo[];
}

export const useMAExpUpdIntInformation = () => {
  const [visible, setVisible] = useState(false);
  const [changeFields, setChangeFields] = useState({});

  const setVisibleMAExpeditionIntInfo = (v: boolean) => {
    setVisible(v);
  };

  const MassActionExpeditionUpdateInternalInformation: React.FC<ComponentProps> = ({ selectedExpeditions, onComplete }) => {
    const { tr } = useTranslationLgs();
    const { updateMassActionInternalInformation } = useContext(ApiContext);

    const form = useForm<InternetInformationFormData>(
      internalInfoPanelValidationSchema,
      internalInfoPanelInitialData,
      d => {
        save(d);
      },
      true,
      false,
    );

    const save = (data: InternetInformationFormData) => {
      updateMassActionInternalInformation(
        {
          expeditionIds: selectedExpeditions.map(t => t.expeditionId),
          internalData: {
            internalNote: data.internalNote,
            responsiblePersonId: data.responsiblePersonId,
            invoiceDepartment: data.invoiceDepartment,
            invoicePartnerId: data.invoicePartnerId,
            invoiceRebillingType: data.invoiceRebillingType,
            invoiceState: data.invoiceState,
            invoiceUserId: data.invoiceUserId,
            subscribersIds: data.subscribersIds || [],
            overwriteInternalNote: data.overwriteInternalNote,
            overwriteSubscribersIds: data.overwriteSubscribersIds,
            overwriteResponsiblePersonId: data.overwriteResponsiblePersonId,
            overwriteInvoiceState: data.overwriteInvoiceState,
            overwriteInvoiceRebillingType: data.overwriteInvoiceRebillingType,
            overwriteInvoiceUserId: data.overwriteInvoiceUserId,
          },
        },
        () => {
          setVisible(false);
          onComplete();
        },
      );
    };

    return (
      <>
        {/*// @ts-ignore*/}
        <Sidebar
          onHide={() => setVisible(false)}
          visible={visible}
          closeOnEscape={false}
          blockScroll={true}
          position={'right'}
          style={{ width: 'var(--default-sidebar-width)' }}
        >
          <SidebarContent>
            <h2>{tr(`MAExpUpdIntInformation.bulkUpdateInternalInformation`, `Bulk update of internal information`)}</h2>

            <InfoNote
              text={tr(`MAExpUpdIntInformation.infoNote1`, `Zaškrtněte, jaké hodnoty chcete hromadně změnit. U nezaškrtnutých položek zůstanou údaje nezměněny.`)}
            />

            <div className={'p-mb-2'}>
              <HorizontalFieldValue
                label={tr(`MAExpUpdIntInformation.selectedExpeditions`, `Selected expeditions`)}
                value={selectedExpeditions.map(t => t.applicationId).join(', ')}
              />
            </div>

            <InternalInfoPanelForm setFieldValue={form.setFieldValue} values={form.values} errors={form.errors} />

            <ButtonLayout wrapper={'WITH_PADDING_TOP_4'}>
              <Button label={tr(`MAExpUpdIntInformation.ulozit`, `Ulozit`)} className="p-mr-2" onClick={() => form.validateAndSend()} />
              <Button label={tr(`MAExpUpdIntInformation.cancel`, `Zrušit`)} className="p-button-text" onClick={e => setVisible(false)} />
            </ButtonLayout>
          </SidebarContent>
        </Sidebar>
      </>
    );
  };

  return {
    MassActionExpeditionUpdateInternalInformation,
    setVisibleMAExpeditionIntInfo,
  };
};

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;
