import React from 'react';
import { SelectButton } from 'primereact/selectbutton';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  value: boolean;
  onChange: (value: boolean) => void;
  style?: any;
  disabled?: boolean;
}

const InputYesNoPlain: React.FC<ComponentProps> = ({ value, onChange, style, disabled = false }) => {
  const { tr } = useTranslationLgs();

  const model = [
    {
      name: tr('InputYesNoPlain.yes', 'Yes'),
      value: true,
    },
    {
      name: tr('InputYesNoPlain.no', 'No'),
      value: false,
    },
  ];
  return (
    <>
      <SelectButton
        options={model}
        optionValue={'value'}
        optionLabel={'name'}
        value={value}
        disabled={disabled}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
    </>
  );
};

export default InputYesNoPlain;
