import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCutReservation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={16} fill="none" {...props}>
    <path
      fill="#616061"
      fillRule="evenodd"
      d="M5.121.879c1 .999 1.147 2.528.441 3.683L15 14l-1 1h-3l-4.5-4.5-.938.938A3.001 3.001 0 0 1 3 16h-.172a2.828 2.828 0 0 1-2-4.828L4 8 .828 4.828a2.828 2.828 0 0 1 2-4.828H3a3 3 0 0 1 2.121.879M2 3a1 1 0 1 0 2 0 1 1 0 0 0-2 0m1 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
      clipRule="evenodd"
    />
    <path fill="#616061" d="M11 1 8 4l2.5 2.5L15 2l-1-1z" />
  </svg>
);
export default SvgCutReservation;
