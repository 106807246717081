import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import { DetailDAPSNResponseOut, EntityTypeEnum, FeaturesEnum, SupplierNotificationTypeEnum, TypeEnum } from '@api/logsteo-api.v2.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { formatLocationNotino, formatPieces, formatWeight, isBlank, isNullOrUndefined } from '@utils/utils.tsx';
import Heading from '@components/ringil3/Heading/Heading.tsx';
import LabelsTag from '@components/ringil3/Tags/LabelsTag.tsx';
import CreatedBadge from '@components/ringil3/Badges/CreatedBadge.tsx';
import UpdatedBadge from '@components/ringil3/Badges/UpdatedBadge.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import SenderBadge from '@components/ringil3/Badges/SenderBadge.tsx';
import RecieverBadge from '@components/ringil3/Badges/RecieverBadge.tsx';
import TransportationTypeTag from '@components/features/tags/TransportationTypeTag.tsx';
import ShipmentNumberTag from '@app/pages/dap/tags/ShipmentNumberTag.tsx';
import CarrierNameTag from '@app/pages/dap/tags/CarrierNameTag.tsx';
import SNTypeBadge from '@app/pages/dap/badges/SNTypeBadge.tsx';
import { useCreateDAPTabs } from '@app/pages/dap/tags/useCreateDapTabs.tsx';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SvgReservation from '@components/ringil3/icons/heading/Reservation.tsx';
import { Card } from '@components/ringil3/styles.tsx';
import { ColWithGap, ColWithSmallerGap, Label } from '@components/styles.tsx';
import EditableHeading3 from '@components/ringil3/Heading/EditableHeading3.tsx';
import SvgMappoint from '@components/ringil3/icons/Mappoint.tsx';
import SvgCalendar from '@components/ringil3/icons/Calendar.tsx';
import SvgReservationManipulation from '@components/ringil3/icons/ReservationManipulation.tsx';
import RingilOrder from '@components/ringil3/RingilOrder/RingilOrder.tsx';
import { printDeliverySlots } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import { useCreateEditDAPOrdersSidebar } from '@app/pages/dap/sidebar/EditDAPOrdersSidebar.tsx';
import SvgIconNote from '@app/icons/heading/IconNote.tsx';
import { useCreateEditDAPNoteSidebar } from '@app/pages/dap/sidebar/EditDAPNoteSidebar.tsx';
import { useCreateEditDAPSupplierSidebar } from '@app/pages/dap/sidebar/EditDAPSupplierSidebar.tsx';
import { useCreateEditDAPCustomerSidebar } from '@app/pages/dap/sidebar/EditDAPCustomerSidebar.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import ResponsiblePersonTag from '@components/features/tags/ResponsiblePersonTag.tsx';
import InternalInfoNotifyTag from '@components/features/tags/InternalInfoNotifyTag.tsx';
import InternalInfoNotifyByRoleTag from '@components/features/tags/InternalInfoNotifyByRoleTag.tsx';
import { RoleEnum } from '@type/enums.ts';
import InternalInfoGoodsInvoiceTag from '@components/features/tags/InternalInfoGoodsInvoiceTag.tsx';
import InternalNoteTag from '@components/features/tags/InternalNoteTag.tsx';
import Badge from '@components/framework/badge/Badge.tsx';
import CustomsDetail from '@app/pages/Customs/CustomsDetail.tsx';

interface ComponentProps {
  supplierNotificationId: string;
}

const SupplierNotificationDetailDap: React.FC<ComponentProps> = ({ supplierNotificationId }) => {
  const {
    detailDAPSupplierNotification,
    updateTransportTypeDAPSupplierNotification,
    updateShipmentNumberDAPSupplierNotification,
    updateCarrierNameDAPSupplierNotification,
    updateResponsiblePersonDAPSupplierNotification,
    updateNotifyUsersDAPSupplierNotification,
    updateNotifyByRoleDAPSupplierNotification,
    updateInternalInvoiceDAPSupplierNotification,
    updateInternalNoteDAPSupplierNotification,
  } = useContext(ApiContext);
  const [data, setData] = useState<DetailDAPSNResponseOut>();
  const nav = useNavigation();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const abortControllerRef = useRef<AbortController | null>(null);
  const { DapTables, selectedPanel } = useCreateDAPTabs();
  const { load: loadAttachments, AttachmentComponent } = useCreateAttachmentComponent(false);
  const { visible, setVisible, EditDAPOrdersSidebar } = useCreateEditDAPOrdersSidebar();
  const { setVisible: setEditNoteVisible, EditDAPNoteSidebar, visible: visibleEditNote } = useCreateEditDAPNoteSidebar();
  const { setVisible: setVisibleSupplier, EditDAPSupplierSidebar, visible: visibleSupplier } = useCreateEditDAPSupplierSidebar();
  const { setVisible: setVisibleCustomer, EditDAPCustomerSidebar, visible: visibleCustomer } = useCreateEditDAPCustomerSidebar();

  const load = () => {
    detailDAPSupplierNotification(supplierNotificationId, data => {
      if (abortControllerRef.current.signal.aborted) return;
      setData(data);
    });
    loadAttachments(EntityTypeEnum.SUPPLIER_NOTIFICATION, supplierNotificationId);
  };

  useEffect(() => {
    const ac = new AbortController();
    abortControllerRef.current = ac;
    load();
    return () => {
      abortControllerRef.current.abort();
    };
  }, [supplierNotificationId]);

  const { tr } = useTranslationLgs();

  if (isNullOrUndefined(data) || isNullOrUndefined(loggedUser)) return <></>;
  const isOwner = loggedUser.companyId === data?.customerCompany.id;

  return (
    <div className={'flex flex-col gap-2'}>
      <BreadCrumbComponent
        items={[
          {
            label: tr('SupplierNotificationDetailDap.list', 'Avizace'),
            url: nav.createNavigationLink(nav.urlFunctions.createSupplierNotificationList()),
          },
          { label: data.applicationId, url: null },
        ]}
      />

      <Heading
        title={data.applicationId}
        headingVariant={'h1'}
        labels={
          <>{isOwner && <LabelsTag objectId={data.id} objectType={EntityTypeEnum.SUPPLIER_NOTIFICATION} resolveLabelsForType={EntityTypeEnum.SUPPLIER_NOTIFICATION} />}</>
        }
        buttons={[]}
      />

      <div className={'flex flex-row gap-2'}>
        <SNTypeBadge snType={SupplierNotificationTypeEnum.DAP} />
        <SenderBadge companyName={data.supplierCompany.name} />
        <RecieverBadge companyName={data.customerCompany.name} />
        <Badge value={formatWeight(data.totalWeight)} />
        <Badge
          value={formatPieces(
            data.orders
              ?.map(t => t.items)
              .flat(1)
              .map(t => t.amount)
              .filter(t => t != null)
              .reduce((previousValue, currentValue) => {
                return previousValue + currentValue;
              }, 0),
            tr,
          )}
        />

        <CreatedBadge createdAt={data.createdAt} createdBy={data.createdBy?.fullName} />
        <UpdatedBadge updatedAt={data.updatedAt} updatedBy={data.updatedBy?.fullName} />
      </div>
      <div className={'flex flex-row gap-2'}>
        <TransportationTypeTag
          transportationType={data.transportationType}
          onChange={v => {
            updateTransportTypeDAPSupplierNotification(supplierNotificationId, { transportationType: v }, () => {
              load();
            });
          }}
        />

        <ShipmentNumberTag
          value={data.shipmentNumber}
          onChange={v => {
            updateShipmentNumberDAPSupplierNotification(supplierNotificationId, { shipmentNumber: v }, () => {
              load();
            });
          }}
        />

        <CarrierNameTag
          value={data.carrierName}
          onChange={v => {
            updateCarrierNameDAPSupplierNotification(supplierNotificationId, { carrierName: v }, () => {
              load();
            });
          }}
        />
      </div>
      <DapTables isOwner={isOwner} showCustoms={loggedUser?.features?.some(t => t == FeaturesEnum.CUSTOMS && isOwner === true)} />

      {selectedPanel === 'detail' && (
        <div className={'flex flex-col gap-4 p-4'}>
          <COHeadline variant={'h2'} title={tr('SupplierNotificationDetailDap.oAvizaci', 'O avizaci')} icon={<SvgReservation />}></COHeadline>
          <div className={'grid grid-cols-2 gap-4'}>
            <div className={'flex flex-col gap-4'}>
              <Card>
                <ColWithGap>
                  <EditableHeading3
                    editable={true}
                    title={tr('SupplierNotificationDetailDap.adresaNakladky', 'Adresa nakládky')}
                    icon={<SvgMappoint />}
                    onEditClick={() => {
                      setVisibleSupplier(true);
                    }}></EditableHeading3>
                  <ColWithSmallerGap>
                    <Label>{data.supplierCompany.name}</Label>
                    {formatLocationNotino(data.supplierLocation.zipCode, data.supplierLocation.city, data.supplierLocation.country, data.supplierLocation.streetNr)}
                  </ColWithSmallerGap>
                  <EditableHeading3
                    editable={false}
                    title={tr('SupplierNotificationDetailDap.datumVyzvednuti', 'Datum vyzvednutí')}
                    icon={<SvgCalendar />}></EditableHeading3>
                  <ColWithSmallerGap>{printDeliverySlots(data.supplierDeliverySlots, tr)}</ColWithSmallerGap>

                  {isBlank(data.note) ? (
                    <LinkWithIcon
                      label={tr('SupplierNotificationDetailDap.poznamkaKOdesilateli', 'Poznámka k odesílateli')}
                      icon={<SvgIconPlus />}
                      onClick={() => setEditNoteVisible(true)}
                    />
                  ) : (
                    <>
                      <EditableHeading3
                        editable={true}
                        title={tr('SupplierNotificationDetailDap.poznamkaKOdesilateli', 'Poznámka k odesílateli')}
                        icon={<SvgIconNote />}
                        onEditClick={() => {
                          setEditNoteVisible(true);
                        }}></EditableHeading3>
                      {data.note}
                    </>
                  )}
                </ColWithGap>
              </Card>
              <Card>
                <ColWithGap>
                  <EditableHeading3
                    editable={true}
                    title={tr('SupplierNotificationDetailDap.adresaVykladky', 'Adresa vykládky')}
                    icon={<SvgMappoint />}
                    onEditClick={() => {
                      setVisibleCustomer(true);
                    }}></EditableHeading3>
                  <ColWithSmallerGap>
                    <Label>{data.customerCompany.name}</Label>
                    {formatLocationNotino(data.customerLocation.zipCode, data.customerLocation.city, data.customerLocation.country, data.customerLocation.streetNr)}
                  </ColWithSmallerGap>
                  <EditableHeading3 editable={false} title={tr('SupplierNotificationDetailDap.datumDodani', 'Datum dodání')} icon={<SvgCalendar />}></EditableHeading3>
                  <ColWithSmallerGap>{printDeliverySlots(data.customerDeliverySlots, tr)}</ColWithSmallerGap>
                </ColWithGap>
              </Card>
            </div>
            <Card>
              <EditableHeading3
                editable={true}
                title={tr(`ReservationDetail.manipulation`, `Manipulation`)}
                icon={<SvgReservationManipulation />}
                onEditClick={() => {
                  setVisible(true);
                }}></EditableHeading3>
              <>
                <ColWithGap>
                  {data.orders.map((order, orderIndex) => {
                    return <RingilOrder key={orderIndex} type={'neutral'} order={order} />;
                  })}
                </ColWithGap>
              </>
            </Card>
          </div>
        </div>
      )}
      {selectedPanel === 'customs' && <>{isOwner && <CustomsDetail supplierNotificationId={data.id} />}</>}
      {selectedPanel === 'internalinfo' && (
        <div className={'flex flex-col gap-4'}>
          <div className={'flex flex-row gap-4'}>
            <ResponsiblePersonTag
              responsiblePerson={data.responsiblePerson}
              onChange={v => {
                updateResponsiblePersonDAPSupplierNotification(supplierNotificationId, { responsiblePersonId: v.id }, () => {
                  load();
                });
              }}
            />

            <InternalInfoNotifyTag
              responsiblePersons={data.usersToNotify}
              onChange={v => {
                updateNotifyUsersDAPSupplierNotification(supplierNotificationId, { userIdsToNotify: v.map(t => t.id) }, () => {
                  load();
                });
              }}
            />

            <InternalInfoNotifyByRoleTag
              notifyRoleName={RoleEnum.ROLE_SN_NOTIFY}
              isActive={data.notifyUsersDependsOnRole}
              onChange={v => {
                updateNotifyByRoleDAPSupplierNotification(supplierNotificationId, { notifyUsersDependsOnRole: v }, () => {
                  load();
                });
              }}
            />
          </div>
          <div className={'flex flex-row gap-4'}>
            <InternalInfoGoodsInvoiceTag
              value={
                data.internalInvoiceNumber
                  ? {
                      goodsInvoiceNumber: data.internalInvoiceNumber,
                      goodsInvoiceDate: data.invoiceDueDate,
                    }
                  : null
              }
              onChange={v => {
                updateInternalInvoiceDAPSupplierNotification(
                  supplierNotificationId,
                  {
                    internalInvoiceNumber: v.goodsInvoiceNumber,
                    invoiceDueDate: v.goodsInvoiceDate,
                  },
                  () => {
                    load();
                  },
                );
              }}
            />

            <InternalNoteTag
              value={data.internalNote}
              onChange={v => {
                updateInternalNoteDAPSupplierNotification(supplierNotificationId, { internalNote: v }, () => {
                  load();
                });
              }}
              readonly={false}
              required={false}
            />
          </div>
        </div>
      )}
      {selectedPanel === 'attachments' && (
        <>
          <AttachmentComponent entityId={data.id} entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION} />
        </>
      )}
      {visible && (
        <EditDAPOrdersSidebar
          supplierNotificationId={supplierNotificationId}
          applicationId={data.applicationId}
          onSaved={() => {
            load();
          }}
        />
      )}
      {visibleEditNote && (
        <EditDAPNoteSidebar
          applicationId={data.applicationId}
          onSaved={() => {
            load();
          }}
          supplierNotificationId={supplierNotificationId}
        />
      )}
      {visibleSupplier && (
        <EditDAPSupplierSidebar
          supplierNotificationId={supplierNotificationId}
          applicationId={data.applicationId}
          companyId={data.customerCompany.id}
          onSaved={() => {
            load();
          }}
        />
      )}

      {visibleCustomer && (
        <EditDAPCustomerSidebar
          supplierNotificationId={supplierNotificationId}
          applicationId={data.applicationId}
          companyId={data.customerCompany.id}
          onSaved={() => {
            load();
          }}
        />
      )}
    </div>
  );
};

export default SupplierNotificationDetailDap;
