import clsx from 'clsx';

interface ComponentProps {
  nameIcon?: string;
  srcIcon?: string;
  numberIcon?: string;
  colorIcon?: string;
  children?: any;
  wrapper?: string;
}
const PanelWithIcon: React.FC<ComponentProps> = ({ srcIcon, nameIcon, numberIcon, colorIcon, children, wrapper }) => {
  const className = clsx({
    '': colorIcon === undefined,
    'text-orange': colorIcon === 'orange',
    'text-green': colorIcon === 'green',
  });

  const wrapperClassName = clsx('panel-with-icon', {
    '': wrapper === undefined,
    'p-ai-center': wrapper === 'VERTICAL_ALIGNMENT',
    'panel-with-icon--sm': wrapper === 'SM',
    'p-mb-0': wrapper === 'PADDING_BOTTOM_0',
  });

  return (
    <>
      <div className={wrapperClassName}>
        {srcIcon && (
          <div className="icon-img">
            <img src={srcIcon} alt="" />
          </div>
        )}

        {nameIcon && (
          <div className="icon">
            <i className={`${nameIcon} ${className}`}></i>
          </div>
        )}

        {numberIcon && (
          <div className="icon-number">
            <span>{numberIcon}</span>
          </div>
        )}

        <div className="panel-with-icon__description">{children}</div>
      </div>
    </>
  );
};

export default PanelWithIcon;
