import { useId } from 'react';

interface ComponentProps {
  title?: string | object;
  subTitle?: string | object;
  style?: any;
  required?: boolean;
}

const Label: React.FC<ComponentProps> = ({ title, subTitle, style, required }) => {
  const uniqID = useId().replace(/:/g, '');

  return (
    <div className="label-panel p-mb-2" style={style}>
      <label className="p-d-block" htmlFor={uniqID}>
        <strong>{`${title}${required && !subTitle ? '*' : ''}`}</strong>
        {subTitle && <span className="sub-label p-ml-2">{`${subTitle}${required && subTitle ? '*' : ''}`}</span>}
      </label>
    </div>
  );
};

export default Label;
