import styled, { css } from 'styled-components';

export const Ringil3H1 = styled.h1`
  color: var(--ringil3-gr4);
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  unicode-bidi: isolate;
`;

export const Ringil3H2 = styled.h2`
  color: black;
  font-size: 1.375rem;
  font-weight: 400;
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  unicode-bidi: isolate;
`;

export const Ringil3H3 = styled.div`
  color: #343333;
  font-size: 1rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Ringil3BadgeRow = styled.div`
  flex-direction: row;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
`;

export const Panel = styled.div`
  border-radius: 8px;
  background-color: var(--ringil3-base-color);
  margin: 0.5rem 0;
  padding: 1rem 1rem;
  flex: 1;
`;

export const Paragraph = styled.p`
  font-size: 15px;
  color: var(--ringil3-gr4);
`;

export const Card = styled.div`
  border-radius: 1rem;
  background-color: var(--ringil3-base-secondary-color);
  padding: 1.5rem 1rem;
  width: 100%;
`;

export const GrayText = styled.div`
  color: var(--ringil3-gr4);
`;

export const LargeText = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20;
  color: #000000;
`;

export const HorizontalLine = styled.hr<{
  variant: 'brand-light' | 'normal' | 'tertiary' | 'quaternary';
}>`
  width: 100%;
  height: 1px;
  margin: 0rem 0;
  border: 0px;

  ${props =>
    props.variant === 'normal' &&
    css`
      background-color: var(--ringil3-gr4);
    `}

  ${props =>
    props.variant === 'brand-light' &&
    css`
      background-color: var(--ringil3-brand-light-color);
    `}
    
  ${props =>
    props.variant === 'tertiary' &&
    css`
      background-color: var(--ringil3-content-tertiary-color);
    `}

  ${props =>
    props.variant === 'tertiary' &&
    css`
      background-color: var(--ringil3-content-quaternary);
    `}
`;

export const ClickableCursor = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const Clickable = styled.div`
  display: inline-block;
  cursor: pointer;
  color: var(--ringil3-brand-color);
`;

export const ClickableLink = styled.a`
  display: inline-block;
  cursor: pointer;
  color: var(--ringil3-brand-color);
`;

export const Ringil3Note = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--ringil3-gr4);
`;

export const InputAndButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
`;

/**
 * Used for new pages - should be refactored
 */
export const Test = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: black;
  gap: 1rem;

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--ringil3-brand-color);
    background: var(--ringil3-brand-color);
  }
`;

export const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const OrderWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 1rem;

  margin: 0 -1rem;
  padding: 1rem;
`;

export const ColOdd = styled.div`
  display: flex;
  flex-direction: column;

  ${OrderWrapper}:nth-child(odd) {
    background-color: var(--base-basequaternary);
  }
`;

export const TwoColGridWithoutGap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Anchor = styled.div`
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
`;

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Heading = styled.h2`
  line-height: 24px;
  margin: auto 0 auto 0;
`;

export const BlueIcon = styled.i`
  margin: auto auto;
  padding: 0 1rem 0 0;
  color: var(--ringil3-brand-color);
`;
export const HeaderWithButtonText = styled.h1``;

export const SectionHeading = styled.h2`
  display: flex;
`;

export const ButtonRow = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
`;

export const ButtonRowNoWrap = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  white-space: nowrap;
`;

export const CenteredButtonRow = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`;

export const Hr = styled.hr`
  margin-top: 1rem;
  width: 100%;
`;

export const Label = styled.strong`
  color: #495057;
  font-size: 1rem;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  flex-wrap: nowrap;
`;
export const Value = styled.div`
  width: 100%;
  margin-top: 0.3rem;
  flex-grow: 1;
`;

export const RingilHeading = styled.h1`
  font-size: 1.5rem;
`;

export const RingilH2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const RingilH3 = styled.h3`
  font-size: 1.165rem;
`;

export const RingilLabel = styled.h1`
  font-size: 1rem;
`;

export const Row = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
`;

export const RowWithMargin = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
  margin: 1rem 0;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColWithGap = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
  flex-direction: column;
`;
export const ColWithSmallerGap = styled.div`
  display: flex;
  gap: var(--ringil-red-gap);
  flex-direction: column;
`;

export const CenteredRowWithGap = styled.div`
  display: flex;
  column-gap: var(--ringil-cyan-gap);
  align-items: center;
`;

export const CenteredColWithGap = styled.div`
  display: flex;
  column-gap: var(--ringil-cyan-gap);
  align-items: center;
  flex-direction: column;
`;

export const RowWithGap = styled.div`
  display: flex;
  column-gap: var(--ringil-cyan-gap);
  align-items: center;
`;

export const RowWithIcon = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
  align-items: center;
`;

export const RowWithSpaceBetween = styled.div<{
  alignItems?: string;
  margin?: string;
}>`
  display: flex;
  gap: var(--ringil-cyan-gap);
  align-items: ${props => props.alignItems || 'center'};
  justify-content: space-between;
  white-space: nowrap;
  ${props =>
    props &&
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const RingilSectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--ringil-base-tertiary-color);
  padding: 1rem;
  margin: 0.5rem -1rem;
  container-type: inline-size;
`;

export const RingilSubsectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0.5rem -1rem;
  background-color: #e9ecef;
`;

export const Note = styled.div`
  display: flex;
  font-size: small;
  color: gray;
`;

export const WithGap = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const NoWrapText = styled.div`
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const RowForTagsAndBadges = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--ringil-cyan-gap);
  justify-content: space-between;
  align-items: center;
`;
