import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import AdminI18nTemplatesList from '@components/pages/admin/templates/AdminI18nTemplatesList';

interface ComponentProps {}

const TemplatingPage: React.FC<ComponentProps> = () => {
  return (
    <>
      <AdminLayout title="Templating settings">
        <AdminI18nTemplatesList />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(TemplatingPage);
