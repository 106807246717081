import React from 'react';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  reservation?: ReservationsDataOutDTO;
}

const CFRApplicationId: React.FC<ComponentProps> = ({ reservation }) => {
  return (
    <>
      <div>{reservation.reservationApplicationId}</div>
    </>
  );
};

export default CFRApplicationId;
