import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import { LovCompanyLocationWithOpeningHours, CompanyModuleEnum } from '@api/logsteo-api.v2';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  companyId: string;
  companyLocationId: string;
  onChange: (companyLocation: LovCompanyLocationWithOpeningHours) => void;
  style?: any;
  moduleName: CompanyModuleEnum;
  disabled?: boolean;
  showOnlyWithRamp?: boolean;
}

const InputCompanyLocation: React.FC<ComponentProps> = ({ companyLocationId, companyId, onChange, moduleName, style, disabled = false, showOnlyWithRamp = false }) => {
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [options, setOptions] = useState<LovCompanyLocationWithOpeningHours[]>([]);
  const { lovGetAllLocationsForCompanyId } = useContext(ApiContext);

  useEffect(() => {
    if (companyId) {
      lovGetAllLocationsForCompanyId('', showOnlyWithRamp, moduleName, companyId, d => {
        setOptions(d);
        if (companyLocationId) {
          if (d.find(t => t.id === companyLocationId) == null) {
            onChange(null);
          }
        }
      });
    }
  }, [companyId]);

  return (
    <>
      {loggedUser && (
        <Dropdown
          style={style}
          value={{ id: companyLocationId }}
          options={options}
          optionLabel={'name'}
          dataKey={'id'}
          onChange={e => {
            onChange(e.value);
          }}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default InputCompanyLocation;
