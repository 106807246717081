import * as yup from 'yup';

export interface Order {
  orderName: string;
  items: OrderItem[];
  orderNote?: string;
  orderValue?: number;
  orderValueCurrency?: string;
  orderAmount: number;
  orderDescription: string;
}

export interface OrderItem {
  amount: number;
  skuCode: string;
  stackable: boolean;
  itemNote?: string;
  height?: number;
  width?: number;
  length?: number;
  weight?: number;
}

export const emptyOrder = (addItem: boolean): Order => ({
  orderName: '',
  items: addItem ? [{ amount: null, skuCode: null } as OrderItem] : [],
  orderAmount: null,
  orderDescription: null,
});

export const orderSchema = yup.array().of(
  yup.object().shape({
    orderName: yup.string().required().min(1),
    items: yup.array().of(
      yup.object().shape({
        skuCode: yup.string().required(),
        amount: yup.number().required(),
      }),
    ),
  }),
);
