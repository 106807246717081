import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
  text: string;
}

const InfoNote: React.FC<ComponentProps> = ({ text }) => {
  return (
    <>
      <InfoNoteWrapper>
        <span className={'pi pi-info-circle'}></span>
        <span>{text}</span>
      </InfoNoteWrapper>
    </>
  );
};

const InfoNoteWrapper = styled.div`
  display: flex;
  padding: 1rem;
  background-color: var(--info-alert-background-color);
  align-items: center;
  gap: 1rem;
  margin: 0 0 1rem 0;
`;

export default InfoNote;
