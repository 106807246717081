import React from 'react';

interface ComponentProps {
  values: string[];
  divider: React.ReactNode;
}

const DivideStrings: React.FC<ComponentProps> = ({ values, divider }) => {
  return (
    <>
      {values.map((value, index) => {
        return index == 0 ? (
          <React.Fragment key={index}>
            <span>{value}</span>
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            {divider}
            <span>{value}</span>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DivideStrings;
