import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ExpeditionTypeEnum, LoadingTypeEnum, OrderDetailPlanOutDTO } from '@api/logsteo-api.v2';
import { formatWeight, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import { Note } from '../Note/Note';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import LoadInfoItem from '@components/obsolete/LoadDetails/LoadInfo/LoadInfoItem.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import { CustomerExpeditionViewContext } from '@app/pages/customer/expedition-detail/CustomerExpeditionView.tsx';
import { useExpeditionUpdateNote } from '@app/pages/customer/expedition-detail/ExpeditionUpdateNoteSidebar.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import CardView from '@components/obsolete/CardView/CardView.tsx';
import SelectedTruckType from '@components/SelectedTruckType.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import CheckboxItem from '@components/obsolete/CheckboxWrapper/CheckboxItem.tsx';
import InfoProvided from '@components/obsolete/InfoProvided/InfoProvided.tsx';
import InfoProvidedItem from '@components/obsolete/InfoProvided/InfoProvidedItem.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import AccordionTitle from '@components/obsolete/AccordionTitle/AccordionTitle.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import { printDeliverySlots } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import EditLocation from '@app/pages/customer/expedition-detail/EditLocation/EditLocation.tsx';
import { ExpeditionContactInfo } from '@components/obsolete/common/ExpeditionContactInfo.tsx';
import { ResponsiblePersonChangeDialog } from '@app/pages/customer/expedition-detail/ResponsiblePersonChangeDialog/ResponsiblePersonChangeDialog.tsx';
import EditRequirementSidebar from '@app/pages/customer/expedition-detail/EditRequirement/EditRequirementSidebar.tsx';

interface LoadingUloadingDetailProps {
  loading: boolean;
  order: OrderDetailPlanOutDTO;
  carrierCode?: string;
  isFirstElement?: boolean;
}

const LoadingUloadingDetail: React.FC<LoadingUloadingDetailProps> = ({ loading, order, carrierCode = '-', isFirstElement = true }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="">{isFirstElement && <img className="w-[42px] mr-2" src={loading ? '/images/icon_pickup.png' : '/images/icon_dropoff.png'} alt="" />}</div>
          <div>
            {isFirstElement && <Label title={loading ? t('loadDetailsPage.labelPickUp') : t('loadDetailsPage.labelDropOff')} />}
            <LoadInfoItem bookingRef={order.name} totalWeight={formatWeight(order.totalWeight)} className="p-mb-3" />
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-5 p-lg-4">
        {order?.cargos?.map((orderItem, orderItemIndex) => {
          return (
            <LoadInfoList
              key={orderItemIndex}
              title={`${orderItem.name} (${orderItem.quantity} ${translateQuantityCode(orderItem.quantityUnit, tr)})`}
              description={weightAndDimensions(orderItem.height, orderItem.length, orderItem.width, orderItem.weight, t)}
              stackable={orderItem.stackable}
              cargoMessage={orderItem.cargoMessage}
            />
          );
        })}
      </div>
    </div>
  );
};

interface FTLLoadingUloadingDetailProps {
  index: number;
  size: number;
  carrierCode: string;
}

const FTLLoadingUloadingDetail: React.FC<FTLLoadingUloadingDetailProps> = ({ index, size, carrierCode = '-' }) => {
  const { t }: { t: any } = useTranslation('common');
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">
            <img src={index < size - 1 ? '/images/icon_pickup.png' : '/images/icon_dropoff.png'} alt="" />
          </div>
          <div>
            <Label
              title={index == 0 ? t('loadDetailsPage.labelPickUp') : index < size - 1 ? t('loadDetailsPage.labelPickUpDropOff') : t('loadDetailsPage.labelDropOff')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface TabExpeditionRouteCP {
  isExpeditionCanceled: boolean;
  isDeprecatedByManualMode: boolean;
}

const TabExpeditionRoute: React.FC<TabExpeditionRouteCP> = ({ isExpeditionCanceled, isDeprecatedByManualMode }) => {
  const { t } = useTranslation('common');
  const { state, loadExpeditionRouteOverview, changeResponsiblePerson, cuEditCarrierRequirement, changeLocation } = useContext(CustomerExpeditionViewContext);
  const [visibleChangeResponsiblePersonDialog, setVisibleChangeResponsiblePersonDialog] = useState(false);
  const [visibleEditExpeditionRequirements, setVisibleEditExpeditionRequirements] = useState(false);
  const [visibleEditLocation, setVisibleEditLocation] = useState<number>(null);

  const { ExpeditionUpdateNoteSidebar, setVisible: setVisibleUpdateExpeditionNote } = useExpeditionUpdateNote();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const isDisabled = isDeprecatedByManualMode || isExpeditionCanceled || loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1;

  useEffect(() => {
    loadExpeditionRouteOverview();
  }, []);

  return (
    <>
      {state.tabExpeditionPlan && (
        <>
          <NavTitle>
            <Label title={t('loadDetailsPage.titlePage')} />
            <Button
              label={t('labelChangeBtn')}
              className="p-button-outlined p-btn-edit"
              iconPos="left"
              icon="pi pi-pencil"
              onClick={() => setVisibleEditExpeditionRequirements(true)}
              disabled={isDisabled}
            />
          </NavTitle>
          <div className="p-d-flex p-grid">
            <CardView title={t(`global.expeditionTypeShort${state.header.expeditionType}`)} option={t(`global.expeditionType${state.header.expeditionType}`)} />
            {state.tabExpeditionPlan.truckType && <SelectedTruckType selectedTruck={state.tabExpeditionPlan.truckType} />}
            <CardView
              title={
                state.tabExpeditionPlan.loadingTypes &&
                state.tabExpeditionPlan.loadingTypes
                  .map((loadingType: any) => {
                    return loadingType.name;
                  })
                  .join(',')
              }
              option={t('expeditionOverviewPage.manipulationTitle')}
            />
            <CardView title="- km" option={t('expeditionOverviewPage.routeLength')} />
          </div>
          <ButtonLayout wrapper="WITH_PADDING_TOP_2">
            {state.tabExpeditionPlan.requirements.map((requirement: any, index: number) => (
              <CheckboxItem className="p-md-3" key={index}>
                <Checkbox checked={true} className="p-mr-2" disabled />
                <label>{requirement.name}</label>
              </CheckboxItem>
            ))}
          </ButtonLayout>

          <InfoProvided className="p-mt-3">
            <InfoProvidedItem title={t(`TabExpeditionRoute.cargoType`, `Typ nákladu`)}>
              <p className="p-mt-1">{state.tabExpeditionPlan.cargoType.name}</p>
            </InfoProvidedItem>

            {state.tabExpeditionPlan.cargoType.code === 'ADR_LQ' && (
              <InfoProvidedItem title={t(`TabExpeditionRoute.adrWeight`, `ADR weight`)}>
                <p className="p-mt-1">{formatWeight(state.tabExpeditionPlan.adrWeight)}</p>
              </InfoProvidedItem>
            )}

            {state.tabExpeditionPlan.carrierNote && (
              <HorizontalFieldValue
                label={t(`TabExpeditionRoute.carrierNote`, `Poznámka pro dopravce`)}
                value={
                  <Row>
                    <p className="">{state.tabExpeditionPlan.carrierNote}</p>
                    <Button
                      label={t('labelChange')}
                      className="p-button-text"
                      onClick={() => setVisibleUpdateExpeditionNote(true)}
                      disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
                    />
                  </Row>
                }
              />
            )}
          </InfoProvided>

          <div className="p-mt-4 p-mb-4">
            <Label title={t('expeditionOverviewPage.labelPerson')} />
            <div className="p-d-flex p-ai-center">
              <span>{state.tabExpeditionPlan.responsiblePerson.name}</span>
              <Button label={t('labelChange')} className="p-button-text" onClick={() => setVisibleChangeResponsiblePersonDialog(true)} disabled={isDisabled} />
            </div>
          </div>

          <div className="p-mt-3">
            <div className="p-mt-6">
              <Label title={t('expeditionOverviewPage.labelRoute')} />
              {/*//
      @ts-ignore*/}
              <Accordion multiple={true} activeIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}>
                {state.tabExpeditionPlan.locations.map((location: any, index: number, locations: any[]) => {
                  return (
                    // @ts-ignore
                    <AccordionTab
                      key={index}
                      header={
                        <>
                          <AccordionTitle
                            number={index + 1}
                            title={location.name}
                            pretitle={location.address.city}
                            btnTitle={t('expeditionOverviewPage.accordionDetail')}
                          />
                        </>
                      }>
                      <div className="p-grid row-accordion-info">
                        <div className="p-col" style={{ minWidth: '125px' }}>
                          <PanelWithIcon srcIcon="/images/calendar.svg" wrapper="PADDING_BOTTOM_0">
                            <Label title={t('loadDetailsPage.panelLocationDetails.calendar')} />
                            {printDeliverySlots(location.timeslots, t)}
                          </PanelWithIcon>
                        </div>
                        <div className="p-col-fixed">
                          <Button
                            label={t('labelChangeBtn')}
                            className="p-button-outlined p-btn-edit-relative"
                            iconPos="left"
                            icon="pi pi-pencil"
                            onClick={e => setVisibleEditLocation(index)}
                            disabled={isDisabled}
                          />
                        </div>
                        <EditLocation
                          visible={visibleEditLocation === index}
                          onHide={() => {
                            setVisibleEditLocation(null);
                          }}
                          data={location}
                          locationIndex={index}
                          onComplete={data => {
                            changeLocation(index, data);
                            setVisibleEditLocation(null);
                          }}
                        />
                      </div>
                      {location.loadedOrders?.length > 0 &&
                        location.loadedOrders.map((loadingOrders: any, orderIndex: number) => {
                          return (
                            <LoadingUloadingDetail
                              key={orderIndex}
                              loading={true}
                              order={loadingOrders}
                              carrierCode={location.pickupCode}
                              isFirstElement={orderIndex === 0}
                            />
                          );
                        })}
                      {location.unloadedOrders?.length > 0 &&
                        location.unloadedOrders.map((unloadedOrder: any, orderIndex: number) => {
                          return (
                            <LoadingUloadingDetail
                              key={orderIndex}
                              loading={false}
                              order={unloadedOrder}
                              carrierCode={location.pickupCode}
                              isFirstElement={orderIndex === 0}
                            />
                          );
                        })}
                      <ExpeditionContactInfo location={location} />
                    </AccordionTab>
                  );
                })}
              </Accordion>
            </div>
          </div>

          <Note
            noteData={state.tabExpeditionPlan.internalNote}
            applicationId={state.header.applicationId}
            companyId={state.header.companyId}
            onChanged={() => loadExpeditionRouteOverview()}
            disabled={isDisabled}
          />

          <ResponsiblePersonChangeDialog
            visible={visibleChangeResponsiblePersonDialog}
            onHide={() => setVisibleChangeResponsiblePersonDialog(false)}
            onSelect={e => {
              changeResponsiblePerson(e, () => setVisibleChangeResponsiblePersonDialog(false));
            }}
          />
          <EditRequirementSidebar
            visible={visibleEditExpeditionRequirements}
            onHide={() => setVisibleEditExpeditionRequirements(false)}
            data={{
              carrierNote: state.tabExpeditionPlan.carrierNote,
              expeditionType:
                state.header.expeditionType === ExpeditionTypeEnum.FULL_TRUCK_LOAD ? ExpeditionTypeEnum.FULL_TRUCK_LOAD : ExpeditionTypeEnum.LESS_TRUCK_LOAD,
              manipulationTypes: state.tabExpeditionPlan.loadingTypes?.map(
                // @ts-ignore
                (t: any) => LoadingTypeEnum[t.code],
              ),
              selectedRequirement: state.tabExpeditionPlan.requirements.map(
                // @ts-ignore
                e => e.code,
              ),
              cargoTypeCode: state.tabExpeditionPlan.cargoType.code,
              truckTypeId: state.tabExpeditionPlan.truckType?.code,
              adrWeight: state.tabExpeditionPlan.adrWeight,
            }}
            onComplete={data => {
              cuEditCarrierRequirement({
                carrierNote: data.carrierNote,
                requirements: data.selectedRequirement,
                loadingTypes: data.manipulationTypes,
                truckType: data.truckTypeId,
                cargoType: data.cargoTypeCode,
                adrWeight: data.adrWeight,
              });
              setVisibleEditExpeditionRequirements(false);
            }}
          />
          <ExpeditionUpdateNoteSidebar
            applicationId={state.header.applicationId}
            customerId={state.header.companyId}
            onComplete={() => {
              loadExpeditionRouteOverview();
            }}
          />
        </>
      )}
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default TabExpeditionRoute;
