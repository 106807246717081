import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  value: string;
  onChange: (v: string) => void;
  maxLength?: number;
  placeholder?: string;
}

const RingilTextArea: React.FC<ComponentProps> = ({ value, onChange, maxLength, placeholder }) => {
  const { tr } = useTranslationLgs();
  return (
    <div className={'flex flex-col flex-1 gap-1'}>
      <textarea rows={5} placeholder={placeholder} className={"p-2 font-['DM Sans']"} value={value} onChange={e => onChange(e.target.value)} />
      {maxLength && (
        <div className={'text-[10px] text-content-secondary'}>
          {tr(`RingilTextArea.limit`, `{{actual}} / {{max}} znaků`, {
            actual: value?.length || 0,
            max: maxLength,
          })}
        </div>
      )}
    </div>
  );
};

export default RingilTextArea;
