import React, { MouseEventHandler, PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled, { css } from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  variant: 'filled' | 'outlined';
  type: 'loading' | 'unloading' | 'neutral' | 'warning' | 'positive' | 'negative' | 'custom';
  backgroundColor?: string;
  iconPath?: string;
  onIconClick?: (e: any) => void;
  onClick?: (e: any) => void;
}

const COBadge: React.FC<PropsWithChildren<ComponentProps>> = ({ children, variant, type, backgroundColor, iconPath, onIconClick = e => {}, onClick = e => {} }) => {
  return (
    <>
      {type === 'loading' && <Inner backgroundColor={'#B2E9F6'}>{children}</Inner>}
      {type === 'unloading' && <Inner backgroundColor={'#dbd3ee'}>{children}</Inner>}
      {type === 'neutral' && (
        <Inner backgroundColor={'var(--ringil3-secondary-color)'} color={'var(--ringil3-content-negative)'} onClick={e => onClick(e)}>
          {children}
        </Inner>
      )}
      {type === 'warning' && (
        <Inner color={'var(--ringil3-content-primary)'} backgroundColor={'var(--ringil3-complementary-warning-medium)'} onClick={e => onClick(e)}>
          {children}
        </Inner>
      )}
      {type === 'positive' && (
        <Inner color={'var(--ringil3-content-primary)'} backgroundColor={'var(--ringil3-complementary-success-medium)'} onClick={e => onClick(e)}>
          {children}
        </Inner>
      )}{' '}
      {type === 'negative' && (
        <Inner color={'var(--ringil3-content-primary)'} backgroundColor={'var(--ringil3-content-quaternary)'} onClick={e => onClick(e)}>
          {children}
        </Inner>
      )}
      {type === 'custom' && (
        <Inner color={'white'} backgroundColor={backgroundColor} onClick={e => onClick(e)}>
          {children}
          {iconPath && (
            <img
              src={iconPath}
              onClick={e => {
                onIconClick(e);
              }}
            />
          )}
        </Inner>
      )}
    </>
  );
};

interface InnerProps {
  backgroundColor: string;
  color?: string;
}

const Inner = styled.div<InnerProps>`
  /* Auto layout */
  padding: 4px 8px;
  border-radius: 4px;
  color: #616061;
  align-self: flex-start;
  display: inline-flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  ${props =>
    props &&
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  ${props =>
    props &&
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export default COBadge;
