import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import * as yup from 'yup';
import HereMaps from '@components/features/HereMap/HereMaps.tsx';

interface ComponentProps {}

const MapComponent: React.FC<ComponentProps> = () => {
  const {} = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  return (
    <>
      <HereMaps customerId={''} applicationId={''} />
    </>
  );
};

export default MapComponent;
