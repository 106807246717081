import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import SupplierShipments from '@components/obsolete/shipment/SupplierShipments.tsx';

interface ComponentProps {}

const SupplierShipmentsListPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Layout title={tr(`SupplierShipmentsListPage.listSupplierShipments`, `Supplier shipments list`)}>
        <SupplierShipments />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(SupplierShipmentsListPage);
