import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { SplitButton } from 'primereact/splitbutton';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';

interface ComponentProps {
  archived: boolean;
  shipmentId: string;
  onChange: () => void;
}

export const MoreButtonsShipment: React.FC<ComponentProps> = ({ archived, shipmentId, onChange }) => {
  const { tr } = useTranslationLgs();
  const { changeArchiveStateShipment } = useContext(ApiContext);
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);

  const model = [
    {
      label: tr('MoreButtonsShipment.archive', 'Archive'),
      icon: 'pi pi-times',
      command: (e: any) => {
        setVisibleConfirmDialog(true);
      },
      visible: !archived,
    },
  ];

  const archive = () => {
    changeArchiveStateShipment(shipmentId, { archive: true }, () => {
      setVisibleConfirmDialog(false);
      onChange();
    });
  };

  return (
    <>
      {model.filter(t => t.visible).length > 0 && <SplitButton model={model.filter(t => t.visible)} className="btn-more-info p-button-rounded p-button-outlined" />}

      <ConfirmDialog
        visible={visibleConfirmDialog}
        dialogTitle={tr(`MoreButtonsShipment.archiveEntity`, `Do you want to archive this record?`)}
        dialogMessage={tr(`MoreButtonsShipment.switchToManualModeDialogBody`, `Do you want to archive this record?`)}
        onCancel={() => setVisibleConfirmDialog(false)}
        onConfirm={() => archive()}
        onAcceptButtonText={tr(`MoreButtonsShipment.yesArchive`, `Archive`)}
        onCancelButtonText={tr(`MoreButtonsShipment.close`, `Close`)}
        loading={true}
      />
    </>
  );
};
