import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: InputTransportModeEnum;
  onChange: (value: InputTransportModeEnum) => void;
  fullWidth?: boolean;
}

export enum InputTransportModeEnum {
  InternationalToRO = 1,
  ROToRO = 2,
  ROToInternational = 3,
  InternationalToInternational = 4,
}

export const createI18NKey = (key: number) => {
  return `InputTransportModeEnum.${InputTransportModeEnum[key]}`;
};

const ETransportInputTransportMode: React.FC<ComponentProps> = ({ value, onChange, fullWidth = false }) => {
  const { tr } = useTranslationLgs();

  const routes = Object.keys(InputTransportModeEnum)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      label: tr(`InputTransportModeEnum.${key}`, `InputTransportModeEnum.${key}`),
      value: InputTransportModeEnum[key as keyof typeof InputTransportModeEnum],
    }));

  const style = fullWidth ? { width: '100%' } : {};

  return (
    <>
      <Dropdown value={value} options={routes} onChange={e => onChange(e.value)} placeholder="Select a Route" style={style} />
    </>
  );
};

export default ETransportInputTransportMode;
