import React, { useContext, useEffect, useState } from 'react';
import SvgMenuMessages from '@app/icons/menu/MenuMessages.tsx';
import MenuItem from '@components/menu/MenuItem.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import { EntityTypeEnum, MessageTypeFilterEnum, PageResponseNotificationPreviewListOutDTO, SupplierNotificationTypeEnum, TypeEnum } from '@api/logsteo-api.v2.tsx';
import { useNavigate } from 'react-router-dom';
import useNavigation from '@hooks/useNavigation.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date.tsx';

interface ComponentProps {
  smallMenu?: boolean;
  lockedMenu?: boolean;
}

const NotificationMenuItem: React.FC<ComponentProps> = ({ smallMenu, lockedMenu }) => {
  const { tr } = useTranslationLgs();
  const [state, setState] = useState<PageResponseNotificationPreviewListOutDTO>();
  const { cuListNotifications, cuReadNotification } = useContext(ApiContext);
  const nav = useNavigation();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    cuListNotifications({ messageTypeFilter: MessageTypeFilterEnum.UNREAD }, { pageSize: 12, pageNumber: 0 }, data => {
      setState(data);
    });
  };

  const maskAsReadAndRedirect = (notificationId: string, companyId: string, applicationId: string, entityId: string, entityType: EntityTypeEnum) => {
    cuReadNotification(notificationId, companyId, applicationId, () => {
      if (entityType == EntityTypeEnum.SUPPLIER_NOTIFICATION) {
        nav.navigate(nav.urlFunctions.createSupplierNotificationDetail(entityId, SupplierNotificationTypeEnum.FCA));
      } else if (entityType == EntityTypeEnum.GROUP_DEMAND) {
        nav.navigate(nav.urlFunctions.createMultiDemanDetail(entityId));
      } else {
        nav.navigate(nav.urlFunctions.createCustomerExpeditionDetail(applicationId));
      }
      load();
    });
  };

  const content = (
    <>
      {state?.data.map((n, index) => {
        return (
          <li role="menuitem" key={index}>
            <button className="p-link topbar-message" onClick={() => maskAsReadAndRedirect(n.id, n.companyId, n.applicationId, n.entityId, n.entityType)}>
              <div className="h-16 px-4 flex-col justify-center items-start gap-[5px] inline-flex">
                <div className="">{n.name}</div>
                <div className="flex flex-row justify-between items-start gap-2.5 text-content-secondary ">
                  <div className="">{n.applicationId}</div>
                  <div className="">{dateFormatDateTime(mapFromAPIDateTime(n.createAt))}</div>
                </div>
              </div>
            </button>
          </li>
        );
      })}
      <MenuItem
        smallMenu={false}
        render={true}
        title={tr(`AppMenuNotification.seeAll`, `Vše`)}
        onClick={() => {
          nav.navigate(nav.urlFunctions.createCustomerNotifications());
        }}
      />
    </>
  );

  return (
    <>
      <MenuItem
        title={tr('NotificationMenuItem.messages', 'Messages')}
        icon={<SvgMenuMessages />}
        elementCount={state?.total}
        smallMenu={smallMenu && !lockedMenu}
        render={true}
        mode={'open-outside-bottom'}
        subMenu={content}
      />
    </>
  );
};

export default NotificationMenuItem;
