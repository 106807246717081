import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined } from '@utils/utils';
import { BlueIcon, CenteredButtonRow, Clickable, Row, RowWithGap, RowWithSpaceBetween } from '@components/styles';
import dayjs, { Dayjs } from 'dayjs';
import { findNextDay, formatDate, mapFromAPIToDateShort, mapFromAPIToTime, mapToAPIDateTime, shortDateFormat, toTime } from '@utils/date';
import { DayEnum, GlobalOrderItemsRequest, GlobalOrderResponse, OrderReservationInDTO, ReservationDetailOutDTO, ReservationDriverInfoOutDTO } from '@api/logsteo-api.v2';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { useImmer } from 'use-immer';
import useNavigation from '@hooks/useNavigation.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';

interface ComponentProps {
  onComplete: () => void;
}

type SlotState = 'check' | 'free' | 'save' | 'error' | 'saved';

interface Slot {
  since: Dayjs;
  till: Dayjs;
  newReservationId?: string;
  state: SlotState;
}

export const useDuplicateTimeslot = () => {
  const [timeslotIdForDuplication, setTimeslotIdForDuplication] = useState(null);

  const DuplicateTimeslot: React.FC<ComponentProps> = ({ onComplete }) => {
    const { tr } = useTranslationLgs();
    const { getReservationDetail } = useContext(ApiContext);
    const [newSlots, setNewSlots] = useImmer<Slot[]>([]);

    const [lastSlot, setLastSlot] = useState<Slot>();
    const [reservationData, setReservationData] = useState<ReservationDetailOutDTO>();

    const [saving, setSaving] = useState(false);

    const navigation = useNavigation();

    useEffect(() => {
      const c = new AbortController();

      if (timeslotIdForDuplication) {
        getReservationDetail(timeslotIdForDuplication, d => {
          setReservationData(d);
          setLastSlot({
            since: dayjs(d.timeslot.since),
            till: dayjs(d.timeslot.till),
            state: 'check',
          });
        });
      }

      return () => {
        c.abort();
      };
    }, [timeslotIdForDuplication]);

    const createNewSlot = (daysToAdd: number) => {
      const l = newSlots.length > 0 ? newSlots[newSlots.length - 1] : lastSlot;

      const newSlot = {
        since: l.since.add(daysToAdd, 'day'),
        till: l.till.add(daysToAdd, 'day'),
        state: 'check',
      } as Slot;

      pushSlot(newSlot);
    };

    const changeSlotState = (slot: Slot) => {
      setNewSlots(draft => {
        const s = draft.find(s => s.since.isSame(slot.since));
        s.state = slot.state;
      });
    };

    /*    const delayedCheck = (slot: Slot) => {
                      changeSlotState({ ...slot, hasPendingApiRequest: true });
                    };*/
    /**
     * Add slot to state and trigger api delayed api check
     * @param slot
     */
    const pushSlot = (slot: Slot) => {
      setNewSlots(d => {
        d.push(slot);
      });
      //delayedCheck(slot);
    };

    const createNewSlotWithDate = (date: Dayjs) => {
      const newSlot = {
        since: lastSlot.since.set('date', date.date()).set('month', date.month()).set('year', date.year()),
        till: lastSlot.till.set('date', date.date()).set('month', date.month()).set('year', date.year()),
        state: 'check',
      } as Slot;
      setLastSlot(newSlot);
      pushSlot(newSlot);
    };

    /**
     * Create new slot with next day in week, next Monday, Tuesday, etc.
     * @param dayInWeek
     */
    const createNewSlotNext = (dayInWeek: DayEnum) => {
      const l = newSlots.length > 0 ? newSlots[newSlots.length - 1] : lastSlot;

      const next = findNextDay(l.since, dayInWeek);

      const newSlot = {
        since: l.since.set('date', next.date()).set('month', next.month()).set('year', next.year()),
        till: l.till.set('date', next.date()).set('month', next.month()).set('year', next.year()),
        state: 'check',
      } as Slot;
      pushSlot(newSlot);
    };

    const removeSlot = (since: Dayjs, till: Dayjs) => {
      setNewSlots(d => {
        return d.filter(s => !s.since.isSame(since));
      });
    };

    const save = () => {
      setSaving(true);
      newSlots.forEach(slot => {
        if (slot.state == 'free') {
          changeSlotState({
            ...slot,
            state: 'save',
          });
          /*createReservation(
                                                                          {
                                                                              customerId: reservationData.customerId,
                                                                              locationId: reservationData.locationId,
                                                                              timeslot: {since: mapToAPIDateTime(slot.since), till: mapToAPIDateTime(slot.till)},
                                                                              labelIds: [],
                                                                              calendarId: reservationData.calendarId,
                                                                              order: reservationData.order,
                                                                          },
                                                                          (d) => {
                                                                              changeSlotState({
                                                                                  ...slot,
                                                                                  hasPendingApiRequest: false,
                                                                                  messageType: 'success',
                                                                                  message: (
                                                                                      <div>
                                                                                          {tr('duplicate.reservationCreated', 'Reservation created')} -{' '}
                                                                                          <a href={`/timeslots/${d.id}`} style={{fontWeight: 400}}>
                                                                                              {d.applicationId}
                                                                                          </a>
                                                                                      </div>
                                                                                  ),
                                                                              });
                                                                          },
                                                                          null,
                                                                          {
                                                                              onValidationFailed: (v) => {
                                                                                  /!*mark slot as error *!/
                                                                                  changeSlotState({
                                                                                      ...slot,
                                                                                      hasPendingApiRequest: false,
                                                                                      messageType: 'error',
                                                                                      message: tr('duplicate.creatingFailed', 'Creating failed...'),
                                                                                  });
                                                                              },
                                                                          },
                                                                      );*/
        }
      });
    };

    const saved = saving && !newSlots.some(s => s.state === 'check' || s.state === 'free' || s.state === 'save');
    if (reservationData == null) return <></>;

    return (
      <>
        <RingilDialog
          visible={isNotNullOrUndefined(timeslotIdForDuplication)}
          onHide={() => setTimeslotIdForDuplication(null)}
          onSave={() => {
            save();
          }}
          headerText={tr('DuplicateTimeslot.header', 'Duplicate timeslot')}
          wide={true}
          saveButtonDisabled={!newSlots.some(t => t.state === 'free')}
          showButtonBar={!saved}
        >
          <Row>
            <HorizontalFieldValue
              label={tr(`duplicate.localityAndRamp`, 'Locality and ramp')}
              value={[reservationData.locationName, reservationData.rampName].filter(t => t != null).join(', ')}
            />
            <HorizontalFieldValue
              label={tr(`duplicate.resevationDateAndTime`, `Resevation date and time`)}
              value={`${mapFromAPIToDateShort(reservationData.timeslot?.since)} ${mapFromAPIToTime(reservationData.timeslot?.since)} - ${mapFromAPIToTime(
                reservationData.timeslot?.till,
              )}`}
            />
          </Row>

          <Grid>
            {newSlots.map((slot, key) => {
              return (
                <DateAndMessage
                  order={reservationData.orders[0]}
                  orders={reservationData.orders}
                  customerId={reservationData.customerId}
                  locationId={reservationData.locationId}
                  carPlate={reservationData.carPlate}
                  driverInfo={reservationData.driverInfo}
                  carrierNote={reservationData.carrierNote}
                  carrierId={reservationData.carrierId}
                  rampId={reservationData.rampId}
                  key={key}
                  since={slot.since}
                  till={slot.till}
                  onRemove={(since, till) => {
                    removeSlot(since, till);
                  }}
                  event={slot.state}
                  onChangedState={newState => {
                    changeSlotState({ ...slot, state: newState });
                  }}
                  reservationNote={reservationData.reservationNote}
                  reservationName={reservationData.reservationName}
                />
              );
            })}
          </Grid>
          {!saved && (
            <>
              <DatesGrid>
                <DuplicateButtons createNewSlot={createNewSlot} createNewSlotWithDate={createNewSlotWithDate} createNewSlotNext={createNewSlotNext} />
              </DatesGrid>
            </>
          )}

          {saved && (
            <CenteredButtonRow>
              <Button
                label={tr(`duplicate.okClose`, `OK, close`)}
                onClick={() => {
                  setTimeslotIdForDuplication(null);
                  onComplete();
                }}
              />
            </CenteredButtonRow>
          )}
        </RingilDialog>
      </>
    );
  };

  interface DateAndMessageProps {
    since: Dayjs;
    till: Dayjs;
    onRemove: (since: Dayjs, till: Dayjs) => void;
    event: SlotState;
    customerId: string;
    locationId: string;
    order: OrderReservationInDTO;
    orders: GlobalOrderResponse[];
    rampId: string;
    onChangedState: (newState: SlotState) => void;
    carPlate?: string;
    driverInfo?: ReservationDriverInfoOutDTO;
    carrierNote?: string;
    carrierId?: string;
    reservationName?: string;
    reservationNote?: string;
  }

  interface Message {
    message: any;
    messageType: 'error' | 'info' | 'success' | 'warn';
  }

  const DateAndMessage: React.FC<DateAndMessageProps> = ({
    since,
    till,
    onRemove,
    event,
    locationId,
    customerId,
    order,
    rampId,
    onChangedState,
    carPlate,
    carrierNote,
    driverInfo,
    carrierId,
    orders,
    reservationName,
    reservationNote,
  }) => {
    const { createReservation, checkReservation } = useContext(ApiContext);
    const { tr } = useTranslationLgs();
    const [apiRequest, setApiRequest] = useState(false);
    const [message, setMessage] = useState<Message>();

    const navigation = useNavigation();

    useEffect(() => {
      const c = new AbortController();
      if (event == 'check') {
        setTimeout(() => {
          setApiRequest(true);
          setMessage({
            message: tr('duplicate.checking', 'Checking...'),
            messageType: 'info',
          });
          checkReservation(
            [],
            {
              customerId,
              locationId,
              timeslot: {
                since: mapToAPIDateTime(since),
                till: mapToAPIDateTime(till),
              },
              labelIds: [],
              rampId,
              order,
            },
            () => {
              setApiRequest(false);
              setMessage({
                message: tr('duplicate.freeTimeslotDetected', 'Free timeslot detected...'),
                messageType: 'success',
              });
              onChangedState('free');
            },
            null,
            {
              onError: e => {
                setApiRequest(false);
                setMessage({
                  message: tr('duplicate.reservationNotEligible', 'Reservation not eligible'),
                  messageType: 'error',
                });
                onChangedState('error');
              },
              onValidationFailed: v => {
                setApiRequest(false);
                setMessage({
                  message: tr('duplicate.reservationNotEligible', 'Reservation not eligible'),
                  messageType: 'error',
                });
                onChangedState('error');
              },
            },
          );
        }, 600);
      }
      if (event == 'save') {
        // save
        setApiRequest(true);
        setMessage({
          message: tr('duplicate.saving', 'Saving...'),
          messageType: 'info',
        });
        createReservation(
          {
            customerId,
            locationId,
            timeslot: {
              since: mapToAPIDateTime(since),
              till: mapToAPIDateTime(till),
            },
            labelIds: [],
            rampId,
            order,
            carrierNote: carrierNote,
            driverInfo: driverInfo,
            carPlate: carPlate,
            reservationName: reservationName,
            reservationNote: reservationNote,
            carrierId: carrierId,
            orders: orders.map(t => ({
              orderValue: t.orderValue,
              items: t.items.map(
                t =>
                  ({
                    amount: t.amount,
                    stackable: t.stackable,
                    note: t.note,
                    height: t.height,
                    width: t.width,
                    length: t.length,
                    weight: t.weight,
                    cargoTemplateCode: t.cargoTemplate.code,
                  } as GlobalOrderItemsRequest),
              ),
              cargoAmount: t.cargoAmount,
              cargoDescription: t.cargoDescription,
              orderNote: t.orderNote,
              orderName: t.orderName,
            })),
          },
          d => {
            setApiRequest(false);
            setMessage({
              message: (
                <>
                  <div>
                    {tr('duplicate.reservationCreated', 'Reservation created')} - {/*<a href={`/timeslots/${d.id}`} style={{ fontWeight: 400 }}>*/}
                    <a href={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(d.id))} style={{ fontWeight: 400 }}>
                      {d.applicationId}
                    </a>
                  </div>
                </>
              ),
              messageType: 'info',
            });
            onChangedState('saved');
          },
          null,
          {
            onError: e => {
              setApiRequest(false);
              setMessage({
                message: tr('duplicate.reservationNotEligible', 'Reservation not eligible'),
                messageType: 'error',
              });
              onChangedState('error');
            },
            onValidationFailed: v => {
              setApiRequest(false);
              setMessage({
                message: tr('duplicate.reservationNotEligible', 'Reservation not eligible'),
                messageType: 'error',
              });
              onChangedState('error');
            },
          },
        );
      }
      return () => {
        c.abort();
      };
    }, [event]);

    return (
      <>
        <div>
          <RowWithSpaceBetween>
            <div>{formatDate(since)}</div>
            <RowWithGap>
              <RowNoGap>
                {toTime(since)} - {toTime(till)}
              </RowNoGap>
              <Clickable onClick={() => onRemove(since, till)}>
                <BlueIcon className={'pi pi-trash'} />
              </Clickable>
            </RowWithGap>
          </RowWithSpaceBetween>
        </div>
        <div>
          {apiRequest && (
            <>
              <ProgressSpinner style={{ width: '15px', height: '15px', marginRight: '0.5rem' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
            </>
          )}
          {message && message.messageType == 'info' && <span>{message.message}</span>}
          {message && message.messageType == 'success' && <span style={{ color: 'var(--ringil-success-color)' }}>{message.message}</span>}
          {message && message.messageType == 'error' && <span style={{ color: 'var(--ringil-error-color)' }}>{message.message}</span>}
        </div>
      </>
    );
  };

  const DuplicateButtons = ({
    // @ts-ignore
    createNewSlot,
    // @ts-ignore
    createNewSlotWithDate,
    // @ts-ignore
    createNewSlotNext,
  }) => {
    const [customDate, setCustomDate] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(null);
    const { tr } = useTranslationLgs();
    return (
      <>
        <div>
          {customDate && (
            <CustomRow>
              FIXCALENDAR
              <Calendar
                dateFormat={shortDateFormat}
                style={{ width: '140px' }}
                locale={'cs'}
                showButtonBar={true}
                showIcon
                value={selectedDate}
                onChange={e => setSelectedDate(e.value as Date)}
              />
              <Button
                label={tr(`duplicate.save`, `Save`)}
                onClick={() => {
                  createNewSlotWithDate(dayjs(selectedDate));
                  setCustomDate(false);
                  setSelectedDate(null);
                }}
              />
              <BlueIcon
                className={'pi pi-trash'}
                onClick={() => {
                  setCustomDate(false);
                }}
              />
            </CustomRow>
          )}
        </div>
        <>
          {!customDate && (
            <div>
              <RowWithSpaceBetween>
                <DaysRowWithGap>
                  <Clickable onClick={() => createNewSlot(1)}>{tr(`duplicate.1Den`, `1 den`)}</Clickable>
                  <span>|</span>
                  <Clickable onClick={() => createNewSlot(7)}>{tr(`duplicate.7Dnu`, `7 dnu`)}</Clickable>
                  <span>|</span>
                  <Clickable onClick={() => createNewSlot(14)}>{tr(`duplicate.14Dni`, `14 dni`)}</Clickable>
                  <span>|</span>
                  <Clickable onClick={() => createNewSlot(21)}>{tr(`duplicate.21Dnu`, `21 dnu`)}</Clickable>
                  <span>|</span>
                  <Clickable onClick={() => createNewSlot(30)}>{tr(`duplicate.30Dni`, `30 dni`)}</Clickable>
                </DaysRowWithGap>
                <Clickable onClick={() => setCustomDate(true)}>{tr(`duplicate.vlastni`, `Vlastni`)}</Clickable>
              </RowWithSpaceBetween>
              <RowWithSpaceBetween>
                <Clickable onClick={() => createNewSlotNext(DayEnum.MONDAY)}>
                  <NoWrap>{tr(`duplicate.nextMonday`, `Pristi pondeli`)}</NoWrap>
                </Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.TUESDAY)}>{tr(`duplicate.tuesday`, `Utery`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.WEDNESDAY)}>{tr(`duplicate.wednesday`, `Streda`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.THURSDAY)}>{tr(`duplicate.thursday`, `Ctvrtek`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.FRIDAY)}>{tr(`duplicate.friday`, `Patek`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.SATURDAY)}>{tr(`duplicate.saturday`, `Sobota`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.SUNDAY)}>{tr(`duplicate.sunday`, `Nedele`)}</Clickable>
              </RowWithSpaceBetween>
            </div>
          )}
        </>
      </>
    );
  };

  return { setTimeslotIdForDuplication, DuplicateTimeslot };
};

const CustomRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 0.5rem;
`;
const NoWrap = styled.div`
  display: flex;
  white-space: nowrap;
`;

const RowNoGap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const DaysRowWithGap = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const Grid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const DatesGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
`;
