import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExtracosts = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={27} fill="none" {...props}>
    <path
      fill="#B2B2B2"
      d="M0 21.082v2.168c0 1.793 4.367 3.25 9.75 3.25s9.75-1.457 9.75-3.25v-2.168c-2.097 1.477-5.931 2.168-9.75 2.168S2.097 22.56 0 21.082M16.25 7C21.633 7 26 5.543 26 3.75S21.633.5 16.25.5 6.5 1.957 6.5 3.75 10.867 7 16.25 7M0 15.755v2.62c0 1.793 4.367 3.25 9.75 3.25s9.75-1.457 9.75-3.25v-2.62c-2.097 1.726-5.936 2.62-9.75 2.62s-7.653-.894-9.75-2.62m21.125.558C24.035 15.75 26 14.703 26 13.5v-2.168c-1.178.832-2.91 1.401-4.875 1.752zM9.75 8.625C4.367 8.625 0 10.443 0 12.688s4.367 4.062 9.75 4.062 9.75-1.818 9.75-4.062-4.367-4.063-9.75-4.063m11.136 2.859C23.933 10.935 26 9.859 26 8.625V6.457c-1.803 1.274-4.9 1.96-8.16 2.122 1.497.726 2.6 1.701 3.046 2.905"
    />
  </svg>
);
export default SvgExtracosts;
