import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onReject: () => void;
  disabled: boolean;
}

const RejectDialogComponent: React.FC<ComponentProps> = ({ visible, onReject, onHide, disabled }) => {
  const { t } = useTranslation('common');
  return (
    <>
      {/*// @ts-ignore*/}
      <Dialog header={t(`RejectDialogComponent.rejectThisDemand`, `Reject this demand`)} visible={visible} className="modal-sm" onHide={() => onHide()}>
        <div>
          <p className="p-mt-0">
            {t(
              `RejectDialogComponent.byRejectingYouWonTBeAbleToAddAny`,
              `By rejecting you won’t be able to add any price quote to this demand. Customer will be notified about this
          information.`,
            )}
          </p>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`RejectDialogComponent.reject`, `Reject`)} className="p-mr-2 p-button-danger" onClick={() => onReject()} disabled={disabled} />
          <Button label={t(`RejectDialogComponent.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};

export default RejectDialogComponent;
