import React, { useEffect } from 'react';
import * as yup from 'yup';
import { getTime, Identifier, validateHours } from '@utils/utils';
import { Dialog } from 'primereact/dialog';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import dayjs, { Dayjs } from 'dayjs';
import useForm from '@hooks/useForm/useForm';
import HorizontalFieldValue from '../../customer/carriers/HorizontalFieldValue';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { setTime, shortDateFormat } from '@utils/date';
import ValidationDiv from '@utils/validation';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  locationId: Identifier;
  zipCode: string;
  countryCode: string;
  propertyName: string;
  onConfirm: (locationId: Identifier, when: Dayjs) => void;
  onCancel: () => void;
  visible: boolean;
}

interface Form {
  localDate: Date;
  localTime: string;
}

const LoadingUnloadingConfirmDialog: React.FC<ComponentProps> = ({ propertyName, locationId, onConfirm, countryCode, zipCode, onCancel, visible }) => {
  const { tr } = useTranslationLgs();

  const initialValues: Form = {
    localDate: dayjs().toDate(),
    localTime: getTime(dayjs()),
  };

  const validationScheme = yup.object().shape({
    localDate: yup.date().required(),
    localTime: yup.string().test({
      test: validateHours,
      name: 'time-ok',
      message: tr('validation.invalid', 'Value is invalid'),
    }),
  });

  const form = useForm<Form>(
    validationScheme,
    initialValues,
    () => {
      onConfirm(locationId, setTime(dayjs(form.values.localDate), form.values.localTime));
    },
    true,
    true,
    () => {},
  );

  useEffect(() => {
    if (visible) {
      form.setValues({
        localDate: dayjs().toDate(),
        localTime: getTime(dayjs()),
      });
    }
  }, [visible]);

  const resolveHeading = (propertName: string) => {
    switch (propertName) {
      case 'unloadingLocationFinished':
        return tr('LoadingUnloadingConfirmDialog.unloadingLocationFinished', 'Confirm unloading');
      case 'loadingLocationFinished':
        return tr('LoadingUnloadingConfirmDialog.loadingLocationFinished', 'Confirm loading');
      case 'noActionFinishedAt':
        return tr(`LoadingUnloadingConfirmDialog.noActionFinishedAt`, `Confirm location`);
      default:
        return 'NA';
    }
  };

  const resolveQuestion = (propertName: string) => {
    switch (propertName) {
      case 'unloadingLocationFinished':
        return tr(`LoadingUnloadingConfirmDialog.wouldYouLikeToConfirmUnloading`, `Would you like to confirm unloading at location: {{countryCode}}, {{zipCode}}?`, {
          countryCode,
          zipCode,
        });
      case 'loadingLocationFinished':
        return tr(`LoadingUnloadingConfirmDialog.wouldYouLikeToConfirmLoading`, `Would you like to confirm loading at location: {{countryCode}}, {{zipCode}}?`, {
          countryCode,
          zipCode,
        });
      case 'noActionFinishedAt':
        return tr('LoadingUnloadingConfirmDialog.wouldYouLikeToConfirmArrival', 'Would you like to confirm arrival at location: {{countryCode}}, {{zipCode}}?', {
          countryCode,
          zipCode,
        });
      default:
        return 'NA';
    }
  };

  return (
    <>
      {/*// @ts-ignore*/}
      <Dialog visible={visible} baseZIndex={1000000} onHide={() => onCancel()} header={<h4>{resolveHeading(propertyName)}</h4>} className="dialog-confirm dialog-md">
        <strong>{resolveQuestion(propertyName)}</strong>
        <DateAndTime>
          <HorizontalFieldValue
            label={tr(`LoadingUnloadingConfirmDialog.date`, `Date`)}
            value={
              <>
                <Calendar
                  locale={'cs'}
                  value={form.values.localDate}
                  style={{ width: '10rem' }}
                  dateFormat={shortDateFormat}
                  onChange={d => form.setFieldValue('localDate', d.value)}
                />
                <ValidationDiv errors={form.validationErrors} path={'localDate'} />
              </>
            }
          />
          <HorizontalFieldValue
            label={tr(`LoadingUnloadingConfirmDialog.time`, `Time`)}
            value={
              <>
                <InputText value={form.values.localTime} style={{ width: '5rem' }} onChange={e => form.setFieldValue('localTime', e.target.value)} />
                <ValidationDiv errors={form.validationErrors} path={'localTime'} />
              </>
            }
          />
        </DateAndTime>
        <ButtonLayout wrapper="WITHOUT_PADDING_BOTTOM">
          <Button
            label={tr(`LoadingUnloadingConfirmDialog.save`, `Save`)}
            className="p-mr-2"
            onClick={e => {
              form.validateAndSend();
            }}
          />
          <Button label={tr(`LoadingUnloadingConfirmDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onCancel()} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};

const DateAndTime = styled.div`
  display: flex;
  gap: 1rem;
`;

export default LoadingUnloadingConfirmDialog;
