import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { CargoTemplateWithoutIdDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: CargoTemplateWithoutIdDTO;
  onChange: (sku: CargoTemplateWithoutIdDTO) => void;
  style?: any;
  disabled?: boolean;
}

const InputSKUPlain: React.FC<ComponentProps> = ({ value, onChange, style, disabled = false }) => {
  const [cargoItemTypes, setCargoItemTypes] = useState<CargoTemplateWithoutIdDTO[]>([]);
  const { lovCargoItemType } = useContext(ApiContext);

  useEffect(() => {
    lovCargoItemType(data => {
      setCargoItemTypes(data);
    });
  }, []);
  return (
    <>
      <Dropdown value={value} dataKey="code" options={cargoItemTypes} onChange={e => onChange(e.target.value)} optionLabel="name" style={style} disabled={disabled} />
    </>
  );
};

export default InputSKUPlain;
