import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import CustomsStatusTag from '@app/pages/Customs/tags/CustomsStatusTag.tsx';
import { CustomsCustomsInDTO, CustomsDetailOutDTO } from '@api/logsteo-api.v2.tsx';
import { dumpVars, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import CustomsValuesTag from '@app/pages/Customs/tags/CustomsValueTag.tsx';
import CustomsMRNsTag from '@app/pages/Customs/tags/CustomsMRNTag.tsx';
import CustomsAreaTag from '@app/pages/Customs/tags/CustomsAreaTag.tsx';
import CustomsLoadingNumberTag from '@app/pages/Customs/tags/CustomsLoadingNumberTag.tsx';
import CustomsInternalPDNumberTag from '@app/pages/Customs/tags/CustomsInternalPDNumberTag.tsx';
import InternalNoteTag from '@components/features/tags/InternalNoteTag.tsx';

interface ComponentProps {
  expeditionId?: string;
  supplierNotificationId?: string;
}

const CustomsDetail: React.FC<ComponentProps> = ({ expeditionId, supplierNotificationId }) => {
  const { updateCustomsForExpedition, getCustomsForExpedition, getCustomsForSupplierNotification, updateCustomsForSupplierNotification } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [detail, setDetail] = useState<CustomsDetailOutDTO>();

  useEffect(() => {
    if (expeditionId) {
      getCustomsForExpedition(expeditionId, d => {
        if (isNullOrUndefined(d)) {
          setDetail({
            customsArea: null,
            customsValue: null,
            loadingNumber: null,
            id: null,
            status: null,
            entityId: null,
            entityType: null,
            internalNote: null,
            internalPDNumber: null,
            mrnNumber: null,
          });
        } else setDetail(d);
      });
    }

    if (supplierNotificationId) {
      getCustomsForSupplierNotification(supplierNotificationId, d => {
        if (isNullOrUndefined(d)) {
          setDetail({
            customsArea: null,
            customsValue: null,
            loadingNumber: null,
            id: null,
            status: null,
            entityId: null,
            entityType: null,
            internalNote: null,
            internalPDNumber: null,
            mrnNumber: null,
          });
        } else setDetail(d);
      });
    }
  }, [expeditionId, supplierNotificationId]);

  if (isNullOrUndefined(detail)) return <></>;

  const save = (key: keyof CustomsCustomsInDTO, v: any) => {
    if (isNotNullOrUndefined(expeditionId)) {
      updateCustomsForExpedition(
        expeditionId,
        expeditionId,
        {
          [key]: v,
        },
        () => {
          getCustomsForExpedition(expeditionId, d => {
            setDetail(d);
          });
        },
      );
    }

    if (isNotNullOrUndefined(supplierNotificationId)) {
      updateCustomsForSupplierNotification(
        supplierNotificationId,
        supplierNotificationId,
        {
          [key]: v,
        },
        () => {
          getCustomsForSupplierNotification(supplierNotificationId, d => {
            setDetail(d);
          });
        },
      );
    }
  };

  return (
    <>
      <div className={'flex flex-row gap-4'}>
        <CustomsStatusTag
          value={
            isNotNullOrUndefined(detail.status)
              ? {
                  status: detail.status.customsStatus,
                  localDate: detail.status.date?.customsLocalDate,
                }
              : null
          }
          onChange={v => {
            save(
              'customsStatus',
              isNotNullOrUndefined(v)
                ? {
                    customsStatus: v.status,
                    date: isNotNullOrUndefined(v.localDate) ? { customsLocalDate: v.localDate } : null,
                  }
                : null,
            );
          }}
        />
        <CustomsValuesTag value={detail.customsValue} onChange={v => save('customsValue', v)} />
        <CustomsMRNsTag value={detail.mrnNumber} onChange={v => save('mrnNumber', { mrnNumber: v })} />
        <CustomsAreaTag value={detail.customsArea} onChange={v => save('customsArea', { customsArea: v })} />
      </div>
      <div className={'flex flex-row gap-4'}>
        <CustomsLoadingNumberTag value={detail.loadingNumber} onChange={v => save('loadingNumber', { loadingNumber: v })} />
        <CustomsInternalPDNumberTag value={detail.internalPDNumber} onChange={v => save('internalPDNumber', { internalPDNumber: v })} />
        <InternalNoteTag value={detail.internalNote} onChange={v => save('internalNote', { internalNote: v })} />
      </div>
    </>
  );
};

export default CustomsDetail;
