import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CarrierCarrierGroupPreviewOutDTO } from '@api/logsteo-api.v2';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import { Dialog } from 'primereact/dialog';
import AddCarrierToGroup from './sidebars/AddCarrierToGroup';
import { useDebounce } from 'use-debounce';
import { ApiContext } from '@api/api';
import useNavigation from '@hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { Link } from 'react-router-dom';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';

interface ComponentProps {
  carrierGroupId: string;
}

interface CarrierGroupDetail {
  name: string;
  data: CarrierCarrierGroupPreviewOutDTO[];
  total: number;
  page: number;
  size: number;
}

const CarrierGroupDetail: React.FC<ComponentProps> = ({ carrierGroupId }) => {
  const { t } = useTranslation('common');
  const { cuDetailCarrierGroupCarriersPage, cuDetailCarrierGroup, cuRemoveCarrierFromCarrierGroup } = useContext(ApiContext);
  const [carrierGroupDetail, setCarrierGroupDetail] = useState<CarrierGroupDetail>(undefined);
  const [removeCarrierId, setRemoveCarrierId] = useState(undefined);
  const [visibleAddCarrierToGroup, setVisibleAddCarrierToGroup] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation();

  useEffect(() => {
    load('', 10, 0);
  }, []);

  const load = (q = '', pageSize: number, pageNumber: number) => {
    setLoading(true);
    cuDetailCarrierGroupCarriersPage(carrierGroupId, { pageSize, pageNumber }, q, data => {
      cuDetailCarrierGroup(carrierGroupId, data1 => {
        setLoading(false);
        setCarrierGroupDetail({
          name: data1.carrierGroupName,
          ...data,
        });
      });
    });
  };

  const removeCarrierFromGroup = () => {
    cuRemoveCarrierFromCarrierGroup(removeCarrierId, carrierGroupId, () => {
      load(searchDebounced, 10, 0);
      setRemoveCarrierId(undefined);
    });
  };

  const navBodyTemplate = (rowData: CarrierCarrierGroupPreviewOutDTO) => {
    return (
      <div>
        <ButtonLayout>
          <Button className=" p-button p-button-rounded p-button-outlined" icon="pi pi-trash" onClick={() => setRemoveCarrierId(rowData.carrierId)} />
        </ButtonLayout>
      </div>
    );
  };

  const nameBodyTemplate = (rowData: CarrierCarrierGroupPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`CarrierGroupDetail.name`, `Name`)} />
        {/*<Link href={`/customer/carrier/${rowData.carrierId}`}>{rowData.carrierName}</Link>*/}
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierDetail(rowData.carrierId))}>{rowData.carrierName}</Link>
      </>
    );
  };
  const createByBodyTemplate = (rowData: CarrierCarrierGroupPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`CarrierGroupDetail.createdBy`, `Created by`)} />
        <ColumnContent content={rowData.addBy} />
      </>
    );
  };
  const createdBodyTemplate = (rowData: CarrierCarrierGroupPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`CarrierGroupDetail.created`, `Created`)} />
        <ColumnContent content={dateFormatDateTime(mapFromAPIDateTime(rowData.addAt))} />
      </>
    );
  };

  const carrierGroupBreadCrumb = () => {
    return [
      {
        label: t(`CarrierGroupDetail.allCarrierGroups`, `Všechny skupiny`),
        url: /*"/customer/carrier-groups"*/ navigation.createNavigationLink(navigation.urlFunctions.createCarrierGroups()),
      },
      {
        label: t(`CarrierGroupDetail.carrierGroupDetail`, `Detail`),
        disabled: true,
      },
    ];
  };

  const [searchText, setSearchText] = useState('');
  const [searchDebounced] = useDebounce(searchText, 1000);

  useEffect(() => {
    load(searchDebounced, 10, 0);
  }, [searchDebounced]);

  const onPage = (event: any) => {
    load(searchDebounced, event.rows, event.page + 1);
  };
  return (
    <>
      <BreadCrumbComponent items={carrierGroupBreadCrumb()} />
      {carrierGroupDetail && (
        <>
          <NavTitle wrapper="WITH_PADDING-TOP-4">
            <h2 className="h2-title p-m-0">{carrierGroupDetail.name}</h2>
            <Button
              label={t(`CarrierGroupDetail.addCarrierToGroup`, `Přidat dopravce do skupiny`)}
              icon="icon pi pi-plus"
              className="p-btn-edit p-button-rounded"
              onClick={() => setVisibleAddCarrierToGroup(true)}
            />
          </NavTitle>
          <div className="filter-table p-mt-3 p-mb-2">
            <InputText
              id="fieldId"
              type="text"
              placeholder={t(`CarrierGroupDetail.search`, `Search`)}
              style={{ width: '100%' }}
              value={searchText}
              onChange={e => setSearchText((e.target as HTMLInputElement).value)}
            />
          </div>
          {/*
        // @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            value={carrierGroupDetail.data}
            rows={carrierGroupDetail.size}
            totalRecords={carrierGroupDetail.total}
            paginator={true}
            className="same-table responsive-table p-mt-4"
            loading={loading}
            lazy={true}
            onPage={onPage}
            first={(carrierGroupDetail.page - 1) * carrierGroupDetail.size}
          >
            <Column className="same-table__name" field="name" header={t(`CarrierGroupDetail.name`, `Název`)} body={nameBodyTemplate}></Column>
            <Column className="same-table__created" field="created" header={t(`CarrierGroupDetail.dateAdded`, `Přidáno`)} body={createdBodyTemplate}></Column>
            <Column className="same-table__createBy" field="createBy" header={t(`CarrierGroupDetail.createBy`, `Vytvořil`)} body={createByBodyTemplate}></Column>
            <Column className="same-table__nav" header={t(`CarrierGroupDetail.action`, `Akce`)} body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}
      {/*
        // @ts-ignore*/}
      <Dialog
        header={t(`CarrierGroupDetail.removeThisCarrierFromTheGroup`, `Odebrání dopravce ze skupiny`)}
        visible={removeCarrierId}
        className="modal-sm"
        onHide={() => setRemoveCarrierId(undefined)}
      >
        <div>
          <p className="p-mt-0">{t(`CarrierGroupDetail.areYouSureToRemove`, `Chcete odebrat tohoto dopravce ze skupiny?`)}</p>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`CarrierGroupDetail.remove`, `Smazat`)} className="p-mr-2 p-button-danger" onClick={() => removeCarrierFromGroup()} />
          <Button label={t(`CarrierGroupDetail.cancel`, `Zrušit`)} className="p-button-text" onClick={() => setRemoveCarrierId(undefined)} />
        </ButtonLayout>
      </Dialog>

      <AddCarrierToGroup
        visible={visibleAddCarrierToGroup}
        onHide={() => setVisibleAddCarrierToGroup(false)}
        carrierGroupId={carrierGroupId}
        onComplete={() => {
          load(searchDebounced, 10, 0);
          setVisibleAddCarrierToGroup(false);
        }}
      />
    </>
  );
};

export default CarrierGroupDetail;
