import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path fill="#616061" d="M12 14.93a2.93 2.93 0 1 1 0-5.859 2.93 2.93 0 0 1 0 5.86m0-4.36a1.43 1.43 0 1 0 0 2.861 1.43 1.43 0 0 0 0-2.86" />
    <path
      fill="#616061"
      d="M12.06 20.75a2.22 2.22 0 0 1-2.21-2.24.48.48 0 0 0-.29-.42.51.51 0 0 0-.56.09 2.24 2.24 0 0 1-1.56.65 2.26 2.26 0 0 1-1.58-.63 2.22 2.22 0 0 1 0-3.14.5.5 0 0 0 .14-.52.56.56 0 0 0-.48-.28 2.27 2.27 0 0 1-2.26-2.2 2.22 2.22 0 0 1 2.23-2.21.48.48 0 0 0 .42-.29.51.51 0 0 0-.09-.56 2.24 2.24 0 0 1-.65-1.56 2.2 2.2 0 0 1 .63-1.58 2.22 2.22 0 0 1 3.14 0 .58.58 0 0 0 .55.14.6.6 0 0 0 .31-.48A2.27 2.27 0 0 1 12 3.25a2.22 2.22 0 0 1 2.2 2.24.52.52 0 0 0 .28.44.5.5 0 0 0 .52-.11 2.24 2.24 0 0 1 1.56-.65 2.13 2.13 0 0 1 1.58.63 2.22 2.22 0 0 1 0 3.14.58.58 0 0 0-.11.55.6.6 0 0 0 .48.31 2.27 2.27 0 0 1 2.24 2.2 2.22 2.22 0 0 1-2.24 2.2.52.52 0 0 0-.44.28.5.5 0 0 0 .11.52c.412.415.645.975.65 1.56a2.2 2.2 0 0 1-.63 1.58 2.22 2.22 0 0 1-3.14 0 .5.5 0 0 0-.52-.11.56.56 0 0 0-.28.48 2.27 2.27 0 0 1-2.2 2.24m-2.71-4.21a1.8 1.8 0 0 1 .77.16 2 2 0 0 1 1.23 1.8.74.74 0 0 0 .71.75.76.76 0 0 0 .7-.77 2.06 2.06 0 0 1 1.18-1.85 2 2 0 0 1 2.18.43.707.707 0 1 0 1-1 2 2 0 0 1-.42-2.18 2 2 0 0 1 1.82-1.18.73.73 0 0 0 .74-.7.75.75 0 0 0-.77-.7 2.1 2.1 0 0 1-1.85-1.22 2.05 2.05 0 0 1 .43-2.2.707.707 0 0 0-1-1 2 2 0 0 1-3.36-1.4.73.73 0 0 0-.7-.74.75.75 0 0 0-.7.77 2.1 2.1 0 0 1-1.22 1.85 2.05 2.05 0 0 1-2.2-.43.707.707 0 0 0-1 1 2 2 0 0 1 .41 2.18 2 2 0 0 1-1.8 1.23.74.74 0 0 0-.75.71.76.76 0 0 0 .77.7 2.06 2.06 0 0 1 1.85 1.18 2 2 0 0 1-.43 2.18.707.707 0 0 0 1 1 2.06 2.06 0 0 1 1.41-.57"
    />
  </svg>
);
export default SvgMenuSettings;
