import React, { useContext } from 'react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import { RingilHeading } from '@components/styles.tsx';

interface ComponentProps {}

const IndexPage: React.FC<ComponentProps> = () => {
  const { handleWebhook } = useContext(ApiContext);
  return (
    <>
      <AdminLayout title={'I18n'}>
        <RingilHeading>I18N</RingilHeading>
        <Button label={'Refresh'} onClick={() => handleWebhook(() => {})} />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(IndexPage);
