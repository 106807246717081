import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'primereact/tooltip';

interface ComponentProps {
  id: string;
}

const Circle = styled.span`
  height: 15px;
  width: 15px;
  padding-top: 3px;
  background-color: lightgreen;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid green;
  line-height: 25px;
  position: relative;
  top: 2px;
  left: 15px;
`;

const CircleWithOnHoverText: React.FC<PropsWithChildren<ComponentProps>> = ({ id, children }) => {
  return (
    <>
      <Circle id={`circle_${id}`}></Circle>
      {/*// @ts-ignore*/}
      <Tooltip target={`#circle_${id}`}>
        <span>{children}</span>
      </Tooltip>
    </>
  );
};

export default CircleWithOnHoverText;
