import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRoute = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
    <path
      fill="#B2B2B2"
      d="M9.375 5.625a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0M28.125 24.375a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0M16.875 8.438a2.813 2.813 0 0 1 5.625 0v8.437h3.75V8.438a6.562 6.562 0 1 0-13.125 0v13.125a2.813 2.813 0 0 1-5.625 0v-8.438H3.75v8.438a6.562 6.562 0 1 0 13.125 0z"
    />
  </svg>
);
export default SvgRoute;
