import { withAuthenticationRequired } from '@auth0/auth0-react';
import AllCarriersList from '@components/pages/admin/carriers/AllCarriersList';
import AdminLayout from '@app/layout/AdminLayout.tsx';

const AdminAllCarriersPage = () => {
  return (
    <AdminLayout title={'Carriers'}>
      <AllCarriersList />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(AdminAllCarriersPage);
