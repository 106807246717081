import React from 'react';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
}

export const SaveButton: React.FC<ComponentProps> = ({ onClick, loading = false, disabled = false }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Button
        label={tr(`SaveButton.save`, `Save`)}
        /*
            iconPos="left"
            icon="pi pi-pencil"
            className="p-button-outlined"
*/
        onClick={e => onClick(e)}
        loading={loading}
        disabled={disabled}
      />
    </>
  );
};
