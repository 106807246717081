import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuRingilSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path fill="#FE5000" d="M24 0H0v24h24z" />
    <path
      fill="#fff"
      d="M12.67 7.57c0-2.878-1.335-4.029-3.527-4.029H3.14v11.892h2.668v-4.22l3.527 4.22h3.336l-3.431-4.22c.571-.095 3.43-.479 3.43-3.644M9.81 8.815H5.807V5.939H9.81z"
    />
  </svg>
);
export default SvgMenuRingilSmall;
