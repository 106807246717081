import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { ExpeditionInternalInformationOutDTO, InternalInvoiceDetailOutDTO, InvoiceRebillingTypeEnum } from '@api/logsteo-api.v2';
import UpdateExpeditionInternalInformationSidebar from './UpdateExpeditionInternalInformationSidebar';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { HeaderWithButtonText } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  applicationId: string;
  companyId: string;
  isDeprecatedByManualMode: boolean;
  isExpeditionCanceled: boolean;
}

const InternalInfoTab: React.FC<ComponentProps> = ({ companyId, applicationId, isDeprecatedByManualMode, isExpeditionCanceled }) => {
  const { tr } = useTranslationLgs();
  const { cuGetExpeditionInternalInformation, cuUpdateExpeditionInternalInformation } = useContext(ApiContext);
  const [internalInformation, setInternalInformation] = useState<ExpeditionInternalInformationOutDTO>();
  const [visibleEditSidebar, setVisibleEditSidebar] = useState(false);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const isDisabled = isDeprecatedByManualMode || isExpeditionCanceled || loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1;

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    cuGetExpeditionInternalInformation(companyId, applicationId, data => {
      setInternalInformation(data);
    });
  };

  const resolveReinvoice = (invoiceDetail: InternalInvoiceDetailOutDTO) => {
    if (invoiceDetail.invoiceRebillingType === InvoiceRebillingTypeEnum.NON_REBILLING)
      return <>{tr(`InternalInfoTab.InvoiceRebillingTypeEnum.NON_REBILLING`, 'Non rebilling')}</>;
    if (invoiceDetail.invoiceRebillingType === InvoiceRebillingTypeEnum.EXTERNAL_REBILLING) return <>{invoiceDetail?.invoicePartner?.name}</>;
    if (invoiceDetail.invoiceRebillingType === InvoiceRebillingTypeEnum.INTERNAL_REBILLING) return <>{invoiceDetail?.invoiceDepartment}</>;
    if (invoiceDetail.invoiceRebillingType === InvoiceRebillingTypeEnum.OTHER) return <>{tr(`InternalInfoTab.InvoiceRebillingTypeEnum.OTHER`, 'Other')}</>;
    return <></>;
  };

  return (
    <>
      <HeaderWithButton>
        <HeaderWithButtonText>{tr(`InternalInfoTab.internalInformation`, `Internal information`)}</HeaderWithButtonText>
        <HeaderButtonWrapper>
          <Button
            disabled={isDisabled}
            label={tr(`CreateSupplierManifest.edit`, `Edit`)}
            iconPos="left"
            icon="icon pi pi-pencil"
            className="p-button-outlined"
            onClick={e => setVisibleEditSidebar(true)}
          ></Button>
        </HeaderButtonWrapper>
      </HeaderWithButton>
      {internalInformation && (
        <>
          <HorizontalFieldValue label={tr(`InternalInfoTab.responsiblePerson`, `Responsible person`)} value={<div>{internalInformation.responsiblePerson?.name}</div>} />
          <HorizontalFieldValue
            label={tr(`InternalInfoTab.subscribers`, `Subscribers`)}
            value={<div>{internalInformation?.subscribers.map(t => t.name).join(', ')}</div>}
          />
          <HorizontalFieldValue label={tr(`InternalInfoTab.internalNote`, `Internal note`)} value={<div>{internalInformation.internalNote}</div>} />
          <hr />
          <HorizontalFieldValue label={tr(`InternalInfoTab.reinvoiceTo`, `Reinvoice to`)} value={resolveReinvoice(internalInformation.invoiceDetail)} />
          <HorizontalFieldValue label={tr(`InternalInfoTab.disponent`, `Disponent`)} value={<div>{internalInformation?.invoiceDetail?.invoiceUser?.name}</div>} />
          <HorizontalFieldValue
            label={tr(`InternalInfoTab.invoicingDetail`, `Invoicing detail`)}
            value={<div>{tr(`InternalInfoTab.InvoiceStateEnum.${internalInformation.invoiceDetail.invoiceState}`, '')}</div>}
          />
          <HorizontalFieldValue label={tr(`InternalInfoTab.pairingSymbol`, `Pairing symbol`)} value={<div>{internalInformation.pairingSymbol}</div>} />
        </>
      )}

      <UpdateExpeditionInternalInformationSidebar
        applicationId={applicationId}
        companyId={companyId}
        visible={visibleEditSidebar}
        onHide={() => setVisibleEditSidebar(false)}
        onChanged={() => {
          setVisibleEditSidebar(false);
          load();
        }}
      />
    </>
  );
};
const HeaderButtonWrapper = styled.div`
  display: block;
`;
const HeaderWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default InternalInfoTab;
