import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect';
import { DistributionTypeEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  selectedItemCode: string;
  onSelect: (selectedItemCode: string) => void;
}

const InputSpotDirect: React.FC<ComponentProps> = ({ onSelect, selectedItemCode }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <InputSelect
        model={[
          {
            label: tr('InputSpotDirect.spot', 'Spotova poptavka'),
            code: DistributionTypeEnum.SPOT,
          },
          {
            label: tr('InputSpotDirect.fixedPrice', 'Pevna cena'),
            code: DistributionTypeEnum.FIXED_PRICE,
          },
        ]}
        selectedItemCode={selectedItemCode}
        onSelect={v => {
          onSelect(v);
        }}
      />
    </>
  );
};

export default InputSpotDirect;
