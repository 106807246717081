import React from 'react';
import { Clickable } from '@components/styles.tsx';

interface ComponentProps {
  onClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  disabled?: boolean;
}

export const ClearButton: React.FC<ComponentProps> = ({ onClick, disabled = false }) => {
  return <Clickable>{!disabled && <span className="pi pi-trash" onClick={e => onClick(e)} />}</Clickable>;
};
