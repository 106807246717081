import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import * as yup from 'yup';
import SvgArrayDown from '@components/framework/icons/ArrayDown.tsx';

interface ComponentProps {
  title: string;
}

const MoreLink: React.FC<ComponentProps> = ({ title }) => {
  const {} = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  return (
    <div className={'inline-flex'}>
      <div className={'flex flex-row gap-2 items-center text-content-brand py-4'}>
        {title}
        <SvgArrayDown />
      </div>
    </div>
  );
};

export default MoreLink;
