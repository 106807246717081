import React, { useState, useRef, useEffect } from 'react';

const MenuComponent: React.FC = () => {
  const [showScrollHintTop, setShowScrollHintTop] = useState(false);
  const [showScrollHintBottom, setShowScrollHintBottom] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (menuRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
      setShowScrollHintTop(scrollTop > 0);
      setShowScrollHintBottom(scrollTop + clientHeight < scrollHeight);
    }
  };

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.addEventListener('scroll', handleScroll);
      handleScroll(); // Pro kontrolu při načtení
    }

    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="flex flex-col relative" style={{ height: '100vh' }}>
      {/* Horní část s logem */}
      <div className="h-[80px] bg-gray-800 text-white flex items-center justify-center">
        <div>Logo</div>
      </div>

      {/* Prostřední část se scrollovatelným menu */}
      <div ref={menuRef} className="flex-1 overflow-y-auto bg-white p-4">
        <ul>
          {Array.from({ length: 30 }, (_, index) => (
            <li key={index} className="py-2">
              Položka menu {index + 1}
            </li>
          ))}
        </ul>
      </div>

      {/* Nápověda nahoře, pevně umístěná na spodní hraně horní části */}
      {showScrollHintBottom && (
        <div className="absolute bottom-[80px] left-1/2 transform -translate-x-1/2 translate-y-1/2 bg-blue-500 text-white px-2 py-1 rounded z-10">více</div>
      )}

      {/* Spodní část s jazykem a profilem */}
      <div className="h-[80px] bg-gray-200 flex items-center justify-center relative">
        <div>Jazyk a Profil</div>
      </div>

      {/* Nápověda dole, pevně umístěná na horní hraně spodní části */}
      {showScrollHintTop && (
        <div className="absolute top-[80px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white px-2 py-1 rounded z-10">více</div>
      )}
    </div>
  );
};

export default MenuComponent;
