import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  className?: any;
  width: number;
  height: number;
  length: number;
  onChangeDimensions: (propert: string, value: number) => void;
  widthError?: string;
  heightError?: string;
  lengthError?: string;
  disabledInputs?: string[];
}

export const DimensionsSelector: React.FC<ComponentProps> = ({
  heightError,
  lengthError,
  widthError,
  width,
  height,
  length,
  onChangeDimensions,
  disabledInputs = [],
}) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="dimensions-selector-wrapper p-field p-col-12 p-md-2">
        <Label title={t(`loadDetailsPage.panelBooking.labelLength`)} subTitle={t(`loadDetailsPage.panelBooking.subTitleUnit`)} required />
        <InputNumber
          value={length}
          onChange={e => {
            onChangeDimensions('length', e.value);
          }}
          disabled={disabledInputs.some(t => t === 'length')}
        />
        {lengthError && <div className="p-invalid-feedback">{lengthError}</div>}
      </div>
      <div className="width-selector-wrapper p-field p-col-12 p-md-2">
        <Label title={t(`loadDetailsPage.panelBooking.labelWidth`)} subTitle={t(`loadDetailsPage.panelBooking.subTitleUnit`)} required />
        <InputNumber
          value={width}
          onChange={e => {
            onChangeDimensions('width', e.value);
          }}
          disabled={disabledInputs.some(t => t === 'width')}
        />
        {widthError && <div className="p-invalid-feedback">{widthError}</div>}
      </div>
      <div className="height-selector-wrapper p-field p-col-12 p-md-2">
        <Label title={t(`loadDetailsPage.panelBooking.labelHeight`)} subTitle={t(`loadDetailsPage.panelBooking.subTitleUnit`)} required />
        <InputNumber
          value={height}
          onChange={e => {
            onChangeDimensions('height', e.value);
          }}
          disabled={disabledInputs.some(t => t === 'height')}
        />
        {heightError && <div className="p-invalid-feedback">{heightError}</div>}
      </div>
    </>
  );
};
