import React, { ReactElement } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface ComponentProps {
  icon?: ReactElement;
  rightIcon?: ReactElement;
  label: string;
  caption?: string;
  className?: string;
}

const COItemDisplay: React.FC<ComponentProps> = ({ icon, rightIcon, label, caption, className = '' }) => {
  const { tr } = useTranslationLgs();
  return (
    <div className={twMerge('flex flex-row p-4 gap-4 justify-between items-center', className)}>
      <div className={'flex flex-row gap-4 items-center'}>
        {icon && <div>{icon}</div>}
        <div className={'flex flex-col'}>
          <div className={'text-base'}>{label}</div>
          {caption && <div className={'text-content-gr1 text-sm'}>{caption}</div>}
        </div>
      </div>
      {rightIcon && <div className={'flex items-center'}>{rightIcon}</div>}
    </div>
  );
};

export default COItemDisplay;
