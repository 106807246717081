import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox';
import SvgNoteInfo from '@components/ringil3/icons/NoteInfo';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import { ColWithGap } from '@components/styles.tsx';

interface SidebarData {
  selectedQuoteId: string;
  selectedCarrierName: string;
  applicationId: string;
  companyId: string;
}

interface ComponentProps {
  onCompleted: () => void;
}

export const useCreateAcceptQuote = () => {
  const [sidebarData, setSidebarData] = useState<SidebarData>(undefined);

  const AcceptQuote: React.FC<ComponentProps> = ({ onCompleted }) => {
    const { tr } = useTranslationLgs();
    const { cuAcceptDemand } = useContext(ApiContext);
    const [isReconfirmedNeeded, setIsReconfirmedNeeded] = useState(false);
    const { BackendValidationComponent, setBeValidationErrors: onValidationFailed } = useBEValidation();

    if (isNullOrUndefined(sidebarData)) return <></>;

    const save = () => {
      cuAcceptDemand(
        sidebarData.companyId,
        sidebarData.applicationId,
        sidebarData.selectedQuoteId,
        { isReconfirmedNeeded },
        () => {
          setSidebarData(null);
          onCompleted();
        },
        null,
        { onValidationFailed },
      );
    };

    return (
      <>
        <RingilSidebar
          visible={isNotNullOrUndefined(sidebarData)}
          heading={tr(`AcceptQuote.selectACarrierQuote`, `Select a carrier quote`)}
          onContinue={() => save()}
          onBack={() => setSidebarData(null)}
        >
          <ColWithGap>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr(`AcceptQuote.forExpedition`, `For expedition`)}
              valueText={sidebarData?.applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />
            <VerticalFieldAndValueWithIcon
              label={tr(`AcceptQuote.selectedCarrier`, `Selected carrier`)}
              valueText={sidebarData?.selectedCarrierName}
              iconPath={'/images/icons/ringil3/trucktype.svg'}
              showHr={true}
            />
            <COInlineMessage icon={<SvgNoteInfo />} variant={'info'}>
              <p>Potvrzením vyberete dopravce k realizaci přepravy. Dopravce bude ihned informován e-mailem.</p>
              <p>Cenové nabídky ostatních dopravců budou odmítnuty a dopravci budou informováni e-mailem.</p>
            </COInlineMessage>
            <InputCheckbox
              value={isReconfirmedNeeded}
              onChange={(_, v) => setIsReconfirmedNeeded(v)}
              label={'Požádat dopravce o příjetí přeravy'}
              note={'Dopravci bude odeslán potvzrující e-mail se žádost o přijetí přeravy. <br />\n' + '          Potvzení neblokuje další průběh přepravy'}
            />
          </ColWithGap>
        </RingilSidebar>
      </>
    );
  };

  return { AcceptQuote, setSidebarData };
};
