import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';

import { dateFormatDateTime, dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { DistributionTypeEnum, ExpeditionStatusEnum, FeaturesEnum, LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { AddMoreCarrierDialog } from '../AddMoreCarriersDialog/AddMoreCarrierDialog';
import QuoteDataTable from '../QuoteDataTable/QuoteDataTable';
import FixedQuoteDataTable from '../QuoteDataTable/FixedQuoteDataTable';
import useTranslationLgs from '../../../../../hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { Tooltip } from 'primereact/tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { CustomerExpeditionViewContext } from '@app/pages/customer/expedition-detail/CustomerExpeditionView.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import CardViewWrapper from '@components/obsolete/new-expedition/CardView/CardViewWrapper.tsx';
import CardView from '@components/obsolete/new-expedition/CardView/CardView.tsx';
import { formatPrice } from '@utils/utils.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import CheckboxItem from '@components/obsolete/CheckboxWrapper/CheckboxItem.tsx';
import { Checkbox } from 'primereact/checkbox';
import { CenteredRowWithGap } from '@components/styles.tsx';
import EditDistributionSidebar from '@app/pages/customer/expedition-detail/EditDistribution/EditDistributionSidebar.tsx';
import PickSidebar from '@app/pages/customer/expedition-detail/TabCarrierPending/Pick/Pick.tsx';
import RejectSidebar from '@app/pages/customer/expedition-detail/TabCarrierPending/Pick/Reject.tsx';

interface ComponentProps {
  customerId: string;
  applicationId: string;
  requirements: LovWithoutIdDTO[];
  selectedHistory: () => void;
  isExpeditionCanceled: boolean;
  isDeprecatedByManualMode: boolean;
}

const TabCarrierPending: React.FC<ComponentProps> = ({ customerId, applicationId, requirements, selectedHistory, isExpeditionCanceled, isDeprecatedByManualMode }) => {
  const { t }: { t: any } = useTranslation('common'),
    { tr } = useTranslationLgs(),
    { state, loadTabAssigning, showPickDialog, hidePickDialog, hideRejectDialog, rejectQuote, addCarrierToDistribution, showRejectDialog, editRequestCoverage } =
      useContext(CustomerExpeditionViewContext),
    { sendToEurowag } = useContext(ApiContext),
    { loggedUser } = useContext(AuthenticatedUserContext);

  const isDisabled = isExpeditionCanceled || isDeprecatedByManualMode || loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1;

  useEffect(() => loadTabAssigning(customerId, applicationId), []);

  const [editCoverageRequestModal, setEditCoverageRequestModal] = useState(false);
  const [addMoreCarriersModal, setAddMoreCarriersModal] = useState(false);

  const { showToastMessage } = useContext(ToastContext);

  const sendToEurowagHandler = () => {
    sendToEurowag(
      {
        customerId,
        applicationId,
      },
      () => {
        loadTabAssigning(customerId, applicationId);
      },
      null,
      {
        onValidationFailed: error => {
          showToastMessage(
            tr('TabCarrierPending.cannotSendToEurowag', 'Cannot send to Eurowag'),
            error.map((t: any) => <div>{t.message}</div>),
            'error',
          );
        },
      },
    );
  };

  return (
    <>
      {state.tabAssigningData && (
        <>
          <NavTitle>
            <Label title={t('customerExpeditionDetail.tabCarrierPending.yourCoverageRequest', 'Your coverage request')} />
            <Button
              label={t(`TabCarrierPending.change`, `Change`)}
              iconPos="left"
              icon="pi pi-pencil"
              onClick={() => setEditCoverageRequestModal(true)}
              className="p-button-outlined p-btn-edit"
              disabled={isDisabled}
            />
          </NavTitle>
          <CardViewWrapper>
            <CardView title={t('customerExpeditionDetail.tabCarrierPending.validTill', 'Platné do')}>
              <div className="p-d-flex p-flex-wrap">
                <strong className="p-pr-2">{dateFormatDateTime(mapFromAPIDateTime(state.tabAssigningData.validTill))}</strong>{' '}
                <span>
                  {t('customerExpeditionDetail.tabCarrierPending.remaining', {
                    count: state.tabAssigningData.remainingTimeInHours,
                  })}
                </span>
              </div>
            </CardView>
            <CardView title={t(`customerExpeditionDetail.tabCarrierPending.Type${state.header.distributionType}`)}>
              {state.tabAssigningData.price && <strong>{formatPrice(state.tabAssigningData.price, state.tabAssigningData.currency)}</strong>}
              {!state.tabAssigningData.price && <strong>-</strong>}
            </CardView>
            <CardView title={t(`TabCarrierPending.sendToTheCarriers`, `Send to the carriers`)}>
              {state.header.isPublished ? (
                <strong>
                  {tr(`TabCarrierPending.publishedBy`, `published by {{user}} at {{date}}`, {
                    user: state.header.publishedByName,
                    date: dateFormatOnlyDateShort(mapFromAPIDateTime(state.header.publishedAt)),
                  })}
                </strong>
              ) : (
                <strong>{tr(`TabCarrierPending.notPublishedYet`, `Not published yet`)}</strong>
              )}
            </CardView>
          </CardViewWrapper>
          <ButtonLayout wrapper="WITH_PADDING_TOP_2">
            {requirements.map((requirement, index) => (
              <CheckboxItem className="p-md-3" key={index}>
                <Checkbox checked={true} className="p-mr-2" disabled />
                <label>{requirement.name}</label>
              </CheckboxItem>
            ))}
          </ButtonLayout>
          <NavTitle>
            <Label
              title={t(`customerExpeditionDetail.tabCarrierPending.priceFromCarrier`, {
                count: state.tabAssigningData.quotes.length,
              })}
            />
            <CenteredRowWithGap>
              <Button
                label={t(`TabCarrierPending.addMoreCarriers`, `Přidat další dopravce`)}
                icon="pi pi-plus"
                className="p-button-outlined p-btn-edit"
                onClick={() => setAddMoreCarriersModal(true)}
                disabled={isDisabled}
              />
              {loggedUser?.features?.find(t => t === FeaturesEnum.EW) && (
                <>
                  {state.header.distributionType === DistributionTypeEnum.SPOT && (
                    <Button
                      label={tr(`TabCarrierPending.sendToEurowag`, `Send to eurowag`)}
                      icon="pi pi-plus"
                      className="p-button-outlined p-btn-edit"
                      onClick={() => sendToEurowagHandler()}
                      disabled={isDisabled || state.header.systemLabels.find((t: any) => t.name === 'EW') != undefined}
                    />
                  )}

                  {state.header.systemLabels.find((t: any) => t.name === 'EW') && (
                    <>
                      {/*//
      @ts-ignore*/}
                      <Tooltip target={'#ewId'} position={'left'} autoHide={false}>
                        {tr(`TabCarrierPending.ewIdId`, `EW Id: {{id}}`, {
                          id: state.header.systemLabels.find((t: any) => t.name === 'EW').additionalInfo,
                        })}
                      </Tooltip>
                      <span id="ewId" className={'pi pi-hashtag'}></span>
                    </>
                  )}
                </>
              )}
            </CenteredRowWithGap>
          </NavTitle>
          {state.header.distributionType === DistributionTypeEnum.FIXED_PRICE && (
            <FixedQuoteDataTable
              applicationId={state.header.applicationId}
              companyId={state.header.companyId}
              disabled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED || state.header.isManualExpedition}
            />
          )}
          {state.header.distributionType === DistributionTypeEnum.SPOT && (
            <QuoteDataTable
              applicationId={state.header.applicationId}
              companyId={state.header.companyId}
              disabled={
                state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED ||
                state.header.isManualExpedition ||
                loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1
              }
            />
          )}
          <div className="p-mt-6">
            <p>
              <strong>{t(`TabCarrierPending.coverageRequestDetail`, `Detail poptávky`)}</strong>
            </p>
            <p>
              <span>
                <Trans
                  i18nKey="TabCarrierPending.requestCreatedByOn"
                  values={{
                    userName: state.tabAssigningData.createdByName,
                    at: dateFormatDateTime(mapFromAPIDateTime(state.tabAssigningData.createdAt)),
                  }}></Trans>
              </span>
            </p>
          </div>
          <ButtonLayout>
            <Button
              label={t(`TabCarrierPending.allActivitiesForThisExpedition`, `Všechny aktivity k této expedici`)}
              className="p-button-outlined"
              icon="pi pi-angle-right"
              iconPos="right"
              onClick={() => selectedHistory()}
            />
          </ButtonLayout>
          <EditDistributionSidebar
            visible={editCoverageRequestModal}
            onHide={() => setEditCoverageRequestModal(false)}
            distributionType={
              // @ts-ignore
              DistributionTypeEnum[state.header.distributionType]
            }
            price={state.tabAssigningData.price}
            validTill={mapFromAPIDateTime(state.tabAssigningData.validTill)}
            isPublished={state.header.isPublished}
            publishedByName={state.header.publishedByName}
            publishedAt={state.header.publishedAt}
            onSave={data => {
              editRequestCoverage(data);
              setEditCoverageRequestModal(false);
            }}
            currency={state.tabAssigningData.currency}
          />
          <AddMoreCarrierDialog
            visible={addMoreCarriersModal}
            onHide={() => setAddMoreCarriersModal(false)}
            onSelect={e => {
              addCarrierToDistribution(e);
              setAddMoreCarriersModal(false);
            }}
            applicationId={state.header.applicationId}
            companyId={state.header.companyId}
          />
          <PickSidebar
            pickSidebarData={state.pickSidebarData}
            applicationId={state.header.applicationId}
            customerId={state.header.companyId}
            onBackButton={() => hidePickDialog()}
            quoteId={state.pickSidebarData.quoteId}
            onContinue={(quoteId, isReconfirmedNeeded) => {
              window.location.reload();
            }}
          />
          <RejectSidebar
            rejectSidebarData={state.rejectSidebarData}
            onBackButton={() => hideRejectDialog()}
            onReject={(quoteId, reasonText) => {
              rejectQuote(state.header.companyId, state.header.applicationId, quoteId, reasonText);
              hideRejectDialog();
            }}
          />
        </>
      )}
    </>
  );
};

export default TabCarrierPending;
