import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import {
  CarrierModuleOutDTO,
  ExpeditionModuleOutDTO,
  ModulesPreviewDataOutDTO,
  PermissionTypesEnum,
  SupplierManifestModuleOutDTO,
  TimeslotsModuleOutDTO,
} from '@/api/logsteo-api.v2';
import { isNotNullOrUndefined, isNullOrUndefined } from '@/utils/utils';
import useTranslationLgs from '@/hooks/i18n/useTranslation';
import ExpeditionAd from './ExpeditionAd';
import CarrierAd from './CarrierAd';
import TimeslotAd from './TimeslotAd';
import SupplierManifestAd from './SupplierManifestAd';
import { Clickable, Ringil3H1 } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { Link, useNavigate } from 'react-router-dom';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';

interface ComponentProps {}

const ModuleDashboard: React.FC<ComponentProps> = () => {
  const { getModulesDashboard } = useContext(ApiContext);
  const [moduleDashboard, setModuleDashboard] = useState<ModulesPreviewDataOutDTO>();
  const { tr } = useTranslationLgs();
  const { hasPermission, loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    getModulesDashboard(d => {
      setModuleDashboard(d);
    });
  };

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      {moduleDashboard && (
        <ModuleDashboardWrapper>
          {moduleDashboard.expeditionModule?.isActive && <ExpeditionModule moduleData={moduleDashboard.expeditionModule}></ExpeditionModule>}
          {moduleDashboard.timeslotsModule?.isActive && (
            <TimeslotModule
              moduleData={moduleDashboard.timeslotsModule}
              onReload={() => {
                load();
              }}
            ></TimeslotModule>
          )}
          {moduleDashboard.supplierManifestModule?.isActive && <SupplyManifestModule moduleData={moduleDashboard.supplierManifestModule}></SupplyManifestModule>}
          {moduleDashboard.carrierModule?.isActive && <CarrierModule moduleData={moduleDashboard.carrierModule}></CarrierModule>}
        </ModuleDashboardWrapper>
      )}
      <Ringil3H1>{tr(`ModuleDashboard.availableAddons`, `Available addons`)}</Ringil3H1>
      {moduleDashboard && (
        <AvailableModulesWrapper>
          {!moduleDashboard.expeditionModule.isActive && <ExpeditionAd />}
          {!moduleDashboard.timeslotsModule.isActive && <TimeslotAd />}
          {!moduleDashboard.supplierManifestModule.isActive && <SupplierManifestAd />}

          {!moduleDashboard.carrierModule.isActive && <CarrierAd />}
        </AvailableModulesWrapper>
      )}
    </>
  );
};

const AvailableModulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

interface ExpeditionModuleProps {
  moduleData: ExpeditionModuleOutDTO;
}

const ExpeditionModule: React.FC<ExpeditionModuleProps> = ({ moduleData }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const nav = useNavigation();

  return (
    <>
      {loggedUser && (
        <ModuleWrapperComp
          moduleHeading={tr(`ModuleDashboard.myActiveExpeditions`, `My active expeditions ({{activeExpeditionCount}})`, {
            activeExpeditionCount: moduleData.activeExpeditionCount,
          })}
          moduleMetrics={[
            {
              metricName: tr(`ModuleDashboard.choosingCarrier`, `Choosing carrier`),
              iconName: 'hours.svg',
              value: moduleData.choosingCarrierExpeditionCount.toLocaleString(),
              /*metricsUrl: `/customer/expeditions?responsiblePersonIds%5B0%5D=${loggedUser.id}&statusEnums%5B0%5D=ASSIGNING&pageNumber=1&pageSize=20`,*/
              metricsUrl: nav.createNavigationLink(
                nav.urlFunctions.createCustomerExpeditionList({
                  responsiblePersonIds: loggedUser.id,
                  statusEnums: ['ASSIGNING'],
                }),
              ),
            },
            {
              metricName: tr('ModuleDashboard.w4dExpeditionCount', 'Waiting for documents'),
              iconName: 'folder.svg',
              value: moduleData.w4dExpeditionCount.toLocaleString(),
              /*metricsUrl: `/customer/expeditions?responsiblePersonIds%5B0%5D=${loggedUser.id}&statusEnums%5B0%5D=WAITING_FOR_DOCS&pageNumber=1&pageSize=20`,*/
              metricsUrl: nav.createNavigationLink(
                nav.urlFunctions.createCustomerExpeditionList({
                  responsiblePersonIds: loggedUser.id,
                  statusEnums: ['WAITING_FOR_DOCS'],
                }),
              ),
            },
            {
              metricName: tr('ModuleDashboard.workInProgress', 'Work in progress'),
              iconName: 'folder.svg',
              value: moduleData.workInProgressExpeditionCount.toLocaleString(),
              /*metricsUrl: `/customer/expeditions?responsiblePersonIds%5B0%5D=${loggedUser.id}&statusEnums%5B0%5D=NEW&pageNumber=1&pageSize=20`,*/
              metricsUrl: nav.createNavigationLink(
                nav.urlFunctions.createCustomerExpeditionList({
                  responsiblePersonIds: loggedUser.id,
                  statusEnums: ['NEW'],
                }),
              ),
            },
            {
              metricName: tr('ModuleDashboard.inProgress', 'In progress'),
              iconName: 'car.svg',
              value: moduleData.inProgressExpeditionCount.toLocaleString(),
              /*metricsUrl: `/customer/expeditions?responsiblePersonIds%5B0%5D=${loggedUser.id}&statusEnums=ACTIVE&statusEnums=READY_TO_SHIPMENT&statusEnums=ASSIGNED&pageNumber=1&pageSize=20`,*/
              metricsUrl: nav.createNavigationLink(
                nav.urlFunctions.createCustomerExpeditionList({
                  responsiblePersonIds: loggedUser.id,
                  statusEnums: ['ACTIVE', 'READY_TO_SHIPMENT', 'ASSIGNED'],
                }),
              ),
            },
          ]}
          links={[
            {
              label: tr('ModuleDashboard.newExpedition', '+ New expedition'),
              linkURL: nav.createNavigationLink(nav.urlFunctions.createCustomerNewExpedition()),
              isButton: true,
              disabled: loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1,
            },
            {
              label: tr('ModuleDashboard.allExpeditions', 'All expeditions'),
              linkURL: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionList()),
              isButton: false,
              disabled: false,
            },
          ]}
        />
      )}
    </>
  );
};

interface TimeslotModuleProps {
  moduleData: TimeslotsModuleOutDTO;
  onReload: () => void;
}

const TimeslotModule: React.FC<TimeslotModuleProps> = ({ moduleData, onReload }) => {
  const { tr } = useTranslationLgs();
  const { hasPermission } = useContext(AuthenticatedUserContext);
  const nav = useNavigation();
  const { CreateTimeslotSidebar, createTimeslotRequest, setCreateTimeslotRequest } = useCreateTimeslotSidebar();

  return (
    <>
      <ModuleWrapperComp
        moduleHeading={tr(`ModuleDashboard.myActiveTimeslots`, `Timeslots in next 7 days ({{in7DaysTimeslotsCount}})`, {
          in7DaysTimeslotsCount: moduleData.in7DaysTimeslotsCount,
        })}
        moduleMetrics={[
          {
            metricName: tr(`ModuleDashboard.todayTimeslots`, `Timeslots today`),
            iconName: 'folder.svg',
            value: moduleData.todayTimeslotsCount.toLocaleString(),
            metricsUrl: null,
          },
          {
            metricName: tr('ModuleDashboard.tomorrowTimeslots', 'Timeslots tomorrow'),
            iconName: 'clock.svg',
            value: moduleData.tomorrowTimeslotsCount.toLocaleString(),
            metricsUrl: null,
          },
        ]}
        links={[
          {
            label: tr('ModuleDashboard.newTimeslot', '+ New timeslot'),
            onClick: () => {
              setCreateTimeslotRequest({
                applicationId: null,
                companyLocationId: null,
                preferredDate: '',
                companyLocationCustomerId: null,
                expeditionLocationId: null,
                expeditionId: null,
              });
            },
            isButton: true,
            disabled: !hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOTS, PermissionTypesEnum.PERMISSION_TIMESLOT_CREATE]),
          },
          {
            label: tr('ModuleDashboard.allTimeslots', 'All timeslots'),
            linkURL: nav.createNavigationLink(nav.urlFunctions.createReservationList()),
            isButton: false,
            disabled: !hasPermission([
              PermissionTypesEnum.PERMISSION_TIMESLOTS,
              PermissionTypesEnum.PERMISSION_TIMESLOT_READ,
              PermissionTypesEnum.PERMISSION_TIMESLOT_FULLREAD,
            ]),
          },
        ]}
      />
      {createTimeslotRequest && (
        <CreateTimeslotSidebar
          onContinue={reservation => {
            onReload();
          }}
          headerDisabled={false}
        />
      )}
    </>
  );
};

interface CarrierModuleProps {
  moduleData: CarrierModuleOutDTO;
}

const CarrierModule: React.FC<CarrierModuleProps> = ({ moduleData }) => {
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  return (
    <>
      <ModuleWrapperComp
        moduleHeading={tr(`CarrierModule.supplier`, `Supplier`)}
        moduleMetrics={[
          {
            metricName: tr('CarrierModule.demandsWaitingForQuote', 'Demands waiting for quote'),
            iconName: 'folder.svg',
            value: moduleData.waitingForQuote.toLocaleString(),
            /*metricsUrl: '/carrier/demands?pageNumber=1&pageSize=20&pseudoState=ASSIGNING',*/
            metricsUrl: nav.createNavigationLink(nav.urlFunctions.createCarrierDemandsList()),
          },
          {
            metricName: tr('ModuleDashboard.numberOfValidGroupDemands', 'Number of valid group demands'),
            iconName: 'clock.svg',
            value: moduleData.waitingGroupDemands.toLocaleString(),
            /*metricsUrl: `/carrier/multi-demands?groupDemandState[]=NEW&validTill=${new Date().toISOString()}`,*/
            metricsUrl: nav.createNavigationLink(
              nav.urlFunctions.createCarrierMultiDemandsList({
                // FIXME: proc nejde udelat pole? groupDemandState: ['NEW'],
                groupDemandState: ['NEW'],
                validTill: new Date().toISOString(),
              }),
            ),
          },
          {
            metricName: tr('CarrierModule.shipmentsWaitingForLicencePlate', 'Shipments waiting for licence plate'),
            iconName: 'clock.svg',
            value: moduleData.waitingForCarPlate.toLocaleString(),
            /*metricsUrl: '/carrier/shipments?pageNumber=1&pageSize=20&carrierExpeditionStatus=ASSIGNED',*/
            metricsUrl: nav.createNavigationLink(
              nav.urlFunctions.createCarrierShipmentsList({
                carrierExpeditionStatus: 'ASSIGNED',
              }),
            ),
          },
        ]}
        links={[
          {
            label: tr('CarrierModule.allDemands', 'All demands'),
            linkURL: nav.createNavigationLink(nav.urlFunctions.createCarrierDemandsList()),
            isButton: false,
            disabled: false,
          },
          {
            label: tr(`ModuleDashboard.allMultidemands`, `All multidemands`),
            linkURL: nav.createNavigationLink(nav.urlFunctions.createCarrierMultiDemandsList()),
            isButton: false,
            disabled: false,
          },
          {
            label: tr('CarrierModule.allSupplyManifests', 'All supply manifests'),
            linkURL: nav.createNavigationLink(nav.urlFunctions.createCarrierShipmentsList()),
            isButton: false,
            disabled: false,
          },
        ]}
      />
    </>
  );
};

interface SupplyManifestModuleProps {
  moduleData: SupplierManifestModuleOutDTO;
}

const SupplyManifestModule: React.FC<SupplyManifestModuleProps> = ({ moduleData }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {/*<ModuleWrapperComp
        moduleHeading={tr(
          `ModuleDashboard.allSupplierManifestCount`,
          `Supply manifests ({{allSupplierManifestCount}})`,
          {
            allSupplierManifestCount: moduleData.allSupplierManifestCount,
          },
        )}
        moduleMetrics={[
          {
            metricName: tr(`ModuleDashboard.mySupplyManifestsCount`, `My supply manifests`),
            iconName: 'folder.svg',
            value: moduleData.mySupplierManifestCount.toLocaleString(),
            metricsUrl: null,
          },
          {
            metricName: tr('ModuleDashboard.supplierManifestNoResponsible', 'All supply manifests'),
            iconName: 'clock.svg',
            value: moduleData.noResponsiblePersonSupplierManifestCount.toLocaleString(),
            metricsUrl: null,
          },
        ]}
        links={[
          {
            label: tr('ModuleDashboard.newSupplyManifest', '+ New supply manifest'),
            isButton: true,
            linkURL: '/supplier-manifest',
            disabled: false,
          },
          {
            label: tr('ModuleDashboard.allSupplyManifests', 'All supply manifests'),
            linkURL: '/supplier-manifest/list',
            isButton: false,
            disabled: false,
          },
        ]}
      />*/}
    </>
  );
};

interface ModuleAnchor {
  label: string;
  linkURL?: string;
  onClick?: () => void;
  isButton: boolean;
  disabled: boolean;
}

interface ModuleMetric {
  iconName: string;
  value: string;
  metricName: string;
  metricsUrl: string;
}

interface ModuleWrapperCompProps {
  moduleHeading: string;
  moduleMetrics: ModuleMetric[];
  links: ModuleAnchor[];
}

const ModuleWrapperComp: React.FC<ModuleWrapperCompProps> = ({ moduleHeading, links, moduleMetrics }) => {
  const navigate = useNavigate();

  return (
    <>
      <ModuleWrapper>
        <ModuleHeading>{moduleHeading}</ModuleHeading>
        <MetricWrapper>
          {moduleMetrics.map((metrics, metricsKey) => {
            return (
              <Module key={metricsKey}>
                <Icon src={`/icons/moduledashboard/${metrics.iconName}`}></Icon>
                <MetricName>{metrics.metricName}</MetricName>
                <div></div>
                {metrics.metricsUrl && (
                  <Link to={metrics.metricsUrl}>
                    <Clickable>
                      <MetricValue>{metrics.value}</MetricValue>
                    </Clickable>
                  </Link>
                )}
                {!metrics.metricsUrl && <MetricValue>{metrics.value}</MetricValue>}
              </Module>
            );
          })}
        </MetricWrapper>
        <Buttons>
          {links.map((moduleLink, moduleLinkIterator) => {
            return (
              <React.Fragment key={moduleLinkIterator}>
                {moduleLink.isButton && (
                  <RingilButton
                    label={moduleLink.label}
                    disabled={moduleLink.disabled}
                    mode={'outlined'}
                    onClick={() => {
                      if (isNotNullOrUndefined(moduleLink.onClick)) {
                        moduleLink.onClick();
                      } else {
                        navigate(moduleLink.linkURL);
                      }
                    }}
                  ></RingilButton>
                )}
                {!moduleLink.isButton && <Link to={moduleLink.linkURL}>{moduleLink.label}</Link>}
              </React.Fragment>
            );
          })}
        </Buttons>
      </ModuleWrapper>
    </>
  );
};

/*const Link = styled.a`
  font-weight: 300;
  cursor: pointer;
`;*/

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  height: 13px;
  vertical-align: center;
  grid-area: a;
  align-self: center;
`;
const MetricName = styled.div`
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333;
  grid-area: b;
`;
const MetricValue = styled.div`
  font-size: 20px;

  grid-area: c;
`;

const Module = styled.div`
  display: grid;
  grid-template-columns: 35px auto;
  grid-template-areas:
    'a b'
    'd c';
`;

const MetricWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const ModuleHeading = styled.div`
  font-weight: 600;
  letter-spacing: normal;
  font-size: 18px;
`;
const ModuleWrapper = styled.div`
  display: flex;
  row-gap: 2rem;
  padding: 1rem 2rem;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgb(102 102 102 / 35%);
`;

const ModuleDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default ModuleDashboard;
