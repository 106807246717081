import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: () => void;
  phoneNumber: string;
}

export const LoginSmsDialog: React.FC<ComponentProps> = ({ visible, onHide, onComplete, phoneNumber }) => {
  const { t } = useTranslation('common');
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog className="modal-sm" header={t(`LoginSmsDialog.sendSmsHeader`, `Odeslat sms`)} visible={visible} onHide={onHide}>
        <div>
          <p className="p-mt-0">
            {t(`LoginSmsDialog.sendSmsText`, `We will send to this {{phoneNumber}} login SMS to mobile application.`, { phoneNumber: phoneNumber })}
          </p>
        </div>
        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          <Button label={t(`LoginSmsDialog.sendSms`, `Send`)} className="p-mr-2 p-button" onClick={() => onComplete()} />
          <Button label={t(`LoginSmsDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};
