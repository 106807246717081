interface ComponentProps {
  number?: string;
  address?: string | JSX.Element;
  company?: string;
}

const TimeslotsProgressTitle: React.FC<ComponentProps> = ({ number, address, company }) => {
  return (
    <div className="timeslotsProgress__title p-d-flex">
      <div className="number">{number}</div>
      <div>
        <div className="timeslots-address">{address}</div>
        <div className="timeslots-company">
          <small className="text-gray5">{company}</small>
        </div>
      </div>
    </div>
  );
};
export default TimeslotsProgressTitle;
