import React, { useContext, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { Button } from 'primereact/button';
import { useImmer } from 'use-immer';
import { ExpeditionTypeEnum, LoadingTypesEnum } from '@api/logsteo-api.v2';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import { findValidationMessage } from '@utils/validation';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { TransportationTypeSelector } from '@components/obsolete/common/TransportationTypeSelector.tsx';
import { TruckTypeSelector } from '@components/obsolete/common/TruckTypeSelector.tsx';
import { LoadingTypeSelector } from '@components/obsolete/common/LoadingTypeSelector.tsx';
import { SpecialRequirementsSelector } from '@components/obsolete/common/SpecialRequirementsSelector.tsx';
import { CargoTypeSelector } from '@components/obsolete/common/CargoTypeSelector.tsx';
import { TextArea } from '@components/obsolete/common/TextArea.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: (data: ComponentData) => void;
  templateId: string;
}

export interface ComponentData {
  expeditionType: ExpeditionTypeEnum;
  truckTypeId: string;
  selectedRequirement: string[];
  cargoTypeCode: string;
  adrWeight?: number;
  manipulationTypes: LoadingTypesEnum[];
  carrierNote: string;
}

const EditRequirementSidebar: React.FC<ComponentProps> = ({ visible, onHide, onComplete, templateId }) => {
  const { t } = useTranslation('common');
  const [state, setState] = useImmer<ComponentData>(undefined);
  const { cuGetEditTemplateCarrierRequirement } = useContext(ApiContext);

  useEffect(() => {
    if (visible)
      cuGetEditTemplateCarrierRequirement(templateId, data => {
        clear();
        setState(draft => {
          return {
            cargoTypeCode: data.cargoType.code,
            truckTypeId: data.truckType?.code,
            selectedRequirement: data.requirements.map(r => r.code),
            manipulationTypes: data.loadingTypes,
            carrierNote: data.carrierNote,
            expeditionType: data.expeditionType === ExpeditionTypeEnum.FULL_TRUCK_LOAD ? ExpeditionTypeEnum.FULL_TRUCK_LOAD : ExpeditionTypeEnum.LESS_TRUCK_LOAD,
          };
        });
      });
  }, [visible]);

  const validationSchema = yup.object().shape({
    cargoTypeCode: yup.string().required(),
    manipulationTypes: yup.array().required().min(1),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(validationSchema, () => {
    onComplete(state);
  });

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar style={{ width: '750px' }} visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal" blockScroll={true}>
        {state && (
          <>
            <h4>{t(`EditRequirementSidebar.updateRequirements`, `Upravit požadavky na dopravce`)}</h4>
            <div className="p-mb-2">
              <Label title={t(`EditRequirementSidebar.truckType`, `Typ auta`)} />
              <TransportationTypeSelector
                value={state.expeditionType}
                onChange={e =>
                  setState(draft => {
                    draft.expeditionType = e;
                  })
                }
              />
            </div>
            <div className="p-mb-2">
              <Label title={t(`EditRequirementSidebar.truckType`, `Typ auta`)} />
              <TruckTypeSelector
                onChange={e =>
                  setState(draft => {
                    draft.truckTypeId = e;
                  })
                }
                value={state.truckTypeId}
                required={true}
              />
            </div>
            <LoadingTypeSelector
              values={state.manipulationTypes}
              onChange={v =>
                setState(draft => {
                  draft.manipulationTypes = v;
                })
              }
              errorMessages={findValidationMessage(validationErrors, 'manipulationTypes', t)}
            />
            <SpecialRequirementsSelector
              selectedIds={state.selectedRequirement}
              onChange={e => {
                setState(draft => {
                  draft.selectedRequirement = e;
                });
              }}
            />
            <CargoTypeSelector
              value={state.cargoTypeCode}
              onChange={e => {
                setState(draft => {
                  draft.cargoTypeCode = e;
                });
              }}
              adrWeight={state.adrWeight}
              onChangeAdrWeight={v => {
                setState(draft => {
                  draft.adrWeight = v;
                });
              }}
              errorMessages={findValidationMessage(validationErrors, 'cargoTypeCode', t)}
            />
            <TextArea
              value={state.carrierNote}
              onChange={v =>
                setState(draft => {
                  draft.carrierNote = v;
                })
              }
              displayFull={true}
            />
            <ButtonLayout>
              <Button label={t('wayPage.form.buttonContinue')} className="p-mr-2" onClick={() => validateAndSend(state)} />
              <Button label={t('wayPage.form.buttonBack')} className="p-button-text" onClick={() => onHide()} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default EditRequirementSidebar;
