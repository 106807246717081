import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { RouteType } from '../../new-expedition/types';
import HeaderPanel from '../../common/HeaderPanel';
import InputLayout from '../../new-expedition/InputLayout/InputLayout';
import ParametrizedInputWrapper from '../../new-expedition/ParametrizedInputWrapper/ParametrizedInputWrapper';
import InputWrapper from '../../new-expedition/InputWrapper/InputWrapper';
import { CreateTemplateHandlerContext } from '../CreateTemplateHandler';
import { Location } from '../types';
import LocationAutocomplete from '../../common/LocationAutocomplete';
import { ValidationError } from 'yup';
import ValidationDiv from '@utils/validation';
import { useTranslation } from 'react-i18next';
import FormLayout from '@components/obsolete/Form/FormLayout/FormLayout.tsx';
import InputCountry from '@components/obsolete/Form/InputCountry/InputCountry.tsx';

interface ComponentProps {
  locationDetail: Location;
  locationIndex: number;
  locationsLength: number;
  routeType: RouteType;
  validationErrors?: ValidationError[];
}

export const LocationDetailComponent: React.FC<ComponentProps> = ({ locationDetail, locationIndex, routeType, locationsLength, validationErrors = [] }) => {
  const { t }: any = useTranslation('common');
  const { locationChanged, removeLocation, addLocation, applyAddressBook } = useContext(CreateTemplateHandlerContext);
  // @ts-ignore
  const decideTitle = (locationIndex, locationsLength) => {
    if (routeType === RouteType.ROUND_TRIP) {
      if (locationIndex === 0) {
        return t(`wayPage.form.titleFromStartEndRoute`);
      } else if (locationIndex !== 0) {
        return t(`wayPage.form.titleFormIntermediateStop`, {
          index: locationIndex,
        });
      }
    } else {
      if (locationIndex === 0) {
        return t(`wayPage.form.titleFormStartRoute`);
      } else if (locationIndex !== 0 && locationIndex < locationsLength - 1) {
        return t(`wayPage.form.titleFormIntermediateStop`, {
          index: locationIndex,
        });
      } else {
        return t(`wayPage.form.titleFormEndRoute`);
      }
    }
  };

  // @ts-ignore
  const deleteLocationButton = (locationIndex, locationsLength) => {
    if ((routeType === RouteType.ROUND_TRIP && locationIndex > 1) || (routeType === RouteType.DIRECT && locationIndex > 0 && locationIndex < locationsLength - 1)) {
      return (
        <Button className="p-button-filled" onClick={() => removeLocation(locationIndex)}>
          {t(`LocationDetailComponent.delete`, `Delete`)}
        </Button>
      );
    }
  };

  return (
    <>
      <HeaderPanel classNameIcon="pi pi-compass" title={decideTitle(locationIndex, locationsLength)}>
        {deleteLocationButton(locationIndex, locationsLength)}
      </HeaderPanel>
      <>
        <FormLayout>
          <InputLayout>
            <ParametrizedInputWrapper>
              <LocationAutocomplete
                onChangeName={data => locationChanged(locationIndex, 'name', data)}
                locationName={locationDetail.name}
                onSelectAddress={e => applyAddressBook(locationIndex, e)}
              />
              <ValidationDiv errors={validationErrors} path={`locations[${locationIndex}].name`} />
            </ParametrizedInputWrapper>
          </InputLayout>

          <InputLayout>
            <InputCountry
              value={locationDetail.country}
              onChange={e => {
                locationChanged(locationIndex, 'country', e);
              }}
              label={t(`wayPage.form.labelCountry`)}
              required
            ></InputCountry>
            <ValidationDiv errors={validationErrors} path={`locations[${locationIndex}].country`} />
            <InputWrapper
              value={locationDetail.zipCode}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'zipCode', targetValue);
              }}
              label={t(`wayPage.form.labelPostcode`)}
              type="text"
              required
            >
              <ValidationDiv errors={validationErrors} path={`locations[${locationIndex}].zipCode`} />
            </InputWrapper>
          </InputLayout>
          <InputLayout>
            <InputWrapper
              value={locationDetail.city}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'city', targetValue);
              }}
              label={t(`wayPage.form.labelCity`)}
              type="text"
              required
            >
              <ValidationDiv errors={validationErrors} path={`locations[${locationIndex}].city`} />
            </InputWrapper>
            <InputWrapper
              value={locationDetail.streetNr}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'streetNr', targetValue);
              }}
              label={t(`wayPage.form.labelStreet`)}
              type="text"
              required
            >
              <ValidationDiv errors={validationErrors} path={`locations[${locationIndex}].streetNr`} />
            </InputWrapper>
          </InputLayout>
          <InputLayout>
            <InputWrapper
              value={locationDetail.contactName}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'contactName', targetValue);
              }}
              label={t(`wayPage.form.labelContactPerson`)}
              type="text"
              InputGroup
            />
          </InputLayout>
          <InputLayout>
            <InputWrapper
              value={locationDetail.contactPhone}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'contactPhone', targetValue);
              }}
              label={t(`wayPage.form.labelContactPhone`)}
              type="text"
            />
          </InputLayout>
          <InputLayout className="p-ai-end">
            <InputWrapper
              value={locationDetail.contactEmail}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'contactEmail', targetValue);
              }}
              label={t(`wayPage.form.labelContactEmail`)}
              type="text"
              placeholder="@"
            >
              <ValidationDiv errors={validationErrors} path={`locations[${locationIndex}].contactEmail`} />
            </InputWrapper>
          </InputLayout>
          <div className="p-field">{locationIndex === locationsLength - 2 && <Button onClick={addLocation}>{t(`wayPage.form.addLocation`)}</Button>}</div>
        </FormLayout>
      </>
    </>
  );
};
