import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ResolvedEmailEntity, ListResolvedEmailsResponse } from '@api/logsteo-api.v2.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { mapFromAPIToDateLong } from '@utils/date.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import { SelectableButton } from '@components/ringil3/Buttons/SelectableButton.tsx';
import SvgLoader from '@app/icons/loader/Loader.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { useDebounce } from 'use-debounce';
import { parseNumberOrUndefined } from '@utils/utils.tsx';
import SvgBateryOut from '@app/icons/admin/icon/BateryOut.tsx';
import SvgRepeatIcon from '@app/icons/admin/icon/RepeatIcon.tsx';

interface ComponentProps {}

const ResolvedEmailsMonitoringPage: React.FC<ComponentProps> = () => {
  const { listResolvedEmails } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [data, setData] = useState<ListResolvedEmailsResponse>();
  const [refresh, setRefresh] = useState(false);
  const [runningApi, setRunningApi] = useState(false);
  const [requestInterval, setRequestInterval] = useState('5000');
  const [debouncedValue] = useDebounce(requestInterval, 5000);

  const { Table, Column } = useCreateTable<ResolvedEmailEntity>();
  const { Table: FailedTable, Column: FailedColumn } = useCreateTable<ResolvedEmailEntity>();

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    const ac = new AbortController();
    abortControllerRef.current = ac;

    const intervalId = setInterval(() => {
      if (refresh === true) {
        load();
      }
    }, parseNumberOrUndefined(debouncedValue) || 5000);

    return () => {
      clearInterval(intervalId); // zruší interval při odmountování nebo změně
      abortControllerRef.current.abort();
    };
  }, [refresh, debouncedValue]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setRunningApi(true);
    listResolvedEmails(d => {
      setRunningApi(false);
      if (abortControllerRef.current.signal.aborted) return;
      setData(d);
    });
  };

  return (
    <AdminLayout title={'Resolved Emails Monitoring'}>
      <BreadCrumbComponent items={[{ label: 'Resolved Emails', url: null }]} />
      <div className={'flex flex-row justify-between py-4'}>
        <COHeadline title={'In Progress Resolved Emails'} variant={'h2'} icon={<SvgRepeatIcon />} />
        <div className={'flex flex-row gap-4 items-center'}>
          {runningApi === true && <SvgLoader className={'animate-spin'} />}
          <RingilInputText value={requestInterval} onChange={v => setRequestInterval(v)} />
          <SelectableButton label={'Refresh'} checked={refresh} onClick={() => setRefresh(v => !v)} />
        </div>
      </div>

      {/* Sumarizační řádek */}
      <div className="flex justify-between py-4">
        <p>{`Total: ${data?.totalEmailsCount || 0}, In Queue: ${data?.inQueueCount || 0}, Failed: ${data?.failedCount || 0}`}</p>
      </div>

      <div className={'flex flex-col gap-4'}>
        <Table value={data?.inQueue}>
          <Column header={'recipientEmail'} body={r => <>{r.recipientEmail}</>} />
          <Column header={'subject'} body={r => <>{r.subject}</>} />
          <Column header={'retryCount'} body={r => <>{r.retryCount}</>} />
          <Column header={'emailCreatedAt'} body={r => <>{mapFromAPIToDateLong(r.emailCreatedAt)}</>} />
          <Column header={'emailLastRetryAt'} body={r => <>{mapFromAPIToDateLong(r.emailLastRetryAt)}</>} />
          <Column header={'lastException'} body={r => <>{r.lastException}</>} />
        </Table>
        <COHeadline title={'Failed Resolved Emails'} variant={'h2'} icon={<SvgBateryOut />} />
        <Table value={data?.failed}>
          <Column header={'recipientEmail'} body={r => <>{r.recipientEmail}</>} />
          <Column header={'subject'} body={r => <>{r.subject}</>} />
          <Column header={'retryCount'} body={r => <>{r.retryCount}</>} />
          <Column header={'emailCreatedAt'} body={r => <>{mapFromAPIToDateLong(r.emailCreatedAt)}</>} />
          <Column header={'emailLastRetryAt'} body={r => <>{mapFromAPIToDateLong(r.emailLastRetryAt)}</>} />
          <Column header={'lastException'} body={r => <>{r.lastException}</>} />
        </Table>
      </div>
    </AdminLayout>
  );
};

export default withAuthenticationRequired(ResolvedEmailsMonitoringPage);
