import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api.tsx';
import { CargoTemplateWithoutIdDTO } from '@api/logsteo-api.v2.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  skuCode: string;
  onChange: (code: string) => void;
  fullWidth?: boolean;
  onChangeCargoTemplate?: (cargoTemplate: CargoTemplateWithoutIdDTO) => void;
}

const InputSKU: React.FC<ComponentProps> = ({ skuCode, onChange, fullWidth, onChangeCargoTemplate }) => {
  const { tr } = useTranslationLgs();
  const { lovCargoItemType } = useContext(ApiContext);
  const [cargoTemplates, setCargoTemplates] = useState<CargoTemplateWithoutIdDTO[]>();

  useEffect(() => {
    lovCargoItemType(d => setCargoTemplates(d));
  }, []);

  const style = fullWidth ? { width: '100%' } : {};

  return (
    <>
      <Dropdown
        style={style}
        value={skuCode}
        options={cargoTemplates}
        onChange={e => {
          onChange(e.target.value);
          if (isNotNullOrUndefined(onChangeCargoTemplate)) {
            const cargoTemplate = cargoTemplates?.find(c => c.code === e.target.value);
            if (isNotNullOrUndefined(cargoTemplate)) {
              onChangeCargoTemplate(cargoTemplate);
            }
          }
        }}
        optionLabel="name"
        optionValue={'code'}
      />
    </>
  );
};

export default InputSKU;
