import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import { TabPanel, TabView } from 'primereact/tabview';
import { useImmer } from 'use-immer';
import { dumpVars, isNullOrUndefined } from '@utils/utils';
import { Dialog } from 'primereact/dialog';
import { ComputedPreviewEmailOutDTO, GlobalTemplateDetailOutDTO, GlobalTemplateLanguageOutDTO } from '@api/logsteo-api.v2';
import { DefaultFallback, Fallbacks, LanguagesList } from '@components/pages/admin/templates/components/components';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  templateId: string;
}

const I18nTemplatesList: React.FC<ComponentProps> = ({ templateId }) => {
  const { adminGlobalTemplateDetail, adminGlobalUpdateOrCreateTemplate, sendTestEmail, computeEmail } = useContext(ApiContext);
  const [template, setTemplate] = useImmer<GlobalTemplateDetailOutDTO>(undefined);
  const [testEmail, setTestEmail] = useState('');
  const [computedEmail, setComputedEmail] = useState<ComputedPreviewEmailOutDTO>(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    loadTemplate();
  }, [templateId]);

  const loadTemplate = () => {
    adminGlobalTemplateDetail(
      templateId,
      d => {
        setTemplate(draft => {
          return d;
        });
        setActiveIndex(0);
      },
      null,
      {
        onValidationFailed: () => {
          const emptyTemplate = {
            fallbackOverride: [],
            fallback: 'en',
            description: '',
            emailType: templateId,
            initialData: '',
            version: 0,
            languages: [],
            templates: [],
          } as GlobalTemplateDetailOutDTO;
          setTemplate(draft => {
            return emptyTemplate;
          });
          setActiveIndex(0);
        },
      },
    );
  };

  const newLanguage = (lng: string) => {
    return {
      language: lng,
      body: '',
      subject: '',
    } as GlobalTemplateLanguageOutDTO;
  };

  const save = () => {
    // missing validation
    adminGlobalUpdateOrCreateTemplate(
      templateId,
      {
        initialData: template.initialData,
        fallback: template.fallback,
        fallbackOverride: template.fallbackOverride,
        description: template.description,
        languages: template.languages.map(t => t.language),
        version: template.version,
        templates: template.templates,
      },
      () => {
        loadTemplate();
      },
    );
  };

  const test = () => {
    sendTestEmail(
      {
        template: template.templates[activeIndex].body,
        email: testEmail,
        templateData: template.initialData,
        subjectEmail: template.templates[activeIndex].subject,
      },
      () => {},
    );
  };

  const computeEmail2 = () => {
    computeEmail(
      {
        template: template.templates[activeIndex].body,
        templateData: template.initialData,
        subjectEmail: template.templates[activeIndex].subject,
      },
      res => {
        setComputedEmail(res);
      },
    );
  };

  const changeLanguageTexts = (lng: string, field: string, content: string) => {
    setTemplate(draft => {
      const templateIndex = draft.templates.findIndex(t => t.language === lng);
      // @ts-ignore
      draft.templates[templateIndex][field] = content;
    });
  };

  return (
    <>
      {template && (
        <>
          <Row>
            <Header>{`${template.emailType}#${template.version}`}</Header>
            <div>
              <Button className="p-filled" label={'Save'} onClick={e => save()} />
            </div>
          </Row>
          <ColumnContainer>
            <TwoColumns>
              <FirstColumn>
                <HorizontalFieldValue
                  label={'Languages'}
                  value={
                    <LanguagesList
                      languages={template.templates.map(t => t.language)}
                      onAdd={lng => {
                        setTemplate(draft => {
                          draft.templates.push(newLanguage(lng));
                        });
                      }}
                      onRemove={lng => {
                        setTemplate(o => {
                          o.templates = o.templates.filter(t => t.language !== lng);
                          setActiveIndex(0);
                        });
                      }}
                      /*
                      onChange={(l) => {
                        setTemplate((draft) => {
                          draft.templates = draft.templates.filter(t=>t.language === )
                        });
                      }}
*/
                    />
                  }
                />
                <HorizontalFieldValue
                  label={'Default fallback'}
                  value={
                    <DefaultFallback
                      value={template.fallback}
                      onChange={e =>
                        setTemplate(draft => {
                          draft.fallback = e;
                        })
                      }
                    />
                  }
                />
                <HorizontalFieldValue
                  label={'Fallbacks'}
                  value={
                    <Fallbacks
                      fallbacks={template.fallbackOverride}
                      onChange={fallbacks =>
                        setTemplate(draft => {
                          draft.fallbackOverride = fallbacks;
                        })
                      }
                    />
                  }
                />
              </FirstColumn>
              <SecondColumn>
                <HorizontalFieldValue
                  label={'Description'}
                  value={
                    <InputTextarea
                      value={template.description}
                      style={{ width: '100%', height: '100%' }}
                      onChange={e => {
                        setTemplate(draft => {
                          draft.description = e.target.value;
                        });
                      }}
                    />
                  }
                />
              </SecondColumn>
            </TwoColumns>

            <TabViewWrapper>
              {/*// @ts-ignore*/}
              <TabView
                onTabChange={e => {
                  setActiveIndex(e.index);
                }}
                activeIndex={activeIndex}
              >
                {template.templates.map((t, key) => (
                  <React.Fragment key={key}>
                    {/*// @ts-ignore*/}
                    <TabPanel header={`${t.language}`} key={key}>
                      <HorizontalFieldValue
                        label={'Subject'}
                        value={
                          <InputTextarea
                            style={{ width: '100%' }}
                            cols={4}
                            value={template.templates.find(obj => obj.language === t.language)?.subject || ''}
                            onChange={e => changeLanguageTexts(t.language, 'subject', e.target.value)}
                          />
                        }
                      />
                      <Splitter>
                        <LeftPane>
                          <TextAreaWrapper>
                            <HorizontalFieldValue
                              label={'Body'}
                              value={
                                <InputTextarea
                                  value={template.templates.find(obj => obj.language === t.language)?.body || ''}
                                  style={{ width: '100%', height: '100%' }}
                                  onChange={e => changeLanguageTexts(t.language, 'body', e.target.value)}
                                />
                              }
                            />
                          </TextAreaWrapper>
                        </LeftPane>
                        <RightPane>
                          <TextAreaWrapper>
                            <HorizontalFieldValue
                              label={'Initial data'}
                              value={<InputTextarea style={{ width: '100%', height: '100%' }} rows={30} value={JSON.stringify(template.initialData)} />}
                            />
                          </TextAreaWrapper>
                        </RightPane>
                      </Splitter>
                    </TabPanel>
                  </React.Fragment>
                ))}
              </TabView>
            </TabViewWrapper>
          </ColumnContainer>
          {/*// @ts-ignore*/}
          <Dialog onHide={() => setComputedEmail(undefined)} visible={!isNullOrUndefined(computedEmail)} style={{ widht: '96vw', height: '96vh' }}>
            {!isNullOrUndefined(computedEmail) && (
              <Layout>
                <HorizontalFieldValue label={'Subject'} value={computedEmail.subject} />
                <HorizontalFieldValue label={'Body'} value={<div dangerouslySetInnerHTML={{ __html: computedEmail.email }} />} />
              </Layout>
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 850px;
`;

const ComputedEmailPanel = styled.div`
  display: flex;
  width: 95vw;
  height: 95vh;
  position: static;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
`;

const SecondColumn = styled.div`
  width: 50%;
  flex-grow: 1;
  display: flex;
`;
const FirstColumn = styled.div`
  width: 50%;
  flex-grow: 1;
`;

const TwoColumns = styled.div`
  display: flex;
`;

const Header = styled.h1`
  padding: 0;
  margin: 0;
`;
export const Row = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  div {
    align-items: center;
  }
`;

const TestingEmailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  input {
    flex-grow: 1;
  }
`;

const TextAreaWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`;

const TabViewWrapper = styled.div`
  flex-grow: 1;
  .p-tabview {
    height: 100%;
    display: flex;
    flex-direction: column;
    .p-tabview-panels {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .p-tabview-panel {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TestingRow = styled.div`
  background-color: #f3f5f7;
  padding: 15px;
`;

const Splitter = styled.div`
  display: flex;
  flex-grow: 1;
`;

const LeftPane = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const RightPane = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`;
export default I18nTemplatesList;
