interface ComponentProps {
  company?: string;
  address?: string;
}

const Address: React.FC<ComponentProps> = ({ company, address }) => {
  return (
    <div className="p-grid">
      <div className="p-col">
        <div className="p-mb-2">{company}</div>
        <div>
          <span>{address}</span>
        </div>
      </div>
    </div>
  );
};

export default Address;
