import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { CargoTypeCodeEnum, LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  value: CargoType;
  onChange: (cargoType: CargoType) => void;
  inSidebar: boolean;
  disabled?: boolean;
}

export interface CargoType {
  cargoTypeCode: CargoTypeCodeEnum;
  adrWeight?: number;
}

export const CargoTypeComponent: React.FC<ComponentProps> = ({ value, inSidebar, onChange, disabled = false }) => {
  const { tr } = useTranslationLgs();
  const { lovSHSNCargoTypes } = useContext(ApiContext);
  const [cargoTypes, setCargoTypes] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovSHSNCargoTypes(d => {
      setCargoTypes(d);
      if (value.cargoTypeCode !== CargoTypeCodeEnum.ADR_LQ) {
        onChange({ ...value, adrWeight: null });
      }
    });
  }, []);

  return (
    <>
      <RR>
        <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={12}>
          <HorizontalFieldValue
            required={true}
            label={tr(`CargoType.cargoType`, `Cargo type`)}
            value={
              <Dropdown
                disabled={disabled}
                options={cargoTypes}
                optionValue={'code'}
                optionLabel={'name'}
                value={value.cargoTypeCode}
                style={{ width: '100%' }}
                onChange={e => onChange({ ...value, cargoTypeCode: e.value })}
              />
            }
          />
        </RC>
        {value.cargoTypeCode === CargoTypeCodeEnum.ADR_LQ && (
          <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              required={true}
              label={tr('CargoType.adrWeightKg', 'ADR weight [kg]')}
              value={
                <InputNumber
                  value={value?.adrWeight}
                  onChange={e => onChange({ ...value, adrWeight: e.value })}
                  minFractionDigits={0}
                  maxFractionDigits={2}
                  locale={'cs'}
                  style={{ width: '100%' }}
                />
              }
            />
          </RC>
        )}
      </RR>
    </>
  );
};
