import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { isNullOrUndefined } from '@utils/utils';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';

interface ComponentProps {
  onReconfirm: () => void;
  reconfirmedAt?: string;
  isReconfirmedByCarrier?: boolean;
  disabled: boolean;
}

const ReconfirmQuote: React.FC<ComponentProps> = ({ onReconfirm, reconfirmedAt, isReconfirmedByCarrier, disabled }) => {
  const { tr } = useTranslationLgs();
  const [visibleDialog, setVisibleDialog] = useState(false);

  return (
    <>
      <Row>
        <div>{tr(`ReconfirmQuote.reconfirmationNeededTitle`, `Zákazník požádal o opětovné potvrzení nabídky`)}</div>
        <Button
          type="button"
          label="?"
          tooltip={tr(
            `ReconfirmQuote.reconfirmationTooltip`,
            `Zákazník žádá o potvrzení přijetí přepravy, aby měl jistotu, že s přepravou počítáte a nemusel Vás kontaktovat telefonicky. Pokud přepravu nemůžete potvrdit, kontaktujte, prosím, zákazníka.`,
          )}
          className="p-button-text p-ml-2 p-mb-2 p-mb-2"
          tooltipOptions={{ position: 'top' }}
        />
      </Row>
      {isNullOrUndefined(reconfirmedAt) && (
        <Row>
          <Button
            type="button"
            label={tr(`ReconfirmQuote.reconfirmButton`, `Potvrdit přijetí`)}
            className="p-button-rounded"
            onClick={e => setVisibleDialog(true)}
            disabled={disabled}
          />
        </Row>
      )}

      {isReconfirmedByCarrier &&
        tr(`ReconfirmQuote.carrierReconfirmedAt`, `Dopravce potvrdil přijetí přepravy {{when}}`, {
          when: dateFormatDateTime(mapFromAPIDateTime(reconfirmedAt)),
        })}
      {!isReconfirmedByCarrier &&
        !isNullOrUndefined(reconfirmedAt) &&
        tr(`ReconfirmQuote.customerReconfirmedAt`, `Zákazník potvrdil za dopravce {{when}}`, {
          when: dateFormatDateTime(mapFromAPIDateTime(reconfirmedAt)),
        })}

      <ConfirmDialog
        visible={visibleDialog}
        dialogTitle={tr(`ReconfirmQuote.reconfirmDialogTitle`, `Potvrzení přijetí přepravy`)}
        dialogMessage={tr(`ReconfirmQuote.reconfirmDialogBody`, `Potvrzujeme, že s přepravou počítáme a budeme ji realizovat.`)}
        onConfirm={() => {
          onReconfirm();
          setVisibleDialog(false);
        }}
        onCancel={() => setVisibleDialog(false)}
        onAcceptButtonText={tr(`ReconfirmQuote.accept`, `Potvrdit`)}
        onCancelButtonText={tr(`ReconfirmQuote.back`, `Zpět`)}
      ></ConfirmDialog>
    </>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default ReconfirmQuote;
