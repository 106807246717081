import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useContext } from 'react';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Layout from '@app/layout/Layout.tsx';
import CustomerExpeditionDetail from '@app/pages/customer/expedition-detail/NewDetailExpedition.tsx';

const CustomerExpeditionDetailPage = () => {
  const { loggedUser, isReady } = useContext(AuthenticatedUserContext);

  const { id } = useParams();
  const { t } = useTranslation('common');

  return (
    <>
      {id && loggedUser && (
        <Layout title={t(`ExpeditionDetailPage.expeditionDetail`, `Detail expedice {{id}}`, { replace: { id } })}>
          <CustomerExpeditionDetail id={id as string} customerId={loggedUser.companyId} />
        </Layout>
      )}
    </>
  );
};

export default withAuthenticationRequired(CustomerExpeditionDetailPage);
