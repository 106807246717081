import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuAnchorLocked = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#FE5000"
      d="M15.75 3.25h-7.5A2.75 2.75 0 0 0 5.5 6v14a.75.75 0 0 0 1.18.62L12 16.91l5.32 3.71a.75.75 0 0 0 .43.13.85.85 0 0 0 .35-.08.77.77 0 0 0 .4-.67V6a2.75 2.75 0 0 0-2.75-2.75"
    />
  </svg>
);
export default SvgMenuAnchorLocked;
