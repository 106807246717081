import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Sidebar } from 'primereact/sidebar';
import { ApiContext } from '../../../../../api/api';
import { Button } from 'primereact/button';
import { isNullOrUndefined } from '@utils/utils';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { OpeningHourDefinitionsInDTO } from '@api/logsteo-api.v2';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import OpeningHourDefinitionForm, { openingHoursValidationSchema } from '@components/obsolete/OpeningHoursDefinition/OpeningHourDefinitionForm.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onCompleted: () => void;
  locationId?: string;
}

const EditOpeningHours: React.FC<ComponentProps> = ({ visible, onHide, onCompleted, locationId }) => {
  const { tr } = useTranslation();
  const { getLocationOpeningHours, updateLocatioOpeningHours, getExceptionsForCompanyLocation } = useContext(ApiContext);

  useEffect(() => {
    if (visible) formik.clear();
    if (!isNullOrUndefined(locationId)) {
      getLocationOpeningHours(locationId, d => {
        formik.setValues(d);
      });
    }
  }, [visible]);

  const validationSchema = openingHoursValidationSchema;

  const formik = useForm<OpeningHourDefinitionsInDTO>(validationSchema, null, values => save(values), false, false);

  const save = (values: OpeningHourDefinitionsInDTO) => {
    updateLocatioOpeningHours(locationId, values, () => {
      onCompleted();
    });
  };

  const names = proxiedPropertiesOf<FormData>();
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={onHide} blockScroll={true} dismissable={false} style={{ minWidth: '40rem' }}>
        <ColumnRow>
          <OpeningHourDefinitionForm openingHours={formik.values} onChange={v => formik.setValues(v)} validationErrors={formik.validationErrors} validationPrefix={''} />
          <ButtonRow>
            <Button label={tr(`AddEditLocationSidebar.save`, `Save`)} onClick={e => formik.validateAndSend()}></Button>
            <Button
              label={tr(`AddEditLocationSidebar.back`, `Back`)}
              className={'p-button-text'}
              onClick={e => {
                e.preventDefault();
                onHide();
              }}
            ></Button>
          </ButtonRow>
        </ColumnRow>
      </Sidebar>
    </>
  );
};

const Link = styled.a`
  cursor: pointer;
  color: var(--anchor-color);
`;

const Delete = styled.div`
  display: flex;
`;
const To = styled.div`
  display: flex;
  flex-grow: 1;
`;
const From = styled.div`
  display: flex;
  flex-grow: 1;
`;

const FromToRow = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
`;
const FromTo = styled.div`
  display: flex;
  flex-grow: 1;
  row-gap: 0.5rem;
  flex-direction: column;
  align-items: start;
  align-content: start;
`;
const Day = styled.div`
  display: flex;
  flex-grow: 0;
  padding: 0 1rem 0 0;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Grid = styled.div`
  margin-top: 1rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: auto auto;
`;
const ButtonRow = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
`;

const SidebarHeading = styled.div`
  display: flex;
  font-size: 1.5rem;
  color: black;
  margin-bottom: 1rem;
`;

const InnerRow = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
`;

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  display: flex;
  color: red;
`;

export default EditOpeningHours;
