import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import Layout from '@app/layout/Layout.tsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PartnerDetail from '@components/obsolete/partners/PartnerDetail.tsx';

const PartnerDetailPage = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  return (
    <>
      {id && (
        <Layout title={t(`[id].partnerDetail`, `Detail partnera`)}>
          {/*
        // @ts-ignore*/}
          <Card>
            <PartnerDetail partnerId={id as string} />
          </Card>
        </Layout>
      )}
    </>
  );
};

export default withAuthenticationRequired(PartnerDetailPage);
