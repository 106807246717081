import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import { Sidebar } from 'primereact/sidebar';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons.tsx';
import useValidation from '@hooks/validation-hook/useValidation.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onSelect: (selectedPerson: LovDTO) => void;
}

export const ResponsiblePersonChangeDialog: React.FC<ComponentProps> = ({ visible, onHide, onSelect }) => {
  const { t }: { t: any } = useTranslation('common');
  const responsiblePersons = useResponsiblePersons();
  const [selectedPerson, setSelectedPerson] = useState();

  const validationScheme = yup.object().shape({
    id: yup.string().required(),
  });

  const { validateAndSend, validationErrors, clear } = useValidation(validationScheme, () => onSelect(selectedPerson));

  useEffect(() => {
    if (visible) {
      setSelectedPerson(undefined);
      clear();
    }
  }, [visible]);

  return (
    <>
      {/*//
      @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} style={{ width: '750px' }}>
        <h4>{t(`ResponsiblePersonChangeDialog.changeResponsiblePerson`, `Change responsible person`)}</h4>
        <div className=" p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12">
            <Label title={t(`ResponsiblePersonChangeDialog.whoIsResponsible`, `Změnit zodpovědnou osobu`)} />
            <Dropdown value={selectedPerson} options={responsiblePersons} onChange={e => setSelectedPerson(e.target.value)} optionLabel="name" />
            <ValidationDiv errors={validationErrors} path={'id'} />
          </div>
        </div>
        <ButtonLayout>
          <Button
            label={t(`ResponsiblePersonChangeDialog.save`, `Uložit`)}
            className="p-mr-2"
            onClick={() => {
              validateAndSend(selectedPerson);
            }}
          />
          <Button label={t(`ResponsiblePersonChangeDialog.back`, `Zpět`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};
