import React from 'react';

interface ComponentProps {
  onClick: () => void;
}

const error: React.FC<ComponentProps> = ({ onClick }) => {
  return <button onClick={() => onClick()}>Break the world</button>;
};

export default error;
