import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { PickList } from 'primereact/picklist';
import { LovDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  value: string[];
  onChange: (selectedPermission: LovDTO[]) => void;
}

const InputPickSecurityGroup: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { lovSecurityGroups } = useContext(ApiContext);

  const [securityGroups, setSecurityGroups] = useState<LovDTO[]>();

  useEffect(() => {
    const ac = new AbortController();
    lovSecurityGroups(d => {
      if (!ac.signal.aborted) {
        setSecurityGroups(d);
      }
    });
    return () => {
      ac.abort();
    };
  }, []);

  const onChangeInternal = (event: any) => {
    onChange(event.target);
  };

  return (
    <>
      <PickList
        source={securityGroups?.filter(p => !value.includes(p.id))}
        target={securityGroups?.filter(p => value.includes(p.id))}
        onChange={onChangeInternal}
        sourceHeader="Available"
        targetHeader="Selected"
        showSourceControls={false}
        showTargetControls={false}
        itemTemplate={item => <div>{item.name}</div>}
      />
    </>
  );
};

export default InputPickSecurityGroup;
