import React, { useContext, useEffect, useState } from 'react';
import MenuItem from '@components/menu/MenuItem.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import SvgMenuBell from '@components/icons/menu/MenuBell.tsx';
import { CustomerNotificatonListResponse } from '@api/logsteo-api.v2.tsx';
import { dumpVars, useLocalStorage } from '@utils/utils.tsx';
import RingilDialog from '@components/framework/dialog/RingilDialog.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';

interface ComponentProps {
  smallMenu?: boolean;
  lockedMenu?: boolean;
  onClick?: () => void;
}

const NotificationMenuItem: React.FC<ComponentProps> = ({ smallMenu, lockedMenu, onClick }) => {
  const { tr } = useTranslationLgs();
  const [data, setDate] = useState<CustomerNotificatonListResponse>();
  const [postponedMessage, setPostPonedMessages] = useLocalStorage('postponedMessage', []);
  const { getCompanyNotificationsForInheritor } = useContext(ApiContext);
  const [unreadMessageIds, setUnreadMessageIds] = useState([]);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      load();
    }, 60000);

    load();

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const load = () => {
    getCompanyNotificationsForInheritor(data => {
      setDate(data);
      setUnreadMessageIds(data.notifications.filter(t => t.unread).map(t => t.id));
    });
  };

  const postPone = () => {
    setPostPonedMessages(data.notifications.filter(t => t.unread).map(t => t.id));
  };

  const areArraysEqual = (arr1: any[], arr2: any[]): boolean => new Set(arr1).size === new Set(arr2).size && arr1.every(value => arr2.includes(value));

  return (
    <>
      <MenuItem
        title={tr('NotificationMenuItem.customerNotification', 'Customer notifications')}
        icon={<SvgMenuBell />}
        elementCount={data?.notifications?.filter(t => t.unread)?.length}
        smallMenu={smallMenu && !lockedMenu}
        render={true}
        onClick={() => {
          onClick();
        }}
        mode={'open-outside-bottom'}
      />

      <RingilDialog
        heading={tr(
          `CustomerNotificationMenuItem.mateNNovychUpozorneni`,
          `Máte {{count}} nová upozornění`,
          { count: unreadMessageIds?.length },
          unreadMessageIds?.length,
        )}
        visible={unreadMessageIds?.length > 0 && !areArraysEqual(unreadMessageIds, postponedMessage)}
        onHide={() => {
          postPone();
        }}
        wide={false}
        buttons={[
          <RingilButton
            label={tr('CustomerNotificationMenuItem.precist', 'Přečíst')}
            onClick={() => {
              postPone();
              onClick();
            }}
          />,

          <LinkWithIcon
            label={tr('CustomerNotificationMenuItem.pozdeji', 'Později')}
            onClick={() => {
              postPone();
            }}
          />,
        ]}></RingilDialog>
    </>
  );
};

export default NotificationMenuItem;
