import { useEffect, useState } from 'react';

type Condition<T> = (value: T) => boolean;

export const useConditionalDebounce = <T,>(value: T, delay: number, condition: Condition<T>): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (condition(value)) {
      // Pokud podmínka platí, změna proběhne okamžitě
      setDebouncedValue(value);
    } else {
      // Pokud podmínka neplatí, nastav debounce se zpožděním
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Vyčistí timeout při změně hodnoty nebo unmountu
      return () => {
        clearTimeout(handler);
      };
    }
  }, [value, delay, condition]);

  return debouncedValue;
};
