import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotBlank } from '@utils/utils';
import { GlobalOrderResponse, ReservationsDataOutDTO } from '@api/logsteo-api.v2';
import { RowWithGap } from '@components/styles.tsx';

interface ComponentProps {
  reservation: ReservationsDataOutDTO;
}

const CFRName: React.FC<ComponentProps> = ({ reservation }) => {
  const { tr } = useTranslationLgs();

  const print = (t: GlobalOrderResponse) => {
    return t.orderName;
  };

  return (
    <>
      <RowWithGap>
        {isNotBlank(reservation.reservationName) ? (
          <div>
            {reservation.reservationName} ({reservation.orders.map(t => t.orderName).join(', ')})
          </div>
        ) : (
          <>{reservation.orders.map(t => t.orderName).join(', ')}</>
        )}
      </RowWithGap>
    </>
  );
};

export default CFRName;
