import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { CarrierGroupInDTO, CarrierRequirementEditInDTO, LocationDetailPlanOutDTO, LovDTO } from '@api/logsteo-api.v2';
import { DistributionView } from '@type/types';
import { mapToAPIDateTime } from '@utils/date';
import { ApiContext } from '@api/api';
import { ExpeditionDetailView } from '@app/pages/customer/expedition-detail/types.tsx';
import { mapToDistributionInDTO } from '@app/pages/customer/expedition-detail/mappers.ts';
import { PriceAndValidityData } from '@app/pages/customer/expedition-detail/EditDistribution/EditDistributionSidebar.tsx';
import { createInitialPickComponentData } from '@app/pages/customer/expedition-detail/TabCarrierPending/Pick/Pick.tsx';
import { createInitialRejectData } from '@app/pages/customer/expedition-detail/TabCarrierPending/Pick/Reject.tsx';

interface CustomerExpeditionInterface {
  state: ExpeditionDetailView;
  load: (customerId: string, applicationId: string) => void;
  createDistribution: (customerId: string, applicationId: string, distribution: DistributionView) => void;
  loadTabAssigning: (customerId: string, applicationId: string) => void;
  showPickDialog: (company: string, quoteId: string) => void;
  hidePickDialog: () => void;
  hideRejectDialog: () => void;
  rejectQuote: (customerId: string, applicationId: string, quoteId: string, reasonText: string) => void;
  showRejectDialog: (company: string, quoteId: string) => void;
  getDetailExpeditionCarrierTabAssigned: (customerId: string, applicationId: string) => void;
  loadExpeditionRouteOverview: () => void;
  loadHistory: () => void;
  changeResponsiblePerson: (selectedPerson: LovDTO, callback: () => void) => void;
  hideCustomerChat: () => void;
  openCustomerChat: () => void;
  openCustomerChatWithCarrier: (carrierId: string) => void;
  addCarrierToDistribution: (selectedCarriers: CarrierGroupInDTO[]) => void;
  editRequestCoverage: (data: PriceAndValidityData) => void;
  cuEditCarrierRequirement: (data: CarrierRequirementEditInDTO) => void;
  changeLocation: (index: number, data: LocationDetailPlanOutDTO) => void;
  recofirmExpedition: (customerId: string, applicationId: string, onSuccess?: () => void) => void;
}

export const CustomerExpeditionViewContext = createContext<CustomerExpeditionInterface>(undefined);

interface ComponentProps {
  initialData: ExpeditionDetailView;
}

export const CustomerExpeditionViewProvider: React.FC<PropsWithChildren<ComponentProps>> = ({ children, initialData }) => {
  const [state, setState] = useImmer<ExpeditionDetailView>(initialData);

  useEffect(() => {
    setState(draft => {
      return initialData;
    });
  }, [initialData]);

  const {
    cuGetDetailExpeditionHeader,
    cuCreateDistributionForExpedition,
    cuGetDetailExpeditionCarrierTabAssigning,
    cuAcceptDemand,
    cuRejectDemand,
    cuGetDetailExpeditionCarrierTabAssigned,
    cuGetDetailExpeditionPlan,
    deleteDocumentForExpedition,
    cuListExpeditionHistory,
    cuSetResponsiblePersonToExpedition,
    cuAddMoreCarriersToDistribution,
    cuEditTimeOrPriceDistribution,
    cuEditCarrierRequirement,
    cuEditLocations,
    cuReconfirmedExpedition,
  } = useContext(ApiContext);

  const log = (message: string) => {};

  const methods = {
    state,
    load: (customerId: string, applicationId: string) => {
      cuGetDetailExpeditionHeader(customerId, applicationId, data => {
        setState(draft => {
          return {
            header: data,
            pickSidebarData: createInitialPickComponentData(),
            rejectSidebarData: createInitialRejectData(),
            customerChatData: { visible: false },
          };
        });
      });
    },

    createDistribution: (customerId: string, applicationId: string, distribution: DistributionView) => {
      cuCreateDistributionForExpedition(customerId, applicationId, mapToDistributionInDTO(distribution), () => {
        cuGetDetailExpeditionHeader(customerId, applicationId, data => {
          setState(draft => {
            draft.header = data;
          });
        });
      });
    },
    loadTabAssigning: (customerId: string, applicationId: string) => {
      cuGetDetailExpeditionCarrierTabAssigning(customerId, applicationId, data => {
        setState(draft => {
          draft.tabAssigningData = data.assigning;
        });
      });
    },
    showPickDialog: (company: string, quoteId: string) => {
      setState(draft => {
        draft.pickSidebarData.visible = true;
        draft.pickSidebarData.quoteId = quoteId;
        draft.pickSidebarData.company = company;
      });
    },

    hidePickDialog: () => {
      setState(draft => {
        draft.pickSidebarData.visible = false;
      });
    },
    hideRejectDialog: () => {
      setState(draft => {
        draft.rejectSidebarData.visible = false;
      });
    },
    rejectQuote: (customerId: string, applicationId: string, quoteId: string, reasonText: string) => {
      cuRejectDemand(customerId, applicationId, quoteId, { message: reasonText }, () => {
        cuGetDetailExpeditionCarrierTabAssigning(customerId, applicationId, data => {
          setState(draft => {
            draft.tabAssigningData = data.assigning;
          });
        });
      });
    },
    showRejectDialog: (company: string, quoteId: string) => {
      setState(draft => {
        draft.rejectSidebarData.visible = true;
        draft.rejectSidebarData.company = company;
        draft.rejectSidebarData.quoteId = quoteId;
      });
    },
    getDetailExpeditionCarrierTabAssigned: (customerId: string, applicationId: string) => {
      log(`getDetailExpeditionCarrierTabAssigned()>cuGetDetailExpeditionCarrierTabAssigned - loading customer: ${customerId}, #${applicationId}`);
      cuGetDetailExpeditionCarrierTabAssigned(customerId, applicationId, data => {
        cuGetDetailExpeditionHeader(customerId, applicationId, headerData => {
          setState(draft => {
            return { ...draft, readyToShipment: data, header: headerData };
          });
        });
      });
    },
    loadExpeditionRouteOverview: () => {
      log(`loadExpeditionRouteOverview()>cuGetDetailExpeditionPlan - loading customer: ${state.header.companyId}, #${state.header.applicationId}`);

      cuGetDetailExpeditionPlan(state.header.companyId, state.header.applicationId, data => {
        setState(draft => {
          draft.tabExpeditionPlan = data;
        });
      });
    },

    loadHistory: () => {
      cuListExpeditionHistory(state.header.companyId, state.header.applicationId, { offset: 0, pageNumber: 0, pageSize: 1000 }, data => {
        setState(draft => {
          draft.tabHistory = data;
        });
      });
    },
    changeResponsiblePerson: (selectedPerson: LovDTO, callback: () => void = () => {}) => {
      cuSetResponsiblePersonToExpedition(state.header.companyId, state.header.applicationId, selectedPerson.id, () => {
        cuGetDetailExpeditionPlan(state.header.companyId, state.header.applicationId, data => {
          setState(draft => {
            draft.tabExpeditionPlan = data;
            callback();
          });
        });
      });
    },
    hideCustomerChat: () => {
      setState(draft => {
        draft.customerChatData.visible = false;
      });
    },
    openCustomerChat: () => {
      setState(draft => {
        draft.customerChatData.visible = true;
        draft.customerChatData.selectedCarrierId = null;
      });
    },
    openCustomerChatWithCarrier: (carrierId: string) => {
      setState(draft => {
        draft.customerChatData.visible = true;
        draft.customerChatData.selectedCarrierId = carrierId;
      });
    },
    addCarrierToDistribution: (selectedPerson: CarrierGroupInDTO[]) => {
      cuAddMoreCarriersToDistribution(state.header.companyId, state.header.applicationId, { carriers: selectedPerson }, () => {
        cuGetDetailExpeditionCarrierTabAssigning(state.header.companyId, state.header.applicationId, data => {
          setState(draft => {
            draft.tabAssigningData = data.assigning;
          });
        });
      });
    },
    editRequestCoverage: (data: PriceAndValidityData) => {
      cuEditTimeOrPriceDistribution(
        state.header.companyId,
        state.header.applicationId,
        {
          price: data.price,
          currency: data.currency,
          validTill: mapToAPIDateTime(data.validTill),
          isPublish: data.isPublish,
        },
        () => {
          cuGetDetailExpeditionCarrierTabAssigning(state.header.companyId, state.header.applicationId, data => {
            setState(draft => {
              draft.tabAssigningData = data.assigning;
              draft.header = data.expeditionHeaderPreview;
            });
          });
        },
      );
    },
    cuEditCarrierRequirement: (data: CarrierRequirementEditInDTO) => {
      cuEditCarrierRequirement(state.header.companyId, state.header.applicationId, data, () => {
        cuGetDetailExpeditionPlan(state.header.companyId, state.header.applicationId, result => {
          setState(draft => {
            draft.tabExpeditionPlan = result;
          });
        });
      });
    },
    changeLocation: (index: number, data: LocationDetailPlanOutDTO) => {
      cuEditLocations(
        state.header.companyId,
        state.header.applicationId,
        data.id,
        {
          contactEmail: data.contactEmail,
          contactName: data.contactName,
          contactPhone: data.contactPhone,
          pickupCode: data.pickupCode,
          loadedOrders: data.loadedOrders.map(lo => {
            return {
              id: lo.id,
              name: lo.name,
              weight: lo.totalWeight,
              cargos: lo.cargos.map(cargo => {
                return {
                  weightMode: cargo.weightMode,
                  length: cargo.length,
                  height: cargo.height,
                  weight: cargo.weight,
                  cargoItemType: cargo.cargoItemTypeCode,
                  width: cargo.width,
                  stackable: cargo.stackable,
                  quantityUnit: cargo.quantityUnit,
                  quantity: cargo.quantity,
                  cargoMessage: cargo.cargoMessage,
                };
              }),
            };
          }),

          timeslots: data.timeslots /*data.timeslots.map((ts) => {
            if (ts.isNotSpecifiedTime) {
              return {
                whenInUtc: mapFromAPIDateTime(ts.sinceInUTC).format('YYYY-MM-DD'),
              };
            } else
              return {
                whenInUtc: formatAPIDateOnly(mapFromAPIDateTime(ts.sinceInUTC)),
                sinceInUtc: formatAPITimeOnly(mapFromAPIDateTime(ts.sinceInUTC)),
                untilInUtc: formatAPITimeOnly(mapFromAPIDateTime(ts.tillInUTC)),
              };
          }),*/,
          enabledEmailNotificationForContactPerson: data.enabledEmailNotificationForContactPerson,
        },
        () => {
          cuGetDetailExpeditionPlan(state.header.companyId, state.header.applicationId, result => {
            setState(draft => {
              draft.tabExpeditionPlan = result;
            });
          });
        },
      );
    },
    recofirmExpedition: (customerId: string, applicationId: string, onSuccess: () => void = () => {}) => {
      cuReconfirmedExpedition(customerId, applicationId, () => {
        onSuccess();
      });
    },
  };

  return <CustomerExpeditionViewContext.Provider value={methods}>{children}</CustomerExpeditionViewContext.Provider>;
};
