import React from 'react';
import { Card } from 'primereact/card';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import DemandDetailComponent from '@app/pages/carrier/demand-detail/DemandDetailComponent.tsx';

interface ComponentProps {}

const DemandDetailPage: React.FC<ComponentProps> = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`Demand.demandsDetail`, `Demands detail`)}>
      {/*// @ts-ignore*/}

      <Card>
        <DemandDetailComponent />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(DemandDetailPage);
