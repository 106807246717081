import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Clickable } from '@components/ringil3/styles';
import { RowWithSpaceBetween } from '@components/styles';
import AddButton from '@components/ringil3/Buttons/AddButton';

interface ComponentProps {
  onClick: () => void;
}

const AddOrderItemButton: React.FC<ComponentProps> = ({ onClick }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <AddOrderItemButtonWrapper onClick={() => onClick()}>
        <RowWithSpaceBetween>
          <Clickable>{tr(`AddOrderItemButtonWrapper.orderItem`, `Order item`)}</Clickable>
          <AddButton onClick={() => onClick()} />
        </RowWithSpaceBetween>
      </AddOrderItemButtonWrapper>
    </>
  );
};

const AddOrderItemButtonWrapper = styled.div`
  padding: 16px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid var(--ringil3-gr2);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 16px 48px rgba(254, 80, 0, 0.02);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-self: flex-start;
`;

export default AddOrderItemButton;
