import { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface ComponentProps {
  wrapper?: string;
}

export const StackableWrapper: React.FC<PropsWithChildren<ComponentProps>> = ({ wrapper, children }) => {
  const className = clsx('stackable-wrapper p-field', {
    '': wrapper === '',
    'p-col-12 p-md-3': wrapper === 'p-col-12 p-md-3',
    'p-fluid p-col-12 p-md-3': wrapper === 'p-fluid p-col-12 p-md-3',
    'p-fluid': wrapper === 'p-fluid',
  });

  return <div className={className}>{children}</div>;
};
