import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { Button } from 'primereact/button';
import { AllReservationsFilter, PageResponsePartnerPreviewOutDTO, PartnerDetailInDTO, PartnerPreviewOutDTO } from '../../../../api/logsteo-api.v2';
import { dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useDebounce } from 'use-debounce';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import CreateOrEditPartner from '@components/obsolete/partners/components/CreateOrEditPartner.tsx';

interface Filter {
  q: string;
}

const AllPartnersList = () => {
  const { t } = useTranslation('common');
  const { cuListPartners, cuCreatePartner, cuDeletePartner } = useContext(ApiContext);
  const [pageData, setPageData] = useState<PageResponsePartnerPreviewOutDTO>(undefined);
  const [addNewPartnerModal, setAddNewPartnerModal] = useState(false);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort, applyFilter } = useFilter<Filter>(
    (page, filter) => {
      loadData(page.pageSize, page.pageNumber, filter.q);
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  const [deletingPartnerId, setDeletingPartnerId] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const loadData = (pageSize: number, pageNumber: number, searchTerm = '') => {
    setLoading(true);
    cuListPartners({ pageSize, pageNumber: pageNumber }, searchTerm, data => {
      setPageData(draft => {
        setLoading(false);
        return data;
      });
    });
  };

  const navigation = useNavigation();

  /*  const onPage = (event) => {
      updateUrl(event.page + 1, event.rows);
      loadData(event.rows, event.page + 1);
    };*/

  const savePartner = (data: PartnerDetailInDTO) => {
    cuCreatePartner(data, () => {
      loadData(page.pageSize, page.pageNumber);
      setAddNewPartnerModal(false);
    });
  };

  const deletePartner = (partnerId: string) => {
    cuDeletePartner(partnerId, () => {
      loadData(page.pageSize, page.pageNumber);
      setDeletingPartnerId(undefined);
    });
  };

  const navBodyTemplate = (rowData: PartnerPreviewOutDTO) => {
    return (
      <div>
        <ButtonLayout>
          {/* <Link href={`/customer/partner/${rowData.partnerId}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createPartnerDetail(rowData.partnerId))}>
            <a className="btn-detail p-button p-button-rounded p-button-outlined secondary-btn">{t(`AllPartnersList.detail`, `Detail`)}</a>
          </Link>

          <SplitButton
            icon="pi pi-ellipsis-h"
            model={[
              {
                label: t(`AllPartnersList.delete`, `Smazat`),
                icon: 'pi pi-trash',
                command: () => {
                  setDeletingPartnerId(rowData.partnerId);
                },
              },
            ]}
            className="btn-more-info p-button-rounded p-button-outlined p-ml-3"
          />
        </ButtonLayout>
      </div>
    );
  };

  const nameBodyTemplate = (rowData: PartnerPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllPartnersList.name`, `Název`)} />
        <div>
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createPartnerDetail(rowData.partnerId))}>{rowData.name}</Link>
        </div>
      </>
    );
  };
  const addressBodyTemplate = (rowData: PartnerPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllPartnersList.address`, `Adresa`)} />
        <ColumnContent
          content={
            <>
              {rowData.address.streetNr}, {rowData.address.city}, {rowData.address.zipCode} ({rowData.address.country})
            </>
          }
        />
      </>
    );
  };
  const crnBodyTemplate = (rowData: PartnerPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllPartnersList.crn`, `IČ`)} />
        <ColumnContent content={rowData.crn} />
      </>
    );
  };
  const emailBodyTemplate = (rowData: PartnerPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllPartnersList.mainContact`, `Hlavní kontakt`)} />
        <ColumnContent content={rowData.contactEmail} />
      </>
    );
  };
  const createdBodyTemplate = (rowData: PartnerPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllPartnersList.created`, `Vytvořeno`)} />
        <ColumnContent content={dateFormatOnlyDateShort(mapFromAPIDateTime(rowData.createdAt))} />
      </>
    );
  };

  /*
  const [searchText, setSearchText] = useState("");
  const [searchTextDebounced] = useDebounce(searchText, 1000);

  useEffect(() => {
    loadData(pageSize, pageNumber, searchTextDebounced);
  }, [searchTextDebounced]);
*/

  return (
    <>
      <NavTitle wrapper="PADDING-0">
        <h2 className="h2-title p-m-0">{t(`AllPartnersList.partners`, `Partneři`)}</h2>
        <Button
          label={t(`AllPartnersList.newPartner`, `Nový partner`)}
          icon="icon pi pi-plus"
          className="p-btn-edit p-button-rounded"
          onClick={() => setAddNewPartnerModal(true)}
        />
      </NavTitle>
      <div className="filter-table p-mt-3 p-mb-2">
        <InputText
          id="fieldId"
          type="text"
          placeholder={t(`AllPartnersList.searchName`, `název`)}
          value={filter?.q}
          style={{ width: '100%' }}
          onChange={e => applyFilterValue('q', e.target.value)}
        />
      </div>
      {pageData && (
        <>
          {/*
        // @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            value={pageData.data}
            rows={pageData.size}
            totalRecords={pageData.total}
            className="same-table partners-table responsive-table p-mt-4"
            loading={loading}
            lazy={true}
            onPage={onPage}
            first={(pageData.page - 1) * pageData.size}
            paginator={true}>
            <Column className="same-table__name" field="name" header={t(`AllPartnersList.name`, `Název`)} body={nameBodyTemplate}></Column>
            <Column className="same-table__address" field="address" header={t(`AllPartnersList.address`, `Adresa`)} body={addressBodyTemplate}></Column>
            <Column field="crn" header={t(`AllPartnersList.crn`, `IČ`)} body={crnBodyTemplate}></Column>
            <Column className="same-table__email" field="mainContact" header={t(`AllPartnersList.mainContact`, `Hlavní kontakt`)} body={emailBodyTemplate}></Column>
            <Column className="same-table__created" field="created" header={t(`AllPartnersList.created`, `Vytvořeno`)} body={createdBodyTemplate}></Column>
            <Column className="same-table__nav" header={t(`AllPartnersList.actions`, `Akce`)} body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}
      {/*
        // @ts-ignore*/}
      <Dialog
        header={t(`AllPartnersList.deleteThisPartner`, `Smazání partnera`)}
        visible={deletingPartnerId}
        className="modal-sm"
        onHide={() => setDeletingPartnerId(undefined)}>
        <div>
          <p className="p-mt-0">
            {t(`AllPartnersList.byDeletingThisPartnerAllAssociatedInformationsWillBeLost`, `Smazáním partnera se smažou všechny navázáné informace`)}
          </p>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`AllPartnersList.delete`, `Smazat`)} className="p-mr-2 p-button-danger" onClick={() => deletePartner(deletingPartnerId)} />
          <Button label={t(`AllPartnersList.cancel`, `Zrušit`)} className="p-button-text" onClick={() => setDeletingPartnerId(undefined)} />
        </ButtonLayout>
      </Dialog>
      <CreateOrEditPartner visible={addNewPartnerModal} onHide={() => setAddNewPartnerModal(false)} onComplete={savePartner} />
    </>
  );
};

export default AllPartnersList;
