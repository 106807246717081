import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPriceAlert2 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={19} fill="none" {...props}>
    <path
      fill="#9EC600"
      d="M11.5 13.823a3 3 0 0 0 3-3c0-1.12-.61-2.1-1.5-2.61l-9.71-5.62 5.53 9.58c.5.98 1.51 1.65 2.68 1.65m0-13c-1.81 0-3.5.5-4.97 1.32l2.1 1.21c.87-.34 1.87-.53 2.87-.53a8 8 0 0 1 8 8c0 2.21-.89 4.21-2.34 5.65h-.01a.996.996 0 0 0 0 1.41c.39.39 1.03.39 1.42.01a9.97 9.97 0 0 0 2.93-7.07 10 10 0 0 0-10-10m-10 10c0 2.76 1.12 5.26 2.93 7.07a1.005 1.005 0 0 0 1.626-.333 1 1 0 0 0-.216-1.087 7.95 7.95 0 0 1-2.34-5.65c0-1 .19-2 .54-2.9l-1.21-2.1c-.83 1.5-1.33 3.18-1.33 5"
    />
  </svg>
);
export default SvgPriceAlert2;
