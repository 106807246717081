import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { CarrierDetailGroupsOutDTO, CatalogCarrierDetailOutDTO, PageResponseCarrierDetailGroupsOutDTO } from '@api/logsteo-api.v2';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import AddToCarrierGroup from './sidebars/AddToCarrierGroup';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ContactEntry from './ContactEntry';
import FieldValue from './FieldValue';
import AddCarrierSidebar from '../CarrierCatalog/AddCarrierSidebar';
import { Tooltip } from 'primereact/tooltip';
import { ApiContext } from '../../../../api/api';
import { Identifier } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import useNavigation from '@hooks/useNavigation.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { Link } from 'react-router-dom';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import { AddCarrierUserSidebar } from '@components/obsolete/CarrierDetail/AddCarrierUserSidebar.tsx';

interface ComponentProps {
  carrierId: string;
  back?: string;
}

export enum Back {
  CARRIERS = 'carriers',
  CARRIER_CATALOG = 'carrier-catalog',
}

interface FilterType {}

const CarrierDetail: React.FC<ComponentProps> = ({ carrierId, back = 'carriers' }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const nav = useNavigation();

  const { cuDetailCarrier, cuDetailCarrierGroupsPage, cuRemoveGroupOnCarrierDetail, cuChangeBlacklistCarrierUser } = useContext(ApiContext);
  const [carrierData, setCarrierData] = useState<CatalogCarrierDetailOutDTO>(undefined);
  const [carrierGroups, setCarrierGroups] = useState<PageResponseCarrierDetailGroupsOutDTO>(undefined);
  const [groupToRemoveFromId, setGroupToRemoveFromId] = useState(undefined);
  const [visibleAddToGroup, setVisibleAddToGroup] = useState(false);
  const [visibleAddCarrierUserForm, setVisibleAddCarrierUserForm] = useState({
    visible: false,
  });

  const navigation = useNavigation();

  const [carrierToAddID, setCarrierToAddID] = useState(undefined);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<FilterType>((page, filter) => {
    loadCarrierGroups(page.pageSize, page.pageNumber);
  });

  const displayRegionsAndCars = [carrierData?.cars, carrierData?.importNote, carrierData?.exportNote, carrierData?.exportCountries, carrierData?.importCountries].some(
    t => t !== null && t?.length > 0,
  );

  const loadCarrierData = () => {
    cuDetailCarrier(carrierId, data => {
      setCarrierData(data);
    });
  };

  const loadCarrierGroups = (pageSize: number, pageNumber: number) => {
    cuDetailCarrierGroupsPage(carrierId, { pageSize, pageNumber }, data => {
      setCarrierGroups(data);
    });
  };

  const removeFromGroup = () => {
    cuRemoveGroupOnCarrierDetail(carrierId, groupToRemoveFromId, () => {
      loadCarrierData();
      loadCarrierGroups(10, 0);
      setGroupToRemoveFromId(undefined);
    });
  };

  const load = () => {
    loadCarrierData();
    /*loadCarrierGroups(10, 0);*/
  };
  useEffect(() => {
    load();
  }, []);

  const toggleBlacklist = (contactId: Identifier, enabledDelivery: boolean) => {
    cuChangeBlacklistCarrierUser(carrierId, contactId, { toBlacklist: !enabledDelivery }, () => {
      load();
    });
  };

  const navBodyTemplate = (rowData: CarrierDetailGroupsOutDTO) => {
    return (
      <ButtonLayout>
        <Button className=" p-button p-button-rounded p-button-outlined" icon="pi pi-trash" onClick={() => setGroupToRemoveFromId(rowData.carrierGroupId)} />
      </ButtonLayout>
    );
  };

  const nameBodyTemplate = (rowData: CarrierDetailGroupsOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`CarrierDetail.name`, `Name`)} />
        {/*<Link href={`/customer/carrier-group/${rowData.carrierGroupId}`}>{rowData.name}</Link>*/}
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierGroupDetail(rowData.carrierGroupId))}>{rowData.name}</Link>
      </>
    );
  };
  const numberBodyTemplate = (rowData: CarrierDetailGroupsOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`CarrierDetail.numberOfCarriers`, `Number of carriers`)} />
        <ColumnContent content={rowData.count} />
      </>
    );
  };
  const createByBodyTemplate = (rowData: CarrierDetailGroupsOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`CarrierDetail.createdBy`, `Created by`)} />
        <ColumnContent content={rowData.createdBy} />
      </>
    );
  };
  const createdBodyTemplate = (rowData: CarrierDetailGroupsOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`CarrierDetail.created`, `Created`)} />
        <ColumnContent content={dateFormatDateTime(mapFromAPIDateTime(rowData.createdAt))} />
      </>
    );
  };

  const carrierBreadCrumb = () => {
    return [
      {
        label: t('CarrierDetail.allCarriers', 'All carriers'),
        url: /*"/customer/carriers"*/ navigation.createNavigationLink(navigation.urlFunctions.createMyCarrierList()),
      },
      {
        label: t('CarrierDetail.carrierDetail', 'Carrier detail'),
        disabled: true,
      },
    ];
  };

  const carrierCatalogBreadCrumb = () => {
    return [
      {
        label: t('CarrierDetail.carrierCatalog', 'Carrier catalog'),
        url: /*"/customer/carrier-catalog"*/ navigation.createNavigationLink(navigation.urlFunctions.createCarrierCatalogList()),
      },
      {
        label: t('CarrierDetail.carrierDetail', 'Carrier detail'),
        disabled: true,
      },
    ];
  };

  return (
    <>
      <BreadCrumbComponent items={back === Back.CARRIERS ? carrierBreadCrumb() : carrierCatalogBreadCrumb()} />
      {carrierData && (
        <>
          <HeadingRow>
            <HeadingRow>
              <h1>{carrierData.name}</h1>
              {carrierData.isMyCarrier && (
                <>
                  <Icon className={'pi pi-id-card'} id={`myCarrier`}></Icon>
                  {/*
        // @ts-ignore*/}
                  <Tooltip target={`#myCarrier`}>{tr(`CarrierDetail.myCarrier`, `My carrier`)}</Tooltip>
                </>
              )}
            </HeadingRow>
            {!carrierData.isAlreadyAdded && (
              <Button
                className="p-button p-button-rounded p-button-outlined"
                label={tr('CarrierDetail.addCarrier', 'Přidat mezi své dopravce')}
                onClick={e => setCarrierToAddID(carrierId)}
              />
            )}
            {carrierData.isAlreadyAdded && <i className={'pi pi-check'} style={{ fontSize: '2em' }} />}
          </HeadingRow>
          <DetailAndMap>
            <Detail>
              <h3>{tr(`CarrierDetail.basicInformationTitle`, `Základní údaje o dopravci`)}</h3>
              {carrierData.isRegistered && <FieldValue label={t('labelRegistrationStatus')} value={<small>{tr(`CarrierDetail.registred`, `Registred`)}</small>} />}
              <FieldValue label={t(`CarrierDetail.crn`, `CRN`)} value={carrierData.crn} />
              <FieldValue
                label={t(`CarrierDetail.address`, `Adresa`)}
                value={
                  <span>
                    {carrierData.streetNr}, {carrierData.city}, {carrierData.zipCode}, {carrierData.country}
                  </span>
                }
              />

              <FieldValue label={t(`CarrierDetail.wwwPage`, `WWW`)} value={carrierData.wwwPage} />

              {carrierData?.contactPersons?.length > 0 && (
                <>
                  <h3>{tr(`CarrierDetail.contactPersonsTitle`, `Kontaktní osoby`)}</h3>

                  {carrierData.contactPersons.map((t, index) => {
                    return (
                      <Strips>
                        <ContactEntry
                          key={index}
                          contactName={t.name}
                          contactEmail={t.email}
                          contactPhone={t.phone}
                          deliveringEnabled={!t.isBlacklisted}
                          onChange={enabledDelivery => toggleBlacklist(t.id, enabledDelivery)}
                        />
                      </Strips>
                    );
                  })}
                </>
              )}

              <Button
                label={t(`CarrierDetail.addNewUser`, `Přidat uživatele`)}
                className="p-button-outlined p-btn-edit"
                iconPos="left"
                icon="pi pi-plus"
                onClick={() => setVisibleAddCarrierUserForm({ visible: true })}
              />

              {displayRegionsAndCars && (
                <>
                  <h3>{tr(`CarrierDetail.regionAndCarsTitle`, `Pokrývané regiony a vozový park`)}</h3>
                  <FieldValue label={tr(`CarrierDetail.export`, `Země export`)} value={carrierData.exportCountries.join(', ')} />
                  <FieldValue label={tr(`CarrierDetail.exportNote`, `Poznámka export`)} value={carrierData.exportNote} />
                  <FieldValue label={tr(`CarrierDetail.import`, `Země import`)} value={carrierData.importCountries.join(', ')} />
                  <FieldValue label={tr(`CarrierDetail.importNote`, `Poznámka import`)} value={carrierData.importNote} />

                  <FieldValue
                    label={tr(`CarrierDetail.cars`, `Vozový park`)}
                    value={carrierData.cars
                      .map(t => {
                        return `${t.name} (${t.count})`;
                      })
                      .join(', ')}
                  />
                </>
              )}
            </Detail>
            <Map>
              {carrierData.hqLat && carrierData.hqLng && (
                <>
                  TODO: Map
                  {/*<HQMap
                      lat={carrierData.hqLat}
                      lgn={carrierData.hqLng}
                  />*/}
                </>
              )}
            </Map>
          </DetailAndMap>
        </>
      )}
      {carrierData?.isAlreadyAdded && (
        <>
          <NavTitle wrapper="WITH_PADDING-TOP-4_PADDING-BOTTOM-4">
            <h2 className="h2-title p-m-0">{t(`CarrierDetail.listOfGroupsCarrierIsMemberOf`, `List of groups carrier is member of`)}</h2>
            <Button
              label={t(`CarrierDetail.addToAnotherGroup`, `Add to another group`)}
              icon="pi pi-plus"
              className="p-btn-edit p-button-outlined"
              onClick={() => setVisibleAddToGroup(true)}
            />
          </NavTitle>

          {carrierGroups && (
            <>
              {/*
        // @ts-ignore*/}
              <DataTable
                emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
                value={carrierGroups.data}
                rows={carrierGroups.size}
                totalRecords={carrierGroups.total}
                paginator={true}
                lazy={true}
                onPage={onPage}
                first={(carrierGroups.page - 1) * carrierGroups.size}
                className="same-table responsive-table p-mt-4"
              >
                <Column className="same-table__name" field="name" header={t(`CarrierDetail.name`, `Name`)} body={nameBodyTemplate}></Column>
                <Column
                  className="same-table__address"
                  field="number"
                  header={t(`CarrierDetail.numberOfCarriers`, `Number of carriers`)}
                  body={numberBodyTemplate}
                ></Column>
                <Column className="same-table__createBy" field="createBy" header={t(`CarrierDetail.createdBy`, `Created by`)} body={createByBodyTemplate}></Column>
                <Column className="same-table__created" field="created" header={t(`CarrierDetail.created`, `Created`)} body={createdBodyTemplate}></Column>
                <Column className="same-table__nav" header={t(`CarrierDetail.actions`, `Actions`)} body={navBodyTemplate}></Column>
              </DataTable>
            </>
          )}
        </>
      )}

      <AddCarrierSidebar
        visible={carrierToAddID}
        onHide={() => {
          setCarrierToAddID(undefined);
        }}
        carrierId={carrierId}
        onAdded={() => {
          load();
          setCarrierToAddID(undefined);
        }}
      />
      {/*
        // @ts-ignore*/}
      <Dialog
        header={t(`CarrierDetail.removeThisCarrierFromTheGroup`, `Remove this carrier from the group`)}
        visible={groupToRemoveFromId}
        className="modal-sm"
        onHide={() => setGroupToRemoveFromId(undefined)}
      >
        <div>
          <p className="p-mt-0">{t(`CarrierDetail.areYouSureToRemove`, `Are you sure to remove this carrier from this carrier group?`)}</p>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`CarrierDetail.remove`, `Remove`)} className="p-mr-2 p-button-danger" onClick={removeFromGroup} />
          <Button label={t(`CarrierDetail.cancel`, `Cancel`)} className="p-button-text" onClick={() => setGroupToRemoveFromId(undefined)} />
        </ButtonLayout>
      </Dialog>

      <AddToCarrierGroup
        visible={visibleAddToGroup}
        onHide={() => setVisibleAddToGroup(false)}
        carrierId={carrierId}
        onSuccess={() => {
          setVisibleAddToGroup(false);
          loadCarrierGroups(10, 0);
          loadCarrierData();
        }}
      />

      <AddCarrierUserSidebar
        visible={visibleAddCarrierUserForm.visible}
        onHide={() => {
          setVisibleAddCarrierUserForm({ visible: false });
        }}
        onComplete={() => {
          load();
          setVisibleAddCarrierUserForm({ visible: false });
        }}
        carrierId={carrierId}
      />
    </>
  );
};

const Strips = styled.div`
  padding: 5px 0 5px 0;

  &:nth-child(odd) {
    background-color: #eeeeee;
  }

  &:nth-child(even) {
    background-color: white;
  }
`;

const Icon = styled.span`
  margin-left: 15px;
`;

const DetailAndMap = styled.div`
  display: flex;
`;

const Map = styled.div`
  flex-grow: 1;
`;

const Detail = styled.div`
  font-size: 1rem;
  padding-right: 15px;
  min-width: 540px;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 20px 0px;
  align-items: center;

  h1 {
    margin: 0;
    line-height: 21px;
  }
`;

export default CarrierDetail;
