import React from 'react';
import AttachmentUploadPanel from '@app/pages/attachment/AttachmentUploadPanel.tsx';

interface ComponentProps {}

const MapComponent: React.FC<ComponentProps> = () => {
  return (
    <>
      <AttachmentUploadPanel onSave={v => {}} />
    </>
  );
};

export default MapComponent;
