import React, { useContext, useEffect } from 'react';
import { AccessTypeEnum, EntityTypeEnum, FileOutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent.tsx';
import { AttachmentDocument } from '@components/ringil3/Features/Attachments/UploadAttachmentDialog.tsx';

interface ComponentProps {
  documents: FileOutDTO[];
  onDelete: (documentId: string) => void;
  applicationId: string;
  companyId: string;
  isExpeditionCanceled: boolean;
  isDeprecatedByManualMode: boolean;
  expeditionId: string;
}

interface DeleteDocumentInfo {
  documentId: string;
  visible: boolean;
}

const TabDocuments: React.FC<ComponentProps> = ({ documents, onDelete, expeditionId, applicationId, companyId, isExpeditionCanceled, isDeprecatedByManualMode }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { genericUploadV2, deleteDocumentForEntity, changeDocumentVisibility } = useContext(ApiContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    loadAttachments(EntityTypeEnum.EXPEDITION, expeditionId);
  };

  const { load: loadAttachments, AttachmentComponent } = useCreateAttachmentComponent(true, null, loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1);

  const addAttachment = (attachments: AttachmentDocument[]) => {
    genericUploadV2(
      {
        entityType: EntityTypeEnum.EXPEDITION,
        entityIds: [expeditionId],
        attachments: attachments.map(t => {
          return {
            attachmentId: t.attachmentId,
            accessType: t.accessType,
            documentType: t.documentType?.documentType,
            userNote: t.userNote,
            userDate: t.userDate,
          };
        }),
        companyOwnerId: null,
      },
      () => {
        load();
      },
    );
  };

  const delAttachment = (attachmentId: string) => {
    deleteDocumentForEntity(EntityTypeEnum.EXPEDITION, expeditionId, attachmentId, () => {
      load();
    });
  };

  const changeAttachmentVisibility = (attachmentId: string, internal: boolean) => {
    changeDocumentVisibility(attachmentId, { accessType: internal ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL }, () => {
      load();
    });
  };
  return (
    <>
      <AttachmentComponent
        entityId={expeditionId}
        entityType={EntityTypeEnum.EXPEDITION}
        onAddAttachment={attachments => {
          addAttachment(attachments);
        }}
        onDeletedAttachment={attachmentId => {
          delAttachment(attachmentId);
        }}
        onChangeVisibility={(attachmentId, internal) => {
          changeAttachmentVisibility(attachmentId, internal);
        }}
        disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
      />
    </>
  );
};
export default TabDocuments;
