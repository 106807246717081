import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { formatWeight, isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  weight: number;
}

const WeightBadge: React.FC<ComponentProps> = ({ weight }) => {
  const { tr } = useTranslationLgs();
  return <>{isNotNullOrUndefined(weight) && <Badge filled={false} value={formatWeight(weight)} highlightedValue={false}></Badge>}</>;
};

export default WeightBadge;
