import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tag/ButtonTag.tsx';
import { InputText } from 'primereact/inputtext';
import SvgETransportTruck from '@components/features/eTransport/icons/ETransportTruck.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { Input } from 'postcss';
import InputCountryPlain from '@components/obsolete/Form/InputCountry/InputCountryPlain.tsx';
import { dumpVars, isNullOrUndefined } from '@utils/utils.tsx';
import { ETransportCompanyDTO } from '@api/logsteo-api.v2.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import { ValidationError } from 'yup';
import { findValidationMessage, hasValidationProblemForPrefix } from '@utils/validation.tsx';

interface ComponentProps {
  value: ETransportCompanyDTO;
  onChange: (value: ETransportCompanyDTO) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

const ETransportTransportCompany: React.FC<ComponentProps> = ({ value, onChange, readonly, validationErrors, validationPrefix }) => {
  const [internalValue, setInternalValue] = useState<ETransportCompanyDTO>(value);
  const { tr } = useTranslationLgs();

  const printValue = (value: ETransportCompanyDTO) => {
    if (isNullOrUndefined(value?.transportCompanyName)) return null;

    return <MultipleValues values={[value.transportCompanyCountry, value.transportCompanyName, value.transportCompanyVatId]}></MultipleValues>;
  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  return (
    <>
      <ButtonTag
        label={tr(`ETransportTransportCompany.transportCompany`, `Transport company`)}
        icon={<SvgETransportTruck />}
        required={true}
        value={printValue(value)}
        readonly={readonly}
        errorMessage={hasError ? tr(`ETransportTransportCompany.validationError`, `Validation error`) : undefined}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        popupWidth={'350px'}>
        <>
          <Field
            label={tr(`ETransportTransportCompany.country`, `Transport company country of origin`)}
            errorMessage={findValidationMessage(validationErrors, validationPrefix + '.transportCompanyCountry', tr)}>
            <InputCountryPlain
              codeValue={internalValue?.transportCompanyCountry}
              onChange={code => setInternalValue({ ...internalValue, transportCompanyCountry: code })}
            />
          </Field>
          <Field
            label={tr(`ETransportTransportCompany.name`, `Transport company (legal) name`)}
            errorMessage={findValidationMessage(validationErrors, validationPrefix + '.transportCompanyName', tr)}>
            <InputText value={internalValue?.transportCompanyName} onChange={e => setInternalValue({ ...internalValue, transportCompanyName: e.target.value })} />
          </Field>
          <Field
            label={tr(`ETransportTransportCompany.vat`, `Transport company VAT ID`)}
            errorMessage={findValidationMessage(validationErrors, validationPrefix + '.transportCompanyVatId', tr)}>
            <InputText
              value={internalValue?.transportCompanyVatId}
              onChange={e =>
                setInternalValue({
                  ...internalValue,
                  transportCompanyVatId: e.target.value,
                })
              }
            />
          </Field>
        </>
      </ButtonTag>
    </>
  );
};

export default ETransportTransportCompany;
