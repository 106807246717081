import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Slider } from 'primereact/slider';
import { mapFromAPIDateTime, mapFromAPIToDateLong } from '@utils/date';
import { Note } from '@components/styles';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  since: string;
  till: string;
  calendarId: string;
  reservationId: string;
  onComplete: () => void;
}

export const useCreateChangeSlotDuration = () => {
  const [visible, setVisible] = useState(false);

  const ReservationChangeSlotDuration: React.FC<ComponentProps> = ({ since, till, calendarId, reservationId, onComplete }) => {
    const { tr } = useTranslationLgs();
    const { updateReservationTimeslotDuration } = useContext(ApiContext);

    const max = mapFromAPIDateTime(till).diff(mapFromAPIDateTime(since), 'minutes');
    const [durationInMinues, setDurationInMinues] = useState(max);

    const tillComputed = mapFromAPIDateTime(since).add(durationInMinues, 'minutes').toISOString();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const changeDuration = () => {
      updateReservationTimeslotDuration(reservationId, { since: since, till: tillComputed, calendarId }, () => {
        setVisible(false);
        onComplete();
      });
    };

    return (
      <>
        <RingilDialog headerText={tr(`ChangeSlotSidebar.changeSlot`, `Change Slot`)} visible={visible} onHide={() => setVisible(false)} onSave={() => changeDuration()}>
          <BackendValidationComponent />
          <Grid>
            <HorizontalFieldValue label={tr(`ChangeSlotSidebar.from`, `From`)} value={mapFromAPIToDateLong(since)} />
            <HorizontalFieldValue label={tr(`ChangeSlotSidebar.till`, `Till`)} value={mapFromAPIToDateLong(tillComputed)} />
          </Grid>
          <HorizontalFieldValue
            label={tr(`ChangeSlotSidebar.duration`, `Duration`)}
            value={<Slider max={max} value={durationInMinues} onChange={e => setDurationInMinues(e.value as number)} step={1} />}
          />
          <Note>{tr(`ChangeSlotSidebar.changeTheValueByDraggingTheSlider`, `Change the value by dragging the slider`)}</Note>
        </RingilDialog>
      </>
    );
  };
  return { ReservationChangeSlotDuration, setVisible, visible };
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
