import React, { useContext, useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation';

import { ApiContext } from '@api/api';
import {
  CargoTypeCodeEnum,
  ShipmentDataForSNOrderInDTO,
  ShipmentStateCodeEnum,
  SupplierNotificationItemInDTO,
  TransportationTypeEnum,
  WeightModeEnum,
} from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';
import { useParams, useSearchParams } from 'react-router-dom';
import Layout from '@app/layout/Layout.tsx';
import CreateSupplierNotification, { CreateSupplierNotificationFormData } from '@app/pages/customer/supplier-notification/CreateSupplierNotification.tsx';
import { Order, OrderItem } from '@components/obsolete/Orders/types';
import { AttachmentDocument } from '@components/ringil3/Features/Attachments/UploadAttachmentDialog.tsx';

interface ComponentProps {}

const CreateSupplierNotificationPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const [searchParams] = useSearchParams();

  const fromShipmentId = searchParams.get('fromShipmentId');

  const { getShipmentDataForSupplierNotification } = useContext(ApiContext);

  const [initialData, setInitialData] = useState<CreateSupplierNotificationFormData>();

  const convertToOrder = (t: ShipmentDataForSNOrderInDTO): Order => {
    return {
      name: t.name,
      cargoAmount: t.cargoAmount,
      weight: t.weight,
      currencyOrderValue: t.currencyOrderValue,
      cargoDescription: t.cargoDescription,
      items: t.items.map(t => convertToItem(t)),
    } as Order;
  };

  const convertToItem = (t: SupplierNotificationItemInDTO): OrderItem => {
    return {
      quantityUnit: t.quantityUnitCode,
      weight: t.weight,
      width: t.width,
      stackable: isNullOrUndefined(t.stackable) ? false : t.stackable,
      quantity: t.quantity,
      height: t.height,
      length: t.length,
      cargoItemType: { code: t.cargoItemTypeCode },
      cargoMessage: '',
      weightMode: WeightModeEnum.TOTAL_WEIGHT,
    } as OrderItem;
  };

  useEffect(() => {
    getShipmentDataForSupplierNotification(fromShipmentId, data => {
      const initialData: CreateSupplierNotificationFormData = {
        transportationType: TransportationTypeEnum.GROUND,
        attachments: data.attachmentsData.map(t => {
          return {
            ...t,
            documentType: {
              documentType: t.documentType,
              name: t.documentTypeName,
            },
          } as AttachmentDocument;
        }),
        cargoType: {
          cargoTypeCode: data.cargoType.cargoType.cargoTypeCode,
          adrWeight: data.cargoType.adrWeight,
        },
        containerInfo: data.containerInfo,
        customsTypeCode: data.customsTypeCode,
        referralCode: data.referralCode,

        fromShipmentId: fromShipmentId,
        orders: data.orders.map(t => convertToOrder(t)),
        companyId: null,
        supplierId: null,
        specialRequirementIds: data.specialRequirementIds,
        supplierLocationId: null,
        customerLocationId: null,
        shipmentStateCode: ShipmentStateCodeEnum.WAITING,
        notifyData: {
          notifyUsersDependsOnRole: true,
          responsiblePersonId: null,
          userIdsToNotify: [],
        },
        shipmentId: data.shipmentId,
        supplierDeliverySlots: { dayWithInterval: [] },
        customerDeliverySlots: { dayWithInterval: [] },
      };
      setInitialData(initialData);
    });
  }, [fromShipmentId]);

  return (
    <>
      <Layout title={tr(`create-shipment.createSupplierNotification`, `Create aviso`)}>{initialData && <CreateSupplierNotification initialData={initialData} />}</Layout>
    </>
  );
};

export default withAuthenticationRequired(CreateSupplierNotificationPage);
