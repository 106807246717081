import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import { CompanyListOutDTO, PageResponseCompanyListOutDTO } from '@api/logsteo-api.v2';
import { Clickable, Heading, RowWithSpaceBetween } from '@components/styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { mapFromAPIToDateShort } from '@utils/date';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { proxiedPropertiesOf } from '@hooks/useForm/useForm.tsx';
import { Link } from 'react-router-dom';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import EmailPreferencesDialog from '@components/pages/admin/EmailPreferences/EmailPreferences/EmailPreferencesDialog.tsx';

interface ComponentProps {}

interface Filter {
  name?: string;
}

const AllCompanies: React.FC<ComponentProps> = () => {
  const { adminListCompanies } = useContext(ApiContext);
  const [companies, setCompanies] = useState<PageResponseCompanyListOutDTO>();
  const [showNotificationSBForCompanyId, setShowNotificationSBForCompanyId] = useState(undefined);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<Filter>((page, filter) => {
    load(page.pageSize, page.pageNumber);
  });

  const load = (pageSize: number, pageNumber: number) => {
    adminListCompanies({ pageNumber, pageSize }, filter.name, data => {
      setCompanies(data);
    });
  };

  const names = proxiedPropertiesOf<Filter>();

  return (
    <>
      <RowWithSpaceBetween>
        <Heading>Spolecnosti</Heading>
        <Clickable>
          <Link to={'/admin/import-companies'}>Import </Link>
        </Clickable>
      </RowWithSpaceBetween>
      <HorizontalFieldValue label={'Name'} value={<InputText value={filter?.name} onChange={e => applyFilterValue(names.name, e.target.value)} />} />
      {companies?.data && (
        <>
          {/*// @ts-ignore*/}
          <DataTable
            value={companies.data}
            rows={companies.size}
            totalRecords={companies.total}
            onPage={onPage}
            first={(companies.page - 1) * companies.size}
            paginator={true}
            lazy={true}
          >
            <Column header={'ID'} body={(row: CompanyListOutDTO) => <Link to={`/admin/company/${row.companyId}`}>{row.companyId}</Link>} />
            <Column header={'Name'} body={(row: CompanyListOutDTO) => <div>{row.name}</div>} />
            <Column header={'CRN'} body={(row: CompanyListOutDTO) => <div>{row.crn}</div>} />
            <Column header={'CreatedAt'} body={(row: CompanyListOutDTO) => <div>{mapFromAPIToDateShort(row.createdAt)}</div>} />
            <Column
              header={'Action'}
              body={(row: CompanyListOutDTO) => (
                <Button
                  label={'Email Prefs'}
                  onClick={e => {
                    setShowNotificationSBForCompanyId(row.companyId);
                  }}
                ></Button>
              )}
            ></Column>
          </DataTable>
        </>
      )}
      <EmailPreferencesDialog
        visible={showNotificationSBForCompanyId}
        onHide={() => setShowNotificationSBForCompanyId(undefined)}
        companyId={showNotificationSBForCompanyId}
      />
    </>
  );
};

export default AllCompanies;
