import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

interface ComponentProps {
  isSpinning?: boolean;
}

const RingilSpinner: React.FC<ComponentProps> = ({ isSpinning = false }) => {
  return (
    <>
      {isSpinning && (
        <>
          <ProgressSpinner
            style={{
              width: '15px',
              height: '15px',
              marginRight: '0.5rem',
              color: 'var(--ringil3-brand-color)',
            }}
            strokeWidth="3"
            animationDuration="1s"
          />
        </>
      )}
    </>
  );
};

export default RingilSpinner;
