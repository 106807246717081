import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#FE5000"
      d="M9 11.25H6A2.25 2.25 0 0 1 3.75 9V6A2.25 2.25 0 0 1 6 3.75h3A2.25 2.25 0 0 1 11.25 6v3A2.25 2.25 0 0 1 9 11.25m-3-6a.76.76 0 0 0-.75.75v3a.76.76 0 0 0 .75.75h3A.76.76 0 0 0 9.75 9V6A.76.76 0 0 0 9 5.25zM9 20.25H6A2.25 2.25 0 0 1 3.75 18v-3A2.25 2.25 0 0 1 6 12.75h3A2.25 2.25 0 0 1 11.25 15v3A2.25 2.25 0 0 1 9 20.25m-3-6a.76.76 0 0 0-.75.75v3a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75v-3a.76.76 0 0 0-.75-.75zM18 11.25h-3A2.25 2.25 0 0 1 12.75 9V6A2.25 2.25 0 0 1 15 3.75h3A2.25 2.25 0 0 1 20.25 6v3A2.25 2.25 0 0 1 18 11.25m-3-6a.76.76 0 0 0-.75.75v3a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75V6a.76.76 0 0 0-.75-.75zM18 20.25h-3A2.25 2.25 0 0 1 12.75 18v-3A2.25 2.25 0 0 1 15 12.75h3A2.25 2.25 0 0 1 20.25 15v3A2.25 2.25 0 0 1 18 20.25m-3-6a.76.76 0 0 0-.75.75v3a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75v-3a.76.76 0 0 0-.75-.75z"
    />
  </svg>
);
export default SvgMenuDashboard;
