import { useContext, useEffect, useState } from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useDrivers = (expeditionId: string) => {
  const [drivers, setDrivers] = useState<LovDTO[]>([]);
  const { lovDriverTemplatesForExp } = useContext(ApiContext); // LovDTO

  useEffect(() => {
    if (expeditionId) {
      lovDriverTemplatesForExp(expeditionId, data => setDrivers(data));
    }
  }, [expeditionId]);

  return drivers;
};

export default useDrivers;
