import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { OnlyCompanyAdmin } from '@utils/security';
import Layout from '@app/layout/Layout.tsx';
import CompanyNotificationList from '@components/features/CompanyNotification/Customer/CompanyNotificationList.tsx';

interface ComponentProps {}

const CompanyNotificationPage: React.FC<ComponentProps> = () => {
  const {} = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  return (
    <>
      <Layout title={tr(`CompanyNotificationPage.title`, `Námi rozeslaná upozornění`)}>
        <OnlyCompanyAdmin>
          <CompanyNotificationList />
        </OnlyCompanyAdmin>
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(CompanyNotificationPage);
