import React from 'react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SelectButton } from 'primereact/selectbutton';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface LocationFormatProps {
  value: string;
  onChange: (newValue: string) => void;
}

const LocationFormat: React.FC<LocationFormatProps> = ({ value, onChange }) => {
  const options = [
    { name: 'Zip, City, Country', code: 'SHORT' },
    { name: 'Location name, Street, City, Zip, Country', code: 'LONG' },
  ];
  return <SelectButton options={options} optionValue={'code'} optionLabel={'name'} value={value} onChange={e => onChange(e.value)} />;
};

interface ComponentProps {}

interface FormState {
  locationRenderingType: string;
}

const CustomerSettings: React.FC<ComponentProps> = () => {
  const [state, setState] = useImmer<FormState>({
    locationRenderingType: 'SHORT',
  });
  return (
    <AdminLayout title={'Customer admin - dashboard'}>
      <HorizontalFieldValue
        label={'How to render location for carriers'}
        value={
          <LocationFormat
            value={state.locationRenderingType}
            onChange={value =>
              setState(draft => {
                draft.locationRenderingType = value;
              })
            }
          />
        }
      />
      <Note>By checking the short value </Note>
    </AdminLayout>
  );
};

const Note = styled.div`
  display: flex;
  font-size: small;
  color: gray;
`;

export default withAuthenticationRequired(CustomerSettings);
