import React from 'react';
import styled from 'styled-components';
import { InputText } from 'primereact/inputtext';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const InputPlate: React.FC<ComponentProps> = ({ label, onChange, value, disabled = false }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Label title={label} />
      <div className="p-inputgroup">
        <InputText
          onChange={e => {
            const targetValue = (e.target as HTMLInputElement).value;
            return onChange(targetValue);
          }}
          value={value}
          disabled={disabled}
        />
        <span className="p-inputgroup-addon">
          <i className="pi pi-id-card"></i>
        </span>
      </div>
      <Note>
        <b>{tr(`InputPlate.note1`, `Máte auto s přívěsem?`)}</b>
        <p>{tr(`InputPlate.note2`, `Např. tahač SPZ 0001 | 1. přívěs SPZ 0002 | 2. přívěs SPZ 00003`)} </p>
        <p>{tr(`InputPlate.note3`, `Oddělte SPZ čárkami: SPZ 0001, SPZ 0002. SPZ 0003`)}</p>
      </Note>
    </>
  );
};

const Note = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.78rem;

  b {
    font-weight: 700;
    color: black;
    margin-top: 0.3rem;
  }

  p {
    margin: 0;
  }
`;

export default InputPlate;
