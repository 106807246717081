import React, { useContext, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useImmer } from 'use-immer';
import { PartnerDetailInDTO } from '@api/logsteo-api.v2';
import useValidation from '@hooks/validation-hook/useValidation';
import * as yup from 'yup';
import { findValidationMessage } from '@utils/validation';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import InputWrapper from '@components/obsolete/Form/InputWrapper/InputWrapper.tsx';
import InputCountry from '@components/obsolete/Form/InputCountry/InputCountry.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  partnerId?: string;
  onComplete: (data: PartnerDetailInDTO) => void;
}

const CreateOrEditPartner: React.FC<ComponentProps> = ({ visible, onHide, partnerId, onComplete }) => {
  const { t } = useTranslation('common');
  const { cuGetPartnerDetail } = useContext(ApiContext);
  const [partner, setPartner] = useImmer<PartnerDetailInDTO>(undefined);

  const validationScheme = yup.object().shape({
    name: yup.string().required(),
    country: yup.string().required(),
    zipCode: yup.string().required(),
    city: yup.string().required(),
    streetNr: yup.string().required(),
    contactEmail: yup.string().email().nullable(),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(validationScheme, () => {
    onComplete(partner);
  });

  useEffect(() => {
    if (visible) {
      if (partnerId) {
        cuGetPartnerDetail(partnerId, data => {
          setPartner(draft => {
            return {
              crn: data.crn,
              city: data.address.city,
              contactEmail: data.contactEmail,
              country: data.address.country,
              contactPerson: data.contactPerson,
              name: data.name,
              contactPhone: data.contactPhone,
              streetNr: data.address.streetNr,
              zipCode: data.address.zipCode,
            };
          });
        });
      } else {
        setPartner(draft => {
          return {
            crn: '',
            name: '',
            zipCode: '',
            streetNr: '',
            contactPhone: '',
            contactPerson: '',
            country: '',
            contactEmail: '',
            city: '',
          };
        });
      }
    }
    clear();
  }, [visible]);

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal  sidebar-modal-edit-partner"
        style={{ width: '750px' }}>
        <h3 className="p-mb-4">{t(`CreateOrEditPartner.editPartner`, `Edit partner`)}</h3>
        {partner && (
          <>
            <InputLayout>
              <InputWrapper
                label={t(`CreateOrEditPartner.partnerName`, `Partner name`)}
                type="text"
                required={true}
                classNameWrapper="p-col-12 p-md-6"
                value={partner.name}
                onChange={e =>
                  setPartner(draft => {
                    draft.name = (e.target as HTMLInputElement).value;
                  })
                }
                error={findValidationMessage(validationErrors, 'name', t)}
              />
              <InputWrapper
                label={t(`CreateOrEditPartner.crn`, `CRN`)}
                type="text"
                value={partner.crn}
                onChange={e =>
                  setPartner(draft => {
                    draft.crn = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
              />
              <InputCountry
                label={t(`CreateOrEditPartner.country`, `Country`)}
                value={partner.country}
                required={true}
                onChange={value =>
                  setPartner(draft => {
                    draft.country = value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'country', t)}
              />
              <InputWrapper
                label={t(`CreateOrEditPartner.postalCode`, `Postal code`)}
                type="text"
                required={true}
                value={partner.zipCode}
                onChange={e =>
                  setPartner(draft => {
                    draft.zipCode = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'zipCode', t)}
              />
              <InputWrapper
                label={t(`CreateOrEditPartner.city`, `City`)}
                type="text"
                required={true}
                value={partner.city}
                onChange={e =>
                  setPartner(draft => {
                    draft.city = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'city', t)}
              />
              <InputWrapper
                label={t(`CreateOrEditPartner.streetAndNumber`, `Street and number`)}
                type="text"
                required={true}
                value={partner.streetNr}
                onChange={e =>
                  setPartner(draft => {
                    draft.streetNr = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'streetNr', t)}
              />
              <InputWrapper
                label={t(`CreateOrEditPartner.contactPerson`, `Contact person`)}
                type="text"
                value={partner.contactPerson}
                onChange={e =>
                  setPartner(draft => {
                    draft.contactPerson = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
              />
              <InputWrapper
                label={t(`CreateOrEditPartner.contactEmail`, `Contact email`)}
                type="text"
                value={partner.contactEmail}
                onChange={e =>
                  setPartner(draft => {
                    draft.contactEmail = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors, 'contactEmail', t)}
              />
              <InputWrapper
                label={t(`CreateOrEditPartner.contactPhone`, `Contact phone`)}
                type="text"
                value={partner.contactPhone}
                onChange={e =>
                  setPartner(draft => {
                    draft.contactPhone = (e.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12 p-md-6"
              />
            </InputLayout>
            <ButtonLayout wrapper="WITH_PADDING_TOP_4">
              <Button label={t(`CreateOrEditPartner.save`, `Save`)} className="p-mr-2" onClick={() => validateAndSend(partner)} />
              <Button label={t(`CreateOrEditPartner.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default CreateOrEditPartner;
