import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
}

export const OffIssueOrdersDialog: React.FC<ComponentProps> = ({ visible, onHide }) => {
  const { t } = useTranslation('common');

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog className="modal-sm" header={t(`OffIssueOrdersDialog.turnOffIssueOfOrders`, `Turn off issue of orders`)} visible={visible} onHide={onHide}>
        <div>
          <p className="p-mt-0">
            {t(`OffIssueOrdersDialog.byTurningOff`, `By turning off issuing of orders, Logsteo will no longer generate orders of your expeditions.`)}
          </p>
        </div>
        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          <Button label={t(`OffIssueOrdersDialog.turnOff`, `Turn off`)} className="p-mr-2 p-button-danger" onClick={onHide} />
          <Button label={t(`OffIssueOrdersDialog.cancel`, `Cancel`)} className="p-button-text" onClick={onHide} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};
