import React from 'react';
import { ACLOutDTO, VisibilityEnum } from '@api/logsteo-api.v2';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import Badge, { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';

interface ComponentProps {
  acl: ACLOutDTO;
}

const VisibilityBadge: React.FC<ComponentProps> = ({ acl }) => {
  const { tr } = useTranslation();

  const renderBadge = () => {
    switch (acl.visibility) {
      case VisibilityEnum.PUBLIC:
        return <Badge text={tr('VisibilityBadge.visibleToAll', 'Visible to all')} />;
      case VisibilityEnum.PRIVATE:
        return <Badge text={tr('VisibilityBadge.private', 'Private')} icon={<span className={'pi pi-lock'}></span>} />;
      case VisibilityEnum.VISIBLE_TO_COMPANIES:
        return (
          <Badge
            text={tr('VisibilityBadge.visibilityToSelected', 'Visibility to selected')}
            icon={<span className={'pi pi-unlock'}></span>}
            hoverComponent={
              <div>
                <div>{tr('VisibilityBadge.visibilityToSelected2', 'Visibility to selected:')}</div>
                {acl.visibleToCompanies.map(company => company.name).join('\n ')}
              </div>
            }
          />
        );
    }
  };
  return (
    <>
      <BadgeRow>{renderBadge()}</BadgeRow>
    </>
  );
};

export default VisibilityBadge;
