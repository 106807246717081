import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import LocalityList from '@app/pages/customer/LocalityList/LocalityList.tsx';

interface ComponentProps {}

const LocalityListPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslation();
  return (
    <>
      <Layout title={tr(`index.localityList`, `Locality list`)}>
        <LocalityList />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(LocalityListPage);
