import React from 'react';
import Badge from './Badge';
import { mapFromAPIDateTime } from '@utils/date';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { FiEdit } from 'react-icons/fi';

interface ComponentProps {
  createdAt: string;
  createdBy: string;
  createdEmail: string;
}

const CreatedBadge: React.FC<ComponentProps> = ({ createdAt, createdBy, createdEmail }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <a href={`mailto:${createdEmail}`}>
        <Badge
          text={tr('CreatedBadge.created', '{{fullName}} ({{dateWithTimeDash}})', {
            fullName: createdBy,
            dateWithTimeDash: mapFromAPIDateTime(createdAt).format('DD.MM.YYYY - HH:mm'),
          })}
          icon={<FiEdit />}
        />
      </a>
    </>
  );
};

export default CreatedBadge;
