import { useEffect, useRef } from 'react';

export const useVisibilityAction = <T extends Element>(callback: () => void, delay: number, enable: boolean): React.RefObject<T> => {
  const elementRef = useRef<T>(null);
  const timeoutRef = useRef<number | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const hasActionBeenCalledRef = useRef<boolean>(false);

  useEffect(() => {
    if (!enable) {
      // Pokud není povoleno, odpojíme pozorovatele a vrátíme se
      if (observerRef.current && elementRef.current) {
        observerRef.current.unobserve(elementRef.current);
      }
      return;
    }

    const handleVisibilityChange = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting && !hasActionBeenCalledRef.current) {
        // Element je viditelný a akce ještě nebyla spuštěna
        timeoutRef.current = window.setTimeout(() => {
          callback();
          hasActionBeenCalledRef.current = true;
          // Odpojíme pozorovatele, pokud ho již nepotřebujeme
          if (observerRef.current && elementRef.current) {
            observerRef.current.unobserve(elementRef.current);
          }
        }, delay);
      } else {
        // Element není viditelný nebo akce již byla spuštěna
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
      }
    };

    observerRef.current = new IntersectionObserver(handleVisibilityChange);

    if (elementRef.current) {
      observerRef.current.observe(elementRef.current);
    }

    return () => {
      if (observerRef.current && elementRef.current) {
        observerRef.current.unobserve(elementRef.current);
      }
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [callback, delay, enable]);

  return elementRef;
};
