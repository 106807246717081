import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Layout from '@app/layout/Layout.tsx';
import { CompanySettings } from '@components/obsolete/settings/CompanySettings.tsx';

interface ComponentProps {}

const CompanySettingsPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <Layout title={tr('CompanySettingsPage.companySettings', 'Company settings')}>
      <CompanySettings />
    </Layout>
  );
};

export default withAuthenticationRequired(CompanySettingsPage);
