import { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface ComponentProps {
  className?: any;
  style?: any;
}

const PanelCard: React.FC<PropsWithChildren<ComponentProps>> = ({ children, className }) => {
  const classes = clsx(className);
  return <div className={'p-grid p-d-flex p-jc-between p-ai-center ' + classes}>{children}</div>;
};

export default PanelCard;
