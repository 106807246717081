import React from 'react';
import { Card } from 'primereact/card';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import ExpeditionDetailPage from '@app/pages/carrier/expedition-detail/ExpeditionDetailPage.tsx';

interface ComponentProps {}

const CarrierExpeditionDetailPage: React.FC<ComponentProps> = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`ShipmentDetail.demandsDetail`, `Demands detail`)}>
      <ExpeditionDetailPage />
    </Layout>
  );
};

export default withAuthenticationRequired(CarrierExpeditionDetailPage);
