import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgCustomer from '@components/ringil3/icons/Customer';

interface ComponentProps {
  customerName: string;
}

const CustomerNameTag: React.FC<ComponentProps> = ({ customerName }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonTag
        label={tr(`CustomerNameTag.title`, `Customer`)}
        labelNote={customerName}
        icon={<SvgCustomer fill={'var(--content-secondary)'} />}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
};

export default CustomerNameTag;
