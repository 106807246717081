import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined } from '@utils/utils';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgCargotype from '@components/ringil3/icons/Cargotype';

interface ComponentProps {
  cargoTypeCode?: string;
}

const CargoTypeTag: React.FC<ComponentProps> = ({ cargoTypeCode }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      {isNotNullOrUndefined(cargoTypeCode) && (
        <ButtonTag
          label={tr(`CargoTypeTag.cargo`, `Naklad`)}
          labelNote={tr(`global.cargoTypeCode.${cargoTypeCode}`, `global.cargoTypeCode.${cargoTypeCode}`)}
          icon={<SvgCargotype />}
          setOpen={setOpen}
          open={open}
        />
      )}
    </>
  );
};

export default CargoTypeTag;
