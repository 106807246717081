import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tag/ButtonTag.tsx';
import { InputText } from 'primereact/inputtext';
import SvgETransportTruck from '@components/features/eTransport/icons/ETransportTruck.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { Input } from 'postcss';
import InputCountryPlain from '@components/obsolete/Form/InputCountry/InputCountryPlain.tsx';
import { dumpVars, formatLocalDate, isBlank, isNullOrUndefined, isValidString } from '@utils/utils.tsx';
import { ETransportCompanyDTO } from '@api/logsteo-api.v2.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import { ValidationError } from 'yup';
import { findValidationMessage, hasValidationProblemForPrefix } from '@utils/validation.tsx';
import SvgIconFile from '@components/framework/icons/IconFile.tsx';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';

interface ComponentProps {
  value: GoodsInvoiceDTO;
  onChange: (value: GoodsInvoiceDTO) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

export interface GoodsInvoiceDTO {
  goodsInvoiceNumber: string;
  goodsInvoiceDate: string;
}

const InternalInfoGoodsInvoiceTag: React.FC<ComponentProps> = ({ value, onChange, readonly, validationErrors, validationPrefix }) => {
  const [internalValue, setInternalValue] = useState<GoodsInvoiceDTO>(value);
  const { tr } = useTranslationLgs();

  const printValue = (value: GoodsInvoiceDTO) => {
    if (isNullOrUndefined(value?.goodsInvoiceNumber)) return null;

    return <MultipleValues values={[value.goodsInvoiceNumber, formatLocalDate(value.goodsInvoiceDate)]}></MultipleValues>;
  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  const cleanup = (value: GoodsInvoiceDTO) => {
    if (isNullOrUndefined(value)) return null;
    if (!isValidString(value.goodsInvoiceNumber) && isNullOrUndefined(value.goodsInvoiceDate)) return null;
    return value;
  };

  return (
    <>
      <ButtonTag
        label={'Zbožová faktura'}
        icon={<SvgIconFile />}
        required={false}
        value={printValue(value)}
        readonly={readonly}
        errorMessage={hasError ? tr(`ETransportTransportCompany.validationError`, `Validation error`) : undefined}
        onSave={onSuccess => {
          onChange(cleanup(internalValue));
          onSuccess();
        }}
        popupWidth={'350px'}>
        <>
          <Field label={'Číslo zbožové faktury'} errorMessage={findValidationMessage(validationErrors, validationPrefix + '.goodsInvoiceNumber', tr)}>
            <InputText value={internalValue?.goodsInvoiceNumber} onChange={e => setInternalValue({ ...internalValue, goodsInvoiceNumber: e.target.value })} />
          </Field>
          <Field label={'Datum zadání zbožové faktury'} errorMessage={findValidationMessage(validationErrors, validationPrefix + '.goodsInvoiceDate', tr)}>
            <RingilCalendar value={internalValue?.goodsInvoiceDate} onChange={v => setInternalValue({ ...internalValue, goodsInvoiceDate: v })} fullWidth />
          </Field>
        </>
      </ButtonTag>
    </>
  );
};

export default InternalInfoGoodsInvoiceTag;
