import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import AllCompanies from '@components/pages/admin/companies/AllCompanies';

const AdminAllCompanies = () => {
  return (
    <AdminLayout title={'Carriers'}>
      <AllCompanies />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(AdminAllCompanies);
