import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ExpeditionStatusEnum } from '@api/logsteo-api.v2';
import Tag from '@components/ringil3/Tags/Tag';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgStateinfo from '@components/ringil3/icons/Stateinfo';

interface ComponentProps {
  expeditionState: ExpeditionStatusEnum;
}

const StateTag: React.FC<ComponentProps> = ({ expeditionState }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonTag label={tr(`StateTag.state`, `Stav`)} labelNote={tr(expeditionState, expeditionState)} icon={<SvgStateinfo />} setOpen={setOpen} open={open} />
    </>
  );
};

export default StateTag;
