import { isNullOrUndefined } from '@utils/utils';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  bookingRef?: string;
  name?: string;
  phone?: string;
  email?: string;
  children?: any;
  totalWeight?: string;
  className?: any;
}

const LoadInfoItem: React.FC<ComponentProps> = ({ bookingRef, name, phone, email, className, totalWeight }) => {
  const classes = clsx(className);

  const { t }: { t: any } = useTranslation('common');

  return (
    <div className={'load-info-item ' + classes}>
      <div>
        {bookingRef && (
          <div className="load-info__number">
            {t(`LoadInfoItem.bookingRef`, `Objednávka {{bookingRef}}`, {
              bookingRef,
            })}
          </div>
        )}
        {!isNullOrUndefined(totalWeight) && (
          <div className="load-info__number">
            {t(`LoadInfoItem.weight`, `Hmotnost {{totalWeight}}`, {
              totalWeight,
            })}
          </div>
        )}
        {name && <div className="load-info__name p-mt-2">{name ? name : '-'}</div>}
        {phone && (
          <div className="load-info__phone p-mt-2 text-blue">
            <a href={'tel:' + phone}>{phone ? phone : '-'}</a>
          </div>
        )}
        {email && (
          <div className="load-info__email p-mt-2 text-blue">
            <a href={'mailto:' + email}>{email ? email : '-'}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadInfoItem;
