import React, { PropsWithChildren, useId } from 'react';
// @ts-ignore
import { ValidationError } from 'yup';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface ComponentProps {
  label?: string | object;
  className?: string;
  InputGroup?: boolean;
  classNameInput?: string;
  classNameWrapper?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  value?: any;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => unknown;
  required?: boolean;
  descriptionOnClick?: () => unknown;
  errors?: ValidationError[];
  validationPath?: string;
  mediumPadding?: number;
  largePadding?: number;
}

const InputWrapper: React.FC<PropsWithChildren<ComponentProps>> = ({
  className,
  label,
  required,
  description,
  InputGroup,
  errors = [],
  classNameWrapper,
  children,
  validationPath = '',
  mediumPadding = 8,
  largePadding = 4,
}) => {
  const { t } = useTranslation('common');

  const classesWrapper = clsx(classNameWrapper);

  const id = useId().replace(/:/g, '');

  const error = errors.find(e => e.path === validationPath);

  return (
    <div className={classesWrapper ? 'p-field ' + classesWrapper : `p-field p-col-12 p-md-${mediumPadding} p-lg-${largePadding}`}>
      {label && (
        <label className="inputLabel p-d-block" htmlFor={id}>
          <strong>{`${label}${required ? '*' : ''}`}</strong>
        </label>
      )}

      {children}
      {InputGroup && (
        <span className="p-inputgroup-addon">
          <i className="pi pi-user"></i>
        </span>
      )}

      {description && (
        <small className="p-d-block">
          {description}
          <span className="text-blue" style={{ marginLeft: '.5rem' }}>
            {t('wayPage.form.labelEnterManuallyAddresses')}
          </span>
        </small>
      )}

      {error && (
        <small className="p-invalid p-d-block validationErrorMessage" style={{ color: 'red' }}>
          {t(`validation.${error.type}`)}
        </small>
      )}
    </div>
  );
};

export default InputWrapper;
