import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { CompanyLocationsListPreviewOutDTO } from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AddEditLocationSidebar from './sidebars/AddEditLocationSidebar';
import VisibilityBadge from './component/VisibilityBadge';
import { Checkbox } from 'primereact/checkbox';
import { useCreateLocationACLSidebar } from './sidebars/LocationACLSidebar';
import useNavigation from '@hooks/useNavigation.tsx';
import useMassAction from '@hooks/useMassAction/useMassAction.tsx';
import { Col, Heading, HeadingRow, WithGap } from '@components/styles.tsx';
import { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { InputSwitch } from 'primereact/inputswitch';
import COText from '@app/pages/COText/COText.tsx';

interface ComponentProps {}

interface Filter {
  locationName?: string;
  showOnlyWithRamp?: boolean;
}

const LocalityList: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { getCompanyLocations } = useContext(ApiContext);
  const [locations, setLocations] = useState<CompanyLocationsListPreviewOutDTO[]>([]);
  const [addLocality, setAddLocality] = useState(false);
  const navigation = useNavigation();

  const { Filter, applyFilterValue, filter, applySorting, sort } = useFilter<Filter>(
    (page, filter) => {
      load();
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    getCompanyLocations(filter?.locationName, filter?.showOnlyWithRamp, d => {
      setLocations(d);
    });
  };

  const toDetail = (id: string) => {
    navigation.navigate(navigation.urlFunctions.createLocalityDetail(id));
  };

  const { setVisible: setVisibleACLSidebar, LocationACLSidebar } = useCreateLocationACLSidebar();

  const { MassActionCheckboxAndState, toggleCheckbox, setSelectedObjects, isSelected, selectedObjects, clear } = useMassAction<CompanyLocationsListPreviewOutDTO>(
    (a1, a2) => a1.locationId === a2.locationId,
    selectedObjects => (
      <>
        <Button
          label={tr(`LocalityList.setVisibility`, `Set visibility`)}
          onClick={e => {
            setVisibleACLSidebar(true);
          }}
          disabled={selectedObjects.length === 0}
        />
      </>
    ),
  );

  return (
    <>
      <BreadCrumbComponent items={[{ label: tr(`index.localityList`, `Locality list`) }]} />
      <HeadingRow>
        <Heading>{tr(`LocalityList.localities`, `Localities`)}</Heading>
        <Button className="p-button-rounded" icon="pi pi-plus" label={tr(`LocalityList.addLocation`, `Add location`)} onClick={e => setAddLocality(true)}></Button>
      </HeadingRow>
      <div className={'inline-flex flex-row gap-4 items-center'}>
        <RingilInputText
          onChange={v => {
            applyFilterValue('locationName', v);
          }}
          value={filter?.locationName}
        />
        <div className={'flex flex-row gap-4 items-center'}>
          <InputSwitch checked={filter?.showOnlyWithRamp} onChange={e => applyFilterValue('showOnlyWithRamp', e.value)} />
          <COText label={'Jen s rampou'} className={'text-nowrap'} />
        </div>
      </div>
      {locations && (
        <>
          <MassActionCheckboxAndState
            onSelectAll={() => {
              setSelectedObjects(locations);
            }}
          />
          {/*
        // @ts-ignore*/}
          <DataTable value={locations}>
            <Column
              header={''}
              body={(row: CompanyLocationsListPreviewOutDTO) => (
                <Checkbox
                  onChange={e => {
                    toggleCheckbox(row);
                  }}
                  checked={isSelected(row)}
                />
              )}
              className="shipments-table__checkbox text-blue"></Column>
            <Column
              header={tr(`LocalityList.name`, `Name`)}
              body={(row: CompanyLocationsListPreviewOutDTO) => (
                <Col>
                  <span>{row.name}</span>
                  <BadgeRow>
                    <VisibilityBadge acl={row.acl} />
                  </BadgeRow>
                </Col>
              )}></Column>
            <Column header={tr(`LocalityList.contactName`, `Contact name`)} body={(row: CompanyLocationsListPreviewOutDTO) => <div>{row.contactName}</div>}></Column>
            <Column
              header={tr(`LocalityList.address`, `Address`)}
              body={(row: CompanyLocationsListPreviewOutDTO) => (
                <div>
                  {row.streetNr}, {row.postalCode} {row.city}, {row.country}
                </div>
              )}></Column>
            <Column
              header={tr(`LocalityList.action`, `Action`)}
              body={(row: CompanyLocationsListPreviewOutDTO) => (
                <WithGap>
                  <RingilButton label={tr('LocalityList.detail', 'Detail')} onClick={() => toDetail(row.locationId)} />
                  {/*<MoreButtonLocation
                    onChange={() => {
                      load();
                    }}
                    locationId={row.locationId}
                  />*/}
                </WithGap>
              )}></Column>
          </DataTable>
        </>
      )}
      <AddEditLocationSidebar
        visible={addLocality}
        onHide={() => {
          setAddLocality(false);
        }}
        onCompleted={() => {
          load();
          setAddLocality(false);
        }}
      />
      <LocationACLSidebar
        onCompleted={() => {
          load();
        }}
        locationIds={selectedObjects.map(t => t.locationId)}
      />
    </>
  );
};

export default LocalityList;
