import React from 'react';
import styled, { css } from 'styled-components';
import RingilSpinner from '@components/ringil3/RingilSpinner/RingilSpinner';
import { RowWithGap } from '@components/styles.tsx';

interface ComponentProps {
  label: string | JSX.Element;
  onClick: () => void;
  mode?: 'outlined' | 'filled' | 'text-only' | 'table';
  size?: 'big' | 'small';
  iconPath?: string;
  loading?: boolean;
  disabled?: boolean;
}

const RingilButton: React.FC<ComponentProps> = ({ label, onClick, iconPath, mode = 'outlined', size = 'small', loading = false, disabled = false }) => {
  return (
    <>
      <ButtonInner onClick={e => (loading || disabled ? {} : onClick())} mode={mode} size={size} loading={loading ? 'true' : 'false'} disabled={disabled}>
        <RowWithGap>
          <RingilSpinner isSpinning={loading} />
          {iconPath && <img src={iconPath} />}
          <div>{label}</div>
        </RowWithGap>
      </ButtonInner>
    </>
  );
};

const ButtonInner = styled.button<{
  loading?: string;
  mode?: 'outlined' | 'filled' | 'text-only' | 'table';
  size?: 'big' | 'small';
  disabled: boolean;
}>`
  border-radius: 48px;

  font-size: 1rem;
  color: var(--ringil3-brand-color);
  border: 2px solid var(--ringil3-brand-color);
  cursor: pointer;
  padding: 0.7rem 1.5rem;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${props =>
    props.size === 'small' &&
    css`
      padding: 0.5rem 2rem;
    `}

  ${props =>
    props.mode === 'filled' &&
    css`
      color: white;
      background-color: var(--ringil3-brand-color);
      ${props.loading === 'true' &&
      css`
        background-color: var(--ringil3-content-tertiary-color);
        border: 2px solid var(--ringil3-content-tertiary-color);
        cursor: auto;
      `}
    `}

    ${props =>
    props.mode === 'outlined' &&
    css`
      color: var(--ringil3-brand-color);
      background-color: white;
      ${props.loading === 'true' &&
      css`
        border: 2px solid var(--ringil3-content-tertiary-color);
        cursor: auto;
      `}
    `}

    ${props =>
    props.mode === 'text-only' &&
    css`
      color: var(--ringil3-brand-color);
      border-radius: 0px;
      background: none;
      border: none;
    `}

    ${props =>
    props.mode === 'table' &&
    css`
      padding: 10px 8px;
      color: var(--ringil3-secondary-color);
      background: #ffffff;
      border: 1px solid var(--ringil3-gr2);
      box-shadow: 0px 4px 4px rgba(254, 80, 0, 0.15);
      border-radius: 4px;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    `}
`;
export default RingilButton;
