import { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface ComponentProps {
  className?: any;
}
const CheckboxItem: React.FC<PropsWithChildren<ComponentProps>> = ({ children, className }) => {
  const classes = clsx(className);

  return <>{className ? <div className={'checkbox-item ' + classes}> {children}</div> : <div className="checkbox-view p-md-4"> {children}</div>}</>;
};

export default CheckboxItem;
