import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import React from 'react';
import AllPartnersList from '@app/pages/customer/AllPartnersList/AllPartnersList.tsx';

const RoutesListPage = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`partners.TemplatesList`, `Partneři`)}>
      {/*
        // @ts-ignore*/}
      <Card>
        <AllPartnersList />
      </Card>
    </Layout>
  );
};
export default withAuthenticationRequired(RoutesListPage);
