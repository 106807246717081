import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useContext, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import useAdditionalCosts from '@hooks/additional-costs/additional-costs';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import { useImmer } from 'use-immer';
import ValidationDiv from '@utils/validation';
import InputYesNoPlain from '../Form/InputYesNoPlain/InputYesNoPlain';
import { ApiContext } from '@api/api.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: () => void;
  applicationId: string;
  customerId: string;
  currency: string;
}

interface ComponentData {
  selectedCategory?: string;
  description?: string;
  price?: number;
  currency?: string;
  internal: boolean;
}

export const AdditionalCostsSidebar: React.FC<ComponentProps> = ({ visible, onHide, onComplete, applicationId, customerId, currency }) => {
  const { tr } = useTranslationLgs();
  const { cuCreateExtraCostForExpedition } = useContext(ApiContext);

  const categoriesAdditionalCosts = useAdditionalCosts();
  const [state, setState] = useImmer<ComponentData>({
    currency,
    internal: false,
  });

  const validationSchema = yup.object().shape({
    selectedCategory: yup.string().min(1).required(),
    description: yup.string().min(1).required(),
    price: yup.number().required(),
  });

  const { clear, validationErrors, validateAndSend, find } = useValidation(validationSchema, () => {
    cuCreateExtraCostForExpedition(
      customerId,
      applicationId,
      {
        currency: currency,
        description: state.description,
        price: state.price,
        typeEnum: state.selectedCategory,
        internal: state.internal,
      },
      () => {
        onComplete();
      },
    );
  });

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} onHide={onHide} position="right" baseZIndex={1000000} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        <h3 className="p-mb-4">{tr(`AdditionalCostsSidebar.addExtraCosts`, `Přidat informaci o vícenákladech`)}</h3>
        <div className="p-field p-fluid">
          <Label title={tr(`AdditionalCostsSidebar.extraCostCategory`, `Kategorie vícenákladů`)} />
          <Dropdown
            value={state.selectedCategory}
            options={categoriesAdditionalCosts}
            onChange={e => {
              const { value } = e.target as HTMLInputElement;
              setState(draft => {
                draft.selectedCategory = value;
              });
            }}
            optionLabel="name"
            optionValue="code"
            placeholder={tr(`AdditionalCostsSidebar.chooseCategory`, `Vyberte kategorii`)}
          />
          <ValidationDiv errors={validationErrors} path={'selectedCategory'} />
        </div>
        <div className="p-field p-fluid">
          <Label title={tr(`AdditionalCostsSidebar.description`, `Popis vícenákladů`)} />
          <InputTextarea
            value={state.description}
            onChange={e => {
              setState(draft => {
                draft.description = e.target.value;
              });
            }}
            rows={5}
            autoResize
          />
          <ValidationDiv errors={validationErrors} path={'description'} />
        </div>
        <div className="p-field">
          <Label title={tr(`AdditionalCostsSidebar.internal`, `Internal`)} />
          <div className="p-inputgroup">
            <InputYesNoPlain
              value={state.internal}
              onChange={v =>
                setState(draft => {
                  if (v == null) return;
                  draft.internal = v;
                })
              }
            />
          </div>
        </div>
        <div className="p-field">
          <Label title={tr(`AdditionalCostsSidebar.price`, `Cena vícenákladů`)} />
          <div className="p-inputgroup">
            <InputPriceWithCurrency
              price={state.price}
              currency={state.currency}
              disabled={false}
              disabledCurrency={true}
              changePrice={v => {
                setState(draft => {
                  draft.price = v;
                });
              }}
              changeCurrency={v => {}}
            />
          </div>
          <ValidationDiv errors={validationErrors} path={'price'} />
        </div>

        <ButtonLayout>
          <Button label={tr(`AdditionalCostsSidebar.save`, `Uložit`)} className="p-mr-2" onClick={e => validateAndSend(state)} />
          <Button label={tr(`AdditionalCostsSidebar.cancel`, `Zrušit`)} className="p-button-text" onClick={onHide} />
        </ButtonLayout>
        <div className="alert-user">
          <small>
            <i>
              {tr(
                `AdditionalCostsSidebar.note`,
                `Změna v expedici by měla být před uložením domluvena s dopravcem. Dopravce bude o přidané změně pouze
            informován e-mailem.`,
              )}
            </i>
          </small>
        </div>
      </Sidebar>
    </>
  );
};
