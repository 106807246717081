import React from 'react';
import styled from 'styled-components';

interface ComponentProps {}

const NavigationBreadCrumb: React.FC<ComponentProps> = () => {
  return (
    <>
      <PageBreadCrumbInner>
        <img src={'/images/icons/ringil3/breadcrumb/navigation.svg'} width={14} height={15} />
        <PageBreadCrumbClickable>Konec trasy</PageBreadCrumbClickable>
        <img src={'/images/icons/ringil3/breadcrumb_divider.svg'} width={12} height={12} />
        Zacatek trasy
      </PageBreadCrumbInner>
    </>
  );
};

const PageBreadCrumbInner = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const PageBreadCrumbClickable = styled.div`
  color: var(--ringil3-brand-color);
`;

export default NavigationBreadCrumb;
