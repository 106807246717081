import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CompanyLocationRampWithOpeningHoursOutDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  value: string[];
  onChange: (value: string[]) => void;
  style?: any;
  locationId: string;
}

const InputCompanyRamps: React.FC<ComponentProps> = ({ value, onChange, style, locationId }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { getRampsForCompanyLocation } = useContext(ApiContext);
  const [data, setData] = useState<CompanyLocationRampWithOpeningHoursOutDTO[]>([]);

  useEffect(() => {
    getRampsForCompanyLocation(locationId, d => setData(d));
    /*onChange([]);*/
  }, [locationId]);

  return (
    <MultiSelect
      style={style}
      options={data}
      placeholder={tr(`InputCompanyRamps.allRamps`, `All ramps`)}
      optionLabel={'name'}
      optionValue={'rampId'}
      onChange={e => onChange(e.value)}
      value={value}
      filter={true}
    />
  );
};

export default InputCompanyRamps;
