import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Clickable } from '@components/ringil3/styles';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  onClose: () => void;
  headingText: string;
  closeIcon: JSX.Element;
}

const Panel: React.FC<PropsWithChildren<ComponentProps>> = ({ children, onClose, closeIcon, headingText }) => {
  return (
    <PanelInner>
      {headingText && (
        <PanelHeader>
          <COHeadline variant={'h4'} title={headingText}></COHeadline>
          <Clickable onClick={e => (onClose ? onClose() : {})}>{closeIcon}</Clickable>
        </PanelHeader>
      )}

      <PanelContent>{children}</PanelContent>
    </PanelInner>
  );
};

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PanelInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--base-basesecondary);
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
`;

const PanelContent = styled.div`
  display: flex;
`;

export default Panel;
