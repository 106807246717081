import React, { useContext, useEffect, useState } from 'react';
import { TruckDetailPlanOutDTO, TruckTypeWithoutIdDTO } from '@api/logsteo-api.v2';
import { returnTruckDimensions, returnTruckLoadCapacity, returnTruckName } from '@components/obsolete/customer/new-expedition/helpers';
import { ApiContext } from '@api/api';
import CardView from '@components/obsolete/new-expedition/CardView/CardView.tsx';

interface SelectedTruckTypeProps {
  selectedTruckId?: string;
  selectedTruck?: TruckDetailPlanOutDTO;
}

const SelectedTruckType: React.FC<SelectedTruckTypeProps> = ({ selectedTruckId, selectedTruck }) => {
  const [trucks, setTrucks] = useState<TruckTypeWithoutIdDTO[]>([]);
  const { lovTruckTypes } = useContext(ApiContext);

  useEffect(() => {
    lovTruckTypes(data => setTrucks(data));
  }, []);

  return (
    <>
      <>
        {trucks?.length > 0 && selectedTruckId && (
          <CardView
            title={returnTruckName(selectedTruckId, trucks)}
            option={returnTruckLoadCapacity(selectedTruckId, trucks)}
            value={returnTruckDimensions(selectedTruckId, trucks)}
          />
        )}
        {selectedTruck && (
          <CardView
            title={selectedTruck.name}
            option={`${selectedTruck.loadingCapacity || '-'}${selectedTruck.loadingUnit}`}
            value={`${selectedTruck.height || '-'} x ${selectedTruck.width || '-'} x ${selectedTruck.length || '-'} cm`}
          />
        )}
      </>
    </>
  );
};

export default SelectedTruckType;
