import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Layout from '@app/layout/Layout.tsx';
import { useParams, useSearchParams } from 'react-router-dom';
import SupplierNotificationDetail from '@app/pages/customer/supplier-notification/SupplierNotificationDetail.tsx';
import { SupplierNotificationTypeEnum } from '@api/logsteo-api.v2.tsx';
import SupplierNotificationDetailDap from '@app/pages/dap/SupplierNotificationDetailDap.tsx';

interface ComponentProps {}

const SupplierReservationDetailPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const { supplierNotificationId } = useParams();
  const [searchParams] = useSearchParams();

  const snType = searchParams.get('snType');
  const typ: SupplierNotificationTypeEnum = (snType as SupplierNotificationTypeEnum) || SupplierNotificationTypeEnum.FCA;

  return (
    <>
      <Layout title={tr(`SupplierReservationDetailPage.title`, `Supplier notification detail`)}>
        {typ === SupplierNotificationTypeEnum.FCA && <SupplierNotificationDetail supplierNotificationId={supplierNotificationId} />}
        {typ === SupplierNotificationTypeEnum.DAP && <SupplierNotificationDetailDap supplierNotificationId={supplierNotificationId} />}
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(SupplierReservationDetailPage);
