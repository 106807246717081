import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { isNullOrUndefined } from '@utils/utils';
import { LovWithoutIdDTO, WeightModeTypeOrderEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  value: InputWeightWithDefaultModeForm;
  summedValue: number;
  onChange: (v: InputWeightWithDefaultModeForm) => void;
  disabled?: boolean;
}

export interface InputWeightWithDefaultModeForm {
  value: number;
  weightModeTypeOrder: WeightModeTypeOrderEnum;
}

const InputWeightWithDefault: React.FC<ComponentProps> = ({ value, summedValue, onChange, disabled = false }) => {
  const isSummed = isNullOrUndefined(value) || value.weightModeTypeOrder === WeightModeTypeOrderEnum.SUMMED;

  const { lovWeightModesForOrder } = useContext(ApiContext);
  const [weightModes, setWeightModes] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovWeightModesForOrder(d => {
      setWeightModes(d);
      if (isNullOrUndefined(value)) {
        onChange({
          weightModeTypeOrder: WeightModeTypeOrderEnum.SUMMED,
          value: null,
        });
      }
    });
  }, []);

  if (isNullOrUndefined(value)) return <></>;

  return (
    <>
      <Dropdown
        options={weightModes}
        optionLabel={'name'}
        optionValue={'code'}
        disabled={disabled}
        value={value.weightModeTypeOrder}
        onChange={v => {
          onChange({ ...value, weightModeTypeOrder: v.value });
        }}
      />
      {!isSummed && (
        <InputNumber
          value={value?.value}
          minFractionDigits={0}
          maxFractionDigits={2}
          locale={'cs'}
          disabled={disabled}
          onChange={v => {
            onChange({ ...value, value: v.value });
          }}
        />
      )}
      {isSummed && <InputNumber minFractionDigits={0} maxFractionDigits={2} locale={'cs'} value={summedValue} required={true} readOnly={true} disabled={disabled} />}
    </>
  );
};

export default InputWeightWithDefault;
