import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { DayEnum } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined } from '@utils/utils';
import useForm from '@hooks/useForm/useForm';
import { Dropdown } from 'primereact/dropdown';
import { formatTimeInMinutesToHourString } from '@utils/date';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputHour from '@components/obsolete/Form/InputHour/InputHour.tsx';

interface ComponentProps {
  generateForDay: DayEnum;
  onGenerateSlots: (slots: GeneratedSlot[]) => void;
  onHide: () => void;
}

interface Form {
  from: string;
  to: string;
  slotDuration: number;
}

export interface GeneratedSlot {
  sinceHourString: string;
  tillHourString: string;
}

const GenerateDialog: React.FC<ComponentProps> = ({ generateForDay, onHide, onGenerateSlots }) => {
  const { tr } = useTranslation();

  const form = useForm<Form>(yup.object(), { from: '', to: '', slotDuration: 30 }, d => save(d), false, false);

  const save = (values: Form) => {
    const duration = hourStringToMinutes(form.values.to) - hourStringToMinutes(form.values.from);
    const slotsCount = Math.floor(duration / values.slotDuration);
    let current = hourStringToMinutes(form.values.from);
    const end = hourStringToMinutes(form.values.to);
    let slots: GeneratedSlot[] = [];
    while (current + form.values.slotDuration <= end) {
      const slot = {
        sinceHourString: formatTimeInMinutesToHourString(current),
        tillHourString: formatTimeInMinutesToHourString(current + form.values.slotDuration),
      } as GeneratedSlot;
      slots.push(slot);
      current = current + form.values.slotDuration;
    }
    return onGenerateSlots(slots);
  };

  const hourStringToMinutes = (hourString: string): number => {
    const parts = hourString.split(':');
    const hour = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);
    const timeInMinutes = hour * 60 + minutes > 0 ? (hour * 60 + minutes) % (1 + 24 * 60) : 0;
    const sanited = timeInMinutes % (1 + 24 * 60);
    return sanited;
  };

  return (
    <RingilDialog
      headerText={tr(`GenereateDialog.generateOpeningHours`, `Generate opening hours`)}
      visible={isNotNullOrUndefined(generateForDay)}
      onHide={() => onHide()}
      onSave={() => form.validateAndSend()}
    >
      <TwoCols>
        <HorizontalFieldValue
          label={tr(`GenereateDialog.generateFrom`, `Generate from`)}
          value={<InputHour value={form.values.from} onChange={v => form.setFieldValue(form.names.from, v)} />}
        />
        <HorizontalFieldValue
          label={tr(`GenereateDialog.generateTo`, `Generate to`)}
          value={<InputHour value={form.values.to} onChange={v => form.setFieldValue(form.names.to, v)} />}
        />
      </TwoCols>
      <HorizontalFieldValue
        label={tr(`GenereateDialog.slotDurationMin`, `Slot duration [min]`)}
        value={<Dropdown value={form.values.slotDuration} options={[15, 30, 60]} onChange={e => form.setFieldValue(form.names.slotDuration, e.target.value)} />}
      ></HorizontalFieldValue>
    </RingilDialog>
  );
};

const TwoCols = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

export default GenerateDialog;
