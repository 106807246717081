import { PropsWithChildren } from 'react';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  label?: string;
  buttonTitle?: string;
  imgSrc?: string;
  children?: any;
}

const ExpeditionCarrierInfo: React.FC<PropsWithChildren<ComponentProps>> = ({ label, buttonTitle, imgSrc, children }) => {
  return (
    <div className="carrier-info-item">
      <div className="panel-with-icon">
        <div className="icon-img">
          <img src={imgSrc} alt="" />
        </div>
        <div className="panel-with-icon__description">
          {label && <Label title={label} />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ExpeditionCarrierInfo;
