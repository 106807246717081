import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { InputSwitch } from 'primereact/inputswitch';
import COText from '@app/pages/COText/COText.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { sampleData } from '@app/pages/area-visitors/samedata.ts';
import TimeAndDate from '@components/framework/date/TimeAndDate.tsx';
import { mapFromAPIDateTime } from '@utils/date.tsx';
import SvgCoButtonPlus from '@components/framework/buttons/icons/CoButtonPlus.tsx';
import { Clickable } from '@components/styles.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';
import { useCreateNewEntrySidebar } from '@app/pages/area-visitors/sidebars/NewEntrySidebar.tsx';

interface ComponentProps {}

interface Filter {
  personName?: string;
  showPersonInAreal?: boolean;
}

export interface VisitorEntry {
  personName: string;
  personTypeAndId?: string;
  carPlate?: string;
  entryDateTime: string;
  exitDateTime?: string;
  note?: string;
}

const AreaVisitorList: React.FC<ComponentProps> = () => {
  //const { listAllPersonEntries } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, names } = useFilter<Filter>(
    (page, filter) => {
      //loadData(page.pageSize, page.pageNumber, filter);
    },
    null,
    {},
  );

  const { NewEntrySidebar, setVisible, visible } = useCreateNewEntrySidebar();

  const { Table, Column } = useCreateTable<VisitorEntry>();

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <BreadCrumbComponent items={[{ label: 'Návštěvy' }]} />
        <COHeadline variant={'h2'} title={'Evidence návštěv v areálu'} />

        <div className={'flex flex-col gap-4'}>
          <div className={'flex flex-row items-center  gap-4'}>
            <div className={'flex flex-row gap-4 w-2/6'}>
              <RingilInputText
                placeholder={tr('CamsSettings.spz', 'SPZ')}
                value={filter?.personName}
                onChange={v => {
                  applyFilterValue('carPlate', v);
                }}
              />
            </div>
            <div className={'flex flex-row gap-2 items-center'}>
              <InputSwitch checked={filter?.showPersonInAreal} onChange={e => applyFilterValue('showPersonInAreal', e.value)} />
              <COText label={'Zobrazit návštěvníky v areálu'} />
            </div>
          </div>
          <Table
            value={sampleData}
            paginator={true}
            bottomRow={
              <tr className={'bg-white border-b-1 border-solid'}>
                <td colSpan={1} className={'px-4 py-4'}>
                  <Clickable>Příchod</Clickable>
                </td>
                <td colSpan={4}></td>
                <td colSpan={1} className={'px-4 py-4 text-right'}>
                  <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} onClick={() => setVisible(true)} />
                </td>
              </tr>
            }>
            <Column
              header={'Jméno'}
              body={row => (
                <>
                  <div className={'font-medium'}>{row.personName}</div>
                  <div className={'text-content-secondary font-normal'}>{row.personTypeAndId}</div>
                </>
              )}
            />
            <Column header={'SPZ'} body={row => <div>{row.carPlate}</div>} />
            <Column header={'Vstup'} body={row => <TimeAndDate dateTime={mapFromAPIDateTime(row.entryDateTime)}></TimeAndDate>} />
            <Column header={'Výstup'} body={row => <TimeAndDate dateTime={mapFromAPIDateTime(row.exitDateTime)}></TimeAndDate>} />
            <Column header={'Poznámka'} body={row => <div>{row.note}</div>} />
            <Column
              header={''}
              body={row => <div className={'text-right'}>{isNullOrUndefined(row.exitDateTime) && <RingilButton label={'Odchod'} mode={'rect-small'} />}</div>}
            />
          </Table>
        </div>
      </div>
      {visible == true && <NewEntrySidebar />}
    </>
  );
};

export default AreaVisitorList;
