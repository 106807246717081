import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilDialog from '@components/framework/dialog/RingilDialog.tsx';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import COText from '@app/pages/COText/COText.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';

interface ComponentProps {
  applicationId: string;
  onConfirm: () => void;
}

export const useCreateSendDocumentEtransportRO = () => {
  const [visible, setVisible] = useState(false);

  const SendETransportToRO: React.FC<ComponentProps> = ({ applicationId, onConfirm }) => {
    const { tr } = useTranslationLgs();

    return (
      <>
        <RingilDialog
          visible={visible}
          onHide={() => {}}
          heading={tr('useCreateSendDocument.odeslatDokument', 'Odeslat dokument')}
          buttons={[
            <RingilButton
              label={tr('useCreateSendDocument.anoOdeslat', 'Ano, odeslat')}
              onClick={() => {
                onConfirm();
              }}
            />,
            <LinkWithIcon
              label={tr('useCreateSendDocument.zrusit', 'Zrušit')}
              onClick={() => {
                setVisible(false);
              }}
            />,
          ]}>
          <div className={'flex flex-col gap-4'}>
            <VerticalFieldAndValueWithIcon
              label={tr('useCreateSendDocument.proRezervaci', 'Pro rezervaci')}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />
            <HorizontalLine variant={'tertiary'} />
            <COInlineMessage variant={'info'}>
              <COText label={tr('useCreateSendDocument.poznamka', 'Document bude odeslán na rumunský úřad.')} />
            </COInlineMessage>
          </div>
        </RingilDialog>
      </>
    );
  };

  return { SendETransportToRO, visible, setVisible };
};
