import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import qs from 'qs';
import { useParams } from 'react-router-dom';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import CarrierDetail from '@app/pages/customer/carriers/CarrierDetail.tsx';

const CarrierDetailPage = () => {
  const { id, back } = useParams();
  const { tr } = useTranslationLgs();

  return (
    <>
      {id && (
        <Layout title={tr(`CarrierDetailPage.CarrierDetail`, `Detail dopravce`)}>
          <CarrierDetail carrierId={id as string} back={back} />
        </Layout>
      )}
    </>
  );
};

export default withAuthenticationRequired(CarrierDetailPage);
