import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { LovDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const InputDriver: React.FC<ComponentProps> = ({ value, onChange, placeholder }) => {
  const { lovDriverTemplates } = useContext(ApiContext);
  const [drivers, setDrivers] = useState<LovDTO[]>([]);

  useEffect(() => {
    lovDriverTemplates('', d => {
      setDrivers(d);
    });
  }, []);
  return (
    <>
      <Dropdown options={drivers} optionLabel={'name'} optionValue={'id'} value={value} onChange={e => onChange(e.value)} placeholder={placeholder} />
    </>
  );
};
