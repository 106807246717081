import React from 'react';
import { isNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  date?: string;
  time?: string;
  selected?: boolean;
  isWholeDay: boolean;
}

const TimeListItem: React.FC<ComponentProps> = ({ date, time, selected, isWholeDay }) => {
  const { t } = useTranslation('common');
  return (
    <div className="time-item">
      {!isNullOrUndefined(selected) && (
        <>
          {selected === true && <i className="p-mr-2 pi pi-check"></i>}
          {selected === false && <i className="p-mr-2 pi pi-times"></i>}
        </>
      )}

      <span className="p-mr-4">{date}</span>
      <span>{isWholeDay ? t('TimeListItem.isWholeDay') : time}</span>
    </div>
  );
};
export default TimeListItem;
