import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface ComponentProps {
  icon: string;
}

const InfoPanelWithIcon: React.FC<PropsWithChildren<ComponentProps>> = ({ icon, children }) => {
  return (
    <>
      <Grid>
        <ColWithIcon>
          <Icon src={icon} style={{ width: '20px', height: '18px' }}></Icon>
        </ColWithIcon>
        <Description>{children}</Description>
      </Grid>
    </>
  );
};

const Description = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
`;
const ColWithIcon = styled.div`
  display: flex;
`;
const Icon = styled.img``;
const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export default InfoPanelWithIcon;
