import { useContext, useEffect, useState } from 'react';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useCountries = (): LovWithoutIdDTO[] => {
  const [countries, setCountries] = useState<LovWithoutIdDTO[]>([]);
  const { lovCountry } = useContext(ApiContext); // LovDTO

  useEffect(() => {
    lovCountry(data => setCountries(data));
  }, []);

  return countries;
};

export default useCountries;
