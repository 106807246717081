import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { CarrierContactPersonDTO, CarrierSearchOutDTO } from '@api/logsteo-api.v2';
import { useImmer } from 'use-immer';
import { isNullOrUndefined } from '@utils/utils';
import * as yup from 'yup';
import { findValidationMessage } from '@utils/validation';
import useValidation from '@hooks/validation-hook/useValidation';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import InputWrapper from '@components/obsolete/Form/InputWrapper/InputWrapper.tsx';
import InputCountry from '@components/obsolete/Form/InputCountry/InputCountry.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: () => void;
}

interface FindCarrierForm {
  crn: string;
  state: string;
}

const AddNewCarrier: React.FC<ComponentProps> = ({ visible, onComplete, onHide }) => {
  const { t } = useTranslation('common');
  const { cuCreateCarrier, cuGetCarrierInfoByCrnAndState } = useContext(ApiContext);
  const [newCarrier, setNewCarrier] = useImmer<CarrierSearchOutDTO>(undefined);
  const [foundCarrier, setFoundCarrier] = useState(undefined);
  const [findCarrierForm, setFindCarrierForm] = useImmer<FindCarrierForm>({
    state: null,
    crn: null,
  });
  const [myContacts, setMyContacts] = useImmer<CarrierContactPersonDTO[]>([]);

  useEffect(() => {
    if (visible) {
      clear();
      clear2();
      setMyContacts(draft => {
        return [];
      });
      setFindCarrierForm(draft => {
        return { state: '', crn: '' };
      });
      setNewCarrier(draft => {
        return undefined;
      });
      setFoundCarrier(undefined);
    }
  }, [visible]);

  const findCarrier = () => {
    cuGetCarrierInfoByCrnAndState(findCarrierForm.crn, findCarrierForm.state, data => {
      if (data) {
        setNewCarrier(draft => {
          setFoundCarrier(true);
          return data;
        });
      } else {
        setNewCarrier(draft => {
          setFoundCarrier(false);
          return {
            name: '',
            crn: findCarrierForm.crn,
            contactPhone: '',
            contactPerson: '',
            city: '',
            carrierId: null,
            zipCode: '',
            streetNr: '',
            contactEmail: '',
            country: findCarrierForm.state,
            version: 0,
          };
        });
      }
    });
  };

  const saveCarrier = () => {
    cuCreateCarrier(
      {
        crn: newCarrier.crn,
        city: newCarrier.city,
        name: newCarrier.name,
        country: newCarrier.country,
        zipCode: newCarrier.zipCode,
        contacts:
          foundCarrier === true
            ? myContacts
            : [
                {
                  contactPerson: newCarrier.contactPerson,
                  contactEmail: newCarrier.contactEmail,
                  contactPhone: newCarrier.contactPhone,
                } as CarrierContactPersonDTO,
                ...myContacts,
              ],
        streetNr: newCarrier.streetNr,
      },
      () => {
        onComplete();
      },
      null,
      { onValidationFailed: e => setBeValidationErrors(e) },
    );
  };

  const findValidation = yup.object().shape({
    crn: yup.string().required(),
    state: yup.string().required(),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(findValidation, findCarrier);

  const myContactsSchema = yup.object().shape({
    contactPerson: yup.string().required(),
    contactEmail: yup.string().email().required(),
    contactPhone: yup.string().required(),
  });

  const createValidation = yup.object().shape({
    name: yup.string().required(),
    zipCode: yup.string().required(),
    city: yup.string().required(),
    streetNr: yup.string().required(),
    contactPerson: yup.string().required(),
    contactEmail: yup.string().email().required(),
    contactPhone: yup.string().required(),
    myContacts: yup.array().of(myContactsSchema),
  });

  const { clear: clear2, validationErrors: validationErrors2, validateAndSend: validateAndSend2 } = useValidation(createValidation, () => saveCarrier());

  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal sidebar-modal-add-new-carrier"
        style={{ width: '750px' }}>
        <h3 className="p-mb-4">{t(`AddNewCarrier.createANewCarriers`, `Vytvořit nového dopravce`)}</h3>

        <BackendValidationComponent />

        <InputLayout>
          <InputWrapper
            label={`${t('AddNewCarrier.crn', 'IČ')} *`}
            type="text"
            value={findCarrierForm.crn}
            onChange={e => {
              setFindCarrierForm(draft => {
                draft.crn = (e.target as HTMLInputElement).value;
              });
            }}
            classNameWrapper="p-col-12 p-md-6"
            error={findValidationMessage(validationErrors, 'crn', t)}
          />
          <InputCountry
            label={`${t(`CreateOrEditPartnerLocation.country`, `Stát`)} *`}
            value={findCarrierForm.state}
            onChange={e => {
              setFindCarrierForm(draft => {
                draft.state = e;
              });
            }}
            classNameWrapper="p-col-12 p-md-6"
            error={findValidationMessage(validationErrors, 'state', t)}
          />
        </InputLayout>
        <ButtonLayout wrapper="WITH_PADDING_TOP_0">
          <Button label={t(`AddNewCarrier.findCarrier`, `Vyhledat dopravce`)} className="p-mr-2" onClick={() => validateAndSend(findCarrierForm)} />
        </ButtonLayout>
        {!isNullOrUndefined(foundCarrier) && newCarrier && (
          <>
            <InputLayout>
              <InputWrapper
                label={`${t('AddNewCarrier.carrierName', 'Název dopravce')} *`}
                type="text"
                value={newCarrier.name}
                onChange={e => {
                  setNewCarrier(draft => {
                    draft.name = (e.target as HTMLInputElement).value;
                  });
                }}
                classNameWrapper="p-col-12 p-md-6"
                disabled={newCarrier.carrierId !== null}
                error={findValidationMessage(validationErrors2, 'name', t)}
              />
            </InputLayout>
            <InputLayout>
              <InputWrapper
                label={`${t('AddNewCarrier.zipCode', 'PSČ')} *`}
                type="text"
                value={newCarrier.zipCode}
                onChange={e => {
                  setNewCarrier(draft => {
                    draft.zipCode = (e.target as HTMLInputElement).value;
                  });
                }}
                disabled={newCarrier.carrierId !== null}
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors2, 'zipCode', t)}
              />
            </InputLayout>
            <InputLayout>
              <InputWrapper
                label={`${t('AddNewCarrier.city', 'Město')} *`}
                type="text"
                value={newCarrier.city}
                onChange={e => {
                  setNewCarrier(draft => {
                    draft.city = (e.target as HTMLInputElement).value;
                  });
                }}
                disabled={newCarrier.carrierId !== null}
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors2, 'city', t)}
              />
              <InputWrapper
                label={`${t('AddNewCarrier.streetNr', 'Ulice a číslo')} *`}
                type="text"
                value={newCarrier.streetNr}
                onChange={e => {
                  setNewCarrier(draft => {
                    draft.streetNr = (e.target as HTMLInputElement).value;
                  });
                }}
                disabled={newCarrier.carrierId !== null}
                classNameWrapper="p-col-12 p-md-6"
                error={findValidationMessage(validationErrors2, 'streetNr', t)}
              />
            </InputLayout>

            <InputLayout>
              <InputWrapper
                label={`${t('AddNewCarrier.mainContact', 'Kontaktní osoba')} *`}
                type="text"
                value={newCarrier.contactPerson}
                onChange={e =>
                  setNewCarrier(draft => {
                    draft.contactPerson = (e.target as HTMLInputElement).value;
                  })
                }
                disabled={foundCarrier === true}
                error={findValidationMessage(validationErrors2, 'contactPerson', t)}
              />
              <InputWrapper
                label={`${t('AddNewCarrier.mainContactPhone', 'Telefon')} *`}
                type="text"
                value={newCarrier.contactPhone}
                disabled={foundCarrier === true}
                onChange={e =>
                  setNewCarrier(draft => {
                    draft.contactPhone = (e.target as HTMLInputElement).value;
                  })
                }
                error={findValidationMessage(validationErrors2, 'contactPhone', t)}
              />
              <InputWrapper
                label={`${t('AddNewCarrier.mainContactMail', 'Email')} *`}
                type="text"
                value={newCarrier.contactEmail}
                disabled={foundCarrier === true}
                onChange={e =>
                  setNewCarrier(draft => {
                    draft.contactEmail = (e.target as HTMLInputElement).value;
                  })
                }
                error={findValidationMessage(validationErrors2, 'contactEmail', t)}
              />
            </InputLayout>

            {myContacts.map((contact, index) => {
              return (
                <React.Fragment key={index}>
                  <InputLayout>
                    <InputWrapper
                      label={t(`AddNewCarrier.contact`, `contact`)}
                      value={contact.contactPerson}
                      onChange={e =>
                        setMyContacts(draft => {
                          draft[index].contactPerson = (e.target as HTMLInputElement).value;
                        })
                      }
                      type="text"
                      error={findValidationMessage(validationErrors2, `myContacts[${index}].contactPerson`, t)}
                    />
                    <InputWrapper
                      label={t(`AddNewCarrier.contactPhone`, `contact phone`)}
                      type="text"
                      value={contact.contactPhone}
                      onChange={e =>
                        setMyContacts(draft => {
                          draft[index].contactPhone = (e.target as HTMLInputElement).value;
                        })
                      }
                      error={findValidationMessage(validationErrors2, `myContacts[${index}].contactPhone`, t)}
                    />
                    <InputWrapper
                      label={t(`AddNewCarrier.contactEmail`, `contact email`)}
                      type="text"
                      value={contact.contactEmail}
                      onChange={e =>
                        setMyContacts(draft => {
                          draft[index].contactEmail = (e.target as HTMLInputElement).value;
                        })
                      }
                      error={findValidationMessage(validationErrors2, `myContacts[${index}].contactEmail`, t)}
                    />
                  </InputLayout>
                  <div className={'p-d-flex p-jc-end'}>
                    <Button
                      icon="pi pi-trash"
                      className=" p-button-outlined"
                      onClick={() => {
                        setMyContacts(draft => {
                          return draft.filter((t, i) => i !== index);
                        });
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
            <ButtonLayout wrapper="WITH_PADDING_TOP_0">
              <Button
                label={t(`AddNewCarrier.addContact`, `Přidat kontakt`)}
                className="p-mr-2"
                onClick={() => {
                  setMyContacts(draft => {
                    draft.push({
                      contactEmail: '',
                      contactPerson: '',
                      contactPhone: '',
                    });
                  });
                }}
              />
            </ButtonLayout>

            <ButtonLayout wrapper="WITH_PADDING_TOP_4">
              <Button label={t(`AddNewCarrier.add`, `Přidat`)} className="p-mr-2" onClick={() => validateAndSend2({ ...newCarrier, myContacts })} />
              <Button label={t(`AddNewCarrier.cancel`, `Zrušit`)} className="p-button-text" onClick={() => onHide()} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default AddNewCarrier;
