import React, { useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { isNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import useExpeditionTemplates from '@hooks/expedition-templates/useTemplate.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';

interface ComponentProps {}

const NewExpeditionStep1: React.FC<ComponentProps> = () => {
  const { t }: { t: any } = useTranslation('common');
  const { state, initializeNewExpedition, selectExpeditionTemplate, initializeNewExpeditionFromTemplate } = useContext(NewExpeditionViewContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const templates = useExpeditionTemplates();

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-col-12">
          <Label title={t('labelExpeditionRoute')} />
        </div>
        <div className="p-field p-col-12 p-md-4 p-lg-4 p-xl-4">
          <Dropdown
            optionLabel="name"
            placeholder={t(`NewExpeditionStep1.chooseTemplate`, `Choose template`)}
            options={templates}
            optionValue="id"
            value={state.expeditionTemplateId}
            onChange={e => {
              selectExpeditionTemplate(e.value);
            }}
            filter={true}
            disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
          />
        </div>
        <div className="p-field p-d-flex p-flex-wrap p-ai-center p-col-12 p-md-7 p-lg-7">
          <span className="p-pr-2">{t('labelOr')}</span>
          <RingilButton
            label={t('labelCreateNewExpedition')}
            onClick={() => {
              initializeNewExpedition();
            }}
            disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
          />
        </div>
      </div>
      <ButtonLayout>
        <RingilButton
          label={t('wayPage.form.buttonContinue')}
          mode="filled"
          disabled={isNullOrUndefined(state.expeditionTemplateId) || loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
          onClick={() => initializeNewExpeditionFromTemplate()}
        />
      </ButtonLayout>
    </>
  );
};

export default NewExpeditionStep1;
