import useTranslationLgs from '@hooks/i18n/useTranslation';
import React from 'react';
import { SelectButton } from 'primereact/selectbutton';
import styled from 'styled-components';
import Label from '@components/obsolete/Form/Label/Label.tsx';

export const DelayedPublishingLabel = () => {
  const { tr } = useTranslationLgs();
  return <Label title={tr(`RequestCoverageSidebar.automaticallyPublish`, `Send to the carriers`)} />;
};

interface ComponentProps {
  changeAutomaticPublishing: (automaticPublishing: boolean) => void;
  value: boolean;
  disabled?: boolean;
}

const NowNote = () => {
  const { tr } = useTranslationLgs();
  return <Note>{tr(`index.nowNote`, `Po vytvoření oslovení dopravci expedici ihned uvidí v Logsteo a zároveň odejde notifikační e-mail.`)}</Note>;
};

const LaterNote = () => {
  const { tr } = useTranslationLgs();

  return (
    <Note>
      <p>
        {tr(
          `index.laterNote1`,
          `Po vytvoření expedici dopravci neuvidí a nebude odeslán notifikační e-mail. Zveřejnit ji můžete manuálně kdykoliv později v detailu nebo ve výpisu všech expedic.`,
        )}
      </p>
      <p>{tr(`index.laterNote2`, `Upozornění: Pokud expedici zveřejníte až po datu, kdy potřebujete cenovou nabídku, nebudou dopravci moci nabídnout cenu.`)}</p>
    </Note>
  );
};

export const DelayedPublishingInputComponent: React.FC<ComponentProps> = ({ changeAutomaticPublishing, value, disabled = false }) => {
  const { tr } = useTranslationLgs();
  const publishMenu = [
    { value: 'NOW', label: tr('CreateDistributionTemplate.now', 'Now') },
    { value: 'LATER', label: tr('CreateDistributionTemplate.alter', 'Later') },
  ];
  return (
    <>
      <SelectButton
        options={publishMenu}
        onChange={e => {
          changeAutomaticPublishing(convertAutomaticPublish(e.value));
        }}
        value={mapAutomaticallyPublishValue(value)}
        disabled={disabled}
      />
      {value ? <NowNote /> : <LaterNote />}
    </>
  );
};

export const mapAutomaticallyPublishValue = (automaticallyPublish: boolean) => {
  if (automaticallyPublish) return 'NOW';
  return 'LATER';
};

const Note = styled.div`
  margin-top: 15px;
`;

export const convertAutomaticPublish = (value: string) => (value === 'NOW' ? true : false);
