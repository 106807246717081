import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Visibility } from '@components/framework/orders/validation.ts';

interface ComponentProps {
  visibility: Visibility;
  switchComponent: ReactElement;
}

const VisibitityComponent: React.FC<PropsWithChildren<ComponentProps>> = ({ visibility, switchComponent, children }) => {
  const { tr } = useTranslationLgs();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(visibility === 'always');
  }, [visibility]);

  const switchComp = React.cloneElement(switchComponent, { onClick: () => setVisible(!visible) });
  if (visibility === 'never') return <></>;

  const isVisible = visibility === 'always' || (visibility === 'optional' && visible);
  return <>{isVisible ? children : switchComp}</>;
};

export default VisibitityComponent;
