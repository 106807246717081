import React, { useContext, useEffect } from 'react';
import { AssignedCarrierLocationOutDTO, DistributionTypeEnum, ExpeditionStatusEnum } from '@api/logsteo-api.v2';
import { dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { formatInterval, formatPrice } from '@utils/utils';
import { resolveLoadingUnloadingStateText } from '@utils/handlers';
import QuoteDataTable from '../QuoteDataTable/QuoteDataTable';
import FixedQuoteDataTable from '../QuoteDataTable/FixedQuoteDataTable';
import { ApiContext } from '@api/api';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import { CustomerExpeditionViewContext } from '@app/pages/customer/expedition-detail/CustomerExpeditionView.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import ExpeditionTimeLine from '@components/obsolete/ExpeditionTimeLine/ExpeditionTimeLine.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { AdditionalCosts } from '@components/obsolete/AdditionalCosts/AdditionalCosts.tsx';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import ExpeditionCarrierInfo from '@components/obsolete/ExpeditionCarrierInfo/ExpeditionCarrierInfo.tsx';
import { useCreateChangeVehicleInfo } from '@app/pages/carrier/expedition-detail/ChangeVehicleInfo/ChangeVehicleInfo.tsx';
import HereMaps from '@components/features/HereMap/HereMaps.tsx';

const TabCarrierActive: React.FC = () => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { state, getDetailExpeditionCarrierTabAssigned } = useContext(CustomerExpeditionViewContext);

  const { getMapDataForExpedition, getMapLastTruckPosition } = useContext(ApiContext);

  useEffect(() => {
    getDetailExpeditionCarrierTabAssigned(state.header.companyId, state.header.applicationId);
  }, [state.header.applicationId]);

  const resolveStateColor = (location: AssignedCarrierLocationOutDTO): string => {
    if (location.isComplete && location.inTime) return 'picked';
    if (location.isComplete && !location.inTime) return 'waiting-2';
    if (!location.isComplete && location.inTime) return 'waiting-1';
    if (!location.isComplete && !location.inTime) return 'waiting-2';
  };

  const { ChangeVehicleInfo, setVisible: setVisibleChangeVehicleInfo } = useCreateChangeVehicleInfo();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  return (
    <>
      {state.readyToShipment && (
        <>
          <div className="p-d-flex p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
              <NavTitle>
                <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                  {t(`TabCarrierActive.yourExpedition`, `Your expedition`)}
                </h3>
              </NavTitle>
              {state.readyToShipment.assigned.locations.map((location: any, locationIndex: number) => {
                return (
                  <ExpeditionTimeLine
                    locationName={location.name}
                    locationCity={location.city}
                    statusInfo={resolveLoadingUnloadingStateText(
                      location.isComplete,
                      location.completedAt,
                      location.loadingActionIsFinished,
                      location.loadingActionFinishedAt,
                      location.unloadingActionIsFinished,
                      location.unloadingActionFinishedAt,
                      t,
                    )}
                    status={resolveStateColor(location)}
                    key={locationIndex}>
                    <PanelWithIcon srcIcon="/images/calendar.svg">
                      <Label title={t('labelCarrierTimeslots')} />
                      <div className="time-wrapper">
                        {location.selectedTimeslot && (
                          <p>
                            <span className="p-mr-2">{dateFormatOnlyDateShort(mapFromAPIDateTime(location.selectedTimeslot.sinceInUTC))}</span>
                            {formatInterval(location.selectedTimeslot.isNotSpecifiedTime, location.selectedTimeslot.sinceInUTC, location.selectedTimeslot.tillInUTC, t)}
                          </p>
                        )}
                      </div>
                    </PanelWithIcon>
                  </ExpeditionTimeLine>
                );
              })}
              <AdditionalCosts
                applicationId={state.header.applicationId}
                companyId={state.header.companyId}
                onRemove={() => {
                  window.location.reload();
                }}
                editable={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') >= 0}
              />
              <RowWithSpaceBetween>
                <h3>{t(`TabCarrierActive.carrier`, `Carrier`)}</h3>
                <Button
                  label={tr(`TabCarrierActive.change`, `Change`)}
                  className={'p-button-outlined'}
                  onClick={e => setVisibleChangeVehicleInfo(true)}
                  disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
                />
              </RowWithSpaceBetween>
              <div className="carrier-info">
                <ExpeditionCarrierInfo label={state.readyToShipment.assigned.assignedCarrierInfo.name} imgSrc="/images/contacts.svg">
                  {/*              <Button
                label={t('expeditionOverviewPage.labelWriteMessage')}
                className="p-button-outlined"
                icon="pi pi-envelope"
                iconPos="left"
              />*/}
                </ExpeditionCarrierInfo>
                <ExpeditionCarrierInfo imgSrc="/images/local_shipping_full.svg">
                  <p>
                    <strong>{t(`TabCarrierActive.spz`, `SPZ`)}</strong> {state.readyToShipment.assigned.assignedCarrierInfo.carInfo.carPlate}
                  </p>
                  <p>
                    <strong>{t(`TabCarrierActive.driver`, `Driver`)}</strong> {state.readyToShipment.assigned.assignedCarrierInfo.carInfo.driverName}
                  </p>
                </ExpeditionCarrierInfo>
                <ExpeditionCarrierInfo imgSrc="/images/money.svg">
                  <p>{t(`TabCarrierActive.agreedPriceForShipment`, `Agreed price for shipment`)}</p>
                  <p>
                    <strong>{formatPrice(state.readyToShipment.assigned.assignedCarrierInfo.price, state.readyToShipment.assigned.assignedCarrierInfo.currency)}</strong>
                  </p>
                </ExpeditionCarrierInfo>
              </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-6">
              <Label title="Map" />
              <HereMaps customerId={state.header.companyId} applicationId={state.header.applicationId} />
              {/*<GooelMaps companyId={state.header.companyId} applicationId={state.header.applicationId} />*/}
            </div>
          </div>
          {state.header.distributionType === DistributionTypeEnum.FIXED_PRICE && (
            <FixedQuoteDataTable
              applicationId={state.header.applicationId}
              companyId={state.header.companyId}
              disabled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED || state.header.isManualExpedition}
            />
          )}
          {state.header.distributionType === DistributionTypeEnum.SPOT && (
            <QuoteDataTable
              applicationId={state.header.applicationId}
              companyId={state.header.companyId}
              disabled={
                state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED ||
                state.header.isManualExpedition ||
                loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1
              }
            />
          )}
          <ChangeVehicleInfo
            expeditionId={state.header.id}
            onComplete={() => {
              // reload data
              getDetailExpeditionCarrierTabAssigned(state.header.companyId, state.header.applicationId);
            }}
            data={{
              driverId: null,
              carPlate: state.readyToShipment.assigned.assignedCarrierInfo.carInfo.carPlate,
              dispatcherId: null,
            }}
            showDispatcher={false}
            showDriver={false}
          />
        </>
      )}
    </>
  );
};

export default TabCarrierActive;
