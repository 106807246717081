import React, { PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import * as yup from 'yup';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  icon?: ReactElement<{ color: string }>;
  title: string;
  mode?: 'normal' | 'compact';
}

const CardSection: React.FC<PropsWithChildren<ComponentProps>> = ({ children, icon, title, mode = 'normal' }) => {
  const {} = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  return (
    <div className={'flex flex-col gap-2'}>
      <COHeadline variant={'h3'} title={title} icon={icon} />
      {mode === 'normal' && <div className={'flex flex-col gap-2'}>{children}</div>}
      {mode === 'compact' && <div className={'flex flex-col ml-6 text-content-secondary'}>{children}</div>}
    </div>
  );
};

export default CardSection;
