import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import AdminCustomerI18nTemplatesList from '@components/pages/admin/templates/AdminICustomer18nTemplatesList';

interface ComponentProps {}

const GlobalAdminTemplating: React.FC<ComponentProps> = () => {
  return (
    <AdminLayout title={'TemplateAdmin - company'}>
      <AdminCustomerI18nTemplatesList />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(GlobalAdminTemplating);
