import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import Label from '../Form/Label/Label';
import { InputText } from 'primereact/inputtext';
import ButtonLayout from '../Form/ButtonLayout/ButtonLayout';
import { Button } from 'primereact/button';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import useValidation from '@hooks/validation-hook/useValidation.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  phoneNumber: string;
  onComplete: () => void;
}

const EditPhoneSidebar: React.FC<ComponentProps> = ({ visible, onHide, phoneNumber, onComplete }) => {
  const { tr } = useTranslation();
  const [state, setState] = useState(phoneNumber);
  const { setPhoneToCurrentUser } = useContext(ApiContext);

  const validationSchema = yup.string().defined().required();

  const { clear, validationErrors, validateAndSend } = useValidation(validationSchema, () => save());

  const save = () => {
    setPhoneToCurrentUser({ phone: state }, () => {
      onComplete();
    });
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        <h3 className="p-mb-4">{tr(`MyProfile.editPhoneNumber`, `Upravit telefonní číslo`)}</h3>
        <div className="p-field p-fluid">
          <Label title={tr(`MyProfile.phoneNumber`, `Telefonní číslo`)} />
          <InputText type="tel" value={state} onChange={e => setState(e.target['value'])} />
          <ValidationDiv errors={validationErrors} path={''} />
        </div>
        <ButtonLayout>
          <Button label={tr(`EditPhoneSidebar.save`, `Uložit`)} className="p-mr-2" onClick={() => validateAndSend(state)} />
          <Button label={tr(`EditPhoneSidebar.cancel`, `Zrušit`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default EditPhoneSidebar;
