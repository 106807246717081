import React from 'react';
import styled from 'styled-components';
import DivideStrings from '@components/ringil3/HelperComponents/DivideStrings';

interface ComponentProps {
  values: string[];
}

const MultipleValues: React.FC<ComponentProps> = ({ values }) => {
  return (
    <MultipleValuesInner>
      <DivideStrings values={values} divider={<Divider>|</Divider>} />
    </MultipleValuesInner>
  );
};

const MultipleValuesInner = styled.div`
  display: flex;
  gap: 0.4rem;
  white-space: nowrap;
  flex-wrap: wrap;
`;

const Divider = styled.div`
  display: flex;
  color: var(--complementary-warning);
`;

export default MultipleValues;
