import React, { useEffect, useState } from 'react';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import SvgDocument from '@app/pages/attachment/Document.tsx';
import COItemDisplay from '@app/pages/COItemDisplay/COItemDisplay.tsx';
import SvgDeleteIcon from '@app/pages/attachment/DeleteIcon.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { InputDocumentType } from '@components/obsolete/Form/InputDocumentType/InputDocumentType.tsx';
import { AccessTypeEnum, AttachmentsUploadedOutDTO } from '@api/logsteo-api.v2.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import * as yup from 'yup';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';
import { HorizontalLine } from '@components/styles.tsx';
import COText from '@app/pages/COText/COText.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { InputSwitch } from 'primereact/inputswitch';
import UploadPanelVisual from '@components/framework/uploadpanel/UploadPanelVisual.tsx';
import UploadPanel from '@components/framework/uploadpanel/UploadPanel.tsx';

interface ComponentProps {
  onSave: (data: AttachmentUploadPanelValues) => void;
  loading?: boolean;
}

export interface AttachmentUploadPanelValues {
  attachments: AttachmentsUploadedOutDTO[];
}

const AttachmentUploadPanel: React.FC<ComponentProps> = ({ onSave, loading = false }) => {
  const [visible, setVisible] = useState(false);
  const { tr } = useTranslationLgs();

  const form = useForm<AttachmentUploadPanelValues>(
    yup.object().shape({
      attachments: yup
        .array()
        .min(1)
        .of(yup.object().shape({ documentType: yup.string().required() })),
    }),
    { attachments: [] },
    d => save(d),
    false,
    false,
    null,
    'scroll',
  );

  useEffect(() => {
    if (!visible) {
      form.overwriteValues({ attachments: [] });
    }
  }, [visible]);

  const save = (data: AttachmentUploadPanelValues) => {
    console.log('Saving', data);
    onSave(data);
    setVisible(false);
  };

  const addNewAttachment = (d: AttachmentsUploadedOutDTO[]) => {
    form.setFieldValue(form.names.attachments, [...form.values.attachments, ...d]);
  };

  const deleteFile = (index: number) => {
    form.setFieldValue(
      form.names.attachments,
      form.values.attachments.filter((_, i) => i !== index),
    );
  };

  return (
    <div className={'py-2'}>
      <UploadPanelVisual
        loading={loading}
        onClick={() => {
          setVisible(true);
        }}
      />

      <RingilSidebar
        visible={visible}
        onBack={() => setVisible(false)}
        onContinue={() => {
          form.validateAndSend();
        }}
        heading={'Nahrát soubor'}>
        <div className="my-4">
          {form.values?.attachments.map((attachment, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-col gap-2">
                <COItemDisplay
                  icon={<SvgDocument />}
                  label={attachment.fileName}
                  rightIcon={
                    <SvgDeleteIcon
                      className={'cursor-pointer'}
                      onClick={() => {
                        deleteFile(index);
                      }}
                    />
                  }
                />
                <Field label={'Typ dokumentu'} errorMessage={findValidationMessage(form.validationErrors, `attachments[${index}].documentType`, tr)}>
                  <InputDocumentType
                    documentType={attachment.documentType}
                    onChange={v => form.setFieldValue(form.names.attachments + `[${index}].documentType`, v.documentType)}
                  />
                </Field>
                <div className={'flex flex-row gap-4 items-center my-2'}>
                  <InputSwitch
                    checked={attachment.accessType === AccessTypeEnum.INTERNAL}
                    onChange={v => form.setFieldValue(form.names.attachments + `[${index}].accessType`, v.value ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL)}
                  />
                  <COText label={'Interní dokument'}></COText>
                </div>
                <Field label={'Datum'}>
                  <RingilCalendar value={attachment.userDate} onChange={v => form.setFieldValue(form.names.attachments + `[${index}].userDate`, v)} fullWidth />
                </Field>
                <Field label={'Note'}>
                  <RingilTextArea maxLength={255} value={attachment.userNote} onChange={v => form.setFieldValue(form.names.attachments + `[${index}].userNote`, v)} />
                </Field>
                <div className={'my-4'}>
                  <HorizontalLine variant={'tertiary'} />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        {/*<input type="file" ref={inputFileRef} onChange={handleFileSelection} className="hidden" multiple />
        <UploadPanelVisual
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={() => {
            handleClick();
          }}
          showDropZone
          loading={apiRequestCount > 0}
        />*/}
        <UploadPanel onSave={v => addNewAttachment(v)} />
      </RingilSidebar>
    </div>
  );
};

export default AttachmentUploadPanel;
