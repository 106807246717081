import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { LovCompanyDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  companyId: string;
  onChange: (companyId: string) => void;
}

const InputAdminAllCompanies: React.FC<ComponentProps> = ({ companyId, onChange }) => {
  const { adminLoVAllCompanies } = useContext(ApiContext);
  const [companies, setCompanies] = useState<LovCompanyDTO[]>([]);

  useEffect(() => {
    adminLoVAllCompanies(d => {
      setCompanies(d);
    });
  }, []);

  return (
    <>
      <Dropdown
        filter={true}
        options={companies}
        optionLabel={'name'}
        optionValue={'id'}
        value={companyId}
        showClear={true}
        onChange={e => {
          onChange(e.value);
        }}
      />
    </>
  );
};

export default InputAdminAllCompanies;
