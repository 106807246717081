import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ExpeditionDetailV3OutDTO, ExpeditionStatusEnum } from '@api/logsteo-api.v2';
import { Panel, Paragraph } from '@components/ringil3/styles';
import { Row } from '@components/styles';
import RingilButton from '@components/ringil3/Buttons/RingilButton';
import SvgLkw from '@components/ringil3/icons/heading/Lkw';
import { useCreateAssignCarrierSidebar } from '@components/ringil3/Features/Expedition/Sidebars/AssignCarrierSidebar.tsx';
import { useCreateRequestCarrier } from '@components/ringil3/Features/Expedition/Sidebars/RequestCarrierSidebar.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  expedition: ExpeditionDetailV3OutDTO;
  onReload: () => void;
}

const TabExpeditionStateNew: React.FC<ComponentProps> = ({ expedition, onReload = () => {} }) => {
  const { tr } = useTranslationLgs();

  const { AssignCarrierSidebar, setVisible: setVisibleAssignCarrierSidebar } = useCreateAssignCarrierSidebar();
  const { RequestCarrier, setVisible: setVisibleRequestCarrier } = useCreateRequestCarrier();

  return (
    <>
      {/* assign carrier*/}
      {expedition.expeditionStatus === ExpeditionStatusEnum.NEW && (
        <>
          <COHeadline variant={'h2'} title={tr(`ExpeditionDetail.carrier`, `Carrier`)} icon={<SvgLkw />}></COHeadline>
          <Panel>
            <div className={'flex flex-row gap-4 items-center'}>
              <Paragraph>{tr(`ExpeditionDetail.doYouHaveNotAssignedCarrier`, `Do you have not assigned carrier`)}</Paragraph>
              <RingilButton label={tr(`TabExpeditionStateNew.requestCarrier`, `Request carrier`)} onClick={() => setVisibleRequestCarrier(true)} />
              <RingilButton label={tr(`TabExpeditionStateNew.assignCarrier`, `Assign carrier`)} onClick={() => setVisibleAssignCarrierSidebar(true)} />
            </div>
          </Panel>
        </>
      )}
      <RequestCarrier
        onContinue={() => {
          onReload();
        }}
        applicationId={expedition.applicationId}
        customerId={expedition.customerId}
      />
      <AssignCarrierSidebar
        applicationId={expedition.applicationId}
        customerId={expedition.customerId}
        onContinue={() => {
          onReload();
        }}
      />
    </>
  );
};

export default TabExpeditionStateNew;
