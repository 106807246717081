import React, { PropsWithChildren, useId } from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from 'primereact/tooltip';

interface ComponentProps {
  helpText?: string;
  label?: string;
  labelMode?: 'normal' | 'bigger';
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
}

export const VerticalFieldAndValue: React.FC<PropsWithChildren<ComponentProps>> = ({
  helpText,
  label,
  labelMode,
  children,
  errorMessage,
  required = false,
  readOnly,
  disabled,
}) => {
  const convertToMode = (errorMessage: string, readOnly: boolean, disabled: boolean): 'error' | 'disabled' | 'readOnly' | 'normal' => {
    if (errorMessage) {
      return 'error';
    }
    if (disabled) {
      return 'disabled';
    }
    if (readOnly) {
      return 'readOnly';
    }
    return 'normal';
  };
  return (
    <>
      <Content mode={convertToMode(errorMessage, readOnly, disabled)} className={errorMessage ? 'hasError' : null}>
        <LabelContainer>
          <Label>
            {label} {required && <span>*</span>}
          </Label>
          {helpText && <Help>{helpText}</Help>}
        </LabelContainer>
        <ContentInner>{children}</ContentInner>
        {errorMessage && <Error>{errorMessage}</Error>}
      </Content>
    </>
  );
};

const Error = styled.div`
  background-color: var(--ringil3-complementary-error-color);
  color: white;
  padding: 0 0.5rem;
  font-size: 0.75rem;
`;

interface HelpProps {}
const Help: React.FC<PropsWithChildren<HelpProps>> = ({ children }) => {
  const uniq = useId().replace(/:/g, '');
  return (
    <>
      <img src={'/images/icons/ringil3/help.svg'} id={uniq} />
      {/*
        // @ts-ignore*/}
      <Tooltip target={`#${uniq}`}>{children}</Tooltip>
    </>
  );
};

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0.5rem 0;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  /* Black */
  color: var(--ringil3-primary-color);
`;

const HelpInner = styled.div`
  font-size: 0.75rem;
`;

const Content = styled.div<{
  mode: 'error' | 'disabled' | 'readOnly' | 'normal';
}>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.mode === 'error' &&
    css`
      input,
      div.p-inputwrapper,
      div.r-input-wrapper {
        border: 1px solid var(--ringil3-complementary-error-color);
        color: var(--ringil3-complementary-error-color);
      }
      span.p-inputtext {
        color: var(--ringil3-complementary-error-color);
      }
    `}

  ${props =>
    props.mode === 'disabled' &&
    css`
      input,
      div.p-inputwrapper {
        border: 1px solid var(--ringil3-content-tertiary-color);
        color: var(--ringil3-content-tertiary-color);
      }
      span.p-inputtext {
        color: var(--ringil3-content-tertiary-color);
      }
    `}

  ${props =>
    props.mode === 'readOnly' &&
    css`
      input,
      div.p-inputwrapper {
        border: 1px solid var(--ringil3-content-tertiary-color);
        color: var(--ringil3-content-tertiary-color);
      }
      span.p-inputtext,
      div {
        color: var(--ringil3-content-tertiary-color);
      }
    `}
`;

const Icon = styled.div`
  display: flex;
`;
