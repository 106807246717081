import { ValidationError } from 'yup';
import { isNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';

// @ts-ignore
export const validate = (schema, data, context?: any): ValidationError[] => {
  try {
    schema.validateSync(data, {
      abortEarly: false,
      recursive: true,
      strict: false,
      context,
    });
    return [];
  } catch (err) {
    // @ts-ignore
    return err.inner;
  }
};

interface ComponentPros {
  errors: ValidationError[];
  path: string;
  wrapperStyle?: any;
  overidedErrorMessage?: string;
}

export const findValidationMessage = (errors: ValidationError[], path: string, t: any) => {
  if (isNullOrUndefined(errors)) return null;

  const error = errors.find(e => e.path === path);
  if (isNullOrUndefined(error)) return undefined;
  return t(`validation.${error.type}`);
};

export const findValidationMessageRaw = (errors: ValidationError[], path: string) => {
  if (isNullOrUndefined(errors)) return null;

  const error = errors.find(e => e.path === path);
  if (isNullOrUndefined(error)) return undefined;
  return error;
};

/**
 * This method is used for checking, if there is any validation problem for given prefix.
 * ie: company.name, company.address, company.city => prefix = company
 * @param errors
 * @param prefix
 */
export const hasValidationProblemForPrefix = (errors: ValidationError[], prefix: string) => {
  if (isNullOrUndefined(errors)) return false;

  const error = errors.find(e => e.path.startsWith(prefix));
  if (isNullOrUndefined(error)) return false;
  return true;
};

/**
 * This method is used for checking, if there is any validation problem for given prefix.
 * ie: company.name, company.address, company.city => prefix = company
 * @param errors
 * @param prefix
 */
export const findValidationProblemsForPrefix = (errors: ValidationError[], prefix: string) => {
  if (isNullOrUndefined(errors)) return null;

  const ret = errors.filter(e => e.path.startsWith(prefix));
  if (isNullOrUndefined(ret)) return null;
  return ret;
};

const ValidationDiv: React.FC<ComponentPros> = ({ path, errors, overidedErrorMessage, wrapperStyle }) => {
  const { t } = useTranslation('common');

  const error = errors?.find(e => e.path === path);
  if (error)
    return (
      <div className="p-invalid-feedback validationErrorMessage" style={wrapperStyle}>
        {!overidedErrorMessage && <>{t(`validation.${error.type}`)}</>}
        {overidedErrorMessage && <>{overidedErrorMessage}</>}
      </div>
    );
  return <div className="p-invalid-feedback "></div>;
};

export default ValidationDiv;
