import React from 'react';
import { COHeadlineProps } from '@components/framework/headlines/types.ts';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

const COHeadline: React.FC<COHeadlineProps> = ({ variant, title, icon, loading = false }) => {
  switch (variant) {
    case 'h1':
      return (
        <h1 className={'text-[26px] font-normal text-content-secondary  flex flex-row gap-4 items-center leading-10'}>
          {isNotNullOrUndefined(icon) && <div className={loading ? 'animate-spin' : ''}>{React.cloneElement(icon, { color: 'var(--ringil3-primary-color)' })}</div>}
          {title}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={'text-black text-xl font-normal flex flex-row gap-4 items-center'}>
          {isNotNullOrUndefined(icon) && <div className={loading ? 'animate-spin' : ''}>{React.cloneElement(icon, { color: 'var(--ringil3-primary-color)' })}</div>}
          {title}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={'text-black text-lg font-normal flex flex-row gap-2 items-center'}>
          {isNotNullOrUndefined(icon) && <div className={loading ? 'animate-spin' : ''}>{React.cloneElement(icon, { color: 'var(--ringil3-primary-color)' })}</div>}
          {title}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={'text-content-primary text-base font-medium  flex flex-row gap-2 items-center'}>
          {isNotNullOrUndefined(icon) && <div className={loading ? 'animate-spin' : ''}>{React.cloneElement(icon, { color: 'var(--ringil3-primary-color)' })}</div>}
          {title}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={'text-black text-xs font-normal flex flex-row gap-2 items-center'}>
          {isNotNullOrUndefined(icon) && <div className={loading ? 'animate-spin' : ''}>{React.cloneElement(icon, { color: 'var(--ringil3-primary-color)' })}</div>}
          {title}
        </h5>
      );
  }
};

export default COHeadline;
