import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { dumpVars, isNullOrUndefined } from '@utils/utils';
import { MaxCountResponseRampsOutDTO } from '@api/logsteo-api.v2';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { HorizontalLine } from '@components/ringil3/styles';
import { ColWithGap, Label, Row } from '@components/styles';
import RingilButton from '@components/ringil3/Buttons/RingilButton';
import styled from 'styled-components';
import { mapFromAPIDateTime, printDateInterval } from '@utils/date';
import SvgNoteInfo from '@components/ringil3/icons/NoteInfo';
import SvgWarningExclamation from '@components/ringil3/icons/WarningExclamation';

interface ComponentProps {
  reservationAppId: string;
}

interface Reservation {
  locationId: string;
  reservationId: string;
}

export const createPutOnTheTable = (onRemoved: () => void, onAdded: () => void) => {
  const { tr } = useTranslationLgs();
  const { putOnLocRampTable, removeFromLocRampTable, replaceOnLocRampTable } = useContext(ApiContext);
  const [selectedReservation, setSelectedReservation] = useState<string>();
  const [currentTable, setCurrentTable] = useState<MaxCountResponseRampsOutDTO[]>();
  const [currentReservation, setCurrentReservation] = useState<Reservation>();

  const putOnTheTable = (locationId: string, reservationId: string, reservationAppId: string) => {
    putOnLocRampTable(locationId, reservationId, d => {
      if (isNullOrUndefined(d.errorMaxCountResponse)) {
        onAdded();
      } else {
        setSelectedReservation(reservationAppId);
        setCurrentTable(d.errorMaxCountResponse);
        setCurrentReservation({ locationId, reservationId });
      }
    });
  };
  const removeFromTable = (locationId: string, reservationId: string) => {
    removeFromLocRampTable(reservationId, locationId, d => {
      onRemoved();
    });
  };

  const replace = (overwriteReservationId: string) => {
    replaceOnLocRampTable(currentReservation.locationId, currentReservation.reservationId, overwriteReservationId, d => {
      setSelectedReservation(undefined);
      onAdded();
    });
  };

  const PutOnTheTableComponent: React.FC<ComponentProps> = ({ reservationAppId }) => {
    return (
      <>
        <RingilSidebar
          onBack={() => setSelectedReservation(undefined)}
          visible={!isNullOrUndefined(selectedReservation)}
          onContinue={() => setSelectedReservation(undefined)}
          heading={tr(`PutOnTheTable.attachReservation`, `Attach reservation`)}
          showSaveButton={false}
        >
          <ColWithGap>
            <VerticalFieldAndValueWithIcon
              label={tr(`PutOnTheTableComponent.forReservation`, `For reservation`)}
              valueText={reservationAppId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />
            <HorizontalLine variant={'quaternary'} />
            <COInlineMessage variant={'warning'} icon={<SvgWarningExclamation />}>
              {tr(`PutOnTheTable.attention1`, `Pay attention, all the seats on the board are taken. Choose which one to replace.`)}
            </COInlineMessage>

            <>
              {currentTable?.map((ct, index) => {
                return (
                  <ColWithGap key={index}>
                    <Label>
                      {tr(`PutOnTheTable.plaxe`, `Misto {{placeNr}}`, {
                        placeNr: index + 1,
                      })}
                    </Label>
                    <div>
                      <Row>
                        <TableRow>
                          {ct.rampName} | SPZ: {ct.carPlate} | {printDateInterval(mapFromAPIDateTime(ct.timeslotFrom), mapFromAPIDateTime(ct.timeslotTo))}
                        </TableRow>
                        <RingilButton label={tr(`PutOnTheTable.replace`, `Replace`)} onClick={() => replace(ct.reservationId)} mode={'table'} />
                      </Row>
                      <TableRowNote>
                        {tr(`PutOnTheTable.resNote`, `Rezervace {{resAppId}}`, {
                          resAppId: ct.reservationApplicationId,
                        })}
                      </TableRowNote>
                    </div>
                  </ColWithGap>
                );
              })}
            </>
          </ColWithGap>
        </RingilSidebar>
      </>
    );
  };

  return { PutOnTheTableComponent, putOnTheTable, removeFromTable };
};

const TableRow = styled.div`
  display: flex;
  padding: 10px 12px;
  border: 1px solid #ccc;
  flex-grow: 1;

  /* Elements/Input Text */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* Content/Secondary */
  color: #616061;
`;

const TableRowNote = styled.div`
  margin-top: 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* Content/Secondary */
  color: var(--ringil3-secondary-color);
`;
