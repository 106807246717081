import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Dropdown } from 'primereact/dropdown';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  value: string;
  onChange: (cloCode: string) => void;
  disabled?: boolean;
}

export const CustomsTypeComponent: React.FC<ComponentProps> = ({ value, onChange, disabled = false }) => {
  const { lovCustomsTypes } = useContext(ApiContext);
  const [customsTypes, setCustomsTypes] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovCustomsTypes(d => {
      setCustomsTypes(d);
    });
  }, []);

  return (
    <>
      <Dropdown
        options={customsTypes}
        optionValue={'code'}
        optionLabel={'name'}
        value={value}
        style={{ width: '100%' }}
        disabled={disabled}
        onChange={e => onChange(e.value)}
      />
    </>
  );
};
