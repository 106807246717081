import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RingilH3, RowWithGap } from '@components/styles';
import { Ringil3H1 } from '@components/ringil3/styles';
import { MenuItem } from '@components/ringil3/types';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  title: string;
  buttons?: React.ReactNode[];
  moreActions?: MenuItem[];
  labels?: React.ReactNode;
  headingVariant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

const Heading: React.FC<ComponentProps> = ({ title, buttons, moreActions, labels, headingVariant = 'h1' }) => {
  return (
    <RingilRowWithButtons>
      <RowWithGap>
        <COHeadline variant={headingVariant} title={title}></COHeadline>
        {labels && <>{labels}</>}
      </RowWithGap>
      <ButtonRow>
        {buttons.map((t, index) => (
          <React.Fragment key={index}>{t}</React.Fragment>
        ))}
      </ButtonRow>
    </RingilRowWithButtons>
  );
};

const RingilRowWithButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 1rem;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 1rem;
`;

export default Heading;
