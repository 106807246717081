import React from 'react';
import styled from 'styled-components';
import { OpeningHourDefinitionsDTO, OpeningModeEnum } from '@api/logsteo-api.v2';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import { RowWithGap } from '@components/styles.tsx';

interface ComponentProps {
  openingHourDefinitions?: OpeningHourDefinitionsDTO;
}

const DisplayOpeningHours: React.FC<ComponentProps> = ({ openingHourDefinitions }) => {
  const { tr } = useTranslation();
  return (
    <>
      {openingHourDefinitions.openingHoursDays.map(openingDay => {
        return (
          <RowWithGap>
            <div>
              <Day>{tr(`TimeIntervalComponent.days${openingDay.day}`, openingDay.day)}:</Day>
              {openingDay.openingMode === OpeningModeEnum.PARTIALLY_OPEN && <span>{openingDay.openingHours.map(t => `${t.since} - ${t.till}`).join(', ')}</span>}
              {openingDay.openingMode === OpeningModeEnum.CLOSED && <span>{tr(`OpeningHourDefinitionForm.isClosed`, `Is closed`)}</span>}
              {openingDay.openingMode === OpeningModeEnum.OPEN && <span>{tr(`OpeningHourDefinitionForm.nonStop`, `non-stop`)}</span>}
            </div>
          </RowWithGap>
        );
      })}
    </>
  );
};

const Day = styled.div`
  font-weight: bold;
`;

export default DisplayOpeningHours;
