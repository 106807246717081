import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import { CameraTypeEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  value: CameraTypeEnum;
  onChange: (value: CameraTypeEnum) => void;
}

const InputSelectCamType: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <InputSelect
        model={[CameraTypeEnum.ENTRY, CameraTypeEnum.EXIT].map(t => ({
          label: tr(`InputSelectCamType.${t}`),
          code: t,
        }))}
        selectedItemCode={value}
        onSelect={v => onChange(v as CameraTypeEnum)}
      />
    </>
  );
};

export default InputSelectCamType;
