import { PropsWithChildren } from 'react';

interface ComponentProps {
  classNameIcon?: any;
  title?: string | number;
}

const HeaderPanel: React.FC<PropsWithChildren<ComponentProps>> = ({ classNameIcon, title, children }) => {
  return (
    <>
      <div className="p-d-flex p-ai-center p-mt-2 p-mb-2">
        <i className={classNameIcon} style={{ fontSize: '1.5em', marginRight: '.5rem' }}></i>
        <h3 className="p-m-0">{title}</h3>
        <span className="p-ml-3">{children}</span>
      </div>
      <hr className="p-mb-3" />
    </>
  );
};

export default HeaderPanel;
