import styled from 'styled-components';
import { PropsWithChildren } from 'react';

const StyledWrapper = styled.div`
  font-size: 14px;
  width: 270px;
  flex-shrink: 0;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 360px;
  }
`;

interface ComponentProps {}
export const ExpeditionCostWrapper: React.FC<PropsWithChildren<ComponentProps>> = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};
