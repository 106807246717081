import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';
import { isNotNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { UserCompanyUserDetailOutDTO } from '@api/logsteo-api.v2.tsx';
import { ApiContext } from '@api/api.tsx';

interface ComponentProps {
  onHide: () => void;
  userId: string;
  onComplete: () => void;
}

export const DeactivateUserDialog: React.FC<ComponentProps> = ({ userId, onHide, onComplete }) => {
  const { t } = useTranslation('common');
  const { changeStatusUserUnderCompany, getCompanyUserDetail } = useContext(ApiContext);
  const [detail, setDetail] = useState<UserCompanyUserDetailOutDTO>();

  useEffect(() => {
    if (isNotNullOrUndefined(userId)) {
      getCompanyUserDetail(userId, d => {
        setDetail(d);
      });
    }
  }, [userId]);

  const deactiveOrActivate = () => {
    // isActive == 1 => isBlocked = 1
    changeStatusUserUnderCompany(userId, detail.isActive, () => {
      onComplete();
    });
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog className="modal-sm" header={t(`DeactivateUserDialog.deactivateUser`, `Deactivate user`)} visible={isNotNullOrUndefined(userId)} onHide={onHide}>
        <div>
          {detail?.isActive && (
            <p className="p-mt-0">{t(`DeactivateUserDialog.byDeactivatingUser`, `By deactivating user this account won’t be able to log in anymore.`)}</p>
          )}
          {!detail?.isActive && <p className="p-mt-0">{t(`DeactivateUserDialog.byActivatingUser`, `By activating user this account will be able to log in.`)}</p>}
        </div>
        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          {detail?.isActive && (
            <Button label={t(`DeactivateUserDialog.deactivate`, `Deactivate`)} className="p-mr-2 p-button-danger" onClick={() => deactiveOrActivate()} />
          )}

          {!detail?.isActive && <Button label={t(`DeactivateUserDialog.activate`, `Activate`)} className="p-mr-2 p-button-danger" onClick={() => deactiveOrActivate()} />}

          <Button label={t(`DeactivateUserDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};
