import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  orderName: string;
  onChangeOrderName: (orderName: string) => void;
}

export const OrderIdSelector: React.FC<ComponentProps> = ({ orderName, onChangeOrderName }) => {
  const { t } = useTranslation('common');
  const [isOrderIdChangeOpen, setIsOrderIdChangeOpen] = useState(false);
  const [innerOrderId, setInnerOrderId] = useState(orderName);

  useEffect(() => {
    setInnerOrderId(orderName);
  }, []);

  return (
    <>
      {isOrderIdChangeOpen ? (
        <div className="p-col-12 p-md-6">
          <div className="p-field">
            <InputText
              value={innerOrderId}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                setInnerOrderId(targetValue);
              }}
              type="string"
            />
            <div className="p-invalid-feedback">Hodnota musí být zadána</div>
          </div>
          <div>
            <Button
              label={'+ ' + t('loadDetailsPage.saveBookingRef')}
              className="p-button-outlined"
              onClick={() => {
                onChangeOrderName(innerOrderId);
                setIsOrderIdChangeOpen(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="p-col-12 p-d-flex p-ai-start p-mt-3 p-mb-3">
          <div className="p-mr-3">
            <strong className="p-pr-2">{t('loadDetailsPage.labelBookingRef')}</strong>
            <strong>{orderName}</strong>
          </div>
          <Button
            label={String(t('loadDetailsPage.buttonChange'))}
            onClick={() => setIsOrderIdChangeOpen(true)}
            className="p-ml-auto p-button-text p-p-0"
            style={{ flexShrink: 0 }}
          />
        </div>
      )}
    </>
  );
};
