import { Button } from 'primereact/button';
import React, { useContext, useEffect, useId, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import CheckboxLayout from '@components/obsolete/CheckboxLayout/CheckboxLayout.tsx';
import CheckboxWrapper from '@components/obsolete/CheckboxWrapper/CheckboxWrapper.tsx';
import CheckboxItem from '@components/obsolete/CheckboxWrapper/CheckboxItem.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  selectedIds: string[];
  onChange: (selectedItems: string[]) => void;
  disabled?: boolean;
}

export const SpecialRequirementsSelector: React.FC<ComponentProps> = ({ selectedIds = [], onChange, disabled = false }) => {
  const [showMoreFields, setShowMoreFields] = useState(true);
  const { lovSpecialRequirements } = useContext(ApiContext);
  const [specialRequirements, setSpecialRequirements] = useState([]);

  useEffect(() => {
    lovSpecialRequirements(data => {
      setSpecialRequirements(data);
    });
  }, []);

  const { t } = useTranslation('common');

  const countSelected = () => {
    return selectedIds?.length;
  };

  const isChecked = (id: string) => {
    return selectedIds.indexOf(id) >= 0;
  };

  const setChecked = (key: string, checked: boolean) => {
    const newList = selectedIds.filter((item, index) => item !== key);
    if (checked) newList.push(key);
    onChange(newList);
  };

  const random = useId().replace(/:/g, '');

  return (
    <CheckboxLayout
      label={t(`loadDetailsPage.panelRequiredAutoEquipment.title`, {
        selected: countSelected(),
      })}
      className="p-field">
      <CheckboxWrapper>
        <>
          {specialRequirements?.map((requirement, index) => {
            if (index > 5 && !showMoreFields) {
              return null;
            } else {
              return (
                <CheckboxItem key={index}>
                  <Checkbox
                    inputId={`${random}${requirement.code}`}
                    checked={isChecked(requirement.code)}
                    onChange={e => setChecked(requirement.code, e.checked)}
                    className="p-mr-2"
                    disabled={disabled}
                  />
                  <label htmlFor={`${random}${requirement.code}`}>{requirement.name}</label>
                </CheckboxItem>
              );
            }
          })}
        </>
      </CheckboxWrapper>
      {!showMoreFields && (
        <ButtonLayout wrapper="WITHOUT_PADDING_BOTTOM">
          <Button label={t('loadDetailsPage.panelRequiredAutoEquipment.buttonAllEquipment')} className="p-button-text p-pl-0" onClick={() => setShowMoreFields(true)} />
        </ButtonLayout>
      )}
    </CheckboxLayout>
  );
};
