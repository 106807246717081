import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ListAdditionTermsOutDTO, PageResponseListAdditionTermsOutDTO } from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { printYesNo } from '@utils/utils';
import { Tooltip } from 'primereact/tooltip';
import { FilterPage, useFilter } from '@hooks/useFilter/Filter.tsx';
import { useCreateAdditionTermSidebar } from '@components/obsolete/additionalterms/sidebars/CreateOrUpdateAdditionalTermSidebar.tsx';
import { HeadingRow, RingilHeading } from '@components/styles.tsx';
import DeleteButton from '@components/obsolete/DeleteButton/DeleteButton.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';

interface ComponentProps {}

interface Filter {}

const AdditonalTermsList: React.FC<ComponentProps> = () => {
  const { listAdditionalTerms, getAdditionalTermById, deleteAdditionalTermById } = useContext(ApiContext);
  const [data, setData] = useState<PageResponseListAdditionTermsOutDTO>();
  const { tr } = useTranslationLgs();

  const { onPage, page, filter } = useFilter<Filter>((page, filter) => {
    load(page, filter);
  });

  const load = (page: FilterPage, filter: Filter) => {
    listAdditionalTerms(page, d => {
      setData(d);
    });
  };

  const deleteTerm = (termId: string) => {
    deleteAdditionalTermById(termId, () => {
      load(page, filter);
    });
  };

  const { CreateOrUpdateAdditionalTermSidebar, setInitialData } = useCreateAdditionTermSidebar(() => load(page, filter));

  return (
    <>
      {/*// @ts-ignore*/}
      <Card>
        <HeadingRow>
          <RingilHeading>{tr(`AdditonalTermsList.additionalTerms`, `Additional terms`)}</RingilHeading>
          <RingilButton
            label={tr(`AdditonalTermsList.create`, `Create`)}
            onClick={() =>
              setInitialData({
                name: '',
                isOnSinglePage: false,
                termText: '',
                isMandatory: false,
                companies: [],
              })
            }
          />
        </HeadingRow>

        {data && (
          <>
            {/*// @ts-ignore*/}
            <DataTable value={data.data} rows={data.size} paginator={true} lazy={true} totalRecords={data.total} onPage={onPage} first={(data.page - 1) * data.size}>
              <Column header={tr(`AdditonalTermsList.name`, `Name`)} field={'name'} style={{ width: '10rem' }}></Column>
              <Column
                header={tr(`AdditonalTermsList.isMandatory`, `Is mandatory`)}
                body={(row: ListAdditionTermsOutDTO) => <div>{printYesNo(row.isMandatory, tr)}</div>}
                style={{ width: '8rem' }}
              ></Column>
              <Column
                header={tr(`AdditonalTermsList.isOnSinglePage`, `Is printed on single page`)}
                body={(row: ListAdditionTermsOutDTO) => <div>{printYesNo(row.isOnSinglePage, tr)}</div>}
                style={{ width: '12rem' }}
              ></Column>
              <Column header={tr(`AdditonalTermsList.appliedCompanies`, `Count of companies using`)} field={'companyCount'} style={{ width: '12rem' }}></Column>

              <Column
                header={tr(`AdditonalTermsList.termText`, `Term text`)}
                body={(row: ListAdditionTermsOutDTO) => (
                  <>
                    {/*// @ts-ignore*/}
                    <Tooltip target={`#termTest_${row.id}`} style={{ maxWidth: '530px' }} position={'top'} event={'hover'}>
                      {row.termsText}
                    </Tooltip>
                    <div id={`termTest_${row.id}`}>{row.termsText.substring(0, 150)}...</div>
                  </>
                )}
                style={{ width: 'auto' }}
              ></Column>
              <Column
                body={(row: ListAdditionTermsOutDTO) => (
                  <div className={'flex gap-1'}>
                    <RingilButton
                      mode="filled"
                      label={tr(`AdditonalTermsList.edit`, `Edit`)}
                      onClick={() => {
                        getAdditionalTermById(row.id, d => {
                          setInitialData(d);
                        });
                      }}
                    />
                    <DeleteButton onDelete={() => deleteTerm(row.id)} />
                  </div>
                )}
                style={{ width: '18rem' }}
              ></Column>
            </DataTable>
          </>
        )}
        <CreateOrUpdateAdditionalTermSidebar />
      </Card>
    </>
  );
};

export default AdditonalTermsList;
