import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItemCommandParams } from 'primereact/menuitem';

interface BreadCrumbDefinition {
  label: string;
  url?: string;
  visibleOn: string[];
  clickable: string[];
  step?: string;
}

interface ComponentProps {
  model: BreadCrumbDefinition[];
  currentStep: string;
  onClick: (step: string) => void;
}

const WizardBreadCrumbComponent: React.FC<ComponentProps> = ({ model, currentStep, onClick }) => {
  const items = model
    .filter(s => s.visibleOn.find(vo => vo === currentStep))
    .map(step => ({
      label: step.label,
      url: step.clickable.find(s => s === currentStep) ? step.url : null,
      command: step.clickable.find(s => s === currentStep) ? (e: MenuItemCommandParams) => onClick(step.step) : (e: MenuItemCommandParams) => {},
      disabled: step.clickable.find(s => s === currentStep) ? false : true,
    }));
  return (
    <>
      <BreadCrumb model={items} className="p-mb-5" />
    </>
  );
};

export default WizardBreadCrumbComponent;
