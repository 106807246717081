import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuUser = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#616061"
      d="M12 12.25a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5m0-6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M19 19.25a.76.76 0 0 1-.75-.75c0-1.95-1.06-3.25-6.25-3.25s-6.25 1.3-6.25 3.25a.75.75 0 1 1-1.5 0c0-4.75 5.43-4.75 7.75-4.75s7.75 0 7.75 4.75a.76.76 0 0 1-.75.75"
    />
  </svg>
);
export default SvgMenuUser;
