import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CompanyInfoInDTO } from '@api/logsteo-api.v2';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { InputText } from 'primereact/inputtext';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import InputCountryPlain from '@components/obsolete/Form/InputCountry/InputCountryPlain.tsx';

interface ComponentProps {
  onChanged: () => void;
}

interface Form extends CompanyInfoInDTO {}

export const createEditCompanySidebar = () => {
  const [visible, setVisible] = useState(false);
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { getCompanyForCurrentUser, updateCompanyInfo } = useContext(ApiContext);

  const EditCompanyInfoSidebar: React.FC<ComponentProps> = ({ onChanged }) => {
    useEffect(() => {
      getCompanyForCurrentUser(d => {
        form.setValues(d);
      });
    }, [visible]);

    const validationSchema = yup.object().shape({
      city: yup.string().required(),
      companyName: yup.string().required(),
      country: yup.string().required(),
      streetNr: yup.string().required(),
      zipCode: yup.string().required(),
    });

    const form = useForm<CompanyInfoInDTO>(
      validationSchema,
      {
        city: '',
        companyName: '',
        country: '',
        streetNr: '',
        zipCode: '',
      },
      values => {
        save(values);
      },
      false,
      false,
    );

    const save = (values: CompanyInfoInDTO) => {
      updateCompanyInfo(values, () => {
        setVisible(false);
        onChanged();
      });
    };

    const names = proxiedPropertiesOf<CompanyInfoInDTO>();
    return (
      <RingilSidebar
        visible={visible}
        onHide={() => setVisible(false)}
        headerText={tr('EditCompanyInfoSidebar.updateCompanyInfo', 'Update company info')}
        onSave={() => {
          form.validateAndSend();
        }}
      >
        <>
          <HorizontalFieldValue
            label={tr('EditCompanyInfoSidebar.companyName', 'Company name')}
            required={true}
            validationError={form.errors.companyName}
            value={<InputText value={form.values.companyName} onChange={v => form.setFieldValue(names.companyName, v.target.value)} style={{ width: '100%' }} />}
          />
          <HorizontalFieldValue
            label={tr('EditCompanyInfoSidebar.streetNr', 'Streen and number')}
            required={true}
            validationError={form.errors.streetNr}
            value={<InputText value={form.values.streetNr} onChange={v => form.setFieldValue(names.streetNr, v.target.value)} style={{ width: '100%' }} />}
          />
          <HorizontalFieldValue
            label={tr('EditCompanyInfoSidebar.city', 'City')}
            required={true}
            validationError={form.errors.city}
            value={<InputText value={form.values.city} onChange={v => form.setFieldValue(names.city, v.target.value)} style={{ width: '100%' }} />}
          />
          <HorizontalFieldValue
            label={tr('EditCompanyInfoSidebar.zipCode', 'ZIP code')}
            required={true}
            validationError={form.errors.zipCode}
            value={<InputText value={form.values.zipCode} onChange={v => form.setFieldValue(names.zipCode, v.target.value)} style={{ width: '100%' }} />}
          />
          <HorizontalFieldValue
            label={tr('EditCompanyInfoSidebar.country', 'Country')}
            required={true}
            validationError={form.errors.country}
            value={<InputCountryPlain codeValue={form.values.country} onChange={v => form.setFieldValue(names.country, v)} style={{ width: '100%' }} />}
          />
        </>
      </RingilSidebar>
    );
  };

  return { setVisible, EditCompanyInfoSidebar };
};
