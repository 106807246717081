import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import { Clickable } from '@components/ringil3/styles.tsx';

export interface BreadCrumbModel {
  label: string;
  disabled?: boolean | false;
  action: string | (() => void);
}

interface ComponentProps {
  model: BreadCrumbModel[];
}

const PageBreadCrumb: React.FC<ComponentProps> = ({ model }) => {
  return (
    <>
      <PageBreadCrumbInner>
        <Link to={'/'}>
          <img src={'/images/icons/ringil3/home.svg'} width={14} height={15} />
        </Link>

        {model?.map((item, index, collection) => (
          <React.Fragment key={index}>
            <img src={'/images/icons/ringil3/breadcrumb_divider.svg'} width={12} height={12} />
            {isNotNullOrUndefined(item.action) && (
              <>
                {typeof item.action === 'string' && <Link to={item.action}>{item.label}</Link>}
                {typeof item.action === 'function' && <Clickable onClick={item.action}>{item.label}</Clickable>}
              </>
            )}
            {isNullOrUndefined(item.action) && <div>{item.label}</div>}
          </React.Fragment>
        ))}
      </PageBreadCrumbInner>
    </>
  );
};

const PageBreadCrumbInner = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export default PageBreadCrumb;
