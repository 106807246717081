import React from 'react';
import styled from 'styled-components';
import SvgTabularShowIcon from '@components/framework/icons/TabularShowIcon.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';

interface ComponentProps {
  onClick: () => void;
}

const TabularShowButton: React.FC<ComponentProps> = ({ onClick }) => {
  return <RingilButton onClick={onClick} mode={'rect-small'} leftIcon={<SvgTabularShowIcon />}></RingilButton>;
};

export default TabularShowButton;
