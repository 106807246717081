import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import { mapFromAPIToDateLong } from '@utils/date.tsx';
import SvgHeadingIconBus from '@app/icons/heading/HeadingIconBus.tsx';
import SvgHeadingIconVideo from '@app/icons/heading/HeadingIconVideo.tsx';
import TabularDeleteButton from '@components/ringil3/Buttons/TabularDeleteButton.tsx';
import { useCreateInputPasteTabular } from '@components/framework/input/InputPasteTabular/InputPasteTabular.tsx';
import { ApiContext } from '@api/api.tsx';
import {
  AllowedCarPlateResponse,
  ImportCameraInput,
  ListCompanyCameraResponse,
  ListCompanyCamerasResponse,
  PageResponseAllowedCarPlateResponse,
} from '@api/logsteo-api.v2.tsx';
import CamsTabs from '@app/pages/cams/tabs/CamsTabs.tsx';
import { useCreateAddCarPlateSidebar } from '@app/pages/cams/seidebars/AddCarPlateSidebar.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import { useCreateCameraSidebar } from '@app/pages/cams/seidebars/AddCameraSidebar.tsx';

interface ComponentProps {}

interface Filter {
  carPlate?: string;
  personName?: string;
}

export interface Data {
  carPlate: string;
  personName: string;
  entry?: {
    name: string;
    dateTime: string;
  };
  exit?: {
    name: string;
    dateTime: string;
  };
}

export interface CarPlates {
  carPlate: string;
  personName: string;
  validSince: string;
  validTill: string;
}

export interface Camera {
  externalId: string;
  name: string;
  companyLocationId: string;
  cameraType: 'entry' | 'exit';
}

const CamsDetail: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { listCompanyCameras, importCameras, deleteCam, listAllowedCarPlates, deleteAllowedCamPlate, importAllowedCarPlates } = useContext(ApiContext);

  const [cameras, setCameras] = useState<ListCompanyCamerasResponse>();
  const [allowedCarPlates, setAllowedCarPlates] = useState<PageResponseAllowedCarPlateResponse>();

  const loadData = (pageSize: number, pageNumber: number, filter: Filter) => {
    listAllowedCarPlates(filter, { pageNumber, pageSize }, d => {
      setAllowedCarPlates(d);
    });
  };

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, names } = useFilter<Filter>(
    (page, filter) => {
      loadData(page.pageSize, page.pageNumber, filter);
    },
    null,
    {},
  );

  const { InputPasteTabular, PasteTabularButton } = useCreateInputPasteTabular<CarPlates>(['carPlate', 'personName', 'validSince', 'validTill']);
  const { InputPasteTabular: InputPasteTabularCams, PasteTabularButton: PasteTabularButtonCams } = useCreateInputPasteTabular<ImportCameraInput>([
    'externalId',
    'name',
    'cameraType',
    'companyLocationId',
  ]);

  const { Table, Column } = useCreateTable<Data>();
  const { Table: TableCarPlate, Column: ColumnCarPlate } = useCreateTable<AllowedCarPlateResponse>();
  const { Table: TableCamera, Column: ColumnCamera } = useCreateTable<ListCompanyCameraResponse>();

  const { setVisible: setVisibleAddCarPlate, AddCarPlateSidebar } = useCreateAddCarPlateSidebar();
  const { setVisible: setVisibleCam, AddCameraSidebar } = useCreateCameraSidebar();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    listCompanyCameras(d => {
      setCameras(d);
    });
  };

  const deleteAllowedCamHandler = (id: string) => {
    deleteAllowedCamPlate(id, () => {
      loadData(page.pageSize, page.pageNumber, filter);
    });
  };

  return (
    <div className={'flex flex-col gap-2'}>
      <BreadCrumbComponent items={[{ label: 'Rezervace', url: nav.createNavigationLink(nav.urlFunctions.createReservationList()) }]} />
      <COHeadline variant={'h2'} title={tr('CamsSettings.kamery', 'Kamery')} />
      <CamsTabs selectedPanel={'setting'} />

      <div className={'flex flex-col gap-2'}>
        <div className={'my-3'}>
          <COHeadline variant={'h2'} title={tr('CamsSettings.interniSpz', 'Interní SPZ')} icon={<SvgHeadingIconBus />}></COHeadline>
        </div>

        <div className={'flex flex-row justify-between items-center'}>
          <div className={'flex flex-row gap-4 w-4/6'}>
            <RingilInputText
              placeholder={tr('CamsSettings.spz', 'SPZ')}
              value={filter?.carPlate}
              onChange={v => {
                applyFilterValue('carPlate', v);
              }}
            />

            <RingilInputText
              placeholder={tr('CamsSettings.pojmenovani', 'Pojmenování')}
              value={filter?.personName}
              onChange={v => {
                applyFilterValue('personName', v);
              }}
            />
          </div>
          <div>
            <PasteTabularButton />
          </div>
        </div>

        <TableCarPlate
          value={allowedCarPlates?.data}
          paginator
          onPage={onPage}
          selectedPage={page.pageNumber}
          rows={allowedCarPlates?.size}
          totalRecords={allowedCarPlates?.total}>
          <ColumnCarPlate header={tr('CamsSettings.spz', 'SPZ')} body={r => <>{r.carPlate}</>} />
          <ColumnCarPlate header={tr('CamsSettings.pojmenovani', 'Pojmenování')} body={r => <>{r.personName}</>} />
          <ColumnCarPlate header={tr('CamsSettings.platnostOd', 'Platnost od')} body={r => <>{mapFromAPIToDateLong(r.validSince)}</>} />
          <ColumnCarPlate header={tr('CamsSettings.platnostDo', 'Platnost do')} body={r => <>{mapFromAPIToDateLong(r.validTill)}</>} />
          <ColumnCarPlate
            header={''}
            width={100}
            body={r => (
              <>
                <TabularDeleteButton
                  onClick={() => {
                    deleteAllowedCamHandler(r.id);
                  }}
                  showConfirm
                />
              </>
            )}
          />
        </TableCarPlate>

        <AddButton onClick={() => setVisibleAddCarPlate(true)} title={tr('CamsSettings.pridatSpz', 'Přidat SPZ')} fullWidth />

        <div className={'my-3 flex flex-row items-center justify-between'}>
          <COHeadline variant={'h2'} title={tr('CamsSettings.evidovaneKamery', 'Evidované kamery')} icon={<SvgHeadingIconVideo />}></COHeadline>
          <PasteTabularButtonCams />
        </div>

        <TableCamera value={cameras?.cameras}>
          <ColumnCamera header={tr('CamsSettings.idKamery', 'ID kamery')} body={r => <>{r.externalId}</>} />
          <ColumnCamera header={tr('CamsSettings.pojmenovani', 'Pojmenování')} body={r => <>{r.name}</>} />
          <ColumnCamera header={tr('CamsSettings.typ', 'Typ')} body={r => <>{r.cameraType}</>} />
          <ColumnCamera header={tr('CamsSettings.lokace', 'Lokace')} body={r => <>{r.locationName}</>} />
          <ColumnCamera
            width={100}
            header={''}
            body={r => (
              <>
                <TabularDeleteButton
                  onClick={() => {
                    deleteCam(r.id, () => {
                      load();
                    });
                  }}
                />
              </>
            )}
          />
        </TableCamera>
      </div>
      <AddButton onClick={() => setVisibleCam(true)} title={'Přidat kameru'} fullWidth />

      <InputPasteTabular
        onSave={d => {
          importAllowedCarPlates(
            {
              allowedCarPlates: d,
            },
            () => {
              loadData(page.pageSize, page.pageNumber, filter);
            },
          );
        }}
      />

      <InputPasteTabularCams
        onSave={d => {
          importCameras(
            {
              cameras: d,
            },
            () => {
              load();
            },
          );
        }}
      />

      <AddCarPlateSidebar
        onSaved={() => {
          setVisibleAddCarPlate(false);
          loadData(page.pageSize, page.pageNumber, filter);
        }}></AddCarPlateSidebar>

      <AddCameraSidebar
        onSaved={() => {
          setVisibleCam(false);
          load();
        }}></AddCameraSidebar>
    </div>
  );
};

export default CamsDetail;
