import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Terminal } from 'primereact/terminal';
import { TerminalService } from 'primereact/terminalservice';

interface ComponentProps {}

const ConsolePage: React.FC<ComponentProps> = () => {
  const { getConsoleRunners, runConsoleRunner } = useContext(ApiContext);
  const [responseText, setResponseText] = useState(undefined);

  const commandHandler = (text: string) => {
    let response;
    //let argsIndex = text.indexOf(' ');
    //let command = argsIndex !== -1 ? text.substring(0, argsIndex) : text;

    switch (text) {
      case 'commands':
        getConsoleRunners(d => {
          setResponseText(d.map(r => r).join('\r\n'));
          TerminalService.emit('response', 'Ok');
        });
        return;
        break;

      case 'clear':
        response = null;
        setResponseText('');
        break;

      default:
        runConsoleRunner({ command: text }, d => {
          TerminalService.emit('response', 'OK');
          setResponseText(d.response);
        });
        return;
        break;
    }
  };

  useEffect(() => {
    TerminalService.on('command', commandHandler);

    return () => {
      TerminalService.off('command', commandHandler);
    };
  }, []);
  return (
    <>
      <Terminal prompt={'ringil #'} welcomeMessage={'Vitejte v systemu Ringil'} />
      <div className={'bg-black flex'}>
        <pre className={'w-full h-full text-white p-3'}>
          Output:{'\r\n'}
          {responseText}
        </pre>
      </div>
    </>
  );
};

export default withAuthenticationRequired(ConsolePage);
