import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  text: string;
  applicationId: string;
}

export const InternalInfoIcon: React.FC<ComponentProps> = ({ text, applicationId }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      {text && (
        <Icon>
          <span id={`internalInfo_${applicationId}`} className={'pi pi-info-circle'} />
          {/*
        // @ts-ignore*/}
          <Tooltip target={`#internalInfo_${applicationId}`} position={'left'}>
            <div>{text}</div>
          </Tooltip>
        </Icon>
      )}
    </>
  );
};

const Icon = styled.span`
  padding: 3px;
  cursor: pointer;
`;
