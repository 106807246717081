export enum QtyUnit {
  KS = 'KS',
}

export enum DemandListPseudoState {
  ASSIGNING = 'ASSIGNING',
  NOT_WIN = 'NOT_WIN',
  WIN = 'WIN',
  OFFERED = 'OFFERED',
  REJECTED = 'REJECTED',
}

export enum QuotePriceTypeEnum {
  AGREED = 'AGREED',
  OFFERED = 'OFFERED',
  FIXED = 'FIXED',
  EXPECTED = 'EXPECTED',
  REJECTED = 'REJECTED',
}

export enum FilterDocumentsInDTOEnum {
  CARRIER = 'CARRIER',
  CUSTOMER = 'CUSTOMER',
}

export enum ShipmentListState {
  ASSIGNED = 'ASSIGNED',
  READY_TO_SHIPMENT = 'READY_TO_SHIPMENT',
  ACTIVE = 'ACTIVE',
  WAITING_FOR_DOCS = 'WAITING_FOR_DOCS',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
}

export enum FeatureEnum {
  PDF = 'PDF',
  EW = 'EW',
  SN_EXTERNAL_IDS = 'SN_EXTERNAL_IDS',
  ADD_ATTACHMENTS_TO_MAIL = 'ADD_ATTACHMENTS_TO_MAIL',
}

export enum RoleEnum {
  ROLE_CARRIER = 'ROLE_CARRIER',
  ROLE_CUSTOMER = 'ROLE_CUSTOMER',
  ROLE_DRIVER = 'ROLE_DRIVER',
  ROLE_COMPANY = 'ROLE_COMPANY',
  ROLE_COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN',
  ROLE_CUSTOMER_ADMIN = 'ROLE_CUSTOMER_ADMIN',
  ROLE_CARRIER_ADMIN = 'ROLE_CARRIER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SM_NOTIFY = 'ROLE_SM_NOTIFY',
  ROLE_TS_NOTIFY = 'ROLE_TS_NOTIFY',
  ROLE_EXP_NOTIFY = 'ROLE_EXP_NOTIFY',
  ROLE_SH_NOTIFY = 'ROLE_SH_NOTIFY',
  ROLE_SN_NOTIFY = 'ROLE_SN_NOTIFY',
  ROLE_EXP_RW = 'ROLE_EXP_RW',
  ROLE_SN_RW = 'ROLE_SN_RW',
}
