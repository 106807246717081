import * as yup from 'yup';
import { isNullOrUndefined, isValidString, parseNumberOrUndefined } from '@utils/utils.tsx';
import { InputDocumentTypeEnum } from '@components/features/eTransport/input/ETransportInputDocumentType.tsx';
import { PriceDTO } from '@api/logsteo-api.v2.tsx';

export const validationSchema = (tr: any) =>
  yup.object().shape({
    referenceNumber: yup.string().required(),
    transportCompany: yup.object().shape({
      transportCompanyName: yup.string().required(),
      transportCompanyCountry: yup.string().required(),
      transportCompanyVatId: yup.string().required(),
    }),
    licencePlate: yup.string().required(),
    document: yup.object().shape({
      documentType: yup.number().required(),
      documentNumber: yup.string().required(),
      documentDate: yup.date().required(),
      documentName: yup.string().nullable().when('documentType', {
        is: InputDocumentTypeEnum.Other,
        then: yup.string().required(),
      }),
    }),
    supplierName: yup.string().required(),
    transportDate: yup.date().required(),
    transportMode: yup.number().min(1).required(),
    products: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().min(1).required(),
          ean: yup.string().min(1).required(),
          nomenclature: yup.number().min(10000000).required(),
          quantity: yup.number().positive().required(),
          weightNetto: yup.number().positive().required(),
          priceExVat: yup.mixed().test('required', 'required', (value, context) => {
            const priceExVat = value as PriceDTO;
            if (priceExVat?.price > 0 && priceExVat?.currency?.length > 1) {
              return true;
            }
            return false;
          }),
        }),
      )
      .min(1, tr('ETransport.doNotForgetToSpecifyTheItemsToBeTransferred', 'Nezapomeňte zadat položky, které se mají odeslat.'))
      .required(tr('ETransport.doNotForgetToSpecifyTheItemsToBeTransferred', 'Nezapomeňte zadat položky, které se mají odeslat.')),
    originAddress: yup.object().shape({
      borderId: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 1 || transportMode == 4) {
          if (isNullOrUndefined(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
      regionId: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 2 || transportMode == 3) {
          if (isNullOrUndefined(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
      town: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 2 || transportMode == 3) {
          if (!isValidString(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
      street: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 2 || transportMode == 3) {
          if (!isValidString(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
    }),
    destinationAddress: yup.object().shape({
      borderId: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 3 || transportMode == 4) {
          if (isNullOrUndefined(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
      regionId: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 1 || transportMode == 2) {
          if (isNullOrUndefined(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
      town: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 1 || transportMode == 2) {
          if (!isValidString(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
      street: yup.mixed().test('required', 'required', (value, context) => {
        const transportMode = context.options.context.formData.transportMode;
        if (transportMode == 1 || transportMode == 2) {
          if (!isValidString(value)) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }),
    }),
  });
