import React from 'react';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { Dropdown } from 'primereact/dropdown';
import { LovDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';

interface ComponentProps {
  responsiblePersons: LovDTO[];
  onChange: (responsiblePersons: LovDTO[]) => void;
  style?: any;
  showClearButton?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const InputResponsiblePerson: React.FC<ComponentProps> = ({ responsiblePersons, onChange, style, showClearButton = false, disabled = false, placeholder }) => {
  const options = useResponsiblePersons();

  return (
    <>
      <MultiSelect
        value={responsiblePersons}
        options={options}
        onChange={e => onChange(e.target.value)}
        optionLabel="name"
        dataKey={'id'}
        style={style}
        showClear={showClearButton}
        disabled={disabled}
        placeholder={placeholder}
        filter={true}
        itemTemplate={(option: LovDTO) => (
          <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
        )}
      />
    </>
  );
};

export default InputResponsiblePerson;
