import React from 'react';
import styled from 'styled-components';
import { Clickable } from '@components/ringil3/styles';

interface ComponentProps {
  icon?: JSX.Element;
  label?: string;
  onClick?: () => void;
}

const LinkWithIcon: React.FC<ComponentProps> = ({ icon, label, onClick }) => {
  return (
    <>
      <Clickable onClick={e => (onClick ? onClick() : {})}>
        <div className={'flex flex-row gap-2 items-center'}>
          {icon && <span>{icon}</span>}
          {label && <span>{label}</span>}
        </div>
      </Clickable>
    </>
  );
};

const RowWithGap = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

export default LinkWithIcon;
