import React, { PropsWithChildren } from 'react';
import classNames from 'clsx';

interface ComponentProps {
  className?: string;
}

const Panel: React.FC<PropsWithChildren<ComponentProps>> = ({ children, className }) => {
  const classes = classNames(className);
  return <div className={'panel-part ' + classes}>{children}</div>;
};

export default Panel;
