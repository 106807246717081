import React, { PropsWithChildren, useEffect } from 'react';
import { isNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface ComponentProps {}
/// OBSOLETE
const Language: React.FC<PropsWithChildren<ComponentProps>> = ({ children }) => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const lang = searchParams.get('lang');

  useEffect(() => {
    if (!isNullOrUndefined(lang)) i18n.changeLanguage(lang);
  }, [lang]);

  return <>{children}</>;
};

export default Language;
