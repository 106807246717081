import React from 'react';
import classNames from 'clsx';

interface ComponentProps {
  value?: string;
  description?: string;
  img?: any;
  classNameIcon?: any;
}

const ExpeditionDetailOptions: React.FC<ComponentProps> = ({ value, description, img, classNameIcon }) => {
  const classes = classNames(classNameIcon);

  return (
    <div className="item-details">
      <div className={'flex flex-row items-center gap-2'}>
        {img && (
          <div>
            <img src={img} alt="" />
          </div>
        )}
        {classNameIcon && <i className={'pi ' + classes}></i>}
        {value}
      </div>
      <small>{description}</small>
    </div>
  );
};
export default ExpeditionDetailOptions;
