import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import { Chip } from 'primereact/chip';
import { GlobalTemplatePreviewOutDTO } from '../../../../api/logsteo-api.v2';
import { Link } from 'react-router-dom';

interface ComponentProps {}

const AdminI18nTemplatesList: React.FC<ComponentProps> = () => {
  const [data, setData] = useState<GlobalTemplatePreviewOutDTO[]>();
  const { adminGlobalListTemplate } = useContext(ApiContext);
  useEffect(() => {
    adminGlobalListTemplate(data => {
      setData(data);
    });
  }, []);

  const renderLanguages = (row: GlobalTemplatePreviewOutDTO) => (
    <LanguageRow>
      {row.languages.map((item, index) => {
        return <Language key={index}>{item.language}</Language>;
      })}
    </LanguageRow>
  );

  return (
    <>
      <h1>I18N templates</h1>
      <DataTableWrapper>
        {/*// @ts-ignore*/}
        <DataTable value={data}>
          <Column header={'ID'} body={(row: GlobalTemplatePreviewOutDTO) => <Link to={`/admin/templating/${row.emailType}`}>{row.emailType}</Link>} />
          <Column body={renderLanguages} header={'Languages'} style={{ width: '10rem' }} />
          <Column body={(row: GlobalTemplatePreviewOutDTO) => <div>{row.description}</div>} header={'Description'} />
          <Column body={(row: GlobalTemplatePreviewOutDTO) => <div>{row.fallback}</div>} header={'Fallback'} style={{ width: '10rem' }} />
          <Column
            body={(row: GlobalTemplatePreviewOutDTO) => (
              <div>
                {row.fallbackOverride.map((t, key) => (
                  <Chip key={key} label={`${t.from}->${t.to}`} />
                ))}
              </div>
            )}
            header={'Fallback override'}
            style={{ width: '15rem' }}
          />
        </DataTable>
      </DataTableWrapper>
    </>
  );
};

const DataTableWrapper = styled.div`
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Language = styled.div`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;
const LanguageRow = styled.div`
  display: flex;
`;

export default AdminI18nTemplatesList;
