import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface ComponentProps {
  className?: string;
}

const InputLayout: React.FC<PropsWithChildren<ComponentProps>> = ({ className, children }) => {
  const classes = clsx(className);

  return <>{className ? <div className={'p-fluid p-grid p-formgrid ' + classes}>{children}</div> : <div className={'p-fluid p-grid p-formgrid'}>{children}</div>}</>;
};

export default InputLayout;
