import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { PropertyWithSubProperties } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import InputCompanyPartnerPreview from '../Form/InputPartner/InputCompanyPartnerPreview';
import { InputNumber } from 'primereact/inputnumber';
import InputRPricePlain from '../Form/InputPricePlain/InputRPricePlain';
import { useStateCallback } from '@hooks/useStateCallback/useStateCallback.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  modelPrefix: string;
  contentModel: string;
  attributeName: string;
  readMode: boolean;
  applicationId: string;
  customerId: string;
}

const DynamicForm: React.FC<ComponentProps> = ({ modelPrefix, contentModel, attributeName, readMode, customerId, applicationId }) => {
  const { getDescribeModel, setCustomerDynamicData, cuDynamicCustomerData } = useContext(ApiContext);
  const [describedModel, setDescribedModel] = useStateCallback<PropertyWithSubProperties>(undefined);
  const [data, setData] = useState<{ [key: string]: any }>({});
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const renderPickFromValueFunction = (p: PropertyWithSubProperties, readMode: boolean) => {
    if (readMode) return <div>{data[p.name]}</div>;
    return p.multiple ? (
      <MultiSelect
        options={p.valuesFunction.split(',')}
        onChange={e => setData({ ...data, [p.name]: e.target.value })}
        value={data[p.name]}
        disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
      />
    ) : (
      <Dropdown
        options={p.valuesFunction.split(',')}
        onChange={e => setData({ ...data, [p.name]: e.target.value })}
        value={data[p.name]}
        disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
      />
    );
  };

  const renderInputText = (p: PropertyWithSubProperties, readMode: boolean) => {
    if (readMode) return <div>{data[p.name]}</div>;
    return (
      <InputText value={data[p.name]} onChange={e => setData({ ...data, [p.name]: e.target.value })} disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1} />
    );
  };

  const renderInputNumber = (p: PropertyWithSubProperties, readMode: boolean) => {
    if (readMode) return <div>{data[p.name]}</div>;
    return (
      <InputNumber
        value={data[p.name]}
        onChange={e => setData({ ...data, [p.name]: e.value })}
        minFractionDigits={0}
        maxFractionDigits={2}
        locale={'cs'}
        disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
      />
    );
  };

  const renderInputMoney = (p: PropertyWithSubProperties, readMode: boolean) => {
    if (readMode) return <div>{data[p.name]}</div>;
    return <InputRPricePlain value={data[p.name]} onChange={e => setData({ ...data, [p.name]: e })} disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1} />;
  };
  const renderCompanyPartnerPreview = (p: PropertyWithSubProperties, readMode: boolean) => {
    return (
      <InputCompanyPartnerPreview
        value={data[p.name]}
        onChange={e => setData({ ...data, [p.name]: e })}
        required={p.required}
        readMode={readMode || loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
      />
    );
  };

  const resolveInputControl = (p: PropertyWithSubProperties, readMode: boolean) => {
    if (!isNullOrUndefined(p.valuesFunction)) return renderPickFromValueFunction(p, readMode);
    if (p.type === 'cm:string') return renderInputText(p, readMode);
    if (p.type === 'cm:number') return renderInputNumber(p, readMode);
    if (p.type === 'r:price') return renderInputMoney(p, readMode);
    if (p.type === 'r:companyPartnerPreview') return renderCompanyPartnerPreview(p, readMode);

    return (
      <div>
        unknow {p.type} for {p.name}
      </div>
    );
  };

  const convertToYup = (p: PropertyWithSubProperties) => {
    if (p.type === 'cm:string') return p.required ? yup.string().required() : yup.string();
  };

  const computeSchema = (p: PropertyWithSubProperties) => {
    p.innerProperty.map(t => convertToYup(t));
  };

  const save = () => {
    setCustomerDynamicData(applicationId, 'additionalInvoicingInfo', customerId, data, () => {});
  };

  return <>NOT IMPLEMENTED</>;
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export default DynamicForm;
