import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { LocationRampTableOutDTO } from '@api/logsteo-api.v2';
import { useLocalStorage } from '@utils/utils';

interface ComponentProps {}

interface TableRowProps {
  text: string;
}

const TableRow: React.FC<PropsWithChildren<TableRowProps>> = ({ text, children }) => {
  return (
    <TableRowWrapper>
      {text}
      {children}
    </TableRowWrapper>
  );
};

export const useYardTable = (locationId: string) => {
  const { getLocRampTable, removeFromLocRampTable } = useContext(ApiContext);

  const [tableData, setTableData] = useState<LocationRampTableOutDTO>(undefined);
  const [hasTableData, setHasTableData] = useState(false);

  useEffect(() => {
    refresh();
  }, [locationId]);

  const refresh = () => {
    getLocRampTable(locationId, d => {
      setTableData(d);
      setHasTableData(d != null);
    });
  };

  const removeFromTable = (reservationId: string) => {
    removeFromLocRampTable(reservationId, locationId, () => {
      refresh();
    });
  };

  const YardTable: React.FC<ComponentProps> = ({}) => {
    const { tr } = useTranslationLgs();
    const [showTable, setShowTable] = useLocalStorage('SHOW_TABLE', false);
    return (
      <>
        {tableData && (
          <>
            <Label onClick={e => setShowTable(!showTable)}>
              {tr(`YardTable.attachedOnTable`, `Attached on table`)} {!showTable ? <>&gt;&gt;&gt;</> : <>&lt;&lt;&lt;</>}
            </Label>
            {showTable && (
              <TableWrapper>
                {tableData?.definedArea?.currentData.map((rowData, rowKey) => {
                  return (
                    <TableRow key={rowKey} text={rowData.text}>
                      <span className={'pi pi-trash'} onClick={e => removeFromTable(rowData.reservationId)} style={{ cursor: 'pointer' }}></span>
                    </TableRow>
                  );
                })}
              </TableWrapper>
            )}
          </>
        )}
      </>
    );
  };

  return { YardTable, refresh, hasTableData };
};

const Label = styled.div`
  display: flex;
  font-weight: 700;
  font-size: medium;
  color: var(--anchor-color);
  cursor: pointer;
`;

const TableRowWrapper = styled.div`
  display: flex;
  color: #ff0000;
  font-weight: 700;
  font-size: larger;
  justify-content: space-between;
  padding: 0.3rem;
  font-family: 'Courier New';
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  background-color: #000000;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;
