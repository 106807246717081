import React, { PropsWithChildren } from 'react';
import { Provider } from '../../api/api.tsx';
import AuthenticatedUser from './AuthenticatedUser.tsx';
import { ToastProvider } from './ToastContext.tsx';

interface ComponentProps {}

const AuthWrapper: React.FC<PropsWithChildren<ComponentProps>> = ({ children }) => {
  return (
    <ToastProvider>
      <Provider>
        <AuthenticatedUser>{children}</AuthenticatedUser>
      </Provider>
    </ToastProvider>
  );
};

export default AuthWrapper;
