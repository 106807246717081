import React, { useContext, useEffect } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ExpeditionStatusEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  expeditionStage: string;
  isManual: boolean;
}

const ExpeditionStateSpan: React.FC<ComponentProps> = ({ expeditionStage, isManual }) => {
  let stage = null;
  const { tr } = useTranslationLgs();

  switch (expeditionStage) {
    case ExpeditionStatusEnum.ASSIGNED:
      stage = (
        <span className="" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </span>
      );
      break;

    case ExpeditionStatusEnum.ASSIGNING:
      stage = (
        <p className="" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </p>
      );
      break;

    case ExpeditionStatusEnum.NEW:
      stage = (
        <span className="" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </span>
      );
      break;

    case ExpeditionStatusEnum.CANCELED:
      stage = (
        <span className="" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </span>
      );
      break;

    case ExpeditionStatusEnum.READY_TO_SHIPMENT:
      stage = (
        <span className="" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </span>
      );
      break;

    case ExpeditionStatusEnum.ACTIVE:
      stage = (
        <span className="" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </span>
      );
      break;

    case ExpeditionStatusEnum.WAITING_FOR_DOCS:
      stage = (
        <span className="text-green" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </span>
      );
      break;

    case ExpeditionStatusEnum.FINISHED:
      stage = (
        <span className="" title={tr(`StateTooltip${expeditionStage}`, `${expeditionStage}`)} style={{ textDecoration: 'underline dotted' }}>
          {tr(`Shipments.${expeditionStage}`, `Shipments.${expeditionStage}`)}
        </span>
      );
      break;
  }
  return <>{stage}</>;
};

export default ExpeditionStateSpan;
