import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { EntityTypeEnum, GetCMRDetailResponse } from '@api/logsteo-api.v2';
import { dumpVars, formatPrice, formatWeight, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { Card } from 'primereact/card';
import { Clickable, RingilHeading, RowWithSpaceBetween, SectionHeading } from '@components/styles';

import { Button } from 'primereact/button';
import { mapFromAPIToDateLong } from '@utils/date';
import Labels from '@components/obsolete/Labels/Labels.tsx';

interface ComponentProps {
  expeditionId: string;
}

const CMRDetail: React.FC<ComponentProps> = ({ expeditionId }) => {
  const { getCMRDetail, generateCMRPDF } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [cmr, setCmr] = useState<GetCMRDetailResponse>();

  useEffect(() => {
    if (expeditionId) {
      getCMRDetail(expeditionId, d => {
        setCmr(d);
      });
    }
  }, [expeditionId]);

  if (isNullOrUndefined(cmr)) return <></>;

  const is = (id: string) => {
    return false;
  };

  const click = (id: string) => {
    //router.push(`/cmr/66d39e5f-e0f0-40e7-8843-e7b624167622#${id}`);
  };

  const downloadFile = (cmrId: string) => {
    generateCMRPDF(cmrId, data => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = data.fileName;
      downloadLink.click();
    });
  };

  return (
    <>
      {/*// @ts-ignore*/}
      <Card>
        <RingilHeading>
          {tr(`CMRDetail.title`, `Detail CMR pro {{entityApplicationId}}`, {
            entityApplicationId: cmr.entityApplicationId,
          })}
        </RingilHeading>
        <NavigationBar>
          <Links>
            {cmr.documents.map(doc => {
              return (
                <>
                  {is(doc.applicationId) ? (
                    <div>{doc.applicationId}</div>
                  ) : (
                    <a onClick={e => click(doc.applicationId)}>
                      <Clickable>{doc.applicationId}</Clickable>
                    </a>
                  )}
                </>
              );
            })}
          </Links>
        </NavigationBar>
        <CMRWrapper>
          {cmr.documents.map(doc => {
            return (
              <CMR>
                <RowWithSpaceBetween>
                  <SectionHeading id={doc.applicationId} style={{ scrollMarginTop: '140px' }}>
                    {doc.applicationId}
                  </SectionHeading>
                  <Buttons>
                    <Button label={tr(`CMRDetail.download`, `Download`)} onClick={e => downloadFile(doc.id)} />
                  </Buttons>
                </RowWithSpaceBetween>
                {tr(`CMRDetail.created`, `Created: {{createdByFullName}}, {{createdDate}}`, {
                  createdByFullName: doc.createdByFullName,
                  createdDate: mapFromAPIToDateLong(doc.createdDate),
                })}
                <Labels objectType={EntityTypeEnum.CMR_DOCUMENT} objectId={doc.id} />
                <TwoColumns>
                  <Col>
                    <NumberedBox no={1} name={tr(`CMRDetail.sender`, 'Sender')} editable={true}>
                      <strong>{doc.senderName}</strong>
                      <div>{doc.senderStreetNr}</div>
                      <div>
                        {doc.senderZipCode}, {doc.senderCountryCode3}, {doc.senderCity}
                      </div>
                    </NumberedBox>
                    <NumberedBox no={2} name={tr(`CMRDetail.consignee`, `Consignee`)} editable={true}>
                      <strong>{doc.consigneeName}</strong>
                      <div>{doc.consigneeStreetNr}</div>
                      <div>
                        {doc.consigneeZipCode}, {doc.consigneeCountryCode3}, {doc.consigneeCity}
                      </div>
                    </NumberedBox>
                    <NumberedBox no={3} name={tr(`CMRDetail.unloadingDateAndPlace`, `unloadingDateAndPlace`)} editable={true}>
                      <strong>{doc.unloadingDateAndPlace}</strong>
                    </NumberedBox>
                    <NumberedBox no={4} name={tr(`CMRDetail.loadDateAndPlace`, `loadDateAndPlace`)} editable={true}>
                      <strong>{doc.loadDateAndPlace}</strong>
                    </NumberedBox>
                    <NumberedBox no={5} name={tr(`CMRDetail.attachedDocumentTypes`, `attachedDocumentTypes`)} editable={true}>
                      <strong>{doc.attachedDocumentTypes}</strong>
                    </NumberedBox>
                  </Col>
                  <ColWithSpaceBetween>
                    <NumberedBox no={16} name={tr(`CMRDetail.carrier`, `Carrier`)} editable={true}>
                      {isNotNullOrUndefined(doc.carrier) && (
                        <>
                          <strong>{doc.carrier?.name}</strong>
                          <div>{doc.carrier?.streetNr}</div>
                          <div>{[doc.carrier?.zipCode, doc.carrier?.countryCode3, doc.carrier?.city].filter(t => t != null).join(', ')}</div>
                        </>
                      )}
                    </NumberedBox>
                    <NumberedBox no={17} name={tr(`CMRDetail.successiveCarriers`, `successiveCarriers`)} editable={true}>
                      <strong>{doc.successiveCarriers}</strong>
                    </NumberedBox>
                    <NumberedBox no={18} name={tr(`CMRDetail.carrierReservationsAndObservations`, `carrierReservationsAndObservations`)} editable={true}>
                      <strong>{doc.carrierReservationsAndObservations}</strong>
                    </NumberedBox>
                  </ColWithSpaceBetween>
                </TwoColumns>
                <TwoColumns>
                  <Col>
                    <SectionHeading>{tr(`CMRDetail.cargoInfo`, `Cargo Info`)}</SectionHeading>
                    <NumberedBox no={null} name={tr(`CMRDetail.orders`, `Orders`)} editable={true} useMargin={false}>
                      <strong>{doc.ordersNumbers}</strong>
                    </NumberedBox>
                  </Col>
                </TwoColumns>
                <TwoColumns>
                  <Col>
                    <NumberedBox no={13} name={tr(`CMRDetail.senderInstructions`, `Sender instructions`)} editable={true}>
                      <strong>{doc.sendersInstructions}</strong>
                    </NumberedBox>
                    <NumberedBox no={14} name={tr(`CMRDetail.cod`, `COD`)} editable={true}>
                      {doc.codAmount && <strong>{formatPrice(doc.codAmount, doc.codCurrenyCode)}</strong>}
                    </NumberedBox>
                    <NumberedBox no={15} name={tr(`CMRDetail.instrPaymentForCarriage`, `instrPaymentForCarriage`)} editable={true}>
                      <strong>{doc.instrPaymentForCarriage}</strong>
                    </NumberedBox>
                    <NumberedBox no={21} name={tr(`CMRDetail.estabilished`, `Estabilished`)} editable={true}>
                      <strong>{[doc.establishedAt, doc.establishedIn].filter(t => t != null).join(', ')}</strong>
                    </NumberedBox>
                    <NumberedBox no={25} name={tr(`CMRDetail.carPlate`, `Car plate`)} editable={true}>
                      <strong>{doc.carPlates}</strong>
                    </NumberedBox>
                    <NumberedBox no={26} name={tr(`CMRDetail.totalWeight`, `Total weight`)} editable={true}>
                      {doc.weight && <strong>{formatWeight(doc.weight)}</strong>}
                    </NumberedBox>
                    <TwoColumns>
                      <Col>
                        <NumberedBox no={27} name={tr(`CMRDetail.dzvvNumber`, `dzvvNumber`)} editable={true}>
                          <strong>{doc.dzvvNumber}</strong>
                        </NumberedBox>
                      </Col>
                      <Col>
                        <NumberedBox no={28} name={tr(`CMRDetail.rideNumber`, `rideNumber`)} editable={true}>
                          <strong>{doc.rideNumber}</strong>
                        </NumberedBox>
                      </Col>
                    </TwoColumns>
                    <NumberedBox no={29} name={tr(`CMRDetail.borderCrossings`, `borderCrossings`)} editable={true}>
                      <strong>{doc.borderCrossings}</strong>
                    </NumberedBox>
                    <NumberedBox no={30} name={tr(`CMRDetail.accompanyingDocuments`, `accompanyingDocuments`)} editable={true}>
                      <strong>{doc.accompanyingDocuments}</strong>
                    </NumberedBox>
                    <NumberedBox no={31} name={tr(`CMRDetail.variousNote`, `variousNote`)} editable={true}>
                      <strong>{doc.variousNote}</strong>
                    </NumberedBox>
                  </Col>
                  <Col>
                    <NumberedBox no={19} name={tr(`CMRDetail.extraCosts`, `Extra costs`)} editable={true}></NumberedBox>
                    <NumberedBox no={19} name={tr(`CMRDetail.extraCosts`, `Extra costs`)} editable={true}>
                      {doc.specialAgreements}
                    </NumberedBox>
                  </Col>
                </TwoColumns>
              </CMR>
            );
          })}
        </CMRWrapper>
      </Card>
    </>
  );
};

interface Props {
  no: number;
  name: string;
  editable: boolean;
  useMargin?: boolean;
}

const NumberedBox: React.FC<PropsWithChildren<Props>> = ({ no, name, editable, children, useMargin = true }) => {
  return (
    <>
      <NumberedBoxWrapper withMargin={true}>
        <RowWithSpaceBetween>
          <NumberAndText>
            <> {no && <Number>{no}</Number>}</>
            <Text>{name}</Text>
          </NumberAndText>
          {/*<EditButton label={'Edit'} onClick={() => {}} />*/}
        </RowWithSpaceBetween>
        <div>{children}</div>
      </NumberedBoxWrapper>
    </>
  );
};

const ColWithSpaceBetween = styled.div`
  justify-content: space-between;
  background-color: var(--ringil-base-quaternarycolor);
  padding: 1rem;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const NumberAndText = styled.div`
  display: flex;
  gap: 1rem;
  font-weight: 700;
`;

const Text = styled.div`
  display: flex;
`;

const Number = styled.div`
  display: flex;
  flex-grow: 10;
`;

const NumberedBoxWrapper = styled.div<{ withMargin: boolean }>`
  display: flex;
  justify-content: space-between;
  ${props =>
    props.withMargin &&
    css`
      margin: 2rem 0 0 0;
    `}
  min-height: 70px;
  flex-direction: column;
`;

const TwoColumns = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const CMR = styled.article`
  display: flex;
  flex-direction: column;
`;

const CMRWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Links = styled.div`
  display: flex;
  gap: 1rem;
`;

const Buttons = styled.div`
  display: flex;
  gap: 1rem;
`;

const NavigationBar = styled.div`
  top: 75px;
  position: sticky;
  display: flex;
  background-color: white;
  padding: 1rem 0.3rem;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
`;

export default CMRDetail;
