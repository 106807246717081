import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { InputSwitch } from 'primereact/inputswitch';
import { Label } from '@components/styles.tsx';

interface ComponentProps {
  stackable: boolean;
  onChange: (stackable: boolean) => void;
}

const InputStackable: React.FC<ComponentProps> = ({ stackable, onChange }) => {
  const { tr } = useTranslationLgs();
  return (
    <Grid>
      <InputSwitch checked={stackable} onChange={e => onChange(e.target.value)} />
      <Label>{tr(`InputStackable.stackable`, `Stackable`)}</Label>
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5rem;

  div.p-inputswitch-checked .p-inputswitch-slider,
  div.p-inputswitch-checked .p-inputswitch-focus,
  div.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: var(--ringil3-brand-color);
  }
`;

export default InputStackable;
