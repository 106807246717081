import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import Demands from '@app/pages/carrier/demand-list/Demands.tsx';

const PageDashboard = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`Dashboard.allDemands`, `All demands`)}>
      {/*// @ts-ignore*/}
      <Card>
        <Demands />
      </Card>
    </Layout>
  );
};
export default withAuthenticationRequired(PageDashboard);
