import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tag/ButtonTag.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { findValidationMessage, hasValidationProblemForPrefix } from '@utils/validation.tsx';
import SvgFlag from '@icons/Flag.tsx';
import { Dropdown } from 'primereact/dropdown';
import useForm from '@hooks/useForm/useForm.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import SvgGlobe from '@icons/Globe.tsx';

export type CustomsAreaEnum = 'EU' | 'NONEU';

export interface CustomsAreaData {
  status: CustomsAreaEnum;
}

interface ComponentProps {
  value: CustomsAreaEnum; // comma separated values
  onChange: (value: CustomsAreaEnum) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

export const CustomsTagValidationSchema = () => {
  return yup.object().shape({});
};

const CustomsAreaTag: React.FC<ComponentProps> = ({ value, onChange, readonly, validationPrefix, validationErrors }) => {
  const { tr } = useTranslationLgs();

  const options: CustomsAreaEnum[] = ['EU', 'NONEU'];

  const form = useForm<CustomsAreaData>(
    CustomsTagValidationSchema(),
    { status: value },
    (d, onSuccess) => {
      if (isNotNullOrUndefined(onSuccess)) {
        onSuccess();
      }
      onChange(d.status);
    },
    false,
    false
  );

  const mapToText = (value: CustomsAreaEnum): string => {
    return tr(`CustomsAreaTag.${value}`, `${value}`);
  };

  const printValue = (value: CustomsAreaEnum) => {
    if (value == null) return null;
    return tr(`CustomsAreaTag.${value}`, `${value}`);
  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  return (
    <>
      <ButtonTag
        label={tr("CustomsAreaTag.customsArea", "Customs area")}
        icon={<SvgGlobe />}
        value={printValue(value)}
        readonly={readonly}
        errorMessage={hasError ? tr(`CustomsAreaTag.validationError`, `Validation error`) : undefined}
        onSave={(onSuccess) => {
          form.validateAndSendWithSuccess(onSuccess);
        }}>
        <>
          <Field label={tr("CustomsAreaTag.status", "Status")} errorMessage={findValidationMessage(validationErrors, 'status', tr)}>
            <Dropdown
              value={form.values?.status}
              options={options.map((t) => ({ label: mapToText(t), value: t }))}
              onChange={(e) => form.setFieldValue(form.names.status, e.value)} />

          </Field>
        </>
      </ButtonTag>
    </>);

};

export default CustomsAreaTag;