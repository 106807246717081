import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { InputText } from 'primereact/inputtext';
import useForm from '@hooks/useForm/useForm';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  onCompleted: () => void;
  onCancel: () => void;
}

interface Form {
  groupDemandName: string;
  groupDemandId: string;
}

const useCreateGDNameDialog = () => {
  const [groupDemand, setGroupDemand] = useState<Form>();

  const ChangeGDNameDialog: React.FC<ComponentProps> = ({ onCompleted, onCancel }) => {
    const { tr } = useTranslationLgs();
    const { changeGDName } = useContext(ApiContext);

    useEffect(() => {
      if (isNotNullOrUndefined(groupDemand)) {
        form.setValues(groupDemand);
      }
    }, [groupDemand]);

    if (isNullOrUndefined(groupDemand)) return <></>;

    const form = useForm<Form>(
      yup.object().shape({
        groupDemandName: yup.string().required(tr(`validation.required`, 'Value is required')),
      }),
      { groupDemandName: '', groupDemandId: '' },
      values => save(values),
      false,
      false,
    );

    const save = (values: Form) => {
      // @ts-ignore
      changeGDName(groupDemand.groupDemandId, { name: values.groupDemandName }, () => {
        setGroupDemand(null);
        onCompleted();
      });
    };

    return (
      <>
        <RingilDialog
          visible={isNotNullOrUndefined(groupDemand)}
          headerText={tr(`ChangeGDNameDialog.editGroupDemandName`, `Edit group demand name`)}
          onHide={() => {
            setGroupDemand(null);
            onCancel();
          }}
          onSave={() => form.validateAndSend()}
        >
          <HorizontalFieldValue
            label={tr(`ChangeGDNameDialog.groupDemandName`, `Group demand name`)}
            value={<InputText value={form.values.groupDemandName} onChange={e => form.setFieldValue(form.names.groupDemandName, e.target.value)} />}
            validationError={form.errors.groupDemandName}
          />
        </RingilDialog>
      </>
    );
  };
  return { setGroupDemand, ChangeGDNameDialog };
};

export default useCreateGDNameDialog;
