import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import ManageReportSettingDialog from './ManageReportSettingDialog';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { ManageReportsAdminReportsResponse } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { CenteredButtonRow, RingilH2 } from '@components/styles.tsx';

interface ComponentProps {
  customerId: string;
}

const ManageReports: React.FC<ComponentProps> = ({ customerId }) => {
  const { getReports } = useContext(ApiContext);
  const [reports, setReports] = useState<ManageReportsAdminReportsResponse[]>();

  useEffect(() => {
    load();
  }, [customerId]);

  const load = () => {
    getReports(customerId, d => {
      setReports(d);
    });
  };
  const ReportDefinition: React.FC<Props> = ({ reportDefinition }) => {
    const [selectedReportDefinition, setSelectedReportDefinition] = useState<ManageReportsAdminReportsResponse>();
    const { tr } = useTranslationLgs();

    const header = <img src={`/images/reports/${reportDefinition.reportImage}`} width={'100%`'} />;
    const footer = (
      <CenteredButtonRow>
        {isNullOrUndefined(reportDefinition.companySettings) && (
          <Button
            label={tr(`ManageReports.addToCompanyCollection`, `Add to company collection`)}
            icon="pi pi-check"
            className=""
            onClick={e => setSelectedReportDefinition(reportDefinition)}
          />
        )}
        {isNotNullOrUndefined(reportDefinition.companySettings) && (
          <Button
            label={tr(`ManageReports.manageAccess`, `Manage access`)}
            icon="pi pi-users"
            className=""
            onClick={e => setSelectedReportDefinition(reportDefinition)}
          />
        )}
      </CenteredButtonRow>
    );

    return (
      <>
        <>
          {/*
        // @ts-ignore*/}
          <Card title={reportDefinition.reportName} style={{ width: '25em' }} footer={footer} header={header}>
            <p className="p-m-0" style={{ lineHeight: '1.5' }}>
              {reportDefinition.reportDescription}
            </p>
          </Card>
        </>
        <ManageReportSettingDialog
          onComplete={() => {
            load();
            setSelectedReportDefinition(null);
          }}
          onHide={() => setSelectedReportDefinition(null)}
          selectedDefinition={selectedReportDefinition}
          companyId={customerId}
        />
      </>
    );
  };

  return (
    <>
      <RingilH2>Report settings</RingilH2>
      <ReportRow>
        {reports?.map(r => {
          return <ReportDefinition reportDefinition={r} />;
        })}
      </ReportRow>
    </>
  );
};

interface Props {
  reportDefinition: ManageReportsAdminReportsResponse;
}

const ReportRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`;

export default ManageReports;
