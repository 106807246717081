import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
}

export const OnIssueOrdersDialog: React.FC<ComponentProps> = ({ visible, onHide }) => {
  const { t } = useTranslation('common');
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog className="modal-sm" header={t(`OnIssueOrdersDialog.enableIssueOfOrders`, `Enable issue of orders`)} visible={visible} onHide={onHide}>
        <div>
          <p className="p-mt-0">
            {t(
              `OnIssueOrdersDialog.byEnablingIssuingOfOrders`,
              `By enabling issuing of orders, Logsteo will automatically generate orders of your expeditions immediately as
            their stage is Ready to shipment.`,
            )}
          </p>
        </div>
        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          <Button label={t(`OnIssueOrdersDialog.enable`, `Enable`)} className="p-mr-2 p-button-danger" onClick={onHide} />
          <Button label={t(`OnIssueOrdersDialog.cancel`, `Cancel`)} className="p-button-text" onClick={onHide} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};
