import React, { useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { isNullOrUndefined } from '@utils/utils';
import { WeightModeEnum } from '@api/logsteo-api.v2';
import { weightModes } from '../types';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  className?: any;
  weight: number;
  weightMode: WeightModeEnum;
  onChangeMode: (value: WeightModeEnum) => void;
  onChangeWeight: (value: number) => void;
  weightError?: string;
}

export const WeightSelector: React.FC<ComponentProps> = ({ className, weight, weightMode, onChangeMode, onChangeWeight, weightError }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (isNullOrUndefined(weightMode)) onChangeMode(WeightModeEnum.SKU_WEIGHT);
  }, []);

  return (
    <div className="weight-selector-wrapper p-field p-col-12 p-md-3">
      <Label title={t(`loadDetailsPage.panelBooking.labelWeight`)} required />
      <div className="p-d-flex p-ai-center">
        <InputNumber
          value={weight}
          onChange={e => {
            const targetValue = e.value;
            onChangeWeight(isNaN(targetValue) ? null : targetValue);
          }}
          placeholder="kg"
          style={{ minWidth: '70px' }}
          minFractionDigits={0}
          maxFractionDigits={2}
          locale={'cs'}
        />

        <Dropdown
          value={weightMode}
          options={weightModes}
          // @ts-ignore
          onChange={e => onChangeMode(WeightModeEnum[e.target.value])}
          optionLabel="label"
          dataKey={'value'}
        />
      </div>
      {weightError && <div className="p-invalid-feedback">{weightError}</div>}
    </div>
  );
};
