import { useContext, useEffect, useState } from 'react';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useAdditionalCosts = () => {
  const [additionalCosts, setAdditionalCosts] = useState<LovWithoutIdDTO[]>();
  const { lovExtraCosts } = useContext(ApiContext); // LovDTO

  useEffect(() => {
    lovExtraCosts(data => setAdditionalCosts(data));
  }, []);

  return additionalCosts;
};

export default useAdditionalCosts;
