import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { InvoiceRebillingTypeEnum, InvoiceStateEnum, MassActionExpeditionInternalInformationInDTO } from '@api/logsteo-api.v2';
import { FormikErrors } from '@hooks/useForm/useForm';
import HorizontalFieldValue from '../../carriers/HorizontalFieldValue';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';
import InputResponsiblePersonPlain from '@components/obsolete/Form/InputResponsiblePerson/InputResponsiblePersonPlain.tsx';
import InputSubscriberPlain from '@components/obsolete/Form/InputSubscriberPlain/InputSubscriberPlain.tsx';
import InputDepartmentPlain from '@components/obsolete/Form/InputDepartmentPlain.tsx';
import InputPartnerPlain from '@components/obsolete/Form/InputPartner/InputPartnerPlain.tsx';

interface ComponentProps {
  setFieldValue: (fieldName: string, value: any) => void;
  values: InternetInformationFormData;
  errors: FormikErrors<InternetInformationFormData>;
}

export interface InternetInformationFormData extends MassActionExpeditionInternalInformationInDTO {}

export const internalInfoPanelValidationSchema = yup.object().shape({
  invoiceDepartment: yup.mixed().when('invoiceRebillingType', {
    is: InvoiceRebillingTypeEnum.INTERNAL_REBILLING,
    then: yup.mixed().required(),
    otherwise: yup.mixed().nullable(),
  }),
});

export const internalInfoPanelInitialData: InternetInformationFormData = {
  invoiceUserId: null,
  invoiceDepartment: '',
  invoiceRebillingType: null,
  invoiceState: null,
  subscribersIds: [],
  invoicePartnerId: null,
  responsiblePersonId: null,
  internalNote: null,
  overwriteInternalNote: false,
  overwriteInvoiceRebillingType: false,
  overwriteInvoiceState: false,
  overwriteInvoiceUserId: false,
  overwriteResponsiblePersonId: false,
  overwriteSubscribersIds: false,
};

export const InternalInfoPanelForm: React.FC<ComponentProps> = ({ setFieldValue, values, errors }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <HorizontalFieldValue
        label={
          <Row>
            <CheckboxWithLabel
              checkboxLabel={tr(`UpdateExpeditionInternalInformationSidebar.responsiblePerson`, `Responsible person`)}
              value={values.overwriteResponsiblePersonId}
              onChange={v => setFieldValue('overwriteResponsiblePersonId', v)}
              disabled={false}
            />
          </Row>
        }
        value={
          <Row>
            <InputResponsiblePersonPlain
              responsiblePersonId={values.responsiblePersonId}
              onChange={v => setFieldValue('responsiblePersonId', v)}
              disabled={!values.overwriteResponsiblePersonId}
            />
          </Row>
        }
      />

      <HorizontalFieldValue
        label={
          <Row>
            <CheckboxWithLabel
              checkboxLabel={tr(`UpdateExpeditionInternalInformationSidebar.subscribers`, `Subscribers`)}
              value={values.overwriteSubscribersIds}
              onChange={v => setFieldValue('overwriteSubscribersIds', v)}
              disabled={false}
            />
          </Row>
        }
        value={
          <InputSubscriberPlain
            subscribers={values.subscribersIds.map(t => ({
              id: t,
              name: '',
              active: true,
            }))}
            onChange={v =>
              setFieldValue(
                'subscribersIds',
                v.map(t => t.id),
              )
            }
            disabled={!values.overwriteSubscribersIds}
          />
        }
      />
      <HorizontalFieldValue
        label={
          <Row>
            <CheckboxWithLabel
              checkboxLabel={tr(`UpdateExpeditionInternalInformationSidebar.note`, `Note`)}
              value={values.overwriteInternalNote}
              onChange={v => setFieldValue('overwriteInternalNote', v)}
              disabled={false}
            />
          </Row>
        }
        value={
          <InputTextarea
            value={values.internalNote}
            onChange={e => setFieldValue('internalNote', e.target.value)}
            style={{ width: '100%', height: ' 7rem' }}
            disabled={!values.overwriteInternalNote}
          />
        }
      />
      <HorizontalFieldValue
        label={
          <Row>
            <CheckboxWithLabel
              checkboxLabel={tr(`UpdateExpeditionInternalInformationSidebar.reaccountTo`, `Reaccount to`)}
              value={values.overwriteInvoiceRebillingType}
              onChange={v => setFieldValue('overwriteInvoiceRebillingType', v)}
              disabled={false}
            />
          </Row>
        }
        value={
          <>
            <Dropdown
              value={values.invoiceRebillingType}
              style={{ width: '100%' }}
              options={[
                InvoiceRebillingTypeEnum.NON_REBILLING,
                InvoiceRebillingTypeEnum.INTERNAL_REBILLING,
                InvoiceRebillingTypeEnum.EXTERNAL_REBILLING,
                InvoiceRebillingTypeEnum.OTHER,
              ]}
              showClear={true}
              valueTemplate={e => <div>{tr(`UpdateExpeditionInternalInformationSidebar.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
              itemTemplate={e => <div>{tr(`UpdateExpeditionInternalInformationSidebar.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
              onChange={e => setFieldValue('invoiceRebillingType', e.target.value)}
              disabled={!values.overwriteInvoiceRebillingType}
            />
          </>
        }
      />

      {values.invoiceRebillingType === InvoiceRebillingTypeEnum.INTERNAL_REBILLING && (
        <HorizontalFieldValue
          label={tr(`UpdateExpeditionInternalInformationSidebar.internalDepartment`, `Internal department`)}
          validationError={errors.invoiceDepartment}
          value={
            <>
              <InputDepartmentPlain
                department={values.invoiceDepartment}
                style={{ width: '100%' }}
                onChange={v => setFieldValue('invoiceDepartment', v)}
                disabled={!values.overwriteInvoiceRebillingType}
              />
            </>
          }
        />
      )}
      {values.invoiceRebillingType === InvoiceRebillingTypeEnum.EXTERNAL_REBILLING && (
        <HorizontalFieldValue
          label={tr(`UpdateExpeditionInternalInformationSidebar.externalPartner`, `External partner`)}
          value={
            <>
              <InputPartnerPlain
                style={{ width: '100%' }}
                partnerId={values.invoicePartnerId}
                onChange={v => setFieldValue('invoicePartnerId', v)}
                disabled={!values.overwriteInvoiceRebillingType}
              />
            </>
          }
        />
      )}
      <HorizontalFieldValue
        label={
          <Row>
            <CheckboxWithLabel
              checkboxLabel={tr(`UpdateExpeditionInternalInformationSidebar.invoiceUser`, `Invoice user`)}
              value={values.overwriteInvoiceUserId}
              onChange={v => setFieldValue('overwriteInvoiceUserId', v)}
              disabled={false}
            />
          </Row>
        }
        value={
          <InputResponsiblePersonPlain
            responsiblePersonId={values.invoiceUserId}
            style={{ width: '100%' }}
            onChange={v => setFieldValue('invoiceUserId', v)}
            disabled={!values.overwriteInvoiceUserId}
          />
        }
      />
      <HorizontalFieldValue
        label={
          <Row>
            <CheckboxWithLabel
              checkboxLabel={tr(`UpdateExpeditionInternalInformationSidebar.invoicingState`, `Invoicing state`)}
              value={values.overwriteInvoiceState}
              onChange={v => setFieldValue('overwriteInvoiceState', v)}
              disabled={false}
            />
          </Row>
        }
        value={
          <Dropdown
            value={values.invoiceState}
            options={[InvoiceStateEnum.NON_INVOICED, InvoiceStateEnum.INVOICED]}
            showClear={true}
            style={{ width: '100%' }}
            valueTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceStateEnum.${e}`, e)}</div>}
            itemTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceStateEnum.${e}`, e)}</div>}
            onChange={e => setFieldValue('invoiceState', e.target.value)}
            disabled={!values.overwriteInvoiceState}
          />
        }
      />
    </>
  );
};

const Row = styled.div`
  display: flex;
  gap: 0.2rem;
`;

const Note = styled.div`
  display: flex;
  color: #6c757d;
  font-size: small;
`;

// #ffface
