import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import AdminLayout from '@app/layout/Layout.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';
import { ListReservations } from '@app/pages/reservations/ListReservations.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Intro from '@app/layout/Intro.tsx';
import CamsDetail from '@app/pages/cams/CamsDetail.tsx';
import CamsSettings from '@app/pages/cams/CamsSettings.tsx';

interface ComponentProps {}

const CamsSettingsPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <AdminLayout
        title={tr(`CamsSettingsPage.title`, `Nastavení kamer`)}
        oneOfPermission={[PermissionTypesEnum.PERMISSION_CAM_ENTRYLOG, PermissionTypesEnum.PERMISSION_CAM_SETTINGS]}>
        <CamsSettings />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(CamsSettingsPage, {
  onRedirecting: () => <Intro />,
});
