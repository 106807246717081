import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { ReservationCoordinates } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { InputText } from 'primereact/inputtext';
import { ColWithGap, RowWithGap, RowWithSpaceBetween } from '@components/styles';
import { HorizontalLine, InputAndButtonGrid } from '@components/ringil3/styles';
import DeleteButton from '@components/ringil3/Buttons/DeleteButton';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge';
import LinkButton from '@components/ringil3/Buttons/LinkButton';
import { InputTextarea } from 'primereact/inputtextarea';
import { SendIcon } from '@components/ringil3/icons';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { findValidationMessage } from '@utils/validation.tsx';

interface ComponentProps {
  onContinue: (reservation: ReservationCoordinates) => void;
  applicationId: string;
  companyOwnerId: string;
  locationId: string;
}

interface AskForCarrierReservationForm {
  emails: string[];
  note: string;
}

export const useCreateAskForCarrierReservation = () => {
  const [visible, setVisible] = useState(false);

  const AskForCarrierReservation: React.FC<ComponentProps> = ({ onContinue, applicationId }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { sendExpeditionTimeslotReminder } = useContext(ApiContext);

    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const schema = yup.object().shape({
      emails: yup.array().min(1).of(yup.string().email().required()),
    });

    const form = useForm<AskForCarrierReservationForm>(
      schema,
      {
        emails: [''],
        note: null,
      },
      d => save(d),
      false,
      false,
      null,
      'scroll',
    );

    useEffect(() => {
      const ab = new AbortController();
    }, []);

    const save = (data: AskForCarrierReservationForm) => {
      //sendExpeditionTimeslotReminder();
    };

    const addEmail = () => {
      form.setFieldValue(form.names.emails, form.values.emails ? [...form.values.emails, ''] : ['']);
    };

    const deleteEmail = (index: number) => {
      form.setFieldValue(
        form.names.emails,
        form.values.emails.filter((_, i) => i !== index),
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          onContinue={() => form.validateAndSend()}
          heading={
            <RowWithSpaceBetween>
              <div>{tr(`AskForCarrierReservation.heading`, `Ask the carrier for a reservation`)}</div>
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map(t => t.path)).size} />
            </RowWithSpaceBetween>
          }
          continueLabel={
            <RowWithGap>
              <SendIcon />
              <div>{tr(`AskForCarrierReservation.send`, `Send`)}</div>
            </RowWithGap>
          }
        >
          <BackendValidationComponent />
          <ColWithGap>
            <VerticalFieldAndValueWithIcon
              label={tr(`AskForCarrierReservation.forExpedition`, `For expedition`)}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />
            <HorizontalLine variant={'quaternary'} />
            {form.values.emails?.map((email, index) => {
              return (
                <VerticalFieldAndValue
                  key={index}
                  label={tr(`AskForCarrierReservation.toWhom`, `Whom`)}
                  labelMode={'normal'}
                  errorMessage={findValidationMessage(form.validationErrors, `emails[${index}]`, tr)}
                  required={true}
                >
                  <InputAndButtonGrid key={index}>
                    <InputText
                      value={email}
                      style={{ width: '100%' }}
                      onChange={e => form.setFieldValue(`emails[${index}]`, e.target.value)}
                      placeholder={tr('AskForCarrierReservation.email', 'Carrier e-mail')}
                    />
                    {form.values.emails.length > 1 && (
                      <RowWithGap>
                        <DeleteButton onClick={() => deleteEmail(index)} />
                      </RowWithGap>
                    )}
                  </InputAndButtonGrid>
                </VerticalFieldAndValue>
              );
            })}
            <LinkButton label={tr('AskForCarrierReservation.addEmail', 'Carrier')} onClick={() => addEmail()} leftIcon={'/images/icons/ringil3/tagPlus.svg'} />
            <VerticalFieldAndValue labelMode={'normal'} label={tr('AskForCarrierReservation.messageNote', 'Note')}>
              <InputTextarea
                value={form.values.note}
                rows={4}
                placeholder={tr('AskForCarrierReservation.messageNote', 'Note text')}
                onChange={e => form.setFieldValue(form.names.note, e.target.value)}
              />
            </VerticalFieldAndValue>
          </ColWithGap>
        </RingilSidebar>
      </>
    );
  };

  return { AskForCarrierReservation, setVisible, visible };
};
