import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import Layout from '@app/layout/Layout.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const useCreateTable = <T,>() => {
  type ColumnProps = {
    title: string;
    render: (row: T) => React.ReactNode;
    fixed?: boolean;
    width?: number; // Šířka v pixelech
  };

  const Column: React.FC<ColumnProps> = ({ title, render, fixed, width }) => null;

  type TableProps = {
    data: T[];
    children: React.ReactElement<ColumnProps> | React.ReactElement<ColumnProps>[];
  };

  const Table: React.FC<TableProps> = ({ data, children }) => {
    const columns = React.Children.toArray(children) as React.ReactElement<ColumnProps>[];

    // Výpočet kumulativní šířky pro pevné sloupce
    const fixedColumns: { index: number; left: number }[] = [];
    let cumulativeWidth = 0;

    columns.forEach((column, index) => {
      const colWidth = column.props.width || 0;

      if (column.props.fixed) {
        fixedColumns.push({ index, left: cumulativeWidth });
      }

      cumulativeWidth += colWidth;
    });

    return (
      <div>
        <div className="overflow-auto">
          <table className="relative table-fixed w-full">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  const isFixed = column.props.fixed;
                  const fixedColumn = fixedColumns.find(col => col.index === index);

                  const thClasses = ['px-4 py-2 w-0', isFixed ? 'sticky left-0 bg-white z-10' : ''].join(' ');

                  const thStyle = {
                    ...(isFixed ? { left: `${fixedColumn?.left}px` } : {}),
                    width: column.props.width ? `${column.props.width}px` : 'auto',
                    minWidth: column.props.width ? `${column.props.width}px` : 'auto',
                    maxWidth: column.props.width ? `${column.props.width}px` : 'auto',
                  };

                  return (
                    <th key={index} className={thClasses} style={thStyle}>
                      {column.props.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => {
                const isLastRow = rowIndex === data.length - 1;
                return (
                  <tr key={rowIndex} className="bg-white">
                    {columns.map((column, colIndex) => {
                      const isFixed = column.props.fixed;
                      const fixedColumn = fixedColumns.find(col => col.index === colIndex);
                      const isFirstCol = colIndex === 0;
                      const isLastCol = colIndex === columns.length - 1;

                      let tdClasses = 'py-8 px-4 w-0';
                      tdClasses += isFixed ? ' sticky left-0 bg-white z-10' : '';

                      // Přidání zaoblení rohů u posledního řádku
                      if (isLastRow) {
                        if (isFirstCol) {
                          tdClasses += ' rounded-bl-[10px]';
                        }
                        if (isLastCol) {
                          tdClasses += ' rounded-br-[10px]';
                        }
                      }

                      const tdStyle = {
                        ...(isFixed ? { left: `${fixedColumn?.left}px` } : {}),
                        width: column.props.width ? `${column.props.width}px` : 'auto',
                        minWidth: column.props.width ? `${column.props.width}px` : 'auto',
                        maxWidth: column.props.width ? `${column.props.width}px` : 'auto',
                      };

                      return (
                        <td key={colIndex} className={tdClasses} style={tdStyle}>
                          {column.props.render(row)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return { Table, Column };
};

type Person = {
  id: number;
  name: string;
  age: number;
  email: string;
};

const table: React.FC = () => {
  const { tr } = useTranslationLgs();
  const { Table, Column } = useCreateTable<Person>();

  const data: Person[] = [
    { id: 1, name: 'Jan', age: 25, email: 'jan@example.com' },
    { id: 2, name: 'Petr', age: 30, email: 'petr@example.com' },
    // ... další data
  ];

  return (
    <Layout>
      <Table data={data}>
        <Column title="ID" fixed width={50} render={row => <span>{row.id}</span>} />
        <Column title="Jméno" fixed width={200} render={row => <span>{row.name}</span>} />
        <Column title="Věk" fixed width={100} render={row => <span>{row.age}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        <Column title="Email" width={200} render={row => <span>{row.email}</span>} />
        {/* ... další sloupce */}
      </Table>
    </Layout>
  );
};

export default withAuthenticationRequired(table);
