import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import { MapDataLastTruckPosition, MapDataOutDTO } from '@api/logsteo-api.v2.tsx';
import { decode } from '@components/features/HereMap/hereFlexPolyLine.ts';

interface ComponentProps {
  customerId: string;
  applicationId: string;
}

const HereMaps: React.FC<ComponentProps> = ({ customerId, applicationId }) => {
  const { getMapDataForExpedition, getMapLastTruckPosition } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const mapRef = React.createRef<HTMLDivElement>();
  const [map, setMap] = useState(undefined);

  const addPolyLineToMap = (H: any, hMap: any, polyLineString: any) => {
    if (hMap == null) return;
    const lineString = new H.geo.LineString();
    const polyLine = decode(polyLineString).polyline.map(el => ({ lat: el[0], lng: el[1] }));

    polyLine.forEach(point => lineString.pushPoint(point));
    hMap.addObject(new H.map.Polyline(lineString, { style: { lineWidth: 4 } }));
  };

  const addMarkerToMap = (H: any, hMap: any, location: { lat: number; lng: number }) => {
    if (hMap == null) return;
    const group = new H.map.Group();
    if (group == null) return;
    const markersArray = [];

    const markup = createCar();
    const icon = new H.map.Icon(markup);
    const marker = new H.map.Marker(location, { icon });
    markersArray.push(marker);

    //Focus all markers in map
    group.addObjects(markersArray);
    hMap.addObject(group);
  };

  const addMarkersToMap = (H: any, hMap: any, locations: { lat: number; lng: number }[]) => {
    const group = new H.map.Group();
    // @ts-ignore
    const markersArray = [];
    locations.forEach((location, index) => {
      // @ts-ignore
      const markup = createMarker(index + 1);
      const icon = new H.map.Icon(markup);
      const marker = new H.map.Marker(location, { icon });
      markersArray.push(marker);
    });
    //Focus all markers in map
    // @ts-ignore
    group.addObjects(markersArray);
    hMap.addObject(group);

    hMap.getViewModel().setLookAtData({
      bounds: group.getBoundingBox(),
    });
  };

  const createMarker = (text: string) =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="28px" height="36px">
    <path
      d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z"
      fill="#000"
      fillOpacity=".2"
    />
    <path
      d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z"
      fill="#fff"
    />
    <path
      d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z"
      fill="#18d"
    />
    <text x="9" y="19" fontSize="12pt" fontWeight="bold" textAnchor="middle" fill="#fff">
      ${text}
    </text>
  </svg>`;

  const createCar = () =>
    `<svg width="40" height="28" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.720588 0C0.324908 0 0 0.324908 0 0.720588V10.8088C0 11.2045 0.324908 11.5294 0.720588 11.5294H2.47059C2.47059 12.8934 3.57721 14 4.94118 14C6.30515 14 7.41177 12.8934 7.41177 11.5294H9.98529C10.381 11.5294 10.7059 11.2045 10.7059 10.8088V0.720588C10.7059 0.324908 10.381 0 9.98529 0H0.720588ZM12.0699 1.64706C11.7739 1.64706 11.5294 1.88833 11.5294 2.1875V10.989C11.5294 11.2109 11.671 11.3975 11.864 11.4779C11.9283 11.5037 11.9959 11.5294 12.0699 11.5294H13.1765C13.1765 12.8934 14.2831 14 15.6471 14C17.011 14 18.1176 12.8934 18.1176 11.5294H19.2243C19.2983 11.5294 19.3658 11.5069 19.4301 11.4779C19.5588 11.4233 19.6585 11.3235 19.7132 11.1949C19.7422 11.1305 19.7647 11.063 19.7647 10.989V6.74265C19.7647 6.63649 19.7197 6.54963 19.6618 6.45956L16.625 1.87868C16.5253 1.7307 16.3676 1.64706 16.1875 1.64706H12.0699ZM14 3.29412H16.0846L18.2721 6.58824H14V3.29412ZM4.94118 10.2941C5.62316 10.2941 6.17647 10.8474 6.17647 11.5294C6.17647 12.2114 5.62316 12.7647 4.94118 12.7647C4.25919 12.7647 3.70588 12.2114 3.70588 11.5294C3.70588 10.8474 4.25919 10.2941 4.94118 10.2941ZM15.6471 10.2941C16.329 10.2941 16.8824 10.8474 16.8824 11.5294C16.8824 12.2114 16.329 12.7647 15.6471 12.7647C14.9651 12.7647 14.4118 12.2114 14.4118 11.5294C14.4118 10.8474 14.9651 10.2941 15.6471 10.2941Z" fill="#FE5000"/>
    </svg>`;

  useLayoutEffect(() => {
    // @ts-ignore
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: 'F4w9igF1EWxSSpOAIVLllKcOycY9ahp6OMpqj-XctK4',
    });

    const defaultLayers = platform.createDefaultLayers();

    const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 50, lng: 5 },
      zoom: 4,
      pixelRatio: window.devicePixelRatio || 1,
    });

    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    const ui = H?.ui?.UI?.createDefault(map, defaultLayers);

    setMap(map);
    return () => {
      map.dispose();
    };
  }, []);

  useEffect(() => {
    if (customerId && applicationId && map) {
      getMapDataForExpedition(customerId, applicationId, (data: MapDataOutDTO) => {
        if (data.mapRoutePolyline.length != 0) {
          // @ts-ignore
          data.mapRoutePolyline.forEach(polyLine => addPolyLineToMap(window.H, map, polyLine));
          // @ts-ignore
          addMarkersToMap(window.H, map, data.coordinates);
        }
      });
      getMapLastTruckPosition(customerId, applicationId, (data: MapDataLastTruckPosition) => {
        // @ts-ignore
        if (data != null && data.lastPosition != null) addMarkerToMap(window.H, map, data.lastPosition);
      });
    }
  }, [map]);

  return (
    <>
      <div className="map" ref={mapRef} />
    </>
  );
};

export default HereMaps;
