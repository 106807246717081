import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'primereact/checkbox';

interface ComponentProps {
  value: boolean;
  onChange: (e: any, value: boolean) => void;
  label: string;
  note?: string;
}

export const InputCheckbox: React.FC<ComponentProps> = ({ onChange, value, note, label }) => {
  return (
    <Grid>
      <Checkbox checked={value} onChange={e => onChange(e, e.checked)} />
      <LabelInner>
        <Label>{label}</Label>
        {note && <Note>{note}</Note>}
      </LabelInner>
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
`;

const LabelInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* Content/Primary */
  color: var(--ringil3-content-primary);
`;

const Note = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;

  /* Content/Secondary */
  color: var(--ringil3-content-secondary);
`;
