import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import { LanguageFallbackOverrideOutDTO } from '@api/logsteo-api.v2';
import { Note } from '@components/styles.tsx';

interface DefaultFallbackProps {
  value: string;
  onChange: (value: string) => void;
}
export const DefaultFallback: React.FC<DefaultFallbackProps> = ({ value, onChange }) => {
  const [suggestions, setSuggestions] = useState(['cs', 'sk', 'en']);
  return <Dropdown options={suggestions} value={value} onChange={e => onChange(e.value)}></Dropdown>;
};

interface LanguagesListProps {
  languages: string[];
  onAdd: (language: string) => void;
  onRemove: (language: string) => void;
}
export const LanguagesList: React.FC<LanguagesListProps> = ({ languages, onAdd, onRemove }) => {
  const [suggestions, setSuggestions] = useState([]);
  const { loVLanguages } = useContext(ApiContext);

  useEffect(() => {
    loVLanguages(d => setSuggestions(['cs', 'en', 'de']));
  }, []);

  const inputRef = useRef(undefined);
  const keyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      const languageName = inputRef.current.value;
      if (suggestions.some(t => t === languageName)) onAdd(languageName);
      inputRef.current.value = '';
    }
  };
  return (
    <AutoComplete
      suggestions={suggestions}
      multiple={true}
      inputRef={inputRef}
      value={languages}
      itemTemplate={e => <div>{e.name}</div>}
      onKeyUp={e => keyUp(e)}
      onUnselect={e => {
        onRemove(e.value);
      }}
    ></AutoComplete>
  );
};

interface FallbacksProps {
  fallbacks: LanguageFallbackOverrideOutDTO[];
  onChange: (fallbacks: LanguageFallbackOverrideOutDTO[]) => void;
}
export const Fallbacks: React.FC<FallbacksProps> = ({ fallbacks, onChange }) => {
  const inputRef = useRef(undefined);
  const keyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      const languageName: string = inputRef.current.value;
      const r = /([\w]+)->([\w]+)/;
      const splitted = languageName.match(r);
      if (!isNullOrUndefined(splitted)) {
        const fallback = {
          from: splitted[1],
          to: splitted[2],
        } as LanguageFallbackOverrideOutDTO;
        onChange([...fallbacks, fallback]);
      }
      inputRef.current.value = '';
    }
  };
  return (
    <>
      <AutoComplete
        multiple={true}
        inputRef={inputRef}
        value={fallbacks}
        selectedItemTemplate={(e: LanguageFallbackOverrideOutDTO) => <div>{`${e.from}->${e.to}`}</div>}
        itemTemplate={(e: LanguageFallbackOverrideOutDTO) => <div>{`${e.from}->${e.to}`}</div>}
        onUnselect={e => {
          onChange(fallbacks.filter(t => t !== e.value));
        }}
        onKeyUp={e => keyUp(e)}
      ></AutoComplete>
      <Note>{`Pro zadani fallbacku zadejte jazyk->jazyk a stisknete [Enter]. Napriklad: sk->cz`}</Note>
    </>
  );
};
