import React, { useContext, useEffect, useState } from 'react';
import Label from '@components/obsolete/Form/Label/Label';
import { LoadingTypesEnum, LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  values: LoadingTypesEnum[];
  onChange: (v: LoadingTypesEnum[]) => void;
  errorMessages?: string;
}

export const LoadingTypeSelector: React.FC<ComponentProps> = ({ values, onChange, errorMessages }) => {
  const [loadingTypes, setLoadingTypes] = useState<LovWithoutIdDTO[]>([]);
  const [selectedLoadingTypes, setSelectedLoadingTypes] = useState<LoadingTypesEnum[]>([]);
  const { tr } = useTranslationLgs();
  const { lovLoadingTypes } = useContext(ApiContext);

  useEffect(() => {
    lovLoadingTypes(data => {
      setLoadingTypes(data);
    });
    setSelectedLoadingTypes(values);
  }, []);

  return (
    <>
      <div className="p-field">
        <Label title={tr('loadDetailsPage.panelRequiredHandlingOptions.title', 'Loading types')} />
        <MultiSelect
          value={selectedLoadingTypes}
          options={loadingTypes}
          optionLabel="name"
          optionValue="code"
          onChange={e => {
            setSelectedLoadingTypes(e.value);
            onChange(e.value);
          }}
          className="multiselect-custom"
          placeholder={tr(`loadDetailsPage.panelRequiredHandlingOptions.placeholder`, `Choose loading types`)}
        />
        <div className="p-invalid-feedback">{errorMessages}</div>
      </div>
    </>
  );
};
