import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAvailableDates = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#FE5000"
      d="M15 1H1v2h14zM11 5H1v2h5.528A5.99 5.99 0 0 1 11 5M5.341 13c.264.745.67 1.423 1.187 2H1v-2zM5.341 9A6 6 0 0 0 5 11H1V9zM15 11c0 .742-.202 1.437-.554 2.032l1.511 1.51-1.414 1.415-1.511-1.51A4 4 0 1 1 15 11"
    />
  </svg>
);
export default SvgAvailableDates;
