import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { CenteredButtonRow, Clickable } from '@components/styles';
import { Column } from 'primereact/column';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import SvgMultiArrow from '@components/icons/massaction/MultiArrow.tsx';

interface MassActionCheckboxAndStateProps {
  onSelectAll: () => void;
  disabled?: boolean;
}

const useMassAction = <T,>(equals: (a1: T, a2: T) => boolean, renderButtons: (selectedObjects: T[]) => any, renderDataSelectedItems?: () => Column[]) => {
  const { tr } = useTranslationLgs();
  const [selectedObjects, setSelectedObjects] = useState<T[]>([]);
  const [visibleDialog, setVisibleDialog] = useState(false);

  const toggleCheckbox = (object: T) => {
    const found = selectedObjects.some(t => equals(t, object));
    if (found) setSelectedObjects(selectedObjects.filter(t => !equals(t, object)));
    else setSelectedObjects([...selectedObjects, object]);
  };

  const isSelected = (object: T) => {
    return selectedObjects.some(t => equals(t, object));
  };

  const clear = () => {
    setSelectedObjects([]);
  };

  const MassActionCheckboxAndState: React.FC<MassActionCheckboxAndStateProps> = ({ onSelectAll, disabled = false }) => {
    return (
      <>
        <Wrapper>
          <img src={'/images/multiarrow.svg'} width={20} height={20} />
          <Clickable onClick={onSelectAll}>{tr(`useMassAction.selectAll`, `Select all`)}</Clickable>
          <SelectedWrapper onClick={e => setVisibleDialog(true)}>
            {tr(`useMassAction.selected`, `{{count}} vybranych`, { count: selectedObjects.length }, selectedObjects.length)}
          </SelectedWrapper>
          <Button icon="pi pi-trash" className=" p-button-outlined" onClick={() => clear()} disabled={disabled} />
          <ButtonWrapper>{renderButtons(selectedObjects)}</ButtonWrapper>
        </Wrapper>
        {visibleDialog && renderDataSelectedItems && (
          // @ts-ignore
          <Dialog
            visible={visibleDialog}
            onHide={() => setVisibleDialog(false)}
            header={tr(`useMassAction.prohlednoutVybraneObjekty`, `Prohlednout vybrane objekty`)}
            style={{ width: '600px' }}
          >
            FIXME: DataTable is not imported
            {/*            <DataTable value={selectedObjects}>
              {[...renderDataSelectedItems()]}
            </DataTable>*/}
            <CenteredButtonRow>
              <Button label={tr(`useMassAction.zavrit`, `Zavrit`)} onClick={e => setVisibleDialog(false)} />
            </CenteredButtonRow>
          </Dialog>
        )}
      </>
    );
  };

  return {
    MassActionCheckboxAndState,
    toggleCheckbox,
    isSelected,
    selectedObjects,
    setSelectedObjects,
    clear,
  };
};

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 1rem;
  gap: 0.5rem;
`;

const SelectedWrapper = styled.a`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 1rem 1rem;
  background-color: #f7f6f7;
  gap: 0.5rem;
  align-items: center;
`;

export default useMassAction;
