import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { formatDistance, isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  distance: number;
}

const DistanceBadge: React.FC<ComponentProps> = ({ distance }) => {
  const { tr } = useTranslationLgs();
  return <>{isNotNullOrUndefined(distance) && <Badge filled={false} value={formatDistance(distance)} highlightedValue={false}></Badge>}</>;
};

export default DistanceBadge;
