import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import AddAttachment from './AddAttachment/AddAttachment';
import { Button } from 'primereact/button';
import { dumpVars, mapFromDateToDayjs } from '@utils/utils';
import { Calendar } from 'primereact/calendar';
import { shortDateFormat } from '@utils/date';
import { InputTextarea } from 'primereact/inputtextarea';
import dayjs from 'dayjs';
import { AccessTypeEnum, AttachmentUploadedOutDTO, LovDocumentTypeOutDTO } from '@api/logsteo-api.v2';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ButtonRow } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { ClearButton } from '@components/obsolete/Buttons/ClearButton/ClearButton.tsx';
import { InputDocumentType } from '@components/obsolete/Form/InputDocumentType/InputDocumentType.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';

export interface AttachmentDocument extends Omit<AttachmentUploadedOutDTO, 'documentType' | 'documentTypeName'> {
  documentType: LovDocumentTypeOutDTO;
}

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onCompleted: (documents: AttachmentUploadedOutDTO[]) => void;
}

const UploadAttachmentDialog: React.FC<ComponentProps> = ({ onHide, visible, onCompleted }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const form = useForm<AttachmentDocument[]>(
    yup.array().of(
      yup.object().shape({
        attachmentId: yup.string().required(),
        documentType: yup.object().shape({ documentType: yup.string().required() }),
        accessType: yup.string().required().oneOf(Object.keys(AccessTypeEnum)),
      }),
    ),
    [],
    data => save(data),
    false,
    false,
  );

  const save = (data: AttachmentDocument[]) => {
    onCompleted(
      data.map(t => {
        return {
          ...t,
          documentType: t.documentType.documentType,
          documentTypeName: t.documentType.name,
        } as AttachmentUploadedOutDTO;
      }),
    );
  };

  useEffect(() => {
    if (visible) {
      form.overwriteValues([]);
    }
  }, [visible]);

  const formNames = proxiedPropertiesOf<AttachmentUploadedOutDTO>();

  const formFieldName = (index: number, name: keyof AttachmentUploadedOutDTO) => `[${index}].${name}`;
  const [addedDocuments, setAddedDocuments] = useState([]);
  useEffect(() => {
    form.overwriteValues(addedDocuments);
  }, [addedDocuments]);

  const addDocument = (attachment: AttachmentUploadedOutDTO) => {
    //form.overwriteValues([...form.values, ,]);
    setAddedDocuments(v => [
      ...v,
      {
        attachmentId: attachment.attachmentId,
        fileName: attachment.fileName,
        companyName: attachment.companyName,
        accessType: AccessTypeEnum.ALL,
        contentType: attachment.contentType,
        companyId: attachment.companyId,
        size: attachment.size,
        uploadedAt: attachment.uploadedAt,
        userId: attachment.userId,
        userName: attachment.userName,
        accessTypeName: attachment.accessTypeName,
        canBeDeleted: attachment.canBeDeleted,
        documentType: {
          documentType: attachment.documentType,
          name: attachment.documentTypeName,
        },
      },
    ]);
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog onHide={onHide} header={tr('UploadAttachmentDialog.uploadDocuments', 'Upload documents')} visible={visible} style={{ width: '950px' }}>
        {loggedUser && (
          <>
            {form.values.map((document, docIndex) => {
              return (
                <React.Fragment key={docIndex}>
                  <RR>
                    <RC large={3} medium={12} small={12}>
                      <HorizontalFieldValue
                        label={tr('UploadAttachmentDialog.document', 'Document')}
                        value={
                          <>
                            {document.fileName} <ClearButton onClick={e => form.overwriteValues(form.values.filter((_, index) => index !== docIndex))} />
                          </>
                        }
                      />
                    </RC>
                    <RC large={3} medium={12} small={12}>
                      <HorizontalFieldValue
                        label={tr('UploadAttachmentDialog.documentType', 'Document type')}
                        validationError={form.find(`[${docIndex}].documentType.documentType`)}
                        value={
                          <InputDocumentType
                            documentType={document.documentType?.documentType}
                            onChange={v => {
                              form.setFieldValue(formFieldName(docIndex, 'documentType'), v);
                            }}
                          />
                        }
                      />
                    </RC>
                    <RC large={3} medium={12} small={12}>
                      <HorizontalFieldValue
                        label={tr('UploadAttachmentDialog.internal', 'Internal')}
                        validationError={form.find(formFieldName(docIndex, 'accessType'))}
                        value={
                          <InputYesNoPlain
                            value={document.accessType === AccessTypeEnum.INTERNAL ? true : false}
                            onChange={v => form.setFieldValue(formFieldName(docIndex, 'accessType'), v ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL)}
                          />
                        }
                      />
                    </RC>
                    <RC large={3} medium={12} small={12}>
                      <HorizontalFieldValue
                        label={tr('UploadAttachmentDialog.date', 'Date')}
                        value={
                          <>
                            <Calendar
                              showIcon={true}
                              value={document.userDate ? dayjs(document.userDate).toDate() : null}
                              onChange={e => form.setFieldValue(formFieldName(docIndex, 'userDate'), mapFromDateToDayjs(e.value as Date).toISOString())}
                              dateFormat={shortDateFormat}
                              selectionMode={'single'}
                              style={{ width: '100%' }}
                              locale={'cs'}
                              hideOnDateTimeSelect={true}
                            />
                          </>
                        }
                      />
                    </RC>
                  </RR>
                  <RR>
                    <RC large={12} medium={12} small={12}>
                      <HorizontalFieldValue
                        label={tr(`UploadAttachmentDialog.note`, `Note`)}
                        value={
                          <InputTextarea
                            style={{ width: '100%', height: '100%' }}
                            value={document.userNote}
                            onChange={e => form.setFieldValue(formFieldName(docIndex, 'userNote'), e.target.value)}
                          />
                        }
                      />
                    </RC>
                  </RR>
                </React.Fragment>
              );
            })}
            <ButtonRow>
              <AddAttachment
                onUpload={attachment => {
                  addDocument(attachment);
                }}
              />
            </ButtonRow>
            <ButtonRow>
              <Button label={tr(`UploadAttachmentDialog.save`, `Save`)} disabled={form.values.length == 0} onClick={e => form.validateAndSend()} />
              <Button label={tr(`UploadAttachmentDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
            </ButtonRow>
          </>
        )}
      </Dialog>
    </>
  );
};

export default UploadAttachmentDialog;
