import React, { useContext } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { isNullOrUndefined } from '@utils/utils';
import { Card } from 'primereact/card';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import Layout from '@app/layout/Layout.tsx';
import ManageReports from '@app/pages/customer/reports/ManageReports.tsx';

interface ComponentProps {}

const CustomerAdminReportPage: React.FC<ComponentProps> = () => {
  const { loggedUser } = useContext(AuthenticatedUserContext);
  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <Layout title={'Report administration'}>
      {/*
        // @ts-ignore*/}
      <Card>
        <ManageReports customerId={loggedUser.companyId} />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(CustomerAdminReportPage);
