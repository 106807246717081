import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { MultiSelect } from 'primereact/multiselect';
import { CompanyLocationWithOpeningHours } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  value: string[];
  onChange: (value: string[]) => void;
  style: any;
}

const InputCompanyLocations: React.FC<ComponentProps> = ({ value, onChange, style }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { getLoVCompanyLocations } = useContext(ApiContext);
  const [data, setData] = useState<CompanyLocationWithOpeningHours[]>([]);

  useEffect(() => {
    getLoVCompanyLocations(false, d => setData(d));
  }, []);

  return (
    <MultiSelect
      style={style}
      options={data}
      placeholder={tr(`InputCompanyLocations.allLocations`, `All locations`)}
      optionLabel={'name'}
      optionValue={'id'}
      onChange={e => onChange(e.value)}
      value={value}
      filter={true}
    />
  );
};

export default InputCompanyLocations;
