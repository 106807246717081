import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { enumerate, getTime } from '@utils/utils';
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';
import { dateFormatOnlyDateShort, mapFromAPIDateTime, setTime, shortDateFormat } from '@utils/date';
import dayjs, { Dayjs } from 'dayjs';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import ValidationDiv, { validate } from '@utils/validation';
import { DistributionTypeEnum } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Checkbox } from 'primereact/checkbox';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  distributionType: DistributionTypeEnum;
  validTill: Dayjs;
  price: number;
  currency: string;
  onSave: (data: PriceAndValidityData) => void;
  isPublished: boolean;
  publishedByName?: string;
  publishedAt?: string;
}

export interface PriceAndValidityData {
  distributionType: DistributionTypeEnum;
  validTill: Dayjs;
  price: number;
  currency: string;
  isPublish: boolean;
}

const EditDistributionSidebar: React.FC<ComponentProps> = ({
  visible,
  onHide,
  price,
  distributionType,
  validTill,
  onSave,
  currency,
  isPublished,
  publishedByName,
  publishedAt,
}) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const [state, setState] = useImmer<PriceAndValidityData>({
    price,
    validTill,
    distributionType,
    currency,
    isPublish: isPublished,
  });
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

  const validationScheme = yup.object().shape({
    distributionType: yup.string().required().oneOf(enumerate(DistributionTypeEnum)),
    validTill: yup.object().required(),
    price: yup.number().when('distributionType', {
      is: DistributionTypeEnum.FIXED_PRICE,
      then: yup.number().min(0),
      otherwise: yup.number().nullable(),
    }),
    currency: yup.string().required(),
  });

  const validateAndSend = () => {
    const validationErrors = validate(validationScheme, state);
    setValidationErrors(validationErrors);
    if (validationErrors.length === 0) onSave(state);
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        blockScroll={true}
        closeOnEscape={false}
        className="sidebar-modal"
        style={{ width: '750px' }}>
        <h3>{t(`EditDistributionSidebar.editCoverageRequest`, `Upravit poptavku`)}</h3>
        <p>
          {t(
            `EditDistributionSidebar.inCaseOfAnyChangeAllSelectedCarriersWillBeFirmaNotifiedImmediately`,
            'V případě jakýchkoliv změn budou dopravci okamžite notifikováni.',
          )}
        </p>
        <>
          {state.distributionType === DistributionTypeEnum.SPOT && <Label title={t('expeditionOverviewPage.labelExpectedPrice')} />}
          {state.distributionType === DistributionTypeEnum.FIXED_PRICE && <Label title={t('expeditionOverviewPage.labelFixedPrice')} />}

          <div className="p-formgrid p-grid p-fluid">
            <div className="p-field p-col p-pr-0">
              {/*<InputNumber
                className="p-text-right"
                value={state.price}
                onChange={(e) => {
                  setState((draft) => {
                    draft.price = e.value;
                  });
                }}
                minFractionDigits={0}
                maxFractionDigits={2}
              />
              <ValidationDiv errors={validationErrors} path={'price'} />
            </div>
            <div className="p-field p-pl-0 p-col-fixed" style={{ width: '100px' }}>
              <CurrencyDropdown
                selectedCurrenry={state.currency}
                onSelected={(value) =>
                  setState((draft) => {
                    draft.currency = value;
                  })
                }
              />{' '}*/}
              <InputPriceWithCurrency
                price={state.price}
                currency={state.currency}
                changePrice={v => {
                  setState(draft => {
                    draft.price = v;
                  });
                }}
                changeCurrency={value => {
                  setState(draft => {
                    draft.currency = value;
                  });
                }}
              />
              <ValidationDiv errors={validationErrors} path={'currency'} />
            </div>
          </div>

          <div className="p-formgrid p-grid">
            <div className="p-col-12">
              <Label title={t('expeditionOverviewPage.labelabelDeadlinel')} />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <Calendar
                locale={'cs'}
                dateFormat={shortDateFormat}
                showIcon
                readOnlyInput
                style={{ width: '100%' }}
                value={state.validTill.toDate()}
                onChange={e => {
                  setState(draft => {
                    draft.validTill = dayjs(e.value as Date);
                  });
                }}
              />
              <ValidationDiv errors={validationErrors} path={'validTill'} />
              <div className="p-invalid-feedback">Value is required</div>
            </div>
            <div className="p-field p-col-12 p-md-6">
              <InputMask
                mask={'99:99'}
                value={getTime(state.validTill)}
                onComplete={e =>
                  setState(draft => {
                    draft.validTill = setTime(draft.validTill, e.value);
                  })
                }
                style={{ width: '100%' }}
              />
              <ValidationDiv errors={validationErrors} path={'validTill'} />
              <div className="p-invalid-feedback">Value is required</div>
            </div>
          </div>
          <div className="p-formgrid p-grid">
            <div className="p-col-12">
              <Label title={tr(`EditDistributionSidebar.sendToTheCarrires`, `Send to the carrires`)} />
            </div>
            <div className="p-field p-col-12 p-md-12">
              <>
                {isPublished ? (
                  <div>
                    {tr(`EditDistributionSidebar.allreadySent`, `Sent by {{user}} at {{date}}`, {
                      user: publishedByName,
                      date: dateFormatOnlyDateShort(mapFromAPIDateTime(publishedAt)),
                    })}
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Checkbox
                        checked={state.isPublish}
                        onChange={e =>
                          setState(draft => {
                            draft.isPublish = e.checked;
                          })
                        }
                      />
                      <label>{tr(`EditDistributionSidebar.sendNow`, `Send now`)}</label>
                    </Row>
                    <p>{tr(`EditDistributionSidebar.note1`, `Před odesláním se ujistěte, že zvolený termín pro podaní cenových nabídek je dostačující.`)}</p>
                  </div>
                )}
              </>
            </div>
          </div>
        </>
        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          <Button label={t(`EditDistributionSidebar.continue`, `Continue`)} className="p-mr-2" onClick={() => validateAndSend()} />
          <Button label={t(`EditDistributionSidebar.back`, `Back`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

const Row = styled.div`
  display: flex;
  label {
    margin-left: 15px;
  }
`;

export default EditDistributionSidebar;
