import React, { useEffect, useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { isNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  value: boolean;
  onChange: (stackable: boolean) => void;
  disabled?: boolean;
}

export const Stackable: React.FC<ComponentProps> = ({ value, onChange, disabled = false }) => {
  const { t } = useTranslation('common');
  const values = [
    { name: t('Stackable.yes', 'Ano'), value: true },
    { name: t('Stackable.no', 'Ne'), value: false },
  ];

  useEffect(() => {
    if (isNullOrUndefined(value)) onChange(true);
  }, []);

  return (
    <>
      <SelectButton
        value={value}
        options={values}
        optionLabel={'name'}
        optionValue={'value'}
        onChange={e => {
          onChange(e.value);
        }}
        disabled={disabled}
      />
    </>
  );
};
