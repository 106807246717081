import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { EmailRequestEntity, FindEmailRequestFilter, PageResponseEmailRequestEntity } from '@api/logsteo-api.v2.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { mapFromAPIToDateLong } from '@utils/date.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { dumpVars } from '@utils/utils.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';

interface ComponentProps {}

const FindEmailRequest: React.FC<ComponentProps> = () => {
  const { findEmailRequest, duplicateEmailRequest } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [data, setData] = useState<PageResponseEmailRequestEntity>();
  const [runningApi, setRunningApi] = useState(false);
  const { Table, Column } = useCreateTable<EmailRequestEntity>();
  const { Table: FailedTable, Column: FailedColumn } = useCreateTable<EmailRequestEntity>();
  const { showToastMessage } = useContext(ToastContext);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, names } = useFilter<FindEmailRequestFilter>(
    (page, filter) => {
      load(page.pageSize, page.pageNumber, filter);
    },
    null,
    {},
  );

  const load = (pageSize: number, pageNumber: number, filter: FindEmailRequestFilter) => {
    setRunningApi(true);
    findEmailRequest({ pageNumber, pageSize }, filter, d => {
      setRunningApi(false);
      setData(d);
    });
  };

  const duplicate = (id: string) => {
    duplicateEmailRequest(id, () => {
      showToastMessage('Email byl duplikován', 'Email byl duplikován', 'info');
    });
  };

  return (
    <AdminLayout title={'Email dohled'}>
      <BreadCrumbComponent items={[{ label: 'Emails', url: null }]} />
      <div className={'flex flex-row gap-4'}>
        <RingilInputText value={filter?.emailType} onChange={v => applyFilterValue(names.emailType, v)} placeholder={'emailType'} />
        <RingilInputText value={filter?.entityType} onChange={v => applyFilterValue(names.entityType, v)} placeholder={'entityType'} />
        <RingilInputText value={filter?.applicationId} onChange={v => applyFilterValue(names.applicationId, v)} placeholder={'Application ID'} />
      </div>
      <div className={'flex flex-row justify-between py-4'}>
        <div className={'flex flex-col gap-4'}>
          <Table value={data?.data} onPage={onPage} selectedPage={page.pageNumber} rows={data?.size} totalRecords={data?.total} paginator={true}>
            <Column header={'ownerCompanyId'} body={r => <>{r.ownerCompanyId}</>} />
            <Column header={'Entity Type'} body={r => <>{r.entityType}</>} />
            {/*// @ts-ignore*/}
            <Column header={'applicationId'} body={r => <>{r.entityData.applicationId}</>} />
            <Column header={'action'} body={r => <>{r.action}</>} />
            <Column header={'retryCount'} body={r => <>{r.retryCount}</>} />
            <Column
              header={''}
              body={r => (
                <>
                  <RingilButton label={'Duplicate'} mode={'rect-small'} onClick={() => duplicate(r.id)} />
                </>
              )}
            />
          </Table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default withAuthenticationRequired(FindEmailRequest);
