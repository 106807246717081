import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { PartnerDetailInDTO, PartnerDetailOutDTO, PartnerLocationInDTO, PartnerLocationOutDTO } from '@api/logsteo-api.v2';
import { Dialog } from 'primereact/dialog';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import ListInfo from '@components/obsolete/ListInfo/ListInfo.tsx';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem.tsx';
import CreateOrEditPartnerLocation from '@components/obsolete/partners/components/CreateOrEditPartnerLocation.tsx';
import CreateOrEditPartner from '@components/obsolete/partners/components/CreateOrEditPartner.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import navigation from '@components/ringil3/icons/breadcrumb/Navigation.tsx';

interface ComponentProps {
  partnerId: string;
}

interface CreateOrUpdatePartnerLocation {
  visible: boolean;
  partnerLocationId?: string;
}

const PartnerDetail: React.FC<ComponentProps> = ({ partnerId }) => {
  const { t } = useTranslation('common');
  const { cuGetPartnerDetail, cuDeletePartnerLocation, cuUpdatePartnerLocation, cuCreatePartnerLocation, cuUpdatePartner } = useContext(ApiContext);
  const [partner, setPartner] = useState<PartnerDetailOutDTO>(undefined);
  const [deletePartnerLocationId, setDeletePartnerLocationId] = useState(undefined);
  const [createOrUpdatePL, setCreateOrUpdatePL] = useState<CreateOrUpdatePartnerLocation>({ visible: false });
  const [editingPartnerId, setEditingPartnerId] = useState(undefined);
  const nav = useNavigation();

  useEffect(() => {
    cuGetPartnerDetail(partnerId, d => {
      setPartner(d);
    });
  }, []);

  const deletePartnerLocation = (partnerLocationId: string) => {
    cuDeletePartnerLocation(partner.partnerId, partnerLocationId, () => {
      cuGetPartnerDetail(partnerId, d => {
        setPartner(d);
        setDeletePartnerLocationId(undefined);
      });
    });
  };

  const saveOrUpdate = (partnerLocation: PartnerLocationInDTO) => {
    if (createOrUpdatePL.partnerLocationId) {
      cuUpdatePartnerLocation(partnerId, createOrUpdatePL.partnerLocationId, partnerLocation, () => {
        cuGetPartnerDetail(partnerId, d => {
          setPartner(d);
          setCreateOrUpdatePL({ visible: false, partnerLocationId: null });
        });
      });
    } else {
      cuCreatePartnerLocation(partnerId, partnerLocation, () => {
        cuGetPartnerDetail(partnerId, d => {
          setPartner(d);
          setCreateOrUpdatePL({ visible: false, partnerLocationId: null });
        });
      });
    }
  };

  const updatePartner = (partner: PartnerDetailInDTO) => {
    cuUpdatePartner(partnerId, partner, () => {
      cuGetPartnerDetail(partnerId, d => {
        setPartner(d);
        setEditingPartnerId(undefined);
      });
    });
  };

  const navBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <ButtonLayout>
        <Button className=" p-button p-button-rounded p-button-outlined" icon="pi pi-trash" onClick={() => setDeletePartnerLocationId(rowData.partnerLocationId)} />
      </ButtonLayout>
    );
  };

  const nameBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.name`, `Název`)} />
        <Button
          label={rowData.name}
          className="p-button-text p-p-0"
          onClick={() => {
            setCreateOrUpdatePL({
              visible: true,
              partnerLocationId: rowData.partnerLocationId,
            });
          }}
        />
      </>
    );
  };

  const stateBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.country`, `Stát`)} />
        <ColumnContent content={rowData.address.country} />
      </>
    );
  };

  const postalCodeBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.postalCode`, `PSČ`)} />
        <ColumnContent content={rowData.address.zipCode} />
      </>
    );
  };

  const cityBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.city`, `Město`)} />
        <ColumnContent content={rowData.address.city} />
      </>
    );
  };

  const streetNumberBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.StreetNr`, `Ulice a číslo`)} />
        <ColumnContent content={rowData.address.streetNr} />
      </>
    );
  };

  const contactPersonBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.contactPerson`, `Kontaktní osoba`)} />
        <ColumnContent content={rowData.contactName} />
      </>
    );
  };

  const emailBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.email`, `Email`)} />
        {rowData.contactEmail ? <ColumnContent content={rowData.contactEmail} /> : <div className="text-center"> — </div>}
      </>
    );
  };

  const phoneBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.phone`, `Telefon`)} />
        {rowData.contactPhone ? <ColumnContent content={rowData.contactPhone} /> : <div className="text-center"> — </div>}
      </>
    );
  };

  const timeslotsBodyTemplate = (rowData: PartnerLocationOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`PartnerDetail.timeslots`, `Čas. okna`)} />
        {rowData.isTimeslotExists ? (
          <div className="text-center">
            <i className="pi pi-check"></i>
          </div>
        ) : (
          <div className="text-center">
            <i className="pi pi-times"></i>
          </div>
        )}
      </>
    );
  };

  const partnerBreadCrumb = () => {
    return [
      {
        label: t('labelAllPartners'),
        url: /*"/customer/partners/"*/ nav.createNavigationLink(nav.urlFunctions.createPartnerList()),
      },
      {
        label: t('labelPartnerDetail'),
        disabled: true,
      },
    ];
  };

  return (
    <>
      {partner && (
        <>
          <BreadCrumbComponent items={partnerBreadCrumb()} />
          <NavTitle wrapper="WITH_PADDING-TOP-4_PADDING-BOTTOM-4">
            <h2 className="h2-title p-m-0">{partner.name}</h2>
          </NavTitle>
          <div className="p-grid">
            <div className="p-d-flex p-ai-start p-jc-between p-col-12 p-lg-6">
              <ListInfo>
                <ListInfoItem title={t(`PartnerDetail.crn`, `IČ`)}>
                  <p>
                    <small>{partner.crn}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`PartnerDetail.country`, `Stát`)}>
                  <p>
                    <small>{partner.address.country}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`PartnerDetail.postalCode`, `PSČ`)}>
                  <p>
                    <small>{partner.address.zipCode}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`PartnerDetail.city`, `Město`)}>
                  <p>
                    <small>{partner.address.city}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`PartnerDetail.streetAndNumber`, `Ulice a číslo`)}>
                  <p>
                    <small>{partner.address.streetNr}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`PartnerDetail.contactPerson`, `Kontaktní osoba`)}>
                  <p>
                    <small>{partner.contactPerson}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`PartnerDetail.contactPhone`, `Kontaktní telefon`)}>
                  <p>
                    <small>{partner.contactPhone}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`PartnerDetail.contactEmail`, `Kontaktní email`)}>
                  <p>
                    <small>{partner.contactEmail}</small>
                  </p>
                </ListInfoItem>
              </ListInfo>
              <Button
                label={t(`PartnerDetail.edit`, `Upravit`)}
                icon="pi pi-pencil"
                className="btn-sm-with-out-text p-button-outlined"
                onClick={() => setEditingPartnerId(partnerId)}
              />
            </div>
          </div>
          <NavTitle wrapper="WITH_PADDING-TOP-4_PADDING-BOTTOM-4">
            <h2 className="h2-title p-m-0">{t(`PartnerDetail.allPartnerLocations`, `Všechny lokace partnerů`)}</h2>
            <Button
              label={t(`PartnerDetail.addMoreLocations`, `Přidat lokaci`)}
              icon="pi pi-plus"
              className="p-btn-edit p-button-outlined"
              onClick={() => setCreateOrUpdatePL({ visible: true })}
            />
          </NavTitle>
          {/*
        // @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            value={partner.locations}
            className="same-table responsive-table all-partner-locations-table p-mt-4"
          >
            <Column className="same-table__name" field="name" header={t(`PartnerDetail.name`, `Název`)} body={nameBodyTemplate}></Column>
            <Column className="same-table__state" field="state" header={t(`PartnerDetail.country`, `Stát`)} body={stateBodyTemplate}></Column>
            <Column className="same-table__postal-code" field="postalCode" header={t(`PartnerDetail.postalCode`, `PSČ`)} body={postalCodeBodyTemplate}></Column>
            <Column className="same-table__city" field="city" header={t(`PartnerDetail.city`, `Město`)} body={cityBodyTemplate}></Column>
            <Column
              className="same-table__street-number"
              field="streetNumber"
              header={t(`PartnerDetail.streetAndNumber`, `Ulice a číslo`)}
              body={streetNumberBodyTemplate}
            ></Column>
            <Column
              className="same-table__contact-person"
              field="contactPerson"
              header={t(`PartnerDetail.contactPerson`, `Kontaktní osoba`)}
              body={contactPersonBodyTemplate}
            ></Column>
            <Column className="same-table__email" field="email" header={t(`PartnerDetail.email`, `Email`)} body={emailBodyTemplate}></Column>
            <Column className="same-table__phone" field="phone" header={t(`PartnerDetail.phone`, `Telefon`)} body={phoneBodyTemplate}></Column>
            <Column className="same-table__timeslots" field="timeslots" header={t(`PartnerDetail.timeslots`, `Čas. okna`)} body={timeslotsBodyTemplate}></Column>

            <Column className="same-table__nav" header={t(`PartnerDetail.actions`, `Akce`)} body={navBodyTemplate}></Column>
          </DataTable>
          {/*
        // @ts-ignore*/}
          <Dialog
            header={t(`PartnerDetail.removeThisLocation`, `Smazat lokaci`)}
            visible={deletePartnerLocationId}
            className="modal-sm"
            onHide={() => setDeletePartnerLocationId(undefined)}
          >
            <div>
              <p className="p-mt-0">{t(`PartnerDetail.byRemovingThisLocation`, `Smazáním této lokace nebude schopni ji použít v adresáři.`)}</p>
            </div>

            <ButtonLayout wrapper="WITH_PADDING_TOP_4">
              <Button label={t(`PartnerDetail.delete`, `Smazat`)} className="p-mr-2 p-button-danger" onClick={() => deletePartnerLocation(deletePartnerLocationId)} />
              <Button label={t(`PartnerDetail.cancel`, `Zrušit`)} className="p-button-text" onClick={() => setDeletePartnerLocationId(undefined)} />
            </ButtonLayout>
          </Dialog>
        </>
      )}

      {partner && (
        <CreateOrEditPartnerLocation
          visible={createOrUpdatePL.visible}
          onHide={() => setCreateOrUpdatePL({ visible: false })}
          partnerLocationId={createOrUpdatePL.partnerLocationId}
          partnerId={partnerId}
          onSuccess={partnerLocation => saveOrUpdate(partnerLocation)}
          partnerAddressDetail={{
            city: partner.address.city,
            contactEmail: partner.contactEmail,
            contactPerson: partner.contactPerson,
            contactPhone: partner.contactPhone,
            country: partner.address.country,
            zipCode: partner.address.zipCode,
            streetNr: partner.address.streetNr,
          }}
        />
      )}

      <CreateOrEditPartner
        visible={editingPartnerId}
        partnerId={editingPartnerId}
        onHide={() => setEditingPartnerId(undefined)}
        onComplete={data => updatePartner(data)}
      />
    </>
  );
};

export default PartnerDetail;
