import React, { useContext, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import Label from '@components/obsolete/Form/Label/Label';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { DistributionTemplateOutDTO, DistributionTypeEnum } from '@api/logsteo-api.v2';
import { useImmer } from 'use-immer';
import { InputNumber } from 'primereact/inputnumber';
import useValidation from '@hooks/validation-hook/useValidation';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import useCustomerCarriersAndGroups from '@hooks/customer-carrier/customer-carriers-groups';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import DistributionAndPrice from '@components/obsolete/DistributionAndPrice/DistributionAndPrice.tsx';
import { carriersAndGroupsTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import { DelayedPublishingInputComponent, DelayedPublishingLabel } from '@components/obsolete/delayed-publishing';

interface ComponentProps {
  onHide: () => void;
  visible: boolean;
  templateId: string;
  onComplete: (data: DistributionTemplateOutDTO) => void;
}

const RequestCoverageSidebar: React.FC<ComponentProps> = ({ onHide, visible, templateId, onComplete }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { cuGetDistributionTemplateData } = useContext(ApiContext);
  const [state, setState] = useImmer<DistributionTemplateOutDTO>(undefined);
  const carriersAndGroups = useCustomerCarriersAndGroups();

  useEffect(() => {
    if (visible) {
      clear();
      cuGetDistributionTemplateData(templateId, data => {
        setState(draft => {
          return data;
        });
      });
    }
  }, [visible]);

  const validationSchema = yup.object().shape({
    name: yup.string().defined().required(),
    distributionType: yup.string().defined().required(),
    price: yup.number().when('distributionType', {
      is: DistributionTypeEnum.FIXED_PRICE,
      then: yup.number().defined().min(0),
      otherwise: yup.number().nullable().min(0),
    }),
    intervalHour: yup.number().defined().min(1),
    currency: yup.string().defined().required(),
    carriersAndGroups: yup.array().min(1),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(validationSchema, () => onComplete(state));

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        {state && (
          <>
            <h3>{t(`RequestCoverageSidebar.editCoverageRequestTest`, `Upravit detail poptávky`)}</h3>
            <div className="p-grid p-d-flex">
              <div className="p-col-12">
                <DistributionAndPrice
                  validationErrors={validationErrors}
                  distributionType={DistributionTypeEnum[state.distributionType]}
                  changeDistributionType={v =>
                    setState(draft => {
                      draft.distributionType = v;
                    })
                  }
                  price={state.price}
                  changeDistributionPrice={v =>
                    setState(draft => {
                      draft.price = v;
                    })
                  }
                  currency={state.currency}
                  changeDistributionCurrency={v =>
                    setState(draft => {
                      draft.currency = v;
                    })
                  }
                  disabled={false}
                />
                <div className="p-field p-grid p-mb-4">
                  <div className="p-col-12">
                    <Label title={`${t('expeditionOverviewPage.labelCarriers')} *`} />
                  </div>
                  <div className="p-col-12">
                    <MultiSelect
                      optionLabel="name"
                      filter
                      className="multiselect-custom"
                      style={{ width: '100%' }}
                      value={state.carriersAndGroups}
                      options={carriersAndGroups}
                      onChange={e =>
                        setState(draft => {
                          draft.carriersAndGroups = e.value;
                        })
                      }
                      itemTemplate={carriersAndGroupsTemplate}
                    />
                    <ValidationDiv errors={validationErrors} path={'carriersAndGroups'} />
                  </div>
                </div>

                <div className="p-field p-grid">
                  <div className="p-col-12">
                    <Label title={t(`RequestCoverageSidebar.howLongIsValid`, `Jak dlouho má distribuce platit?`)} required={true} />
                  </div>
                  <div className="p-field p-col-12">
                    <InputNumber
                      locale={'cs'}
                      value={state.intervalHour}
                      style={{ width: '100%' }}
                      onChange={e => {
                        setState(draft => {
                          draft.intervalHour = e.value;
                        });
                      }}
                      useGrouping={false}
                      minFractionDigits={0}
                      maxFractionDigits={2}
                    />
                    <ValidationDiv errors={validationErrors} path={'intervalHour'} />
                  </div>
                </div>
                <div className="p-field p-grid">
                  <div className="p-col-12">
                    <DelayedPublishingLabel />
                  </div>
                  <div className="p-field p-col-12">
                    <DelayedPublishingInputComponent
                      changeAutomaticPublishing={value => {
                        setState(draft => {
                          draft.shouldExpeditionAutomaticallyPublished = value;
                        });
                      }}
                      value={state.shouldExpeditionAutomaticallyPublished}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ButtonLayout>
              <Button label={t('labelSave')} className="p-mr-2" onClick={() => validateAndSend(state)} />
              <Button label={t('labelCancel')} className="p-button-text" onClick={() => onHide()} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default RequestCoverageSidebar;
