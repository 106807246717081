import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import ImportUsers from '@components/obsolete/admin/import/users/ImportUsers.tsx';

interface ComponentProps {}

const ImportUsersPage: React.FC<ComponentProps> = () => {
  return (
    <AdminLayout title={'Requests'}>
      <ImportUsers />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(ImportUsersPage);
