import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DemandListPseudoState } from '@type/enums.ts';

interface ComponentProps {
  demandState: string;
  isManual: boolean;
  isCanceled?: boolean;
}

const DemandStateSpan: React.FC<ComponentProps> = ({ demandState, isManual, isCanceled }) => {
  const { t } = useTranslation();
  let stage;

  switch (demandState) {
    case DemandListPseudoState.ASSIGNING:
      stage = <span className="text-orange">{t(`Demands.${demandState}`)}</span>;
      break;

    case DemandListPseudoState.OFFERED:
      stage = <span className="text-green">{t(`Demands.${demandState}`)}</span>;
      break;

    case DemandListPseudoState.WIN:
      stage = <span className="text-green">{t(`Demands.${demandState}`)}</span>;
      break;

    case DemandListPseudoState.NOT_WIN:
      stage = <span className="text-pink">{t(`Demands.${demandState}`)}</span>;
      break;

    case DemandListPseudoState.REJECTED:
      stage = <span className="text-pink">{t(`Demands.${demandState}`)}</span>;
      break;

    case 'CANCELED':
      stage = <span className="text-red">{t(`Demands.${demandState}`)}</span>;
      break;
  }

  if (isCanceled) stage = <span className="text-red">{t(`Demands.CANCELED`)}</span>;
  if (isManual) stage = <span className="text-red">{t(`Demands.NOT_WIN`)}</span>;

  return <>{stage}</>;
};

export default DemandStateSpan;
