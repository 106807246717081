import React, { useRef } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import {
  GlobalTimeslotsDTO,
  LovCompanyLocationWithOpeningHours,
  CompanyModuleEnum,
  OpeningEntityTypeEnum,
  ShipmentStateCodeEnum,
  SNConfiguration,
  TransportationTypeEnum,
} from '@api/logsteo-api.v2';
import { Form, proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { findValidationMessage } from '@utils/validation';
import { CUSTOMIZATION_MODE_DISABLED, CUSTOMIZATION_MODE_OPTIONAL, CUSTOMIZATION_MODE_READONLY, CUSTOMIZATION_MODE_REQUIRED } from '@utils/exports';
import InputTransportationType from '@components/obsolete/Form/InputTransportationType/InputTransportationType.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputInheriterCompany from '@components/obsolete/Form/InputEntityOwner/InputInheriterCompany.tsx';
import InputCompanyLocation from '@components/obsolete/Form/InputEntityOwner/InputCompanyLocation.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import InputEntityOwner from '@components/obsolete/Form/InputEntityOwner/InputEntityOwner.tsx';
import InputShipmentStatus from '@components/obsolete/Form/InputShipmentStatus/InputShipmentStatus.tsx';

export interface SupplierNotificationHeaderData {
  transportationType: TransportationTypeEnum;
  companyId: string;
  supplierId: string;
  supplierLocationId: string;
  customerLocationId: string;
  customerLocation?: LovCompanyLocationWithOpeningHours;
  supplierLocation?: LovCompanyLocationWithOpeningHours;
  shipmentStateCode: ShipmentStateCodeEnum;
  companyCode?: string;
  supplierDeliverySlots: GlobalTimeslotsDTO;
  customerDeliverySlots: GlobalTimeslotsDTO;
}

interface ComponentProps {
  form: Form<SupplierNotificationHeaderData>;
  configuration: SNConfiguration;
  showTimeslots: boolean;
  disabled?: boolean;
}

const SupplierNotificationHeaderForm: React.FC<ComponentProps> = ({ form, configuration, showTimeslots, disabled = false }) => {
  const { tr } = useTranslationLgs();
  const childrenForms = useRef([]);

  const names = proxiedPropertiesOf<SupplierNotificationHeaderData>();

  return (
    <>
      <InputTransportationType
        code={form.values.transportationType}
        onChange={code => form.setFieldValue('transportationType', code)}
        disabled={disabled}
        singleOption={false}
      />
      <RR>
        <RC large={6} medium={6} small={6}>
          {configuration?.supplierAndCustomer !== CUSTOMIZATION_MODE_DISABLED && (
            <HorizontalFieldValue
              label={tr(`CreateSupplierNotification.supplier`, `Supplier`)}
              required={true}
              validationError={findValidationMessage(form.validationErrors, 'supplierId', tr)}
              value={
                <>
                  <InputInheriterCompany
                    companyId={form.values.companyId}
                    inheriterId={form.values.supplierId}
                    onChange={v => form.setFieldValue('supplierId', v)}
                    module={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                    style={{ width: '100%' }}
                    readonly={configuration?.supplierAndCustomer === CUSTOMIZATION_MODE_READONLY || disabled}
                  />
                </>
              }
            />
          )}

          <HorizontalFieldValue
            label={tr(`CreateSupplierNotification.supplierLocation`, `Supplier location`)}
            value={
              <InputCompanyLocation
                moduleName={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                companyId={form.values.supplierId}
                companyLocationId={form.values.supplierLocationId}
                onChange={v => {
                  form.setFieldValue('supplierLocationId', v?.id);
                  form.setFieldValue('supplierLocation', v);
                }}
                style={{ width: '100%' }}
                disabled={disabled}
              />
            }
            validationError={findValidationMessage(form.validationErrors, 'supplierLocation', tr)}
            required={true}
          />
          {(configuration?.timeslots === CUSTOMIZATION_MODE_OPTIONAL || configuration?.timeslots === CUSTOMIZATION_MODE_REQUIRED) && showTimeslots && (
            <>
              <HorizontalFieldValue
                label={tr(`CreateSupplierNotification.supplierTimeslot`, `Supplier timeslot`)}
                required={configuration?.timeslots === CUSTOMIZATION_MODE_REQUIRED}
                value={
                  <ManageTimeslot
                    entityType={OpeningEntityTypeEnum.LOCATION}
                    entityId={form.values.supplierLocationId}
                    onChange={v => {
                      form.setFieldValue(names.supplierDeliverySlots, v);
                    }}
                    timeslot={form.values.supplierDeliverySlots}
                    noOpeningHours={false}
                    validationErrors={form.validationErrors}
                    validationPrefix={'supplierDeliverySlots.'}
                  />
                }
              />
            </>
          )}
        </RC>
        <RC large={6} medium={6} small={6}>
          {configuration?.supplierAndCustomer !== CUSTOMIZATION_MODE_DISABLED && (
            <HorizontalFieldValue
              required={configuration?.supplierAndCustomer === CUSTOMIZATION_MODE_REQUIRED}
              label={tr(`CreateSupplierNotification.customer`, `Customer`)}
              value={
                <InputEntityOwner
                  companyId={form.values.companyId}
                  onChange={c => form.setFieldValue('companyId', c)}
                  moduleType={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                  style={{ width: '100%' }}
                  onChangeCompanyCode={v => form.setFieldValue('companyCode', v)}
                  readonly={configuration?.supplierAndCustomer === CUSTOMIZATION_MODE_READONLY || disabled}
                />
                /*<<<<<<< HEAD
                                <ManageTimeslot
                                  entityType={OpeningEntityTypeEnum.LOCATION}
                                  entityId={form.values.supplierLocationId}
                                  onChange={(v) => {
                                    form.setFieldValue(names.supplierDeliverySlots, v);
                                  }}
                                  timeslot={form.values.supplierDeliverySlots}
                                  noOpeningHours={false}
                =======
                                <InputEntityOwner
                                  companyId={form.values.companyId}
                                  onChange={(c) => form.setFieldValue('companyId', c)}
                                  moduleType={ModuleTypeEnum.SUPPLIER_NOTIFICATION}
                                  style={{ width: '100%' }}
                                  onChangeCompanyCode={(v) => form.setFieldValue('companyCode', v)}
                                  readonly={configuration?.supplierAndCustomer === CUSTOMIZATION_MODE_READONLY || disabled}
                >>>>>>> origin/vetev-2.x*/
              }
            />
          )}

          <HorizontalFieldValue
            label={tr(`CreateSupplierNotification.customerLocation`, `Customer location`)}
            value={
              <InputCompanyLocation
                moduleName={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                companyId={form.values.companyId}
                companyLocationId={form.values.customerLocationId}
                onChange={v => {
                  form.setFieldValue('customerLocationId', v?.id);
                  form.setFieldValue('customerLocation', v);
                }}
                style={{ width: '100%' }}
                disabled={disabled}
              />
            }
            required={true}
            validationError={findValidationMessage(form.validationErrors, 'customerLocation', tr)}
          />
          {(configuration?.timeslots === CUSTOMIZATION_MODE_OPTIONAL || configuration?.timeslots === CUSTOMIZATION_MODE_REQUIRED) && showTimeslots && (
            <HorizontalFieldValue
              label={tr(`CreateSupplierNotification.customerTimeslot`, `Customer timeslot`)}
              value={
                <>
                  <ManageTimeslot
                    entityType={OpeningEntityTypeEnum.LOCATION}
                    entityId={form.values.customerLocationId}
                    onChange={v => {
                      form.setFieldValue(names.customerDeliverySlots, v);
                    }}
                    timeslot={form.values.customerDeliverySlots}
                    noOpeningHours={false}
                    validationErrors={form.validationErrors}
                    validationPrefix={'customerDeliverySlots.'}
                  />
                </>
              }
            />
          )}
          {/*=======
          {(configuration?.timeslots === CUSTOMIZATION_MODE_OPTIONAL ||
            configuration?.timeslots === CUSTOMIZATION_MODE_REQUIRED) &&
            showTimeslots && (
              <>
                <HorizontalFieldValue
                  label={tr(`CreateSupplierNotification.customerTimeslot`, `Customer timeslot`)}
                  required={configuration?.timeslots === CUSTOMIZATION_MODE_REQUIRED}
                  value={
                    <TimeslotWithDefaults
                      // @ts-ignore
                      ref={(el) => childrenForms.current.push(el)}
                      onLoadSlotsDefaults={(d) => {
                        return generateDefaultSlots(form.values.customerLocation?.openingHoursSplit, d);
                      }}
                      onChange={(v) => {
                        form.setFieldValue('unloadingTimeslots', v);
                      }}
                      initialValues={{
                        // @ts-ignore
                        range: [],
                        daysWithIntervals: [],
                      }}
                      companyId={form.values.companyId}
                      customerLocationId={form.values.customerLocationId}
                      disabled={disabled}
                    />
                  }
                />
              </>
            )}
>>>>>>> origin/vetev-2.x*/}
        </RC>
      </RR>
      <HorizontalFieldValue
        label={tr('CreateSupplierNotification.shipmentState', 'Shipment state')}
        value={
          <InputShipmentStatus onChange={v => form.setFieldValue('shipmentStateCode', v)} code={form.values.shipmentStateCode} required={true} disabled={disabled} />
        }
      />
    </>
  );
};

export default SupplierNotificationHeaderForm;
