import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRingilText = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={489} height={120} fill="none" {...props}>
    <path
      fill={props.fill ? props.fill : '#fff'}
      d="M216.442 77.455h-.905L177.527 4.15h-33.485v112.22h25.34V40.35h1.81l38.915 76.02h31.675V4.15h-25.34zM438.173 4.15h-25.34v112.22h76.02000000000001V92.84h-50.68zM129.565 4.15h-27.15v112.22h27.15zM398.349 4.15h-27.15v112.22h27.15zM358.527 42.16S355.812.53 307.847.53s-55.205 33.485-55.205 56.11 7.24 62.445 47.06 62.445c19.91 0 28.96-6.335 32.58-12.67v9.955h25.34V54.83h-49.775v19.91h24.435v16.29h-50.68V29.49h47.965v12.67zM90.65 42.16c0-27.15-12.67-38.01-33.485-38.01H.15v112.22h25.34V76.55l33.485 39.82H90.65L58.07 76.55c5.43-.905 32.58-4.525 32.58-34.39M63.5 53.925H25.49v-27.15H63.5z"
    />
  </svg>
);
export default SvgRingilText;
