import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { PermissionTypesEnum } from '@api/logsteo-api.v2';
import { Col } from '@components/styles.tsx';
import AlertInfo from '@components/obsolete/Alert/AlertInfo.tsx';

interface ComponentProps {
  permission: PermissionTypesEnum[];
}

const PermissionError: React.FC<ComponentProps> = ({ permission }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <AlertInfo backgroundWrapper={'red'}>
        <Col>
          <h2>{tr(`PermissionError.permissionError`, `Permission Error`)}</h2>
          <p>{tr(`PermissionError.youDoNotHavePermissionToAccessThisPageYouNeedOnOf`, `You do not have permission to access this page. You need on of:`)}</p>
          <p>{permission.map(t => t.toString()).join(', ')}</p>
        </Col>
      </AlertInfo>
    </>
  );
};

export default PermissionError;
