import React, { useContext, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import {
  CarrierExpeditionId,
  CarrierExpeditionStatusEnum,
  EntityTypeEnum,
  ExpeditionPreviewOutDTO,
  JobPreviewOutDTO,
  PageResponseJobPreviewOutDTO,
} from '@api/logsteo-api.v2';
import { convertToArray, convertToDate, formatCarrierLocation, formatPrice, mapFromDateToDayjs } from '@utils/utils';
import { ApiContext } from '@api/api';
import useMassAction from '@hooks/useMassAction/useMassAction';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import styled from 'styled-components';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { shortDateFormat } from '@utils/date';
import { InputText } from 'primereact/inputtext';
import useNavigation from '@hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import CarrierExpeditionStateSpan from '@components/obsolete/StateSpan/CarrierExpeditionStateSpan.tsx';
import ExpeditionLocation from '@components/obsolete/ExpeditionLocation/ExpeditionLocation.tsx';
import ExpeditionListStart from '@components/obsolete/ExpeditionListStart/ExpeditionListStart.tsx';
import NoteIcon from '@components/obsolete/Icons/NoteIcon.tsx';
import ExtraCostIcon from '@components/obsolete/Icons/ExtraCostIcon.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { Link } from 'react-router-dom';
import { useInputDrivers } from '@components/obsolete/Form/InputDriver/InputDrivers.tsx';
import useCarrierDispatcher from '@hooks/use-carrier-dispatcher/use-carrier-dispatcher.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import MassActionFileUploadDialog from '@components/obsolete/MassActionFileUploadDialog/MassActionFileUploadDialog.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { useCreateMultiEditCarInfoSidebar } from '@app/pages/carrier/shipments-list/sidebars/MultiEditCarInfoSidebar.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { Tooltip } from 'primereact/tooltip';

interface CarrierExpedition {
  applicationId: string;
  customerId: string;
  expeditionId: string;
  locationFrom: string;
  locationTo: string;
}

const Shipments = () => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { caListShipments } = useContext(ApiContext);
  const [pageData, setPageData] = useState<PageResponseJobPreviewOutDTO>(undefined);

  const [visibleFileUploadDialog, setVisibleFileUploadDialog] = useState(false);
  const toast = useRef(undefined);
  const navigation = useNavigation();

  const { MassActionCheckboxAndState, toggleCheckbox, isSelected, setSelectedObjects, selectedObjects, clear } = useMassAction<CarrierExpedition>(
    (a1, a2) => a1.expeditionId === a2.expeditionId,
    selectedObjects => (
      <>
        <Button label={tr(`Shipments.nahratDokumenty`, `Nahrat dokumenty`)} onClick={e => setVisibleFileUploadDialog(true)} disabled={selectedObjects.length === 0} />
        <Button
          label={tr(`Shipments.changeCarInfo`, `Mass update car info`)}
          onClick={e => setVisibleMultiEditCarIfoSidebar(true)}
          disabled={selectedObjects.length === 0}
        />
      </>
    ),
    () => renderDataSelectedItems(),
  );

  const renderDataSelectedItems = (): any[] => {
    return [
      <Column header={tr(`Shipments.applicationId`, `applicationId`)} body={(row: CarrierExpedition) => <div>{row.applicationId}</div>} />,
      <Column header={tr(`Shipments.from`, `state`)} body={(row: CarrierExpedition) => <div>{row.locationFrom}</div>} />,
      <Column header={tr(`Shipments.to`, `state`)} body={(row: CarrierExpedition) => <div>{row.locationTo}</div>} />,
    ];
  };

  const loadData = (pageSize: number, pageNumber: number, filter: FilterType) => {
    caListShipments(
      {
        carrierExpeditionStatus: filter?.carrierExpeditionStatus,
        carrierDispatcherIds: filter.carrierDispatcherIds,
        loadingDateFromISO: mapFromDateToDayjs(filter.loadingDate)?.toISOString(),
        loadingDateToISO: mapFromDateToDayjs(filter.loadingDate)?.toISOString(),
        unloadingDateFromISO: mapFromDateToDayjs(filter.unloadingDate)?.toISOString(),
        unloadingDateToISO: mapFromDateToDayjs(filter.unloadingDate)?.toISOString(),
        applicationId: filter.applicationId,
        locationName: filter.locationName,
        driverIds: filter.driverIds,
      },
      { pageSize, pageNumber },
      data => {
        setPageData(data);
      },
    );
  };

  const onPage = (event: any) => {
    applyPaging({ pageNumber: event.page + 1, pageSize: event.rows });
  };

  const items = [
    {
      label: t('Shipments.action', 'Action'),
      icon: 'pi pi-refresh',
    },
  ];

  const stageBodyTemplate = (rowData: JobPreviewOutDTO) => {
    return (
      <>
        <div className="stage">
          <CarrierExpeditionStateSpan expeditionStage={rowData.expeditionStatus} isManual={rowData.isManualExpedition} />
        </div>
        {rowData.price && (
          <div className="price">
            <span>
              {formatPrice(
                rowData.price +
                  rowData.extraCosts.reduce((a, b) => {
                    return a + b.price;
                  }, 0),
                rowData.currency,
              )}
            </span>
          </div>
        )}
        {rowData.customerCompanyName && (
          <div className="customer-name">
            <span>{rowData.customerCompanyName}</span>
          </div>
        )}
      </>
    );
  };

  const routeBodyTemplate = (rowData: JobPreviewOutDTO) => {
    const throughCount = rowData.locations.length - 2;
    return (
      <div className="locations">
        <ExpeditionLocation
          locationPoint={t(`Shipments.from`, `From`)}
          locationAddress={`${rowData.locations[0].locationName}, ${rowData.locations[0].zipCode}, ${rowData.locations[0].city}, (${rowData.locations[0].country})`}
          wrapper="LARGE"
        />
        <ExpeditionLocation
          locationPoint={t(`Shipments.to`, `To`)}
          locationAddress={`${rowData.locations[rowData.locations.length - 1].locationName}, ${rowData.locations[rowData.locations.length - 1].zipCode}, ${
            rowData.locations[rowData.locations.length - 1].city
          }, (${rowData.locations[rowData.locations.length - 1].country})`}
          wrapper="LARGE"
        />
        {throughCount > 0 && <ExpeditionLocation locationPoint={t('labelThrough')} through={`${throughCount}`} wrapper="LARGE" />}
      </div>
    );
  };

  const expeditionStartBodyTemplate = (rowData: JobPreviewOutDTO) => {
    return <ExpeditionListStart label={t(`Shipments.expedition_start`, `Začátek přepravy:`)} expeditionStart={rowData.expeditionStart} />;
  };

  const typeShipmentsBodyTemplate = (rowData: JobPreviewOutDTO) => {
    return (
      <>
        <div>
          <div className="flex flex-row gap-0.5 items-center">
            <span>{t(`Shipments.${rowData.expeditionType}`)}</span>
            <NoteIcon applicationId={rowData.applicationId} note={rowData.note} />
            <ExtraCostIcon extraCosts={rowData.extraCosts} applicationId={rowData.applicationId} />
            <span id={`route_${rowData.applicationId}`} className={'pi pi-map'}></span>
            {/*// @ts-ignore*/}
            <Tooltip target={`#route_${rowData.applicationId}`} style={{ maxWidth: '530px' }}>
              {rowData.locations.map((location, locationIndex) => {
                return (
                  <div key={locationIndex}>
                    {tr(`AllExpedition.locationInfo`, `Location {{order}}: {{locationName}}`, {
                      order: locationIndex + 1,
                      locationName: location.locationName,
                    })}
                  </div>
                );
              })}
            </Tooltip>
          </div>
        </div>
        <div className="p-d-flex">
          <div className="list-book-ref">
            <small>{rowData.carPlate}</small>
          </div>
        </div>
        <div className="p-pt-1">{rowData.dispatcherFullName ? <span className="carrier">{rowData.dispatcherFullName}</span> : <span>—</span>}</div>
      </>
    );
  };

  const navBodyTemplate = (data: ExpeditionPreviewOutDTO) => {
    return (
      <div>
        <ButtonLayout>
          {/*<Link href={`/carrier/shipment/${data.companyId}-${data.applicationId}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierShipmentDetail(data.companyId, data.applicationId))}>
            <a className="btn-detail p-button p-button-rounded p-button-outlined secondary-btn">Detail</a>
          </Link>

          <SplitButton icon="pi pi-ellipsis-h" model={items} className="btn-more-info p-button-rounded p-button-outlined p-ml-3" />
        </ButtonLayout>
      </div>
    );
  };

  const clickableIdTemplate = (data: JobPreviewOutDTO) => {
    return (
      <>
        {/*<Link href={`/carrier/shipment/${data.companyId}-${data.applicationId}`}>*/}
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierShipmentDetail(data.companyId, data.applicationId))}>{data.applicationId}</Link>
      </>
    );
  };

  interface FilterType {
    carrierExpeditionStatus?: CarrierExpeditionStatusEnum;
    carrierDispatcherIds?: string[];
    loadingDate?: Date;
    unloadingDate?: Date;
    applicationId?: string;
    locationName?: string;
    driverIds?: string[];
  }

  const { Filter, applyFilterValue, filter, applyPaging, page } = useFilter<FilterType>((page, filter) => {
    load();
  });

  const load = () => {
    loadData(page.pageSize, page.pageNumber, filter);
  };

  const { MultiEditCarInfoSidebar, setVisible: setVisibleMultiEditCarIfoSidebar } = useCreateMultiEditCarInfoSidebar(() => {
    clear();
    load();
    setVisibleMultiEditCarIfoSidebar(false);
  });

  const { InputDrivers } = useInputDrivers();
  const dispatchers = useCarrierDispatcher();

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <BreadCrumbComponent items={[{ label: tr(`Shipments.shipments`, `Shipments`) }]} />
        <COHeadline title={tr(`Shipments.shipments`, `Shipments`)} variant={'h1'} />
      </div>
      {pageData && (
        <>
          <Filter />
          <Grid>
            <div className={'field'}>
              <InputText
                value={filter.applicationId}
                onChange={e => applyFilterValue('applicationId', e.target.value)}
                placeholder={tr(`Shipments.applicationId`, `Application ID`)}
              />
            </div>
            <div className={'field'}>
              <Dropdown
                value={filter?.carrierExpeditionStatus}
                options={Object.keys(CarrierExpeditionStatusEnum).map(i => {
                  return { name: t(`Shipments.${i}`, i), value: i };
                })}
                onChange={e => {
                  applyFilterValue('carrierExpeditionStatus', e.value);
                }}
                placeholder={t(`Shipments.expeditionStage`, `Expedition stage`)}
                className="filter-table"
                showClear={true}
                optionValue={'value'}
                optionLabel={'name'}
              />
            </div>
            <div className={'field'}>
              <MultiSelect
                value={convertToArray(filter?.carrierDispatcherIds)}
                options={dispatchers}
                optionLabel={'name'}
                optionValue={'id'}
                onChange={e => applyFilterValue('carrierDispatcherIds', e.value)}
                placeholder={tr('Shipments.dispatchers', 'Dispatchers')}
              />{' '}
            </div>
            <div className={'field'}>
              <Calendar
                value={convertToDate(filter.loadingDate)}
                onChange={e => applyFilterValue('loadingDate', e.value)}
                dateFormat={shortDateFormat}
                placeholder={tr(`Shipments.loading`, `Loading`)}
                showIcon={true}
                locale={'cs'}
                showButtonBar={true}
              />{' '}
            </div>
            <div className={'field'}>
              <Calendar
                value={convertToDate(filter.unloadingDate)}
                onChange={e => applyFilterValue('unloadingDate', e.value)}
                dateFormat={shortDateFormat}
                placeholder={tr(`Shipments.unloading`, `Unloading`)}
                showIcon={true}
                locale={'cs'}
                showButtonBar={true}
              />{' '}
            </div>
            <div className={'field'}>
              <InputText
                value={filter.locationName}
                onChange={e => applyFilterValue('locationName', e.target.value)}
                placeholder={tr(`Shipments.locationName`, `Location name`)}
              />{' '}
            </div>
            <div className={'field'}>
              <InputDrivers value={convertToArray(filter?.driverIds)} onChange={e => applyFilterValue('driverIds', e)} placeholder={tr(`Shipments.driver`, `Driver`)} />{' '}
            </div>
          </Grid>

          <MassActionCheckboxAndState
            onSelectAll={() => {
              setSelectedObjects(
                pageData.data.map(t => {
                  return {
                    expeditionId: t.id,
                    customerId: t.companyId,
                    applicationId: t.applicationId,
                    locationTo: formatCarrierLocation(
                      t.locations[t.locations.length - 1].zipCode,
                      t.locations[t.locations.length - 1].city,
                      t.locations[t.locations.length - 1].country,
                    ),
                    locationFrom: formatCarrierLocation(t.locations[0].zipCode, t.locations[0].city, t.locations[0].country),
                  };
                }),
              );
            }}
          />
          {/*// @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            className="default-exp-table shipments-table"
            value={pageData.data}
            rows={pageData.size}
            paginator={true}
            lazy={true}
            totalRecords={pageData.total}
            onPage={onPage}
            first={(pageData.page - 1) * pageData.size}>
            <Column
              header={''}
              body={(row: JobPreviewOutDTO) => (
                <Checkbox
                  onChange={e => {
                    toggleCheckbox({
                      expeditionId: row.id,
                      customerId: row.companyId,
                      applicationId: row.applicationId,
                      locationTo: formatCarrierLocation(
                        row.locations[row.locations.length - 1].zipCode,
                        row.locations[row.locations.length - 1].city,
                        row.locations[row.locations.length - 1].country,
                      ),
                      locationFrom: formatCarrierLocation(row.locations[0].zipCode, row.locations[0].city, row.locations[0].country),
                    });
                  }}
                  checked={isSelected({
                    expeditionId: row.id,
                    customerId: row.companyId,
                    applicationId: row.applicationId,
                    locationTo: formatCarrierLocation(
                      row.locations[row.locations.length - 1].zipCode,
                      row.locations[row.locations.length - 1].city,
                      row.locations[row.locations.length - 1].country,
                    ),
                    locationFrom: formatCarrierLocation(row.locations[0].zipCode, row.locations[0].city, row.locations[0].country),
                  })}
                />
              )}
              className="shipments-table__checkbox text-blue"></Column>
            <Column className="shipments-table__id text-blue" body={clickableIdTemplate}></Column>
            <Column className="shipments-table__stage" body={stageBodyTemplate}></Column>
            <Column className="shipments-table__route" body={routeBodyTemplate}></Column>
            <Column className="shipments-table__shipments" body={typeShipmentsBodyTemplate}></Column>
            <Column className="shipments-table__expedition_start" body={expeditionStartBodyTemplate}></Column>
            <Column className="shipments-table__nav" body={navBodyTemplate}></Column>
          </DataTable>

          <MassActionFileUploadDialog
            entityType={EntityTypeEnum.EXPEDITION}
            entityIds={selectedObjects.map(t => t.expeditionId)}
            visible={visibleFileUploadDialog}
            onHide={() => setVisibleFileUploadDialog(false)}
            onComplete={count => {
              setVisibleFileUploadDialog(false);
              toast.current.show({
                severity: 'success',
                summary: tr(`Shipments.dokumentyBylyNahrany`, `Dokumenty byly nahrany`),
                detail: tr(`Shipments.nahraneDokumenty`, `K expedidici byly nahrany {{count}} dokumenty`, { count }, count),

                life: 3000,
              });
              clear();
            }}
          />
          <MultiEditCarInfoSidebar
            carrierExpeditionIds={selectedObjects.map(t => {
              return {
                applicationId: t.applicationId,
                customerId: t.customerId,
              } as CarrierExpeditionId;
            })}
            expeditionId={selectedObjects.map(t => t.expeditionId)[0]}
          />
          <Toast ref={toast} />
        </>
      )}
    </>
  );
};

const Grid = styled.div`
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;
export default Shipments;
