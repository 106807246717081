import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Sidebar } from 'primereact/sidebar';
import { Identifier } from '@utils/utils';
import { AdminCompanyNotificationEnablingOutDTO, EmailTypeEnum } from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  companyId: Identifier;
}

const EmailPreferencesDialog: React.FC<ComponentProps> = ({ onHide, visible, companyId }) => {
  const { adminGetNotificationForCompany, adminDeleteNotEnabledEmailNotificationForCompany, adminAddNotEnabledEmailNotificationForCompany } = useContext(ApiContext);
  const [notifications, setNotifications] = useState<AdminCompanyNotificationEnablingOutDTO[]>([]);

  useEffect(() => {
    if (visible) {
      load();
    }
  }, [companyId, visible]);
  const load = () => {
    adminGetNotificationForCompany(companyId, d => {
      setNotifications(d);
    });
  };
  const changeNotificationSetting = (notification: EmailTypeEnum, checked: boolean) => {
    if (checked) {
      adminDeleteNotEnabledEmailNotificationForCompany(companyId, notification, () => {
        load();
      });
    } else {
      adminAddNotEnabledEmailNotificationForCompany(companyId, notification, () => {
        load();
      });
    }
  };

  return (
    <>
      {/*// @ts-ignore*/}
      <Sidebar onHide={onHide} visible={visible} position={'right'} style={{ minWidth: '30rem' }}>
        <h1>Notifications</h1>
        {/*// @ts-ignore*/}
        <DataTable value={notifications}>
          <Column header={'Notification type'} body={(row: AdminCompanyNotificationEnablingOutDTO) => <div>{row.emailType}</div>} />
          <Column
            style={{ width: '5rem' }}
            header={'Enabled'}
            body={(row: AdminCompanyNotificationEnablingOutDTO) => (
              <InputSwitch checked={row.isEnabled} onChange={e => changeNotificationSetting(row.emailType, e.value)} />
            )}
          />
        </DataTable>
        <Button label={'Close'} onClick={e => onHide()}></Button>
      </Sidebar>
    </>
  );
};

export default EmailPreferencesDialog;
