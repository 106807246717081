import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import useForm from '@hooks/useForm/useForm';
import { ExpeditionCarrierNoteInDTO } from '@api/logsteo-api.v2';
import HorizontalFieldValue from '../carriers/HorizontalFieldValue';
import { InputTextarea } from 'primereact/inputtextarea';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  onComplete: () => void;
  customerId: string;
  applicationId: string;
}

export const useExpeditionUpdateNote = () => {
  const { tr } = useTranslationLgs();
  const { cuUpdateCarrierNote, cuGetCarrierNote } = useContext(ApiContext);
  const [visible, setVisible] = useState(false);

  const ExpeditionUpdateNoteSidebar: React.FC<ComponentProps> = ({ customerId, applicationId, onComplete }) => {
    const form = useForm<ExpeditionCarrierNoteInDTO>(yup.object().shape({}), { carrierNote: '' }, d => save(d), true, false);
    const save = (data: ExpeditionCarrierNoteInDTO) => {
      cuUpdateCarrierNote(customerId, applicationId, form.values, () => {
        setVisible(false);
        onComplete();
      });
    };

    useEffect(() => {
      if (visible) {
        cuGetCarrierNote(customerId, applicationId, d => {
          form.setValues(d);
        });
      }
    }, [visible]);

    return (
      <>
        {/*//
      @ts-ignore*/}
        <Sidebar
          onHide={() => setVisible(false)}
          visible={visible}
          closeOnEscape={false}
          blockScroll={true}
          position={'right'}
          style={{ width: 'var(--default-sidebar-width)' }}
        >
          <h2>{tr(`ExpeditionUpdateNoteSidebar.updateInternalInformation`, `Update note`)}</h2>

          <HorizontalFieldValue
            label={tr(`ExpeditionUpdateNoteSidebar.note`, `Note`)}
            value={
              <InputTextarea
                value={form.values.carrierNote}
                onChange={e => form.setFieldValue('carrierNote', e.target.value)}
                style={{ width: '100%', height: ' 7rem' }}
              />
            }
          />

          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
            <Button label={tr(`ExpeditionUpdateNoteSidebar.save`, `Save`)} className="p-mr-2" onClick={e => form.validateAndSend()} />
            <Button label={tr(`ExpeditionUpdateNoteSidebar.cancel`, `Cancel`)} className="p-button-text" onClick={() => setVisible(false)} />
          </ButtonLayout>
        </Sidebar>
      </>
    );
  };

  return { ExpeditionUpdateNoteSidebar, setVisible };
};
