import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { PowerBiTokenDetailOutDTO } from '@api/logsteo-api.v2';
import Layout from '@app/layout/Layout.tsx';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service, Page, factories } from 'powerbi-client';

interface ComponentProps {
  reportId: string;
}

const ShowPowerBIReport: React.FC<ComponentProps> = ({ reportId }) => {
  const { tr } = useTranslationLgs();
  const { sendPowerBIToken } = useContext(ApiContext);
  const [report, setReport] = useState<PowerBiTokenDetailOutDTO>();

  useEffect(() => {
    // https://github.com/microsoft/powerbi-client-react/issues/88
    const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
    sendPowerBIToken({ reportId }, data => {
      setReport(data);
    });
  }, [reportId]);

  const Report = () => {
    return (
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: report.reportId,
          embedUrl: report.embedUrl,
          accessToken: report.accessToken,
          tokenType: models.TokenType.Embed,
        }}
        cssClassName={'w-full h-full'}
      />
    );
  };

  return (
    <>
      <Layout title={tr(`dashboard.title`, `Dashboard`)}>
        {report && (
          <div className={'flex flex-grow-1 w-full h-full '}>
            <Report />
          </div>
          /*<PowerBIEmbed
            id={report.reportId} // required
            embedUrl={report.embedUrl} // required
            accessToken={report.accessToken}
            filterPaneEnabled={false}
            embedType={'report'}
            tokenType={TokenType.Aad}
            settings={{
              panes: {
                pageNavigation: {
                  visible: true,
                  position: 1,
                },
              },
            }}
            pageName={report.sectionId}
            width="100%"
            height="100vh"
          />*/
        )}
      </Layout>
    </>
  );
};

export default ShowPowerBIReport;
