import CreateCompany from '@components/pages/admin/companies/CreateCompany';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import AdminLayout from '@app/layout/AdminLayout.tsx';

const CreateCompanyAdminPage = () => {
  return (
    <AdminLayout title={'Create company'}>
      <CreateCompany />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(CreateCompanyAdminPage);
