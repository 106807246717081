import { Card } from 'primereact/card';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import CarrierCatalog from '@app/pages/customer/CarrierCatalog/CarrierCatalog.tsx';

const CarrierCatalogPage = () => {
  const { tr } = useTranslationLgs();
  return (
    <Layout title={tr('index.carrierCatalogTitle', 'Katalog dopravců')}>
      {/*
        // @ts-ignore*/}
      <Card>
        <CarrierCatalog />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(CarrierCatalogPage);
