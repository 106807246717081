import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import useForm from '@hooks/useForm/useForm';
import { Dayjs } from 'dayjs';
import { Button } from 'primereact/button';
import { mapToAPIDateTime } from '@utils/date';
import { RingilH2 } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputDateAndTime from '@components/obsolete/Form/InputDateAndTime/InputDateAndTime2.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  onCompleted: () => void;
  distributionValidTill: Dayjs;
  groupDemandId: string;
}

interface FormData {
  distributionValidTill: Dayjs;
}
export const useCreateChangeValidTillDialog = () => {
  const [visible, setVisible] = useState(false);

  const CreateChangeValidTillDialog: React.FC<ComponentProps> = ({ groupDemandId, onCompleted, distributionValidTill }) => {
    const { tr } = useTranslationLgs();
    const { cuChangeGroupDemandDistributionValidTill } = useContext(ApiContext);

    const form = useForm<FormData>(yup.object().shape({}), { distributionValidTill }, data => save(data), true, false);

    const save = (data: FormData) => {
      cuChangeGroupDemandDistributionValidTill(
        groupDemandId,
        {
          validTill: mapToAPIDateTime(form.values.distributionValidTill),
        },
        () => {
          setVisible(false);
          onCompleted();
        },
      );
    };
    return (
      <>
        {/*
        // @ts-ignore*/}
        <Dialog onHide={() => setVisible(false)} visible={visible}>
          <RingilH2>Poptat dopravce</RingilH2>
          <HorizontalFieldValue
            label={tr(`ChangeValidTillDialog.untilWhenDoUouNeedAPriceOffer`, `Until when do uou need a price offer`)}
            value={<InputDateAndTime value={form.values.distributionValidTill} onChange={v => form.setFieldValue('distributionValidTill', v)} />}
          />
          <ButtonLayout wrapper="WITH_PADDING_TOP_0">
            <Button label={tr(`CreateChangeValidTillDialog.confirm`, `Potvrdit`)} className="p-mr-3" onClick={() => form.validateAndSend()} />
            <Button label={tr(`CreateChangeValidTillDialog.cancel`, `Zrušit`)} className="p-button-outlined p-button-text" onClick={() => setVisible(false)} />
          </ButtonLayout>
        </Dialog>
      </>
    );
  };

  return { CreateChangeValidTillDialog, setVisible };
};
