import React, { useContext, useEffect, useState } from 'react';
import {
  CarrierQuoteStateEnum,
  ExpeditionDetailCarrierQuotesFilter,
  ExpeditionStatusEnum,
  LoadEWProposalsOutDTO,
  LocationQuotePreviewOutDTO,
  LovDTO,
  QuoteGroupEnum,
  QuotesPreviewOutDTO,
} from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import useTranslationLgs from '../../../../../hooks/i18n/useTranslation';
import { Column } from 'primereact/column';
import { dateFormatDateTime, dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { Button } from 'primereact/button';
import { Dayjs } from 'dayjs';
import { formatFromToAPITime, formatPrice, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { CustomerExpeditionViewContext } from '../CustomerExpeditionView';
import { MultiSelect } from 'primereact/multiselect';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { DescriptionTimeslotsSubTooltip } from '../TabCarrierPending/template';
import { ApiContext } from '@api/api';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { carriersTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';

interface ComponentProps {
  applicationId: string;
  companyId: string;
  disabled: boolean;
}

interface ExpeditionPK {
  applicationId: string;
  companyId: string;
}

const QuoteDataTable: React.FC<ComponentProps> = ({ applicationId, companyId, disabled }) => {
  const {
    cuGetDetailExpeditionCarrierQuotes,
    cuLovCarrierForDetailExpedition,
    cuLovEmailStatus,
    cuLovDemandStateForDetailExpedition,
    loadEurowagProposals,
    declineEurowagQuote,
    acceptEurowagQuote,
  } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [pageData, setPageData] = useState<QuotesPreviewOutDTO[]>([]);
  const [eurowagProposals, setEurowagProposals] = useState<LoadEWProposalsOutDTO>(undefined);

  const [filter, setFilter] = useImmer<ExpeditionDetailCarrierQuotesFilter>({});
  const { state, showPickDialog, showRejectDialog, openCustomerChatWithCarrier, recofirmExpedition } = useContext(CustomerExpeditionViewContext);
  const [expandedRows, setExpandedRows] = useState(null);
  const [reconfirmationRowId, setReconfirmationRowId] = useState<ExpeditionPK>(undefined);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const load = () => {
    cuGetDetailExpeditionCarrierQuotes(companyId, applicationId, filter, data => {
      loadEurowagProposals(companyId, applicationId, d => {
        setPageData(data.quotes);
        setEurowagProposals(d);
      });
    });
  };

  useEffect(() => {
    load();
  }, [filter, state]);

  const rejectEurowagQuote = (id: string) => {
    declineEurowagQuote(companyId, applicationId, id, () => {
      load();
    });
  };

  const pickEurowagQuote = (name: string, id: string) => {
    acceptEurowagQuote(companyId, applicationId, id, () => {
      load();
    });
  };

  const DescriptionDistributionStatus = (emailStatus: string) => {
    return (
      <div className="descrip-distribution-status">
        <span>{tr(`SpotQuoteDataTable.emailStatus.${emailStatus}`, 'SpotQuoteDataTable.emailStatus.${emailStatus}')}</span>
      </div>
    );
  };
  const DescriptionDateTime = (timeStamp: Dayjs) => {
    return (
      <div className="descrip-value">
        <span className="descrip-date text-gray5">{dateFormatDateTime(timeStamp)}</span>
      </div>
    );
  };
  const DescriptionTooltip = (index: number, name: string, status: string, dateTime: Dayjs, email: string) => {
    return (
      <div className="descrip-detail" key={index}>
        <div className="descrip-detail__name">
          <strong>{name}</strong>
        </div>
        <div className="descrip-detail__name">
          <strong>{email}</strong>
        </div>
        <div className="p-d-flex p-jc-between">
          <small className="descrip-detail__status">{tr(`SpotQuoteDataTable.emailStatus.${status}`, 'SpotQuoteDataTable.emailStatus.${status}')}</small>
          <small className="descrip-detail__data">{dateFormatDateTime(dateTime)}</small>
        </div>
      </div>
    );
  };

  const carrierNameTemplate = (rowData: QuotesPreviewOutDTO) => (
    <>
      {rowData.quoteGroup === QuoteGroupEnum.RINGIL && <div>{rowData.name}</div>}
      {rowData.quoteGroup === QuoteGroupEnum.EW && <div>EW {rowData.name} </div>}
    </>
  );
  const communicationTemplate = (rowData: QuotesPreviewOutDTO) => {
    return (
      <div>
        {rowData.emailStatus && (
          <>
            {DescriptionDistributionStatus(rowData.emailStatus)}
            <div className="p-d-flex p-flex-wrap">
              {DescriptionDateTime(mapFromAPIDateTime(rowData.emailStatusCreatedAt))}
              <div className="tooltip">
                <Button label="Detail" className="p-button-text tooltip-button p-p-0" />
                <div className="tooltip-container">
                  <div className="descrip-wrapper">
                    {rowData.emailStatusDetail?.map((item, index) =>
                      DescriptionTooltip(index, item.userName, item.emailStatus, mapFromAPIDateTime(item.time), item.email),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const dateTimeslotsBodyTemplate = (rowData: LocationQuotePreviewOutDTO) => {
    const changedCarrierSlot = rowData.timeslots.find(s => s.isCarrier);
    const customerOriginalSlots = rowData.timeslots.filter(s => !s.isCarrier);

    return (
      <>
        {changedCarrierSlot && (
          <div className="p-d-flex text-orange">
            <strong className="p-pr-1">{dateFormatOnlyDateShort(mapFromAPIDateTime(changedCarrierSlot.sinceInUTC))}</strong>
            <strong>{formatFromToAPITime(changedCarrierSlot.sinceInUTC, changedCarrierSlot.tillInUTC)}</strong>
            <div className="tooltip tooltip-times">
              <span className="p-ml-1">
                <img src="/images/u223.svg" alt="" />
              </span>
              <div className="tooltip-container">
                <div className="descrip-wrapper">
                  <h5 className="p-mt-0 p-mb-0">{tr(`SpotQuoteDataTable.yourRequrestedTimeslots`, `Vaše časová okna`)}</h5>
                  {customerOriginalSlots &&
                    customerOriginalSlots.map((item, index) => {
                      return DescriptionTimeslotsSubTooltip(
                        index,
                        dateFormatOnlyDateShort(mapFromAPIDateTime(item.sinceInUTC)),
                        item.isNotSpecifiedTime ? tr(`SpotQuoteDataTable.timeWasNotSpecified`, `Čas nebyl zadán`) : formatFromToAPITime(item.sinceInUTC, item.tillInUTC),
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}

        {!changedCarrierSlot && (
          <div>
            <strong className="p-pr-1"> {tr(`TabCarrierPending.notChangedSlots`, `Sloty nezměněny`)} </strong>
          </div>
        )}
      </>
    );
  };

  const renderPricePerKM = (data: QuotesPreviewOutDTO) => {
    const price = data.offeredPrice / data.distance;
    return (
      <>
        {isNotNullOrUndefined(data.offeredPrice) && data.distance > 0 ? (
          <div>
            <div>{tr(`QuoteDataTable.approximatePricePerKM`, `Approximate price per km:`)}</div>
            <div>{formatPrice(price, data.currency)}</div>
          </div>
        ) : (
          <div>
            <div>{tr(`QuoteDataTable.approximatePricePerKM`, `Approximate price per km:`)}</div>
            <div>{tr(`QuoteDataTable.cannotBeQuantified`, `Cannot be quantified`)}</div>
          </div>
        )}
      </>
    );
  };

  const differentTimeslotExpansionTableTemplate = (data: QuotesPreviewOutDTO) => {
    return (
      <div className="timeslots-subtable">
        {data.locations.length > 0 ? (
          <>
            <h4 className="p-mt-0">
              {tr(`SpotQuoteDataTable.companyProposedTimeslots`, 'Navrhovane sloty pro {{company}}', {
                company: data.name,
              })}
            </h4>
            {/*//
      @ts-ignore*/}
            <DataTable value={data.locations} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
              <Column field="name" style={{ width: '100px' }}></Column>
              <Column field="city" className="timeslots-subtable-route"></Column>
              <Column body={dateTimeslotsBodyTemplate}></Column>
            </DataTable>
          </>
        ) : (
          <>
            <h5 className="p-text-center no-slots">{tr(`SpotQuoteDataTable.notProposedTimeslots`, `Žádné časové sloty!`)}</h5>
          </>
        )}
      </div>
    );
  };

  const spotPriceTemplate = (rowData: QuotesPreviewOutDTO) => {
    if (rowData.carrierQuoteState === CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER)
      return (
        <>
          <div>{formatPrice(rowData.offeredPrice, rowData.currency)}</div>
          <div>
            {tr(`QuoteDataTable.acceptedByCustomerWithDate`, `Akceptováno {{date}}`, {
              date: dateFormatDateTime(mapFromAPIDateTime(rowData.acceptedAt)),
            })}
          </div>
          {rowData.isReconfirmedByCarrier && (
            <div>
              {tr(`QuoteDataTable.carrierReconfirmedAt`, `Dopravce potvrdil přijetí přepravy {{when}}`, {
                when: dateFormatDateTime(mapFromAPIDateTime(rowData.reconfirmedAt)),
              })}
            </div>
          )}
          {rowData.reconfirmedAt && !rowData.isReconfirmedByCarrier && (
            <div>
              {tr(`QuoteDataTable.customerReconfirmedAt`, `Zákazníkem potvrdil za dopravce potvrdil přijetí přepravy {{when}}`, {
                when: dateFormatDateTime(mapFromAPIDateTime(rowData.reconfirmedAt)),
              })}
            </div>
          )}
        </>
      );
    if (rowData.carrierQuoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM)
      return (
        <>
          <div>{formatPrice(rowData.offeredPrice, rowData.currency)}</div>
          <div>
            {tr(`QuoteDataTable.acceptedByCustomerWithDate`, `Akceptováno {{date}}`, {
              date: dateFormatDateTime(mapFromAPIDateTime(rowData.acceptedAt)),
            })}
          </div>
          <div>{tr(`QuoteDataTable.needReconfirmationLabel`, `Očekáváno přijetí přepravy dopravcem`)}</div>
          <button
            className="p-button p-component p-button-text tooltip-button p-p-0"
            onClick={e => setReconfirmationRowId({ applicationId, companyId })}
            disabled={
              state.header.isManualExpedition || state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED || loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1
            }
          >
            <span className="p-button-label p-c">{tr(`QuoteDataTable.reconfirmAsCustomerButton`, `Potvrdit za dopravce`)}</span>
          </button>
        </>
      );
    if (rowData.carrierQuoteState === CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER)
      return (
        <>
          {!isNullOrUndefined(rowData.offeredPrice) && <div>{formatPrice(rowData.offeredPrice, rowData.currency)}</div>}
          <div>
            {tr(`QuoteDataTable.rejectedByCustomerWithDate`, `Odmítnuto {{date}}`, {
              date: dateFormatDateTime(mapFromAPIDateTime(rowData.rejectedByCustomerAt)),
            })}
          </div>
        </>
      );
    if (rowData.carrierQuoteState === CarrierQuoteStateEnum.REJECTED_BY_CARRIER)
      return (
        <div>
          {tr(`QuoteDataTable.rejectedByCarrierWithDate`, `Dopravce odmítnul {{date}}`, {
            date: dateFormatDateTime(mapFromAPIDateTime(rowData.carrierActionAt)),
          })}
        </div>
      );
    if (rowData.carrierQuoteState === CarrierQuoteStateEnum.OFFERED_PRICE)
      return (
        <>
          <div>{formatPrice(rowData.offeredPrice, rowData.currency)}</div>
          {!rowData.isExpired && (
            <div>
              {tr(`QuoteDataTable.offeredPriceValidWithDate`, `Nabídka platí do {{date}}`, {
                date: dateFormatDateTime(mapFromAPIDateTime(rowData.validTill)),
              })}
            </div>
          )}
          {rowData.isExpired && (
            <div>
              {tr(`QuoteDataTable.offeredPriceInvalidWithDate`, `Nabídka vypršela {{date}}`, {
                date: dateFormatDateTime(mapFromAPIDateTime(rowData.validTill)),
              })}
            </div>
          )}
        </>
      );
    return <></>;
  };

  const differentTimeslotsBodyTemplate = (rowData: QuotesPreviewOutDTO) => {
    const hasDifferentSlot = rowData.locations.filter(location => location.isDifferent).length > 0;
    return (
      <>
        {hasDifferentSlot && (
          <div>
            <img src="/images/u218.svg" className="p-mr-2" />
            <span className="">{tr('customerExpeditionDetail.tabCarrierPending.differentSlots', 'Odlišné sloty')}</span>
          </div>
        )}
      </>
    );
  };

  const buttonBodyTemplate = (rowData: QuotesPreviewOutDTO) => {
    return (
      <>
        {rowData.quoteGroup === QuoteGroupEnum.RINGIL && (
          <>
            {rowData.carrierQuoteState === CarrierQuoteStateEnum.OFFERED_PRICE && !rowData.isExpired && (
              <>
                <Button
                  label={tr('customerExpeditionDetail.tabCarrierPending.pickButton', 'Vybrat')}
                  className="p-mr-2"
                  icon="pi pi-check"
                  onClick={() => showPickDialog(rowData.name, rowData.id)}
                  disabled={disabled}
                />
                <Button
                  label={tr('customerExpeditionDetail.tabCarrierPending.rejectButton', 'Odmítnout')}
                  icon="pi pi-times"
                  onClick={() => showRejectDialog(rowData.name, rowData.id)}
                  disabled={disabled}
                />
              </>
            )}
            <Button
              label={
                rowData.messagesCount === 0
                  ? tr('global.messageButtonZero', 'Napsat zprávu')
                  : tr('global.messageButtonWithCount', 'Zprav {{count}}', {
                      count: rowData.messagesCount,
                    })
              }
              className="p-button-text"
              onClick={() => {
                openCustomerChatWithCarrier(rowData.carrierId);
              }}
            />
          </>
        )}

        {rowData.quoteGroup === QuoteGroupEnum.EW && (
          <>
            {rowData.carrierQuoteState === CarrierQuoteStateEnum.OFFERED_PRICE && !rowData.isExpired && (
              <>
                <Button
                  label={tr('customerExpeditionDetail.tabCarrierPending.pickButton', 'Vybrat')}
                  className="p-mr-2"
                  icon="pi pi-check"
                  onClick={() => pickEurowagQuote(rowData.name, rowData.id)}
                  disabled={disabled}
                />
                <Button
                  label={tr('customerExpeditionDetail.tabCarrierPending.rejectButton', 'Odmítnout')}
                  icon="pi pi-times"
                  onClick={() => rejectEurowagQuote(rowData.id)}
                  disabled={disabled}
                />
              </>
            )}
          </>
        )}
      </>
    );
  };

  const [carriers, setCarriers] = useState<LovDTO[]>([]);
  const [demandStates, setDemandStates] = useState<string[]>([]);
  const [emailStatuses, setEmailStatuses] = useState<string[]>([]);

  const confirm = () => {
    recofirmExpedition(companyId, applicationId, () => {
      setReconfirmationRowId(undefined);
      load();
    });
  };

  useEffect(() => {
    cuLovCarrierForDetailExpedition(companyId, applicationId, data => {
      setCarriers(data);
    });
    cuLovDemandStateForDetailExpedition(companyId, applicationId, data => {
      setDemandStates(data);
    });
    cuLovEmailStatus(data => {
      setEmailStatuses(data);
    });
  }, [state]);

  return (
    <>
      <StyledTable>
        {/*//
      @ts-ignore*/}
        <DataTable
          emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
          value={pageData}
          lazy={true}
          /*loading={loading['cuGetDetailExpeditionCarrierQuotes']}*/
          rowExpansionTemplate={differentTimeslotExpansionTableTemplate}
          onRowToggle={e => setExpandedRows(e.data)}
          expandedRows={expandedRows}
          filterDisplay="row"
          className="responsive-table"
        >
          <Column
            body={carrierNameTemplate}
            filter={true}
            filterElement={
              <MultiSelect
                showClear={true}
                options={carriers}
                optionValue={'id'}
                optionLabel={'name'}
                itemTemplate={carriersTemplate}
                style={{ maxWidth: '100%' }}
                value={filter.carrierIds}
                placeholder={tr('QuoteDataTable.chooseCarrieers', 'Vyberte dopravce')}
                onChange={e => {
                  setFilter(draft => {
                    if (e.value == null) {
                      draft.carrierIds = [];
                    } else {
                      draft.carrierIds = e.value;
                    }
                    /*e.value == null
                      ? (draft.carrierIds = [])
                      : (draft.carrierIds = e.value);*/
                  });
                }}
              />
            }
          ></Column>
          <Column
            body={communicationTemplate}
            filter={true}
            filterElement={
              <MultiSelect
                options={emailStatuses.map(t => {
                  return {
                    id: t,
                    name: tr(`EmailStatus.${t}`, `EmailStatus.${t}`),
                  };
                })}
                optionValue={'id'}
                optionLabel={'name'}
                style={{ maxWidth: '100%' }}
                value={filter.emailStatuses}
                placeholder={tr('QuoteDataTable.demandState', 'Stav odeslane poptavky')}
                onChange={e => {
                  setFilter(draft => {
                    draft.emailStatuses = e.value;
                  });
                }}
              />
            }
          ></Column>
          <Column
            body={spotPriceTemplate}
            filter={true}
            filterElement={
              <MultiSelect
                options={demandStates.map(t => {
                  return {
                    id: t,
                    name: tr(`EmailStatus.${t}`, `EmailStatus.${t}`),
                  };
                })}
                optionValue={'id'}
                optionLabel={'name'}
                style={{ maxWidth: '100%' }}
                value={filter.demandPseudoStates}
                placeholder={tr('QuoteDataTable.demand', 'Nabidka')}
                onChange={e => {
                  setFilter(draft => {
                    draft.demandPseudoStates = e.value;
                  });
                }}
              />
            }
          ></Column>
          <Column body={differentTimeslotsBodyTemplate} />
          <Column body={renderPricePerKM} />
          <Column body={differentTimeslotExpansionTableTemplate} className="timeslots-in-cell" />
          <Column expander className="price-quotes-table__expander" />
          <Column body={buttonBodyTemplate} className={'actions'} />
        </DataTable>
      </StyledTable>
      <ConfirmDialog
        visible={!isNullOrUndefined(reconfirmationRowId)}
        dialogTitle={tr(`QuoteDataTable.reconfirmDialogTitle`, `Potvrzení přijatí přepravy dopravcem`)}
        dialogMessage={tr(`QuoteDataTable.reconfirmDialogBody`, `Potvrzujete, že jste s dopravcem ověřil/a plán realizovat přepravu.`)}
        onAcceptButtonText={tr(`QuoteDataTable.accept`, `Potvrdit`)}
        onCancelButtonText={tr(`QuoteDataTable.back`, `Zpět`)}
        onCancel={() => setReconfirmationRowId(undefined)}
        onConfirm={() => confirm()}
      />
    </>
  );
};
const StyledTable = styled.div`
  .p-datatable .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 1rem 1rem;
  }

  .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .actions {
    width: 300px;
  }

  .timeslots-in-cell {
    display: none;
  }

  @media only screen and (max-width: 960px) {
    .timeslots-in-cell {
      display: block;
    }

    .timeslots-in-cell .timeslots-subtable tr td {
      display: table-cell;
    }

    .p-datatable .p-datatable-tbody > tr > td.price-quotes-table__expander {
      display: none !important;
    }

    .timeslots-in-cell .no-slots {
      display: none !important;
    }

    .p-datatable .p-datatable-tbody tr:nth-child(even) {
      background-color: #f8f9fa;
    }
  }
`;

export default QuoteDataTable;
