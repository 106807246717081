import React, { PropsWithChildren } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {}

const Card: React.FC<PropsWithChildren<ComponentProps>> = ({ children }) => {
  const { tr } = useTranslationLgs();
  return <div className={'rounded-2xl bg-base-secondary py-6 px-4 w-full'}> {children}</div>;
};

export default Card;
