import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import CurrencyDropdown from '@components/ringil3/Input/InputPriceWithCurrency/CurrencyDropdown.tsx';

interface ComponentProps {
  value: UIPriceWithCurrency;
  onChange: (value: UIPriceWithCurrency) => void;
  disabled?: boolean;
}

export interface UIPriceWithCurrency {
  amount: number;
  currencyCode: string;
}
// OBSOLETE
const InputPricePlain: React.FC<ComponentProps> = ({ value, onChange, disabled = false }) => {
  const changeAmount = (amount: number) => {
    onChange({ ...value, amount: amount });
  };
  const changeCurrency = (currencyCode: string) => {
    onChange({ ...value, currencyCode: currencyCode });
  };

  return (
    <>
      <InputNumber value={value?.amount} onChange={e => changeAmount(e.value)} minFractionDigits={2} maxFractionDigits={2} locale={'cs'} disabled={disabled} />
      <CurrencyDropdown selectedCurrenry={value?.currencyCode} onSelected={v => changeCurrency(v)} required={false} disabled={disabled} />
    </>
  );
};

export default InputPricePlain;
