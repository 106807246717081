import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import { Chip } from 'primereact/chip';
import { CustomerTemplatePreviewOutDTO, GlobalTemplatePreviewOutDTO } from '@api/logsteo-api.v2';
import { Link, useParams } from 'react-router-dom';

interface ComponentProps {}

const AdminCustomerI18nTemplatesList: React.FC<ComponentProps> = () => {
  const { customerId } = useParams();
  const reg = /([\w]+)-([\w]+)/;
  const matches = customerId.match(reg);

  const [data, setData] = useState<CustomerTemplatePreviewOutDTO[]>();
  const { adminCompanyListTemplate, adminCompanyDeleteTemplate } = useContext(ApiContext);
  useEffect(() => {
    load();
  }, []);

  const load = () => {
    adminCompanyListTemplate(matches[2], 'CUSTOMER', data => {
      setData(data);
    });
  };

  const renderLanguages = (row: CustomerTemplatePreviewOutDTO) => (
    <LanguageRow>
      {row.languages.map((item, index) => {
        return <Language key={index}>{item.language}</Language>;
      })}
    </LanguageRow>
  );

  return (
    <>
      <h1>I18N templates</h1>
      <DataTableWrapper>
        {/*// @ts-ignore*/}
        <DataTable value={data}>
          <Column header={'ID'} body={(row: CustomerTemplatePreviewOutDTO) => <Link to={`/admin/${customerId}/templating/${row.emailType}`}>{row.emailType}</Link>} />
          <Column
            body={(row: CustomerTemplatePreviewOutDTO) => (
              <>
                {row.isDefault ? (
                  <Chip label={'GLOBAL'} style={{ backgroundColor: '#efefef' }}></Chip>
                ) : (
                  <Chip label={'CUSTOMER'} style={{ backgroundColor: '#00a000', color: 'white' }}></Chip>
                )}
              </>
            )}
            header={'isGlobal'}
            style={{ width: '10rem' }}
          />
          <Column body={renderLanguages} header={'Languages'} style={{ width: '10rem' }} />
          <Column body={(row: GlobalTemplatePreviewOutDTO) => <div>{row.description}</div>} header={'Description'} />
          <Column body={(row: CustomerTemplatePreviewOutDTO) => <div>{row.fallback}</div>} header={'Fallback'} style={{ width: '10rem' }} />
          <Column
            body={(row: CustomerTemplatePreviewOutDTO) => (
              <div>
                {row.fallbackOverride.map((t, key) => (
                  <Chip key={key} label={`${t.from}->${t.to}`} />
                ))}
              </div>
            )}
            header={'Fallback override'}
            style={{ width: '15rem' }}
          />
        </DataTable>
      </DataTableWrapper>
    </>
  );
};

const DataTableWrapper = styled.div`
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Language = styled.div`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;
const LanguageRow = styled.div`
  display: flex;
`;

export default AdminCustomerI18nTemplatesList;
