import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import AdminLayout from '@app/layout/Layout.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Intro from '@app/layout/Intro.tsx';
import CamsDetail from '@app/pages/cams/CamsDetail.tsx';

interface ComponentProps {}

const CamsPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <AdminLayout title={tr(`CamsPage.title`, `Entry log`)} oneOfPermission={[PermissionTypesEnum.PERMISSION_CAM_ENTRYLOG, PermissionTypesEnum.PERMISSION_CAM_SETTINGS]}>
        <CamsDetail />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(CamsPage, {
  onRedirecting: () => <Intro />,
});
