import React, { useContext } from 'react';
import CreateTemplateHandlerProvider, { CreateTemplateHandlerContext } from './CreateTemplateHandler';
import { PageID, RouteType } from '../new-expedition/types';
import { Card } from 'primereact/card';
import CreateTemplateStep3Header from './CreateTemplateStep3Header';
import CreateTemplateStep3Manipulation from './CreateTemplateStep3Manipulation';
import CreateTemplateStep4 from './CreateTemplateStep4';
import { createEmptyLocation } from './types';
import CreateTemplateStep2 from './CreateTemplateStep2';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import WizardBreadCrumbComponent from '@components/obsolete/WizardBreadCrumbComponent/WizardBreadCrumbComponent.tsx';

interface ComponentProps {}

interface BreadCrumbDefinition {
  label: string;
  url?: string;
  visibleOn: string[];
  clickable: string[];
  step?: string;
}

const CreateTemplateInner: React.FC<ComponentProps> = () => {
  const { state, clickOnBreadCrumb } = useContext(CreateTemplateHandlerContext);
  const { t } = useTranslation();

  const allSteps: BreadCrumbDefinition[] = [
    {
      label: t(`CreateTemplate.newTemplate`, `New template`),
      visibleOn: [PageID.STEP2, PageID.STEP3, PageID.STEP4],
      clickable: [PageID.STEP3, PageID.STEP4],
      step: PageID.STEP2,
    },
    {
      label: t('CreateTemplate.load', 'Load'),
      visibleOn: [PageID.STEP2, PageID.STEP3, PageID.STEP4],
      clickable: [PageID.STEP4],
      step: PageID.STEP3,
    },
    {
      label: t('CreateTemplate.overview', 'Overview'),
      visibleOn: [PageID.STEP2, PageID.STEP3, PageID.STEP4],
      clickable: [],
    },
  ];

  return (
    <>
      {state.currentPage === PageID.STEP2 && (
        <Layout title={t(`CreateTemplate.route`, `Route`)}>
          <WizardBreadCrumbComponent
            model={allSteps}
            currentStep={state.currentPage}
            // @ts-ignore
            onClick={step => clickOnBreadCrumb(PageID[step])}
          />
          {/*// @ts-ignore*/}
          <Card>
            <CreateTemplateStep2 locations={state.locations} routeType={state.routeType} />
          </Card>
        </Layout>
      )}
      {state.currentPage === PageID.STEP3 && (
        <Layout title={t(`CreateTemplate.load`, `Load`)}>
          <WizardBreadCrumbComponent
            model={allSteps}
            currentStep={state.currentPage}
            // @ts-ignore
            onClick={step => clickOnBreadCrumb(PageID[step])}
          />
          {/*// @ts-ignore*/}
          <Card title={t(`CreateTemplate.createTemplate`, `Create template`)}>
            <CreateTemplateStep3Header
              expeditionType={state.expeditionType}
              truckType={state.truckType}
              manipulationTypes={state.manipulationTypes}
              specialRequirements={state.specialRequirements}
              cargoType={state.cargoType}
              carrierNote={state.carrierNote}
            />
          </Card>
          {/*// @ts-ignore*/}
          <Card title={t(`CreateTemplate.locationManipulation`, `Location manipulation`)} className="p-mt-4">
            <CreateTemplateStep3Manipulation expeditionType={state.expeditionType} />
          </Card>
        </Layout>
      )}

      {state.currentPage === PageID.STEP4 && (
        <Layout title={t(`CreateTemplate.overview`, `Overview`)}>
          <WizardBreadCrumbComponent
            model={allSteps}
            currentStep={state.currentPage}
            // @ts-ignore
            onClick={step => clickOnBreadCrumb(PageID[step])}
          />
          <CreateTemplateStep4 />
        </Layout>
      )}
    </>
  );
};

const CreateTemplate: React.FC<ComponentProps> = () => {
  return (
    <CreateTemplateHandlerProvider
      initialData={{
        currentPage: PageID.STEP2,
        locations: [createEmptyLocation(0, true, false), createEmptyLocation(1, false, true)],
        routeType: RouteType.DIRECT,
      }}
    >
      <CreateTemplateInner />
    </CreateTemplateHandlerProvider>
  );
};

export default CreateTemplate;
