import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

interface CalendarInputProps {
  includeTime?: boolean;
  enableRange?: boolean;
  currentLocalDate?: string; // ISO format: 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:mm'
  onChange?: (date: string) => void;
  onChangeRange?: (date: { start: string | null; end: string | null }) => void;
}

interface DateInfo {
  year: number;
  month: number;
  day: number;
  hours?: number;
  minutes?: number;
}

const CalendarInput: React.FC<CalendarInputProps> = ({ includeTime = false, enableRange = false, currentLocalDate, onChange, onChangeRange }) => {
  const parseDateString = (dateString: string | null | undefined): DateInfo | null => {
    if (!dateString || typeof dateString !== 'string') return null;
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    let hours, minutes;
    if (timePart) {
      [hours, minutes] = timePart.split(':').map(part => parseInt(part, 10));
    }
    return { year, month: month - 1, day, hours, minutes };
  };

  const [selectedDate, setSelectedDate] = useState<DateInfo | null>(currentLocalDate ? parseDateString(currentLocalDate) : null);
  const [selectedRange, setSelectedRange] = useState<{ start: DateInfo | null; end: DateInfo | null }>({
    start: null,
    end: null,
  });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isSelectingEnd, setIsSelectingEnd] = useState(false);
  const [currentMonth, setCurrentMonth] = useState<{ year: number; month: number }>({
    year: selectedDate ? selectedDate.year : new Date().getFullYear(),
    month: selectedDate ? selectedDate.month : new Date().getMonth(),
  });
  const calendarRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (enableRange && selectedRange.start && selectedRange.end) {
      const formatDate = (date: DateInfo | null) => (date ? `${date.year}-${String(date.month + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}` : null);
      if (isNotNullOrUndefined(onChangeRange)) {
        onChangeRange({ start: formatDate(selectedRange.start), end: formatDate(selectedRange.end) });
      }
    } else if (!enableRange && selectedDate) {
      const formattedDate = `${selectedDate.year}-${String(selectedDate.month + 1).padStart(2, '0')}-${String(selectedDate.day).padStart(2, '0')}`;
      if (includeTime && selectedDate.hours !== undefined && selectedDate.minutes !== undefined) {
        onChange(`${formattedDate}T${String(selectedDate.hours).padStart(2, '0')}:${String(selectedDate.minutes).padStart(2, '0')}`);
      } else {
        onChange(formattedDate);
      }
    } else if (!selectedDate) {
      /*if (enableRange) {
        onChangeRange({ start: null, end: null });
      } else {
        onChange(null);
      }*/
    }
  }, [selectedDate, selectedRange, enableRange, includeTime, onChange, onChangeRange]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node) && inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsCalendarOpen(false);
      }
    };

    if (isCalendarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarOpen]);

  const handleDateChange = (date: DateInfo) => {
    console.log('handleDateChange', date);
    if (enableRange) {
      if (!isSelectingEnd) {
        setSelectedRange({ start: date, end: null });
        setIsSelectingEnd(true);
        console.log('!isSelectingEnd', date);
      } else {
        console.log('else', date);
        setSelectedRange(prev => ({ ...prev, end: date }));
        setIsSelectingEnd(false);
        setIsCalendarOpen(false);
        // Trigger onChange when both start and end dates are selected
        if (onChangeRange && selectedRange.start) {
          const formatDate = (date: DateInfo | null) => (date ? `${date.year}-${String(date.month + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}` : null);
          console.log('onChangeRange && selectedRange.start', date);
          onChangeRange({ start: formatDate(selectedRange.start), end: formatDate(date) });
        }
      }
    } else {
      setSelectedDate(date);
      setIsCalendarOpen(false);
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth(prev => {
      const newMonth = prev.month - 1;
      return newMonth < 0 ? { year: prev.year - 1, month: 11 } : { year: prev.year, month: newMonth };
    });
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => {
      const newMonth = prev.month + 1;
      return newMonth > 11 ? { year: prev.year + 1, month: 0 } : { year: prev.year, month: newMonth };
    });
  };

  const renderCalendar = () => {
    const { year, month } = currentMonth;
    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month + 1, 0);

    // Get the day index for the first day of the month (0 = Sunday, 1 = Monday, etc.)
    const startDayIndex = (startOfMonth.getDay() + 6) % 7; // Adjust to start from Monday
    const days = [];

    // Add empty slots for the days before the start of the month
    for (let i = 0; i < startDayIndex; i++) {
      days.push(null);
    }

    // Add all the days of the current month
    for (let day = 1; day <= endOfMonth.getDate(); day++) {
      days.push({ year, month, day });
    }

    const dayNames = ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'];

    const inputRect = inputRef.current?.getBoundingClientRect();

    return createPortal(
      <div
        ref={calendarRef}
        className="fixed z-50 bg-white border border-gray-300 shadow-md mt-1 p-4"
        style={{
          top: inputRect ? `${inputRect.bottom + window.scrollY}px` : '50%',
          left: inputRect ? `${inputRect.left + window.scrollX}px` : '50%',
          transform: inputRect ? 'none' : 'translate(-50%, -50%)',
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}>
        <div className="flex justify-between items-center mb-2">
          <button onClick={handlePrevMonth} className="p-1 bg-gray-200 rounded">
            &lt;
          </button>
          <span className="font-medium">
            {new Date(year, month).toLocaleString('cs-CZ', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
          <button onClick={handleNextMonth} className="p-1 bg-gray-200 rounded">
            &gt;
          </button>
        </div>
        <div className="grid grid-cols-7 gap-2 mb-2">
          {dayNames.map(dayName => (
            <div key={dayName} className="text-center font-medium">
              {dayName}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-2">
          {days.map((day, index) =>
            day ? (
              <div
                key={index}
                onClick={() => handleDateChange(day)}
                className={`p-2 flex justify-center items-center cursor-pointer  ${
                  enableRange
                    ? (selectedRange.start &&
                        selectedRange.end &&
                        (day.year > selectedRange.start.year ||
                          (day.year === selectedRange.start.year && day.month > selectedRange.start.month) ||
                          (day.year === selectedRange.start.year && day.month === selectedRange.start.month && day.day >= selectedRange.start.day)) &&
                        (day.year < selectedRange.end.year ||
                          (day.year === selectedRange.end.year && day.month < selectedRange.end.month) ||
                          (day.year === selectedRange.end.year && day.month === selectedRange.end.month && day.day <= selectedRange.end.day))) ||
                      (day.year === selectedRange.start?.year && day.month === selectedRange.start?.month && day.day === selectedRange.start?.day) ||
                      (day.year === selectedRange.end?.year && day.month === selectedRange.end?.month && day.day === selectedRange.end?.day)
                      ? 'bg-content-brand text-white'
                      : 'bg-gray-100'
                    : selectedDate && day.year === selectedDate.year && day.month === selectedDate.month && day.day === selectedDate.day
                    ? 'bg-content-brand text-white'
                    : 'bg-gray-100'
                }`}>
                {day.day}
              </div>
            ) : (
              <div key={index} className="p-2"></div>
            ),
          )}
        </div>
      </div>,
      document.body,
    );
  };

  return (
    <div className="relative inline-block" ref={inputRef}>
      <div className="flex items-center cursor-pointer" onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
        <input
          type="text"
          value={
            enableRange
              ? selectedRange.start && selectedRange.end
                ? `${String(selectedRange.start.day).padStart(2, '0')}.${String(selectedRange.start.month + 1).padStart(2, '0')}.${selectedRange.start.year} - ${String(
                    selectedRange.end.day,
                  ).padStart(2, '0')}.${String(selectedRange.end.month + 1).padStart(2, '0')}.${selectedRange.end.year}`
                : selectedRange.start
                ? `${String(selectedRange.start.day).padStart(2, '0')}.${String(selectedRange.start.month + 1).padStart(2, '0')}.${selectedRange.start.year} - ...`
                : ''
              : selectedDate
              ? `${String(selectedDate.day).padStart(2, '0')}.${String(selectedDate.month + 1).padStart(2, '0')}.${selectedDate.year}` +
                (includeTime && selectedDate.hours !== undefined && selectedDate.minutes !== undefined
                  ? `T${String(selectedDate.hours).padStart(2, '0')}:${String(selectedDate.minutes).padStart(2, '0')}`
                  : '')
              : ''
          }
          readOnly
          placeholder="Vyberte datum"
          className="border-content-quaternary p-2 w-[170px] border border-solid h-[38px]"
        />
        <button type="button" className="h-[38px] border-0 bg-content-brand text-white px-3 items-center justify-center rounded-br-[3px] rounded-tr-[3px]">
          <span className="p-button-icon p-c pi pi-calendar"></span>
        </button>
      </div>
      {isCalendarOpen && renderCalendar()}
    </div>
  );
};

export default CalendarInput;
