import React, { useContext, useEffect } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import dayjs from 'dayjs';
import { isNotNullOrUndefined, mapFromDateToDayjs } from '@utils/utils';
import { shortDateFormat } from '@utils/date';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DocumentTypeEnum } from '@api/logsteo-api.v2';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ButtonRow } from '@components/styles';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { InputDocumentType } from '@components/obsolete/Form/InputDocumentType/InputDocumentType.tsx';

interface ComponentProps {
  documentId: string;
  onHide: () => void;
}

interface Form {
  userDate: string;
  userNote: string;
  documentType: DocumentTypeEnum;
}

const EditDocumentMetadata: React.FC<ComponentProps> = ({ documentId, onHide }) => {
  const { tr } = useTranslationLgs();
  const { getDocumentMetadata, updateDocumentMetadata } = useContext(ApiContext);

  const names = proxiedPropertiesOf<Form>();
  const form = useForm<Form>({}, { userNote: '', userDate: null, documentType: null }, d => save(d), false, false);

  useEffect(() => {
    if (documentId) {
      getDocumentMetadata(documentId, d => {
        form.setValues({
          userDate: d.userDate,
          userNote: d.userNote,
          documentType: d.documentType,
        } as Form);
      });
    }
  }, [documentId]);

  const save = (data: Form) => {
    updateDocumentMetadata(
      documentId,
      {
        userNote: data.userNote,
        userDate: data.userDate,
        documentType: data.documentType,
      },
      () => {
        onHide();
      },
    );
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog visible={isNotNullOrUndefined(documentId)} onHide={onHide} style={{ width: '450px' }} header={tr('EditDocumentMetadata.title', 'Copying a document')}>
        {form.calledSetValues && (
          <>
            <HorizontalFieldValue
              label={tr('EditDocumentMetadata.documentType', 'Document type')}
              value={<InputDocumentType documentType={form.values.documentType} onChange={v => form.setFieldValue(names.documentType, v.documentType)} />}
            />
            <HorizontalFieldValue
              label={tr('EditDocumentMetadata.date', 'Date')}
              value={
                <Calendar
                  showIcon={true}
                  value={form.values.userDate ? dayjs(form.values.userDate).toDate() : null}
                  onChange={e => form.setFieldValue(names.userDate, mapFromDateToDayjs(e.value as Date).toISOString())}
                  dateFormat={shortDateFormat}
                  selectionMode={'single'}
                  style={{ width: '100%' }}
                  locale={'cs'}
                  hideOnDateTimeSelect={true}
                />
              }
            />
            <HorizontalFieldValue
              label={tr('EditDocumentMetadata.note', 'Note')}
              value={
                <InputTextarea
                  style={{ width: '100%', height: '100%' }}
                  value={form.values.userNote}
                  onChange={e => form.setFieldValue(names.userNote, e.target.value)}
                />
              }
            />
            <ButtonRow>
              <Button label={tr(`EditDocumentMetadata.save`, `Save`)} onClick={e => form.validateAndSend()} />
              <Button label={tr(`EditDocumentMetadata.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
            </ButtonRow>
          </>
        )}
      </Dialog>
    </>
  );
};

export default EditDocumentMetadata;
