import React from 'react';
import { FindCarrierCarrierOrGroupDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';
import useCustomerCarriers from '@hooks/customer-carrier/customer-carriers.tsx';

interface ComponentProps {
  value: string;
  onSelected: (value: string) => void;
  isDisabled: boolean;
  placeholder: string;
}

export const carrierTemplate = (option: FindCarrierCarrierOrGroupDTO) => {
  return (
    <div className="p-d-flex p-ai-center">
      <i className="pi pi-id-card"></i>
      <span className="p-ml-2">{option.name}</span>
    </div>
  );
};

export const SelectCarrier: React.FC<ComponentProps> = ({ value, onSelected, placeholder, isDisabled }) => {
  const carriersAndGroups = useCustomerCarriers();

  return (
    <Dropdown
      value={value}
      options={carriersAndGroups?.carriers}
      optionLabel="name"
      optionValue="id"
      filter
      onChange={e => onSelected(e.value)}
      style={{ width: '100%' }}
      disabled={isDisabled}
      itemTemplate={carrierTemplate}
    />
  );
};
