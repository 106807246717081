export interface ComponentProps {
  content: string | number | JSX.Element;
  boldContent?: boolean;
}
export const ColumnContent: React.FC<ComponentProps> = ({ content, boldContent = false }) => {
  return (
    <>
      {boldContent ? (
        <div className="p-column-content">
          <strong>{content}</strong>
        </div>
      ) : (
        <div className="p-column-content">{content}</div>
      )}
    </>
  );
};
