import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import LabelsTag from '@components/ringil3/Tags/LabelsTag.tsx';
import {
  AccessTypeEnum,
  CompanyModuleEnum,
  DAPSupplierNotificationInDTO,
  EntityTypeEnum,
  GlobalTimeslotsDTO,
  LovCompanyLocationWithOpeningHours,
  LovDTO,
  OpeningEntityTypeEnum,
  TransportationTypeEnum } from
'@api/logsteo-api.v2.tsx';
import Heading from '@components/ringil3/Heading/Heading.tsx';
import SvgLkw from '@components/ringil3/icons/heading/Lkw.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { validation } from '@app/pages/dap/validation.ts';
import useForm from '@hooks/useForm/useForm.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';
import ShipmentNumberTag from '@app/pages/dap/tags/ShipmentNumberTag.tsx';
import CarrierNameTag from '@app/pages/dap/tags/CarrierNameTag.tsx';
import InputTransportationType from '@components/framework/input/InputTransportationType/InputTransportationType.tsx';
import Card from '@components/framework/card/Card.tsx';
import SvgMapPin from '@components/framework/icons/MapPin.tsx';
import SectionPanel from '@components/framework/panels/SectionPanel.tsx';
import SvgCalendar from '@app/pages/dap/icons/Calendar.tsx';
import InputEntityOwner from '@components/obsolete/Form/InputEntityOwner/InputEntityOwner.tsx';
import InputInheriterCompany from '@components/obsolete/Form/InputEntityOwner/InputInheriterCompany.tsx';
import InputCompanyLocation from '@components/obsolete/Form/InputEntityOwner/InputCompanyLocation.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { Order } from '@components/framework/orders/common.tsx';
import AttachmentUploadPanel from '@app/pages/attachment/AttachmentUploadPanel.tsx';
import AttachmentsList, { AttachmentListDTO } from '@app/pages/attachment/AttachmentsList.tsx';
import SvgInternalInfoHeadling from '@components/framework/icons/InternalInfoHeadling.tsx';
import ResponsiblePersonTag from '@components/features/tags/ResponsiblePersonTag.tsx';
import InternalInfoNotifyTag from '@components/features/tags/InternalInfoNotifyTag.tsx';
import InternalInfoNotifyByRoleTag from '@components/features/tags/InternalInfoNotifyByRoleTag.tsx';
import { RoleEnum } from '@type/enums.ts';
import InternalInfoGoodsInvoiceTag, { GoodsInvoiceDTO } from '@components/features/tags/InternalInfoGoodsInvoiceTag.tsx';
import InternalNoteTag from '@components/features/tags/InternalNoteTag.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { dapOrderConfig } from '@app/pages/dap/config/orders.ts';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  initialData: SupplierNotificationDapForm;
}

export interface SupplierNotificationDapForm {
  companyId: string;
  supplierId: string;
  carrierName: string;
  attachments: AttachmentListDTO[];
  notifyData?: {
    responsiblePerson?: LovDTO;
    userToNotify?: LovDTO[];
    notifyUsersDependsOnRole: boolean;
  };
  transportationType: TransportationTypeEnum;
  shipmentNumber: string;
  orders: Order[];
  supplierLocationId: string;
  customerLocationId: string;
  internalNote?: string;
  loadingGlobalTimeslots: GlobalTimeslotsDTO;
  unloadingGlobalTimeslots: GlobalTimeslotsDTO;
  customerLocation?: LovCompanyLocationWithOpeningHours;
  supplierLocation?: LovCompanyLocationWithOpeningHours;
  goodsInvoice?: GoodsInvoiceDTO;
}

const CreateSupplierNotificationDap: React.FC<ComponentProps> = ({ initialData }) => {
  const { createDAPSupplierNotification } = useContext(ApiContext);
  const [workedApiRequests, setWorkedApiRequests] = useState(0);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const form = useForm<SupplierNotificationDapForm>(validation(tr, dapOrderConfig), initialData, (d) => save(d), false, false);
  const isOwner = loggedUser?.companyId === form.values?.companyId;

  const save = (data: SupplierNotificationDapForm) => {
    /**
     *  companyId: string;
     *     supplierId: string;
     *     attachments: AttachmentIdWithTypeV2InDTO[];
     *     responsiblePersonId?: string;
     *     userIdsToNotify?: string[];
     *     notifyUsersDependsOnRole: boolean;
     *     transportationType: TransportationTypeEnum;
     *     carrierName: string;
     *     shipmentNumber: string;
     *     orders: GlobalOrdersRequest[];
     *     supplierLocationId: string;
     *     customerLocationId: string;
     *     internalInvoiceNumber?: string;
     *     invoiceDueDate?: string;
     *     deliveryConditions?: string;
     *     internalNote?: string;
     *     note?: string;
     *     loadingGlobalTimeslots: GlobalTimeslotsDTO;
     *     unloadingGlobalTimeslots: GlobalTimeslotsDTO;
     *     labelIds: string[];
     */
    createDAPSupplierNotification(
      {
        companyId: data.companyId,
        supplierId: data.supplierId,
        attachments: data.attachments.map((a) => ({
          attachmentId: a.attachmentId,
          documentType: a.documentType,
          accessType: a.accessType || AccessTypeEnum.ALL
        })),
        responsiblePersonId: data.notifyData?.responsiblePerson?.id,
        userIdsToNotify: data.notifyData?.userToNotify?.map((u) => u.id),
        notifyUsersDependsOnRole: data.notifyData?.notifyUsersDependsOnRole,
        transportationType: data.transportationType,
        carrierName: data.carrierName,
        shipmentNumber: data.shipmentNumber,
        orders: data.orders.map((o) => ({
          orderName: o.orderName,
          items: o.items.map((i) => ({
            amount: i.amount,
            cargoTemplateCode: i.skuCode,
            width: i.width,
            height: i.height,
            length: i.length,
            weight: i.weight,
            note: i.itemNote,
            stackable: i.stackable
          })),
          cargoAmount: o.orderAmount,
          cargoDescription: o.orderDescription,
          orderValue: { price: o.orderValue, currency: o.orderValueCurrency }
        })),
        supplierLocationId: data.supplierLocationId,
        customerLocationId: data.customerLocationId,
        internalNote: data.internalNote,
        loadingGlobalTimeslots: data.loadingGlobalTimeslots,
        unloadingGlobalTimeslots: data.unloadingGlobalTimeslots,
        labelIds: []
      } as DAPSupplierNotificationInDTO,
      () => {
        nav.navigate(nav.urlFunctions.createSupplierNotificationList());
      }
    );
  };

  if (isNullOrUndefined(form?.values) || isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      <div className={'flex flex-col gap-4'}>
        <BreadCrumbComponent
          items={[
          { label: tr(`CreateSupplierNotificationDap.supplierNotificationList`, `Avizace`) },
          { label: tr(`CreateSupplierNotificationDap.createSupplierNotificationDap`, `Vytvořit DAP avizaci`) }]
          } />

        <Heading
          title={tr(`CreateSupplierNotificationDap.heading`, `Nová avizace`)}
          headingVariant={'h1'}
          labels={
          <>{isOwner && <LabelsTag objectId={null} objectType={EntityTypeEnum.SUPPLIER_NOTIFICATION} resolveLabelsForType={EntityTypeEnum.SUPPLIER_NOTIFICATION} />}</>
          }
          buttons={[]} />


        <COHeadline variant={'h2'} title={tr("CreateSupplierNotificationDap.informaceKPreprave", "Informace k přepravě")} icon={<SvgLkw />} />
        <InputTransportationType
          onChange={(v) => {
            form.setFieldValue(form.names.transportationType, v);
          }}
          value={form.values.transportationType} />

        <div className={'flex flex-row gap-2'}>
          <ShipmentNumberTag
            value={form.values.shipmentNumber}
            onChange={(v) => form.setFieldValue(form.names.shipmentNumber, v)}
            errorMessage={findValidationMessage(form.validationErrors, 'shipmentNumber', tr)} />

          <CarrierNameTag
            value={form.values.carrierName}
            onChange={(v) => form.setFieldValue(form.names.carrierName, v)}
            errorMessage={findValidationMessage(form.validationErrors, 'carrierName', tr)} />

        </div>
        <SectionPanel icon={<SvgCalendar />} heading={tr("CreateSupplierNotificationDap.oAvizaci", "O avizaci")}>
          <div className={'grid grid-cols-2 gap-4'}>
            <div className={'flex flex-col gap-2'}>
              <Card>
                <div className={'flex flex-col gap-4'}>
                  <COHeadline title={tr("CreateSupplierNotificationDap.adresaNakladky", "Adresa nakládky")} variant={'h4'} icon={<SvgMapPin />} />
                  <Field label={tr("CreateSupplierNotificationDap.odesilatel", "Odesílatel")} errorMessage={findValidationMessage(form.validationErrors, 'supplierId', tr)}>
                    <InputInheriterCompany
                      companyId={form.values.companyId}
                      inheriterId={form.values.supplierId}
                      onChange={(v) => form.setFieldValue('supplierId', v)}
                      module={CompanyModuleEnum.SUPPLIER_NOTIFICATION} />

                  </Field>
                  <Field label={tr("CreateSupplierNotificationDap.nazevLokace", "Název lokace")} errorMessage={findValidationMessage(form.validationErrors, 'supplierLocationId', tr)}>
                    <InputCompanyLocation
                      moduleName={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                      companyId={form.values.supplierId}
                      companyLocationId={form.values.supplierLocationId}
                      onChange={(v) => {
                        form.setFieldValue(form.names.supplierLocationId, v?.id);
                        form.setFieldValue(form.names.supplierLocation, v);
                      }}
                      style={{ width: '100%' }} />

                  </Field>
                  <Field label={tr("CreateSupplierNotificationDap.datumVyzvednuti", "Datum vyzvednutí")} errorMessage={findValidationMessage(form.validationErrors, 'loadingGlobalTimeslots', tr)}>
                    <ManageTimeslot
                      externalLabel
                      entityType={OpeningEntityTypeEnum.LOCATION}
                      entityId={form.values.supplierLocationId}
                      onChange={(v) => {
                        form.setFieldValue(form.names.loadingGlobalTimeslots, v);
                      }}
                      timeslot={form.values.loadingGlobalTimeslots}
                      noOpeningHours={false}
                      validationErrors={form.validationErrors}
                      validationPrefix={'loadingGlobalTimeslots.'} />

                  </Field>
                </div>
              </Card>
              <Card>
                <div className={'flex flex-col gap-4'}>
                  <COHeadline title={tr("CreateSupplierNotificationDap.adresaVykladky", "Adresa vykládky")} variant={'h4'} icon={<SvgMapPin />} />
                  <Field label={tr("CreateSupplierNotificationDap.prijemce", "Příjemce")} errorMessage={findValidationMessage(form.validationErrors, 'companyId', tr)}>
                    <InputEntityOwner
                      companyId={form.values.companyId}
                      onChange={(c) => form.setFieldValue('companyId', c)}
                      moduleType={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                      style={{ width: '100%' }}
                      onChangeCompanyCode={(v) => form.setFieldValue('companyCode', v)} />

                  </Field>
                  <Field label={tr("CreateSupplierNotificationDap.nazevLokace", "Název lokace")} errorMessage={findValidationMessage(form.validationErrors, 'customerLocationId', tr)}>
                    <InputCompanyLocation
                      moduleName={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                      companyId={form.values.companyId}
                      companyLocationId={form.values.customerLocationId}
                      onChange={(v) => {
                        form.setFieldValue(form.names.customerLocationId, v?.id);
                        form.setFieldValue(form.names.customerLocation, v);
                      }}
                      style={{ width: '100%' }} />

                  </Field>
                  <Field label={tr("CreateSupplierNotificationDap.datumDodani", "Datum dodání")}>
                    <ManageTimeslot
                      externalLabel
                      entityType={OpeningEntityTypeEnum.LOCATION}
                      entityId={form.values.customerLocationId}
                      onChange={(v) => {
                        form.setFieldValue(form.names.unloadingGlobalTimeslots, v);
                      }}
                      timeslot={form.values.unloadingGlobalTimeslots}
                      noOpeningHours={false}
                      validationErrors={form.validationErrors}
                      validationPrefix={'unloadingGlobalTimeslots.'} />

                  </Field>
                </div>
              </Card>
            </div>
            <Card>
              <COHeadline title={tr("CreateSupplierNotificationDap.objednavka", "Objednávka")} variant={'h4'} />
              {/*// @ts-ignore*/}
              <OrdersComponent form={form} config={dapOrderConfig} />
            </Card>
          </div>
        </SectionPanel>
        <AttachmentsList
          attachments={form.values.attachments}
          onDeletedAttachment={(attachmentId) =>
          form.setFieldValue(
            form.names.attachments,
            form.values.attachments.filter((t) => t.attachmentId !== attachmentId)
          )
          }></AttachmentsList>
        <AttachmentUploadPanel
          onSave={(v) => {
            form.setFieldValue(form.names.attachments, [...form.values.attachments, ...v.attachments]);
          }}
          loading={workedApiRequests > 0} />

        {isOwner &&
        <>
            <COHeadline variant={'h2'} title={tr("CreateSupplierNotificationDap.interniInfo", "Interní info")} icon={<SvgInternalInfoHeadling />} />
            <div className={'flex flex-row gap-4'}>
              <ResponsiblePersonTag
              responsiblePerson={form.values.notifyData?.responsiblePerson}
              onChange={(v) => {
                form.setFieldValue(form.names.notifyData + '.responsiblePerson', v);
              }} />

              <InternalInfoNotifyTag
              responsiblePersons={form.values.notifyData?.userToNotify}
              onChange={(v) => {
                form.setFieldValue(form.names.notifyData + '.userToNotify', v);
              }} />

              <InternalInfoNotifyByRoleTag
              notifyRoleName={RoleEnum.ROLE_SN_NOTIFY}
              isActive={form.values.notifyData?.notifyUsersDependsOnRole}
              onChange={(v) => {
                form.setFieldValue(form.names.notifyData + '.notifyUsersDependsOnRole', v);
              }} />

            </div>
            <div className={'flex flex-row gap-4'}>
              <InternalInfoGoodsInvoiceTag
              value={form.values.goodsInvoice}
              onChange={(v) => form.setFieldValue(form.names.goodsInvoice, v)}
              validationErrors={form.validationErrors}
              validationPrefix={'goodsInvoice'} />

              <InternalNoteTag value={form.values.internalNote} onChange={(v) => form.setFieldValue(form.names.internalNote, v)} readonly={false} required={false} />
            </div>
          </>
        }

        <div className={'flex flex-row gap-8 items-center'}>
          <RingilButton label={tr("CreateSupplierNotificationDap.vytvorit", "Vytvořit")} mode={'filled-big'} onClick={() => form.validateAndSend()} />
        </div>
      </div>
    </>);

};

export default CreateSupplierNotificationDap;