import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Note } from '@components/styles.tsx';
import { AutoComplete } from 'primereact/autocomplete';
import { AddressBookTemplateOutDTO } from '@api/logsteo-api.v2';
import useNavigation from '@hooks/useNavigation';
import { Link } from 'react-router-dom';

interface ComponentProps {
  style?: any;
  address: InputAddressData;
  onChange: (address: InputAddressData) => void;
}

export interface InputAddressData {
  locationName: string;
  streetNr: string;
  zipCode: string;
  city: string;
  country: string;
  locationId?: string;
}

const InputAddress: React.FC<ComponentProps> = ({ style, address, onChange }) => {
  const { cuGetLocationTemplatesAddressBook } = useContext(ApiContext);

  const [addressBookSuggestions, setAddressBookSuggestions] = useState<AddressBookTemplateOutDTO[]>();

  const loadFromTemplate = (q: string) => {
    cuGetLocationTemplatesAddressBook(q, null, data => {
      setAddressBookSuggestions(data.addressBooks);
    });
  };

  const onSelectAddress = (value: AddressBookTemplateOutDTO) => {
    onChange({
      streetNr: value.addressTemplate.streetNr,
      zipCode: value.addressTemplate.postalCode,
      city: value.addressTemplate.city,
      country: value.addressTemplate.country,
      locationName: value.name,
      locationId: value.id,
    });
  };
  const onChangeName = (p: any) => {
    onChange({
      streetNr: '',
      zipCode: '',
      city: '',
      country: '',
      locationName: '',
      locationId: '',
    });
  };

  const { tr } = useTranslationLgs();
  const navigation = useNavigation();

  return (
    <>
      <AutoComplete
        value={address.locationName}
        dropdown
        itemTemplate={data => <strong>{data.name}</strong>}
        onChange={e => onChangeName(typeof e.target.value === 'string' ? e.target.value : e.target.value.name)}
        delay={3}
        field="name"
        /*completeMethod={(e: { originalEvent: Event; query: string }) => loadFromTemplate(e.query)}*/
        completeMethod={e => loadFromTemplate(e.query)}
        suggestions={addressBookSuggestions}
        onSelect={e => onSelectAddress(e.value)}
        style={{ width: '100%' }}
      />
      <Note>
        {tr(`InputAddress.hledamAdresyZVasich`, `Hledam adresy z Vasich`)} &#160;
        {/*<Link href={'/customer/partners'}>{tr(`InputAddress.partnerLocation`, `Partner location`)}</Link>*/}
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createPartnerList())}>{tr(`InputAddress.partnerLocation`, `Partner location`)}</Link>
      </Note>
    </>
  );
};

export default InputAddress;
