import React, { useEffect } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { isNullOrUndefined } from '@utils/utils';
import { ExpeditionTypeEnum } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  value: string;
  onChange: (expeditionType: ExpeditionTypeEnum) => void;
  errorMessages?: string;
}

export const TransportationTypeSelector: React.FC<ComponentProps> = ({ value, onChange, errorMessages }) => {
  const { t } = useTranslation();

  const loadTypes = [
    {
      label: t('loadDetailsPage.panelShipmentType.buttonFTL'),
      value: ExpeditionTypeEnum.FULL_TRUCK_LOAD,
    },
    {
      label: t('loadDetailsPage.panelShipmentType.buttonLTL'),
      value: ExpeditionTypeEnum.LESS_TRUCK_LOAD,
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(value)) onChange(ExpeditionTypeEnum.FULL_TRUCK_LOAD);
  }, []);

  return (
    <>
      <div className="p-field">
        <ButtonLayout label={t(`TransportationTypeSelector.transportationType`, `Transportation type`)} wrapper="WITHOUT_PADDING_BOTTOM">
          <SelectButton
            options={loadTypes}
            value={value}
            onChange={e => {
              onChange(e.value === ExpeditionTypeEnum.FULL_TRUCK_LOAD ? ExpeditionTypeEnum.FULL_TRUCK_LOAD : ExpeditionTypeEnum.LESS_TRUCK_LOAD);
            }}
          />
        </ButtonLayout>
      </div>
    </>
  );
};
