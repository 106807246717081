import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuSn = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#616061"
      d="M18.17 4.44h-1.34c-1.01 0-1.83.82-1.83 1.83v.45L5.5 8.76v-.51c0-.41-.34-.75-.75-.75S4 7.84 4 8.25v7c0 .41.34.75.75.75s.75-.34.75-.75v-.63l9.5 2.04v.45c0 1.01.82 1.83 1.83 1.83h1.34c1.01 0 1.83-.82 1.83-1.83V6.27c0-1.01-.82-1.83-1.83-1.83M5.5 13.08v-2.79L15 8.25v6.87zm13 4.03c0 .18-.15.33-.33.33h-1.34c-.18 0-.33-.15-.33-.33V6.27c0-.18.15-.33.33-.33h1.34c.18 0 .33.15.33.33zm-5.53.52a2.785 2.785 0 0 1-2.67 1.94c-1.54 0-2.8-1.24-2.8-2.75 0-.11 0-.23.02-.34.05-.41.43-.7.84-.65s.7.42.65.83v.15c0 .69.58 1.25 1.3 1.25.57 0 1.07-.36 1.24-.89.12-.4.55-.62.94-.49.4.12.61.55.49.94z"
    />
  </svg>
);
export default SvgMenuSn;
