import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface ComponentProps {
  wrapper?: string;
}

const NavTitle: React.FC<PropsWithChildren<ComponentProps>> = ({ children, wrapper }) => {
  const className = clsx('nav-title', {
    'p-d-flex p-ai-center p-jc-between p-mt-3 p-mb-3': wrapper === undefined,
    'p-d-flex p-ai-center p-jc-between': wrapper === 'PADDING-0',
    'p-mb-4': wrapper === 'WITHOUT-COL_PADDING-BOOTOM-4',
    'p-d-flex p-ai-center p-jc-between p-mt-4': wrapper === 'WITH_PADDING-TOP-4',
    'p-d-flex p-ai-center p-jc-between p-mt-4 p-mb-4': wrapper === 'WITH_PADDING-TOP-4_PADDING-BOTTOM-4',
  });

  return <div className={className}>{children}</div>;
};
export default NavTitle;
