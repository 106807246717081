import { PropsWithChildren } from 'react';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  title?: any;
}

const ListInfoItem: React.FC<PropsWithChildren<ComponentProps>> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-0.5">
      <Label title={title} />
      <div className="">{children}</div>
    </div>
  );
};
export default ListInfoItem;
