import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { MultiSelect } from 'primereact/multiselect';
import { EntityTypeEnum, GetListLabelsPreviewOutDTO, LabelPreviewWithTypeOutDTO } from '@api/logsteo-api.v2';
import styled from 'styled-components';
import { dumpVars } from '@utils/utils';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  values: string[];
  onChange: (values: LabelPreviewWithTypeOutDTO[]) => void;
  entityType?: EntityTypeEnum;
}

const InputLabel: React.FC<ComponentProps> = ({ values, onChange, entityType = EntityTypeEnum.EXPEDITION }) => {
  const [labels, setLabels] = useState<LabelPreviewWithTypeOutDTO[]>([]);
  const { listAllLabelsForCompany } = useContext(ApiContext);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    listAllLabelsForCompany(d => {
      setLabels(d?.find(t => t.entityType === entityType)?.labels);
    });
  }, []);

  return (
    <>
      <MultiSelect
        options={labels}
        showClear={true}
        value={(values ? values : []).map(t => {
          return { labelValue: t };
        })}
        optionLabel={'labelValue'}
        dataKey={'labelValue'}
        onChange={e => onChange(e.value)}
        placeholder={tr(`InputLabel.labels`, `Labels`)}
        itemTemplate={(e: LabelPreviewWithTypeOutDTO) => (
          <Row>
            <Rectangle backgroundColor={e.labelColor}></Rectangle>
            <div>{e.labelValue}</div>
          </Row>
        )}
      />
    </>
  );
};

const Rectangle = styled.div<{ backgroundColor: string }>`
  display: flex;
  width: 25px;
  background-color: ${props => props.backgroundColor};
`;

const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export default InputLabel;
