import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import { Clickable, RingilHeading, RowWithSpaceBetween } from '@components/styles.tsx';
import { DataTable } from 'primereact/datatable';
import useMassAction from '@hooks/useMassAction/useMassAction';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  CompanyModuleEnum,
  EntityTypeEnum,
  ListShipmentPreviewOutDTO,
  ListSupplierNotificationPreviewOutDTO,
  PageResponseListSupplierNotificationPreviewOutDTO,
  ShipmentStateCodeEnum,
  StateEnum,
  SupplierNotificationTypeEnum,
  TypeEnum,
} from '@api/logsteo-api.v2';
import { Checkbox } from 'primereact/checkbox';
import { convertToArray, fixBooleanValue, fixBooleanValueWithNull, isNullOrUndefined, useLocalStorage } from '@utils/utils';
import { SelectedObject } from './types';
import { useContructCreateMultiDemandComponent } from './dialogs/CreateMultiDemand';
import { shortDateFormat } from '@utils/date';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import useNavigation from '@hooks/useNavigation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { FilterSort, useFilter } from '@hooks/useFilter/Filter.tsx';
import { proxiedPropertiesOf } from '@hooks/useForm/useForm.tsx';
import InputCountryPlain from '@components/obsolete/Form/InputCountry/InputCountryPlain.tsx';
import InputResponsiblePersons from '@components/obsolete/Form/InputResponsiblePersons.tsx';
import InputEntityOwner from '@components/obsolete/Form/InputEntityOwner/InputEntityOwner.tsx';
import InputInheriterCompany from '@components/obsolete/Form/InputEntityOwner/InputInheriterCompany.tsx';
import InputLabel from '@components/obsolete/Form/InputLabel/InputLabel.tsx';
import { formatTimeslotInterval, isSomeOpenTimeslotsExists } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import { Link } from 'react-router-dom';
import { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import { LabelDiv } from '@components/obsolete/Labels/Labels.tsx';
import { isEntityOwner } from '@app/pages/customer/supplier-notification/utils.ts';
import { ProgressSNGD } from '@components/obsolete/ProgressWithName/ProgressWithName.tsx';
import InputInternalNote from '@components/obsolete/Form/InputInternalNote/InputInternalNote.tsx';
import { InternalInfoIcon } from '@components/obsolete/Icons/InternalInfoIcon.tsx';
import { useCreateMultiEditSupplierNotificationSidebar } from '@app/pages/customer/supplier-notification/sidebars/MultiEditSupplierNotificationSidebar.tsx';
import Badge from '@components/ringil3/Badges/Badge.tsx';

interface ComponentProps {}

interface SupplierNotificationList {
  supplierNotificationId: string;
  supplierNotificationAppId: string;
  multiDemandName: string;
  loadingLocation: string;
  loadingDate: string;
  customerName: string;
  supplierName: string;
  expeditionAppId: string;
}

interface FilterType {
  applicationId?: string;
  expeditionConnectApplicationId?: string;
  groupDemandConnectName?: string;
  responsiblePersonIds?: string[];
  supplierId?: string;
  customerId?: string;
  loadingDateFromISO?: string;
  loadingDateToISO?: string;
  loadingLocationName?: string;
  supplierLocationCountry?: string;
  supplierLocationCity?: string;
  showArchived?: boolean;
  onlyDAP?: boolean;
  labelValues: string[];
}

const SupplierNotificationList: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const navigation = useNavigation();
  const { loggedUser, hasModule } = useContext(AuthenticatedUserContext);

  const { listSupplierNotification } = useContext(ApiContext);
  const [data, setData] = useState<PageResponseListSupplierNotificationPreviewOutDTO>(undefined);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort } = useFilter<FilterType>(
    (page, filter) => {
      console.log({
        Filter,
        applyFilterValue,
        filter,
        applyPaging,
        page,
        onPage,
      });
      load(page.pageSize, page.pageNumber, filter, sort);
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  const loadData = (pageSize: number, pageNumber: number, filter: any, sort: FilterSort) => {
    listSupplierNotification(
      {
        pageSize,
        pageNumber,
        sortField: sort.sortField,
        sortDirection: sort.sortDirection,
      },
      filter,
      d => {
        setData(d);
      },
    );
  };

  const load = (pageSize: number, pageNumber: number, filter: FilterType, sort: FilterSort) => {
    loadData(pageSize, pageNumber, filter, sort);
  };

  const { MultiEditSupplierNotificationSidebar, setVisible: setVisibleMultiEdit } = useCreateMultiEditSupplierNotificationSidebar(() => {
    clear();
    load(page.pageSize, page.pageNumber, filter, sort);
  });

  const renderDataSelectedItems = (): any[] => {
    return [<Column header={tr(`SupplierNotificationList.applicationId`, `applicationId`)} body={(row: ListShipmentPreviewOutDTO) => <div>{row.applicationId}</div>} />];
  };

  const names = proxiedPropertiesOf<FilterType>();

  const disabled = loggedUser?.securityRoles.indexOf('ROLE_SN_RW') == -1;

  const { selectedObjects, isSelected, clear, setSelectedObjects, MassActionCheckboxAndState, toggleCheckbox } = useMassAction<SelectedObject>(
    (a1, a2) => a1.id === a2.id,
    selectedObjects => (
      <>
        {!disabled && (
          <>
            <Button
              label={tr(`SupplierNotificationList.multiDemand`, `Multi demand`)}
              disabled={
                selectedObjects.some(t => t.snType == SupplierNotificationTypeEnum.DAP) ||
                selectedObjects.length == 0 ||
                selectedObjects.some(t => t.isGroupDemandCreated || t.isExpeditionCreated || t.shipmentState === ShipmentStateCodeEnum.WAITING || !t.hasTimeslots)
              }
              onClick={e => createGroupedDemand()}
            />
            <Button
              label={tr(`SupplierNotificationList.multiEdit`, `Multi edit`)}
              onClick={e => setVisibleMultiEdit(true)}
              disabled={selectedObjects.length == 0 || selectedObjects.some(t => t.snType == SupplierNotificationTypeEnum.DAP)}
            />
          </>
        )}
      </>
    ),
    renderDataSelectedItems,
  );

  const createGroupedDemand = () => {
    setSelectedSupplierNotifications(selectedObjects);
  };

  const { CreateMultiDemandDialog, setSelectedSupplierNotifications } = useContructCreateMultiDemandComponent();

  const [revealTabTexts, setRevealTabTexts] = useLocalStorage<boolean>('ringil_tags_expanded', false);
  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Card>
        <RowWithSpaceBetween>
          <RingilHeading>{tr(`SupplierNotificationList.supplierNotifications`, `Supplier notifications`)}</RingilHeading>
          <Button
            label={tr(`SupplierNotificationList.createSupplierNotification`, `Create supplier notification`)}
            onClick={() => {
              /*router.push('/supplier-notifications/create-supplier-notification');*/
              navigation.navigate(navigation.urlFunctions.createSupplierNotificationCreate());
            }}
            disabled={disabled}
          />
        </RowWithSpaceBetween>
        {filter && (
          <div className="p-mt-3 p-mb-2">
            <div className="p-formgroup-inline">
              <div className="p-field">
                <InputText
                  value={filter?.applicationId}
                  placeholder={tr('SupplierNotificationList.applicationId', 'Application ID')}
                  onChange={e => applyFilterValue('applicationId', e.target.value)}
                />
              </div>
              <div className="p-field">
                <InputText
                  value={filter?.groupDemandConnectName}
                  placeholder={tr('SupplierNotificationList.groupDemandConnectName', 'Group demand name')}
                  onChange={e => applyFilterValue('groupDemandConnectName', e.target.value)}
                />
              </div>
              <div className="p-field">
                <InputText
                  value={filter?.loadingLocationName}
                  placeholder={tr('SupplierNotificationList.loadingLocationName', 'Loading location name')}
                  onChange={e => applyFilterValue('loadingLocationName', e.target.value)}
                />
              </div>
              <div className="p-field">
                {/*            <InputText
                  value={filter?.supplierLocationCountry}
                  placeholder={tr('SupplierNotificationList.supplierLocationCountry', 'Loading country')}
                  onChange={(e) => applyFilterValue('supplierLocationCountry', e.target.value)}
                />*/}
                <InputCountryPlain
                  codeValue={filter?.supplierLocationCountry}
                  showClear={true}
                  required={false}
                  onChange={v => applyFilterValue('supplierLocationCountry', v)}
                  placeholder={tr('SupplierNotificationList.supplierLocationCountry', 'Loading country')}
                />
              </div>
              <div className="p-field">
                <InputText
                  value={filter?.supplierLocationCity}
                  placeholder={tr('SupplierNotificationList.supplierLocationCity', 'Loading city')}
                  onChange={e => applyFilterValue('supplierLocationCity', e.target.value)}
                />
              </div>
              <div className="p-field">
                <Calendar
                  locale={'cs'}
                  value={filter?.loadingDateFromISO ? new Date(filter?.loadingDateFromISO) : null}
                  onChange={e => {
                    applyFilterValue('loadingDateFromISO', (e.value as Date)?.toISOString());
                  }}
                  dateFormat={shortDateFormat}
                  readOnlyInput
                  showButtonBar={true}
                  showIcon
                  onClearButtonClick={() => applyFilterValue('loadingDateFromISO', null)}
                  placeholder={tr(`SupplierShipments.loadingDateFromISO`, `From`)}
                />
              </div>
              <div className="p-field">
                <Calendar
                  locale={'cs'}
                  value={filter?.loadingDateToISO ? new Date(filter?.loadingDateToISO) : null}
                  onChange={e => {
                    applyFilterValue('loadingDateToISO', (e.value as Date)?.toISOString());
                  }}
                  dateFormat={shortDateFormat}
                  readOnlyInput
                  showButtonBar={true}
                  showIcon
                  onClearButtonClick={() => applyFilterValue('loadingDateToISO', null)}
                  placeholder={tr(`SupplierShipments.loadingDateToISO`, `Loading to`)}
                />
              </div>

              <div className="p-field">
                <InputResponsiblePersons
                  responsiblePersonIds={convertToArray(filter?.responsiblePersonIds)}
                  onChange={v => applyFilterValue('responsiblePersonIds', v ? v : [])}
                  placeholder={tr('SupplierNotificationList.responsiblePersons', 'Responsible persons')}
                  showClearButton={true}
                />
              </div>
              <div className="p-field">
                <InputEntityOwner
                  companyId={filter?.customerId}
                  moduleType={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                  onChange={v => applyFilterValue('customerId', v ? v : [])}
                  placeholder={tr('SupplierNotificationList.customerId', 'Customer')}
                  // TODO RGL-563
                  onChangeCompanyCode={v => {}}
                />
              </div>
              <div className="p-field">
                <InputInheriterCompany
                  companyId={filter?.customerId}
                  inheriterId={filter?.supplierId}
                  module={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                  onChange={v => applyFilterValue('supplierId', v ? v : [])}
                  placeholder={tr('SupplierNotificationList.supplierId', 'Supplier')}
                  showClear={true}
                />
              </div>
              <div className="p-field">
                <InputText
                  value={filter?.expeditionConnectApplicationId}
                  placeholder={tr('SupplierNotificationList.expeditionConnectApplicationId', 'Connected expedition ID')}
                  onChange={e => applyFilterValue('expeditionConnectApplicationId', e.target.value)}
                />
              </div>
              <div className="p-field-checkbox">
                <InputSwitch
                  className="expedition-filters-switch"
                  checked={fixBooleanValue(filter?.showArchived)}
                  onChange={e => applyFilterValue('showArchived', e.value)}
                />
                <label>{tr(`SupplierNotificationList.showOnlyArchived`, `Show only archived`)}</label>
              </div>
              <div className="p-field-checkbox">
                <InputSwitch
                  className="expedition-filters-switch"
                  checked={fixBooleanValueWithNull(filter?.onlyDAP)}
                  onChange={e => applyFilterValue('onlyDAP', e.value === true ? true : null)}
                />
                <label>{tr(`SupplierNotificationList.onlyDAP`, `Show only DAP`)}</label>
              </div>
              <div className={'p-field'}>
                <InputLabel
                  values={convertToArray(filter.labelValues)}
                  onChange={v => {
                    applyFilterValue(names.labelValues, v ? v.map(t => t.labelValue) : []);
                  }}
                  entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
                />
              </div>
            </div>
          </div>
        )}
        {hasModule(CompanyModuleEnum.SUPPLIER_NOTIFICATION, false) && (
          <MassActionCheckboxAndState
            onSelectAll={() => {
              setSelectedObjects(
                data?.data?.map(t => {
                  return {
                    id: t.id,
                    isGroupDemandCreated: !isNullOrUndefined(t.groupDemandCreated),
                    isExpeditionCreated: !isNullOrUndefined(t.expeditionCreated),
                    hasTimeslots: isSomeOpenTimeslotsExists(t.supplierDeliverySlots),
                    shipmentState: t.shipmentStateCode,
                    snType: t.supplierNotificationType,
                  };
                }),
              );
            }}
            disabled={disabled}
          />
        )}
        {data && (
          <>
            {/*
        // @ts-ignore*/}
            <DataTable
              value={data.data}
              rows={data.size}
              paginator={true}
              lazy={true}
              totalRecords={data.total}
              onPage={onPage}
              first={(data.page - 1) * data.size}
              paginatorPosition={'both'}
              sortMode={'single'}
              onSort={e => {
                applySorting(e.sortField, e.sortOrder === 1 ? 'ASC' : 'DESC');
              }}
              sortField={sort.sortField}
              sortOrder={sort.sortDirection === 'ASC' ? 1 : -1}>
              <Column
                header={''}
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <Checkbox
                    onChange={e => {
                      toggleCheckbox({
                        id: row.id,
                        isGroupDemandCreated: !isNullOrUndefined(row.groupDemandCreated),
                        isExpeditionCreated: !isNullOrUndefined(row.expeditionCreated),
                        hasTimeslots: isSomeOpenTimeslotsExists(row.supplierDeliverySlots) && isSomeOpenTimeslotsExists(row.customerDeliverySlots),
                        shipmentState: row.shipmentStateCode,
                        snType: row.supplierNotificationType,
                      });
                    }}
                    checked={isSelected({
                      id: row.id,
                      isGroupDemandCreated: !isNullOrUndefined(row.groupDemandCreated),
                      isExpeditionCreated: !isNullOrUndefined(row.expeditionCreated),
                      hasTimeslots: isSomeOpenTimeslotsExists(row.customerDeliverySlots),
                      shipmentState: row.shipmentStateCode,
                      snType: row.supplierNotificationType,
                    })}
                  />
                )}
                style={{ width: 30 }}></Column>
              <Column
                sortable={true}
                sortField={'applicationId'}
                header={tr(`SupplierNotificationList.id`, `ID`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <>
                    <Link to={navigation.createNavigationLink(navigation.urlFunctions.createSupplierNotificationDetail(row.id, row.supplierNotificationType))}>
                      <Clickable>{row.applicationId}</Clickable>
                    </Link>
                    {row.supplierNotificationType === SupplierNotificationTypeEnum.DAP && <Badge label={'DAP'}></Badge>}

                    <BadgeRow style={{ gap: '0.5rem' }} className={`${revealTabTexts ? 'p-mt-6' : ''}`}>
                      {!isSomeOpenTimeslotsExists(row.supplierDeliverySlots) && (
                        <LabelDiv
                          color={'var(--ringil-draft-color)'}
                          bgColor={'var(--ringil-base-tertiary-color)'}
                          onClick={e => {
                            setRevealTabTexts(v => !v);
                          }}>
                          {revealTabTexts && <>{tr(`SupplierNotificationList.withoutTimeslots`, `Without timeslots`)}</>}
                        </LabelDiv>
                      )}
                      {row.shipmentStateCode == ShipmentStateCodeEnum.WAITING && (
                        <LabelDiv
                          color={'var(--ringil-processing-color)'}
                          bgColor={'var(--ringil-base-tertiary-color)'}
                          onClick={e => {
                            setRevealTabTexts(v => !v);
                          }}>
                          {revealTabTexts && <>{tr(`SupplierNotificationList.waitingForPayment`, `Waiting for payment`)}</>}
                        </LabelDiv>
                      )}
                      {row.labels
                        .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
                        .map((t, tIndex) => (
                          <LabelDiv
                            bgColor={t.labelColor}
                            key={tIndex}
                            onClick={e => {
                              setRevealTabTexts(v => !v);
                            }}>
                            {revealTabTexts && <div>{t.labelValue}</div>}
                          </LabelDiv>
                        ))}
                    </BadgeRow>
                  </>
                )}></Column>
              <Column
                style={{ width: '170px' }}
                header={tr(`SupplierNotificationList.multiDemandName`, `Multi Demand name`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <div style={{ minWidth: '150px' }}>
                    {!isNullOrUndefined(row.groupDemandCreated) && (
                      <>
                        {isEntityOwner(loggedUser, row.entityOwnerCompanyId) ? (
                          <>
                            {row.groupDemandCreated.state === StateEnum.FINISHED ? (
                              /*<Link href={`/multi-demands/${row.groupDemandCreated.groupDemandId}`}>*/
                              <Link to={navigation.createNavigationLink(navigation.urlFunctions.createMultiDemanDetail(row.groupDemandCreated.groupDemandId))}>
                                <Clickable>
                                  <div>{row.groupDemandCreated?.groupDemandName}</div>
                                </Clickable>
                              </Link>
                            ) : (
                              <ProgressSNGD snId={row.id} groupDemandCreated={row.groupDemandCreated} />
                            )}
                          </>
                        ) : (
                          <>{row.groupDemandCreated?.groupDemandName}</>
                        )}
                      </>
                    )}
                  </div>
                )}></Column>
              <Column
                header={tr(`SupplierNotificationList.loadingLocation`, `Loading location`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => <div>{row.supplierLocationName}</div>}></Column>
              <Column
                header={tr(`SupplierNotificationList.locationCountryAndCity`, `Country/City`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <>
                    <div>{row.supplierLocationCountry}</div>
                    <div>{row.supplierLocationCity}</div>
                  </>
                )}></Column>
              <Column
                header={tr(`SupplierNotificationList.loadingAt`, `Loading at`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => <div>{formatTimeslotInterval(row.supplierDeliverySlots)}</div>}></Column>
              <Column
                header={tr(`SupplierNotificationList.responsiblePerson`, `Responsible person`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => <div>{row.responsiblePersonName}</div>}></Column>
              {/*            <Column
              header={tr(`SupplierNotificationList.customerName`, `Customer`)}
              body={(row: ListSupplierNotificationPreviewOutDTO) => <div>{row.customerName}</div>}
            ></Column>*/}
              <Column
                header={tr(`SupplierNotificationList.supplierName`, `Supplier`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <div>
                    <>{row.supplierName}</>
                    <InputInternalNote ownerCompanyId={row.entityOwnerCompanyId} partnerCompanyId={row.supplierCompanyId} readOnly={true} />
                  </div>
                )}></Column>
              <Column
                sortable={true}
                sortField={'expeditionCreatedPreview.expeditionApplicationId'}
                header={tr(`SupplierNotificationList.expeditionAppId`, `Expedition`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <>
                    {hasModule(CompanyModuleEnum.SUPPLIER_NOTIFICATION, false) ? (
                      /*<Link href={`/customer/expedition/${row.expeditionCreated?.expeditionApplicationId}`}>*/
                      <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionDetail(row.expeditionCreated?.expeditionApplicationId))}>
                        <Clickable>
                          <div>{row.expeditionCreated?.expeditionApplicationId}</div>
                        </Clickable>
                      </Link>
                    ) : (
                      <div>{row.expeditionCreated?.expeditionApplicationId}</div>
                    )}
                  </>
                )}></Column>
              <Column
                header={tr(`SupplierNotificationList.furtherInformation`, `Further information`)}
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <div>{row.internalNote && <InternalInfoIcon applicationId={row.applicationId} text={row.internalNote} />}</div>
                )}></Column>
              <Column
                body={(row: ListSupplierNotificationPreviewOutDTO) => (
                  <div>
                    {hasModule(CompanyModuleEnum.SUPPLIER_NOTIFICATION, false) &&
                      isNullOrUndefined(row.expeditionCreated) &&
                      isNullOrUndefined(row.groupDemandCreated) &&
                      row.shipmentStateCode !== ShipmentStateCodeEnum.WAITING &&
                      row.supplierNotificationType === SupplierNotificationTypeEnum.FCA &&
                      isSomeOpenTimeslotsExists(row.supplierDeliverySlots) &&
                      isSomeOpenTimeslotsExists(row.customerDeliverySlots) &&
                      !disabled && (
                        <Button
                          label={tr(`SupplierNotificationList.createExpedition`, `Create expedition`)}
                          onClick={e => {
                            /*router.push(`/customer/process-supplier-notification?fromSupplierNotification=${row.id}`)*/
                            navigation.navigate(navigation.urlFunctions.createSupplierNotificationFromSupplierNotification(row.id));
                          }}
                        />
                      )}
                  </div>
                )}
              />
            </DataTable>
          </>
        )}
      </Card>
      <CreateMultiDemandDialog
        onComplete={() => {
          clear();
          load(page.pageSize, page.pageNumber, filter, sort);
        }}
      />
      <MultiEditSupplierNotificationSidebar supplierNotificationIds={selectedObjects.map(t => t.id)} />
    </>
  );
};

export default SupplierNotificationList;
