import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import useForm from '@hooks/useForm/useForm';
import CreateOrUpdateAdditionTermsForm, { CreateOrUpdateAdditionTermsFormData } from '../forms/CreateOrUpdateAdditionTermsForm';
import { isNotNullOrUndefined } from '@utils/utils';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';

interface ComponentProps {}

export const useCreateAdditionTermSidebar = (onComplete: () => void) => {
  const [initialData, setInitialData] = useState<CreateOrUpdateAdditionTermsFormData>();

  const CreateOrUpdateAdditionalTermSidebar: React.FC<ComponentProps> = () => {
    const { tr } = useTranslationLgs();
    const { createOrUpdateAdditionalTerm } = useContext(ApiContext);

    const form = useForm<CreateOrUpdateAdditionTermsFormData>(
      yup.object().shape({}),
      initialData
        ? initialData
        : {
            isMandatory: false,
            name: '',
            isOnSinglePage: false,
            termText: '',
            companies: [],
          },
      d => save(),
      false,
      false,
    );

    const save = () => {
      createOrUpdateAdditionalTerm(form.values, () => {
        setInitialData(undefined);
        onComplete();
      });
    };

    return (
      <RingilSidebar
        visible={isNotNullOrUndefined(initialData)}
        heading={tr(`CreateOrUpdateAdditionalTermSidebar.createOrUpdateAdditionalTerm`, `Create or update additional term`)}
        onBack={() => setInitialData(null)}
        onContinue={() => form.validateAndSend()}
      >
        <CreateOrUpdateAdditionTermsForm form={form} inSidebar={true} />
      </RingilSidebar>
    );
  };

  return { CreateOrUpdateAdditionalTermSidebar, setInitialData };
};
