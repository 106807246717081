import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface ComponentProps {
  classNameWrapper?: string;
}

const DropdownLayout: React.FC<PropsWithChildren<ComponentProps>> = ({ classNameWrapper, children }) => {
  const classes = clsx(classNameWrapper);
  return <div className={classNameWrapper ? 'p-field ' + classes : 'p-field p-col-12 p-md-8 p-lg-4'}>{children}</div>;
};

export default DropdownLayout;
