import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { InputTextarea } from 'primereact/inputtextarea';
import useForm from '@hooks/useForm/useForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { isBlank, isNullOrUndefined } from '@utils/utils';
import { Button } from 'primereact/button';
import { AdminImportUserResponse } from '@api/logsteo-api.v2';
import { ButtonRow, RowWithSpaceBetween } from '@components/styles';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {}

interface Form {
  securityRoles: string;
  securityGroups: string;
  csvContent: string;
}

interface User {
  companyId: string;
  userEmail: string;
  firstName: string;
  lastName: string;
  userPhone: string;
  status: AdminImportUserResponse;
}

const ImportUsers: React.FC<ComponentProps> = () => {
  const { convertCsvToListObject, adminImportUser } = useContext(ApiContext);
  const [objectList, setObjectList] = useState<User[]>([]);
  const [api, setApi] = useState(false);

  const form = useForm<Form>(
    yup.object().shape({
      securityRoles: yup.string().required(),
      securityGroups: yup.string().required(),
    }),
    {
      securityRoles: '',
      securityGroups: '',
      csvContent: '',
    },
    d => console.log(d),
    false,
    false,
  );

  useEffect(() => {
    convertCsvToListObject({ data: form.values.csvContent }, d => {
      setObjectList(
        d.map(t => ({
          // @ts-ignore
          companyId: t['companyId'],
          // @ts-ignore
          userEmail: t['userEmail'],
          // @ts-ignore
          firstName: t['firstName'],
          // @ts-ignore
          lastName: t['lastName'],
          // @ts-ignore
          userPhone: t['userPhone'],
          status: null,
        })),
      );
    });
  }, [form.values.csvContent]);

  const renderBody = (row: User) => {
    const status = row.status;
    if (isNullOrUndefined(status)) return <></>;
    if (status.error === true)
      return (
        <RowWithSpaceBetween>
          <Error>{status.message}</Error>
          {status.retryable && <Button label={'Retry'} onClick={e => retry(row)}></Button>}
        </RowWithSpaceBetween>
      );
    return <Success>{status.message}</Success>;
  };

  const retry = (row: User) => {
    setApi(true);
    adminImportUser(
      {
        companyId: row.companyId,
        userEmail: row.userEmail,
        firstName: row.firstName,
        lastName: row.lastName,
        userPhone: row.userPhone,
        securityRolesNames: form.values.securityRoles
          .split(',')
          .map(t => t.trim())
          .filter(t => isBlank(t) === false),
        securityGroupsName: form.values.securityGroups
          .split(',')
          .map(t => t.trim())
          .filter(t => isBlank(t) === false),
      },
      d => {
        setApi(false);
        setObjectList(prev =>
          prev.map(t => {
            if (t.userEmail === d.userEmail) {
              return { ...t, status: d };
            }
            return t;
          }),
        );
      },
    );
  };

  const importUsers = () => {
    objectList.forEach(t => {
      adminImportUser(
        {
          companyId: t.companyId,
          userEmail: t.userEmail,
          firstName: t.firstName,
          lastName: t.lastName,
          userPhone: t.userPhone,
          securityRolesNames: form.values.securityRoles
            .split(',')
            .map(t => t.trim())
            .filter(t => isBlank(t) === false),
          securityGroupsName: form.values.securityGroups
            .split(',')
            .map(t => t.trim())
            .filter(t => isBlank(t) === false),
        },
        d => {
          setApi(false);
          setObjectList(prev =>
            prev.map(t => {
              if (t.userEmail === d.userEmail) {
                return { ...t, status: d };
              }
              return t;
            }),
          );
        },
      );
    });
  };

  return (
    <>
      <h1>Import users</h1>
      <TwoRowsGrid>
        <HorizontalFieldValue
          label={'SecurityRoles'}
          value={
            <InputTextarea
              rows={6}
              style={{ width: '100%' }}
              value={form.values.securityRoles}
              onChange={e => form.setFieldValue(form.names.securityRoles, e.target.value)}
            />
          }
        />
        <HorizontalFieldValue
          label={'SecurityGroups'}
          value={
            <InputTextarea
              rows={6}
              style={{ width: '100%' }}
              value={form.values.securityGroups}
              onChange={e => form.setFieldValue(form.names.securityGroups, e.target.value)}
            />
          }
        />
      </TwoRowsGrid>
      <HorizontalFieldValue
        label={'Paste here CSV'}
        value={
          <InputTextarea rows={6} style={{ width: '100%' }} value={form.values.csvContent} onChange={e => form.setFieldValue(form.names.csvContent, e.target.value)} />
        }
      />
      <h2>Users to import</h2>
      {/*// @ts-ignore*/}
      <DataTable responsiveLayout={'scroll'} value={objectList}>
        <Column field="companyId" header="CompanyId" />
        <Column field="userEmail" header="User email" />
        <Column field="firstName" header="First name" />
        <Column field="lastName" header="Last name" />
        <Column field="userPhone" header="User phone" />
        <Column field="status" body={renderBody} />
      </DataTable>
      <ButtonRow>
        <Button label={'Import'} onClick={() => importUsers()} />
      </ButtonRow>
    </>
  );
};

const TwoRowsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Success = styled.div`
  color: green;
`;

const Error = styled.div`
  color: red;
`;

export default ImportUsers;

/**
 companyId,userEmail,firstName,lastName,userPhone
 03160e88-fe53-415c-af50-c1378b1b8b60,miloslav.vlach+sambular@ringil.com,Miloslav,Vlach,725367556
 */
