import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { InputTextarea } from 'primereact/inputtextarea';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm.tsx';
import { Col, Note } from '@components/styles.tsx';
import { Clickable } from '@components/ringil3/styles.tsx';

interface ComponentProps {
  ownerCompanyId: string;
  partnerCompanyId: string;
  readOnly?: boolean;
}

interface FormData {
  internalNote: string;
}

const InputInternalNote: React.FC<ComponentProps> = ({ ownerCompanyId, partnerCompanyId, readOnly }) => {
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { getCompanyPartnerInfo, createOrUpdateCompanyPartnerInfo } = useContext(ApiContext);
  const [editMode, setEditMode] = useState(false);
  const { tr } = useTranslationLgs();

  const form = useForm<FormData>(
    yup.object().shape({}),
    { internalNote: '' },
    d => {
      save(d);
    },
    false,
    false,
  );

  useEffect(() => {
    form.setFieldValue(names.internalNote, '');
    if (partnerCompanyId) {
      getCompanyPartnerInfo(partnerCompanyId, data => {
        if (data != null) {
          form.setFieldValue(names.internalNote, data.partnerNote);
        }
      });
    }
  }, [partnerCompanyId]);

  const names = proxiedPropertiesOf<FormData>();

  const save = (d: FormData) => {
    createOrUpdateCompanyPartnerInfo({ partnerNote: form.values.internalNote, partnerCompanyId }, () => {
      setEditMode(false);
    });
  };
  return (
    <>
      {loggedUser.companyId === ownerCompanyId && (
        <>
          {editMode && !readOnly ? (
            <>
              <Col>
                <InputTextarea value={form.values.internalNote} onChange={e => form.setFieldValue(names.internalNote, e.target.value)} />
                <Clickable onClick={e => form.validateAndSend()}>{tr(`InputInternalNote.save`, `Save`)}</Clickable>
              </Col>
            </>
          ) : (
            <>
              <Note>{form.values.internalNote}</Note>
              {!readOnly && <Clickable onClick={e => setEditMode(true)}>{tr(`InputInternalNote.manageInternalNote`, `Manage internal note`)}</Clickable>}
            </>
          )}
        </>
      )}
    </>
  );
};

export default InputInternalNote;
