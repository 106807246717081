import React from 'react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface ComponentProps {}

const IndexPage: React.FC<ComponentProps> = () => {
  return (
    <>
      <AdminLayout title={'Carriers'}></AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(IndexPage);
