import React from 'react';
import styled from 'styled-components';
import { InputNumber } from 'primereact/inputnumber';
import CurrencyDropdown from '@components/ringil3/Input/InputPriceWithCurrency/CurrencyDropdown';

interface ComponentProps {
  price: number;
  currency: string;
  disabled?: boolean;
  disabledCurrency?: boolean;
  changePrice: (price: number) => void;
  changeCurrency: (currencyCode: string) => void;
  required?: boolean;
}

const InputPriceWithCurrency: React.FC<ComponentProps> = ({
  currency,
  price,
  changePrice,
  changeCurrency,
  disabled = false,
  disabledCurrency = false,
  required = true,
}) => {
  return (
    <>
      <div className={'flex flex-row'}>
        <InputNumber
          className="p-text-right"
          minFractionDigits={2}
          maxFractionDigits={2}
          value={price}
          onChange={e => changePrice(e.value)}
          mode="decimal"
          disabled={disabled}
          locale={'cs'}
          inputStyle={{ textAlign: 'right', borderRight: 'none', width: '100%' }}
        />
        <CurrencyDropdown
          selectedCurrenry={currency}
          onSelected={value => changeCurrency(value)}
          disabled={disabled || disabledCurrency}
          required={required}
          style={{ width: '100px' }}
        />
      </div>
    </>
  );
};

export default InputPriceWithCurrency;
