import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Sidebar } from 'primereact/sidebar';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Button } from 'primereact/button';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

export interface ExpeditionCoord {
  applicationId: string;
  companyId: string;
  id: string;
}

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  expeditionsToPublish: ExpeditionCoord[];
  onComplete: () => void;
}

const PublishSidebar: React.FC<ComponentProps> = ({ visible, onHide, expeditionsToPublish, onComplete }) => {
  const { cuGetUnpublishedCount, cuPublishExpeditions } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [totalExpeditionCount, setTotalExpeditionCount] = useState(undefined);

  useEffect(() => {
    if (visible) {
      cuGetUnpublishedCount(data => {
        setTotalExpeditionCount(data.unpublishedExpeditionCount);
      });
    }
  }, [expeditionsToPublish, visible]);

  const publish = () => {
    cuPublishExpeditions({ expeditionsToPublish: expeditionsToPublish.map(t => t.id) }, () => {
      onComplete();
    });
  };

  return (
    <>
      {/*// @ts-ignore*/}
      <Sidebar visible={visible} onHide={onHide} position="right" baseZIndex={1000000} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        {totalExpeditionCount && (
          <>
            <h3 className="p-mb-4">{tr(`PublishSidebar.publishExpeditions`, `Publish expeditions`)}</h3>
            <p>
              {tr(`PublishSidebar.youAreTryingToPublish`, `You are trying to publish {{count}}`, { count: expeditionsToPublish.length }, expeditionsToPublish.length)}
              {'. '}
              {tr(`PublishSidebar.thereIsXExpedition`, `There is {{count}} unpublished expeditions`, { count: totalExpeditionCount }, totalExpeditionCount)}
            </p>
            <p></p>
            <ButtonLayout>
              <Button label={tr(`PublishSidebar.confirm`, `Confirm`)} className="p-mr-2" onClick={e => publish()} />
              <Button label={tr(`PublishSidebar.cancel`, `Cancel`)} className="p-button-text" onClick={onHide} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default PublishSidebar;
