import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import Label from '@components/obsolete/Form/Label/Label';
import { CargoTemplateWithoutIdDTO } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface ComponentProps {
  sku: CargoTemplateWithoutIdDTO;
  onChange: (value: CargoTemplateWithoutIdDTO) => void;
  wrapper?: string;
  errorMessage?: string;
}

export const SkuSelector: React.FC<ComponentProps> = ({ sku, onChange, errorMessage, wrapper }) => {
  const { t } = useTranslation('common');
  const [cargoItemTypes, setCargoItemTypes] = useState<CargoTemplateWithoutIdDTO[]>([]);
  const { lovCargoItemType } = useContext(ApiContext);

  useEffect(() => {
    lovCargoItemType(data => {
      setCargoItemTypes(data);
      if (isNullOrUndefined(sku)) {
        onChange(data[0]);
      }
    });
  }, []);

  const className = clsx('sku-selector-wrapper p-field p-col-12 p-md-2', {});

  return (
    <div className={className}>
      <Label title={t(`loadDetailsPage.panelBooking.labelHU`)} required />
      <Dropdown
        value={sku}
        dataKey="code"
        options={cargoItemTypes}
        onChange={e => onChange(e.target.value)}
        optionLabel="name"
        placeholder={t(`loadDetailsPage.panelBooking.skuSelectorPlaceholder`)}
      />
      {errorMessage && <div className="p-invalid-feedback">{errorMessage}</div>}
    </div>
  );
};
