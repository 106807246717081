import InputWrapper from './InputWrapper/InputWrapper';
import InputLayout from './InputLayout/InputLayout';
import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { Location, RouteType } from './types';
import ParametrizedInputWrapper from './ParametrizedInputWrapper/ParametrizedInputWrapper';
import { PartnerLocationContactPersonInDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import HeaderPanel from '@components/obsolete/common/HeaderPanel.tsx';
import FormLayout from '@components/obsolete/Form/FormLayout/FormLayout.tsx';
import InputLocationAndPartnerLocation from '@components/obsolete/Form/InputLocationAndPartnerLocation/InputLocationAndPartnerLocation.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputCountry from '@components/obsolete/Form/InputCountry/InputCountry.tsx';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';

interface ComponentProps {
  locationDetail: Location;
  locationIndex: number;
  locationsLength: number;
  routeType: RouteType;
}

export const LocationDetailComponent: React.FC<ComponentProps> = ({ locationDetail, locationIndex, routeType, locationsLength }) => {
  const { t }: any = useTranslation('common');
  const { tr } = useTranslationLgs();
  const {
    state,
    locationChanged,
    removeLocation,
    addLocation,
    addLocationAtPosition,
    applyAddressBook,
    validation,
    applyContactPerson,
    applyAdditionalNotificationPersons,
    changeLocalityName,
  } = useContext(NewExpeditionViewContext);
  const { cuGetLocationTemplatesAddressBook } = useContext(ApiContext);

  const decideTitle = (locationIndex: number, locationsLength: number) => {
    if (routeType === RouteType.ROUND_TRIP) {
      if (locationIndex === 0) {
        return t(`wayPage.form.titleFromStartEndRoute`);
      } else if (locationIndex !== 0) {
        return t(`wayPage.form.titleFormIntermediateStop`, {
          index: locationIndex,
        });
      }
    } else {
      if (locationIndex === 0) {
        return t(`wayPage.form.titleFormStartRoute`);
      } else if (locationIndex !== 0 && locationIndex < locationsLength - 1) {
        return t(`wayPage.form.titleFormIntermediateStop`, {
          index: locationIndex,
        });
      } else {
        return t(`wayPage.form.titleFormEndRoute`);
      }
    }
  };

  const deleteLocationButton = (locationIndex: number, locationsLength: number) => {
    if ((routeType === RouteType.ROUND_TRIP && locationIndex > 1) || (routeType === RouteType.DIRECT && locationIndex > 0 && locationIndex < locationsLength - 1)) {
      return (
        <Button className="p-button-filled" onClick={() => removeLocation(locationIndex)}>
          {t(`LocationDetailComponent.delete`, `Delete`)}
        </Button>
      );
    }
  };

  const onApplyContactPerson = (index: number, value: PartnerLocationContactPersonInDTO) => {
    applyContactPerson(index, value);
  };

  const onApplyAdditionalNotificationPersons = (index: number, value: PartnerLocationContactPersonInDTO[]) => {
    applyAdditionalNotificationPersons(index, value);
  };

  const [value, setVa] = useState();
  const changeLocationAddress = (locationIndex: number, v: any) => {
    setVa(v);
  };

  return (
    <>
      <HeaderPanel classNameIcon="pi pi-compass" title={decideTitle(locationIndex, locationsLength)}>
        {deleteLocationButton(locationIndex, locationsLength)}
      </HeaderPanel>
      <>
        <FormLayout>
          <div className="p-field p-mt-2">
            <Button onClick={e => addLocationAtPosition(locationIndex)}>{t(`wayPage.form.addLocation`)}</Button>
          </div>
          <InputLayout>
            <ParametrizedInputWrapper errors={validation} validationPath={`locations[${locationIndex}].name`}>
              <InputLocationAndPartnerLocation onChange={v => applyAddressBook(locationIndex, v)} value={{ feKey: locationDetail.feKey }} />
            </ParametrizedInputWrapper>
          </InputLayout>

          <HorizontalFieldValue
            label={tr(`LocationDetailComponent.locationName`, `Location name`)}
            value={<InputText style={{ width: '400px' }} value={locationDetail.name} onChange={e => changeLocalityName(locationIndex, e.target.value)} />}
          />

          <InputLayout>
            <InputCountry
              value={locationDetail.country}
              onChange={e => {
                const targetValue = e;
                locationChanged(locationIndex, 'country', targetValue);
              }}
              label={t(`wayPage.form.labelCountry`)}
              required
            />
            <InputWrapper
              value={locationDetail.zipCode}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'zipCode', targetValue);
              }}
              label={t(`wayPage.form.labelPostcode`)}
              type="text"
              required
              errors={validation}
              validationPath={`locations[${locationIndex}].zipCode`}
            />
          </InputLayout>
          <InputLayout>
            <InputWrapper
              value={locationDetail.city}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'city', targetValue);
              }}
              label={t(`wayPage.form.labelCity`)}
              type="text"
              required
              errors={validation}
              validationPath={`locations[${locationIndex}].city`}
            />
            <InputWrapper
              value={locationDetail.streetNr}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'streetNr', targetValue);
              }}
              label={t(`wayPage.form.labelStreet`)}
              type="text"
              required
              errors={validation}
              validationPath={`locations[${locationIndex}].streetNr`}
            />
          </InputLayout>
          <InputLayout>
            <div style={{ padding: '0.5rem' }}>
              <HorizontalFieldValue
                label={t(`LocationDetailComponent.loadPersonFrom`, `Load person from`)}
                value={
                  <Dropdown
                    options={locationDetail.contactPersons}
                    value={locationDetail.appliedContactPerson}
                    optionLabel={'contactName'}
                    onChange={e => onApplyContactPerson(locationIndex, e.value)}
                  />
                }
              />
            </div>
          </InputLayout>
          <InputLayout>
            <InputWrapper
              value={locationDetail.contactName}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'contactName', targetValue);
              }}
              label={t(`wayPage.form.labelContactPerson`)}
              type="text"
              InputGroup
            />
          </InputLayout>
          <InputLayout>
            <InputWrapper
              value={locationDetail.contactPhone}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'contactPhone', targetValue);
              }}
              label={t(`wayPage.form.labelContactPhone`)}
              type="text"
            />
          </InputLayout>
          <InputLayout className="p-ai-end">
            <InputWrapper
              value={locationDetail.contactEmail}
              onChange={e => {
                const targetValue = (e.target as HTMLInputElement).value;
                locationChanged(locationIndex, 'contactEmail', targetValue);
              }}
              label={t(`wayPage.form.labelContactEmail`)}
              type="text"
              placeholder="@"
              errors={validation}
              validationPath={`locations[${locationIndex}].contactEmail`}
            />
          </InputLayout>
          <CheckboxWithLabel
            checkboxLabel={tr(`LocationDetailComponent.sendNotification`, `Send notification`)}
            value={locationDetail.enabledEmailNotificationForContactPerson}
            onChange={e => {
              locationChanged(locationIndex, 'enabledEmailNotificationForContactPerson', e);
            }}
            disabled={false}
          />

          <InputLayout>
            <div style={{ padding: '0.5rem' }}>
              <HorizontalFieldValue
                label={tr(`LocationDetailComponent.sendNotificationTo`, `Send notification to`)}
                value={
                  <MultiSelect
                    options={locationDetail.contactPersons}
                    value={locationDetail.additionalNotificationPersons}
                    optionLabel={'contactName'}
                    onChange={e => onApplyAdditionalNotificationPersons(locationIndex, e.value)}
                  />
                }
              />
            </div>
          </InputLayout>
          <div className="p-field p-mt-2">
            <Button onClick={e => addLocationAtPosition(locationIndex + 1)}>{t(`wayPage.form.addLocation`)}</Button>
            {/*{state.routeType === RouteType.DIRECT && locationIndex === locationsLength - 2 && (
              <Button onClick={addLocation}>{t(`wayPage.form.addLocation`)}</Button>
            )}
            {state.routeType === RouteType.ROUND_TRIP && locationIndex === locationsLength - 1 && (
              <Button onClick={addLocation}>{t(`wayPage.form.addLocation`)}</Button>
            )}*/}
          </div>
        </FormLayout>
      </>
    </>
  );
};
