import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { formatPrice, isNotNullOrUndefined } from '@utils/utils';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgMoney from '@components/ringil3/icons/Money';

interface ComponentProps {
  price?: number;
  currency?: string;
}

const RequestedPriceTag: React.FC<ComponentProps> = ({ price, currency }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      {isNotNullOrUndefined(price) && (
        <ButtonTag label={tr(`RequestedPriceTag.title`, `Requested`)} labelNote={formatPrice(price, currency)} icon={<SvgMoney />} setOpen={setOpen} open={open} />
      )}
    </>
  );
};

export default RequestedPriceTag;
