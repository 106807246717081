import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import ListInfo from '@components/obsolete/ListInfo/ListInfo';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem';
import NavTitle from '@components/obsolete/NavTitle/NavTitle';
import { OnIssueOrdersDialog } from './OnIssueOrdersDialog';
import { DeactivateUserDialog } from './DeactivateUserDialog';
import { OffIssueOrdersDialog } from './OffIssueOrdersDialog';
import { EditUserSidebar } from './EditUserSidebar';
import { PageResponseUserCompanyUserPreviewOutDTO, UserCompanyOutDTO, UserCompanyUserPreviewOutDTO } from '@api/logsteo-api.v2';
import BreadCrumbComponent, { BreadCrumbItemModel } from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent';
import { ApiContext } from '@api/api';
import { AddWebAccessSidebar } from './AddWebAccessSidebar';
import { SplitButton } from 'primereact/splitbutton';
import { LoginSmsDialog } from './LoginSmsDialog';
import { Chip } from 'primereact/chip';
import { isNotNullOrUndefined } from '@utils/utils';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import { createEditCompanySidebar } from '@components/obsolete/CompanyProfile/sidebar/EditCompanyInfoSidebar.tsx';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import EditButton from '@components/obsolete/Buttons/EditButton/EditButton.tsx';
import GenerateNewIntegrationToken from '@components/obsolete/CompanyProfile/dialogs/GenerateNewIntegrationToken.tsx';

interface ComponentProps {}

interface CreateEditUser {
  visible: boolean;
  userId?: string;
  phoneNumber?: string;
}

interface Filter {}

export const CompanyProfile: React.FC<ComponentProps> = () => {
  const { tr } = useTranslation();

  const breadCrumbItems: BreadCrumbItemModel[] = [
    {
      label: tr(`CompanyProfile.companyProfile`, `Profil společnosti`),
      url: '',
      disabled: true,
    },
  ];

  // dialog
  const [changeStatusUserId, setChangeStatusUserId] = useState<string>();
  const [visibleLoginSmsDialog, setVisibleLoginSmsDialog] = useState<CreateEditUser>({ visible: false });
  const [visibleOnIssueDialog, setVisibleOnIssueDialog] = useState(false);
  const [visibleOffIssueDialog, setVisibleOffIssueDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const { getCompanyForCurrentUser, listCompanyUsers, sendLoginToMobileApp } = useContext(ApiContext);

  const [companyData, setCompanyData] = useState<UserCompanyOutDTO>();
  const [users, setUsers] = useState<PageResponseUserCompanyUserPreviewOutDTO>();
  const [createEditUserForm, setCreateEditUserForm] = useState<CreateEditUser>({
    visible: false,
  });
  const [addWebAccessForm, setAddWebAccessForm] = useState<CreateEditUser>({
    visible: false,
  });

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<Filter>((page, filter) => {
    load(page.pageSize, page.pageNumber);
  });

  /*  useEffect(() => {
        load(10, 0);
      }, []);*/

  const load = (pageSize: number, pageNumber: number) => {
    getCompanyForCurrentUser(data => {
      setCompanyData(data);
    });
    listCompanyUsers({ pageSize, pageNumber }, data => {
      setUsers(data);
    });
  };

  const generateNavLink = (rowData: UserCompanyUserPreviewOutDTO) => {
    return [
      {
        label: rowData.isActive ? tr(`CompanyProfile.deactivate`, `Deactivate`) : tr(`CompanyProfile.activate`, `Activate`),
        icon: 'pi pi-trash',
        command: () => {
          setChangeStatusUserId(rowData.userId);
        },
        visible: true,
      },
      {
        label: tr(`CompanyProfile.user_link`, `Vytvorit pristup`),
        icon: 'pi pi-link',
        command: () => {
          setAddWebAccessForm({ visible: true, userId: rowData.userId });
        },
        visible: rowData.isVirtual,
      },
      {
        label: tr(`CompanyProfile.login_sms`, `Odeslat přihlášení do mob. aplikace`),
        icon: 'pi pi-link',
        command: () => {
          setVisibleLoginSmsDialog({
            visible: true,
            userId: rowData.userId,
            phoneNumber: rowData.phone,
          });
        },
        visible: rowData.securityRoles.some(t => t.name == 'ROLE_DRIVER') || rowData.securityRoles.some(t => t.name == 'ROLE_CARRIER'),
      },
      {
        label: tr(`CompanyProfile.generateToken`, `Generate API token`),
        icon: 'pi pi-link',
        command: () => {
          setSelectedUserId(rowData.userId);
        },
        visible: true,
      },
    ].filter(t => t.visible);
  };

  //table template
  const fullNameBodyTemplate = (rowData: UserCompanyUserPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`CompanyProfile.fullName`, `Full name`)} />
        <ColumnContent
          content={
            <>
              <div>
                {rowData.fullName} ({rowData.phone})
              </div>
              <div>{rowData.email}</div>
            </>
          }
        />
      </>
    );
  };

  const emailBodyTemplate = (rowData: UserCompanyUserPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`CompanyProfile.email`, `Email`)} />
        <ColumnContent content={rowData.email} />
      </>
    );
  };
  const phoneNumberBodyTemplate = (rowData: UserCompanyUserPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`CompanyProfile.phoneNumber`, `Phone number`)} />
        <ColumnContent content={rowData.phone} />
      </>
    );
  };
  const roleBodyTemplate = (rowData: UserCompanyUserPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`CompanyProfile.role`, `Role`)} />
        <ColumnContent
          content={
            <>
              <div>{rowData.roles.map(item => tr(`CompanyProfile.role${item}`, item)).join(', ')}</div>
              <div>{rowData.securityRoles.map(item => item.name).join(', ')}</div>
            </>
          }
        />
      </>
    );
  };

  const statusBodyTemplate = (rowData: UserCompanyUserPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`CompanyProfile.status`, `Status`)} />
        {rowData.isRegistered && <ColumnContent boldContent content={tr(`CompanyProfile.registred`, `Registrován`)} />}
        {rowData.isVirtual && <ColumnContent boldContent content={tr(`CompanyProfile.no_acces_only_driver`, `Bez přístupu`)} />}
        {rowData.isActive && <ColumnContent boldContent content={tr(`CompanyProfile.userIsActive`, `Active`)} />}
        {!rowData.isActive && <ColumnContent boldContent content={tr(`CompanyProfile.userNotActive`, `Not active`)} />}
        {rowData.hasToken ? <Chip template="API" /> : <></>}
      </>
    );
  };

  const navBodyTemplate = (rowData: UserCompanyUserPreviewOutDTO) => {
    return (
      <ButtonLayout>
        <div>
          <Button
            className="p-button-outlined p-button-rounded"
            label={tr(`CompanyProfile.edit`, `Upravit`)}
            onClick={() => setCreateEditUserForm({ visible: true, userId: rowData.userId })}
          />
          <SplitButton icon="pi pi-ellipsis-h" model={generateNavLink(rowData)} className="btn-more-info p-button-rounded p-button-outlined p-ml-3" />
        </div>
      </ButtonLayout>
    );
  };
  //End table template

  /*  const onPage = (event) => {
        load(event.rows, event.page + 1);
      };*/

  const { setVisible: setVisibleCompanyEdit, EditCompanyInfoSidebar } = createEditCompanySidebar();

  return (
    <div>
      <BreadCrumbComponent items={breadCrumbItems} />
      {companyData && (
        <>
          <RowWithSpaceBetween style={{ marginTop: '1rem' }}>
            <h2 className="h2-title p-m-0">{companyData.companyName}</h2>
            <EditButton
              label={tr('CompanyProfile.update', 'Update')}
              onClick={() => {
                setVisibleCompanyEdit(true);
              }}
            />
          </RowWithSpaceBetween>
          <ListInfo>
            <ListInfoItem title={tr(`CompanyProfile.ID`, `ID`)}>
              <p className="p-d-flex p-ai-center">
                <small>{companyData.id}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`CompanyProfile.crn`, `IČ`)}>
              <p className="p-d-flex p-ai-center">
                <small>{companyData.crn}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`CompanyProfile.country`, `Stát`)}>
              <p className="p-d-flex p-ai-center">
                <small>{companyData.country}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`CompanyProfile.zipCode`, `PSČ`)}>
              <p className="p-d-flex p-ai-center">
                <small>{companyData.zipCode}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`CompanyProfile.city`, `Město`)}>
              <p className="p-d-flex p-ai-center">
                <small>{companyData.city}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`CompanyProfile.streetNr`, `Ulice a číslo`)}>
              <p className="p-d-flex p-ai-center">
                <small>{companyData.streetNr}</small>
              </p>
            </ListInfoItem>
          </ListInfo>
        </>
      )}

      <NavTitle>
        <h3 className="p-mt-0">{tr(`CompanyProfile.allUsers`, `Uživatelé`)}</h3>
        <Button
          label={tr(`CompanyProfile.addNewUser`, `Přidat uživatele`)}
          className="p-button-outlined p-btn-edit"
          iconPos="left"
          icon="pi pi-plus"
          onClick={() => setCreateEditUserForm({ visible: true })}
        />
      </NavTitle>
      {users && (
        <>
          {/*
        // @ts-ignore*/}
          <DataTable
            emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            value={users.data}
            className="responsive-table company-profile-table"
            rows={users.size}
            paginator={true}
            lazy={true}
            totalRecords={users.total}
            onPage={onPage}
            first={(users.page - 1) * users.size}
          >
            <Column field="fullName" header={tr(`CompanyProfile.fullName`, `Jméno uživatele`)} body={fullNameBodyTemplate}></Column>
            {/*          <Column
            field="email"
            header={tr(`CompanyProfile.email`, `Email`)}
            className="email"
            body={emailBodyTemplate}
          ></Column>*/}
            {/*<Column
            field="phoneNumber"
            header={tr(`CompanyProfile.phoneNumber`, `Telefon`)}
            className="phone"
            body={phoneNumberBodyTemplate}
          ></Column>*/}
            <Column field="role" header={tr(`CompanyProfile.role`, `Role`)} body={roleBodyTemplate} style={{ width: '20rem' }}></Column>
            <Column field="status" header={tr(`CompanyProfile.status`, `Stav`)} body={statusBodyTemplate}></Column>

            <Column header={tr(`CompanyProfile.actions`, `Akce`)} className="nav-table p-text-right" body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}

      <DeactivateUserDialog
        userId={changeStatusUserId}
        onHide={() => setChangeStatusUserId(null)}
        onComplete={() => {
          setChangeStatusUserId(null);
          load(page.pageSize, page.pageNumber);
        }}
      />

      <LoginSmsDialog
        visible={visibleLoginSmsDialog.visible}
        onHide={() => setVisibleLoginSmsDialog({ visible: false })}
        onComplete={() => {
          sendLoginToMobileApp(visibleLoginSmsDialog.userId, () => {});
          setVisibleLoginSmsDialog({ visible: false });
        }}
        phoneNumber={visibleLoginSmsDialog.phoneNumber}
      />

      <OnIssueOrdersDialog visible={visibleOnIssueDialog} onHide={() => setVisibleOnIssueDialog(false)} />

      <OffIssueOrdersDialog visible={visibleOffIssueDialog} onHide={() => setVisibleOffIssueDialog(false)} />

      {isNotNullOrUndefined(companyData) && (
        <EditUserSidebar
          visible={createEditUserForm.visible}
          onHide={() => {
            setCreateEditUserForm({ visible: false });
          }}
          onComplete={() => {
            load(10, 0);
            setCreateEditUserForm({ visible: false });
          }}
          userId={createEditUserForm.userId}
          companyId={companyData.id}
        />
      )}

      <AddWebAccessSidebar
        visible={addWebAccessForm.visible}
        onHide={() => {
          setAddWebAccessForm({ visible: false });
        }}
        onComplete={() => {
          load(10, 0);
          setAddWebAccessForm({ visible: false });
        }}
        userId={addWebAccessForm.userId}
      />
      <EditCompanyInfoSidebar
        onChanged={() => {
          load(10, 0);
        }}
      />
      <GenerateNewIntegrationToken onHide={() => setSelectedUserId(null)} selectedUserId={selectedUserId}></GenerateNewIntegrationToken>
    </div>
  );
};
