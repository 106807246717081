import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SvgRingilText from '@app/icons/menu/RingilText.tsx';

interface ComponentProps {}

const Intro: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <div className={'flex flex-row items-center justify-center content-center h-screen w-full'}>
      <div className={'bg-content-primary w-[600px] h-[400px] flex flex-row'}>
        <div className={'w-[20px] h-full bg-content-brand'}></div>
        <div className={'h-full w-full flex flex-col items-center justify-center'}>
          <div className={'font-normal font-["DM Sans"] text-content-primary'}>
            <SvgRingilText />
          </div>
          <div>
            <div className={'flex flex-row text-xl font-normal font-["DM Sans"] text-white justify-end items-end h-full'}>We make your business easier.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
