import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMappoint = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={17} fill="none" {...props}>
    <path
      fill="#616061"
      fillRule="evenodd"
      d="M0 6.5v.293c0 1.429.486 2.815 1.379 3.93L6 16.5l4.621-5.776A6.3 6.3 0 0 0 12 6.793V6.5a6 6 0 1 0-12 0m6 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMappoint;
