import React from 'react';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { Dropdown } from 'primereact/dropdown';
import { LovDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  responsiblePersonId: string;
  onChange: (responsiblePersonId: string) => void;
  style?: any;
  showClearButton?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const InputResponsiblePersonPlain: React.FC<ComponentProps> = ({ responsiblePersonId, onChange, style, showClearButton = false, disabled = false, placeholder }) => {
  const responsiblePersons = useResponsiblePersons();

  return (
    <>
      <Dropdown
        value={responsiblePersonId}
        options={responsiblePersons}
        onChange={e => onChange(e.target.value)}
        optionLabel="name"
        optionValue={'id'}
        style={style}
        showClear={showClearButton}
        disabled={disabled}
        placeholder={placeholder}
        filter={true}
        itemTemplate={(option: LovDTO) => (
          <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
        )}
      />
    </>
  );
};

export default InputResponsiblePersonPlain;
