import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { isNullOrUndefined } from '@utils/utils';
import { Sidebar } from 'primereact/sidebar';
import { MultiSelect } from 'primereact/multiselect';
import { CarrierGroupInDTO, CarrierOrGroupOutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { carriersAndGroupsTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  companyId: string;
  applicationId: string;
  visible: boolean;
  onHide: () => void;
  onSelect: (selectedCarriers: CarrierGroupInDTO[]) => void;
}

export const AddMoreCarrierDialog: React.FC<ComponentProps> = ({ visible, onHide, onSelect, companyId, applicationId }) => {
  const { t }: { t: any } = useTranslation('common');
  const [selectedCarrierOrGroup, setSelectedCarrierOrGroup] = useState<CarrierGroupInDTO[]>([]);

  const [carriersAndGroups, setCarriersAndGroups] = useState<CarrierOrGroupOutDTO[]>([]);
  const { cuLoVAddMoreCarriers } = useContext(ApiContext); // LovDTO
  const [validationError, setValidationError] = useState<string>(undefined);

  useEffect(() => {
    if (visible)
      cuLoVAddMoreCarriers(companyId, applicationId, data => {
        setCarriersAndGroups(data.carriers);
        setSelectedCarrierOrGroup([]);
        setValidationError(undefined);
      });
  }, [visible]);

  const validateAndSave = () => {
    if (selectedCarrierOrGroup.length == 0) {
      setValidationError(t('validation.defined'));
    } else {
      onSelect(selectedCarrierOrGroup);
    }
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        <h3 className="p-mb-4">{t(`AddMoreCarrierDialog.addMoreCarriers`, `Add more carriers`)}</h3>

        <div className="p-field p-fluid">
          <Label title={t(`AddMoreCarrierDialog.chooseCarriersAndCarrierGroupsToAdd`, `Choose carriers and carrier groups to add`)} />
          <MultiSelect
            value={selectedCarrierOrGroup}
            options={carriersAndGroups}
            onChange={e => setSelectedCarrierOrGroup(e.value)}
            optionLabel="name"
            placeholder={t(`AddMoreCarrierDialog.selectCarriers`, `Select carriers`)}
            filter
            className="multiselect-custom"
            itemTemplate={carriersAndGroupsTemplate}
            style={{ width: '100%' }}
          />
          <div className="p-invalid-feedback">{validationError}</div>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          <Button
            label={t(`AddMoreCarrierDialog.continue`, `Continue`)}
            className="p-mr-2"
            disabled={isNullOrUndefined(selectedCarrierOrGroup)}
            onClick={() => {
              validateAndSave();
            }}
          />
          <Button label={t(`AddMoreCarrierDialog.back`, `Back`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};
