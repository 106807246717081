import { PropsWithChildren } from 'react';

interface ComponentProps {
  className?: any;
}

const Alert: React.FC<PropsWithChildren<ComponentProps>> = ({ children, className }) => {
  return (
    <div className={'alert p-d-flex p-jc-between p-ai-center ' + className}>
      <div>{children}</div>
    </div>
  );
};

export default Alert;
