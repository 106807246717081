import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { Label } from '@components/styles.tsx';

interface ComponentProps {
  carrierCode: string;
  setCarrierCode: (string: string) => void;
}

export const CarrierCodeSelector: React.FC<ComponentProps> = ({ carrierCode, setCarrierCode }) => {
  const { t } = useTranslation('common');
  const [inputFieldDisplayed, setInputFieldDisplayed] = useState(false);

  return (
    <>
      {carrierCode || inputFieldDisplayed ? (
        <>
          <Label title={t(`CarrierCodeSelector.carrierCode`, `Carrier code`)} />
          <InputText
            type="text"
            style={{ width: 'auto' }}
            onChange={e => {
              const targetValue = (e.target as HTMLInputElement).value;
              setCarrierCode(targetValue);
            }}
            value={carrierCode}
          />
        </>
      ) : (
        <Button onClick={() => setInputFieldDisplayed(true)} className="p-button-outlined" label={t(`CarrierCodeSelector.addCarrierCode`, `+ Carrier code`)} />
      )}
    </>
  );
};
