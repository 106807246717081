import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import {
  CargoTypeCodeEnum,
  CargoTypeOutDTO,
  CarrierCreateGDSNInDTO,
  CarrierGroupDemandDetailOutDTO,
  CarrierSNListOrderOutDTO,
  CarrierSupplierNotificationRowAddressOutDTO,
  CurrencyOrderValue,
  EntityTypeEnum,
  PreviewPlanOutLoV,
  StateEnum,
  TimeslotOutDTO,
} from '@api/logsteo-api.v2';
import { ButtonRow, CenteredRowWithGap, Clickable, Col, Hr, Label, RingilHeading, RowWithSpaceBetween } from '@components/styles';
import { Button } from 'primereact/button';
import { formatCarrierLocation, formatDistance, formatPieces, formatPrice, formatWeight, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { mapFromAPIToDateLong, mapFromAPIToDateShort } from '@utils/date';
import useNavigation from '@hooks/useNavigation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';
import { Link } from 'react-router-dom';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import CarrierChatComponent from '@app/pages/carrier/Chat/CarrierChatComponent.tsx';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import OrderInfoComponent from '@components/obsolete/OrderInfoComponent/OrderInfoComponent.tsx';

interface ComponentProps {
  id: string;
}

interface FormData {
  items: SupplierNotificationRow[];
}

interface SupplierNotificationRow {
  fromFormattedLocation: string;
  loadingPlace: CarrierSupplierNotificationRowAddressOutDTO;
  unloadingPlace: CarrierSupplierNotificationRowAddressOutDTO;
  toFormattedLocation: string;
  snApplicationId: string;
  snId: string;
  distance: number;
  loadingDate: string;
  weight: number;
  orders: CarrierSNListOrderOutDTO[];
  notInterested: boolean;
  price: number;
  priceCurrency: string;
  expeditionAppId: string;
  expeditionCustomerId: string;
  specialRequirements: PreviewPlanOutLoV[];
  orderValue: CurrencyOrderValue;
  sumCargoAmount: null;
  loadingTimeslots: TimeslotOutDTO;
  unloadingTimeslots: TimeslotOutDTO;
  cargoTypeOutDTO: CargoTypeOutDTO;
  transportCode?: string;
  carrierNote?: string;
}

const MultiDemands: React.FC<ComponentProps> = ({ id }) => {
  const { tr } = useTranslationLgs();
  const { caGetGroupDemandDetailById, caCreateGroupDemandQuote } = useContext(ApiContext);
  const [data, setData] = useState<CarrierGroupDemandDetailOutDTO>();
  const [visibleCounterPartyChat, setVisibleCounterPartyChat] = useState(false);

  const navigation = useNavigation();

  useEffect(() => {
    if (id) {
      caGetGroupDemandDetailById(id, d => {
        setData(d);
        form.setValues({
          items: d.supplierNotificationRowsWithDemands.map(t => {
            return {
              fromFormattedLocation: formatCarrierLocation(t.loadingPlace.zipCode, t.loadingPlace.city, t.loadingPlace.country),
              loadingPlace: t.loadingPlace,
              unloadingPlace: t.unloadingPlace,
              toFormattedLocation: formatCarrierLocation(t.unloadingPlace.zipCode, t.unloadingPlace.city, t.unloadingPlace.country),
              loadingDate: mapFromAPIToDateShort(t.loadingFromTime),
              snId: t.snId,
              snApplicationId: t.snApplicationId,
              distance: t.distance,
              weight: t.weight,
              orders: t.orders,
              price: t.myPrice ? t.myPrice.price : null,
              priceCurrency: t.myPrice ? t.myPrice.currency : null,
              notInterested: t.isNotInterested,
              expeditionAppId: t.expeditionApplicationId,
              expeditionCustomerId: t.expeditionCustomerId,
              specialRequirements: t.specialRequirements,
              orderValue: t.orderValue,
              sumCargoAmount: t.sumCargoAmount,
              loadingTimeslots: t.loadingTimeslots,
              unloadingTimeslots: t.unloadingTimeslots,
              cargoTypeOutDTO: t.cargoTypeOut,
              transportCode: t.transportCode?.code,
              carrierNote: t.carrierNote,
            } as SupplierNotificationRow;
          }),
        });
      });
    }
  }, []);

  const form = useForm<FormData>(yup.object().shape({}), null, d => save(d), true, false);
  const { setBeValidationErrors, BackendValidationComponent } = useBEValidation();

  const save = (data: FormData) => {
    caCreateGroupDemandQuote(
      id,
      {
        isDifferentPriceForAll: false,
        differentPrice: null,
        supplierNotificationQuotes: form.values.items.map(t => {
          return {
            snId: t.snId,
            price: t.notInterested ? null : { price: t.price, currency: t.priceCurrency },
            isNotInterested: t.notInterested,
          } as CarrierCreateGDSNInDTO;
        }),
      },
      () => {
        /*router.push('/carrier/multi-demands');*/
        navigation.navigate(navigation.urlFunctions.createCarrierMultiDemandsList());
      },
      null,
      {
        onValidationFailed: setBeValidationErrors,
      },
    );
  };

  const renderIcDic = () => {
    const values = [
      {
        label: 'MultiDemand.IC',
        labelDefault: 'IC: {{value}}',
        value: data.createdByInfo.crn,
      },
      {
        label: 'MultiDemand.DIC',
        labelDefault: 'DIC: {{value}}',
        value: data.createdByInfo.tin,
      },
    ];
    return values
      .filter(t => !isNullOrUndefined(t.value))
      .map(t => tr(t.label, t.labelDefault, { value: t.value }))
      .join(' | ');
  };

  const [detailData, setDetailData] = useState<SupplierNotificationRow>(undefined);

  if (isNullOrUndefined(form.values)) return <></>;

  return (
    <>
      <BackendValidationComponent />
      <RowWithSpaceBetween>
        <RingilHeading>
          {tr(`MultiDemandDetail.title`, `Multi demand detail {{groupDemandName}}`, {
            groupDemandName: data.groupDemandName,
          })}
        </RingilHeading>
        <CenteredRowWithGap>
          <Button
            icon="pi pi-envelope"
            className="p-button-outlined"
            label={tr(`TabShipmentAssigned.writeMessage`, `Napsat zprávu`)}
            onClick={() => setVisibleCounterPartyChat(true)}
          />
          <div>
            {tr(`MultiDemandDetail.validTillDate`, `Valid till {{date}}`, {
              date: mapFromAPIToDateLong(data.validTill),
            })}
          </div>
        </CenteredRowWithGap>
      </RowWithSpaceBetween>
      {data.state === StateEnum.NEW && data.isValid && (
        <>
          <Table>
            <thead>
              <tr>
                <th>{tr(`MultiDemandDetail.id`, `ID`)}</th>
                <th>{tr(`MultiDemandDetail.route`, `Route`)}</th>
                <th>{tr(`MultiDemandDetail.cargo`, `Cargo`)}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {form && form.values && form.values.items && (
              <tbody>
                {form.values.items.map((sr, srIndex) => {
                  return (
                    <React.Fragment key={srIndex}>
                      <tr className={sr.notInterested ? 'notInterested' : ''}>
                        <td>{sr.snApplicationId}</td>
                        <td>
                          <RowWithSpaceBetween>
                            <Direction>{tr(`MultiDemandDetail.z`, `Z`)}</Direction>
                            <Address>{sr.fromFormattedLocation}</Address>
                          </RowWithSpaceBetween>
                          <RowWithSpaceBetween>
                            <Direction>{tr(`MultiDemandDetail.do`, `Do`)}</Direction>
                            <Address>{sr.toFormattedLocation}</Address>
                          </RowWithSpaceBetween>
                          <div>{formatDistance(sr.distance)}</div>
                          <div>
                            <strong>{tr(`MultiDemandDetail.possibleStartOfTransport`, `Possible start of transport`)}</strong>
                            {sr.loadingTimeslots.daysWithIntervals.map((ts, tsIndex) => {
                              return (
                                <RowWithSpaceBetween alignItems={'flex-start'} key={tsIndex}>
                                  <Col>{mapFromAPIToDateShort(ts.day)}</Col>
                                  <Col>
                                    {ts.intervals.map((interval, intervalKey) => {
                                      return <div key={intervalKey}>{`${interval.since} - ${interval.till}`}</div>;
                                    })}
                                  </Col>
                                </RowWithSpaceBetween>
                              );
                            })}
                          </div>
                          <div>
                            <strong>{tr(`MultiDemandDetail.possibleEndOfTransport`, `Possible end of transport`)}</strong>
                            {sr.unloadingTimeslots.daysWithIntervals.map((ts, tsIndex) => {
                              return (
                                <RowWithSpaceBetween alignItems={'flex-start'} key={tsIndex}>
                                  <Col>{mapFromAPIToDateShort(ts.day)}</Col>
                                  <Col>
                                    {ts.intervals.map((interval, intervalKey) => {
                                      return <div key={intervalKey}>{`${interval.since} - ${interval.till}`}</div>;
                                    })}
                                  </Col>
                                </RowWithSpaceBetween>
                              );
                            })}
                          </div>
                        </td>
                        <td>
                          {tr(
                            `MultiDemandDetail.totalHandlingUnitsSrSumCargoAmount`,
                            `Total handling units: {{count}} pcs`,
                            {
                              count: sr.orders.reduce((previousValue, currentValue) => {
                                return previousValue + currentValue.quantity;
                              }, 0),
                            },
                            sr.orders.reduce((previousValue, currentValue) => {
                              return previousValue + currentValue.quantity;
                            }, 0),
                          )}
                          {sr.orders.map((order, orderKey) => {
                            return (
                              <div key={orderKey}>
                                {order.quantity}x {order.manipulationUnitName}
                              </div>
                            );
                          })}
                          <TotalWeightContainer>
                            {tr(`MultiDemandDetail.totalWeight`, `Total weight {{totalWeight}}`, {
                              totalWeight: formatWeight(sr.weight),
                            })}
                          </TotalWeightContainer>
                          <div>
                            {tr(`MultiDemandDetail.orderValue`, `Order value {{orderValue}}`, {
                              orderValue: formatPrice(sr.orderValue.amount, sr.orderValue.currencyCode),
                            })}
                          </div>
                          <div>
                            {tr(`MultiDemandDetail.pieces`, `Order pieces {{pieces}}`, {
                              pieces: formatPieces(sr.sumCargoAmount, tr),
                            })}
                          </div>
                          {sr.cargoTypeOutDTO && (
                            <>
                              <div>
                                {[
                                  sr.cargoTypeOutDTO.cargoType.cargoTypeName,
                                  sr.cargoTypeOutDTO.cargoType.cargoTypeCode === CargoTypeCodeEnum.ADR_LQ ? formatWeight(sr.cargoTypeOutDTO.adrWeight) : null,
                                ]
                                  .filter(t => t != null)
                                  .join(', ')}
                              </div>
                            </>
                          )}
                        </td>
                        <td>
                          <RowWithSpaceBetween>
                            <Label>{tr(`MultiDemandDetail.price`, `Price`)}</Label>
                            <InputPriceWithCurrency
                              price={sr.price}
                              currency={sr.priceCurrency}
                              changePrice={v => {
                                form.setFieldValue(`items[${srIndex}]price`, v);
                              }}
                              changeCurrency={v => {
                                form.setFieldValue(`items[${srIndex}].priceCurrency`, v);
                              }}
                              disabled={sr.notInterested}
                              disabledCurrency={sr.notInterested}
                            />
                          </RowWithSpaceBetween>
                        </td>
                        <td>
                          <CheckboxWithLabel
                            checkboxLabel={tr('MultiDemandDetail.notInterested', 'Not interested')}
                            value={sr.notInterested}
                            onChange={v => {
                              form.setFieldValue(`items[${srIndex}].notInterested`, v);
                            }}
                            disabled={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td colSpan={2}>
                          <strong>{tr('RouteDescriptionComponent.requirements', 'Requirements')}:</strong>
                          <div>{sr.specialRequirements?.map(t => t.name).join(', ')}</div>
                          <strong>{tr('RouteDescriptionComponent.note', 'Note')}:</strong>
                          <div>{sr.carrierNote}</div>
                          <Clickable onClick={e => setDetailData(sr)}>Detail</Clickable>
                        </td>
                        <td colSpan={3}></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            )}
          </Table>
          <Hr />
          <ButtonRow>
            <Button label={tr(`MultiDemandDetail.sendQuote`, `Send quote`)} onClick={e => form.validateAndSend()} />
            <Button
              label={tr(`MultiDemandDetail.cancel`, `Cancel`)}
              className={'p-button-outlined'}
              onClick={() => {
                /*router.push('/carrier/multi-demands');*/
                navigation.navigate(navigation.urlFunctions.createCarrierMultiDemandsList());
              }}
            />
          </ButtonRow>
        </>
      )}
      {(data.state === StateEnum.FINISHED || !data.isValid) && (
        <Table>
          <thead>
            <tr>
              <th>{tr(`MultiDemandDetail.id`, `ID`)}</th>
              <th>{tr(`MultiDemandDetail.route`, `Route`)}</th>
              <th>{tr(`MultiDemandDetail.start`, `Start`)}</th>
              <th>{tr(`MultiDemandDetail.cargo`, `Cargo`)}</th>
              <th>{tr(`MultiDemandDetail.totalWeight`, `Total weight`)}</th>
              <th>{tr(`MultiDemandDetail.offeredPrice`, `Offered price`)}</th>
              <th>{tr(`MultiDemandDetail.followingShipment`, `Following shipment`)}</th>
            </tr>
          </thead>
          {form && form.values && form.values.items && (
            <tbody>
              {form.values.items.map((sr, srIndex) => {
                return (
                  <tr key={srIndex} className={sr.notInterested ? 'notInterested' : ''}>
                    <td>
                      <>{sr.snApplicationId}</>
                    </td>
                    <td>
                      <RowWithSpaceBetween>
                        <Direction>{tr(`MultiDemandDetail.from`, `From`)}</Direction>
                        <Address>{sr.fromFormattedLocation}</Address>
                      </RowWithSpaceBetween>
                      <RowWithSpaceBetween>
                        <Direction>{tr(`MultiDemandDetail.to`, `To`)}</Direction>
                        <Address>{sr.toFormattedLocation}</Address>
                      </RowWithSpaceBetween>
                      <div>{formatDistance(sr.distance)}</div>
                    </td>
                    <td>{sr.loadingDate}</td>
                    <td>
                      {tr(
                        `MultiDemandDetail.totalHandlingUnitsSrSumCargoAmount`,
                        `Total handling units: {{count}} pcs`,
                        {
                          count: sr.orders.reduce((previousValue, currentValue) => {
                            return previousValue + currentValue.quantity;
                          }, 0),
                        },
                        sr.orders.reduce((previousValue, currentValue) => {
                          return previousValue + currentValue.quantity;
                        }, 0),
                      )}
                      {sr.orders.map((order, orderKey) => {
                        return (
                          <React.Fragment key={orderKey}>
                            <div>
                              {order.quantity}x {order.manipulationUnitName}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </td>
                    <td className={'number'}>{formatWeight(sr.weight)}</td>
                    <td className={'number'}>
                      <>{sr.price && <>{formatPrice(sr.price, sr.priceCurrency)}</>}</>
                    </td>
                    <td>
                      {sr.notInterested && <NotInterested>{tr(`MultiDemandDetail.notInterested`, `Not interested`)}</NotInterested>}

                      {sr.expeditionAppId && (
                        <>
                          {/*<Link href={`/carrier/shipment/${sr.expeditionCustomerId}-${sr.expeditionAppId}`}>*/}
                          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierShipmentDetail(sr.expeditionCustomerId, sr.expeditionAppId))}>
                            <Clickable>{sr.expeditionAppId}</Clickable>
                          </Link>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      )}
      <h3 className="p-mt-4" style={{ fontWeight: 'normal' }}>
        {tr(`MultiDemandDetail.customer`, `Customer`)}
      </h3>
      <PanelWithIcon srcIcon="/images/contacts.svg">
        <Label>{data.createdByInfo.companyName}</Label>
        <p>{`${data.createdByInfo.streetNr}, ${data.createdByInfo.city}, (${data.createdByInfo.country})`}</p>
        <p>{renderIcDic()}</p>
      </PanelWithIcon>
      <PanelWithIcon srcIcon="/images/user.svg">
        <p>{data.createdByInfo.responsiblePersonName}</p>
        <p className="text-gray5">
          <small>{tr(`MultiDemandDetail.responsiblePerson`, `Responsible person`)}</small>
        </p>
      </PanelWithIcon>
      <CarrierChatComponent
        companyId={data.customerId}
        entityId={data.groupDemandId}
        entityType={EntityTypeEnum.GROUP_DEMAND}
        visible={visibleCounterPartyChat}
        onHide={() => setVisibleCounterPartyChat(false)}
        customerName={data.customerName}
      />
      {detailData && (
        <RingilSidebar visible={isNotNullOrUndefined(detailData)} onHide={() => setDetailData(undefined)} onSave={() => setDetailData(undefined)} showButtonBar={false}>
          <RingilHeading>
            {tr('MultiDemandDetail.demand', 'Demand {{applicationId}}', {
              applicationId: detailData.snApplicationId,
            })}
          </RingilHeading>
          <HorizontalFieldValue label={tr('MultiDemandDetail.validTill', 'Valid till')} value={mapFromAPIToDateLong(data.validTill)} />
          <HorizontalFieldValue label={tr('MultiDemandDetail.totalDistance', 'Total distance')} value={formatDistance(detailData.distance)} />
          <HorizontalFieldValue label={tr('MultiDemandDetail.maxWeight', 'Maximal weight')} value={formatWeight(detailData.weight)} />
          <HorizontalFieldValue
            label={tr('MultiDemandDetail.fromLocation', 'From')}
            value={
              <>
                <div>{detailData.loadingPlace.locationName}</div>
                <div>
                  {detailData.loadingPlace.streetNr}, {detailData.loadingPlace.city}, {detailData.loadingPlace.zipCode}
                </div>
                <div></div>
                <div>{detailData.loadingPlace.country}</div>
              </>
            }
          />
          <HorizontalFieldValue
            label={tr('MultiDemandDetail.toLocation', 'To')}
            value={
              <>
                <div>{detailData.unloadingPlace.locationName}</div>
                <div>
                  {detailData.unloadingPlace.streetNr}, {detailData.unloadingPlace.city}, {detailData.unloadingPlace.zipCode}
                </div>
                <div></div>
                <div>{detailData.unloadingPlace.country}</div>
              </>
            }
          />
          <HorizontalFieldValue
            label={tr('MultiDemandDetail.items', 'Items')}
            value={detailData.orders.map(t => (
              <div>
                <OrderInfoComponent
                  name={t.manipulationUnitName}
                  amount={t.quantity}
                  unit={'KS'}
                  weight={t.weight}
                  length={t.length}
                  height={t.height}
                  width={t.width}
                  stackable={t.stackable}
                />
              </div>
            ))}
          />
          <HorizontalFieldValue
            label={tr('MultiDemandDetail.transportCode', 'transportCode')}
            value={
              <>
                <div>{tr(`TransportationCode_${detailData.transportCode}`, `TransportationCode_${detailData.transportCode}`)}</div>
              </>
            }
          />
          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
            <Button label={tr(`MultiDemandDetail.close`, `Close`)} className="p-button-text" onClick={() => setDetailData(undefined)} />
          </ButtonLayout>
        </RingilSidebar>
      )}
    </>
  );
};

const TotalWeightContainer = styled.div`
  display: flex;
  margin-top: var(--ringil-red-gap);
`;

const Direction = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Address = styled.div`
  display: flex;
`;

const Table = styled.table`
  border-spacing: 0px;
  width: 100%;

  th,
  td {
    vertical-align: top;
    padding: var(--ringil-red-gap);
    text-align: left;
  }

  td.number {
    text-align: right;
  }

  tr.notInterested td {
    color: var(--ringil-tertiary-color);
  }

  th {
    background-color: #f2f2f2;
  }
`;

const NotInterested = styled.div`
  display: flex;
  font-style: italic;
  color: var(--ringil-tertiary-color);
`;

export default MultiDemands;
