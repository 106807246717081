import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { RingilLabel } from '@components/styles.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgDialogCloseIcon from '@components/framework/dialog/DialogCloseIcon.tsx';

interface RingilDialogProps {
  visible: boolean;
  onHide: () => void;
  children?: React.ReactNode;
  wide?: boolean;
  buttons?: React.ReactNode;
  heading?: string;
}

const RingilDialog: React.FC<RingilDialogProps> = ({ visible, onHide, children, wide, buttons, heading }) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onHide();
      }
    };
    if (visible) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible]);

  if (!visible) return null;

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div ref={dialogRef} className="absolute bg-white p-6 rounded-lg shadow-lg z-10" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <div className={'flex flex-row items-center justify-between mb-6 gap-4'}>
          <div className={'font-normal text-2xl'}>{heading}</div>
          <SvgDialogCloseIcon onClick={onHide} className={'cursor-pointer'} />
        </div>
        {children}
        <div className={'flex flex-row gap-6 items-center mt-4'}>{buttons}</div>
      </div>
    </div>,
    document.body,
  );
};

export default RingilDialog;
