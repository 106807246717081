import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import ListCustomerGroupDemand from '@components/obsolete/multi-demand/ListCustomerGroupDemand/ListCustomerGroupDemand.tsx';

const ListCustomerGroupDemandPage = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`ListCustomerGroupDemand.title`, `All group demands`)}>
      {/*
        // @ts-ignore*/}
      <Card>
        <ListCustomerGroupDemand />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(ListCustomerGroupDemandPage);
