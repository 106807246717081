import React from 'react';
import { Button } from 'primereact/button';
import useTranslationLgs from '../../../../hooks/i18n/useTranslation';

interface ComponentProps {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const EditButton: React.FC<ComponentProps> = ({ onClick, label }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Button className="p-button-outlined flex-shrink-0" iconPos="left" icon="pi pi-pencil" onClick={e => onClick(e)} />
    </>
  );
};

export default EditButton;
