import React, { useEffect, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
}
// DEPRECATED
export const NewNote: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { tr } = useTranslationLgs();
  useEffect(() => {
    if (!visibleNoteValue && !isNullOrUndefined(value) && value.length > 0) {
      setVisibleNoteValue(true);
    }
  }, [value]);
  const [visibleNoteValue, setVisibleNoteValue] = useState(!isNullOrUndefined(value) && value.length > 0);
  return (
    <div className="new-note">
      {!visibleNoteValue && <Button label={tr(`NewNote.addNote`, `+ Poznámka`)} className="p-mb-3 p-button-outlined" onClick={() => setVisibleNoteValue(true)} />}
      {visibleNoteValue && (
        <div className="new-note__message">
          <InputTextarea
            value={value}
            onChange={e => {
              onChange(e.target.value);
            }}
            autoResize
            rows={6}
            cols={30}
            style={{ width: '100%' }}
          />
        </div>
      )}
    </div>
  );
};
