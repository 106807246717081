import React from 'react';
import { Dayjs } from 'dayjs';
import { isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  dateTime: Dayjs;
}

const TimeAndDate: React.FC<ComponentProps> = ({ dateTime }) => {
  if (isNullOrUndefined(dateTime)) return <></>;

  return (
    <div className={'inline-flex flex-row w-[150px]'}>
      <div className="w-[50px]">{dateTime.format('HH:mm')}</div>
      <div className={'w-[10px] text-content-tertiary'}>|</div>
      <div className="w-[70px] text-content-tertiary">{dateTime.format('DD.MM.YYYY')}</div>
    </div>
  );
};

export default TimeAndDate;
