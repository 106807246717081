import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import { MyProfile } from '@app/pages/my-profile/MyProfile.tsx';

const PageMyProfilePage = () => {
  const { tr } = useTranslation();
  return (
    <Layout title={tr(`myprofile.myProfile`, `My profile`)}>
      {/*
        // @ts-ignore*/}
      <Card>
        <MyProfile />
      </Card>
    </Layout>
  );
};
export default withAuthenticationRequired(PageMyProfilePage);
