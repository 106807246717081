import React from 'react';
import COSelect from '@components/framework/selects/COSelect.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';
import { CompanyModuleEnum } from '@api/logsteo-api.v2.tsx';

export interface CustomerNotificationData {
  module: CompanyModuleEnum;
  count: number;
  unread: number;
}

interface ComponentProps {
  data: CustomerNotificationData[];
  onModuleChange?: (module?: CompanyModuleEnum) => void;
  selectedModule?: CompanyModuleEnum;
}

interface Item {
  label: string;
  unread: number;
  module?: CompanyModuleEnum;
}

const FilterButtons: React.FC<ComponentProps> = ({ data, onModuleChange, selectedModule }) => {
  const { tr } = useTranslationLgs();
  //const order = [ModulesEnum.EXPEDITION, ModulesEnum.SUPPLIER_NOTIFICATION, ModulesEnum.TIMESLOTS, ModulesEnum.CARRIER];

  if (isNullOrUndefined(data)) return <></>;

  const summed = data.reduce((acc: number, curr: CustomerNotificationData) => {
    return acc + curr.count;
  }, 0);

  const summedNew = data.reduce((acc: number, curr: CustomerNotificationData) => {
    return acc + curr.unread;
  }, 0);

  const items = [
    { label: tr('FilterButtons.all', 'All {{_count}}', { _count: summed }), unread: summedNew, module: null },
    ...data.map(t => ({
      label: tr(`FilterButtons.${t.module}`, `${t.module} {{_count}}`, { _count: t.count }),
      unread: t.unread,
      module: t.module,
    })),
  ] as Item[];

  return (
    <>
      <div className={'flex flex-row gap-2'}>
        {items.map((item, index) => (
          <div className={'cursor-pointer'} key={index}>
            <COSelect
              selected={selectedModule == item.module}
              label={item.label}
              onClick={() => {
                onModuleChange(item.module);
              }}
              badgeTexts={item.unread > 0 ? [tr('FilterButtons.new', '{{count}} new', { count: item.unread }, item.unread)] : []}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default FilterButtons;
