import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Layout from '@app/layout/Layout.tsx';
import AccountingList from '@components/obsolete/accounting-list/AccountingList.tsx';

interface ComponentProps {}

const AccountingListPage: React.FC<ComponentProps> = () => {
  return (
    <>
      <Layout>
        <AccountingList />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(AccountingListPage);
