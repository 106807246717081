import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { PickList, PickListChangeParams } from 'primereact/picklist';
import { LovGetPermissionResponse, PermissionTypesEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  value: PermissionTypesEnum[] | string[];
  onChange: (selectedPermission: PermissionTypesEnum[]) => void;
}

const InputPickPermissions: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { lovGetPermissions } = useContext(ApiContext);

  const [sourcePermissions, setSourcePermissions] = useState<LovGetPermissionResponse>();

  useEffect(() => {
    const ac = new AbortController();
    lovGetPermissions(d => {
      if (!ac.signal.aborted) {
        setSourcePermissions(d);
      }
    });
    return () => {
      ac.abort();
    };
  }, []);

  const onChangeInternal = (event: PickListChangeParams) => {
    onChange(event.target);
  };

  return (
    <>
      <PickList
        source={sourcePermissions?.permissions.filter(p => !value.includes(PermissionTypesEnum[p.name])).map(t => t.name)}
        target={sourcePermissions?.permissions.filter(p => value.includes(PermissionTypesEnum[p.name])).map(t => t.name)}
        onChange={onChangeInternal}
        sourceHeader="Available"
        targetHeader="Selected"
        showSourceControls={false}
        showTargetControls={false}
      />
    </>
  );
};

export default InputPickPermissions;
