import React, { PropsWithChildren, useContext, useEffect, useId, useRef, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { AddressBookTemplateOutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import clsx from 'clsx';

interface ComponentProps {
  onChangeName: (locationName: string) => void;
  locationName: string;
  onSelectAddress: (partnerLocation: AddressBookTemplateOutDTO) => void;
  wrapper?: string;
  partnerId?: string;
}

const LocationAutocomplete: React.FC<PropsWithChildren<ComponentProps>> = (
  { locationName, onChangeName, onSelectAddress, children, wrapper, partnerId = null },
  context,
) => {
  const { cuGetLocationTemplatesAddressBook } = useContext(ApiContext);

  const [addressBookSuggestions, setAddressBookSuggestions] = useState<AddressBookTemplateOutDTO[]>();

  const loadFromTemplate = (q: string) => {
    cuGetLocationTemplatesAddressBook(q, partnerId, data => {
      setAddressBookSuggestions(data.addressBooks);
    });
  };

  const className = clsx({
    'p-field p-col-12': wrapper === 'p-field p-col-12',
  });

  const random = useId().replace(/:/g, '');
  useEffect(() => {
    // @ts-ignore
    document.getElementById(random).autocomplete = 'nope';
  }, [random]);

  return (
    <>
      <div>
        <AutoComplete
          value={locationName}
          dropdown
          inputId={random}
          itemTemplate={data => <strong>{data.name}</strong>}
          onChange={e => onChangeName(typeof e.target.value === 'string' ? e.target.value : e.target.value.name)}
          delay={3}
          field="name"
          /*completeMethod={(e: { originalEvent: Event; query: string }) => loadFromTemplate(e.query)}*/
          completeMethod={e => loadFromTemplate(e.query)}
          suggestions={addressBookSuggestions}
          onSelect={e => onSelectAddress(e.value)}
        />
      </div>
      {children}
    </>
  );
};

export default LocationAutocomplete;
