import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled, { css } from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Button } from 'primereact/button';

interface ComponentProps {
  label: string;
  onClick: () => void;
  checked: boolean;
}

export const SelectableButton: React.FC<ComponentProps> = ({ label, onClick, checked }) => {
  return (
    <>
      <ButtonInner onClick={e => onClick()} checked={checked}>
        {checked && <img src={'/images/icons/ringil3/checked.svg'} />}
        <span>{label}</span>
      </ButtonInner>
    </>
  );
};

const ButtonInner = styled.button<Pick<ComponentProps, 'checked'>>`
  border-radius: 48px;

  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  padding: 16px 16px;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  ${props =>
    props.checked &&
    css`
      color: var(--ringil3-brand-color);
      background-color: var(--ringil3-brand-light-color);
      border: 2px solid var(--ringil3-brand-color);
    `}
`;
