import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { LovDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';

interface ComponentProps {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
}

export const useInputDrivers = () => {
  const { lovDriverTemplates } = useContext(ApiContext);
  const [drivers, setDrivers] = useState<LovDTO[]>([]);

  useEffect(() => {
    lovDriverTemplates('', d => {
      setDrivers(d);
    });
  }, []);

  const InputDrivers: React.FC<ComponentProps> = ({ value, onChange, placeholder }) => {
    return (
      <>
        <MultiSelect options={drivers} optionLabel={'name'} optionValue={'id'} value={value} onChange={e => onChange(e.value)} placeholder={placeholder} />
      </>
    );
  };

  return { InputDrivers };
};
