import React, { useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { LocationTimeslotQuoteDemandOutDTO, MyQuoteInfoOutDTO } from '@api/logsteo-api.v2';
import { useImmer } from 'use-immer';
import { dateFormatOnlyDateShort, mapFromAPIDateTime, mapToAPIDateTime, setTime, shortDateFormat } from '@utils/date';
import dayjs, { Dayjs } from 'dayjs';
import { first, getTime } from '@utils/utils';
import { InputMask } from 'primereact/inputmask';
import { Slot } from '../EditSlots/EditSlotsComponent';
import ValidationDiv from '@utils/validation';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import TimeslotsProgressTitle from '@components/obsolete/TimeslotsProgress/TimeslotsProgressTitle.tsx';
import TimeslotsProgressInput from '@components/obsolete/TimeslotsProgress/TimeslotsProgressInput.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  myQuoteInfo: MyQuoteInfoOutDTO;
  timeslots: LocationTimeslotQuoteDemandOutDTO[];
  onNewQuote: (myQuoteInfo: ViewMyQuoteInfoOutDTO, timeslots: LocationTimeslotQuoteDemandOutDTO[]) => void;
  code: string;
}

interface ComponentData {
  myQuoteInfo: ViewMyQuoteInfoOutDTO;
  timeslots: LocationTimeSlots[];
}

export interface LocationTimeSlots {
  city: string;
  zipCode: string;
  country: string;
  name: string;
  id: string;
  streetNr: string;
  useCustomSlots: boolean;
  carrierSlot: Slot;
  customerSlots: Slot[];
}

export interface ViewMyQuoteInfoOutDTO {
  myQuotePrice: number;
  myQuoteCurrency: string;
  myQuoteValidTill: Dayjs;
}

const EditQuoteSidemodal: React.FC<ComponentProps> = ({ visible, onHide, myQuoteInfo, timeslots, onNewQuote, code }) => {
  const { t } = useTranslation('common');
  const [state, setState] = useImmer<ComponentData>({
    timeslots: [],
    myQuoteInfo: {
      myQuoteCurrency: myQuoteInfo.myQuoteCurrency,
      myQuotePrice: myQuoteInfo.myQuotePrice,
      myQuoteValidTill: mapFromAPIDateTime(myQuoteInfo.myQuoteValidTill),
    },
  });

  useEffect(() => {
    if (visible) {
      clear();
      setState(draft => {
        draft.timeslots = timeslots.map(timeslot => {
          return {
            id: timeslot.locationId,
            city: timeslot.city,
            country: timeslot.country,
            name: timeslot.locationName,
            streetNr: timeslot.streetNr,
            zipCode: timeslot.zipCode,
            useCustomSlots: timeslot.timeslots.some(t => t.isCarrier),
            customerSlots: timeslot.timeslots
              .filter(t => !t.isCarrier)
              .map(t => {
                return {
                  isWholeDay: t.isNotSpecifiedTime,
                  tillTime: getTime(mapFromAPIDateTime(t.tillInUTC)),
                  sinceTime: getTime(mapFromAPIDateTime(t.sinceInUTC)),
                  selectedDate: mapFromAPIDateTime(t.sinceInUTC),
                } as Slot;
              }),
            carrierSlot: first(
              timeslot.timeslots
                .filter(t => t.isCarrier)
                .map(t => {
                  return {
                    tillTime: getTime(mapFromAPIDateTime(t.tillInUTC)),
                    sinceTime: getTime(mapFromAPIDateTime(t.sinceInUTC)),
                    isWholeDay: t.isNotSpecifiedTime,
                    selectedDate: mapFromAPIDateTime(t.sinceInUTC),
                  } as Slot;
                }),
            ),
          } as LocationTimeSlots;
        });
        draft.myQuoteInfo = {
          myQuoteCurrency: myQuoteInfo.myQuoteCurrency,
          myQuotePrice: myQuoteInfo.myQuotePrice,
          myQuoteValidTill: mapFromAPIDateTime(myQuoteInfo.myQuoteValidTill),
        };
      });
    }
  }, [visible]);

  const validationScheme = yup.object().shape({
    myQuoteInfo: yup.object().shape({
      myQuotePrice: yup.number().defined().required(),
      myQuoteCurrency: yup.string().defined().required(),
      myQuoteValidTill: yup
        .mixed()
        .test('required', 'required', (value: Dayjs) => value.isValid())
        .test('in-future', 'in-future', (value: Dayjs) => value.isValid() && value.isAfter(dayjs())),
    }),
    timeslots: yup.array().of(
      yup.object().shape({
        carrierSlot: yup.object().when('useCustomSlots', {
          is: true,
          then: yup.object().shape({
            selectedDate: yup.mixed().test('required', 'required', (value: Dayjs) => value.isValid()),
            sinceTime: yup.string().defined().required(),
            tillTime: yup.string().defined().required(),
          }),
          otherwise: yup.object().nullable(),
        }),
      }),
    ),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(validationScheme, () => {
    const remappedSlots = state.timeslots.map(t => {
      return {
        locationName: t.name,
        zipCode: t.zipCode,
        country: t.country,
        city: t.city,
        streetNr: t.streetNr,
        locationId: t.id,
        timeslots: [
          ...t.customerSlots.map(ts => {
            return {
              id: null,
              selected: false,
              isSelected: false,
              isCarrier: false,
              isNotSpecifiedTime: ts.isWholeDay,
              sinceInUTC: ts.sinceTime,
              tillInUTC: ts.tillTime,
            };
          }),
          t.carrierSlot
            ? {
                id: null,
                tillInUTC: mapToAPIDateTime(setTime(t.carrierSlot.selectedDate, t.carrierSlot.tillTime)),
                sinceInUTC: mapToAPIDateTime(setTime(t.carrierSlot.selectedDate, t.carrierSlot.sinceTime)),
                isCarrier: true,
                isNotSpecifiedTime: false,
                isSelected: true,
                selected: true,
              }
            : [],
        ],
      } as LocationTimeslotQuoteDemandOutDTO;
    });

    onNewQuote(state.myQuoteInfo, remappedSlots);
  });

  return (
    <>
      {/*// @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal sidebar-modal-md sidebar-modal-edit-price"
        style={{ width: '750px' }}>
        {state && (
          <>
            <h3>{t(`EditQuoteSidemodal.editYourPriceQuotes`, `Upravte svojí nabídku`)}</h3>
            <div className="p-field">
              <Label title={t(`EditQuoteSidemodal.setYourPrice`, `Nová cena`)} />
              <div className="p-inputgroup">
                <InputPriceWithCurrency
                  code={code}
                  price={state.myQuoteInfo.myQuotePrice}
                  currency={state.myQuoteInfo.myQuoteCurrency}
                  changePrice={v => {
                    setState(draft => {
                      draft.myQuoteInfo.myQuotePrice = v;
                    });
                  }}
                  changeCurrency={v => {
                    setState(draft => {
                      draft.myQuoteInfo.myQuoteCurrency = v;
                    });
                  }}
                />
              </div>
              <ValidationDiv errors={validationErrors} path={'myQuoteInfo.myQuotePrice'} />
            </div>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-col-12">
                <Label title={t(`EditQuoteSidemodal.setAValidityDateOfYourPriceQuote`, `Set a validity date of your price quote`)} />
              </div>
              <div className="p-field p-col-12 p-md-6">
                <Calendar
                  locale={'cs'}
                  className="p-mb-1"
                  dateFormat={shortDateFormat}
                  value={state.myQuoteInfo?.myQuoteValidTill.toDate()}
                  onChange={e => {
                    setState(draft => {
                      draft.myQuoteInfo.myQuoteValidTill = dayjs(e.value as Date);
                    });
                  }}
                  showIcon
                />
                <ValidationDiv errors={validationErrors} path={'myQuoteInfo.myQuoteValidTill'} />
              </div>

              <div className="p-field p-col-12 p-md-6">
                <InputMask
                  mask={'99:99'}
                  value={getTime(state.myQuoteInfo.myQuoteValidTill)}
                  onComplete={e =>
                    setState(draft => {
                      draft.myQuoteInfo.myQuoteValidTill = setTime(draft.myQuoteInfo.myQuoteValidTill, e.value);
                    })
                  }
                />
                <ValidationDiv errors={validationErrors} path={'myQuoteInfo.myQuoteValidTill'} />
              </div>
            </div>
            <h3>{t(`EditQuoteSidemodal.shipmentTimeslots`, `Shipment timeslots`)}</h3>
            <div className="">
              {state.timeslots.map((location, locationIndex) => {
                return (
                  <div className="timeslotsProgress" key={locationIndex}>
                    <TimeslotsProgressTitle
                      number={`${locationIndex + 1}`}
                      address={`${location.zipCode}, ${location.city}, (${location.country})`}
                      company={location.name}
                    />

                    <TimeslotsProgressInput
                      title={t('EditSlotsComponent.weAcceptTimeslotsForCustomer', 'Akceptovat časová okna zákazníka')}
                      checked={!location.useCustomSlots}
                      onCheck={() =>
                        setState(draft => {
                          draft.timeslots[locationIndex].useCustomSlots = false;
                          draft.timeslots[locationIndex].carrierSlot = null;
                        })
                      }>
                      <div>
                        {location.customerSlots.map((customerSlot, slotIndex) => {
                          return (
                            <TimeListItem
                              key={slotIndex}
                              date={dateFormatOnlyDateShort(customerSlot.selectedDate)}
                              isWholeDay={customerSlot.isWholeDay}
                              time={`${customerSlot.sinceTime} - ${customerSlot.tillTime}`}
                            />
                          );
                        })}
                      </div>
                      {/*<div className="p-invalid-feedback">Hodnota musí být zadána</div>*/}
                    </TimeslotsProgressInput>
                    <TimeslotsProgressInput
                      title={t('EditSlotsComponent.weSpecifyOwnTimeslot', 'Zadáme vlastní čas. okna')}
                      checked={location.useCustomSlots}
                      onCheck={() =>
                        setState(draft => {
                          draft.timeslots[locationIndex].useCustomSlots = true;
                          draft.timeslots[locationIndex].carrierSlot = {
                            selectedDate: dayjs(),
                            tillTime: '',
                            sinceTime: '',
                            isWholeDay: false,
                          };
                        })
                      }>
                      {location.useCustomSlots && (
                        <>
                          <div className="p-mb-3">
                            <Calendar
                              locale={'cs'}
                              className="p-mr-4"
                              showIcon
                              style={{ width: '100%' }}
                              disabled={!location.useCustomSlots}
                              dateFormat={shortDateFormat}
                              onChange={e => {
                                setState(draft => {
                                  draft.timeslots[locationIndex].carrierSlot.selectedDate = dayjs(e.value as Date);
                                });
                              }}
                              value={state.timeslots[locationIndex].carrierSlot?.selectedDate?.toDate()}
                            />
                          </div>
                          <div>
                            <Label title={t('EditSlotsComponent.whatTime', 'Kdy')} required={true} />
                            <div className="dropdown-wrapper-wdelimiter--half">
                              <div className="item p-mb-1">
                                <InputMask
                                  mask={'99:99'}
                                  value={state.timeslots[locationIndex].carrierSlot?.sinceTime || ''}
                                  className={'p-md-12'}
                                  disabled={!location.useCustomSlots}
                                  onComplete={e => {
                                    setState(draft => {
                                      draft.timeslots[locationIndex].carrierSlot.sinceTime = e.value;
                                    });
                                  }}
                                />
                                <ValidationDiv errors={validationErrors} path={`timeslots[${locationIndex}].carrierSlot.sinceTime`} />
                              </div>
                              <div className="delimiter"> —</div>
                              <div className="item p-mb-1">
                                <InputMask
                                  mask={'99:99'}
                                  value={state.timeslots[locationIndex].carrierSlot?.tillTime || ''}
                                  className={'p-md-12'}
                                  disabled={!location.useCustomSlots}
                                  onComplete={e => {
                                    setState(draft => {
                                      draft.timeslots[locationIndex].carrierSlot.tillTime = e.value;
                                    });
                                  }}
                                />
                                <ValidationDiv errors={validationErrors} path={`timeslots[${locationIndex}].carrierSlot.tillTime`} />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </TimeslotsProgressInput>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <ButtonLayout wrapper="WITH_PADDING_TOP_0">
          <Button label={t(`EditQuoteSidemodal.confirm`, `Potvrdit`)} className="p-mr-3" onClick={() => validateAndSend(state)} />
          <Button label={t(`EditQuoteSidemodal.cancel`, `Zrušit`)} className="p-button-outlined p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default EditQuoteSidemodal;
