import { InputSwitch } from 'primereact/inputswitch';
import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { isLastItem } from '@utils/utils';
import { OrdersComponent } from './OrdersComponent';
import ValidationDiv from '@utils/validation';
import { OrderViewData } from '@components/obsolete/customer/new-expedition/types.tsx';
import { useTranslation } from 'react-i18next';
import { CreateTemplateHandlerContext } from '@components/obsolete/CreateTemplate/CreateTemplateHandler.tsx';
import PanelCard from '@components/obsolete/PanelCard/PanelCard.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  collapsed: boolean;
  orders: OrderViewData[];
  locationIndex: number;
  locationName: string;
}

export const LoadingComponent: React.FC<ComponentProps> = ({ collapsed, orders, locationIndex, locationName }) => {
  const { t } = useTranslation();

  const { toggleLoading, addLoadingUnit, addOrder, state } = useContext(CreateTemplateHandlerContext);

  return (
    <>
      <PanelCard>
        <div className="p-sm-6 p-d-flex p-ai-center">
          <InputSwitch checked={collapsed} onChange={e => toggleLoading(locationIndex)} className="p-mr-2" disabled={locationIndex == 0} />
          <label htmlFor="binary-2">{t('loadDetailsPage.labelPickUp')}</label>
        </div>
        <div className="p-sm-6 p-d-flex p-jc-end">
          <img src="/images/icon_pickup.png" alt="" style={{ width: '60px' }} />
        </div>
      </PanelCard>
      {collapsed &&
        orders?.length !== 0 &&
        orders.map((item, orderIndex, orders) => (
          <div key={orderIndex}>
            <OrdersComponent order={item} locationIndex={locationIndex} orderIndex={orderIndex} />
            {/*            {state.validationErrors
              .filter((t) => t.path === `locations[${locationIndex}].loadingOrders[${orderIndex}].unloadingLocationId`)
              .map((t) => (
                <div className="p-invalid-feedback "></div>
              ))}*/}
            <ValidationDiv errors={state.validationErrors} path={`locations[${locationIndex}].loadingOrders[${orderIndex}].unloadingLocationId`} />
            <ButtonLayout wrapper="WITH_PADDING_BOTTOM_2_TOP_4">
              <Button
                label={'+ ' + t('loadDetailsPage.panelBooking.buttonHandlingUnit')}
                className="p-button-outlined p-mr-2"
                onClick={() => {
                  addLoadingUnit(locationIndex, orderIndex);
                }}
              />
              {isLastItem(orderIndex, orders) && (
                <Button
                  label={'+ ' + t('loadDetailsPage.labelBookingRef')}
                  className="p-button-outlined"
                  onClick={() => {
                    addOrder(locationIndex);
                  }}
                />
              )}
            </ButtonLayout>
          </div>
        ))}
    </>
  );
};

export default LoadingComponent;
