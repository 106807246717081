import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import { AdminGetUsersFilter, AdminUserOutDTO, PageResponseAdminUserOutDTO } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { NoWrapText, Row } from '@components/styles.tsx';
import { EditUserSidebar } from '@components/obsolete/CompanyProfile/EditUserSidebar.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {}

interface CompanyAndUserId {
  companyId: string;
  userId: string;
}

const AdminAllUsers: React.FC<ComponentProps> = () => {
  const { adminGetUsers, adminCreateUserInAuth0 } = useContext(ApiContext);
  const [pageData, setPageData] = useState<PageResponseAdminUserOutDTO>();
  const { showToastMessage } = useContext(ToastContext);
  const [showPermisionForUser, setShowPermisionForUser] = useState<CompanyAndUserId>(undefined);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort } = useFilter<AdminGetUsersFilter>(
    (page, filter) => {
      console.log({
        Filter,
        applyFilterValue,
        filter,
        applyPaging,
        page,
        onPage,
      });
      load(page.pageSize, page.pageNumber, filter);
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  const load = (pageSize: number, pageNumber: number, filter: AdminGetUsersFilter) => {
    adminGetUsers({ pageSize, pageNumber }, filter, d => {
      setPageData(d);
    });
  };

  if (isNullOrUndefined(pageData)) return <></>;

  const renderState = (row: AdminUserOutDTO) => {
    return (
      <>
        <div>
          {row.registered && <div>Registered</div>}
          {row.active && <div>Active</div>}
        </div>
      </>
    );
  };

  const renderActions = (row: AdminUserOutDTO) => {
    return (
      <NoWrapText>
        <Button
          label={'Create in Auth0'}
          onClick={e => {
            adminCreateUserInAuth0({ userId: row.id }, () => {
              showToastMessage('Success', 'User created in Auth0', 'success');
            });
          }}
        />
        <Button
          label={'Manage permission'}
          onClick={e => {
            setShowPermisionForUser({
              companyId: row.companyId,
              userId: row.id,
            });
          }}
        />
        <a href={`/?runAs=${row.id}`} target={'_blank'}>
          Login as user
        </a>
      </NoWrapText>
    );
  };

  return (
    <>
      <Row>
        <HorizontalFieldValue
          label={'email'}
          value={
            <InputText
              value={filter.email}
              onChange={e => {
                applyFilterValue('email', e.target.value);
              }}
            />
          }
        />
        <HorizontalFieldValue
          label={'firstName'}
          value={
            <InputText
              value={filter.firstName}
              onChange={e => {
                applyFilterValue('firstName', e.target.value);
              }}
            />
          }
        />
        <HorizontalFieldValue
          label={'lastName'}
          value={
            <InputText
              value={filter.lastName}
              onChange={e => {
                applyFilterValue('lastName', e.target.value);
              }}
            />
          }
        />
        <HorizontalFieldValue
          label={'companyName'}
          value={
            <InputText
              value={filter.companyName}
              onChange={e => {
                applyFilterValue('companyName', e.target.value);
              }}
            />
          }
        />
      </Row>
      {/*// @ts-ignore*/}
      <DataTable
        value={pageData.data}
        rows={pageData.size}
        paginator={true}
        lazy={true}
        totalRecords={pageData.total}
        onPage={onPage}
        first={(pageData.page - 1) * pageData.size}
      >
        <Column header={'email'} field={'email'} style={{ width: '25rem' }} />
        <Column header={'firstName'} field={'firstName'} style={{ width: '10rem' }} />
        <Column header={'lastName'} field={'lastName'} style={{ width: '10rem' }} />
        <Column header={'companyName'} field={'companyName'} style={{ width: '10rem' }} />
        <Column header={'state'} body={renderState} style={{ width: '10rem' }} />
        <Column header={'actions'} body={renderActions} style={{ width: '10rem' }} />
      </DataTable>
      {isNotNullOrUndefined(showPermisionForUser) && (
        <EditUserSidebar
          visible={isNotNullOrUndefined(showPermisionForUser)}
          onHide={() => {
            setShowPermisionForUser(undefined);
          }}
          onComplete={() => {
            setShowPermisionForUser(undefined);
            load(pageData.size, pageData.page, filter);
          }}
          userId={showPermisionForUser.userId}
          companyId={showPermisionForUser.companyId}
        />
      )}
    </>
  );
};

export default AdminAllUsers;
