import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CreateReservationCheckEnum } from '@api/logsteo-api.v2';
import { Note } from '@components/styles';
import { dumpVars, isNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  companyLocationId?: string;
}

const CanMakeReservation: React.FC<PropsWithChildren<ComponentProps>> = ({ children, companyLocationId }) => {
  const { checkUserCanCreateReservation } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [result, setResult] = useState<CreateReservationCheckEnum>();

  useEffect(() => {
    const ac = new AbortController();

    checkUserCanCreateReservation(companyLocationId, data => {
      if (ac.signal.aborted) return;
      setResult(data.createReservationCheck);
    });
    return () => {
      ac.abort();
    };
  }, [companyLocationId]);

  return (
    <>
      {isNullOrUndefined(companyLocationId) && <Note>{tr(`CanMakeReservation.thisIsNotCompanyLocation`, `This is not company location`)}</Note>}

      {result === CreateReservationCheckEnum.NO_TIMESLOT_MODULE && (
        <Note>{tr(`CanMakeReservation.companyDoesNotOwnTheTimeslotModule`, `Company does not own the timeslot module`)}</Note>
      )}

      {result === CreateReservationCheckEnum.NOT_VISIBLE_FOR_USER && (
        <Note>{tr(`CanMakeReservation.thisLocationIsNotVisibleForYou`, `This location is not visible for you`)}</Note>
      )}

      {result === CreateReservationCheckEnum.CAN_CREATE_RESERVATION && <>{children}</>}
    </>
  );
};

export default CanMakeReservation;
