import React, { useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useImmer } from 'use-immer';
import { ExpeditionTypeEnum, LoadingTypesEnum } from '@api/logsteo-api.v2';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import { findValidationMessage } from '@utils/validation';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { TruckTypeSelector } from '@components/obsolete/common/TruckTypeSelector.tsx';
import { LoadingTypeSelector } from '@components/obsolete/common/LoadingTypeSelector.tsx';
import { SpecialRequirementsSelector } from '@components/obsolete/common/SpecialRequirementsSelector.tsx';
import { CargoTypeSelector } from '@components/obsolete/common/CargoTypeSelector.tsx';
import { TextArea } from '@components/obsolete/common/TextArea.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  data: ComponentData;
  onComplete: (data: ComponentData) => void;
}

export interface ComponentData {
  expeditionType: ExpeditionTypeEnum;
  truckTypeId: string;
  selectedRequirement: string[];
  cargoTypeCode: string;
  adrWeight?: number;
  manipulationTypes: LoadingTypesEnum[];
  carrierNote: string;
}

const EditRequirementSidebar: React.FC<ComponentProps> = ({ visible, onHide, data, onComplete }) => {
  const { t } = useTranslation('common');
  const [state, setState] = useImmer<ComponentData>(data);

  useEffect(() => {
    if (visible)
      setState(draft => {
        return data;
      });
  }, [visible]);

  const validationScheme = yup.object().shape({
    manipulationTypes: yup.array().required().min(1),
  });

  const { validateAndSend, validationErrors, clear } = useValidation(validationScheme, () => onComplete(state));

  return (
    <>
      {/*//
      @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal" blockScroll={true} style={{ width: '750px' }}>
        <h3 className="p-mb-4">{t(`EditRequirementSidebar.editCarrierRequirements`, `Edit carrier requirements`)}</h3>
        <div className="p-mb-6">
          <Label title={t(`EditRequirementSidebar.truckType`, `Truck type`)} />
          <TruckTypeSelector
            onChange={e =>
              setState(draft => {
                draft.truckTypeId = e;
              })
            }
            value={state.truckTypeId}
            required={true}
          />
        </div>
        <LoadingTypeSelector
          values={state.manipulationTypes}
          onChange={v =>
            setState(draft => {
              draft.manipulationTypes = v;
            })
          }
          errorMessages={findValidationMessage(validationErrors, 'manipulationTypes', t)}
        />
        <SpecialRequirementsSelector
          selectedIds={state.selectedRequirement}
          onChange={e => {
            setState(draft => {
              draft.selectedRequirement = e;
            });
          }}
        />
        <CargoTypeSelector
          value={state.cargoTypeCode}
          onChange={e => {
            setState(draft => {
              draft.cargoTypeCode = e;
            });
          }}
          adrWeight={state.adrWeight}
          onChangeAdrWeight={e => {
            setState(draft => {
              draft.adrWeight = e;
            });
          }}
          errorMessages="Hodnota musí být zadána"
        />

        <TextArea
          value={state.carrierNote}
          onChange={v =>
            setState(draft => {
              draft.carrierNote = v;
            })
          }
          displayFull={true}
        />

        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          <Button label={t(`EditRequirementSidebar.continue`, `Continue`)} className="p-mr-2" onClick={() => validateAndSend(state)} />
          <Button label={t(`EditRequirementSidebar.back`, `Back`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default EditRequirementSidebar;
