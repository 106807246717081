import { AddressDetailPlanOutDTO, ContactPerson } from '@api/logsteo-api.v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Address from '@components/obsolete/Address/Address.tsx';
import LoadInfoItem from '@components/obsolete/LoadDetails/LoadInfo/LoadInfoItem.tsx';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';

export interface LocationDetail {
  name: string;
  address: AddressDetailPlanOutDTO;
  pickupCode?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  enabledEmailNotificationForContactPerson: boolean;
  additionalNotificationContacts: ContactPerson[];
}

interface ContactInfoProps {
  location: LocationDetail;
}

export const ExpeditionContactInfo: React.FC<ContactInfoProps> = ({ location }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-6">
        <div className="p-d-flex">
          <div className="icon-accordion">
            <img src="/images/contacts.svg" alt="" />
          </div>
          <div>
            <Label title={t('expeditionOverviewPage.labelContacts')} />
            {location.pickupCode && (
              <div className="p-mt-2 p-mb-2 text-gray5">
                <small>
                  {t(`LoadInfoItem.codeRef`, `Kód pro dopravce: {{codeRef}}`, {
                    codeRef: location.pickupCode,
                  })}
                </small>
              </div>
            )}
            <Address company={`${location.name}, ${location.address.city}`} address={`${location.address.streetNr}, ${location.address.zipCode}`} />
            <LoadInfoItem name={location.contactName} phone={location.contactPhone} email={location.contactEmail} className="p-mb-3"></LoadInfoItem>
            <CheckboxWithLabel
              checkboxLabel={tr(`ExpeditionContactInfo.sendNotification`, `Send notification`)}
              value={location.enabledEmailNotificationForContactPerson}
              onChange={() => {}}
              disabled={true}
            />
            {location.additionalNotificationContacts && (
              <HorizontalFieldValue
                label={tr('ExpeditionContactInfo.additionalNotifiedPersons', 'Additional notified persons')}
                value={location.additionalNotificationContacts.map(t => (
                  <LoadInfoItem name={t.contactName} phone={t.contactPhone} email={t.contactEmail} className="p-mb-3"></LoadInfoItem>
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
