import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dropdown } from 'primereact/dropdown';
import { EntityTypeEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  value: EntityTypeEnum;
  onChange: (value: EntityTypeEnum) => void;
}

const InputEntityType: React.FC<ComponentProps> = ({ onChange, value }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Dropdown
        value={value}
        onChange={e => onChange(e.value)}
        options={Object.values(EntityTypeEnum)}
        placeholder={tr('InputEntityType.selectEntityType', 'Select Entity Type')}
      />
    </>
  );
};

export default InputEntityType;
