import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import {
  CompanyModuleEnum,
  FreeSlotsWrapperOutDTO,
  GlobalOrdersRequest,
  OrderReservationInDTO,
  ReservationActionTypesEnum,
  ReservationCoordinates,
  ReservationDriverInfoInDTO,
  TimeslotCalendarDTO,
} from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import { dumpVars, isNotNullOrUndefined, isNullOrUndefined, parseNumberOrUndefined } from '@utils/utils';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';
import { ColWithGap, RowWithGap, RowWithSpaceBetween } from '@components/styles';
import { ClickableCursor, ColOdd, HorizontalLine, TwoColGrid } from '@components/ringil3/styles';
import InputManipulationType from '@components/ringil3/Input/InputManipulationType/InputManipulationType';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix';
import InputNoteWithLink from '@components/ringil3/Input/InputNoteWithLink/InputNoteWithLink';
import OrderTabs, { TabProps } from '@components/ringil3/RingilTabs/OrderTabs';
import InputEntityOwner from '@components/obsolete/Form/InputEntityOwner/InputEntityOwner';
import InputCompanyLocation from '@components/obsolete/Form/InputEntityOwner/InputCompanyLocation';
import { findValidationMessage } from '@utils/validation';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge';
import InputCompanyRamp from '@components/ringil3/Input/InputCompanyRamp/InputCompanyRamp';
import { useDebounce } from 'use-debounce';
import InputHourManual from '@components/ringil3/Input/InputHour/InputHourManual';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage';
import dayjs, { Dayjs } from 'dayjs';
import { createZonedDateTime, mapFromAPIDateTime, mapFromAPIToTime, mapToAPIDateTime, toTime } from '@utils/date';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon';
import { ProgressSpinner } from 'primereact/progressspinner';
import InputInheriterCompany from '@components/obsolete/Form/InputEntityOwner/InputInheriterCompany';
import { emptyOrder, Order, orderSchema } from '@components/framework/orders/common.tsx';
import SvgAvailableDates from '@components/ringil3/icons/reservation/AvailableDates';
import SvgRefresh from '@components/ringil3/icons/Refresh';
import Panel from '@components/ringil3/Panel/Panel';
import SvgClose from '@components/ringil3/icons/panel/Close';
import COSelect from '@components/ringil3/COSelect/COSelect';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { reservationOrdersConfig } from '@components/ringil3/Features/Reservation/orderConfig.ts';

interface ComponentProps {
  onContinue: (reservation: ReservationCoordinates) => void;
  initialData?: CreateTimeslotForm;
  headerDisabled?: boolean;
}

export interface CreateTimeslotRequest {
  applicationId: string;
  expeditionId: string;
  companyLocationId: string;
  companyLocationCustomerId: string;
  expeditionLocationId?: string;
  preferredDate: string;
}

interface CreateTimeslotForm {
  actionTypes: ReservationActionTypesEnum[];
  companyLocationId: string;
  companyLocationCustomerId: string;
  customerCompanyCode: string;
  orders: Order[];
  timeslot: Timeslot;
  driverInfo: ReservationDriverInfoInDTO;
  carPlate: string;
  carrierNote?: string;
  carrierId: string;
  reservationName: string;
  reservationNote: string;
}

interface Timeslot {
  rampId: string;
  durationInMin: string;
  arrivalHourString: string;
  localDate: string;
}

export const useCreateTimeslotSidebar = () => {
  const [createTimeslotRequest, setCreateTimeslotRequest] = useState<CreateTimeslotRequest>(undefined);

  const CreateTimeslotSidebar: React.FC<ComponentProps> = ({ onContinue, initialData, headerDisabled = true }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { checkReservation, createReservation, getFreeSlotsForLocationReservation, computeReservationDurationWithManipulationUnits, getExpeditionV3Detail } =
      useContext(ApiContext);
    const [slots, setSlots] = useState<FreeSlotsWrapperOutDTO[]>();
    const [canEditDuration, setCanEditDuration] = useState(false);

    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const schema = yup.object().shape({
      orders: orderSchema,
      companyLocationId: yup.string().required(),
      timeslot: yup.object().when('customerCompanyCode', {
        is: 'MAGNA-CARTECH',
        then: yup.object().shape({
          rampId: yup.string().required(),
          durationInMin: yup.number().required(),
          arrivalHourString: yup.string().required(),
          localDate: yup.string().required(),
        }),
        otherwise: yup.object().nullable(),
      }),
    });

    const createInitialData = (companyLocationId: string, companyLocationCustomerId: string, preferredDate?: Dayjs): CreateTimeslotForm => {
      return {
        actionTypes: [],
        reservationName: null,
        reservationNote: null,
        companyLocationId,
        customerCompanyCode: null,
        companyLocationCustomerId,
        orders: [emptyOrder(false)],
        timeslot: {
          rampId: null,
          durationInMin: '30',
          arrivalHourString: null,
          localDate: isNotNullOrUndefined(preferredDate) ? preferredDate.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
        },
        driverInfo: {
          driverName: null,
          driverId: null,
          driverPhone: null,
        },
        carPlate: null,
        carrierId: null,
      };
    };

    const form = useForm<CreateTimeslotForm>(
      schema,
      initialData ||
        createInitialData(
          createTimeslotRequest.companyLocationId,
          createTimeslotRequest.companyLocationCustomerId,
          createTimeslotRequest.preferredDate ? dayjs(createTimeslotRequest.preferredDate) : null,
        ),
      d => save(d),
      false,
      false,
      null,
      'scroll',
    );

    const [reservationValid, setReservationValid] = useState(undefined);
    const [stateForDeffered, setStateForDeffered] = useState(undefined);
    const [debouncedValue] = useDebounce(stateForDeffered, 700);

    useEffect(() => {
      const ab = new AbortController();

      const { rampId, durationInMin, arrivalHourString, localDate } = form.values.timeslot;

      if (rampId && durationInMin && arrivalHourString && localDate) {
        setStateForDeffered({
          rampId,
          durationInMin,
          arrivalHourString,
          localDate,
        });
      }

      setReservationValid(undefined);
      return () => {
        ab.abort();
      };
    }, [form.values.timeslot?.arrivalHourString, form.values.timeslot?.durationInMin, form.values.timeslot?.rampId, form.values.timeslot?.localDate]);

    // wait 700ms after use change this object
    const [changedOrders] = useDebounce(form.values.orders, 700);

    // when orders are changed, recompute duratinInMinutes
    useEffect(() => {
      const ac = new AbortController();

      recomputeMinDuration(ac);

      return () => {
        ac.abort();
      };
    }, [changedOrders]);

    const recomputeMinDuration = (ac: AbortController) => {
      computeReservationDurationWithManipulationUnits(
        form.values.companyLocationCustomerId,
        {
          items: form.values.orders
            .flatMap(o => o.items)
            .filter(t => t.skuCode !== null)
            .map(i => ({ cargoItemType: i.skuCode, quantity: i.amount })),
        },
        d => {
          if (ac && ac.signal.aborted) return;
          form.setFieldValue('timeslot.durationInMin', d.computedDuration);
          setCanEditDuration(d.canEditDuration);
        },
      );
    };

    const loadExpeditionOrders = (ac: AbortController) => {
      getExpeditionV3Detail(createTimeslotRequest.expeditionId, d => {
        if (ac && ac.signal.aborted) return;
        form.setFieldValue(
          'orders',
          d.locations[0].loadedOrders.map(o => ({
            orderName: o.name,
            items: o.cargos.map(c => ({
              amount: c.quantity,
              skuCode: c.cargoItemTypeCode,
              stackable: c.stackable,
              itemNote: null,
            })),
          })),
        );
      });
    };

    useEffect(() => {
      const ac = new AbortController();
      if (isNullOrUndefined(initialData)) {
        recomputeMinDuration(ac);
        loadExpeditionOrders(ac);
      }
      return () => {
        ac.abort();
      };
    }, []);

    const [apiRequest, setApiRequest] = useState(false);

    useEffect(() => {
      checkReservationHandler();
    }, [debouncedValue]);

    const mapGlobalOrderWithItemsForCheckTimelosts = (orders: Order[]) => {
      return orders
        .map(order => {
          // I need to have at least one item with amount and skuCode
          const filteredItems = order.items.filter(item => item.amount !== null && item.skuCode !== null);

          if (filteredItems.length > 0) {
            return {
              orderNote: order.orderNote,
              orderName: order.orderName,
              orderValue: order.orderValue
                ? {
                    price: order.orderValue,
                    currency: order.orderValueCurrency,
                  }
                : null,
              cargoAmount: order.orderAmount,
              cargoDescription: order.orderDescription,
              items: filteredItems.map(item => ({
                amount: item.amount,
                cargoTemplateCode: item.skuCode,
                note: item.itemNote,
                stackable: item.stackable,
              })),
            };
          }

          return null;
        })
        .filter(order => order !== null);
    };

    const offerAvailableSlots = () => {
      if (form.values.timeslot.localDate && form.values.timeslot.durationInMin && form.values.companyLocationId) {
        getFreeSlotsForLocationReservation(
          form.values.timeslot.localDate,
          parseInt(form.values.timeslot.durationInMin),
          form.values.companyLocationId,
          [],
          {
            customerId: form.values.companyLocationCustomerId,
            rampId: null,
            locationId: form.values.companyLocationId,
            labelIds: [],
            orders: mapGlobalOrderWithItemsForCheckTimelosts(form.values.orders),
            linkToExpeditionLocationId: createTimeslotRequest.expeditionLocationId,
          },
          d => {
            setSlots(d);
          },
        );
      }
    };

    const checkReservationHandler = () => {
      if (isNotNullOrUndefined(debouncedValue)) {
        setApiRequest(true);
        checkReservation(
          [],
          {
            customerId: form.values.companyLocationCustomerId,
            timeslot: {
              since: mapToAPIDateTime(createZonedDateTime(debouncedValue.localDate, debouncedValue.arrivalHourString, 'Europe/Prague')),
              till: mapToAPIDateTime(
                createZonedDateTime(debouncedValue.localDate, debouncedValue.arrivalHourString, 'Europe/Prague').add(debouncedValue.durationInMin, 'minute'),
              ),
            },
            orders: mapGlobalOrderWithItemsForCheckTimelosts(form.values.orders),
            rampId: debouncedValue.rampId,
            locationId: form.values.companyLocationId,
            labelIds: [],
            linkToExpeditionLocationId: createTimeslotRequest.expeditionLocationId,
          },
          () => {
            setApiRequest(false);
            setReservationValid(true);
          },
          null,
          {
            onError: e => {
              setApiRequest(false);
              setReservationValid(false);
            },
            onValidationFailed: v => {
              setApiRequest(false);
              setReservationValid(false);
            },
          },
        );
      }
    };

    const computeTimeslot = (timeslot: Timeslot) => {
      if (timeslot?.rampId == null || timeslot?.durationInMin == null || timeslot?.arrivalHourString == null || timeslot?.localDate == null) {
        return null;
      }
      return {
        since: mapToAPIDateTime(createZonedDateTime(timeslot.localDate, timeslot.arrivalHourString, 'Europe/Prague')),
        till: mapToAPIDateTime(createZonedDateTime(timeslot.localDate, timeslot.arrivalHourString, 'Europe/Prague').add(parseInt(timeslot.durationInMin), 'minute')),
      };
    };

    const save = (data: CreateTimeslotForm) => {
      createReservation(
        {
          linkToExpeditionLocationId: createTimeslotRequest.expeditionLocationId,
          rampId: data.timeslot.rampId,
          labelIds: [],
          carPlate: data.carPlate,
          locationId: form.values.companyLocationId,
          customerId: form.values.companyLocationCustomerId,
          reservationNote: form.values.reservationNote,
          reservationName: form.values.reservationName,
          driverInfo: data.driverInfo,
          carrierNote: data.carrierNote,
          carrierId: data.carrierId,
          reservationActionTypes: data.actionTypes,
          timeslot: computeTimeslot(data.timeslot),
          orders: data.orders.map(
            t =>
              ({
                orderNote: t.orderNote,
                orderName: t.orderName,
                orderValue: t.orderValue
                  ? {
                      price: t.orderValue,
                      currency: t.orderValueCurrency,
                    }
                  : null,
                cargoAmount: t.orderAmount,
                cargoDescription: t.orderDescription,
                items: t.items.map(t => ({
                  amount: t.amount,
                  cargoTemplateCode: t.skuCode,
                  note: t.itemNote,
                  stackable: t.stackable,
                })),
              } as GlobalOrdersRequest),
          ),
          order: data.orders
            .map(
              t =>
                ({
                  orderType: null,
                  orderNote: t.orderNote,
                  reservationName: t.orderName,
                } as OrderReservationInDTO),
            )
            .find((_, index) => index == 0),
        },
        d => {
          setCreateTimeslotRequest(undefined);
          onContinue(d);
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    const applySlot = (slot: TimeslotCalendarDTO, rampId: string) => {
      form.setFieldValue('timeslot.arrivalHourString', toTime(mapFromAPIDateTime(slot.since)));
      form.setFieldValue('timeslot.rampId', rampId);
      setSlots(undefined);
    };

    return (
      <>
        <RingilSidebar
          visible={isNotNullOrUndefined(createTimeslotRequest)}
          onBack={() => setCreateTimeslotRequest(undefined)}
          onContinue={() => form.validateAndSend()}
          heading={
            <RowWithSpaceBetween>
              <div>{tr(`CreateTimeslotSidebar.heading`, `Create timeslot`)}</div>
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map(t => t.path)).size} />
            </RowWithSpaceBetween>
          }>
          <BackendValidationComponent />
          <ColWithGap>
            {createTimeslotRequest?.applicationId && (
              <VerticalFieldAndValueWithIcon
                label={tr(`CreateTimeslotSidebar.forExpedition`, `For expedition`)}
                valueText={createTimeslotRequest?.applicationId}
                iconPath={'/images/icons/ringil3/forExpedition.svg'}
              />
            )}
            <TwoColGrid>
              <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.customer`, `Customer`)} labelMode={'normal'} readOnly={headerDisabled}>
                <InputEntityOwner
                  companyId={form.values.companyLocationCustomerId}
                  onChange={v => {
                    form.setFieldValue(form.names.companyLocationCustomerId, v);
                  }}
                  moduleType={CompanyModuleEnum.TIMESLOTS}
                  onChangeCompanyCode={v => {
                    form.setFieldValue(form.names.customerCompanyCode, v);
                  }}
                  readonly={headerDisabled}
                />
              </VerticalFieldAndValue>
              <VerticalFieldAndValue
                label={tr(`CreateTimeslotSidebar.location`, `Location`)}
                labelMode={'normal'}
                readOnly={headerDisabled}
                errorMessage={findValidationMessage(form.validationErrors, `companyLocationId`, tr)}>
                <InputCompanyLocation
                  companyId={form.values.companyLocationCustomerId}
                  companyLocationId={form.values.companyLocationId}
                  moduleName={CompanyModuleEnum.TIMESLOTS}
                  disabled={headerDisabled}
                  onChange={v => {
                    form.setFieldValue(form.names.companyLocationId, v?.id);
                  }}
                  showOnlyWithRamp={true}
                />
              </VerticalFieldAndValue>
            </TwoColGrid>
            <HorizontalLine variant={'tertiary'} />
            <VerticalFieldAndValue label={tr('CreateTimeslotSidebar.reservationName', 'Reservation name')} labelMode={'normal'} readOnly={false}>
              <InputText value={form.values.reservationName} onChange={e => form.setFieldValue(form.names.reservationName, e.target.value)} />
            </VerticalFieldAndValue>
            <InputNoteWithLink
              note={form.values.reservationNote}
              onChanges={v => {
                form.setFieldValue(form.names.reservationNote, v);
              }}
              noteLabel={tr(`CreateTimeslotSidebar.reservationNote`, `Reservation note`)}
            />
            <COHeadline variant={'h3'} title={tr(`CreateTimeslotSidebar.cargo`, `Cargo`)}></COHeadline>
            <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.manipulationType`, `Manipulation type`)} labelMode={'normal'}>
              <InputManipulationType
                loadingTypes={form.values.actionTypes}
                onChange={v => {
                  form.setFieldValue(form.names.actionTypes, v);
                }}
              />
            </VerticalFieldAndValue>
            <OrderTabs
              tabs={form.values.orders.map(
                (_, oIndex) =>
                  ({
                    label: `${oIndex + 1}.`,
                    icon: null,
                    active: false,
                    anchorId: `order_${oIndex}`,
                    className: 'ordersTabs',
                  } as TabProps),
              )}
              contentSelectorClassName={'ordersTabsContent'}></OrderTabs>
            <ColOdd>
              {/*// @ts-ignore*/}
              <OrdersComponent orders={form.values.orders} form={form} config={reservationOrdersConfig} />
            </ColOdd>
            <HorizontalLine variant={'tertiary'} />
            <COHeadline variant={'h3'} title={tr(`CreateTimeslotSidebar.plan`, `Plan`)}></COHeadline>
            <VerticalFieldAndValue
              label={tr(`CreateTimeslotSidebar.localDate`, `Date`)}
              labelMode={'normal'}
              errorMessage={findValidationMessage(form.validationErrors, 'timeslot.localDate', tr)}>
              <input
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  color: 'var(--ringil3-gr4)',
                }}
                type="date"
                onChange={e => {
                  form.setFieldValue('timeslot.localDate', e.target.value);
                }}
                value={form.values.timeslot.localDate}
              />
            </VerticalFieldAndValue>
            <LinkWithIcon
              icon={<SvgAvailableDates />}
              label={tr(`CreateTimeslotSidebar.offerAvailableSlots`, `Offer available slots`)}
              onClick={() => {
                offerAvailableSlots();
              }}
            />
            {slots && (
              <Panel onClose={() => setSlots(undefined)} headingText={tr('CreateTimeslotSidebar.availableSlots', 'Available slots')} closeIcon={<SvgClose />}>
                <ColWithGap>
                  {slots[0].freeSlots.map((s, i) => {
                    return (
                      <VerticalFieldAndValue label={s.name} labelMode={'normal'} key={i}>
                        <SlotsGrid>
                          {s.slots.map((slot, j) => {
                            return (
                              <ClickableCursor onClick={() => applySlot(slot, s.rampId)}>
                                <COSelect text={`${mapFromAPIToTime(slot.since)} - ${mapFromAPIToTime(slot.till)}`} key={j} />
                              </ClickableCursor>
                            );
                          })}
                        </SlotsGrid>
                      </VerticalFieldAndValue>
                    );
                  })}
                </ColWithGap>
              </Panel>
            )}
            <TwoColGrid>
              <VerticalFieldAndValue
                label={tr(`CreateTimeslotSidebar.ramp`, `Ramp`)}
                labelMode={'normal'}
                errorMessage={findValidationMessage(form.validationErrors, 'timeslot.rampId', tr)}>
                <InputCompanyRamp
                  value={form.values.timeslot.rampId}
                  onChange={v => form.setFieldValue('timeslot.rampId', v)}
                  companyLocationId={form.values.companyLocationId}
                  companyId={form.values.companyLocationCustomerId}
                />
              </VerticalFieldAndValue>
              <VerticalFieldAndValue
                label={tr(`CreateTimeslotSidebar.duration`, `Duration`)}
                labelMode={'normal'}
                errorMessage={findValidationMessage(form.validationErrors, 'timeslot.durationInMin', tr)}>
                <InputTextWithSuffix
                  suffix={'min'}
                  value={form.values.timeslot.durationInMin}
                  onChange={v => form.setFieldValue('timeslot.durationInMin', parseNumberOrUndefined(v))}
                  readOnly={!canEditDuration}
                />
              </VerticalFieldAndValue>
            </TwoColGrid>
            <VerticalFieldAndValue
              label={tr(`CreateTimeslotSidebar.arrival`, `Arrival`)}
              labelMode={'normal'}
              errorMessage={findValidationMessage(form.validationErrors, 'timeslot.arrivalHourString', tr)}>
              {/*              <InputHour
                value={form.values.timeslot.arrivalHourString}
                onChange={(v) => {
                  form.setFieldValue('timeslot.arrivalHourString', v);
                }}
                deferredTimeInSec={700}
                swallowFirstDefer={false}
                onDeferred={(v) => {}}
              />*/}
              <InputHourManual value={form.values.timeslot.arrivalHourString} onChange={v => form.setFieldValue('timeslot.arrivalHourString', v)} />
            </VerticalFieldAndValue>
            <RowWithGap>
              <LinkWithIcon
                icon={<SvgRefresh />}
                label={tr(`CreateTimeslotSidebar.checkReservation`, `Check reservation`)}
                onClick={() => {
                  checkReservationHandler();
                }}
              />
              {apiRequest && (
                <>
                  <ProgressSpinner
                    style={{
                      width: '15px',
                      height: '15px',
                      marginRight: '0.5rem',
                      color: 'var(--ringil3-brand-color)',
                    }}
                    strokeWidth="3"
                    animationDuration="1s"
                  />
                </>
              )}
            </RowWithGap>
            {reservationValid && (
              <COInlineMessage variant={'success'}>{tr(`CreateTimeslotSidebar.reservationPossible`, `Reservation is possible in this time and ramp`)}</COInlineMessage>
            )}
            {reservationValid == false && (
              <COInlineMessage variant={'error'}>
                {tr(`CreateTimeslotSidebar.reservationImpossible`, `Reservation is not possible in this time and ramp`)}
              </COInlineMessage>
            )}
            <>
              <HorizontalLine variant={'tertiary'} />
              <COHeadline variant={'h3'} title={tr(`CreateTimeslotSidebar.carrier`, `Carrier`)}></COHeadline>
              <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.carrier`, `Carrier`)} labelMode={'normal'}>
                <InputInheriterCompany
                  companyId={form.values.companyLocationCustomerId}
                  inheriterId={form.values.carrierId}
                  onChange={v => form.setFieldValue(form.names.carrierId, v)}
                  module={CompanyModuleEnum.TIMESLOTS}
                />
              </VerticalFieldAndValue>

              <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.driver`, `Driver`)} labelMode={'normal'}>
                <InputText value={form.values.driverInfo.driverName} onChange={e => form.setFieldValue('driverInfo.driverName', e.target.value)} />
              </VerticalFieldAndValue>

              <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.driverPhone`, `Driver phone`)} labelMode={'normal'}>
                <InputText value={form.values.driverInfo.driverPhone} onChange={e => form.setFieldValue('driverInfo.driverPhone', e.target.value)} />
              </VerticalFieldAndValue>

              <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.driverId`, `Driver ID`)} labelMode={'normal'}>
                <InputText value={form.values.driverInfo.driverId} onChange={e => form.setFieldValue('driverInfo.driverId', e.target.value)} />
              </VerticalFieldAndValue>

              <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.carPlate`, `Car plate`)} labelMode={'normal'}>
                <InputText value={form.values.carPlate} onChange={e => form.setFieldValue(form.names.carPlate, e.target.value)} />
              </VerticalFieldAndValue>

              <InputNoteWithLink
                note={form.values.carrierNote}
                onChanges={v => {
                  form.setFieldValue(form.names.carrierNote, v);
                }}
                noteLabel={tr(`CreateTimeslotSidebar.carrierNote`, `Carrier note`)}
              />
            </>
          </ColWithGap>
        </RingilSidebar>
      </>
    );
  };

  return {
    CreateTimeslotSidebar,
    setCreateTimeslotRequest,
    createTimeslotRequest,
  };
};

const ButtonDownWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
`;

const SlotsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
