import { useContext, useEffect, useState } from 'react';
import { LovDTO, LovWithoutIdDTO, TruckTypeWithoutIdDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '../api/api';

export const useCountryRegion = () => {
  const [data, setData] = useState<LovDTO[]>([]);
  const { cuLoVCatalogRegions } = useContext(ApiContext);

  useEffect(() => {
    cuLoVCatalogRegions(d => setData(d));
  }, []);

  return data;
};

export const useImportExportCountries = () => {
  const [data, setData] = useState<LovWithoutIdDTO[]>([]);
  const { cuLoVCatalogCountries } = useContext(ApiContext);

  useEffect(() => {
    cuLoVCatalogCountries(d => setData(d));
  }, []);

  return data;
};

export const useLovTruckTypes = () => {
  const [data, setData] = useState<TruckTypeWithoutIdDTO[]>([]);
  const { lovTruckTypes } = useContext(ApiContext);

  useEffect(() => {
    lovTruckTypes(d => setData(d));
  }, []);

  return data;
};
