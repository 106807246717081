import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  errorCount: number;
}

const ErrorCountBadge: React.FC<ComponentProps> = ({ errorCount }) => {
  const { tr } = useTranslationLgs();
  const scrollToError = () => {
    const errorElement = document.querySelector('.hasError');
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <>{errorCount > 0 && <BadgeInner onClick={e => scrollToError()}>{tr(`ErrorBadge.errorCount`, `{{count}} errors`, { count: errorCount }, errorCount)}</BadgeInner>}</>
  );
};

const BadgeInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 4px;
  gap: 0.25rem;
  border: 1px solid var(--ringil3-brand-light-color);
  background-color: var(--ringil3-complementary-error-color);
  align-self: stretch; /* Tento potomek bude na celou výšku */
  color: white;
  cursor: pointer;
`;

export default ErrorCountBadge;
