import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { EditNoteSidebar } from '../EditNoteSidebar/EditNoteSidebar';
import useTranslationLgs from '../../../../../hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';

export interface ComponentProps {
  noteData?: string;
  companyId: string;
  applicationId: string;
  onChanged: (note: string) => void;
  disabled: boolean;
}
export const Note: React.FC<ComponentProps> = ({ noteData, companyId, applicationId, onChanged, disabled }) => {
  const { t } = useTranslation('common');
  const [visibleEditNoteSidebar, setVisibleEditNoteSidebar] = useState(false);
  const { tr } = useTranslationLgs();

  return (
    <>
      <div className="p-d-flex p-ai-center p-mt-3">
        <h3>{tr(`Note.internalNote`, `Interní poznámka k expedici`)}</h3>
        <Button
          type="button"
          label="?"
          tooltip={tr(`Note.notDisplayedToCarrier`, `Poznámka nebude zobrazována dopravci`)}
          className="p-button-text p-ml-2 p-mb-2"
          tooltipOptions={{ position: 'top' }}
        />
        <Button label={t('labelChange')} className="p-button-text p-p-0" onClick={() => setVisibleEditNoteSidebar(true)} disabled={disabled} />
      </div>
      {noteData ? (
        <div className="note-detail">{noteData}</div>
      ) : (
        <div className="text-gray5">
          <i>{tr(`Note.noteNotFilled`, `Poznámka nebyla vyplněna`)}</i>
        </div>
      )}

      <EditNoteSidebar
        visible={visibleEditNoteSidebar}
        onHide={() => setVisibleEditNoteSidebar(false)}
        companyId={companyId}
        applicationId={applicationId}
        onChange={note => {
          onChanged(note);
          setVisibleEditNoteSidebar(false);
        }}
      />
    </>
  );
};
