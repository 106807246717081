import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { isNotNullOrUndefined } from '@utils/utils';
import { CompanyLocationRampWithOpeningHoursOutDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  companyLocationId: string;
  companyId: string;
}

const InputCompanyRamp: React.FC<ComponentProps> = ({ value, onChange, companyLocationId, companyId }) => {
  const { getSharedRampsForCompanyLocation } = useContext(ApiContext);
  const [data, setData] = useState<CompanyLocationRampWithOpeningHoursOutDTO[]>([]);

  useEffect(() => {
    const ab = new AbortController();
    if (isNotNullOrUndefined(companyLocationId)) {
      getSharedRampsForCompanyLocation(companyLocationId, companyId, d => {
        if (!ab.signal.aborted) {
          setData(d);
        }
      });
    }
    return () => {
      ab.abort();
    };
  }, [companyLocationId]);

  return (
    <>
      <Dropdown options={data} optionLabel={'name'} optionValue={'rampId'} onChange={e => onChange(e.value)} value={value} />
    </>
  );
};

export default InputCompanyRamp;
