import React from 'react';
import styled from 'styled-components';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  reservation?: ReservationsDataOutDTO;
}

const CFRLabels: React.FC<ComponentProps> = ({ reservation }) => {
  return (
    <>
      {reservation.labels?.length > 1 && (
        <Labels>
          {reservation.labels.map((label, labelKey) => {
            return <Label color={label.labelColor} key={labelKey}></Label>;
          })}
        </Labels>
      )}
    </>
  );
};

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
`;

const Label = styled.div`
  width: 30px;
  height: 10px;
  background-color: ${props => props.color};
`;

export default CFRLabels;
