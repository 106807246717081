import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
  onClick: () => void;
  label: string;
}

const RectangleButton: React.FC<ComponentProps> = ({ onClick, label }) => {
  return <ButtonInner onClick={e => onClick()}>{label}</ButtonInner>;
};

const ButtonInner = styled.button`
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--ringil3-base-secondary-color);
  border: 1px solid var(--ringil3-gr2);
  box-shadow: 0px 4px 4px rgba(254, 80, 0, 0.15);
  flex-shrink: 1;
`;

export default RectangleButton;
