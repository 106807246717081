import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import AdminLayout from '@app/layout/Layout.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';
import { ListReservations } from '@app/pages/reservations/ListReservations.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Intro from '@app/layout/Intro.tsx';

interface ComponentProps {}

const ReservationListPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <AdminLayout
        title={tr(`reservations.orderList`, `Order list`)}
        oneOfPermission={[PermissionTypesEnum.PERMISSION_TIMESLOTS, PermissionTypesEnum.PERMISSION_TIMESLOT_READ, PermissionTypesEnum.PERMISSION_TIMESLOT_FULLREAD]}>
        <ListReservations />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(ReservationListPage, {
  onRedirecting: () => <Intro />,
});
