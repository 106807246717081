import React, { useContext, useEffect } from 'react';
import { TransportationTypeSelector } from '../common/TransportationTypeSelector';
import { TruckTypeSelector } from '../common/TruckTypeSelector';
import { LoadingTypeSelector } from '../common/LoadingTypeSelector';
import { SpecialRequirementsSelector } from '../common/SpecialRequirementsSelector';
import { CreateTemplateHandlerContext } from './CreateTemplateHandler';
import { ExpeditionTypeEnum, LoadingTypesEnum } from '@api/logsteo-api.v2';
import { findValidationMessage } from '@utils/validation';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import HeaderPanel from '@components/obsolete/common/HeaderPanel.tsx';
import ContainerWrapper from '@components/obsolete/ContainerWrapper/ ContainerWrapper.tsx';
import { CargoTypeSelector } from '@components/obsolete/common/CargoTypeSelector.tsx';
import { TextArea } from '@components/obsolete/common/TextArea.tsx';

interface ComponentProps {
  expeditionType: ExpeditionTypeEnum;
  truckType: string;
  manipulationTypes: LoadingTypesEnum[];
  specialRequirements: string[];
  cargoType: string;
  carrierNote?: string;
}

const CreateTemplateStep3Header: React.FC<ComponentProps> = ({ expeditionType, truckType, manipulationTypes, specialRequirements, cargoType, carrierNote }) => {
  const { t }: { t: any } = useTranslation('common');
  const { changeExpeditionType, selectTruck, selectManipulationTypes, selectSpecialRequirement, selectCargoType, changeCarrierNote, state } =
    useContext(CreateTemplateHandlerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TransportationTypeSelector value={expeditionType} onChange={e => changeExpeditionType(e)} />
      {expeditionType === ExpeditionTypeEnum.FULL_TRUCK_LOAD && (
        <div className="p-mb-6">
          <Label title={t(`CreateTemplateStep3Header.truckType`, `Truck type`)} />
          <TruckTypeSelector onChange={e => selectTruck(e)} value={truckType} required={true} />
        </div>
      )}
      <LoadingTypeSelector
        values={manipulationTypes}
        onChange={v => selectManipulationTypes(v)}
        errorMessages={findValidationMessage(state.validationErrors ? state.validationErrors : [], 'manipulationTypes', t)}
      />
      <SpecialRequirementsSelector selectedIds={specialRequirements} onChange={v => selectSpecialRequirement(v)} />
      <HeaderPanel title={t('loadDetailsPage.panelAllAvailableEquipment.title')} />
      <ContainerWrapper wrapper="SM">
        <CargoTypeSelector value={cargoType} onChange={v => selectCargoType(v)} />
      </ContainerWrapper>
      <TextArea value={carrierNote} onChange={v => changeCarrierNote(v)} />
    </>
  );
};

export default CreateTemplateStep3Header;
