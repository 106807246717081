import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import ExceptionList from '@components/obsolete/ExceptionList/ExceptionList.tsx';

interface ComponentProps {}

const ExceptionPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <Layout title={tr('exceptions.exceptions', 'Exceptions')}>
        <ExceptionList />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(ExceptionPage);
