import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import { CustomerExpeditionViewContext } from '@app/pages/customer/expedition-detail/CustomerExpeditionView.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import AddNewCarrier from '@app/pages/customer/carriers/sidebars/AddNewCarrier.tsx';
import HereMaps from '@components/features/HereMap/HereMaps.tsx';

const TabCarrierManualAssignedPlus: React.FC = () => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { state, getDetailExpeditionCarrierTabAssigned } = useContext(CustomerExpeditionViewContext);

  useEffect(() => {
    if (state.header.isDistributionCreated) load();
  }, []);

  const load = () => getDetailExpeditionCarrierTabAssigned(state.header.companyId, state.header.applicationId);

  const [visibleAddNewCarrier, setVisibleAddNewCarrier] = useState(false);
  const { getMapDataForExpedition } = useContext(ApiContext);

  return (
    <>
      <div className="p-grid tab-carrier-manual-assigned-plus">
        <div className="p-col-12 p-md-6 p-lg-6">
          {state.header.isDistributionCreated && state.readyToShipment && (
            <>
              <h3>{t(`TabCarrierActive.yourExpedition`, `Your expedition`)}</h3>
              UpdateCarrierSlotManual
              {/*<UpdateCarrierSlotManual
                locations={state.readyToShipment.assigned.locations}
                applicationId={state.header.applicationId}
                customerId={state.header.companyId}
                disabled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
              />*/}
            </>
          )}
          {/* <CreateOrEditManualAssignment
            companyId={state.header.companyId}
            applicationId={state.header.applicationId}
            create={!state.header.isDistributionCreated}
            onComplete={() => {
              load();
            }}
            disabled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
          />*/}
          CreateOrEditManualAssignment
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-4">
          <div>
            <Label title={tr(`TabCarrierManualAssignedPlus.map`, `Mapa`)}></Label>
            <HereMaps customerId={state.header.companyId} applicationId={state.header.applicationId} />
          </div>
        </div>
      </div>
      <AddNewCarrier
        visible={visibleAddNewCarrier}
        onHide={() => setVisibleAddNewCarrier(false)}
        onComplete={() => {
          setVisibleAddNewCarrier(false);
          // load(pageSize, pageNumber);
        }}
      />
    </>
  );
};

export default TabCarrierManualAssignedPlus;
