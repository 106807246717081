import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import Label from '@components/obsolete/Form/Label/Label';
import { InputText } from 'primereact/inputtext';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  templateId: string;
  onComplete: (templateName: string) => void;
}

interface InternalInformationForm {
  responsiblePerson: any;
}

const EditName: React.FC<ComponentProps> = ({ visible, onHide, templateId, onComplete }) => {
  const { t } = useTranslation('common');
  const { cuGetEditTemplateName } = useContext(ApiContext);
  const [templateName, setTemplateName] = useState<string>(undefined);

  useEffect(() => {
    if (visible)
      cuGetEditTemplateName(templateId, data => {
        setTemplateName(data.templateName);
      });
    clear();
  }, [visible]);

  const schema = yup.string().required();
  const { clear, validationErrors, validateAndSend } = useValidation(schema, () => onComplete(templateName));

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal sidebar-modal-md sidebar-modal-edit-price"
        style={{ width: '750px' }}>
        <h3>{t(`EditName.changeTemplateName`, `Změna názvu trasy`)}</h3>
        <div className="p-field">
          <Label title={`${t('EditName.name', 'Název')} *`} />
          <div className="p-inputgroup">
            <InputText value={templateName} onChange={e => setTemplateName((e.target as HTMLInputElement).value)} />
          </div>
          <ValidationDiv errors={validationErrors} path={''} />
        </div>
        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t('labelSave')} className="p-mr-3" onClick={() => validateAndSend(templateName)} />
          <Button label={t('labelCancel')} className="p-button-outlined p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default EditName;
