import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Dropdown } from 'primereact/dropdown';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  code: string;
  onChange: (code: string) => void;
  style?: any;
  disabled?: boolean;
}

const InputUnitPlain: React.FC<ComponentProps> = ({ code, onChange, style, disabled = false }) => {
  const { lovQuantityUnit } = useContext(ApiContext);
  const [units, setUnits] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovQuantityUnit(d => {
      setUnits(d);
    });
  }, []);

  return (
    <>
      <Dropdown value={code} options={units} optionValue={'code'} optionLabel={'name'} onChange={e => onChange(e.value)} style={style} disabled={disabled} />
    </>
  );
};

export default InputUnitPlain;
