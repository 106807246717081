import React from 'react';
import styled, { css } from 'styled-components';
import { GrayText } from '@components/ringil3/styles';

interface ComponentProps {
  filled?: boolean;
  title?: string;
  value: string | number;
  highlightedValue?: boolean;
}

const Badge: React.FC<ComponentProps> = ({ filled = false, title, value, highlightedValue }) => {
  return (
    <BadgeInner filled={filled}>
      {title && <GrayText>{title}</GrayText>}
      <Value highlightedValue={highlightedValue}>{value}</Value>
    </BadgeInner>
  );
};

const Value = styled.div<Pick<ComponentProps, 'highlightedValue'>>`
  ${props => props.highlightedValue && `color: var(--ringil3-brand-color);`}
  font-weight: 700;
`;

const BadgeInner = styled.div<{ filled: boolean }>`
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  gap: 0.25rem;
  border: 1px solid var(--ringil3-brand-light-color);
  align-self: stretch; /* Tento potomek bude na celou výšku */

  ${props =>
    props.filled
      ? css`
          background-color: var(--ringil3-brand-light-color);
        `
      : css`
          background-color: var(--ringil3-base-secondary-color);
        `}
`;
export default Badge;
