import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { formatHandlingUnits, isNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { RingilH2, RowWithSpaceBetween } from '@components/styles.tsx';
import ExpeditionDetailOptions from '@components/obsolete/ExpeditionDetailOptions/ExpeditionDetailOptions.tsx';

interface ComponentProps {
  applicationId?: string;
  distance?: number;
  expeditionType: string;
  expeditionState: string;
  isExpeditionCanceled: boolean;
  expeditionToId: string;
  expeditionFromId: string;
  isManualExpedition: boolean;
  handlingUnitCount: number;
}

const ExpeditionDetailHeader: React.FC<ComponentProps> = ({
  applicationId,
  distance,
  expeditionType,
  expeditionState,
  isExpeditionCanceled,
  expeditionToId,
  expeditionFromId,
  isManualExpedition,
  handlingUnitCount,
}) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const className = '';

  const isCanceled = isExpeditionCanceled;
  const isSwitchedToManual = isManualExpedition && !isNullOrUndefined(expeditionToId);
  const isManual = isManualExpedition && isNullOrUndefined(expeditionToId);
  const isAutomatic = !isManualExpedition && !isExpeditionCanceled;

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-6">
        <RowWithSpaceBetween>
          <RingilH2>
            {t('labelExpedition')} <span>{applicationId}</span>
          </RingilH2>
        </RowWithSpaceBetween>

        <div className="p-d-flex p-ai-center">
          <p className="p-mb-0 p-mt-0 p-mr-5">
            <>
              {isManual || (isSwitchedToManual && <span className={'text-red'}>{tr(`ExpeditionDetailHeader.switchedToManualMode`, 'Přepnuto na manuální režim ')}</span>)}

              {isCanceled && <div className={className}>{t('CANCELED', 'CANCELED')}</div>}
              {isAutomatic && <span className={className}>{t(expeditionState, expeditionState)}</span>}
            </>
          </p>
        </div>
      </div>

      <div className="p-col-12 p-md-6 p-d-flex p-jc-between p-jc-md-end">
        <ExpeditionDetailOptions value={`${distance || ' - '} km`} description={t('labelTotalDistance')} />
        <ExpeditionDetailOptions
          value={t(`expeditionTypeShort${expeditionType}`)}
          description={t(`expeditionTypeLong${expeditionType}`)}
          img="/images/local_shipping.png"
        />
        <ExpeditionDetailOptions
          value={formatHandlingUnits(handlingUnitCount, tr)}
          description={tr(`CarrierExpeditionDetailHeader.totalHandlingUnits`, `Total handling units`)}
        />
      </div>
    </div>
  );
};

export default ExpeditionDetailHeader;
