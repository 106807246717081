import React from 'react';
import styled from 'styled-components';
import { SelectableButton } from '@components/ringil3/Buttons/SelectableButton';

interface ComponentProps {
  model: ClickableItem[];
  selectedItemCodes: string[];
  onSelect: (selectedItemCodes: string[]) => void;
}

interface ClickableItem {
  code: string;
  label: string;
}

const InputSelect: React.FC<ComponentProps> = ({ model, selectedItemCodes = [], onSelect }) => {
  const isSelected = (code: string): boolean => {
    return selectedItemCodes?.find(item => item === code) !== undefined;
  };

  const toggleValue = (code: string): void => {
    if (isSelected(code)) {
      onSelect(selectedItemCodes.filter(item => item !== code));
    } else {
      onSelect([...selectedItemCodes, code]);
    }
  };

  return (
    <>
      <InputSelectInner>
        {model.map((item, itemPos) => {
          return <SelectableButton key={itemPos} label={item.label} onClick={() => toggleValue(item.code)} checked={isSelected(item.code)} />;
        })}
      </InputSelectInner>
    </>
  );
};

const InputSelectInner = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export default InputSelect;
