import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import AdminImportCompanies from '@components/pages/admin/AdminImportCompanies/AdminImportCompanies';

interface ComponentProps {}

const CompaniesPage: React.FC<ComponentProps> = () => {
  return (
    <>
      <AdminLayout title="Import companies">
        <AdminImportCompanies />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(CompaniesPage);
