import classNames from 'clsx';

interface ComponentProps {
  children?: any;
  wrapper?: any;
}

const ContainerWrapper: React.FC<ComponentProps> = ({ children, wrapper }) => {
  const className = classNames('container-wrapper', {
    'container-wrapper-md': wrapper === 'MD',
    'container-wrapper-sm': wrapper === 'SM',
  });
  return <div className={className}>{children}</div>;
};
export default ContainerWrapper;
