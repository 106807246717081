import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useImmer } from 'use-immer';
import { ExpeditionDetailHandlerView, LocationTimeslots } from './types';
import { EditCarInfoInDTO, EditTimeslotCarrierInDTO, ShipmentProgressFormDataInDTO } from '@api/logsteo-api.v2';
import dayjs, { Dayjs } from 'dayjs';
import { mapFromAPIDateTime, mapToAPIDateTime, setTime } from '@utils/date';
import { ApiContext } from '@api/api';

export interface ExpeditionDetailHandlerContextType {
  state: ExpeditionDetailHandlerView;
  loadShipmentProgress: (companyId: string, applicationId: string) => void;
  selectSlot: (locationId: string, slotId: string) => void;
  changePlate: (plate: string) => void;
  changeDriver: (driverId: string) => void;
  changeDispatcher: (dispatcherId: string) => void;
  createJobProgressTabForReadyToShipment: (companyId: string, applicationId: string, body: ShipmentProgressFormDataInDTO) => void;
  confirmAction: (locationId: string, when: Dayjs, propertyName: string) => void;
  loadTabActivePlus: () => void;
  finishShipment: () => void;
  loadShipmentPlan: () => void;
  changeCarrierSlots: (slots: EditTimeslotCarrierInDTO) => void;
  changeCarInfo: (data: EditCarInfoInDTO) => void;
  addCarrierSlot: (locationId: string) => void;
  changeSince: (locationId: string, since: string) => void;
  changeTill: (locationId: string, since: string) => void;
  changeCustomSlotDate: (locationId: string, date: Dayjs) => void;
  reconfirm: (companyId: string, applicationId: string, onSuccess: () => void) => void;
}

export const ExpeditionDetailHandlerContext = createContext<ExpeditionDetailHandlerContextType>(undefined);

interface ComponentProps {
  initialData: ExpeditionDetailHandlerView;
}

const ExpeditionDetailHandlerProvider: React.FC<PropsWithChildren<ComponentProps>> = ({ initialData, children }) => {
  const [state, setState] = useImmer<ExpeditionDetailHandlerView>(initialData);
  const {
    caGetShipmentDetailHeader,
    caGetShipmentDetailProgressTabForReadyToShipment,
    caGetShipmentDetailProgressTabAssigned,
    caSendAssignedShipmentForm,
    caFinishLocationWithData,
    caFinishShipment,
    caGetDetailShipmentPlan,
    listDocumentsForEntity,
    deleteDocumentForExpedition,
    uploadAttachment,
    caUpdateEditTimeslots,
    caEditCarInfo,
    caReconfirmedExpedition,
  } = useContext(ApiContext);

  const methods: ExpeditionDetailHandlerContextType = {
    state,
    loadShipmentProgress: (companyId: string, applicationId: string) => {
      caGetShipmentDetailProgressTabAssigned(companyId, applicationId, data => {
        setState(draft => {
          draft.assigningStateData = data;
          draft.createReadyToShipment = {
            carPlate: data.carPlate,
            dispatcherId: data.dispatcherId,
            driverId: data.driverId,
            locationTimeslotIds: data.locations.reduce((array, value) => {
              return [
                ...array,
                {
                  locationId: value.id,
                  newSlot: null,
                  selectedSlotId: null,
                  customerSlots: value.timeslots,
                },
              ];
            }, [] as LocationTimeslots[]),
          };
        });
      });
    },
    selectSlot: (locationId: string, timeslotId: string) => {
      setState(draft => {
        const location = draft.createReadyToShipment.locationTimeslotIds.find(l => l.locationId === locationId);
        /*        if (isNullOrUndefined(location)) {
                  draft.createReadyToShipment.locationTimeslotIds.push({ locationId, timeslotId });
                } else {
                  location.timeslotId = timeslotId;
                  location.newTimeslot = null;
                }*/
        location.timeslotId = timeslotId;
        location.newTimeslot = null;
      });
    },
    changePlate: (plate: string) => {
      setState(draft => {
        draft.createReadyToShipment.carPlate = plate;
      });
    },
    changeDriver: (driverId: string) => {
      setState(draft => {
        draft.createReadyToShipment.driverId = driverId;
      });
    },
    changeDispatcher: (dispatcherId: string) => {
      setState(draft => {
        draft.createReadyToShipment.dispatcherId = dispatcherId;
      });
    },
    createJobProgressTabForReadyToShipment: (companyId: string, applicationId: string, body: ShipmentProgressFormDataInDTO) => {
      caSendAssignedShipmentForm(companyId, applicationId, body, () => {
        caGetShipmentDetailHeader(companyId, applicationId, data => {
          setState(draft => {
            draft.header = data;
          });
        });
      });
    },
    confirmAction: (locationId: string, when: Dayjs, propertyName: string) => {
      caFinishLocationWithData(
        state.header.companyId,
        state.header.applicationId,
        locationId,
        {
          [propertyName]: {
            finishedAt: mapToAPIDateTime(when),
            isFinished: true,
          },
        },
        () => {
          caGetShipmentDetailProgressTabForReadyToShipment(state.header.companyId, state.header.applicationId, data => {
            setState(draft => {
              draft.header = data.expeditionHeaderPreview;
              draft.activeStateData = data;
            });
          });
        },
      );
    },
    loadTabActivePlus: () => {
      caGetShipmentDetailProgressTabForReadyToShipment(state.header.companyId, state.header.applicationId, data => {
        setState(draft => {
          draft.header = data.expeditionHeaderPreview;
          draft.activeStateData = data;
        });
      });
    },
    finishShipment: () => {
      caFinishShipment(state.header.companyId, state.header.applicationId, () => {
        caGetShipmentDetailProgressTabForReadyToShipment(state.header.companyId, state.header.applicationId, data => {
          setState(draft => {
            draft.header = data.expeditionHeaderPreview;
            draft.activeStateData = data;
          });
        });
      });
    },
    loadShipmentPlan: () => {
      caGetDetailShipmentPlan(state.header.companyId, state.header.applicationId, data => {
        setState(draft => {
          draft.header = data.expeditionHeaderPreview;
          draft.tabExpeditionPlan = data;
        });
      });
    },
    changeCarrierSlots: (slots: EditTimeslotCarrierInDTO) => {
      caUpdateEditTimeslots(state.header.companyId, state.header.applicationId, slots, () => {
        caGetShipmentDetailProgressTabForReadyToShipment(state.header.companyId, state.header.applicationId, data => {
          setState(draft => {
            draft.header = data.expeditionHeaderPreview;
            draft.activeStateData = data;
          });
        });
      });
    },
    changeCarInfo: (data: EditCarInfoInDTO) => {
      caEditCarInfo(state.header.companyId, state.header.applicationId, data, () => {
        caGetShipmentDetailProgressTabForReadyToShipment(state.header.companyId, state.header.applicationId, data => {
          setState(draft => {
            draft.header = data.expeditionHeaderPreview;
            draft.activeStateData = data;
          });
        });
      });
    },
    addCarrierSlot: (locationId: string) => {
      setState(draft => {
        const location = draft.createReadyToShipment.locationTimeslotIds.find(t => t.locationId === locationId);
        location.newTimeslot = {
          timeslotSince: mapToAPIDateTime(dayjs()),
          timeslotTill: mapToAPIDateTime(dayjs().add(30, 'minutes')),
        };
        location.timeslotId = null;
        /*        if (isNullOrUndefined(location)) {
                  location = { locationId: locationId } as LocationTimeslotFormInDTO;
                  draft.createReadyToShipment.locationTimeslotIds.push(location);
                }
                location.newTimeslot = {
                  timeslotSince: mapToAPIDateTime(dayjs()),
                  timeslotTill: mapToAPIDateTime(dayjs().add(30, 'minutes')),
                };
                location.timeslotId = null;*/
      });
    },
    changeSince: (locationId: string, since: string) => {
      setState(draft => {
        const location = draft.createReadyToShipment.locationTimeslotIds.find(t => t.locationId === locationId);
        location.newTimeslot.timeslotSince = mapToAPIDateTime(setTime(mapFromAPIDateTime(location.newTimeslot.timeslotSince), since));
      });
    },
    changeTill: (locationId: string, till: string) => {
      setState(draft => {
        const location = draft.createReadyToShipment.locationTimeslotIds.find(t => t.locationId === locationId);
        location.newTimeslot.timeslotTill = mapToAPIDateTime(setTime(mapFromAPIDateTime(location.newTimeslot.timeslotTill), till));
      });
    },
    changeCustomSlotDate: (locationId: string, date: Dayjs) => {
      setState(draft => {
        const location = draft.createReadyToShipment.locationTimeslotIds.find(t => t.locationId === locationId);
        location.newTimeslot.timeslotSince = mapToAPIDateTime(date);
        location.newTimeslot.timeslotTill = mapToAPIDateTime(date.add(30, 'minutes'));
      });
    },
    reconfirm: (companyId: string, applicationId: string, onSuccess: () => void = () => {}) => {
      caReconfirmedExpedition(companyId, applicationId, () => {
        onSuccess();
      });
    },
  };

  return <ExpeditionDetailHandlerContext.Provider value={methods}>{children}</ExpeditionDetailHandlerContext.Provider>;
};

export default ExpeditionDetailHandlerProvider;
