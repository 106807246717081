import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { CargoTypeCodeEnum, ShipmentStateCodeEnum, TransportationTypeEnum } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import Layout from '@app/layout/Layout.tsx';
import CreateSupplierNotification, { CreateSupplierNotificationFormData } from '@app/pages/customer/supplier-notification/CreateSupplierNotification.tsx';
import { emptyOrder } from '@app/pages/customer/supplier-notification/forms/SupplierNotificationOrdersForm.tsx';

interface ComponentProps {}

const CreateSupplierNotificationPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const initialData: CreateSupplierNotificationFormData = {
    transportationType: TransportationTypeEnum.GROUND,
    attachments: [],
    cargoType: { cargoTypeCode: CargoTypeCodeEnum.COMMON_GOODS },
    containerInfo: null,
    customsTypeCode: null,
    referralCode: '',
    shipmentId: null,
    orders: [emptyOrder()],
    companyId: null,
    supplierId: null,
    specialRequirementIds: [],
    supplierLocationId: null,
    customerLocationId: null,
    shipmentStateCode: ShipmentStateCodeEnum.WAITING,
    notifyData: {
      notifyUsersDependsOnRole: true,
      responsiblePersonId: null,
      userIdsToNotify: [],
    },
    supplierDeliverySlots: { dayWithInterval: [] },
    customerDeliverySlots: { dayWithInterval: [] },
  };

  return (
    <>
      <Layout title={tr(`create-shipment.createSupplierNotification`, `Create aviso`)}>
        <CreateSupplierNotification initialData={initialData} />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(CreateSupplierNotificationPage);
