import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTabularShowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path
      fill="#616061"
      fillRule="evenodd"
      d="m.5 8 3.08-3.695a6.405 6.405 0 0 1 9.84 0L16.5 8l-3.08 3.695a6.405 6.405 0 0 1-9.84 0zm8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTabularShowIcon;
