export interface ComponentProps {
  title: string;
}
export const ColumnTitle: React.FC<ComponentProps> = ({ title }) => {
  return (
    <div className="p-column-title">
      <strong>{title}</strong>
    </div>
  );
};
