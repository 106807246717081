import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { InputTextarea } from 'primereact/inputtextarea';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  companyId: string;
  applicationId: string;
  onChange: (note: string) => void;
}

export const EditNoteSidebar: React.FC<ComponentProps> = ({ visible, onHide, applicationId, companyId, onChange }) => {
  const { tr } = useTranslationLgs();
  const { cuGetInternalNote, cuUpdateInternalNote } = useContext(ApiContext);

  const [noteValue, setValueNote] = useState<string>();

  useEffect(() => {
    if (visible) {
      clear();
      cuGetInternalNote(companyId, applicationId, noteData => {
        setValueNote(noteData.internalNote);
      });
    }
  }, [visible]);

  const save = () => {
    cuUpdateInternalNote(companyId, applicationId, { internalNote: noteValue }, data => {
      onChange(data.internalNote);
    });
  };

  const validationSchema = yup.string().min(1).required();
  const { clear, validationErrors, validateAndSend } = useValidation(validationSchema, save);

  return (
    <>
      {/*//
      @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal" blockScroll={true} style={{ width: '750px' }}>
        <h3 className="p-mb-4">{tr(`EditNoteSidebar.editInternalNoteTitle`, `Upravit interní poznámku`)}</h3>
        <div className="p-grid">
          <div className="p-col-12 p-lg-12">
            <InputTextarea value={noteValue} onChange={e => setValueNote(e.target.value)} className="p-lg-12" rows={6} />
            <ValidationDiv errors={validationErrors} path={''} />
          </div>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM">
          <Button label={tr(`EditNoteSidebar.save`, `Uložit`)} className="p-mr-2" onClick={() => validateAndSend(noteValue)} />
          <Button label={tr(`EditNoteSidebar.back`, `Zpět`)} className="p-button-text" onClick={onHide} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};
