import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { useParams } from 'react-router-dom';
import HTTPDetail from '@components/obsolete/admin/httprequests/HTTPDetail.tsx';

interface ComponentProps {}

const HTTPRequestDetailPage: React.FC<ComponentProps> = () => {
  const { requestId } = useParams();

  if (!requestId) {
    return <></>;
  }

  return (
    <>
      <AdminLayout title={'HTTP Request detail'}>
        <HTTPDetail requestId={requestId} />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(HTTPRequestDetailPage);
