import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgDriverAndId from '@components/ringil3/icons/DriverAndId';
import { InputText } from 'primereact/inputtext';
import useForm from '@hooks/useForm/useForm';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';

interface ComponentProps {
  driverName: string;
  driverId: string;
  onChange: (driverId: string, driverName: string) => void;
}

interface Form {
  driverName: string;
  driverId: string;
}

const DriverAndIdTag: React.FC<ComponentProps> = ({ driverName, driverId, onChange }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  const content = [driverName, driverId].filter(t => t != null).join(', ');

  const form = useForm<Form>(
    {},
    {
      driverName,
      driverId,
    },
    () => save(),
    false,
    false,
    null,
    'none',
  );

  const pane = () => {
    return (
      <>
        <VerticalFieldAndValue label={'Driver name'} labelMode={'normal'}>
          <InputText value={form.values.driverName} onChange={e => form.setFieldValue(form.names.driverName, e.target.value)} />
        </VerticalFieldAndValue>
        <VerticalFieldAndValue label={'Driver ID'} labelMode={'normal'}>
          <InputText value={form.values.driverId} onChange={e => form.setFieldValue(form.names.driverId, e.target.value)} />
        </VerticalFieldAndValue>
      </>
    );
  };

  const save = () => {
    setOpen(false);
    onChange(form.values.driverId, form.values.driverName);
  };

  return (
    <>
      <ButtonTag
        label={tr(`DriverAndIdTag.title`, `Driver and ID`)}
        labelNote={content}
        icon={<SvgDriverAndId />}
        actionPane={pane()}
        onSave={() => save()}
        actionIcon={<SvgTagEdit />}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
};

export default DriverAndIdTag;
