import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M2.907 12.04a6.47 6.47 0 0 1-1.364-3.29h12.914a6.47 6.47 0 0 1-1.364 3.29A12.95 12.95 0 0 0 8 11c-2.027 0-3.745.465-5.093 1.04m1.176 1.147A6.47 6.47 0 0 0 8 14.5a6.47 6.47 0 0 0 3.917-1.313A11.4 11.4 0 0 0 8 12.5c-1.51 0-2.827.292-3.917.687M2.907 3.961A6.47 6.47 0 0 0 1.543 7.25h12.914a6.47 6.47 0 0 0-1.364-3.289A12.95 12.95 0 0 1 8 5c-2.027 0-3.745-.465-5.093-1.039m1.176-1.148C5.173 3.208 6.489 3.5 8 3.5s2.827-.292 3.917-.687A6.47 6.47 0 0 0 8 1.5a6.47 6.47 0 0 0-3.917 1.313"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGlobe;
