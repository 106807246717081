import { useContext, useEffect, useState } from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useCustomerCarriers = () => {
  const [carriers, setCarriers] = useState<LovDTO[]>([]);
  const { cuLovCarriers } = useContext(ApiContext);

  useEffect(() => {
    const ac = new AbortController();
    load(ac);

    return () => {
      ac.abort();
    };
  }, []);

  const load = (ac: AbortController) => {
    cuLovCarriers('', data => {
      if (ac.signal.aborted) return;
      setCarriers(data);
    });
  };

  return { carriers, load };
};

export default useCustomerCarriers;
