import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  dialogTitle: string;
  dialogMessage: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onAcceptButtonText: string;
  onCancelButtonText: string;
  loading?: boolean;
}

const ConfirmDialog: React.FC<ComponentProps> = ({
  visible,
  dialogMessage,
  onConfirm = () => {},
  onCancel = () => {},
  dialogTitle,
  onAcceptButtonText,
  onCancelButtonText,
  loading = false,
}) => {
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog visible={visible} baseZIndex={1000000} onHide={() => onCancel()} header={<h4>{dialogTitle}</h4>} className="dialog-confirm dialog-md">
        <p>{dialogMessage}</p>
        <ButtonLayout wrapper="WITHOUT_PADDING_BOTTOM">
          <Button
            label={onAcceptButtonText}
            className="p-mr-2"
            onClick={() => {
              onConfirm();
            }}
          />
          <Button label={onCancelButtonText} className="p-button-text" onClick={() => onCancel()} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
