import React from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { MultiSelect } from 'primereact/multiselect';

interface ComponentProps {
  responsiblePersonIds: string[];
  onChange: (responsiblePerson: string) => void;
  style?: any;
  showClearButton?: boolean;
  placeholder?: string;
}

const InputResponsiblePersons: React.FC<ComponentProps> = ({ responsiblePersonIds, onChange, style, showClearButton = false, placeholder }) => {
  const responsiblePersons = useResponsiblePersons();

  return (
    <>
      <MultiSelect
        value={responsiblePersonIds}
        dataKey="id"
        options={responsiblePersons}
        onChange={e => onChange(e.target.value)}
        optionLabel="name"
        optionValue={'id'}
        style={style}
        placeholder={placeholder}
        showClear={showClearButton}
        filter={true}
        itemTemplate={(option: LovDTO) => (
          <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
        )}
      />
    </>
  );
};

export default InputResponsiblePersons;
