import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'primereact/checkbox';

interface ComponentProps {
  checkboxLabel: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
  labelStyle?: any;
}

const CheckboxWithLabel: React.FC<ComponentProps> = ({ labelStyle, checkboxLabel, value, onChange, disabled = false }) => {
  return (
    <>
      <CheckboxWrapper>
        <Checkbox checked={value} onChange={e => onChange(e.checked)} disabled={disabled} />
        <Label style={labelStyle}>{checkboxLabel}</Label>
      </CheckboxWrapper>
    </>
  );
};

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  display: flex;
  font-weight: bold;
  margin-left: 0.4rem;
`;

export default CheckboxWithLabel;
