import React, { DragEvent, useContext, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { AttachmentUploadPanelValues } from '@app/pages/attachment/AttachmentUploadPanel.tsx';
import UploadPanelVisual from '@components/framework/uploadpanel/UploadPanelVisual.tsx';
import { AttachmentsUploadedOutDTO } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  onSave: (data: AttachmentsUploadedOutDTO[]) => void;
}

const UploadPanel: React.FC<ComponentProps> = ({ onSave }) => {
  const { uploadAttachments } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [apiRequestCount, setApiRequestCount] = useState(0);
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const processFileList = (fileList: FileList | null) => {
    const filesArray: { name: string; dataUrl: string }[] = [];

    setApiRequestCount(c => c + 1);
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const reader = new FileReader();

      reader.onload = e => {
        const dataUrl = e.target?.result as string;
        filesArray.push({
          name: file.name,
          dataUrl,
        });

        // After processing all files, update the state
        if (filesArray.length === fileList.length) {
          uploadAttachments(
            { fileInDTOs: filesArray.map(file => ({ fileName: file.name, dataURL: file.dataUrl })) },
            d => {
              onSave(d);
              setApiRequestCount(c => c - 1);
            },
            null,
            { onError: () => setApiRequestCount(c => c - 1) },
          );
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    processFileList(droppedFiles);
  };

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    processFileList(selectedFiles);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return (
    <>
      <UploadPanelVisual
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => {
          handleClick();
        }}
        showDropZone
        loading={apiRequestCount > 0}
      />
      <input type="file" ref={inputFileRef} onChange={handleFileSelection} className="hidden" multiple />
    </>
  );
};

export default UploadPanel;
