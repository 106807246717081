import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminCustomerI18NTemplateDetail from '@components/pages/admin/templates/AdminCustomerI18NTemplateDetail';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { useSearchParams } from 'react-router-dom';

interface ComponentProps {}

const TemplateDetailPage: React.FC<ComponentProps> = () => {
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get('templateId');
  const customerId = searchParams.get('adminCompanyId');

  const reg = /([\w]+)-([\w]+)/;
  const matches = customerId.match(reg);

  return (
    <>
      <AdminLayout title="Template detail">
        <AdminCustomerI18NTemplateDetail templateId={templateId} customerId={matches[2]} companyType={matches[1]} />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(TemplateDetailPage);
