import React, { useContext, useRef } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { GlobalTimeslotsDTO, OpeningEntityTypeEnum } from '@api/logsteo-api.v2';
import { Form, proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { dumpVars, isNullOrUndefined } from '@utils/utils';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';

export interface SupplierNotificationLoadingUnloadingData {
  supplierDeliverySlots?: GlobalTimeslotsDTO;
  customerDeliverySlots?: GlobalTimeslotsDTO;
  readonly supplierLocationId: string;
  readonly customerLocationId: string;
}

interface ComponentProps {
  form: Form<SupplierNotificationLoadingUnloadingData>;
  inSidebar: boolean;
}

export const supplierNotificationValidation = yup.object().shape({});

const SupplierNotificationLoadingUnloadingForm: React.FC<ComponentProps> = ({ form, inSidebar }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const childrenForms = useRef([]);

  if (isNullOrUndefined(loggedUser)) return <></>;

  const names = proxiedPropertiesOf<SupplierNotificationLoadingUnloadingData>();

  return (
    <>
      <RR>
        <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
          <HorizontalFieldValue
            label={tr(`CreateSupplierNotification.supplierTimeslot`, `Supplier timeslot`)}
            value={
              <>
                <ManageTimeslot
                  entityId={form.values.supplierLocationId}
                  entityType={OpeningEntityTypeEnum.LOCATION}
                  timeslot={form.values.supplierDeliverySlots}
                  onChange={v => {
                    form.setFieldValue(names.supplierDeliverySlots, v);
                  }}
                  noOpeningHours={false}
                  validationErrors={form.validationErrors}
                  validationPrefix={'supplierDeliverySlots.'}
                />
              </>
            }
          />
        </RC>
        <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
          <HorizontalFieldValue
            label={tr(`CreateSupplierNotification.customerTimeslot`, `Customer timeslot`)}
            value={
              <>
                <ManageTimeslot
                  entityId={form.values.customerLocationId}
                  entityType={OpeningEntityTypeEnum.LOCATION}
                  timeslot={form.values.customerDeliverySlots}
                  onChange={v => {
                    form.setFieldValue(names.customerDeliverySlots, v);
                  }}
                  noOpeningHours={false}
                  validationErrors={form.validationErrors}
                  validationPrefix={'customerDeliverySlots.'}
                />
              </>
            }
          />
        </RC>
      </RR>
    </>
  );
};

export default SupplierNotificationLoadingUnloadingForm;
