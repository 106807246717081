import React from 'react';
import PageBreadCrumb, { BreadCrumbModel } from '@components/ringil3/BreadCrumb/PageBreadCrumb.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';

export interface ComponentProps {
  items: BreadCrumbItemModel[];
  wrapper?: string;
}

export interface BreadCrumbItemModel {
  label: string;
  command?: (event: any) => void;
  disabled?: boolean | false;
  url?: string;
}

export interface BreadCrumbHomeModel {
  icon: string;
  url: string;
}

const BreadCrumbComponent: React.FC<ComponentProps> = ({ items, wrapper }) => {
  const menus = items.map(
    i =>
      ({
        label: i.label,
        action: isNotNullOrUndefined(i.url) ? i.url : i.command,
        disabled: i.disabled,
      } as BreadCrumbModel),
  );

  return (
    <div className={'flex flex-col gap-3 h-[72px] border-0 border-b border-solid justify-center border-content-tertiary mb-2'}>
      {/*<BreadCrumb model={menus} className={className} home={home} />*/}
      <PageBreadCrumb model={menus} />
      {/*<HorizontalLine variant="tertiary" />*/}
    </div>
  );
};

export default BreadCrumbComponent;
