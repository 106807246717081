import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card, HorizontalLine } from '@components/ringil3/styles';
import { LocationPreviewOutDTO } from '@api/logsteo-api.v2';
import EditableHeading3 from '@components/ringil3/Heading/EditableHeading3';
import { formatLocationNotino } from '@utils/utils';
import { printDeliverySlots } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot';
import { Row, RowWithMargin } from '@components/styles';
import LoadingReferenceBadge from '@components/ringil3/Badges/LoadingReferenceBadge';
import RingilOrder, { convertToGlobalOrder } from '@components/ringil3/RingilOrder/RingilOrder';
import SvgMappoint from '@components/ringil3/icons/Mappoint';
import SvgCalendar from '@components/ringil3/icons/Calendar';
import SvgContacts from '@components/ringil3/icons/Contacts';
import SvgLoading from '@components/ringil3/icons/Loading';
import SvgUnloading from '@components/ringil3/icons/Unloading';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  location: LocationPreviewOutDTO;
}

const ExpeditionLocation: React.FC<ComponentProps> = ({ location }) => {
  const { tr } = useTranslationLgs();
  return (
    <ExpeditionLocationInner>
      <Card>
        <Grid>
          <Col>
            <EditableHeading3 editable={false} title={location.name} icon={<SvgMappoint />}></EditableHeading3>
            <div>{formatLocationNotino(location.zipCode, location.city, location.country, location.streetNr)}</div>
            <EditableHeading3 editable={false} title={tr(`ExpeditionLocation.ocekavanaManipulace`, `Ocekavana manipulace`)} icon={<SvgCalendar />}></EditableHeading3>
            <div>{printDeliverySlots(location.timeslots, tr)}</div>
          </Col>
          <Col>
            <EditableHeading3 editable={false} title={'Kontakty'} icon={<SvgContacts />}></EditableHeading3>
            <div>{location.contactName}</div>
            <div>{location.contactPhone}</div>
            <div>{location.contactEmail}</div>

            {location.persons.length > 0 && (
              <AdditionalContacts>
                <COHeadline variant={'h5'} title={'Notifikovat dalsi osoby'}></COHeadline>
                {location.persons.map((p, pIndex) => {
                  return (
                    <Contact key={pIndex}>
                      <div>{p.contactName}</div>
                      <div>{p.contactPhone}</div>
                      <div>{p.contactEmail}</div>
                    </Contact>
                  );
                })}
              </AdditionalContacts>
            )}
          </Col>
        </Grid>
      </Card>
      <Card>
        <Col>
          {location.loadedOrders.length > 0 && (
            <>
              <EditableHeading3 editable={false} title={'Nakladka'} icon={<SvgLoading />}></EditableHeading3>
              <Row>
                <LoadingReferenceBadge loadingReference={location.pickupCode} />
              </Row>
              <RowWithMargin>
                {location.loadedOrders.map((order, orderIndex) => {
                  return <RingilOrder key={orderIndex} type={'loading'} order={convertToGlobalOrder(order)} />;
                })}
              </RowWithMargin>
            </>
          )}
          {location.loadedOrders.length > 0 && location.unloadedOrders.length > 0 && <HorizontalLine variant={'quaternary'} />}

          {location.unloadedOrders.length > 0 && (
            <>
              <EditableHeading3 editable={false} title={'Vykladka'} icon={<SvgUnloading />}></EditableHeading3>
              <Row>
                {location.unloadedOrders.map((order, orderIndex) => {
                  return <RingilOrder key={orderIndex} type={'unloading'} order={convertToGlobalOrder(order)} />;
                })}
              </Row>
            </>
          )}
        </Col>
      </Card>
    </ExpeditionLocationInner>
  );
};

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const AdditionalContacts = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const ExpeditionLocationInner = styled.div`
  /*  margin: 1rem 0;
  gap: 1rem;
  display: flex;*/
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  display: grid;
  margin: 1rem 0;
  gap: 1rem;
`;

export default ExpeditionLocation;
