import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuTms = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#616061"
      d="M17.5 6.75h-2V6c0-.96-.79-1.75-1.75-1.75H4.5c-.96 0-1.75.79-1.75 1.75v9c0 .96.79 1.75 1.75 1.75h.28c.13 1.67 1.52 3 3.22 3s3.09-1.33 3.22-3h1.55c.13 1.67 1.52 3 3.22 3s3.09-1.33 3.22-3h.28c.96 0 1.75-.79 1.75-1.75v-4.5c0-2.07-1.68-3.75-3.75-3.75zM4.25 15V6c0-.14.11-.25.25-.25h9.25c.14 0 .25.11.25.25v7.94s-.05.06-.08.08q-.225.195-.42.42c-.04.05-.08.09-.11.14a3 3 0 0 0-.38.66h-2v-.01a3 3 0 0 0-.27-.49c-.03-.04-.06-.08-.09-.13-.08-.12-.17-.22-.27-.33-.04-.04-.07-.08-.11-.12-.13-.12-.26-.23-.4-.33-.02-.01-.03-.03-.05-.04-.15-.1-.31-.18-.47-.26-.06-.03-.12-.05-.18-.07-.13-.05-.25-.09-.38-.12-.06-.01-.12-.03-.18-.04a2.9 2.9 0 0 0-1.18 0c-.06.01-.12.03-.18.04-.13.03-.26.07-.38.12-.06.02-.12.04-.18.07-.16.07-.32.16-.47.26-.02.01-.03.02-.05.04-.14.1-.28.21-.4.33-.04.04-.08.08-.11.12q-.15.15-.27.33c-.03.04-.06.08-.09.13-.1.16-.19.32-.27.49v.01h-.5c-.14 0-.25-.11-.25-.25zM8 18.25c-.96 0-1.75-.79-1.75-1.75a1.69 1.69 0 0 1 .24-.85c.01-.03.03-.05.04-.08.09-.14.2-.27.33-.38.03-.02.05-.04.08-.06.13-.1.28-.19.44-.25.02 0 .05-.02.08-.02.17-.06.36-.1.55-.1s.37.04.55.1c.03 0 .05.01.08.02.16.06.31.15.44.25.03.02.05.04.08.06.13.11.24.24.33.38.02.02.03.05.04.08.09.16.16.32.2.5.02.11.04.23.04.35 0 .96-.79 1.75-1.75 1.75zm8 0c-.96 0-1.75-.79-1.75-1.75 0-.12.01-.24.04-.35.03-.12.06-.24.11-.35v-.02c.05-.1.1-.2.17-.29.01-.02.03-.03.04-.05a2 2 0 0 1 .19-.22c.02-.02.04-.03.06-.05.07-.06.15-.12.23-.17.02-.01.05-.03.08-.04.09-.05.19-.09.29-.12.04-.01.08-.03.13-.04.13-.03.27-.05.41-.05.19 0 .37.04.55.1.03 0 .05.01.08.02.16.06.31.15.44.25.03.02.05.04.08.06.13.11.24.24.33.38.02.02.03.05.04.08.09.16.16.32.2.5.02.11.04.23.04.35 0 .96-.79 1.75-1.75 1.75zm-.5-7v-3h2c1.24 0 2.25 1.01 2.25 2.25v.75z"
    />
  </svg>
);
export default SvgMenuTms;
