import React from 'react';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { Checkbox } from 'primereact/checkbox';
import CheckboxLayout from '@components/obsolete/CheckboxLayout/CheckboxLayout.tsx';
import CheckboxWrapper from '@components/obsolete/CheckboxWrapper/CheckboxWrapper.tsx';
import CheckboxItem from '@components/obsolete/CheckboxWrapper/CheckboxItem.tsx';

interface RequirementCheckboxesProps {
  value: LovWithoutIdDTO[];
}

const RequirementCheckboxes: React.FC<RequirementCheckboxesProps> = ({ value }) => {
  return (
    <>
      <CheckboxLayout className="p-mt-3 p-mb-5">
        <CheckboxWrapper className="p-lg-7">
          {value &&
            value.map(lov => {
              return (
                <CheckboxItem className="p-md-4 p-lg-3">
                  <Checkbox checked={true} className="p-mr-2" disabled />
                  <label>{lov.name}</label>
                </CheckboxItem>
              );
            })}
        </CheckboxWrapper>
      </CheckboxLayout>
    </>
  );
};

export default RequirementCheckboxes;
