import React from 'react';
import clsx from 'clsx';

interface ComponentProps {
  locationPoint?: string;
  locationAddress?: string;
  locationDate?: any;
  locationDisabled?: boolean;
  wrapper?: string;
  through?: string;
  locationDesc?: string;
  render?: boolean;
}

const ExpeditionLocation: React.FC<ComponentProps> = ({
  locationPoint,
  locationAddress,
  locationDate,
  locationDisabled,
  wrapper,
  through,
  locationDesc,
  render = true,
}) => {
  const className = clsx('location', {
    'p-col-12 p-md-6 p-d-flex p-flex-nowrap': locationDisabled === false,
    'location-disabled p-col-12 p-md-6 p-d-flex p-flex-nowrap': locationDisabled === true,
    'p-d-flex p-flex-nowrap': wrapper === 'LARGE',
  });

  return (
    <>
      {!render && <div className={className}></div>}
      {render && (
        <div className={className}>
          <div className="p-mr-1 location-point">
            <small>{locationPoint}</small>
          </div>
          <div className="p-d-flex p-flex-wrap">
            {locationAddress && (
              <div className="p-mr-3">
                {locationAddress && <strong className="location-name">{locationAddress}</strong>}
                {locationDesc && <span className="delimiter p-pr-2">,</span>}
                {locationDesc && <small className="location-name-decscription">{locationDesc}</small>}
              </div>
            )}
            {locationDate && <small className="location-date text-gray5">{locationDate}</small>}
            {through && <small className="text-gray5">{through}</small>}
          </div>
        </div>
      )}
    </>
  );
};
export default ExpeditionLocation;
