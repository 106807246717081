import React, { useContext, useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RowWithIcon } from '@components/styles';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ClearButton } from '../Buttons/ClearButton/ClearButton';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { findValidationMessage } from '@utils/validation';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  containerInfo: ContainerInfoForm;
  onChange: (containerInfo: ContainerInfoForm) => void;
  clearable?: boolean;
  onClear?: () => void;
  inSidebar: boolean;
  validationErrors: ValidationError[];
  disabled?: boolean;
}

export interface ContainerInfoForm {
  containerInfoCode: string;
  containerId: string;
}

export const ContainerInfo: React.FC<ComponentProps> = ({
  containerInfo,
  onChange,
  onClear = () => {},
  inSidebar,
  clearable = false,
  validationErrors,
  disabled = false,
}) => {
  const [show, setShow] = useState(false);
  const { tr } = useTranslationLgs();
  const { lovContainerTypes } = useContext(ApiContext);
  const [containerTypes, setContainerTypes] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovContainerTypes(d => {
      setContainerTypes(d);
    });
  }, []);

  return (
    <RR>
      <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={12}>
        <HorizontalFieldValue
          required={true}
          label={tr('ContainerInfo.containerType', 'Container type')}
          validationError={findValidationMessage(validationErrors, 'containerInfo.containerInfoCode', tr)}
          value={
            <Dropdown
              style={{ width: '100%' }}
              options={containerTypes}
              optionValue={'code'}
              optionLabel={'name'}
              value={containerInfo?.containerInfoCode}
              onChange={e => onChange({ ...containerInfo, containerInfoCode: e.value })}
              disabled={disabled}
            />
          }
        />
      </RC>
      <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={12}>
        <HorizontalFieldValue
          required={true}
          validationError={findValidationMessage(validationErrors, 'containerInfo.containerId', tr)}
          label={tr('ContainerInfo.containerId', 'Container ID')}
          value={
            <RowWithIcon>
              <InputText
                style={{ width: '100%' }}
                value={containerInfo?.containerId}
                onChange={e => onChange({ ...containerInfo, containerId: e.target.value })}
                disabled={disabled}
              />
              {clearable && <ClearButton onClick={e => onClear()} disabled={disabled} />}
            </RowWithIcon>
          }
        />
      </RC>
    </RR>
  );
};
