import React from 'react';
import { CarrierGroupInDTO, CarrierOrGroupEnum, FindCarrierCarrierOrGroupDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';
import useCustomerCarriersAndGroups from '@hooks/customer-carrier/customer-carriers-groups.tsx';

interface ComponentProps {
  value: CarrierGroupInDTO[];
  onSelected: (value: CarrierGroupInDTO[]) => void;
  onlyCarriers: boolean;
  isDisabled: boolean;
  placeholder: string;
}

export const carriersAndGroupsTemplate = (option: FindCarrierCarrierOrGroupDTO) => {
  return (
    <div className="p-d-flex p-ai-center">
      {option.carrierOrGroup === CarrierOrGroupEnum.CARRIER ? <i className="pi pi-id-card"></i> : <i className="pi pi-list"></i>}
      <span className="p-ml-2">{option.name}</span>
    </div>
  );
};

export const SelectCarriers: React.FC<ComponentProps> = ({ value, onSelected, placeholder, onlyCarriers = false, isDisabled }) => {
  const carriersAndGroups = useCustomerCarriersAndGroups();

  const selectedCarriersAndGroupsTemplate = (option: FindCarrierCarrierOrGroupDTO) => {
    if (option) {
      return (
        <div className={'p-multiselect-label'}>
          {option.carrierOrGroup === CarrierOrGroupEnum.CARRIER ? <i className="pi pi-id-card"></i> : <i className="pi pi-list"></i>}
          <span className="p-ml-1 p-mr-2">{option.name}</span>
        </div>
      );
    }
    return <div className={'p-multiselect-label p-placeholder'}> {placeholder}</div>;
  };

  return (
    <MultiSelect
      value={value}
      options={carriersAndGroups.filter(t => (onlyCarriers ? t.carrierOrGroup === CarrierOrGroupEnum.CARRIER : true))}
      optionLabel="name"
      filter
      className="multiselect-custom"
      onChange={e => onSelected(e.value)}
      style={{ width: '100%' }}
      disabled={isDisabled}
      itemTemplate={carriersAndGroupsTemplate}
      selectedItemTemplate={selectedCarriersAndGroupsTemplate}
      maxSelectedLabels={15}
      placeholder={'asdfasd'}
    />
  );
};
