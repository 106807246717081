import React, { useContext } from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import AdminLayout from '@app/layout/Layout.tsx';

function Profile() {
  const { loggedUser } = useContext(AuthenticatedUserContext);

  return (
    <AdminLayout title="Expedition overview">
      <title>Ringil | Authentication info</title>
      <div>
        <h1>Profile</h1>
        <p>This is the profile page.</p>

        <pre>{JSON.stringify(loggedUser || {}, null, 2)}</pre>
      </div>
    </AdminLayout>
  );
}

export default withAuthenticationRequired(Profile);
