import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const InputLanguage: React.FC<ComponentProps> = ({ value, onChange, disabled }) => {
  const {} = useContext(ApiContext);
  const { tr } = useTranslationLgs();

  const langs = ['cs', 'en', 'de', 'hu', 'pl', 'ro'].map(l => ({ label: tr(`InputLanguage.${l}`), value: l }));

  return (
    <>
      <Dropdown
        options={langs}
        value={value}
        onChange={e => onChange(e.value)}
        optionValue={'value'}
        optionLabel={'label'}
        placeholder={'Choose language'}
        style={{ width: '100%', height: '36px' }}
        disabled={disabled}
      />
    </>
  );
};

export default InputLanguage;
