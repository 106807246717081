import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Layout from '@app/layout/Layout.tsx';
import CreateShipment from '@components/obsolete/shipment/CreateShipment.tsx';

interface ComponentProps {}

const CreateShipmentPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Layout title={tr(`create-shipment.createShipment`, `Create shipment`)}>{<CreateShipment />}</Layout>
    </>
  );
};

export default withAuthenticationRequired(CreateShipmentPage);
