import React from 'react';
import { Form, proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { CompanyPreview, LovDTO } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import { InputCarriersPick } from '@components/obsolete/Form/InputCarriers/InputCarriersPick.tsx';

interface ComponentProps {
  form: Form<CreateOrUpdateAdditionTermsFormData>;

  inSidebar: boolean;
}

export interface CreateOrUpdateAdditionTermsFormData {
  name: string;
  isOnSinglePage: boolean;
  isMandatory: boolean;
  id?: string;
  termText: string;
  companies: CompanyPreview[];
}

const CreateOrUpdateAdditionTermsForm: React.FC<ComponentProps> = ({ inSidebar, form }) => {
  const { tr } = useTranslationLgs();

  const names = proxiedPropertiesOf<CreateOrUpdateAdditionTermsFormData>();

  return (
    <>
      <RR>
        <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 2} small={inSidebar ? 12 : 1}>
          <HorizontalFieldValue
            required={true}
            label={tr(`CreateOrUpdateAdditionTermsForm.name`, `Name`)}
            value={<InputText value={form.values.name} onChange={v => form.setFieldValue(names.name, v.target.value)} style={{ width: '100%' }} />}
          />
        </RC>
        <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 2} small={inSidebar ? 12 : 1}>
          <HorizontalFieldValue
            required={true}
            label={tr(`CreateOrUpdateAdditionTermsForm.mandatory`, `Mandatory`)}
            value={<InputYesNoPlain value={form.values.isMandatory} onChange={v => form.setFieldValue(names.isMandatory, v)} />}
          />
        </RC>
        <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 2} small={inSidebar ? 12 : 1}>
          <HorizontalFieldValue
            required={true}
            label={tr(`CreateOrUpdateAdditionTermsForm.onSinglePage`, `On single page`)}
            value={<InputYesNoPlain value={form.values.isOnSinglePage} onChange={v => form.setFieldValue(names.isOnSinglePage, v)} />}
          />
        </RC>
        <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 2} small={inSidebar ? 12 : 1}>
          <HorizontalFieldValue
            required={true}
            label={tr(`CreateOrUpdateAdditionTermsForm.carriers`, `Carriers`)}
            value={
              <InputCarriersPick
                value={form.values.companies.map(t => {
                  return { id: t.companyId, name: t.companyName } as LovDTO;
                })}
                onSelected={v => {
                  form.setFieldValue(
                    names.companies,
                    v.map(t => {
                      return {
                        companyName: t.name,
                        companyId: t.id,
                      } as CompanyPreview;
                    }),
                  );
                }}
                isDisabled={false}
                placeholder={tr(`CreateOrUpdateAdditionTermsForm.selectCarriersToApplyThisTerm`, `Select carriers to apply this term`)}
              />
            }
          />
        </RC>
        <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 2} small={inSidebar ? 12 : 1}>
          <HorizontalFieldValue
            required={true}
            label={tr(`CreateOrUpdateAdditionTermsForm.terms`, `Terms`)}
            value={
              <InputTextarea
                value={form.values.termText}
                onChange={event => form.setFieldValue(names.termText, event.target.value)}
                rows={10}
                style={{ width: '100%' }}
              />
            }
          />
        </RC>
      </RR>
    </>
  );
};

export default CreateOrUpdateAdditionTermsForm;
