import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import { LovDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  partnerId: string;
  onChange: (partnerId: string) => void;
  style?: any;
  readMode?: boolean;
  disabled?: boolean;
}

const InputPartnerPlain: React.FC<ComponentProps> = ({ partnerId, onChange, style, readMode = false, disabled = false }) => {
  const { cuLovPartners } = useContext(ApiContext);
  const [partners, setPartners] = useState<LovDTO[]>([]);

  useEffect(() => {
    cuLovPartners(null, d => {
      setPartners(d);
    });
  }, []);

  return (
    <>
      {readMode ? (
        <div>{partners.find(t => t.id === partnerId)?.name || '---'}</div>
      ) : (
        <Dropdown
          style={style}
          value={partnerId}
          options={partners}
          optionLabel={'name'}
          optionValue={'id'}
          onChange={e => {
            onChange(e.value);
          }}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default InputPartnerPlain;
