import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import AllCarriersList from '@app/pages/customer/carriers/AllCarriersList.tsx';

const CarriersListPage = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`carriers.allCarriersList`, `Všichni dopravci`)}>
      {/*
        // @ts-ignore*/}
      <Card>
        <AllCarriersList />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(CarriersListPage);
