import React, { useContext, useEffect, useState } from 'react';
import { isNullOrUndefined } from '@utils/utils';
import { Dropdown } from 'primereact/dropdown';
import { LovDTO, ModuleSettingsOutDTO, CompanyModuleEnum } from '@api/logsteo-api.v2';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  companyId: string;
  onChange: (companyId: string, companyCode: string) => void;
  onChangeCompanyCode: (companyCode: string) => void;
  style?: any;
  moduleType: CompanyModuleEnum;
  placeholder?: string;
  readonly?: boolean;
}

interface LovWithPreference extends Omit<LovDTO, 'active'> {
  preferred: boolean;
  companyCode?: string;
}

/**
 * Entity owner is the paying customer of the functionality or the inherited party that creates the entity for the pauint customer
 * @param partnerId
 * @param onChange
 * @param style
 * @constructor
 */
const InputEntityOwner: React.FC<ComponentProps> = ({ moduleType, companyId, onChange, style, placeholder, onChangeCompanyCode, readonly = false }) => {
  const { loggedUser, isReady } = useContext(AuthenticatedUserContext);
  const [companies, setCompanies] = useState<LovWithPreference[]>();

  const convertToCompany = (t: ModuleSettingsOutDTO): LovWithPreference => {
    return {
      id: t.inheritingModule ? t.owner.companyId : loggedUser.companyId,
      name: t.inheritingModule ? t.owner.companyName : loggedUser.company,
      companyCode: loggedUser.companyCode ? loggedUser.companyCode : t.owner?.companyCode,
      preferred: !t.inheritingModule,
    };
  };

  useEffect(() => {
    if (isReady) {
      const c = loggedUser?.modules
        .filter(t => t.companyModule === moduleType)
        .reduce((previousValue, currentValue) => {
          return [...previousValue, convertToCompany(currentValue)];
        }, []);
      setCompanies(c);
    }
  }, [isReady]);

  useEffect(() => {
    if (companies && companies.length > 0 && isNullOrUndefined(companyId)) {
      onChange(companies.find(t => t.preferred)?.id || companies[0].id, companies.find(t => t.preferred)?.companyCode || companies[0].companyCode);
      if (onChangeCompanyCode) {
        onChangeCompanyCode(companies.find(t => t.preferred)?.companyCode || companies[0].companyCode);
      }
    }
  }, [companies]);

  return (
    <>
      {loggedUser && (
        <>
          <Dropdown
            style={style}
            value={companyId}
            options={companies}
            optionLabel={'name'}
            optionValue={'id'}
            placeholder={placeholder}
            disabled={readonly}
            onChange={e => {
              onChange(e.value, companies.find(t => t.id == e.value)?.companyCode);
              if (onChangeCompanyCode) {
                onChangeCompanyCode(companies.find(t => t.id == e.value)?.companyCode);
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default InputEntityOwner;
