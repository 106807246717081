import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import styled from 'styled-components';
import { useParams, useRoutes, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { proxiedPropertiesOf } from '@hooks/useForm/useForm.tsx';
import { first, isNotBlank, isNotNullOrUndefined, isValidString } from '@utils/utils.tsx';

interface ComponentProps {
  filters?: FilterElement[];
}

interface FilterElement {
  filterLabel: string;
  filterInput: any;
}

export interface FilterPage {
  pageNumber: number;
  pageSize: number;
}

export interface FilterSort {
  sortField: string;
  sortDirection: 'ASC' | 'DESC';
}

const getParamsAsArray = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  const result: { [key: string]: string | string[] } = {};

  params.forEach((value, key) => {
    if (!isValidString(value)) return; // Ignoruj neplatné hodnoty

    if (result[key]) {
      if (Array.isArray(result[key])) {
        (result[key] as string[]).push(value);
      } else {
        result[key] = [result[key] as string, value];
      }
    } else {
      result[key] = value;
    }
  });

  return result;
};

///https://alpha.ringil.com/cs/customer/expeditions?onlyUnpublished=true&showArchived=true&onlyManual=true&responsiblePersonIds=&pageNumber=1&pageSize=10

export const useFilter = <T,>(onLoad: (page: FilterPage, filter: T) => void, defaultSort?: FilterSort, defaultFilter?: T) => {
  const [filter, setFilter] = useState<T>(defaultFilter);
  const [page, setPage] = useState<FilterPage>({ pageNumber: 1, pageSize: 10 });
  const [sort, setSort] = useState<FilterSort>(defaultSort);
  const [combined, setCombined] = useState<CombinedValue>();
  const [debouncedValue] = useDebounce(combined, 300, {});
  const [initialized, setInitialized] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  interface CombinedValue {
    page: FilterPage;
    filter: T;
    sort?: FilterSort;
  }

  useEffect(() => {
    //const filteredObject = Object.fromEntries(new URLSearchParams(location.search));
    const filteredObject = getParamsAsArray(location.search);

    const page = {
      pageNumber: filteredObject.pageNumber ? parseInt(first(filteredObject.pageNumber)) : 1,
      pageSize: filteredObject.pageSize ? parseInt(first(filteredObject.pageSize)) : 10,
    } as FilterPage;

    const sort = {
      sortField: filteredObject.sortField?.length > 0 ? first(filteredObject.sortField) : null,
      sortDirection: filteredObject.sortDirection?.length > 0 ? first(filteredObject.sortDirection) : null,
    } as FilterSort;

    delete filteredObject.pageNumber;
    delete filteredObject.pageSize;
    delete filteredObject.sortField;
    delete filteredObject.sortDirection;

    if (sort.sortField === null && defaultSort) {
      setSort(defaultSort);
    } else {
      setSort(sort);
    }

    setPage(page);
    if (Object.keys(filteredObject).length === 0 && isNotNullOrUndefined(defaultFilter)) {
      setFilter(defaultFilter);
    } else {
      setFilter(filteredObject as T);
    }
    // @ts-ignore
    setCombined({ page, filter: filteredObject });
    setInitialized(true);
  }, []);

  const applyFilter = (filter: T) => {
    //console.log(`applyFilter: ${JSON.stringify(filter)}`);
    setFilter(filter);
    setCombined(prev => {
      return { ...prev, filter };
    });
  };

  const names = proxiedPropertiesOf<T>();

  const applyFilterValue = (property: string, value: any) => {
    //console.log(`applyFilterValue: property: ${property}, value=${JSON.stringify(value)}`);
    setFilter(prev => {
      // @ts-ignore
      const newValue = { ...prev, [property]: value };
      //console.log(`setFilter: new value: ${JSON.stringify(newValue)}`);
      return newValue;
    });

    setCombined(prev => {
      // @ts-ignore
      const newValue = {
        ...prev,
        filter: { ...prev.filter, [property]: value },
      };
      //console.log(`setCombined: new value: ${JSON.stringify(newValue)}`);
      return newValue;
    });
  };

  const applyPaging = (page: FilterPage) => {
    //console.log(`applyPaging: ${JSON.stringify(page)}...`);
    setPage(page);
    setCombined(prev => {
      return { ...prev, page };
    });
  };

  const applySorting = (sortField: string, sortDirection: 'ASC' | 'DESC') => {
    //console.log(`applySorting: ${sortField}, ${sortDirection}`);
    setSort({ sortField, sortDirection });
    setCombined(prev => {
      return { ...prev, sort: { sortField, sortDirection } };
    });
  };

  useEffect(() => {
    //console.log(`useEffect: ${JSON.stringify(debouncedValue, null, 2)}, ${initialized}`);
    if (initialized && debouncedValue) {
      onLoad(page, filter);
      const value = qs.stringify(
        {
          // @ts-ignore
          ...filter,
          pageNumber: page?.pageNumber?.toString(),
          pageSize: page?.pageSize?.toString(),
          sortField: debouncedValue?.sort?.sortField,
          sortDirection: debouncedValue?.sort?.sortDirection,
        },
        { arrayFormat: 'repeat' },
      );
      console.log('Stringify: ', value);
      setSearchParams(value);
    }
  }, [debouncedValue, initialized]);

  const onPage = (event: any) => {
    applyPaging({ pageNumber: event.page + 1, pageSize: event.rows });
  };

  const Filter: React.FC<PropsWithChildren<ComponentProps>> = ({ filters, children }) => {
    return <RowGrid>{children}</RowGrid>;
  };

  return {
    Filter,
    applyFilterValue,
    applyPaging,
    filter,
    page,
    onPage,
    applySorting,
    sort,
    applyFilter,
    names,
  };
};

const RowGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  margin: 0.5rem 0;
  gap: 0.5rem;
`;
