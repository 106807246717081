import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';
import { RowWithGap } from '@components/styles.tsx';
import { visualiseWorkflowStateState } from '@utils/utils.tsx';

interface ComponentProps {
  reservation: ReservationsDataOutDTO;
}

const CFRWorkflowIcon: React.FC<ComponentProps> = ({ reservation }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <RowWithGap>{reservation.state && reservation.state.name !== 'new' && <Circle>{visualiseWorkflowStateState(reservation.state, tr, false)}</Circle>}</RowWithGap>
    </>
  );
};

const Circle = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: white;
  padding: 0.5rem;
`;

export default CFRWorkflowIcon;
