import React, { useContext, useState } from 'react';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import { EntityTypeEnum, OpeningEntityTypeEnum, OpeningModeEnum, ShipmentStateCodeEnum } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SupplierNotificationLoadingUnloadingForm, { SupplierNotificationLoadingUnloadingData } from '../forms/SupplierNotificationLoadingUnloadingForm';
import { dumpVars, isNullOrUndefined } from '@utils/utils';
import { Calendar } from 'primereact/calendar';
import { mapToAPIDateTime, shortDateFormat } from '@utils/date';
import dayjs from 'dayjs';
import * as yup from 'yup';
import ManageTimeslot, { timeslotValidationSchema } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import Section from '@components/obsolete/Section/Section.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputShipmentStatus from '@components/obsolete/Form/InputShipmentStatus/InputShipmentStatus.tsx';
import InputResponsiblePersonPlain from '@components/obsolete/Form/InputResponsiblePerson/InputResponsiblePersonPlain.tsx';
import { Note } from '@components/styles.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import LabelsControlled from '@components/obsolete/Labels/LabelsControlled.tsx';

interface ComponentProps {
  supplierNotificationIds: string[];
}

interface SupplierNotificationMassEditData extends SupplierNotificationLoadingUnloadingData {
  responsiblePersonId: string;
  shipmentStateCode: ShipmentStateCodeEnum;

  overwriteLoadingTimeslots: boolean;
  overwriteShipmentStateCode: boolean;
  overwriteResponsiblePersonId: boolean;

  overwriteDatesWithOpeningTimes: boolean;
  loadingDates: Date[];
  unloadingDates: Date[];
  overwriteLabels: boolean;
  labels: string[];
}

export const useCreateMultiEditSupplierNotificationSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);
  const { tr } = useTranslationLgs();

  const MultiEditSupplierNotificationSidebar: React.FC<ComponentProps> = ({ supplierNotificationIds }) => {
    const { updateSupplierNotificationMassAction } = useContext(ApiContext);

    const form = useForm<SupplierNotificationMassEditData>(
      yup.object().shape({
        supplierDeliverySlots: timeslotValidationSchema,
        customerDeliverySlots: timeslotValidationSchema,
      }),
      {
        responsiblePersonId: null,
        supplierDeliverySlots: { dayWithInterval: [] },
        customerDeliverySlots: { dayWithInterval: [] },
        shipmentStateCode: null,
        overwriteLoadingTimeslots: false,
        overwriteShipmentStateCode: false,
        overwriteResponsiblePersonId: false,
        overwriteDatesWithOpeningTimes: false,
        loadingDates: [],
        unloadingDates: [],
        overwriteLabels: true,
        labels: [],
        supplierLocationId: null,
        customerLocationId: null,
      },
      v => {
        updateSupplierNotificationMassAction(
          {
            snIds: supplierNotificationIds,
            internalData: {
              loadingDeliverySlot: form.values.overwriteLoadingTimeslots ? form.values.supplierDeliverySlots : null,
              unloadingDeliverySlot: form.values.overwriteLoadingTimeslots ? form.values.customerDeliverySlots : null,
              responsiblePersonId: form.values.overwriteResponsiblePersonId ? form.values.responsiblePersonId : null,
              shipmentStateCode: form.values.overwriteShipmentStateCode ? form.values.shipmentStateCode : null,
              overwriteLoadingTimeslots: form.values.overwriteLoadingTimeslots,
              overwriteResponsiblePersonId: form.values.overwriteResponsiblePersonId,
              overwriteShipmentStateCode: form.values.overwriteShipmentStateCode,
              overwriteDatesWithOpeningTimes: form.values.overwriteDatesWithOpeningTimes,
              loadingDatesSince: form.values.overwriteDatesWithOpeningTimes ? mapToAPIDateTime(dayjs(fixRangeDate(form.values.loadingDates)[0])) : null,
              loadingDatesUntil: form.values.overwriteDatesWithOpeningTimes ? mapToAPIDateTime(dayjs(fixRangeDate(form.values.loadingDates)[1])) : null,
              unloadingDatesSince: form.values.overwriteDatesWithOpeningTimes ? mapToAPIDateTime(dayjs(fixRangeDate(form.values.unloadingDates)[0])) : null,
              unloadingDatesUntil: form.values.overwriteDatesWithOpeningTimes ? mapToAPIDateTime(dayjs(fixRangeDate(form.values.unloadingDates)[1])) : null,
              labels: form.values.overwriteLabels ? form.values.labels : null,
              overwriteLabels: form.values.overwriteLabels,
            },
          },
          () => {
            setVisible(false);
            onComplete();
          },
        );
      },
      false,
      false,
    );

    const fixRangeDate = (d: Date[]) => {
      if (d.length == 1) {
        return [d[0], d[0]];
      } else if (d.length == 2 && d[1] == null) {
        return [d[0], d[0]];
      }
      return [d[0], d[1]];
    };

    if (isNullOrUndefined(form)) return <></>;

    const names = proxiedPropertiesOf<SupplierNotificationMassEditData>();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`MultiEditSupplierNotificationSidebar.massUpdateAction`, `Mass update action`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        <Section
          label={tr(`MultiEditSupplierNotificationSidebar.iWantChangeShipmentState`, `I want change shipment state`)}
          showEnable={true}
          onVisibilityChanged={v => form.setFieldValue(names.overwriteShipmentStateCode, v)}
        >
          <HorizontalFieldValue
            label={tr(`MultiEditSupplierNotificationSidebar.shipmentState`, `Shipment state`)}
            value={
              <InputShipmentStatus
                code={form.values.shipmentStateCode}
                onChange={v => {
                  form.setFieldValue(names.shipmentStateCode, v);
                }}
              />
            }
          />
        </Section>
        <Section
          label={tr(`MultiEditSupplierNotificationSidebar.iWatchChangeResponsiblePerson`, `I want change responsible person`)}
          showEnable={true}
          onVisibilityChanged={v => form.setFieldValue(names.overwriteResponsiblePersonId, v)}
        >
          <HorizontalFieldValue
            label={tr(`MultiEditSupplierNotificationSidebar.responsiblePerson`, `Responsible person`)}
            value={<InputResponsiblePersonPlain responsiblePersonId={form.values.responsiblePersonId} onChange={v => form.setFieldValue(names.responsiblePersonId, v)} />}
          />
        </Section>
        <Section
          label={tr(`MultiEditSupplierNotificationSidebar.iWatchChangeDates`, `I want change dates`)}
          showEnable={true}
          onVisibilityChanged={v => {
            form.setFieldValue(names.overwriteLoadingTimeslots, v);
            if (v) {
              form.setFieldValue(names.supplierDeliverySlots, {
                dayWithInterval: [
                  {
                    day: mapToAPIDateTime(dayjs()),
                    openingMode: OpeningModeEnum.CLOSED,
                    intervals: [],
                  },
                ],
              });
              form.setFieldValue(names.customerDeliverySlots, {
                dayWithInterval: [
                  {
                    day: mapToAPIDateTime(dayjs()),
                    openingMode: OpeningModeEnum.CLOSED,
                    intervals: [],
                  },
                ],
              });
            }
          }}
        >
          <HorizontalFieldValue
            label={tr(`MultiEditSupplierNotificationSidebar.nakladka`, `Nakladka`)}
            value={
              <>
                <ManageTimeslot
                  entityId={null}
                  entityType={null}
                  timeslot={form.values.supplierDeliverySlots}
                  onChange={v => {
                    form.setFieldValue(names.supplierDeliverySlots, v);
                  }}
                  noOpeningHours={true}
                  validationErrors={form.validationErrors}
                  validationPrefix={'supplierDeliverySlots.'}
                />
              </>
            }
          />
          <HorizontalFieldValue
            label={tr(`MultiEditSupplierNotificationSidebar.vykladka`, `Vykladka`)}
            value={
              <>
                <ManageTimeslot
                  entityId={null}
                  entityType={null}
                  timeslot={form.values.customerDeliverySlots}
                  onChange={v => {
                    form.setFieldValue(names.customerDeliverySlots, v);
                  }}
                  noOpeningHours={true}
                  validationErrors={form.validationErrors}
                  validationPrefix={'customerDeliverySlots.'}
                />
              </>
            }
          />
        </Section>
        <Section
          label={tr(`MultiEditSupplierNotificationSidebar.changeDate`, `Change date`)}
          showEnable={true}
          onVisibilityChanged={v => {
            form.setFieldValue(names.loadingDates, null);
            form.setFieldValue(names.loadingDates, null);
            form.setFieldValue(names.overwriteDatesWithOpeningTimes, v);
          }}
        >
          <Note>
            {tr(`MultiEditSupplierNotificationSidebar.exceptionsNotTaken`, `Possible exceptions in the opening time of the locations are not taken into account`)}
          </Note>
          <HorizontalFieldValue
            label={''}
            value={
              <>
                <RR>
                  <RC large={12} medium={12} small={12}>
                    <HorizontalFieldValue
                      label={tr(`CreateSupplierNotification.supplierTimeslot`, `Supplier timeslot`)}
                      value={
                        <>
                          <Calendar
                            value={form.values.loadingDates}
                            selectionMode={'range'}
                            locale={'cs'}
                            dateFormat={shortDateFormat}
                            onChange={v => form.setFieldValue(names.loadingDates, v.value)}
                          />
                        </>
                      }
                    />
                  </RC>
                  <RC large={12} medium={12} small={12}>
                    <HorizontalFieldValue
                      label={tr(`CreateSupplierNotification.customerTimeslot`, `Customer timeslot`)}
                      value={
                        <>
                          <Calendar
                            value={form.values.unloadingDates}
                            selectionMode={'range'}
                            locale={'cs'}
                            dateFormat={shortDateFormat}
                            onChange={v => form.setFieldValue(names.unloadingDates, v.value)}
                          />
                        </>
                      }
                    />
                  </RC>
                </RR>
              </>
            }
          />
        </Section>
        <Section
          label={tr(`MultiEditSupplierNotificationSidebar.overrideLabels`, `Override labels`)}
          showEnable={true}
          onVisibilityChanged={v => {
            form.setFieldValue(names.labels, []);
            form.setFieldValue(names.overwriteLabels, v);
          }}
        >
          <LabelsControlled
            entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
            value={form.values.labels}
            onChange={v => {
              form.setFieldValue(names.labels, v);
            }}
          />
        </Section>
      </RingilSidebar>
    );
  };

  return { MultiEditSupplierNotificationSidebar, setVisible };
};
