import React from 'react';
import styled from 'styled-components';
import { RowWithSpaceBetween } from '@components/styles.tsx';

interface ComponentProps {
  iconButton: React.ReactNode;
  text: string;
  onClick?: () => void;
}

const PanelWithTextAndIcon: React.FC<ComponentProps> = ({ iconButton, text, onClick }) => {
  return (
    <Panel onClick={e => (onClick ? onClick() : {})}>
      <RowWithSpaceBetween>
        <Text>{text}</Text>
        {iconButton}
      </RowWithSpaceBetween>
    </Panel>
  );
};

const Panel = styled.div`
  border-radius: 0px;
  background-color: var(--ringil3-base-secondary-color);
  margin: 0.5rem 0;
  padding: 1rem 1rem;
  flex: 1;
  cursor: pointer;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--ringil3-brand-color);
`;

export default PanelWithTextAndIcon;
