import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgManipulation from '@components/ringil3/icons/Manipulation';

interface ComponentProps {
  loadingTypes: string[];
}

const ManipulationTag: React.FC<ComponentProps> = ({ loadingTypes }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonTag
        label={tr(`ManipulationTag.manipulation`, `Manipulace`)}
        labelNote={loadingTypes.map(t => tr(`global.manipulation.${t}`, `global.manipulation.${t}`)).join(' | ')}
        icon={<SvgManipulation />}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
};

export default ManipulationTag;
