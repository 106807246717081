import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import TemplateEdit from '@app/pages/customer/TemplateEdit/TemplateEdit.tsx';

const PageTemplateEdit = () => {
  const { t } = useTranslation('common');

  return (
    <Layout title={t(`RouteDetail.templateDetail`, `Detail trasy`)}>
      {/*
        // @ts-ignore*/}
      <Card>
        <TemplateEdit />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(PageTemplateEdit);
