import { useContext, useEffect, useState } from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useResponsiblePersons = () => {
  const [responsiblePersons, setResponsiblePersons] = useState<LovDTO[]>([]);
  const { cuLovResponsiblePersons } = useContext(ApiContext); // LovDTO

  useEffect(() => {
    cuLovResponsiblePersons(d => {
      setResponsiblePersons(d);
    });
  }, []);

  return responsiblePersons;
};

export default useResponsiblePersons;
