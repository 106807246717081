import React, { PropsWithChildren, useContext, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosResponse } from 'axios';
import { logsteoApiGenerator, LogsteoApiType } from './logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../components/auth/ToastContext.tsx';
import { useSearchParams } from 'react-router-dom';

const resolveQueryParams = (params: any[]) => {
  const retObject = {};
  params.forEach(item => {
    Object.keys(item).forEach(key => {
      // @ts-ignore
      retObject[key] = item[key];
    });
  });

  return retObject;
};

interface Error {
  message: string;
  name: string;
  stack: string;
  config: {
    url: string;
    method: string;
    data: string;
    headers: { [key: string]: string };
  };
}

interface Callbacks {
  onError?: (error: Error) => void;
  onConflict?: (conflictInfo: any) => void;
}

//@ts-ignore
export const ApiContext = React.createContext<LogsteoApiType>(undefined);

export const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();
  const frontendservicepublicendpoint = '/services/frontend-service';
  const frontendbehost = window.location.origin;
  const { i18n } = useTranslation('common');
  const toastRef = useRef(null);
  const [loading, setLoading] = useState([]);
  const { t } = useTranslation();
  const { showToastMessage } = useContext(ToastContext);

  const [seachParams, setSearchParams] = useSearchParams();

  const runAs = seachParams.get('runAs');

  const handleError = (methodName: string, error: Error, payload?: any) => {
    console.log(`error: ${JSON.stringify(error)}`);
    showToastMessage(
      t(`api.errorApi`, `Nastala chyba při komunikaci s API`),
      <>
        <div className="toast-wrapper">
          <div>{error.message}</div>
          <div>
            <strong>{methodName}</strong> - {error?.config?.method} - {error?.config?.url}
          </div>
          {Object.keys(payload).length > 0 && (
            <>
              <div>Payload</div>
              <div>
                <pre className="long-error-pre">{JSON.stringify(payload, null, 2)}</pre>
              </div>
            </>
          )}
        </div>
      </>,
      'error',
      30000,
    );
  };

  const isSuccess = (response: AxiosResponse) => {
    return response.status >= 200 && response.status < 300 && response.data.errorType === null;
  };

  const isConflict = (response: AxiosResponse) => {
    if (response.status >= 200 && response.status < 300 && response.data.errorType === 'CONFLICT') {
      return true;
    }
    return false;
  };

  const logsteoApiv2 = logsteoApiGenerator(frontendbehost + frontendservicepublicendpoint, i18n, handleError, getIdTokenClaims, runAs);

  return (
    <ApiContext.Provider value={{ ...logsteoApiv2 }}>
      <>{children}</>
    </ApiContext.Provider>
  );
};
