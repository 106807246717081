import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { QuoteDetailOutDTO } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import { useParams, useSearchParams } from 'react-router-dom';
import Language from '@components/obsolete/Language/Language.tsx';
import { QuickActionFixedPrice } from '@app/pages/carrier/quick-actions/QuickActionFixedPrice.tsx';
import { QuickActionSpotDetail } from '@app/pages/carrier/quick-actions/QuickActionSpotDetail.tsx';

interface ComponentProps {}

type ParsedIds = [string, string];

const QuickActionDemandDetail: React.FC<ComponentProps> = () => {
  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const action = searchParams.get('action');
  const code = searchParams.get('code');

  const [companyId, applicationId] = parseQuickMongoIdOrUuid(id);
  const [quoteDetail, setQuoteDetail] = useState<QuoteDetailOutDTO>();
  const { caGetDemandDetail, getInheritCompanySettings } = useContext(ApiContext);
  const [settings, setSettings] = useState(undefined);

  function parseQuickMongoIdOrUuid(inputString: string): ParsedIds {
    const tokens = inputString.split('-');
    let companyId = '';
    let applicationId = '';

    if (tokens.length > 0) {
      // Check if the first token has 24 characters (MongoID)
      if (tokens[0].length === 24) {
        companyId = tokens[0];
        applicationId = tokens.slice(1).join('-');
      }
      // Check if the first token has 8 characters (UUID format)
      else if (tokens[0].length === 8 && tokens.length >= 5) {
        companyId = tokens.slice(0, 5).join('-'); // UUID is made up of the first 5 tokens
        applicationId = tokens.slice(5).join('-'); // The rest is the FV code
      }
    }

    return [companyId, applicationId];
  }

  useEffect(() => {
    if (!isNullOrUndefined(code)) {
      // FIXME: ENUM
      getInheritCompanySettings(
        { identifier: 'LOCATION_DISPLAY_PATTERN' },
        companyId,
        'CUSTOMER',
        data => {
          setSettings({ LOCATION_DISPLAY_PATTERN: data.payload });
        },
        code,
      );
      caGetDemandDetail(
        companyId,
        applicationId,
        data => {
          setQuoteDetail(data);
        },
        code,
      );
    }
  }, [code]);

  return (
    <>
      <Language>
        {/*// @ts-ignore*/}
        <Card>
          {quoteDetail && settings && (action === 'confirm' || action === 'reject') && (
            <QuickActionFixedPrice
              action={action}
              code={code}
              applicationId={applicationId}
              quoteDetail={quoteDetail}
              locationDisplayPattern={settings.LOCATION_DISPLAY_PATTERN}
            />
          )}
          {quoteDetail && settings && (action === 'detail' || action === 'reconfirm') && (
            <QuickActionSpotDetail
              action={action}
              code={code}
              applicationId={applicationId}
              quoteDetail={quoteDetail}
              locationDisplayPattern={settings.LOCATION_DISPLAY_PATTERN}
            />
          )}
        </Card>
      </Language>
    </>
  );
};

export default QuickActionDemandDetail;
