import { DayEnum, NotificationEntityInDTO, NotificationEntityInfoOutDTO, UserInfoOutDTO } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';

export const isEntityOwner = (loggedUser: UserInfoOutDTO, customerId: string) => loggedUser != null && customerId != null && loggedUser.companyId == customerId;

export const toUIMapNotifyData = (notifyData: NotificationEntityInfoOutDTO): NotificationEntityInDTO => {
  return {
    notifyUsersDependsOnRole: notifyData.notifyUsersDependsOnRole,
    userIdsToNotify: notifyData.usersToNotify ? notifyData.usersToNotify.map(t => t.id) : [],
    responsiblePersonId: notifyData.responsiblePerson?.id,
  };
};

/*
export const fromUIDayWithInteral = (timeslot: DayWithInterval[]): SupplierManifestGlobalTimeslots => {
  if (isNullOrUndefined(timeslot)) return { daysWithIntervals: [] };

  return {
    daysWithIntervals: timeslot
      .filter((t) => !t.closed)
      .map((t) => {
        return { day: t.day.toISOString(), intervals: t.intervals } as SupplierManifestDaysWithIntervalsDTO;
      }),
  };
};
*/

/*export const toUIDayWithInterval = (timeslot: SupplierManifestGlobalTimeslots): DayWithInterval[] => {
  if (isNullOrUndefined(timeslot) || timeslot.daysWithIntervals.length == 0) return null;

  return timeslot.daysWithIntervals.map((t) => {
    return {
      intervals: t.intervals.map((t2) => {
        return { since: t2.since, till: t2.till };
      }),
      day: mapFromAPIDateTime(t.day),
      closed: false,
    } as DayWithInterval;
  });
};*/

export const makeInterval = (dates: Date[]): Date[] => {
  if (isNullOrUndefined(dates)) return [];
  if (dates.length == 1) return [dates[0], dates[0]];
  return [dates[0], dates[dates.length - 1]];
};

export const toBoolean = (value: any) => {
  if (value === true) return true;
  if (value === false) return false;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return false;
};

const mapToDayEnum = (number: number): DayEnum => {
  switch (number) {
    case 1:
      return DayEnum.MONDAY;
    case 2:
      return DayEnum.TUESDAY;
    case 3:
      return DayEnum.WEDNESDAY;
    case 4:
      return DayEnum.THURSDAY;
    case 5:
      return DayEnum.FRIDAY;
    case 6:
      return DayEnum.SATURDAY;
    case 0:
      return DayEnum.SUNDAY;
  }
};
/*export const generateDefaultSlots = (openingHoursSplit: CompanyLocationRampOpeningHourOutDTO[], d: Dayjs) => {
  if (openingHoursSplit && openingHoursSplit.length > 0) {
    const intervals = openingHoursSplit.find((t) => t.day === mapToDayEnum(d.day()));

    // neni oteviraci doba
    if (isNullOrUndefined(intervals)) return { day: d, closed: true, intervals: [] };

    if (intervals?.isAllDay) {
      return { day: d, closed: false, intervals: [{ since: '00:00', till: '23:59' }] };
    }

    if (intervals) {
      const ret = intervals.intervals.map((t) => {
        return {
          since: toTime(mapFromAPIDateTime(t.since)),
          till: toTime(mapFromAPIDateTime(t.till)),
        } as SinceTill;
      });
      return ret ? { day: d, closed: false, intervals: ret } : { day: d, closed: true, intervals: [] };
    } else {
      return { day: d, closed: false, intervals: [{ since: '00:00', till: '23:59' }] };
    }
  } else {
    return { day: d, closed: false, intervals: [{ since: '00:00', till: '23:59' }] };
  }
};*/
