import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ACLInDTO, LovDTO, ModulesEnum, VisibilityEnum } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { Col, WithGap } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  acl: ACLInDTO;
  moduleTypes: ModulesEnum[];
  onChange: (acl: ACLInDTO) => void;
}

const InputACL: React.FC<ComponentProps> = ({ acl, moduleTypes, onChange }) => {
  const { tr } = useTranslationLgs();
  const { lovAllCompaniesByInheritedModules } = useContext(ApiContext);
  const [companies, setCompanies] = useState<LovDTO[]>();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    lovAllCompaniesByInheritedModules({ modules: moduleTypes }, data => {
      setCompanies(data);
    });
  }, [moduleTypes]);

  const names = proxiedPropertiesOf<ACLInDTO>();

  const generateVisibilityNoteText = () => {
    switch (acl.visibility) {
      case VisibilityEnum.PRIVATE:
        return tr('InputACL.companyOnly', 'Only for {{companyName}}', {
          companyName: loggedUser.company,
        });
      case VisibilityEnum.PUBLIC:
        return tr('InputACL.companyAndAll', '{{companyName}} and all cooperation partners', {
          companyName: loggedUser.company,
        });
      case VisibilityEnum.VISIBLE_TO_COMPANIES:
        return tr('InputACL.companyAndSelectedPartners', '{{companyName}} and the selected partners', {
          companyName: loggedUser.company,
        });
    }
  };

  const itemTemplate = (visibility: VisibilityEnum) => {
    switch (visibility) {
      case VisibilityEnum.PRIVATE:
        return (
          <WithGap>
            <span className={'pi pi-lock'}></span>
            {tr(`InputACL.internal`, `Internal`)}
          </WithGap>
        );
      case VisibilityEnum.PUBLIC:
        return <div>{tr(`InputACL.public`, `Public`)}</div>;
      case VisibilityEnum.VISIBLE_TO_COMPANIES:
        return (
          <WithGap>
            <span className={'pi pi-unlock'}></span>
            {tr(`InputACL.selected`, `Selected companies`)}
          </WithGap>
        );
    }
  };

  return (
    <Col>
      {loggedUser && (
        <>
          <HorizontalFieldValue
            label={tr('InputACL.visibility', 'Visibility')}
            value={
              <Dropdown
                value={acl.visibility}
                options={Object.keys(VisibilityEnum)}
                onChange={v => onChange({ ...acl, visibility: v.value })}
                itemTemplate={itemTemplate}
                valueTemplate={itemTemplate}
              />
            }
            noteText={generateVisibilityNoteText()}
          />
          {acl.visibility === VisibilityEnum.VISIBLE_TO_COMPANIES && (
            <HorizontalFieldValue
              label={tr('InputACL.visibilityForPartners', 'Visibility for partners')}
              value={
                <MultiSelect
                  value={acl.visibleToCompanies}
                  options={companies}
                  optionLabel={'name'}
                  filter={true}
                  onChange={v => onChange({ ...acl, visibleToCompanies: v.value })}
                  placeholder={tr('InputACL.selectPartnerOrPartners', 'Select partner or partners')}
                />
              }
              noteText={tr('InputACL.beCarefulNoteText', 'Be careful that the settings have an impact on the Visibility of each ramp.')}
            />
          )}
        </>
      )}
    </Col>
  );
};

export default InputACL;
