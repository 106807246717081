import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import ValidationDiv from '@utils/validation';
import { AttachmentUploadedOutDTO, EntityTypeEnum } from '@api/logsteo-api.v2';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ButtonRow, Col, RowWithSpaceBetween } from '@components/styles';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { ClearButton } from '@components/obsolete/Buttons/ClearButton/ClearButton.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  documents: AttachmentUploadedOutDTO[];
  entityType: EntityTypeEnum;
  entityId: string;
}
interface Form {
  emails: string[];
  subject: string;
  body: string;
}

const ShareDocumentDialog: React.FC<ComponentProps> = ({ visible, onHide, documents, entityType, entityId }) => {
  const { tr } = useTranslationLgs();
  const { cuGetAttachmentDataToSpecificEmails, cuSendAttachmentToSpecificEmail } = useContext(ApiContext);

  const form = useForm<Form>(
    yup.object().shape({
      emails: yup.array().of(yup.string().required().email()).required().min(1),
      subject: yup.string().required(),
      body: yup.string().required(),
    }),
    { emails: [''], subject: '', body: '' },
    d => save(d),
    false,
    false,
  );

  useEffect(() => {
    if (visible) {
      cuGetAttachmentDataToSpecificEmails(
        entityType,
        entityId,
        documents.map(t => t.attachmentId),
        d => {
          form.setValues({ subject: d.titleName, body: d.messageBody });
        },
      );
    }
  }, [visible]);

  const names = proxiedPropertiesOf<Form>();

  const save = (form: Form) => {
    cuSendAttachmentToSpecificEmail(
      entityType,
      entityId,
      {
        emailsToSendAttachment: form.emails,
        filesIds: documents.map(t => t.attachmentId),
        messageBody: form.body,
        titleName: form.subject,
      },
      () => {
        onHide();
      },
    );
  };

  const formNames = proxiedPropertiesOf<Form>();
  const formFieldName = (index: number, name: keyof Form) => `${name}[${index}]`;

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog visible={visible} onHide={onHide} style={{ width: '450px' }} header={tr('ShareDocumentDialog.shareDocumentViaEMail', 'Share document via e-mail')}>
        <HorizontalFieldValue label={tr('ShareDocumentDialog.sending', 'Sending')} value={<div></div>} />
        <HorizontalFieldValue
          label={tr('ShareDocumentDialog.toWhom', 'Whom')}
          validationError={form.find(formNames.emails, true)}
          value={
            <div>
              {form.values.emails.map((email, emailIndex) => {
                return (
                  <RowWithSpaceBetween key={emailIndex}>
                    <InputText style={{ width: '100%' }} value={email} onChange={e => form.setFieldValue(formFieldName(emailIndex, 'emails'), e.target.value)} />
                    <ClearButton
                      onClick={() => {
                        if (form.values.emails.length > 1)
                          form.setFieldValue(
                            formNames.emails,
                            form.values.emails.filter((_, i) => i !== emailIndex),
                          );
                      }}
                    />
                  </RowWithSpaceBetween>
                );
              })}
            </div>
          }
        />
        <Button
          label={tr('ShareDocumentDialog.+ AnotherEmail', '+ Another email')}
          onClick={e => form.setFieldValue(formNames.emails, [...form.values.emails, ''])}
        ></Button>

        <HorizontalFieldValue
          label={tr('ShareDocumentDialog.message', 'Message')}
          value={
            <Col>
              <InputText value={form.values.subject} onChange={e => form.setFieldValue(names.subject, e.target.value)} />
              <ValidationDiv errors={form.validationErrors} path={'subject'} />
              <InputTextarea value={form.values.body} rows={6} onChange={e => form.setFieldValue(names.body, e.target.value)} />
              <ValidationDiv errors={form.validationErrors} path={'body'} />
            </Col>
          }
        />
        <ButtonRow>
          <Button label={tr(`ShareDocumentDialog.save`, `Send`)} onClick={e => form.validateAndSend()} />
          <Button label={tr(`ShareDocumentDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonRow>
      </Dialog>
    </>
  );
};

export default ShareDocumentDialog;
