import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation';
import SvgTagPlus from '@components/ringil3/icons/TagPlus';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';

interface ComponentProps {
  valueCodes: string[];
  onChange: (v: LovWithoutIdDTO[]) => void;
  expeditionId?: string;
  onReload: () => void;
}

interface SpecialRequirementsProps {}

const RequirementsTag: React.FC<ComponentProps> = ({ valueCodes, onChange, expeditionId, onReload }) => {
  const { tr } = useTranslationLgs();
  const { lovSpecialRequirements, saveSpecialRequirementsForExpedition } = useContext(ApiContext);
  const [allRequirements, setAllRequirements] = useState<LovWithoutIdDTO[]>();
  const [internalValue, setInternalValue] = useState(valueCodes);
  const [apiReqCount, setApiReqCount] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    lovSpecialRequirements(d => {
      if (ac.signal.aborted) return;

      setAllRequirements(d);
    });

    return () => {
      ac.abort();
    };
  }, []);

  const saveReq = () => {
    setApiReqCount(c => c + 1);
    saveSpecialRequirementsForExpedition(expeditionId, { specialRequirementCodes: internalValue }, () => {
      setApiReqCount(c => c - 1);
      setOpen(false);
      onReload();
    });
  };

  if (isNullOrUndefined(allRequirements)) return <></>;

  const SpecialRequirements: React.FC<SpecialRequirementsProps> = ({}) => {
    return (
      <>
        <Grid>
          {allRequirements?.map((req, index) => {
            return (
              <InputCheckbox
                key={index}
                value={isNotNullOrUndefined(internalValue.find(t => t === req.code))}
                onChange={(e, checked) => {
                  if (checked) {
                    setInternalValue([...internalValue, req.code]);
                  } else {
                    setInternalValue([...internalValue.filter(t => t !== req.code)]);
                  }
                }}
                label={req.name}
              />
            );
          })}
        </Grid>
      </>
    );
  };

  return (
    <>
      <ButtonTag
        variant={internalValue?.length > 0 ? 'defined' : 'empty'}
        icon={internalValue?.length > 0 ? <SvgYellowExclamation /> : null}
        label={tr(`RequirementsTag.specialRequirements`, `Carrier requirements`)}
        labelNote={<MultipleValues values={allRequirements?.filter(t => valueCodes.indexOf(t.code) >= 0).map(t => t.name)}></MultipleValues>}
        actionIcon={internalValue?.length == 0 ? <SvgTagPlus /> : <SvgTagEdit />}
        actionPane={<SpecialRequirements />}
        onSave={() => {
          saveReq();
        }}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1.4rem;
  column-gap: 2rem;
`;

export default RequirementsTag;
