import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge.tsx';
import COText from '@app/pages/COText/COText.tsx';
import RingilForEntitySidebar from '@components/ringil3/Sidebar/RingilForEntitySidebar.tsx';
import { EntityTypeEnum } from '@api/logsteo-api.v2.tsx';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';

import * as yup from 'yup';

interface ComponentProps {
  supplierNotificationId: string;
  applicationId: string;
  onSaved: () => void;
}

interface Form {
  note?: string;
}

export const useCreateEditDAPNoteSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditDAPNoteSidebar: React.FC<ComponentProps> = ({ supplierNotificationId, applicationId, onSaved }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [apiRequest, setApiRequest] = useState(false);
    const { detailDAPSupplierNotification, updateNoteDAPSupplierNotification } = useContext(ApiContext);

    const form = useForm<Form>(yup.object().shape({}), { note: null }, (d) => save(d), false, false);

    const save = (values: Form) => {
      updateNoteDAPSupplierNotification(supplierNotificationId, { note: form.values.note }, () => {
        setVisible(false);
        onSaved();
      });
    };

    useEffect(() => {
      const ac = new AbortController();

      detailDAPSupplierNotification(
        supplierNotificationId,
        (d) => {
          if (ac.signal.aborted) return;
          form.setFieldValue('note', d.note);
        },
        null,
        { onValidationFailed: setBeValidationErrors }
      );
      return () => {
        ac.abort();
      };
    }, [supplierNotificationId]);

    return (
      <>
        <RingilForEntitySidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
          <RowWithSpaceBetween>
              <COText label={tr("EditDAPNoteSidebar.poznamkaKOdesilateli", "Poznámka k odesílateli")} />
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map((t) => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}
          entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
          applicationId={applicationId}>
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <RingilTextArea value={form.values.note} onChange={(v) => form.setFieldValue('note', v)} maxLength={255} />
          </div>
        </RingilForEntitySidebar>
      </>);

  };

  return { EditDAPNoteSidebar, visible, setVisible };
};