import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Layout from '@app/layout/Layout.tsx';
import CarrierGroupDetail from '@components/obsolete/customer/carriergroups/CarrierGroupDetail.tsx';

const CarrierGroupDetailPage = () => {
  const { id } = useParams();

  const { t } = useTranslation('common');

  return (
    <>
      {id && (
        <Layout title={t(`CarrierGroup.title`, `Detail skupiny`)}>
          <CarrierGroupDetail carrierGroupId={id as string} />
        </Layout>
      )}
    </>
  );
};

export default withAuthenticationRequired(CarrierGroupDetailPage);
