import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  value: string;
  onChange: (localDate: string) => void;
  timeZone?: string;
  onChangeTimeZone?: (timeZone: string) => void;
}

const InputDateAndTime: React.FC<ComponentProps> = ({ value, onChange, timeZone, onChangeTimeZone }) => {
  const { getAvailableTimezones } = useContext(ApiContext);
  const [timezones, setTimezones] = useState<String[]>([]);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    getAvailableTimezones(d => {
      setTimezones(d);
    });
  }, []);

  return (
    <Grid>
      <input
        style={{ width: '100%', padding: '0.5rem', color: 'var(--ringil3-gr4)' }}
        type="datetime-local"
        onChange={e => {
          onChange(e.target.value);
        }}
        value={value}
      />
      <Dropdown
        value={timeZone}
        options={timezones}
        filter={true}
        onChange={e => onChangeTimeZone(e.value)}
        placeholder={tr(`InputDateAndTime.selectATimezone`, `Select a timezone`)}
      />
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export default InputDateAndTime;
