import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { ReservationCoordinates } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined } from '@utils/utils';
import { ClickableLink } from '@components/ringil3/styles';
import useNavigation from '@hooks/useNavigation.tsx';

interface ComponentProps {
  linkedReservation?: ReservationCoordinates;
}

const LinkedResBadge: React.FC<ComponentProps> = ({ linkedReservation }) => {
  const { tr } = useTranslationLgs();
  const navigation = useNavigation();

  return (
    <>
      {isNotNullOrUndefined(linkedReservation) && (
        <ClickableLink href={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(linkedReservation.id))}>
          <Badge filled={true} value={linkedReservation.applicationId} label={tr(`LinkedResBadge.reservation`, `Reservation`)} highlightedValue={true}></Badge>
        </ClickableLink>
      )}
    </>
  );
};

export default LinkedResBadge;
