import { formatPrice } from '@utils/utils';
import styled from 'styled-components';

const StyledItem = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledName = styled.div`
  @media screen and (max-width: 1200px) {
    width: 68%;
  }
`;

const StyledPrice = styled.div``;

interface ComponentProps {
  name: string;
  price: number;
  currency: string;
}

export const ExpeditionCostItem: React.FC<ComponentProps> = ({ name, price, currency }) => {
  return (
    <StyledItem>
      <StyledName>{name}</StyledName>
      <StyledPrice>{formatPrice(price, currency)}</StyledPrice>
    </StyledItem>
  );
};
