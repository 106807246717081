import React, { useContext, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { Trans, useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

export interface PickSidebarData {
  visible: boolean;
  company?: string;
  quoteId?: string;
  isReconfirmedNeeded: boolean;
}

interface ComponentProps {
  pickSidebarData: PickSidebarData;
  onContinue: (quoteId: string, isReconfirmedNeeded: boolean) => void;
  onBackButton: () => void;
  customerId: string;
  applicationId: string;
  quoteId: string;
}

const PickSidebar: React.FC<ComponentProps> = ({ pickSidebarData, customerId, applicationId, onContinue, onBackButton, quoteId }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const [isReconfirmedNeeded, setIsReconfirmedNeeded] = useState(pickSidebarData.isReconfirmedNeeded);
  const { cuAcceptDemand } = useContext(ApiContext);
  const [loading, setLoading] = useState(false);

  const pickQuote = () => {
    setLoading(true);
    cuAcceptDemand(
      customerId,
      applicationId,
      quoteId,
      { isReconfirmedNeeded },
      () => {
        onContinue(quoteId, isReconfirmedNeeded);
      },
      null,
      {
        onValidationFailed: errors => {
          setLoading(false);
          setBeValidationErrors(errors);
        },
      },
    );
  };

  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  return (
    <>
      {/*//
      @ts-ignore*/}
      <Sidebar visible={pickSidebarData.visible} position="right" baseZIndex={1000000} onHide={() => onBackButton()} className="sidebar-modal" style={{ width: '750px' }}>
        <h4>{t('customerExpeditionDetail.tabCarrierPending.pickAPriceQuote', 'Pick a price quote')}</h4>
        <BackendValidationComponent />
        <p>
          <Trans i18nKey="customerExpeditionDetail.tabCarrierPending.pickAPriceQuoteWarning" values={{ company: pickSidebarData.company }}></Trans>
        </p>

        <Row>
          <InputSwitch className="p-mr-2" checked={isReconfirmedNeeded} onChange={v => setIsReconfirmedNeeded(v.value)} />
          <span>{tr(`Pick.reconfirmationLabel`, `Požádat dopravce o potvrzení přijetí přepravy`)}</span>
          <Button
            type="button"
            label="?"
            tooltip={tr(
              `Pick.reconfirmationTooltip`,
              `Dopravci bude odeslán potvrzující e-mail se žádostí o potvrzení přijetí přepravy. Chybějící potvrzení neblokuje další průběh expedice.`,
            )}
            className="p-button-text p-ml-2 p-mb-2 p-mb-2"
            tooltipOptions={{ position: 'top' }}
          />
        </Row>

        <ButtonLayout>
          <Button label={t('global.continue', 'Pokračovat')} className="p-mr-2" onClick={() => pickQuote()} loading={loading} />
          <Button label={t('global.back', 'Zpět')} className="p-button-text" onClick={() => onBackButton()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const createInitialPickComponentData = (): PickSidebarData => {
  return { visible: false, isReconfirmedNeeded: false };
};

export default PickSidebar;
