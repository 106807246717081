import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { GetInheritedCompanyOutDTO, PermissionTypesEnum } from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Note, RingilHeading } from '@components/styles.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {}

const InheritedCompanies: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { getInheritedCompanies } = useContext(ApiContext);
  const [inheritedCompanies, setInheritedCompanies] = useState<GetInheritedCompanyOutDTO[]>([]);

  const { Table, Column } = useCreateTable<GetInheritedCompanyOutDTO>();
  const { loggedUser, hasPermission } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    getInheritedCompanies(d => {
      setInheritedCompanies(d);
    });
  }, []);

  return (
    <>
      <div className={'flex flex-col gap-4'}>
        <BreadCrumbComponent items={[{ label: tr(`InheritedCompanies.inheritedCompanies`, `Inherited companies`) }]} />
        <COHeadline title={tr(`InheritedCompanies.inheritedCompanies`, `Inherited companies`)} variant={'h1'} />
        <Table value={inheritedCompanies}>
          <Column
            header={tr(`InheritedCompanies.name`, `Name`)}
            body={(row: GetInheritedCompanyOutDTO) => (
              <>
                <div>{row.name}</div>
                <Note>{row.companyId}</Note>
              </>
            )}></Column>
          <Column header={tr(`InheritedCompanies.modules`, `Modules`)} body={(row: GetInheritedCompanyOutDTO) => <div>{row.modules}</div>}></Column>
          <Column header={tr(`InheritedCompanies.userName`, `User name`)} body={(row: GetInheritedCompanyOutDTO) => <div>{row.userName}</div>}></Column>
          <Column
            width={600}
            header={tr(`InheritedCompanies.userEmailPhone`, `E-mail / Phone`)}
            body={(row: GetInheritedCompanyOutDTO) => (
              <div className={'flex flex-col'}>
                <div>{row.userEmail}</div>
                <div>{row.userPhone}</div>
                {hasPermission([PermissionTypesEnum.PERMISSION_ADMIN]) && (
                  <a href={`/?runAs=${row.userId}`} target={'_blank'}>
                    Login as user
                  </a>
                )}
              </div>
            )}></Column>
        </Table>
      </div>
    </>
  );
};

export default InheritedCompanies;
