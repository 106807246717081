import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import useMassAction from '@hooks/useMassAction/useMassAction2.tsx';
import { Checkbox } from 'primereact/checkbox';
import Pagination from '@components/framework/paging/Pagination.tsx';

export const useCreateTable = <T,>() => {
  type ColumnProps = {
    header: string;
    body: (row: T) => ReactElement;
    fixed?: boolean;
    width?: number;
  };

  const Column: React.FC<ColumnProps> = ({ header, body, fixed, width }) => null;

  type TableProps = {
    value: T[];
    children: ReactElement<ColumnProps>[];
    showMassActions?: boolean;
    rows?: number;
    paginator?: boolean;
    totalRecords?: number;
    onPage?: (event: { page: number; rows: number }) => void;
    selectedPage?: number;
    bottomRow?: ReactElement<HTMLTableRowElement>;
  };

  const Table: React.FC<TableProps> = ({ value, children, showMassActions = false, paginator = false, rows, selectedPage, onPage, totalRecords, bottomRow }) => {
    const columns = React.Children.toArray(children) as ReactElement<ColumnProps>[];

    const fixedColumns: { index: number; left: number }[] = [];
    let cumulativeWidth = 0;

    columns.forEach((column, index) => {
      const colWidth = column.props.width || 0;

      if (column.props.fixed) {
        fixedColumns.push({ index, left: cumulativeWidth });
      }

      cumulativeWidth += colWidth;
    });

    const { MassActionCheckboxAndState, toggleCheckbox, isSelected, setSelectedObjects, selectedObjects, clear } = useMassAction<T>(
      (a, b) => {
        return a === b;
      },
      selectedObjects => <></>,
    );

    const isAtleasOneFixed = columns.some(column => column.props.fixed);

    return (
      <div>
        {showMassActions === true && (
          <div className={'bg-content-quaternary  rounded-tl-[10px] rounded-tr-[10px] overflow-clip'}>
            <MassActionCheckboxAndState
              onSelectAll={() => {
                setSelectedObjects(value);
              }}
            />
          </div>
        )}
        <div className="overflow-auto">
          <table className={'border-separate border-spacing-0 min-w-full radius-[10px] relative table-fixed'}>
            <thead>
              <tr className={'bg-content-quaternary'}>
                {showMassActions === true && (
                  <th className={twMerge(clsx('py-4 px-0.5 bg-content-quaternary w-[45px] ', { 'sticky left-0  z-10': isAtleasOneFixed }))}></th>
                )}

                {columns.map((column, rowIndex, columns) => {
                  const isLast = showMassActions === false && rowIndex === columns.length - 1;
                  const isFirst = showMassActions === false && rowIndex === 0;

                  const isFixed = column.props.fixed;
                  const fixedColumn = fixedColumns.find(col => col.index === rowIndex);

                  const thClasses = ['px-4 py-4 text-left bg-content-quaternary', isFixed ? 'sticky left-0  z-10' : ''].join(' ');

                  const thStyle = {
                    ...(isFixed ? { left: `${fixedColumn?.left + (showMassActions === true ? 25 : 0)}px` } : {}),
                    width: column.props.width ? `${column.props.width}px` : 'auto',
                    minWidth: column.props.width ? `${column.props.width}px` : 'auto',
                    maxWidth: column.props.width ? `${column.props.width}px` : 'auto',
                  };
                  return (
                    <th key={rowIndex} className={twMerge(clsx(thClasses, { 'rounded-tl-[10px]': isFirst }, { 'rounded-tr-[10px]': isLast }))} style={thStyle}>
                      {column.props.header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {bottomRow}
              {value?.map((row, rowIndex) => {
                return (
                  <React.Fragment key={rowIndex}>
                    <tr className="bg-white">
                      {showMassActions === true && (
                        <td className={twMerge(clsx('py-4 px-0.5 w-[40px] ', { 'sticky left-0  z-10': isAtleasOneFixed }))}>
                          <div className={'flex flex-row px-2'}>
                            <Checkbox
                              onChange={e => {
                                toggleCheckbox(row);
                              }}
                              checked={isSelected(row)}
                            />
                          </div>
                        </td>
                      )}

                      {columns.map((column, colIndex) => {
                        const isFixed = column.props.fixed;
                        const fixedColumn = fixedColumns.find(col => col.index === colIndex);

                        const tdStyle = {
                          ...(isFixed ? { left: `${fixedColumn?.left + (showMassActions === true ? 25 : 0)}px` } : {}),
                          width: column.props.width ? `${column.props.width}px` : 'auto',
                          minWidth: column.props.width ? `${column.props.width}px` : 'auto',
                          maxWidth: column.props.width ? `${column.props.width}px` : 'auto',
                        };

                        return (
                          <td key={colIndex} className={twMerge(clsx('py-4 px-4 w-0 ', { 'sticky left-0 bg-content-quaternary z-10': isFixed }))} style={tdStyle}>
                            {column.props.body(row)}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {paginator && (
            <div>
              <Pagination
                pageSize={rows}
                total={totalRecords}
                selectedPage={selectedPage}
                onPageChange={pageNumber => {
                  onPage({ page: pageNumber - 1, rows });
                }}
                onPageSizeChange={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return { Table, Column };
};
