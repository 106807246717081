import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useFormik } from 'formik';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import InputPricePlain, { UIPriceWithCurrency } from '@components/obsolete/Form/InputPricePlain/InputPricePlain.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputCarrierPlain from '@components/obsolete/InputCarrier/InputCarrierPlain.tsx';
import CheckboxWithLabelAndDescription from '@components/obsolete/Form/CheckboxWithLabelAndDescription/CheckboxWithLabel.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: () => void;
  companyId: string;
  applicationId: string;
}

interface Form {
  price: UIPriceWithCurrency;
  carrierId: string;
  informCarrier: boolean;
}

const ManuallyAssignWinCarrierSidemodal: React.FC<ComponentProps> = ({ companyId, applicationId, visible, onHide, onComplete }) => {
  const { tr } = useTranslationLgs();
  const { cuSetDirectAssignment } = useContext(ApiContext);
  const [loading, setLoading] = useState(false);

  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  const validationSchema = yup.object().shape({
    carrierId: yup.mixed().required(tr(`validation.required`, 'Value is required')),
    price: yup
      .object()
      .typeError(tr(`validation.required`, 'Value is required'))
      .shape({
        amount: yup.number().typeError(tr(`validation.required`, 'Value is required')).required(tr(`validation.required`, 'Value is required')),
        currencyCode: yup.string().required(tr(`validation.required`, 'Value is required')),
      }),
  });

  const form = useFormik<Form>({
    initialValues: { carrierId: null, price: null, informCarrier: false },
    validationSchema: validationSchema,
    onSubmit: data => {
      setLoading(true);
      cuSetDirectAssignment(
        companyId,
        applicationId,
        {
          carrierId: form.values.carrierId,
          price: form.values.price.amount,
          currency: form.values.price.currencyCode,
          informCarrier: form.values.informCarrier,
        },
        () => {
          setLoading(false);
          onComplete();
        },
        null,
        {
          onValidationFailed: v => {
            setLoading(false);
            setBeValidationErrors(v);
          },
        },
      );
    },
  });

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar position="right" baseZIndex={1000000} onHide={onHide} blockScroll={true} style={{ minWidth: '40rem' }} visible={visible}>
        <h2>{tr(`ManuallyAssignWinCarrier.assignCarrier`, `Assign carrier`)}</h2>
        <BackendValidationComponent />
        <form onSubmit={form.handleSubmit}>
          <HorizontalFieldValue
            label={tr(`ManuallyAssignWinCarrier.assignCarrier`, `Assign carrier`)}
            value={<InputCarrierPlain required={false} carrierId={form.values.carrierId} onChange={d => form.setFieldValue('carrierId', d)}></InputCarrierPlain>}
            validationError={form.errors.carrierId}
          />
          <HorizontalFieldValue
            label={tr(`ManuallyAssignWinCarrier.smluvenaCenaZaDopravu`, `Smluvena cena za dopravu`)}
            value={<InputPricePlain value={form.values.price} onChange={d => form.setFieldValue('price', d)} />}
          />

          <CheckboxWithLabelAndDescription
            checkboxLabel={tr(`ManuallyAssignWinCarrier.informCarrierCheckboxLabel`, `Informovat o přiřazení dopravce`)}
            checkBoxDescription={tr(`ManuallyAssignWinCarrier.informCarrierCheckboxDescription`, `Dopravci pošleme e-mail s podrobnostmi`)}
            value={form.values.informCarrier}
            onChange={d => form.setFieldValue('informCarrier', d)}
            disabled={false}
          />

          <ButtonRow>
            <Button label={tr(`ManuallyAssignWinCarrier.assign`, `Assign`)} type={'submit'} loading={loading} />
            <Button label={tr(`ManuallyAssignWinCarrier.cancel`, `Cancel`)} onClick={e => onHide()} />
          </ButtonRow>
        </form>
      </Sidebar>
    </>
  );
};

const ButtonRow = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export default ManuallyAssignWinCarrierSidemodal;
