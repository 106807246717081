import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ReservationDetail from '@components/ringil3/Features/Reservation/ReservationDetail';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { PermissionTypesEnum } from '@api/logsteo-api.v2';
import Layout from '@app/layout/Layout.tsx';
import { useParams } from 'react-router-dom';

interface ComponentProps {}

const ReservationDetailPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const { reservationId } = useParams();

  return (
    <>
      <Layout title={tr(`ReservationDetail.reservationDetail`, `Reservation detail`)} oneOfPermission={[PermissionTypesEnum.PERMISSION_TIMESLOT_READ]}>
        {/*<ReservationDetail reservationId={router.query['reservationId'] as string} />*/}
        <ReservationDetail reservationId={reservationId} />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(ReservationDetailPage);
