import React, { useContext, useRef } from 'react';
import { ApiContext } from '@api/api';
import { FileUpload } from 'primereact/fileupload';
import { AttachmentUploadedOutDTO } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  onUpload: (attachment: AttachmentUploadedOutDTO) => void;
}

const AddAttachment: React.FC<ComponentProps> = ({ onUpload }) => {
  const { uploadAttachment } = useContext(ApiContext);
  const { tr } = useTranslationLgs();

  const fileUpload = useRef(null);

  // @ts-ignore
  const uploadHandler = ({ files }) => {
    let counter = 0;
    // @ts-ignore
    files.forEach((file, fileIndex) => {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const dataURL = e.target.result;
        const fileName = file.name;
        uploadAttachment({ dataURL: dataURL as string, fileName }, d => {
          onUpload(d);
          counter++;
          if (counter == files.length) {
            fileUpload.current.clear();
          }
        });
      };
      fileReader.readAsDataURL(file);
    });
  };

  return (
    <>
      <FileUpload
        maxFileSize={10 * 1024 * 1024}
        ref={fileUpload}
        uploadHandler={e => uploadHandler(e)}
        mode="advanced"
        auto={true}
        name="document"
        chooseLabel={tr('AddAttachment.addAttachment', 'Add attachment')}
        customUpload={true}
        multiple={true}
        style={{ width: '100%' }}
      />
    </>
  );
};

export default AddAttachment;
