import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputSwitch } from 'primereact/inputswitch';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { mapCodeToCargoItem, resolveSpecialRequirement } from '@components/obsolete/customer/new-expedition/helpers';
import { CargoTemplateInDTO, ExpeditionTypeEnum, LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { CreateTemplateHandlerContext } from './CreateTemplateHandler';
import { Location } from './types';
import CreateDistributionTemplate from '../common/CreateDistributionTemplate';
import useCargoTypes from '@hooks/use-cargo-types/use-cargo-types';
import useValidation from '@hooks/validation-hook/useValidation';
import { stage4validation } from './validation';
import { translateQuantityCode, weightAndDimensions } from '@utils/utils';
import { OrderViewData, PageID } from '../new-expedition/types';
import { ApiContext } from '@api/api';
import { Toast } from 'primereact/toast';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import styled from 'styled-components';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { ExpeditionContactInfo } from '../common/ExpeditionContactInfo';
import LoadInfo from '@components/obsolete/CreateTemplate/components/LoadInfo';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import LoadInfoItem from '@components/obsolete/LoadDetails/LoadInfo/LoadInfoItem.tsx';
import Address from '@components/obsolete/Address/Address.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import CheckboxLayout from '@components/obsolete/CheckboxLayout/CheckboxLayout.tsx';
import CheckboxWrapper from '@components/obsolete/CheckboxWrapper/CheckboxWrapper.tsx';
import CheckboxItem from '@components/obsolete/CheckboxWrapper/CheckboxItem.tsx';
import { Panel } from 'primereact/panel';
import CardView from '@components/obsolete/CardView/CardView.tsx';
import SelectedTruckType from '@components/SelectedTruckType.tsx';
import InfoProvided from '@components/obsolete/InfoProvided/InfoProvided.tsx';
import InfoProvidedItem from '@components/obsolete/InfoProvided/InfoProvidedItem.tsx';
import AccordionTitle from '@components/obsolete/AccordionTitle/AccordionTitle.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import PanelCard from '@components/obsolete/PanelCard/PanelCard.tsx';
import { useNavigate } from 'react-router-dom';
import useNavigation from '@hooks/useNavigation.tsx';

interface ContactInfoProps {
  location: Location;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ location }) => {
  const { t }: { t: any } = useTranslation('common');
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">
            <img src="/images/contacts.svg" alt="" />
          </div>
          <div>
            <Label title={t(`CreateTemplateStep4.contactInformation`, `Contact information`)} />
            <LoadInfoItem name={location.contactName} phone={location.contactPhone} email={location.contactEmail} className="p-mb-3"></LoadInfoItem>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-5 p-lg-4">
        <Address company={`${location.name}, ${location.city}`} address={`${location.streetNr}, ${location.zipCode}`} />
      </div>
    </div>
  );
};

interface FTLLoadingUloadingDetailProps {
  index: number;
  size: number;
  carrierCode: string;
}

const FTLLoadingUloadingDetail: React.FC<FTLLoadingUloadingDetailProps> = ({ index, size, carrierCode = '-' }) => {
  const { t }: { t: any } = useTranslation('common');
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">
            <img className="w-[42px] mr-2" src={index < size - 1 ? '/images/icon_pickup.png' : '/images/icon_dropoff.png'} alt="" />
          </div>
          <div>
            <Label
              title={
                index == 0
                  ? t('CreateTeplateStep4.labelPickUp', 'Pickup')
                  : index < size - 1
                  ? t('CreateTeplateStep4.labelPickUpDropOff', 'Pickup/Dropoff')
                  : t('CreateTeplateStep4.labelDropOff', 'Dropoff')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface LoadingUloadingDetailProps {
  loading: boolean;
  order: OrderViewData;
  carrierCode?: string;
  isFirstElement?: boolean;
}

const LoadingUloadingDetail: React.FC<LoadingUloadingDetailProps> = ({ loading, order, carrierCode = '-', isFirstElement = true }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();

  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">{isFirstElement && <img src={loading ? '/images/icon_pickup.png' : '/images/icon_dropoff.png'} alt="" />}</div>
          <div>
            {isFirstElement && <Label title={loading ? t('CreateTemplateStep4.pickup', 'Pickup') : t('CreateTemplateStep4.dropoff', 'Dropoff')} />}
            <LoadInfoItem bookingRef={order.name} className="p-mb-3" />
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-5 p-lg-4">
        {order?.items?.map((orderItem, orderItemIndex) => {
          return (
            <LoadInfoList
              key={orderItemIndex}
              title={`${orderItem.cargoItemType.name} (${orderItem.quantity} ${translateQuantityCode(orderItem.quantityUnit, tr)})`}
              description={weightAndDimensions(orderItem.height, orderItem.length, orderItem.width, orderItem.weight, t)}
              stackable={orderItem.stackable}
              cargoMessage={orderItem.cargoMessage}
            />
          );
        })}
      </div>
    </div>
  );
};

interface OverviewSpecialRequirementsProps {
  selectedSpecialRequirements: string[];
}

const OverviewSpecialRequirements: React.FC<OverviewSpecialRequirementsProps> = ({ selectedSpecialRequirements }) => {
  const [specialRequirements, setSpecialRequirements] = useState<LovWithoutIdDTO[]>([]);
  const { lovSpecialRequirements } = useContext(ApiContext);
  const { t } = useTranslation('common');

  useEffect(() => {
    lovSpecialRequirements(data => {
      setSpecialRequirements(data);
    });
  }, []);

  return (
    <CheckboxLayout label={t(`CreateTemplateStep4.additionalRequirements`, `Additional requirements`)} className="p-mt-3">
      <CheckboxWrapper className="p-lg-7">
        {selectedSpecialRequirements
          ?.map(reqCode => resolveSpecialRequirement(reqCode, specialRequirements))
          .filter(sr => sr !== undefined)
          .map(requirement => (
            <CheckboxItem className="p-md-3">
              <Checkbox checked={true} className="p-mr-2" disabled />
              <label>{requirement.name}</label>
            </CheckboxItem>
          ))}
      </CheckboxWrapper>
    </CheckboxLayout>
  );
};

interface ComponentProps {}

const CreateTemplateStep4: React.FC<ComponentProps> = () => {
  const { t }: { t: any } = useTranslation('common');
  const responsiblePersons = useResponsiblePersons();
  const { cuCreateExpeditionTemplate } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();

  const {
    state,
    toggleCreateDistribution,
    changeDistributionType,
    changeDistributionPrice,
    changeDistributionSelectedItems,
    changeResponsiblePerson,
    changeSubscribers,
    changeDistributionIntervalHour,
    changeCurrency,
    changeAutomaticPublishing,
    clickOnBreadCrumb,
    changeInternalNote,
  } = useContext(CreateTemplateHandlerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveTemplate = () => {
    cuCreateExpeditionTemplate(
      {
        locations: state.locations.map(l => {
          return {
            id: l.id,
            fromAddressBookId: l.fromAddressBookId,
            city: l.city,
            name: l.name,
            contactName: l.contactName,
            contactEmail: l.contactEmail,
            contactPhone: l.contactPhone,
            country: l.country,
            pickupCode: l.carrierCode,
            loadedOrders:
              state.expeditionType === ExpeditionTypeEnum.LESS_TRUCK_LOAD
                ? l.loadingOrders.map(lo => {
                    return {
                      name: lo.name,
                      id: null,
                      cargos:
                        state.expeditionType === ExpeditionTypeEnum.LESS_TRUCK_LOAD
                          ? lo.items.map(c => {
                              return {
                                cargoItemType: c.cargoItemType.code,
                                quantity: c.quantity,
                                length: c.length,
                                height: c.height,
                                quantityUnit: c.quantityUnit,
                                stackable: c.stackable,
                                weight: c.weight,
                                weightMode: c.weightMode,
                                width: c.width,
                                cargoMessage: c.cargoMessage,
                              } as CargoTemplateInDTO;
                            })
                          : [],
                      loadingLocationId: lo.loadingLocationId,
                      unloadingLocationId: lo.unloadingLocationId,
                    };
                  })
                : [],
            postalCode: l.zipCode,
            streetNr: l.streetNr,
            openingHourDefinitions: l.openingHourDefinitions,
            /*timeslots: l.timeslots?.reduce((array, value) => {
              return [
                ...array,
                value.intervals.map(
                  (i) =>
                    ({
                      till: i.tillTimeUtc,
                      since: i.sinceTimeUtc,
                      dayOfWeek: value.dayOfWeek,
                    } as IntervalDefinitionTemplateInDTO),
                ),
              ].flat(1);
            }, [] as IntervalDefinitionTemplateInDTO[]),*/
          };
        }),
        distributionTemplate: state.distribution?.enabled
          ? {
              name: state.templateName,
              carriersAndGroups: state.distribution.selectedItems,
              distributionType: state.distribution.distributionType,
              currency: state.distribution.currency,
              intervalHour: state.distribution.intervalHour,
              price: state.distribution.price,
              shouldExpeditionAutomaticallyPublished: state.distribution.isPublished,
            }
          : null,
        name: state.templateName,
        cargoType: state.cargoType,
        note: state.carrierNote,
        loadingTypes: state.manipulationTypes,
        requirements: state.specialRequirements,
        responsiblePersonId: state.responsiblePerson?.id,
        expeditionType: state.expeditionType,
        truckType: state.truckType,
        internalCustomerNote: state.internalNote,
        subscribers: state.subscribers?.map(t => t.id),
      },
      () => {
        nav.navigate(nav.urlFunctions.createCustomerExpeditionTemplates());
        /*router.push("/customer/routes");*/
      },
      null,
      {
        onValidationFailed: errorMessages => {
          if (toast?.current) {
            toast.current.show({
              severity: 'error',
              summary: tr(`CreateTemplateStep4.validationError`, `Validation error`),
              detail: errorMessages.map((t: any) => tr(`${t.type}.${t.scope}.${t.message}`, `${t.type}.${t.scope}.${t.message}`)).map((t: any) => <div>{t}</div>),
            });
          }
        },
      },
    );
  };

  const cargoTypes = useCargoTypes();

  const { clear, validationErrors, validateAndSend } = useValidation(stage4validation, () => saveTemplate());
  const toast = useRef(undefined);

  return (
    <>
      {/*// @ts-ignore*/}
      <Card title={t(`CreateTemplateStep4.expeditionOverview`, `Expedition overview`)}>
        {/*// @ts-ignore*/}
        <Panel>
          <div className="p-d-flex p-grid">
            <CardView title={t(`global.expeditionTypeShort${state.expeditionType}`)} option={t(`global.expeditionType${state.expeditionType}`)} />
            {state.truckType && <SelectedTruckType selectedTruckId={state.truckType} />}
            <CardView
              title={
                state.manipulationTypes &&
                state.manipulationTypes
                  .map(loadingType => {
                    return t(`global.manipulation.${loadingType}`);
                  })
                  .join(',')
              }
              option={t('expeditionOverviewPage.manipulationTitle')}
            />
            <CardView title="- km" option={t('expeditionOverviewPage.routeLength')} />
          </div>
          <OverviewSpecialRequirements selectedSpecialRequirements={state.specialRequirements} />
          <InfoProvided className="p-mt-3">
            <InfoProvidedItem title={t(`CreateTemplateStep4.cargoType`, `Cargo type`)}>
              <p className="p-mt-1">{mapCodeToCargoItem(cargoTypes, state.cargoType)?.name}</p>
            </InfoProvidedItem>

            {state.carrierNote && (
              <InfoProvidedItem title={t(`CreateTemplateStep4.carrierNote`, `Carrier note`)}>
                <p className="p-mt-1">{state.carrierNote}</p>
              </InfoProvidedItem>
            )}
          </InfoProvided>
          {state.expeditionType === ExpeditionTypeEnum.LESS_TRUCK_LOAD && <LoadInfo loadingLocations={state.locations.filter(l => l.loadingLocation)} />}
          <div className="p-mt-6">
            <Label title={t(`CreateTemplateStep4.route`, `Route`)} />
            {/*// @ts-ignore*/}
            <Accordion>
              {state.locations.map((location, index, locations) => {
                return (
                  // @ts-ignore
                  <AccordionTab
                    header={
                      <>
                        <AccordionTitle number={index + 1} title={location.name} pretitle={location.city} btnTitle={t('expeditionOverviewPage.accordionDetail')} />
                      </>
                    }>
                    {state.expeditionType === ExpeditionTypeEnum.LESS_TRUCK_LOAD &&
                      location.loadingLocation &&
                      location.loadingOrders.map((loadingOrders, orderIndex) => {
                        return <LoadingUloadingDetail loading={true} order={loadingOrders} carrierCode={location.carrierCode} isFirstElement={orderIndex === 0} />;
                      })}
                    {state.expeditionType === ExpeditionTypeEnum.LESS_TRUCK_LOAD &&
                      location.unloadingLocation &&
                      state.locations
                        .map(location => location.loadingOrders)
                        .flat(1)
                        .filter(order => order.unloadingLocationId === index)
                        .map((loadingOrders, orderIndex) => {
                          return <LoadingUloadingDetail loading={false} order={loadingOrders} carrierCode={location.carrierCode} isFirstElement={orderIndex === 0} />;
                        })}
                    {state.expeditionType === ExpeditionTypeEnum.FULL_TRUCK_LOAD && (
                      <FTLLoadingUloadingDetail index={index} size={locations.length} carrierCode={location.carrierCode} />
                    )}
                    <ExpeditionContactInfo
                      location={{
                        contactEmail: location.contactEmail,
                        contactPhone: location.contactPhone,
                        contactName: location.contactName,
                        name: location.name,
                        address: {
                          city: location.city,
                          country: location.country,
                          streetNr: location.streetNr,
                          zipCode: location.zipCode,
                        },
                        enabledEmailNotificationForContactPerson: location.enabledEmailNotificationForContactPerson,
                        additionalNotificationContacts: location.additionalNotificationPersons?.map(t => ({
                          contactEmail: t.contactEmail,
                          contactName: t.contactName,
                          contactPhone: t.contactPhone,
                        })),
                      }}
                    />
                  </AccordionTab>
                );
              })}
            </Accordion>
          </div>
          <Section>
            <SectionLabel>{tr(`CreateTemplateStep4.internalInformation`, `Internal information`)}</SectionLabel>
            <ThreeOnRowWrapper>
              <Label title={t(`CreateTemplateStep4.personResponsibleForThisExpedition`, `Person responsible for this expedition`)} />
              <Dropdown
                value={state.responsiblePerson}
                dataKey="id"
                options={responsiblePersons}
                onChange={e => changeResponsiblePerson(e.target.value)}
                optionLabel="name"
                style={{ maxWidth: '100%' }}
              />
            </ThreeOnRowWrapper>
            <ThreeOnRowWrapper>
              <Label title={tr(`CreateTemplateStep4.subscribedPersons`, `Subscribed persons`)} />
              <MultiSelect
                value={state.subscribers}
                dataKey="id"
                options={responsiblePersons}
                onChange={e => changeSubscribers(e.target.value)}
                optionLabel="name"
                style={{ maxWidth: '100%' }}
              />
            </ThreeOnRowWrapper>
            <ThreeOnRowWrapper>
              <Label title={tr(`CreateTemplateStep4.internalNote`, `Internal note`)} />
              <InputTextarea value={state.internalNote} onChange={e => changeInternalNote(e.target.value)} style={{ maxWidth: '100%', height: '8rem' }} />
            </ThreeOnRowWrapper>
          </Section>
        </Panel>
        <>
          <PanelCard className="p-mt-4">
            <div className="p-sm-6 p-mb-4 p-d-flex p-ai-center">
              <InputSwitch checked={state.distribution?.enabled} onChange={() => toggleCreateDistribution()} className="p-mr-2" />
              <label>{t(`CreateTemplateStep4.iWantToCreateDistributionTemplate`, `Založit distribuční šablonu`)}</label>
            </div>
          </PanelCard>

          {!state.distribution?.enabled && <p className="p-mt-0 p-mb-4">{t('expeditionOverviewPage.labelInfo')}</p>}
          {state.distribution?.enabled && (
            <CreateDistributionTemplate
              data={state.distribution}
              changeDistributionType={changeDistributionType}
              changeDistributionPrice={changeDistributionPrice}
              changeDistributionSelectedItems={changeDistributionSelectedItems}
              changeDistributionIntervalHour={changeDistributionIntervalHour}
              changeAutomaticPublishing={changeAutomaticPublishing}
              changeCurrency={changeCurrency}
              validationErrors={validationErrors}
            />
          )}
          <ButtonLayout>
            <Button label={t(`CreateTemplateStep4.continue`, `Continue`)} className="p-mr-2" onClick={e => validateAndSend(state.distribution)} />
            <Button label={t(`CreateTemplateStep4.back`, `Back`)} className="p-button-text" onClick={() => clickOnBreadCrumb(PageID.STEP3)} />
          </ButtonLayout>
        </>
      </Card>
      <Toast ref={toast} position={'top-right'}></Toast>
    </>
  );
};

const ThreeOnRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 33%;
  margin: 1rem 0 0 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled.div`
  display: flex;
  color: rgb(73, 80, 87);
  font-weight: 700;
  font-size: 24px;
  margin: 1rem 0 0 0;
`;

export default CreateTemplateStep4;
