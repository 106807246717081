import classNames from 'clsx';

interface ComponentProps {
  children?: any;
  wrapper?: string;
}

const TimeList: React.FC<ComponentProps> = ({ children, wrapper }) => {
  const className = classNames('time-wrapper', {
    'text-right': wrapper === 'text-right',
  });

  return <div className={className}>{children}</div>;
};

export default TimeList;
