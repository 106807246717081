import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { GlobalOrderResponse, OrderDetailPlanOutDTO, WeightModeEnum } from '@api/logsteo-api.v2';
import COBadge from '@components/ringil3/COBadge/COBadge';
import IconBlock from '@components/ringil3/IconBlock/IconBlock';
import { GrayText, LargeText } from '@components/ringil3/styles';
import { dumpVars, formatPrice, formatWeight, isNotNullOrUndefined } from '@utils/utils';
import SvgCargoItem from '@components/ringil3/icons/CargoItem';
import SvgGrayInfo from '@components/ringil3/icons/GrayInfo';

interface ComponentProps {
  type: 'loading' | 'unloading' | 'neutral';
  order: GlobalOrderResponse;
}

export const convertToGlobalOrder = (orderDetail: OrderDetailPlanOutDTO): GlobalOrderResponse => {
  return {
    orderName: orderDetail.name,
    cargoAmount: orderDetail.cargoAmount,
    cargoDescription: orderDetail.cargoDescription,
    items: orderDetail.cargos.map(cargo => ({
      cargoTemplate: {
        code: cargo.cargoItemTypeCode,
        name: cargo.name,
      },
      amount: cargo.quantity,
      stackable: cargo.stackable,
      weight: cargo.weight,
      length: cargo.length,
      height: cargo.height,
      width: cargo.width,
    })),
  } as GlobalOrderResponse;
};

const RingilOrder: React.FC<PropsWithChildren<ComponentProps>> = ({ type, order }) => {
  const { tr } = useTranslationLgs();
  return (
    <RingilOrderInner>
      <div className={'mb-3 mt-5'}>
        <COBadge variant="filled" type={type}>
          {order.orderName}
        </COBadge>
      </div>
      {order.items.map((cargo, cargoIndex) => {
        return (
          <React.Fragment key={cargoIndex}>
            <IconBlock icon={<SvgCargoItem />}>
              {cargo.stackable ? (
                <LargeText>
                  {tr(`RingilOrder.cargoTemplateStackable`, `{{unit}} ({{count}}), stohovalné`, {
                    unit: cargo.cargoTemplate.name,
                    count: cargo.amount,
                  })}
                </LargeText>
              ) : (
                <LargeText>
                  {tr(`RingilOrder.cargoTemplateNonstackable`, `{{unit}} ({{count}}), nestohovalné`, {
                    unit: cargo.cargoTemplate.name,
                    count: cargo.amount,
                  })}
                </LargeText>
              )}
              <GrayText>
                {cargo.weight > 0 && (
                  <div>{tr('RingilOrder.weightDescription', 'Hmotnost objednavky: {{formatedWeight}}', { formatedWeight: formatWeight(cargo.weight) })}</div>
                )}
                {cargo.length + cargo.height + cargo.width > 0 && (
                  <div>
                    {tr(`RingilOrder.dimensions`, `dimensions: {{length}} x {{height}} x {{width}} (l x h x w)`, {
                      length: cargo.length,
                      height: cargo.height,
                      width: cargo.width,
                    })}
                  </div>
                )}
              </GrayText>
            </IconBlock>
          </React.Fragment>
        );
      })}
      {isNotNullOrUndefined(order.cargoAmount) && isNotNullOrUndefined(order.cargoDescription) && (
        <IconBlock icon={<SvgGrayInfo />}>
          <GrayText>
            {tr(`RingilOrder.cargoDescription`, `{{cargoDescription}}: {{cargoAmount}} ks`, {
              cargoDescription: order.cargoDescription,
              cargoAmount: order.cargoAmount,
            })}
          </GrayText>
        </IconBlock>
      )}
      {isNotNullOrUndefined(order.orderValue) && isNotNullOrUndefined(order.orderValue?.price) && (
        <IconBlock icon={<SvgGrayInfo />}>
          <GrayText>
            {tr(`RingilOrder.orderValues`, `Hodnota objednávky: {{formattedPrice}}`, {
              formattedPrice: formatPrice(order.orderValue.price, order.orderValue.currency),
            })}
          </GrayText>
        </IconBlock>
      )}
    </RingilOrderInner>
  );
};

const RingilOrderInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export default RingilOrder;
