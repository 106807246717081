import React, { useContext, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { PageResponseTemplatePreviewOutDTO, TemplatePreviewOutDTO } from '@api/logsteo-api.v2';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import { ApiContext } from '@api/api';
import { InputText } from 'primereact/inputtext';
import { isNullOrUndefined } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import ExpeditionLocation from '@components/obsolete/ExpeditionLocation/ExpeditionLocation.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { Link } from 'react-router-dom';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';

interface ConfirmDeleteData {
  visible: boolean;
  templateId?: string;
}

const TemplateList = () => {
  const { t } = useTranslation('common');

  const responsiblePersons = useResponsiblePersons();

  const [pageData, setPageData] = useState<PageResponseTemplatePreviewOutDTO>(null);
  const [deleteData, setDeleteData] = useState<ConfirmDeleteData>({
    visible: false,
  });
  const { cuListTemplates, cuDeleteTemplate } = useContext(ApiContext);

  const navigation = useNavigation();

  /*  const { pageNumber, pageSize, filter: filterFromURL, updateUrl } = usePagingForRouteList(1, 20);
  const [filter, setFilter] = useImmer<RouteListFilter>(filterFromURL);
  const [debouncedFilter] = useDebounce(filter, 600);*/

  /*  useEffect(() => {
    updateUrl(pageNumber, pageSize, debouncedFilter);
    loadData(pageSize, pageNumber, debouncedFilter);
  }, [debouncedFilter]);*/

  /*  useEffect(() => {
    loadData(pageSize, pageNumber, filter);
  }, [filter.responsiblePersonValueId, filter.locationName, filter.locationZipCode]);*/

  const loadData = (pageSize: number, pageNumber: number, filter: FilterType) => {
    cuListTemplates(
      {
        createdBy: filter.responsiblePersonValueId,
        locationName: filter.locationName,
        locationZipCode: filter.locationZipCode,
        templateName: filter.templateName,
      },
      { pageSize, pageNumber },
      data => {
        setPageData(data);
      },
    );
  };

  const deleteTemplate = (templateId: string) => {
    cuDeleteTemplate(templateId, () => {
      setDeleteData({ visible: false });
      /*loadData(pageSize, pageNumber, filter);*/
      loadData(page.pageSize, page.pageNumber, filter);
    });
  };

  const onPage = (event: any) => {
    /*updateUrl(event.page + 1, event.rows, filter);
    loadData(event.rows, event.page + 1, filter);*/
    applyPaging({ pageNumber: event.page + 1, pageSize: event.rows });
  };

  const routeBodyTemplate = (rowData: TemplatePreviewOutDTO) => {
    return (
      <div className="locations">
        <ExpeditionLocation locationPoint={t('labelFrom')} locationAddress={rowData.locations[0]} wrapper="LARGE" />
        <ExpeditionLocation locationPoint={t('labelTo')} locationAddress={rowData.locations[rowData.locations.length - 1]} wrapper="LARGE" />
        {rowData.locations.length > 2 && (
          <ExpeditionLocation
            locationPoint={t('labelThrough')}
            through={t(`TemplateList.through`, {
              count: rowData.locations.length - 2,
            })}
            wrapper="LARGE"
          />
        )}
      </div>
    );
  };

  const typeShipmentsBodyTemplate = (rowData: TemplatePreviewOutDTO) => {
    return (
      <>
        <div>
          <div className="type-shipments p-pb-1">
            <img src="/images/local_shipping.png" alt="" />
            <span className="p-ml-2">{t(`TemplatesList.${rowData.expeditionType}`, rowData.expeditionType)}</span>
          </div>
          <ul className="template__load-detail">
            {rowData.truckType && <li>{rowData.truckType}</li>}
            {!isNullOrUndefined(rowData.maxWeight) && <li>{t('TemplateList.maxWeight', { weight: rowData.maxWeight })}</li>}
            <li>{rowData.cargoType}</li>
          </ul>
        </div>
      </>
    );
  };

  const navBodyTemplate = (rowData: TemplatePreviewOutDTO) => {
    return (
      <div className="btn-nav">
        <ButtonLayout>
          {/*<Link href={`/customer/route/${rowData.id}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionTemplateDetail(rowData.id))}>
            <a className="btn-detail p-button p-button-rounded p-button-outlined secondary-btn">{t('Upravit')}</a>
          </Link>

          <SplitButton
            icon="pi pi-ellipsis-h"
            model={[
              {
                label: t(`TemplatesList.delete`, `Smazat`),
                icon: 'pi pi-trash',
                command: () => setDeleteData({ visible: true, templateId: rowData.id }),
              },
            ]}
            className="btn-more-info p-button-rounded p-button-outlined p-ml-3"
          />
        </ButtonLayout>
      </div>
    );
  };

  const templateNameBodyTemplate = (rowData: TemplatePreviewOutDTO) => {
    return (
      <div className="">
        <h4 className="p-m-0 text-blue">
          {/*<Link href={`/customer/route/${rowData.id}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionTemplateDetail(rowData.id))}>{rowData.name}</Link>
        </h4>
        <div className="template__create text-gray5">
          <small>
            <span className="p-mr-1">{t('labelCreatedBy')}:</span> {rowData.createdBy}
          </small>
        </div>
        <div className="template__date text-gray5">
          <small>
            <span className="p-mr-1">{t('labelDate')}:</span>
            {dateFormatDateTime(mapFromAPIDateTime(rowData.createdAt))}
          </small>
        </div>
      </div>
    );
  };

  const templateUseBodyTemplate = (rowData: TemplatePreviewOutDTO) => {
    return (
      <div className="template-use-wrapper">
        <div className="template-use-item">
          {rowData.isExpeditionTemplate ? <i className="pi pi-check p-mr-2"></i> : <i className="pi pi-times p-mr-2"></i>}
          <span>{t(`TemplatesList.expeditionTemplate`, `expediční šablona`)}</span>
        </div>
        <div className="template-use-item">
          {rowData.isDistributionTemplate ? <i className="pi pi-check p-mr-2"></i> : <i className="pi pi-times p-mr-2"></i>}
          <span>{t(`TemplatesList.distributionTemplate`, `distribuční šablona`)}</span>
        </div>
      </div>
    );
  };

  interface FilterType {
    responsiblePersonValueId?: string;
    locationName?: string;
    locationZipCode?: string;
    templateName?: string;
  }

  const { Filter, applyFilterValue, filter, applyPaging, page } = useFilter<FilterType>((page, filter) => {
    loadData(page.pageSize, page.pageNumber, filter);
  });

  return (
    <>
      <NavTitle wrapper="PADDING-0">
        <h2 className="h2-title p-m-0">{t('labelTemplates')}</h2>
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionTemplate())}>
          <a className="btn-detail btn-detail-icon p-button p-button-rounded secondary-btn ">
            <span className="icon pi pi-plus"></span> <span className="text">{t(`TemplatesList.newRoute`, `Nová trasa`)}</span>
          </a>
        </Link>
      </NavTitle>

      <Filter />
      <div className="p-mt-3 p-mb-2">
        <InputText
          value={filter?.templateName}
          onChange={e => applyFilterValue('templateName', e.target.value)}
          placeholder={t(`TemplatesList.templateName`, `Template name`)}
          className="filter-table"
        />
        <Dropdown
          value={filter?.responsiblePersonValueId}
          options={responsiblePersons}
          onChange={e => applyFilterValue('responsiblePersonValueId', e.value)}
          optionLabel="name"
          optionValue={'id'}
          placeholder={t(`TemplatesList.whoCreated`, `Kdo vytvořil`)}
          className="filter-table"
          showClear={true}
        />
        <InputText
          value={filter?.locationZipCode}
          onChange={e => applyFilterValue('locationZipCode', e.target.value)}
          placeholder={t(`TemplatesList.locationZipCode`, `PSČ`)}
          className="filter-table"
        />
        <InputText
          value={filter?.locationName}
          onChange={e => applyFilterValue('locationName', e.target.value)}
          placeholder={t(`TemplatesList.locationName`, `Lokace`)}
          className="filter-table"
        />
      </div>

      {pageData && pageData.data && (
        <>
          {/*
        // @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            className="default-templates-table templates-table"
            value={pageData.data}
            rows={pageData.size}
            paginator={true}
            lazy={true}
            totalRecords={pageData.total}
            onPage={onPage}
            first={(pageData.page - 1) * pageData.size}
          >
            <Column className="templates-table__name" body={templateNameBodyTemplate}></Column>
            <Column className="templates-table__route" body={routeBodyTemplate}></Column>
            <Column className="templates-table__shipments" body={typeShipmentsBodyTemplate}></Column>
            <Column className="templates-table__template-use" body={templateUseBodyTemplate}></Column>
            <Column className="templates-table__nav" body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}
      <ConfirmDialog
        visible={deleteData.visible}
        dialogTitle={t(`TemplatesList.doYouWantDeleteTempalte`, `Do you want delete tempalte`)}
        dialogMessage={t(`TemplatesList.deleteTemplateDialogText`, `Delete template dialog text`)}
        onConfirm={() => deleteTemplate(deleteData.templateId)}
        onCancel={() => setDeleteData({ visible: false })}
        onAcceptButtonText={t(`TemplatesList.confirm`, `Confirm`)}
        onCancelButtonText={t(`TemplatesList.cancel`, `Cancel`)}
      />
    </>
  );
};
export default TemplateList;
