import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { addLocale } from 'primereact/api';

import '@fontsource/dm-sans'; // Defaults to weight 400
import '@fontsource/dm-sans/400.css'; // Specify weight
import '@fontsource/dm-sans/500.css'; // Specify weight
import '@fontsource/dm-sans/700.css'; // Specify weight
import '@fontsource/dm-sans/400-italic.css'; // Specify weight and style
import './index.css';
//import "primereact/resources/themes/saga-blue/theme.css";
import '@styles/ringil/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
//import "primereact/resources/themes/lara-light-cyan/theme.css";
import './config/i18n.ts';
import { Routes } from '@routes';

addLocale('cs', {
  firstDayOfWeek: 1,
  dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
  dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  dayNamesMin: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
  monthNames: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
  monthNamesShort: ['led', 'ún', 'bře', 'dub', 'kvě', 'čer', 'čvn', 'srp', 'zář', 'říj', 'lis', 'pro'],
  today: 'dnes',
  clear: 'Smazat',
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Routes />
  </StrictMode>,
);
