import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import * as yup from 'yup';
import { dumpVars } from '@utils/utils';
import { findValidationMessage } from '@utils/validation';
import { AttachmentUploadedOutDTO, EntityTypeEnum, GetLinkedEntitiesResponse } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { Dialog } from 'primereact/dialog';
import { ButtonRow } from '@components/styles';
import { Button } from 'primereact/button';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import RadioButtons from '@components/obsolete/RadioButtons.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  documents: AttachmentUploadedOutDTO[];
  entityType: EntityTypeEnum;
  entityId: string;
}

interface LinkedObject {
  applicationId: string;
  id: string;
  entityType: EntityTypeEnum;
}

interface Form {
  selectedLinkedObject: LinkedObject;
  copyAsInternal: boolean;
}

const CopyDocumentsDialog: React.FC<ComponentProps> = ({ visible, onHide, documents, entityType, entityId }) => {
  const { tr } = useTranslationLgs();
  const { getLinkedEntities, copyAttachmentToEntity } = useContext(ApiContext);

  const validationSchema = yup.object().shape({
    selectedLinkedObject: yup.mixed().required(),
    copyAsInternal: yup.boolean().required(),
  });

  const [linkedObjects, setLinkedObjects] = useState<GetLinkedEntitiesResponse[]>([]);

  const names = proxiedPropertiesOf<Form>();
  const form = useForm<Form>(validationSchema, { copyAsInternal: true, selectedLinkedObject: null }, () => copy(), false, false);

  useEffect(() => {
    if (visible) {
      getLinkedEntities(
        entityType,
        entityId,
        d => {
          setLinkedObjects(d);
        },
        null,
        {
          onValidationFailed: setBeValidationErrors,
        },
      );
    }
  }, [visible]);

  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  const copy = () => {
    copyAttachmentToEntity(
      {
        entityId: form.values.selectedLinkedObject.id,
        entityType: form.values.selectedLinkedObject.entityType,
        attachmentIds: documents.map(t => t.attachmentId),
        copyAsInternal: form.values.copyAsInternal,
      },
      d => {
        onHide();
      },
      null,
      {
        onValidationFailed: setBeValidationErrors,
      },
    );
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog visible={visible} onHide={onHide} style={{ width: '450px' }} header={tr('CopyDocumentsDialog.title', 'Copying a document')}>
        <HorizontalFieldValue label={tr('CopyDocumentsDialog.selectedDocuments', 'Selected documents')} value={<div>{documents.map(t => t.fileName).join(', ')}</div>} />

        <HorizontalFieldValue
          label={tr('CopyDocumentsDialog.linkTo', 'LinkTo')}
          value={
            <RadioButtons
              options={linkedObjects}
              optionLabel={'applicationId'}
              onChange={d => {
                form.setFieldValue(names.selectedLinkedObject, d);
              }}
              value={form.values.selectedLinkedObject}
            />
          }
          validationError={findValidationMessage(form.validationErrors, names.selectedLinkedObject, tr)}
        />

        <HorizontalFieldValue
          label={tr('CopyDocumentsDialog.markAsInternal', 'Mark as internal')}
          value={<InputYesNoPlain value={form.values.copyAsInternal} onChange={v => form.setFieldValue(names.copyAsInternal, v)} />}
          validationError={findValidationMessage(form.validationErrors, names.copyAsInternal, tr)}
        />
        <ButtonRow>
          <Button label={tr(`CopyDocumentsDialog.copy`, `Copy`)} onClick={e => form.validateAndSend()} />
          <Button label={tr(`CopyDocumentsDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonRow>
      </Dialog>
    </>
  );
};

export default CopyDocumentsDialog;
