import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { formatInterval, formatPrice, getTime, isNullOrUndefined, mapFromDateToDayjs } from '@utils/utils';
import { dateFormatOnlyDateShort, mapFromAPIDateTime, shortDateFormat } from '@utils/date';
import { Dayjs } from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import { LocationTimeslots } from '../types';
import { EntityTypeEnum, ExpeditionStatusEnum } from '@api/logsteo-api.v2';
import ReconfirmQuote from '../../common/ReconfirmQuote';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import CanMakeReservation from '@components/ringil3/Features/Reservation/Helper/CanMakeReservation';
import LinkedResBadge from '@components/ringil3/Badges/LinkedResBadge';
import { useTranslation } from 'react-i18next';
import { ExpeditionDetailHandlerContext } from '@app/pages/carrier/expedition-detail/ExpeditionDetailHandler.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InfoNote from '@components/obsolete/InfoNote/InfoNote.tsx';
import TimeslotsProgressTitle from '@components/obsolete/TimeslotsProgress/TimeslotsProgressTitle.tsx';
import TimeslotsProgressInput from '@components/obsolete/TimeslotsProgress/TimeslotsProgressInput.tsx';
import TimeslotsProgressInputSelect from '@components/obsolete/TimeslotsProgress/TimeslotsProgressInputSelect.tsx';
import AssignVehicleComponent from '@app/pages/carrier/expedition-detail/AssignVehicle/AssignVehicleComponent.tsx';
import { AdditionalCosts } from '@components/obsolete/AdditionalCosts/AdditionalCosts.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import CarrierChatComponent from '@app/pages/carrier/Chat/CarrierChatComponent.tsx';

interface Slot {
  locationId: number;
  since: Dayjs;
  till: Dayjs;
}

interface ComponentProps {
  disabled?: boolean;
}
const TabShipmentAssigned: React.FC<ComponentProps> = ({ disabled }) => {
  const { t } = useTranslation('common');
  const { state, loadShipmentProgress, selectSlot, createJobProgressTabForReadyToShipment, addCarrierSlot, changeSince, changeTill, changeCustomSlotDate, reconfirm } =
    useContext(ExpeditionDetailHandlerContext);

  const [visibleChatDialog, setVisibleChatDialog] = useState(false);
  const [newSlots, setNewSlots] = useState<Slot[]>([]);

  const load = () => {
    loadShipmentProgress(state.header.companyId, state.header.applicationId);
  };

  useEffect(() => {
    load();
  }, []);

  const hasNewSlot = (locationId: string) => {
    const locationSlot = state.createReadyToShipment.locationTimeslotIds.find(t => t.locationId === locationId);
    if (locationSlot) return !isNullOrUndefined(locationSlot.newTimeslot);
    return false;
  };

  const getNewSlot = (locationId: string) => {
    const locationSlot = state.createReadyToShipment.locationTimeslotIds.find(t => t.locationId === locationId);
    return locationSlot.newTimeslot;
  };

  const mapSlots = () => {};

  const isValidDate = yup.string().test('isValidDate', 'message', v => false);

  const validationSchema = yup.object().shape({
    carPlate: yup.string().required().min(1),
    dispatcherId: yup.string().required(),
    driverId: yup.string().required(),
    locationTimeslotIds: yup.array().of(
      yup.object().shape({
        locationId: yup.string().defined().min(1),
        selectedSlot: yup.mixed().test({
          name: 'required',
          test: (value: any, testContext) => {
            const v = testContext.parent as LocationTimeslots;
            if (!isNullOrUndefined(v.timeslotId)) return true;
            // TODO: implement  better checks
            if (!isNullOrUndefined(v.newTimeslot) && v.newTimeslot.timeslotSince !== 'Invalid Date') return true;
            return false;
          },
          message: 'required',
          exclusive: true,
        }),
        newSlot: yup.mixed().test({
          name: 'intervals.not.valid',
          message: 'intervals.not.valid',
          test: (value: any, testContext) => {
            const v = testContext.parent as LocationTimeslots;
            if (!isNullOrUndefined(v.timeslotId)) return true;
            // TODO: implement  better checks
            if (!isNullOrUndefined(v.newTimeslot) && v.newTimeslot.timeslotSince < v.newTimeslot.timeslotTill) return true;
            return false;
          },
        }),
      }),
    ),
  });

  const save = () => {
    createJobProgressTabForReadyToShipment(state.header.companyId, state.header.applicationId, {
      carPlate: state.createReadyToShipment.carPlate,
      dispatcherId: state.createReadyToShipment.dispatcherId,
      driverId: state.createReadyToShipment.driverId,
      locationTimeslotIds: state.createReadyToShipment.locationTimeslotIds,
    });
  };
  const { clear, validateAndSend, validationErrors, find } = useValidation(validationSchema, save);
  const { CreateTimeslotSidebar, setCreateTimeslotRequest, createTimeslotRequest } = useCreateTimeslotSidebar();

  const { tr } = useTranslationLgs();

  return (
    <>
      {state.assigningStateData && (
        <>
          <div className="p-d-flex p-grid">
            <div className="p-col-12 p-md-6 p-lg-6 p-mr-6">
              {state.assigningStateData.carrierNote && (
                <HorizontalFieldValue label={tr(`TabShipmentAssigned.carrierNote`, `Carrier note`)} value={<InfoNote text={state.assigningStateData.carrierNote} />} />
              )}
              {state.assigningStateData.isReconfirmedNeeded && (
                <div className="p-mb-3">
                  <ReconfirmQuote
                    onReconfirm={() => reconfirm(state.header.companyId, state.header.applicationId, load)}
                    reconfirmedAt={state.assigningStateData.reconfirmedAt}
                    isReconfirmedByCarrier={state.assigningStateData.isReconfirmedByCarrier}
                    disabled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED || state.header.isManualExpedition}
                  />
                </div>
              )}
              <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                {t(`TabShipmentAssigned.selectTimeslotsForLoadingAndUnloading`, `Select timeslots for loading and unloading`)}
              </h3>
              {state.assigningStateData.locations.map((location, locationIndex) => {
                return (
                  <div className="timeslotsProgress" key={locationIndex}>
                    <TimeslotsProgressTitle
                      number={`${locationIndex + 1}`}
                      address={`${location.zipCode}, ${location.city}, (${location.country})`}
                      company={location.name}
                    />
                    <div className="timeslotsProgress-wrapper">
                      {location.timeslots.map((timeslot, timeslotIndex) => {
                        return (
                          <TimeslotsProgressInput
                            key={timeslotIndex}
                            date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                            time={formatInterval(timeslot.isNotSpecifiedTime, timeslot.sinceInUTC, timeslot.tillInUTC, t)}
                            checked={state.createReadyToShipment.locationTimeslotIds.some(l => l.locationId == location.id && l.timeslotId == timeslot.id)}
                            onCheck={() => selectSlot(location.id, timeslot.id)}
                            disabled={disabled}
                          />
                        );
                      })}
                    </div>
                    {hasNewSlot(location.id) && (
                      <>
                        <TimeslotsProgressInputSelect inputName="dataTime1" inputValue="data-time-4" checked={true}>
                          <div className="timeslotsProgress-calendar">
                            <Calendar
                              locale={'cs'}
                              value={mapFromAPIDateTime(getNewSlot(location.id).timeslotSince).toDate()}
                              dateFormat={shortDateFormat}
                              onChange={e => changeCustomSlotDate(location.id, mapFromDateToDayjs(e.value as Date))}
                            />
                          </div>
                          <div className="timeslotsProgress-input p-fluid">
                            <InputMask
                              mask={'99:99'}
                              value={getTime(mapFromAPIDateTime(getNewSlot(location.id).timeslotSince))}
                              onComplete={e => changeSince(location.id, e.value)}
                            />
                          </div>
                          <div className="delimiter"> — </div>
                          <div className="timeslotsProgress-input p-fluid">
                            <InputMask
                              mask={'99:99'}
                              value={getTime(mapFromAPIDateTime(getNewSlot(location.id).timeslotTill))}
                              onComplete={e => changeTill(location.id, e.value)}
                            />
                          </div>
                          <ValidationDiv errors={validationErrors} path={`locationTimeslotIds[${locationIndex}].newSlot`} />
                        </TimeslotsProgressInputSelect>
                      </>
                    )}
                    {!hasNewSlot(location.id) && (
                      <Button
                        icon="icon pi pi-plus"
                        className="btn-plus p-mt-2 p-button-outlined"
                        label={t(`TabShipmentAssigned.pickASpecificTime`, `Pick a specific time`)}
                        onClick={() => addCarrierSlot(location.id)}
                        disabled={disabled}
                      />
                    )}
                    <ValidationDiv errors={validationErrors} path={`locationTimeslotIds[${locationIndex}].selectedSlot`} />
                    {location.linkedReservation && <LinkedResBadge linkedReservation={location.linkedReservation} />}
                    {isNullOrUndefined(location.linkedReservation) && (
                      <CanMakeReservation companyLocationId={location.companyLocationId}>
                        <Button
                          label={tr(`TabShipmentActive2Finish.createReservation`, `Create reservation`)}
                          onClick={e => {
                            setCreateTimeslotRequest({
                              applicationId: state.header.applicationId,
                              companyLocationId: location.companyLocationId,
                              preferredDate: '',
                              companyLocationCustomerId: location.companyLocationCustomerId,
                              expeditionLocationId: location.id,
                              expeditionId: state.header.id,
                            });
                          }}
                        />
                      </CanMakeReservation>
                    )}
                  </div>
                );
              })}
              <AssignVehicleComponent validationErrors={validationErrors} />
              <div className="p-field p-mb-5">
                <Button
                  label={t(`TabShipmentAssigned.save`, `Save`)}
                  style={{ width: '150px' }}
                  className="p-button-rounded"
                  onClick={e => validateAndSend(state.createReadyToShipment)}
                  disabled={disabled}
                />
              </div>
              <AdditionalCosts applicationId={state.header.applicationId} companyId={state.header.companyId} editable={false} />
            </div>
            <div className="p-col-12 p-md-5">
              <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                {t(`TabShipmentAssigned.customer`, `Customer`)}
              </h3>
              <PanelWithIcon srcIcon="/images/contacts.svg">
                <Label title={state.assigningStateData.customerDetail.name} />
                <p>{`${state.assigningStateData.customerDetail.streetNr}, ${state.assigningStateData.customerDetail.city}, ${state.assigningStateData.customerDetail.country}`}</p>
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/user.svg">
                <p>{state.assigningStateData.responsiblePersonName}</p>
                <p className="text-gray5">
                  <small>{t(`TabShipmentAssigned.responsiblePerson`, `responsible person`)}</small>
                </p>
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/envelope.svg">
                <Button
                  icon="pi pi-envelope"
                  className="p-button-outlined"
                  label={t(`TabShipmentAssigned.writeMessage`, `Napsat zprávu`)}
                  onClick={() => setVisibleChatDialog(true)}
                />
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/money.svg">
                <p>{t(`TabShipmentAssigned.agreedPrice`, `Agreed price`)}</p>
                <p>
                  <strong>{formatPrice(state.assigningStateData.agreedPrice, state.assigningStateData.currency)}</strong>
                </p>
              </PanelWithIcon>
            </div>
          </div>
          <CarrierChatComponent
            companyId={state.header.companyId}
            entityType={EntityTypeEnum.EXPEDITION}
            entityId={state.header.id}
            visible={visibleChatDialog}
            onHide={() => {
              setVisibleChatDialog(false);
            }}
            customerName={state.header.customerName}
          />
          {createTimeslotRequest && (
            <CreateTimeslotSidebar
              onContinue={() => {
                load();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default TabShipmentAssigned;
