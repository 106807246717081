import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

interface FieldValueCP {
  label: string;
  value: any;
}
const FieldValue: React.FC<FieldValueCP> = ({ label, value }) => {
  return (
    <>
      {value && (
        <FieldValueWrapper>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </FieldValueWrapper>
      )}
    </>
  );
};

const FieldValueWrapper = styled.div`
  :not(:first-child) {
    margin-top: 15px;
  }
`;
const Label = styled.div`
  font-weight: bold;
`;
const Value = styled.div``;
export default FieldValue;
