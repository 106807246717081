import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminI18NTemplateDetail from '@components/pages/admin/templates/AdminI18NTemplateDetail';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { useParams } from 'react-router-dom';

interface ComponentProps {}

const TemplateDetailPage: React.FC<ComponentProps> = () => {
  const { templateId } = useParams();

  return (
    <>
      <AdminLayout title="Template detail">
        <AdminI18NTemplateDetail templateId={templateId} />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(TemplateDetailPage);
