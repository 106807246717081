import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '../../../../hooks/i18n/useTranslation';
import { Label, Note, Value } from '@components/styles.tsx';

interface FieldValueCP {
  label: any;
  value: any;
  style?: any;
  required?: boolean;
  validationError?: string;
  warningText?: string;
  noteText?: string;
  handleNullValue?: boolean;
  isNullValue?: boolean;
}

const HorizontalFieldValue: React.FC<FieldValueCP> = ({ label, value, style, validationError, warningText, required = false, noteText }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <FieldValueWrapper style={style}>
        <Label>{required ? label + ' *' : label}</Label>
        {value && <Value>{value}</Value>}
        {!value && <Value>{tr(`HorizontalFieldValue.undefined`, `Value not set`)}</Value>}
        {validationError && <ValidationError className={'validationErrorMessage'}>{validationError}</ValidationError>}
        {warningText && <WarningMessage>{warningText}</WarningMessage>}
        {noteText && <Note>{noteText}</Note>}
      </FieldValueWrapper>
    </>
  );
};

const ValidationError = styled.div`
  display: flex;
  color: red;
`;

const WarningMessage = styled.div`
  display: flex;
  color: #fc8928;
`;

const FieldValueWrapper = styled.div`
  padding: 5px 5px 5px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default HorizontalFieldValue;
