import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import styled from 'styled-components';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onSuccess: () => void;
  onHide: () => void;
  loading?: boolean;
  companyId: string;
  applicationId: string;
}

const CancelExpeditionDialog: React.FC<ComponentProps> = ({ visible, onSuccess, onHide, loading = false, applicationId, companyId }) => {
  const [disabled, setDisabled] = useState(false);
  const { cuCancelExpedition } = useContext(ApiContext);
  const [reasonCode, setReasonCode] = useState(null);

  const { tr } = useTranslationLgs();

  const cancellationReasons: LovWithoutIdDTO[] = [
    {
      name: tr('CancelExpeditionReasonHIGHER_POWERS', 'Vyssi moc'),
      code: 'HIGHER_POWERS',
    },
    {
      name: tr('CancelExpeditionReasonCANCELED_BY_CUSTOMER', 'Zruseno zakaznikem'),
      code: 'CANCELED_BY_CUSTOMER',
    },
    {
      name: tr('CancelExpeditionReasonCANCELED_BY_CARRIER', 'Zruseno dopravce'),
      code: 'CANCELED_BY_CARRIER',
    },
    {
      name: tr('CancelExpeditionReasonADDED_TO_ANOTHER_EXPEDITION', 'Doloženo do jiné přepravy'),
      code: 'ADDED_TO_ANOTHER_EXPEDITION',
    },
    {
      name: tr('CancelExpeditionReasonDUPLICITY', 'Duplicita'),
      code: 'DUPLICITY',
    },
    {
      name: tr('CancelExpeditionReasonOTHER_CARRIER', 'Jiný dopravce'),
      code: 'OTHER_CARRIER',
    },
    {
      name: tr('CancelExpeditionReasonLOCATION_CHANGED', 'Záměna lokací'),
      code: 'LOCATION_CHANGED',
    },
  ];

  const cancelExpedition = () => {
    cuCancelExpedition(companyId, applicationId, { reasonCode }, () => {
      onSuccess();
    });
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog
        visible={visible}
        baseZIndex={1000000}
        onHide={() => onHide()}
        header={<h4>{tr(`AllExpedition.cancelExpedtionTitle`, `Cancel expedition`)}</h4>}
        className="dialog-confirm dialog-md"
      >
        <p>{tr(`AllExpedition.doYouWantCancelExpeditionLarge`, `Do you want cancel expedition - large`)}</p>
        <FormWrapper>
          <HorizontalFieldValue
            label={tr(`CancelExpeditionDialog.cancelationReason`, `Duvod zruseni`)}
            value={
              <Dropdown
                options={cancellationReasons}
                optionValue={'code'}
                optionLabel={'name'}
                value={reasonCode}
                style={{ width: '100%' }}
                onChange={e => setReasonCode(e.value)}
              />
            }
          />
        </FormWrapper>
        <ButtonLayout wrapper="WITHOUT_PADDING_BOTTOM">
          <Button
            label={tr(`AllExpedition.confirm`, `Confirm`)}
            className="p-mr-2"
            onClick={() => {
              if (loading) setDisabled(true);
              cancelExpedition();
            }}
            disabled={disabled}
          />
          <Button label={tr(`AllExpedition.back`, `Back`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonLayout>
      </Dialog>
    </>
  );
};

const FormWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export default CancelExpeditionDialog;
