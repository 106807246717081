import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { isNullOrUndefined } from '@utils/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ManageInheritedModulesSidebar from './ManageInheritedModulesSidebar/ManageInheritedModulesSidebar';
import { RingilHeading, RowWithSpaceBetween } from '@components/styles.tsx';
import EditButton from '@components/obsolete/Buttons/EditButton/EditButton.tsx';

interface ComponentProps {
  companyId: string;
}

interface Company {
  name: string;
  companyId: string;
  inheritedModules: string[];
}

interface VisibleDialog {
  visible: boolean;
  inheriterCompanyId?: string;
}

const AdminCompanyInheriters: React.FC<ComponentProps> = ({ companyId }) => {
  const { adminListInheritingCompaniesByCompany } = useContext(ApiContext);
  const [data, setData] = useState<Company[]>([]);
  const [visibleAddDialog, setVisibleAddDialog] = useState<VisibleDialog>({
    visible: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    const ac = new AbortController();
    setLoading(true);
    adminListInheritingCompaniesByCompany(companyId, d => {
      if (ac.signal.aborted) return;

      setLoading(false);
      setData(
        d.reduce((previousValue, currentValue) => {
          const company = previousValue.find(t => t.companyId === currentValue.companyId);

          if (isNullOrUndefined(company)) {
            return [
              ...previousValue,
              {
                name: currentValue.companyName,
                companyId: currentValue.companyId,
                inheritedModules: [currentValue.companyModule],
              } as Company,
            ];
          } else {
            return [
              ...previousValue.filter(t => t.companyId !== currentValue.companyId),
              {
                name: currentValue.companyName,
                companyId: currentValue.companyId,
                inheritedModules: [...company.inheritedModules, currentValue.companyModule],
              } as Company,
            ];
          }
        }, []),
      );
      return () => {
        ac.abort();
      };
    });
  };

  return (
    <>
      <RowWithSpaceBetween>
        <RingilHeading>Inherited companies</RingilHeading>
        <Button label={'Add company'} onClick={() => setVisibleAddDialog({ visible: true, inheriterCompanyId: null })} />
      </RowWithSpaceBetween>

      {/*// @ts-ignore*/}
      <DataTable value={data} loading={loading}>
        <Column header={'ID'} body={(r: Company) => <div>{r.companyId}</div>} />
        <Column header={'Company name'} body={(r: Company) => <div>{r.name}</div>} />
        <Column header={'Modules'} body={(r: Company) => <div>{r.inheritedModules.join(', ')}</div>} />
        <Column
          header={''}
          body={(row: Company) => (
            <EditButton
              label={'Manage'}
              onClick={() =>
                setVisibleAddDialog({
                  inheriterCompanyId: row.companyId,
                  visible: true,
                })
              }
            />
          )}
        />
      </DataTable>

      <ManageInheritedModulesSidebar
        ownerCompanyId={companyId}
        visible={visibleAddDialog.visible}
        inheriterCompanyId={visibleAddDialog.inheriterCompanyId}
        onHide={() => {
          load();
          setVisibleAddDialog({ inheriterCompanyId: null, visible: false });
        }}
      />
    </>
  );
};

export default AdminCompanyInheriters;
