import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useContext, useEffect } from 'react';
import { AuditLogOutPreview } from '@api/logsteo-api.v2';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { CustomerExpeditionViewContext } from '@app/pages/customer/expedition-detail/CustomerExpeditionView.tsx';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';

const TabHistory = () => {
  const { t }: { t: any } = useTranslation('common');
  const { state, loadHistory } = useContext(CustomerExpeditionViewContext);

  useEffect(() => {
    loadHistory();
  }, []);

  const activityBodyTemplate = (rowData: AuditLogOutPreview) => {
    return (
      <>
        <ColumnTitle title={t(`TabHistory.activity`, `activity`)} />
        <ColumnContent content={rowData.type} />
      </>
    );
  };

  const descriptionTemplate = (rowData: AuditLogOutPreview) => {
    return (
      <>
        <ColumnTitle title={t(`TabHistory.description`, `Description`)} />
        <ColumnContent content={rowData.description} />
      </>
    );
  };

  const dateTimeBodyTemplate = (rowData: AuditLogOutPreview) => {
    return (
      <>
        <ColumnTitle title={t(`TabHistory.dataEndTime`, `data end time`)} />
        <ColumnContent content={dateFormatDateTime(mapFromAPIDateTime(rowData.creationDate))} />
      </>
    );
  };

  const userNameBodyTemplate = (rowData: AuditLogOutPreview) => {
    return (
      <>
        <ColumnTitle title={t(`TabHistory.executedBy`, `executed by`)} />
        <ColumnContent content={rowData.userName} />
      </>
    );
  };

  const companyBodyTemplate = (rowData: AuditLogOutPreview) => {
    return (
      <>
        <ColumnTitle title={t(`TabHistory.company`, `company`)} />
        <ColumnContent content={rowData.companyName} />
      </>
    );
  };

  return (
    <>
      <NavTitle>
        <Label title={t(`TabHistory.expeditionAuditLog`, `Expedition audit log`)} />
      </NavTitle>

      {state.tabHistory && state.tabHistory.data && state.tabHistory.data.length > 0 && (
        <>
          {/*//
      @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            value={state.tabHistory.data}
            className="document-table responsive-table"
            paginator={true}
            rows={5}
          >
            <Column field="activity" header={t(`TabHistory.activity`, `activity`)} body={activityBodyTemplate}></Column>
            <Column field="data" header={t(`TabHistory.dataEndTime`, `data end time`)} body={dateTimeBodyTemplate}></Column>
            <Column field="name" header={t(`TabHistory.executedBy`, `executed by`)} body={userNameBodyTemplate}></Column>
            <Column field="z firmy" header={t(`TabHistory.company`, `company`)} body={companyBodyTemplate}></Column>
            <Column field="description" header={t(`TabHistory.description`, `Description`)} body={descriptionTemplate}></Column>
          </DataTable>
        </>
      )}
    </>
  );
};
export default TabHistory;
