import { useContext, useState } from 'react';
import { ApiContext } from '@api/api';

interface ComponentProps {}

export const useDownload = () => {
  const { downloadAttachment } = useContext(ApiContext);
  const [downloadingId, setDownloadingId] = useState(undefined);

  const downloadFile = (attachmentId: string) => {
    setDownloadingId(attachmentId);
    downloadAttachment(attachmentId, data => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      const downloadLink = document.createElement('a');

      setDownloadingId(undefined);
      downloadLink.href = linkSource;
      downloadLink.download = data.fileName;
      downloadLink.click();
    });
  };

  return { downloadFile, downloadingId };
};
