import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import { Sidebar } from 'primereact/sidebar';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ExpeditionInternalInformationInDTO, InvoiceRebillingTypeEnum, InvoiceStateEnum } from '@api/logsteo-api.v2';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputResponsiblePersonPlain from '@components/obsolete/Form/InputResponsiblePerson/InputResponsiblePersonPlain.tsx';
import InputSubscriberPlain from '@components/obsolete/Form/InputSubscriberPlain/InputSubscriberPlain.tsx';
import InputDepartmentPlain from '@components/obsolete/Form/InputDepartmentPlain.tsx';
import InputPartnerPlain from '@components/obsolete/Form/InputPartner/InputPartnerPlain.tsx';
import ButtonWrapper from '@components/obsolete/Form/ButtonWrapper/ButtonWrapper.tsx';

interface ComponentProps {
  applicationId: string;
  companyId: string;
  visible: boolean;
  onHide: () => void;
  onChanged: () => void;
}

interface FormData extends ExpeditionInternalInformationInDTO {}

const initialData: FormData = {
  invoiceUserId: null,
  invoiceDepartment: '',
  invoiceRebillingType: null,
  invoiceState: null,
  subscribersIds: [],
  invoicePartnerId: null,
  responsiblePersonId: null,
  internalNote: null,
  pairingSymbol: null,
};

const UpdateExpeditionInternalInformationSidebar: React.FC<ComponentProps> = ({ onHide, onChanged, visible, companyId, applicationId }) => {
  const { tr } = useTranslationLgs();
  const { cuGetExpeditionInternalInformation, cuUpdateExpeditionInternalInformation } = useContext(ApiContext);

  useEffect(() => {
    load();
  }, [visible]);

  const validationSchema = yup.object().shape({
    invoiceDepartment: yup.mixed().when('invoiceRebillingType', {
      is: InvoiceRebillingTypeEnum.INTERNAL_REBILLING,
      then: yup.mixed().required(tr('validation.required', 'Required')),
      otherwise: yup.mixed().nullable(),
    }),
  });

  const load = () => {
    if (visible) {
      cuGetExpeditionInternalInformation(companyId, applicationId, data => {
        form.setValues({
          internalNote: data.internalNote,
          responsiblePersonId: data.responsiblePerson.id,
          subscribersIds: data.subscribers.map(t => t.id),
          invoiceDepartment: data?.invoiceDetail?.invoiceDepartment,
          invoicePartnerId: data?.invoiceDetail?.invoicePartner?.id,
          invoiceState: data?.invoiceDetail?.invoiceState,
          invoiceRebillingType: data?.invoiceDetail?.invoiceRebillingType,
          invoiceUserId: data?.invoiceDetail?.invoiceUser?.id,
          pairingSymbol: data?.pairingSymbol,
        });
      });
    }
  };

  const names = proxiedPropertiesOf<FormData>();

  const form = useForm<FormData>(
    validationSchema,
    initialData,
    v => {
      save(v);
    },
    false,
    false,
  );

  const save = (data: FormData) => {
    cuUpdateExpeditionInternalInformation(companyId, applicationId, form.values, () => {
      onChanged();
    });
  };

  return (
    <>
      {/*//
      @ts-ignore*/}
      <Sidebar onHide={onHide} visible={visible} position={'right'} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        <>
          {/*{dumpVars({ values: form.values, errors: form.errors })}*/}
          <HorizontalFieldValue
            label={tr(`UpdateExpeditionInternalInformationSidebar.responsiblePerson`, `Responsible person`)}
            value={<InputResponsiblePersonPlain responsiblePersonId={form.values.responsiblePersonId} onChange={v => form.setFieldValue('responsiblePersonId', v)} />}
          />
          <HorizontalFieldValue
            label={tr(`UpdateExpeditionInternalInformationSidebar.subscribers`, `Subscribers`)}
            value={
              <InputSubscriberPlain
                subscribers={form.values.subscribersIds.map(t => ({
                  id: t,
                  name: '',
                  active: true,
                }))}
                onChange={v =>
                  form.setFieldValue(
                    'subscribersIds',
                    v.map(t => t.id),
                  )
                }
              />
            }
          />
          <HorizontalFieldValue
            label={tr(`UpdateExpeditionInternalInformationSidebar.note`, `Note`)}
            value={
              <InputTextarea
                value={form.values.internalNote}
                onChange={e => form.setFieldValue('internalNote', e.target.value)}
                style={{ width: '100%', height: ' 7rem' }}
              />
            }
          />
          <HorizontalFieldValue
            label={tr(`UpdateExpeditionInternalInformationSidebar.reaccountTo`, `Reaccount to`)}
            value={
              <>
                <Dropdown
                  value={form.values.invoiceRebillingType}
                  style={{ width: '100%' }}
                  options={[
                    InvoiceRebillingTypeEnum.NON_REBILLING,
                    InvoiceRebillingTypeEnum.INTERNAL_REBILLING,
                    InvoiceRebillingTypeEnum.EXTERNAL_REBILLING,
                    InvoiceRebillingTypeEnum.OTHER,
                  ]}
                  showClear={true}
                  valueTemplate={e => <div>{tr(`UpdateExpeditionInternalInformationSidebar.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
                  itemTemplate={e => <div>{tr(`UpdateExpeditionInternalInformationSidebar.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
                  onChange={e => form.setFieldValue('invoiceRebillingType', e.target.value)}
                />
              </>
            }
          />

          {form.values.invoiceRebillingType === InvoiceRebillingTypeEnum.INTERNAL_REBILLING && (
            <HorizontalFieldValue
              label={tr(`UpdateExpeditionInternalInformationSidebar.internalDepartment`, `Internal department`)}
              validationError={form.errors.invoiceDepartment}
              value={
                <>
                  <InputDepartmentPlain department={form.values.invoiceDepartment} style={{ width: '100%' }} onChange={v => form.setFieldValue('invoiceDepartment', v)} />
                </>
              }
            />
          )}
          {form.values.invoiceRebillingType === InvoiceRebillingTypeEnum.EXTERNAL_REBILLING && (
            <HorizontalFieldValue
              label={tr(`UpdateExpeditionInternalInformationSidebar.externalPartner`, `External partner`)}
              value={
                <>
                  <InputPartnerPlain style={{ width: '100%' }} partnerId={form.values.invoicePartnerId} onChange={v => form.setFieldValue('invoicePartnerId', v)} />
                </>
              }
            />
          )}
          <HorizontalFieldValue
            label={tr(`UpdateExpeditionInternalInformationSidebar.disponent`, `Disponent`)}
            value={
              <InputResponsiblePersonPlain
                responsiblePersonId={form.values.invoiceUserId}
                style={{ width: '100%' }}
                onChange={v => form.setFieldValue('invoiceUserId', v)}
                showClearButton={true}
              />
            }
          />
          <HorizontalFieldValue
            label={tr(`UpdateExpeditionInternalInformationSidebar.invoicingState`, `Invoicing state`)}
            value={
              <Dropdown
                value={form.values.invoiceState}
                options={[InvoiceStateEnum.NON_INVOICED, InvoiceStateEnum.INVOICED]}
                showClear={true}
                style={{ width: '100%' }}
                valueTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceStateEnum.${e}`, e)}</div>}
                itemTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceStateEnum.${e}`, e)}</div>}
                onChange={e => form.setFieldValue('invoiceState', e.target.value)}
              />
            }
          />
          <HorizontalFieldValue
            label={tr(`UpdateExpeditionInternalInformationSidebar.pairingSymbol`, `Pairing symbol`)}
            value={<InputText value={form.values.pairingSymbol} onChange={e => form.setFieldValue(names.pairingSymbol, e.target.value)} />}
          />
          <ButtonWrapper>
            <Button label={tr(`UpdateExpeditionInternalInformationSidebar.save`, `Save`)} onClick={e => form.validateAndSend()}></Button>
          </ButtonWrapper>
        </>
      </Sidebar>
    </>
  );
};

export default UpdateExpeditionInternalInformationSidebar;
