import React from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import useCustomerCarriers from '@hooks/customer-carrier/customer-carriers';
import { Label, RowWithSpaceBetween } from '@components/styles.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';

interface ComponentProps {
  value: LovDTO[];
  onSelected: (value: LovDTO[]) => void;
  isDisabled: boolean;
  placeholder: string;
}

export const InputCarriersPick: React.FC<ComponentProps> = ({ value, onSelected, placeholder, isDisabled }) => {
  const { carriers } = useCustomerCarriers();

  return (
    <>
      {/*<MultiSelect
        value={value}
        options={carriers}
        onChange={(e) => {
          onSelected(e.value);
        }}
        style={{ maxWidth: '100%' }}
        optionLabel={'name'}
        dataKey={'id'}
        placeholder={placeholder}
        filter={true}
      />*/}
      {carriers.map((carrier, carrierKey) => {
        return (
          <React.Fragment key={carrierKey}>
            <RowWithSpaceBetween>
              <Label>{carrier.name}</Label>
              <InputYesNoPlain
                value={value.find(t => t.id == carrier.id) ? true : false}
                onChange={v => onSelected(v ? [...value, { name: carrier.name, id: carrier.id, active: true }] : value.filter(t => t.id !== carrier.id))}
              />
            </RowWithSpaceBetween>
          </React.Fragment>
        );
      })}
    </>
  );
};
