import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import CheckboxWithLabel from '../CheckboxWithLabel/CheckboxWithLabel';
import {
  DayEnum,
  OpeningEntityTypeEnum,
  OpeningHourDefinitionsDTO,
  OpeningHourDefinitionsInDTO,
  OpeningHoursDayDTO,
  OpeningHoursDTO,
  OpeningModeEnum,
} from '@api/logsteo-api.v2';
import produce from 'immer';
import { dumpVars, validateHourString } from '@utils/utils';
import ValidationDiv from '@utils/validation';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import GenereateDialog, { GeneratedSlot } from './GenereateDialog';
import GenerateDialog from './GenereateDialog';
import { CenteredRowWithGap, RingilH3 } from '@components/styles.tsx';
import InputHour from '@components/obsolete/Form/InputHour/InputHour.tsx';
import { Clickable } from '@components/ringil3/styles.tsx';

interface ComponentProps {
  openingHours: OpeningHourDefinitionsDTO | OpeningHourDefinitionsInDTO;
  onChange: (openingHours: OpeningHourDefinitionsDTO | OpeningHourDefinitionsInDTO) => void;
  validationPrefix?: string;
  validationErrors?: ValidationError[];
}

export const openingHoursValidationSchema = yup.object().shape({
  openingHoursDays: yup.array().of(
    yup.object().shape({
      day: yup.string().required(),
      intervalError: yup.mixed().test({
        name: 'intervals.not.disjunct',
        test: (value: any, testContext) => {
          const parent = testContext.parent as OpeningHoursDayDTO;

          if (parent.openingHours && parent.openingHours.length === 0) {
            return true;
          }

          if (parent.openingMode === OpeningModeEnum.OPEN || parent.openingMode === OpeningModeEnum.CLOSED) {
            return true;
          }

          /*          const invalidSlots = parent.openingHours.some((t) => t.since >= t.till);
          if (invalidSlots) {
            return false;
          }*/

          const a = parent.openingHours
            .filter(t => t.since !== '' && t.till !== '')
            .sort((a, b) => a.since.localeCompare(b.since))
            .map(t => {
              return [t.since, t.till];
            })
            .flat(1)
            .filter(t => t !== '');

          const result = a.reduce((acc, cur) => {
            if (acc <= cur) return cur;
            else return 'Error';
          }, '00:00');

          return result !== 'Error';
        },
        message: 'required',
        exclusive: true,
      }),
      openingHours: yup.array().of(
        yup.mixed().test({
          name: 'wrong.interval',
          test: (value: any, testContext) => {
            // @ts-ignore
            const parent = testContext.from[0].value as OpeningHoursDayDTO;
            if (parent.openingMode === OpeningModeEnum.CLOSED || parent.openingMode === OpeningModeEnum.OPEN) {
              return true;
            }
            const since = value.since;
            const till = value.till;
            if (validateHourString(since) && validateHourString(till)) {
              if (since < till) {
                return true;
              }
            }
            return false;
          },
          message: 'required',
          exclusive: true,
        }),
      ),
    }),
  ),
});

export const emptyOpeningHours = (openingEntityType: OpeningEntityTypeEnum) => {
  return {
    id: null,
    entityId: null,
    openingEntityType,
    openingHoursDays: [
      { day: DayEnum.MONDAY, openingMode: OpeningModeEnum.CLOSED },
      { day: DayEnum.TUESDAY, openingMode: OpeningModeEnum.CLOSED },
      { day: DayEnum.WEDNESDAY, openingMode: OpeningModeEnum.CLOSED },
      { day: DayEnum.THURSDAY, openingMode: OpeningModeEnum.CLOSED },
      { day: DayEnum.FRIDAY, openingMode: OpeningModeEnum.CLOSED },
      { day: DayEnum.SATURDAY, openingMode: OpeningModeEnum.CLOSED },
      { day: DayEnum.SUNDAY, openingMode: OpeningModeEnum.CLOSED },
    ],
  } as OpeningHourDefinitionsDTO;
};

const OpeningHourDefinitionForm: React.FC<ComponentProps> = ({ openingHours, onChange, validationPrefix, validationErrors }) => {
  const { tr } = useTranslationLgs();
  const openDay = (day: DayEnum) => {
    const ret = produce(openingHours, draft => {
      const d = draft.openingHoursDays.find(od => od.day === day);
      d.openingMode = OpeningModeEnum.PARTIALLY_OPEN;
      d.openingHours = [{ till: '', since: '' }];
    });
    onChange(ret);
  };

  const closeDay = (day: DayEnum) => {
    const ret = produce(openingHours, draft => {
      draft.openingHoursDays.find(od => od.day === day).openingMode = OpeningModeEnum.CLOSED;
    });
    onChange(ret);
  };

  const toggleMode = (day: DayEnum) => {
    const ret = produce(openingHours, draft => {
      const d = draft.openingHoursDays.find(od => od.day === day);
      if (d.openingMode === OpeningModeEnum.OPEN) {
        d.openingMode = OpeningModeEnum.PARTIALLY_OPEN;
        d.openingHours = [{ till: '', since: '' }];
      } else {
        d.openingMode = OpeningModeEnum.OPEN;
        d.openingHours = [{ till: '24:00', since: '00:00' }];
      }
    });
    onChange(ret);
  };

  const removeDay = (day: DayEnum, dayIntervalPosition: number) => {
    const ret = produce(openingHours, draft => {
      const d = draft.openingHoursDays.find(od => od.day === day);
      d.openingHours.splice(dayIntervalPosition, 1);
    });
    onChange(ret);
  };

  const addInterval = (day: DayEnum) => {
    const ret = produce(openingHours, draft => {
      const d = draft.openingHoursDays.find(od => od.day === day);
      d.openingHours.push({ till: '', since: '' });
    });
    onChange(ret);
  };

  const changeSince = (day: DayEnum, dayIntervalPosition: number, time: string) => {
    // if time do not contains numbers and : then return
    if (time.match(/[0-9:]*/)[0] === time) {
      const ret = produce(openingHours, draft => {
        const d = draft.openingHoursDays.find(od => od.day === day);
        d.openingHours[dayIntervalPosition].since = time;
      });
      onChange(ret);
    }
  };

  const changeTill = (day: DayEnum, dayIntervalPosition: number, time: string) => {
    if (time.match(/[0-9:]*/)[0] === time) {
      const ret = produce(openingHours, draft => {
        const d = draft.openingHoursDays.find(od => od.day === day);
        d.openingHours[dayIntervalPosition].till = time;
      });
      onChange(ret);
    }
  };

  const renderWholeDayIfNot = (openingHours: OpeningHoursDTO[]) => {
    if (openingHours !== null && openingHours.length > 0) return openingHours;
    else return [{ since: '00:00', till: '24:00' }] as OpeningHoursDTO[];
  };

  const [generateForDay, setGenerateForDay] = useState(null);
  const showGeneratingForm = (day: DayEnum) => {
    setGenerateForDay(day);
  };

  const onSlotsGenerated = (day: DayEnum, generatedSlots: GeneratedSlot[]) => {
    const ret = produce(openingHours, draft => {
      const d = draft.openingHoursDays.find(od => od.day === day);
      d.openingMode = OpeningModeEnum.PARTIALLY_OPEN;
      d.openingHours = generatedSlots.map(t => {
        return { since: t.sinceHourString, till: t.tillHourString };
      });
    });
    onChange(ret);
  };

  return (
    <>
      <RingilH3>{tr(`OpeningHourDefinitionForm.openingHours`, `Opening hours`)}</RingilH3>
      <OpeningHoursWrapper>
        {openingHours?.openingHoursDays?.map((openingDay, odIndex) => {
          return (
            <DayWrapper key={odIndex}>
              <Day>{tr(`TimeIntervalComponent.days${openingDay.day}`, openingDay.day)}</Day>
              {openingDay.openingMode === OpeningModeEnum.CLOSED && (
                <CenteredRowWithGap>
                  <span>{tr(`OpeningHourDefinitionForm.isClosed`, `Is closed`)}</span>
                  <Button label={tr(`OpeningHourDefinitionForm.open`, `Open`)} className={'p-button-outlined'} onClick={e => openDay(openingDay.day)} />
                  <Button
                    label={tr(`OpeningHourDefinitionForm.generate`, `Generate`)}
                    className={'p-button-outlined'}
                    onClick={e => showGeneratingForm(openingDay.day)}
                  />
                </CenteredRowWithGap>
              )}
              {openingDay.openingMode !== OpeningModeEnum.CLOSED && (
                <>
                  {renderWholeDayIfNot(openingDay.openingHours).map((hours, hIndex) => {
                    return (
                      <React.Fragment key={hIndex}>
                        <FourGrid>
                          <InputHour
                            value={hours.since}
                            readOnly={openingDay.openingMode === OpeningModeEnum.OPEN}
                            disabled={openingDay.openingMode === OpeningModeEnum.OPEN}
                            onChange={v => changeSince(openingDay.day, hIndex, v)}
                          />
                          <InputHour
                            value={hours.till}
                            readOnly={openingDay.openingMode === OpeningModeEnum.OPEN}
                            disabled={openingDay.openingMode === OpeningModeEnum.OPEN}
                            onChange={v => changeTill(openingDay.day, hIndex, v)}
                          />

                          {hIndex === 0 ? (
                            <CheckboxWithLabel
                              checkboxLabel={tr(`OpeningHourDefinitionForm.openNonStop`, `Open non-stop`)}
                              value={openingDay.openingMode === OpeningModeEnum.OPEN}
                              onChange={v => {
                                toggleMode(openingDay.day);
                              }}
                              disabled={false}
                            />
                          ) : (
                            <div></div>
                          )}

                          {hIndex === 0 ? (
                            <Button
                              label={tr(`OpeningHourDefinitionForm.itIsClosed`, `It is closed`)}
                              className={'p-button-outlined'}
                              onClick={e => closeDay(openingDay.day)}
                            ></Button>
                          ) : (
                            <Button label={'Odebrat'} className={'p-button-outlined'} onClick={e => removeDay(openingDay.day, hIndex)}></Button>
                          )}
                        </FourGrid>
                        <ValidationDiv errors={validationErrors} path={`${validationPrefix}openingHoursDays[${odIndex}].openingHours[${hIndex}]`} />
                      </React.Fragment>
                    );
                  })}
                  <ValidationDiv errors={validationErrors} path={`${validationPrefix}openingHoursDays[${odIndex}].intervalError`} />
                  {openingDay.openingMode === OpeningModeEnum.PARTIALLY_OPEN && (
                    <AddWrapper>
                      <Clickable onClick={e => addInterval(openingDay.day)}>{tr(`OpeningHourDefinitionForm.addWorkingHours`, `+ add working hours`)}</Clickable>
                    </AddWrapper>
                  )}
                </>
              )}
            </DayWrapper>
          );
        })}
        <GenerateDialog
          generateForDay={generateForDay}
          onHide={() => setGenerateForDay(null)}
          onGenerateSlots={slots => {
            onSlotsGenerated(generateForDay, slots);
            setGenerateForDay(null);
          }}
        />
      </OpeningHoursWrapper>
    </>
  );
};

const AddWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const FourGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 1fr 150px;
  gap: 0.5rem;
  margin: 0.3rem 0;
`;

const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Day = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const OpeningHoursWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
`;

export default OpeningHourDefinitionForm;
