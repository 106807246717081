import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { formatPrice } from '@utils/utils';
import { Dayjs } from 'dayjs';
import { dateFormatDateTime, dateFormatOnlyDateShort, mapFromAPIDateTime, mapFromAPIToTime } from '@utils/date';
import { DistributionTypeEnum, LocationTimeslotQuoteDemandOutDTO } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import { DefaultFormPanel } from '@components/obsolete/DefaultFormPanel/DefaultFormPanel.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';

interface ComponentProps {
  distributionType: DistributionTypeEnum;
  enableReject: boolean;
  onReject: () => void;
  enableChangePrice: boolean;
  enableChangeTimeslots: boolean;
  price: number;
  currency: string;
  quoteValidTill: Dayjs;
  timeslots: LocationTimeslotQuoteDemandOutDTO[];
  code?: string;
}

const MyQuoteSummaryComponent: React.FC<ComponentProps> = ({
  distributionType,
  price,
  currency,
  enableReject,
  onReject,
  enableChangePrice,
  quoteValidTill,
  enableChangeTimeslots,
  timeslots,
  code,
}) => {
  const { t } = useTranslation('common');
  const [editPriceModalVisible, setEditModalPriceVisible] = useState(false);
  const [timeslotModalVisible, setTimeslotsModalVisible] = useState(false);

  return (
    <div className="p-col-12 p-lg-6">
      <DefaultFormPanel>
        <NavTitle wrapper="PADDING-0">
          <div>
            {t('labelYourPriceQuote')}
            <p className="p-m-0">
              <small>
                <strong>{formatPrice(price, currency)}</strong>
              </small>
            </p>
          </div>
          {enableChangePrice && (
            <Button
              label={t('labelChangeBtn')}
              className="p-button-outlined p-btn-edit p-btn-edit-tab"
              iconPos="left"
              icon="pi pi-pencil"
              onClick={() => setEditModalPriceVisible(true)}
            />
          )}
        </NavTitle>
        {distributionType === DistributionTypeEnum.SPOT && (
          <div className="p-mt-3 p-mb-3">
            <div>{t(`MyQuoteSummaryComponent.validTill`, `Vaše nabídka platí do`)}</div>
            <div>
              <span>{dateFormatDateTime(quoteValidTill)}</span>
            </div>
          </div>
        )}

        <hr />

        <NavTitle>
          <div>{t(`MyQuoteSummaryComponent.acceptedTimeslots`, `Accepted timeslots`)}</div>
          {enableChangeTimeslots && (
            <Button
              label={t(`MyQuoteSummaryComponent.change`, `Change`)}
              className="p-button-outlined p-btn-edit p-btn-edit-tab"
              iconPos="left"
              icon="pi pi-pencil"
              onClick={() => setTimeslotsModalVisible(true)}
            />
          )}
        </NavTitle>

        <div className="timeslots-wrapper p-mt-4">
          {timeslots.map((location, locationIndex) => {
            return (
              <React.Fragment key={locationIndex}>
                <PanelWithIcon numberIcon={`${locationIndex + 1}`} wrapper="PADDING_BOTTOM_0">
                  <Label title={`${location.zipCode}, ${location.city} (${location.country})`} />
                </PanelWithIcon>
                <div className="time-shell">
                  {location.timeslots
                    .filter(ts => ts.isCarrier === false)
                    .map((timeslot, timeslotIndexKey) => {
                      return (
                        <TimeListItem
                          key={timeslotIndexKey}
                          date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                          isWholeDay={timeslot.isNotSpecifiedTime}
                          time={`${mapFromAPIToTime(timeslot.sinceInUTC)} - ${mapFromAPIToTime(timeslot.tillInUTC)}`}
                        />
                      );
                    })}
                </div>
              </React.Fragment>
            );
          })}
        </div>

        {enableReject && (
          <>
            <hr />
            <NavTitle>
              <div>{t(`MyQuoteSummaryComponent.doYouWantToCancelYourPrice`, `Do you want to cancel your price?`)}</div>

              <Button
                label={t(`MyQuoteSummaryComponent.reject`, `Reject`)}
                className="p-button-outlined p-btn-edit p-btn-edit-tab"
                iconPos="left"
                icon="pi pi-times"
                onClick={() => onReject()}
              />
            </NavTitle>
          </>
        )}
      </DefaultFormPanel>
    </div>
  );
};

export default MyQuoteSummaryComponent;
