import React, { PropsWithChildren } from 'react';

interface RCPP {
  large: number;
  medium: number;
  small: number;
}

export const RC: React.FC<PropsWithChildren<RCPP>> = ({ children, medium, small, large }) => {
  return <div className={`p-lg-${large} p-md-${medium} p-sm-${small}`}>{children}</div>;
};

interface RRCP {}

export const RR: React.FC<PropsWithChildren<RRCP>> = ({ children }) => {
  return (
    <div className={'p-grid p-col-12 p-d-flex '} style={{ margin: '0 -1rem' }}>
      {children}
    </div>
  );
};
