import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage';
import RingilButton from '@components/ringil3/Buttons/RingilButton';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  onDelete: (objToDelete: any) => void;
}

export const useCreateDeleteDialog = () => {
  const [objToDelete, setObjToDelete] = useState(undefined);

  const DeleteDialog: React.FC<ComponentProps> = ({ onDelete }) => {
    const { tr } = useTranslationLgs();
    return (
      <>
        {/*
        // @ts-ignore*/}
        <Dialog header={<div>{tr(`DeleteDialog.realyDelete`, `Realy delete`)}</div>} visible={isNotNullOrUndefined(objToDelete)} onHide={() => setObjToDelete(undefined)}>
          <DialogInner>
            <COInlineMessage variant={'warning'}>{tr(`DeleteDialog.deleteWarning`, `Be careful, deletion cannot be undone`)}</COInlineMessage>
            <DialogButtons>
              <RingilButton
                label={tr(`RingilSidebar.yesDelete`, `Yes, delete`)}
                onClick={() => {
                  onDelete(objToDelete);
                  setObjToDelete(undefined);
                }}
                mode={'filled'}
                size={'big'}
                iconPath={'/images/icons/ringil3/buttons/deleteWhite.svg'}
              />
              <RingilButton label={tr(`RingilSidebar.back`, `Back`)} onClick={() => setObjToDelete(undefined)} mode={'text-only'} size={'big'} />
            </DialogButtons>
          </DialogInner>
        </Dialog>
      </>
    );
  };

  return { DeleteDialog, setObjToDelete };
};

const DialogInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DialogButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;
