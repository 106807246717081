import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import { LocationTimeslotQuoteDemandOutDTO, MakeOfferInDTO, MakeOfferLocationSlotInDTO, MyQuoteInfoOutDTO, TimeSlotQuoteDemandOutDTO } from '@api/logsteo-api.v2';
import { mapToAPIDateTime, setTime } from '@utils/date';
import { ApiContext } from '@api/api';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { DemandDetailView } from '@app/pages/carrier/demand-detail/types.ts';
import { CreateSpotState } from '@app/pages/carrier/demand-detail/CreateSpot/CreateSpotComponent.tsx';
import { LocationTimeSlots } from '@app/pages/carrier/demand-detail/EditSlots/EditSlotsComponent.tsx';

interface DemandDetailContextType {
  BackendValidationComponent: any;
  state: DemandDetailView;
  acceptFixedPrice: (customerId: string, applicationId: string, quoteId: string) => void;
  rejectFixedPrice: (customerId: string, applicationId: string, quoteId: string) => void;
  changeTimeSlots: (data: LocationTimeSlots[]) => void;
  rejectSpot: (customerId: string, applicationId: string, quoteId: string) => void;
  makeQuote: (customerId: string, applicationId: string, quoteId: string, data: CreateSpotState, locationTimeSlots: LocationTimeslotQuoteDemandOutDTO[]) => void;
  createQuote: (customerId: string, applicationId: string, quote: MyQuoteInfoOutDTO, timeslots: LocationTimeslotQuoteDemandOutDTO[]) => void;
  reconfirm: (companyId: string, applicationId: string, onSuccess?: () => void) => void;
  loading: boolean;
}

export const DemandDetailContext = createContext<DemandDetailContextType>(undefined);

interface ComponentProps {
  initialData: DemandDetailView;
}

const DemandDataProvider: React.FC<PropsWithChildren<ComponentProps>> = ({ initialData, children }) => {
  const [state, setState] = useImmer<DemandDetailView>(initialData);
  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
  const { caAcceptFixedPrice, caGetDemandDetail, caRejectFixedPrice, caMakeQuote, caCancelDemand, caMakeAnotherNewQuote, caReconfirmedExpedition } =
    useContext(ApiContext);

  const [loading, setLoading] = useState(false);

  const methods: DemandDetailContextType = {
    BackendValidationComponent,
    state,
    acceptFixedPrice: (customerId: string, applicationId: string, quoteId: string) => {
      setLoading(true);
      caAcceptFixedPrice(
        customerId,
        applicationId,
        quoteId,
        () => {
          caGetDemandDetail(customerId, applicationId, data => {
            setState(draft => data);
            setLoading(false);
          });
        },
        null,
        {
          onValidationFailed: errors => {
            setBeValidationErrors(errors);
            setLoading(false);
          },
        },
      );
    },
    rejectFixedPrice: (customerId: string, applicationId: string, quoteId: string) => {
      caRejectFixedPrice(customerId, applicationId, quoteId, () => {
        caGetDemandDetail(customerId, applicationId, data => {
          setState(draft => data);
        });
      });
    },
    changeTimeSlots: (data: LocationTimeSlots[]) => {
      setState(draft => {
        draft.timeslots.forEach(location => {
          const newLocation = data.find(l => l.id === location.locationId);
          const carrierSlots = newLocation.useCustomSlots ? [newLocation.carrierSlot] || [] : [];
          const customerSlots = newLocation.customerSlots;

          location.timeslots = [
            customerSlots.map(slot => {
              return {
                id: '',
                isCarrier: false,
                isSelected: carrierSlots.length == 0,
                isNotSpecifiedTime: slot.isWholeDay,
                sinceInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.sinceTime)),
                tillInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.tillTime)),
              } as TimeSlotQuoteDemandOutDTO;
            }),
            carrierSlots.map(slot => {
              return {
                id: '',
                isCarrier: true,
                isSelected: true,
                sinceInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.sinceTime)),
                tillInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.tillTime)),
              } as TimeSlotQuoteDemandOutDTO;
            }),
          ].flat(1);
        });
      });
    },
    rejectSpot: (customerId: string, applicationId: string, quoteId: string) => {
      caCancelDemand(customerId, applicationId, quoteId, () => {
        caGetDemandDetail(customerId, applicationId, data => {
          setState(draft => data);
        });
      });
    },
    makeQuote: (customerId: string, applicationId: string, quoteId: string, data: CreateSpotState, locationTimeSlots: LocationTimeslotQuoteDemandOutDTO[]) => {
      const body: MakeOfferInDTO = {
        price: data.price,
        currency: data.currency,
        validTill: mapToAPIDateTime(data.selectedData),
        timeslots: locationTimeSlots.map(locationQueueSlot => {
          return {
            locationId: locationQueueSlot.locationId,
            useCustomerSlot: locationQueueSlot.timeslots.findIndex(a => a.isCarrier) < 0,
            differentTimeslots: locationQueueSlot.timeslots
              .filter(a => a.isCarrier)
              .map(s => {
                return {
                  timeslotSince: s.sinceInUTC,
                  timeslotTill: s.tillInUTC,
                };
              }),
          } as MakeOfferLocationSlotInDTO;
        }),
      };
      caMakeQuote(
        customerId,
        applicationId,
        quoteId,
        body,
        () => {
          caGetDemandDetail(customerId, applicationId, data => {
            setState(draft => data);
          });
        },
        null,
        { onValidationFailed: v => setBeValidationErrors(v) },
      );
    },
    createQuote: (customerId: string, applicationId: string, quote: MyQuoteInfoOutDTO, timeslots: LocationTimeslotQuoteDemandOutDTO[]) => {
      const body: MakeOfferInDTO = {
        price: quote.myQuotePrice,
        currency: quote.myQuoteCurrency,
        validTill: quote.myQuoteValidTill,
        timeslots: timeslots.map(locationQueueSlot => {
          return {
            locationId: locationQueueSlot.locationId,
            useCustomerSlot: locationQueueSlot.timeslots.findIndex(a => a.isCarrier) < 0,
            differentTimeslots: locationQueueSlot.timeslots
              .filter(a => a.isCarrier)
              .map(s => {
                return {
                  timeslotSince: s.sinceInUTC,
                  timeslotTill: s.tillInUTC,
                };
              }),
          } as MakeOfferLocationSlotInDTO;
        }),
      };

      caMakeAnotherNewQuote(customerId, applicationId, body, () => {
        caGetDemandDetail(customerId, applicationId, data => {
          setState(draft => data);
        });
      });
    },
    reconfirm: (companyId: string, applicationId: string, onSuccess: () => void = () => {}) => {
      caReconfirmedExpedition(companyId, applicationId, () => {
        caGetDemandDetail(companyId, applicationId, data => {
          setState(draft => data);
          onSuccess();
        });
      });
    },
    loading,
  };

  return <DemandDetailContext.Provider value={methods}>{children}</DemandDetailContext.Provider>;
};

export default DemandDataProvider;
