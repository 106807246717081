import React, { useContext, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { LocationDetailPlanOutDTO, OrderDetailPlanOutDTO } from '@api/logsteo-api.v2';
import { formatWeight, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import LoadInfoItem from '@components/obsolete/LoadDetails/LoadInfo/LoadInfoItem.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import Address from '@components/obsolete/Address/Address.tsx';
import { ExpeditionDetailHandlerContext } from '@app/pages/carrier/expedition-detail/ExpeditionDetailHandler.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import CardView from '@components/obsolete/CardView/CardView.tsx';
import SelectedTruckType from '@components/SelectedTruckType.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import CheckboxItem from '@components/obsolete/CheckboxWrapper/CheckboxItem.tsx';
import InfoProvided from '@components/obsolete/InfoProvided/InfoProvided.tsx';
import InfoProvidedItem from '@components/obsolete/InfoProvided/InfoProvidedItem.tsx';
import AccordionTitle from '@components/obsolete/AccordionTitle/AccordionTitle.tsx';
import { ExpeditionContactInfo } from '@components/obsolete/common/ExpeditionContactInfo.tsx';

interface LoadingUloadingDetailProps {
  loading: boolean;
  order: OrderDetailPlanOutDTO;
  carrierCode?: string;
  isFirstElement?: boolean;
}

const LoadingUloadingDetail: React.FC<LoadingUloadingDetailProps> = ({ loading, order, carrierCode = '-', isFirstElement = true }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">{isFirstElement && <img src={loading ? '/images/icon_pickup.png' : '/images/icon_dropoff.png'} alt="" />}</div>
          <div>
            {isFirstElement && <Label title={loading ? t('TabExpeditionRoute.pickup', 'Pickup') : t('TabExpeditionRoute.dropoff', 'Dropoff')} />}
            <LoadInfoItem bookingRef={order.name} className="p-mb-3" totalWeight={formatWeight(order.totalWeight)} />
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-5 p-lg-4">
        {order?.cargos?.map((orderItem, orderItemIndex) => {
          return (
            <LoadInfoList
              key={orderItemIndex}
              title={`${orderItem.name} (${orderItem.quantity} ${translateQuantityCode(orderItem.quantityUnit, tr)})`}
              description={weightAndDimensions(orderItem.height, orderItem.length, orderItem.width, orderItem.weight, t)}
              stackable={orderItem.stackable}
              cargoMessage={orderItem.cargoMessage}
            />
          );
        })}
      </div>
    </div>
  );
};

interface ContactInfoProps {
  location: LocationDetailPlanOutDTO;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ location }) => {
  const { t }: { t: any } = useTranslation('common');
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">
            <img src="/images/contacts.svg" alt="" />
          </div>
          <div>
            <Label title={t('expeditionOverviewPage.labelContacts')} />
            <LoadInfoItem name={location.contactName} phone={location.contactPhone} email={location.contactEmail} className="p-mb-3"></LoadInfoItem>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-5 p-lg-4">
        <Address company={`${location.name}, ${location.address.city}`} address={`${location.address.streetNr}, ${location.address.zipCode}`} />
        {/*<Address company="Firma XYZ, České Budějovice" address="Sokolovská 8, 180 00" />*/}
      </div>
    </div>
  );
};

const TabExpeditionRoute = () => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { state, loadShipmentPlan } = useContext(ExpeditionDetailHandlerContext);

  useEffect(() => {
    loadShipmentPlan();
  }, []);

  return (
    <>
      {state.tabExpeditionPlan && (
        <>
          <NavTitle>
            <Label title={t('loadDetailsPage.titlePage')} />
          </NavTitle>
          <div className="p-d-flex p-grid">
            <CardView title={t(`global.expeditionTypeShort${state.header.expeditionType}`)} option={t(`global.expeditionType${state.header.expeditionType}`)} />
            {state.tabExpeditionPlan.truckType && <SelectedTruckType selectedTruck={state.tabExpeditionPlan.truckType} />}
            <CardView
              title={
                state.tabExpeditionPlan.loadingTypes &&
                state.tabExpeditionPlan.loadingTypes
                  .map(loadingType => {
                    return t(`global.manipulation.${loadingType}`);
                  })
                  .join(',')
              }
              option={t('expeditionOverviewPage.manipulationTitle')}
            />

            <CardView title={tr('TabExpeditionRoute.-Km', '- km')} option={t('expeditionOverviewPage.routeLength')} />
          </div>
          <ButtonLayout wrapper="WITH_PADDING_TOP_2">
            {state.tabExpeditionPlan.requirements.map((requirement, requirementIndex) => (
              <CheckboxItem className="p-md-3" key={requirementIndex}>
                <Checkbox checked={true} className="p-mr-2" disabled />
                <label>{requirement.name}</label>
              </CheckboxItem>
            ))}
          </ButtonLayout>
          <InfoProvided className="p-mt-3">
            <InfoProvidedItem title={t(`TabExpeditionRoute.cargoType`, `Typ nákladu`)}>
              <p className="p-mt-1">{state.tabExpeditionPlan.cargoType.name}</p>
            </InfoProvidedItem>

            {state.tabExpeditionPlan.carrierNote && (
              <InfoProvidedItem title={t(`TabExpeditionRoute.carrierNote`, `Poznámka pro dopravce`)}>
                <p className="p-mt-1">{state.tabExpeditionPlan.carrierNote}</p>
              </InfoProvidedItem>
            )}
          </InfoProvided>
          <div className="p-mt-3">
            <div className="p-mt-6">
              <Label title={t('expeditionOverviewPage.labelRoute')} />
              {/*// @ts-ignore*/}
              <Accordion multiple={true} activeIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}>
                {state.tabExpeditionPlan.locations.map((location, index, locations) => {
                  return (
                    // @ts-ignore
                    <AccordionTab
                      key={index}
                      header={
                        <>
                          <AccordionTitle
                            number={index + 1}
                            title={location.name}
                            pretitle={location.address.city}
                            btnTitle={t('expeditionOverviewPage.accordionDetail')}
                          />
                        </>
                      }>
                      {location.loadedOrders?.length > 0 &&
                        location.loadedOrders.map((loadingOrders, orderIndex) => {
                          return <LoadingUloadingDetail loading={true} order={loadingOrders} carrierCode={location.pickupCode} isFirstElement={orderIndex === 0} />;
                        })}
                      {location.unloadedOrders?.length > 0 &&
                        location.unloadedOrders.map((unloadedOrder, orderIndex) => {
                          return (
                            <LoadingUloadingDetail
                              key={orderIndex}
                              loading={false}
                              order={unloadedOrder}
                              carrierCode={location.pickupCode}
                              isFirstElement={orderIndex === 0}
                            />
                          );
                        })}

                      <ExpeditionContactInfo location={location} />
                    </AccordionTab>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TabExpeditionRoute;
