import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTransportationTypeTrain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#616061"
      fillRule="evenodd"
      d="M2 3a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v8a3 3 0 0 1-1.744 2.725L13.23 16h-2.176l-.857-2H5.802l-.857 2H2.77l.975-2.275A3 3 0 0 1 2 11zm2 0h3v4H4zm8 0H9v4h3zM7 9h2v2H7z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTransportationTypeTrain;
