import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { InputText } from 'primereact/inputtext';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';
import { CompanyModuleEnum, GetCustomerNotificationDetail } from '@api/logsteo-api.v2.tsx';
import InputCompanyModule from '@components/framework/input/InputCompanyModule/InputCompanyModule.tsx';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';
import { HorizontalLine } from '@components/styles.tsx';
import InputActionButton from '@components/framework/buttons/InputActionButton.tsx';
import InputLanguage from '@components/framework/input/InputLanguage/InputLanguage.tsx';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {}

export const useCreateDetailCompanyNotificationSidebar = () => {
  const [visibleNotificationId, setVisibleNotificationId] = useState(undefined);

  const DetailCompanyNotificationSidebar: React.FC<ComponentProps> = () => {
    const { getCustomerNotificationDetail } = useContext(ApiContext);
    const { tr } = useTranslationLgs();
    const [data, setData] = useState<GetCustomerNotificationDetail>();

    useEffect(() => {
      if (isNotNullOrUndefined(visibleNotificationId)) {
        getCustomerNotificationDetail(visibleNotificationId, (d) => {
          setData(d);
        });
      }
    }, [visibleNotificationId]);

    if (isNullOrUndefined(data)) return <></>;

    return (
      <>
        <RingilSidebar
          visible={isNotNullOrUndefined(visibleNotificationId)}
          heading={tr(`CreateCompanyNotificationSidebar.header`, `Create company notification`)}
          onContinue={() => {}}
          onBack={() => setVisibleNotificationId(undefined)}
          showSaveButton={false}>
          <Field label={tr(`CreateCompanyNotificationSidebar.module`, `Modul`)} required>
            <InputCompanyModule
              value={data.companyModule}
              onChange={(v) => {}}
              permittedModules={[CompanyModuleEnum.SUPPLIER_NOTIFICATION, CompanyModuleEnum.TIMESLOTS, CompanyModuleEnum.SHIPMENT, CompanyModuleEnum.EXPEDITION]} />

          </Field>
          <div className={'grid grid-cols-2 gap-4'}>
            <Field label={tr(`CreateCompanyNotificationSidebar.validSince`, `Platnost od`)} required>
              <RingilCalendar value={data.since} onChange={(v) => {}} />
            </Field>
            <Field label={tr(`CreateCompanyNotificationSidebar.validTill`, `Platnost do`)} required>
              <RingilCalendar value={data.till} onChange={(v) => {}} />
            </Field>
          </div>
          {/*<Tabs />*/}
          {data.messages.map((m, mIndex) => {
            return (
              <div className={'flex flex-col bg-white mx-[-15px] my-[15px] px-[15px] py-4'} key={mIndex}>
                <Field
                  label={tr(`CreateCompanyNotificationSidebar.labguage`, `Jazyk`)}
                  required
                  buttonsMode={'actionButton'}
                  actionButtons={<InputActionButton title={tr("DetailCompanyNotificationSidebar.delete", "Delete")} onClick={() => {}} />}>
                  <InputLanguage value={m.language} onChange={(v) => {}} />
                </Field>
                <Field label={tr(`CreateCompanyNotificationSidebar.subject`, `Zpráva`)} required>
                  <InputText placeholder={tr(`CreateCompanyNotificationSidebar.subjectPlaceholder`, `Předmět zprávy`)} value={m.subject} onChange={(e) => {}} />
                </Field>
                <Field required>
                  <RingilTextArea
                    maxLength={255}
                    value={m.body}
                    onChange={(v) => {}}
                    placeholder={tr(`CreateCompanyNotificationSidebar.textAreaPlaceholder`, `Text zprávy`)} />

                </Field>
                <div className={'py-4'}>
                  <HorizontalLine variant={'tertiary'} />
                </div>
              </div>);

          })}

          {/*<div>
             {data.attachments.map((attachment, attIndex) => {
               return (
                 <div key={attIndex}>
                   <COItemDisplay
                     icon={<SvgDocument />}
                     label={attachment.fileName}
                     rightIcon={
                       <SvgDeleteIcon
                         className={'cursor-pointer'}
                         onClick={() => {
                           deleteFile(attIndex);
                         }}
                       />
                     }
                   />
                 </div>
               );
             })}
            </div>*/}
        </RingilSidebar>
      </>);

  };

  return { DetailCompanyNotificationSidebar, setVisibleNotificationId, visibleNotificationId };
};

const enum Langs {
  cs = 'cs',
  en = 'en',
}