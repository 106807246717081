import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#616061"
      d="M12 16.287a1.26 1.26 0 0 1-.9-.374L5.205 9.967a1.274 1.274 0 0 1 1.699-1.8L12 13.263l5.096-5.097a1.274 1.274 0 0 1 1.699 1.801l-5.946 5.946a1.26 1.26 0 0 1-.849.373"
    />
  </svg>
);
export default SvgMenuDown;
