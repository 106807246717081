import React, { useContext } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Form, proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { RingilH3 } from '@components/styles';
import { isNullOrUndefined } from '@utils/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

export interface SupplierNotificationInternalNoteData {
  internalNote?: string;
}

interface ComponentProps {
  form: Form<SupplierNotificationInternalNoteData>;
  inSidebar: boolean;
}

export const supplierNotificationValidation = yup.object().shape({});

const SupplierNotificationInternalNoteForm: React.FC<ComponentProps> = ({ form, inSidebar }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  if (isNullOrUndefined(loggedUser)) return <></>;

  const names = proxiedPropertiesOf<SupplierNotificationInternalNoteData>();

  return (
    <>
      <RingilH3>{tr(`CreateSupplierNotification.internalNote`, `Internal note`)}</RingilH3>
      <HorizontalFieldValue
        label={tr('SupplierNotificationInternalDataForm.internalNote', 'Internal note')}
        value={
          <InputTextarea rows={6} style={{ width: '100%' }} value={form.values.internalNote} onChange={e => form.setFieldValue(names.internalNote, e.target.value)} />
        }
      />
    </>
  );
};

export default SupplierNotificationInternalNoteForm;
