import React, { useState } from 'react';
import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import useTranslationLgs from '@/hooks/i18n/useTranslation.tsx';
import { Clickable, Ringil3H2 } from '@/components/styles.tsx';

interface ComponentProps {}

const ExpeditionAd: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Inner>
        <Ringil3H2>{tr(`ExpeditionAd.expeditions`, `Systém řízení přeprav`)}</Ringil3H2>
        <p>{tr(`ExpeditionAd.description`, `Spravujte všechny svoje přepravy. Kdykoliv. Kdekoliv.`)}</p>
        <Clickable>
          <a
            onClick={e => {
              setVisible(true);
            }}
          >
            {tr(`ExpeditionAd.interestButton`, `Mam zajem`)}
          </a>
        </Clickable>
      </Inner>
      {/*
        // @ts-ignore*/}
      <Dialog onHide={() => setVisible(false)} visible={visible} header={tr(`ExpeditionAd.header`, `Mám zájem o modul Systém řízení přeprav`)}>
        <p>{tr(`ExpeditionAd.line1`, `Získejte přehled o čekajících poptávkách a mějte pod kontrolou přiřazené přepravy.`)}</p>
        <strong>{tr(`ExpeditionAd.keyFeatures`, `Klíčové vlastnosti`)}</strong>
        <Ul>
          <li>{tr(`ExpeditionAd.feature1`, `Přepravy`)}</li>
          <li>{tr(`ExpeditionAd.feature2`, `Poptávky`)}</li>
          <li>{tr(`ExpeditionAd.feature3`, `Dopravci a skupiny dopravců`)}</li>
          <li>{tr(`ExpeditionAd.feature4`, `Šablony`)}</li>
        </Ul>
        <br />
        <b>
          {tr(`ExpeditionAd.contactUs`, `V případě zájmu o modul nás prosím kontaktujte na`)}{' '}
          <a
            href={
              'mailto:podpora@ringil.com?subject=Zájem o modul Systém řízení přeprav&body=Dobrý den, mám zájem používat modul Systém řízení přeprav. Prosím o poskytnutí více informací. Děkuji.'
            }
          >
            podpora@ringil.com
          </a>
        </b>
      </Dialog>
    </>
  );
};

const Ul = styled.ul`
  padding: 5px;
  list-style-position: inside;
  list-style-type: disc;
`;

const Inner = styled.div`
  display: flex;
  row-gap: 0.4rem;
  padding: 1rem 2rem;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgb(102 102 102 / 35%);
`;

export default ExpeditionAd;
