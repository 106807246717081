import React, { useContext, useState } from 'react';
import ShipmentOrdersForm, { mapOders, ShipmentOrdersData } from '../forms/ShipmentOrdersForm';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';

interface ComponentProps {
  initialData?: ShipmentOrdersData;
  shipmentId: string;
  companyId: string;
}

export const useCreateEditShipmentOrdersSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditShipmentOrdersSidebar: React.FC<ComponentProps> = ({ initialData, shipmentId, companyId }) => {
    const { updateShipmentUpdateDataFoCargo } = useContext(ApiContext);
    const { tr } = useTranslationLgs();

    const form = useForm<ShipmentOrdersData>(
      {},
      initialData,
      v => {
        updateShipmentUpdateDataFoCargo(shipmentId, { orders: form.values.orders.map(mapOders) }, () => {
          setVisible(false);
          onComplete();
        });
      },
      true,
      true,
    );

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditShipmentOrdersSidebar.updateShipment`, `Update shipment`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        <ShipmentOrdersForm form={form} companyId={companyId} inSidebar={true} />
      </RingilSidebar>
    );
  };

  return { EditShipmentOrdersSidebar, setVisible };
};
