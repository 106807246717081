import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
}

const InputNotificationType: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { lovGetAllNotificationTypes } = useContext(ApiContext);
  const { tr } = useTranslationLgs();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    lovGetAllNotificationTypes(d => {
      setOptions(d);
    });
  }, []);

  return (
    <>
      <Dropdown
        options={options.sort()}
        value={value}
        onChange={e => onChange(e.value)}
        itemTemplate={v => <div>{tr(`InputNotificationType${v}`, v)}</div>}
        valueTemplate={(v, props) => <>{v ? <div>{tr(`InputNotificationType${v}`, v)}</div> : <span>{props.placeholder}</span>}</>}
        placeholder={tr(`InputNotificationType.filterByType`, `Filter by type`)}
        showClear={true}
      />
    </>
  );
};

export default InputNotificationType;
