import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CheckLocationExceptionOutDTO, GlobalTimeslotsDTO, OpenHourExceptionTypeEnum } from '@api/logsteo-api.v2';
import { getTime } from '@utils/utils';
import { mapFromAPIDateTime, mapFromAPIToDateShort } from '@utils/date';
import AlertInfo from '@components/obsolete/Alert/AlertInfo.tsx';

interface ComponentProps {
  customerLocationId: string;
  slots: GlobalTimeslotsDTO;
}

const CompanyExceptionWarning: React.FC<ComponentProps> = ({ customerLocationId, slots }) => {
  const { tr } = useTranslationLgs();
  const { checkLocationException } = useContext(ApiContext);
  const [warning, setWarning] = useState<CheckLocationExceptionOutDTO[]>();

  useEffect(() => {
    if (customerLocationId && slots && slots?.dayWithInterval && slots?.dayWithInterval?.length > 0) {
      checkLocationException(customerLocationId, slots.dayWithInterval[0].day, slots.dayWithInterval[slots.dayWithInterval.length - 1].day, data => {
        setWarning(data);
      });
    }
  }, [slots, customerLocationId]);

  const printInterval = (dates: string[]) => {
    if (dates.length == 0) {
      return 'nikdy';
    }
    if (dates.length == 1) {
      return mapFromAPIToDateShort(dates[0]);
    }
    if (dates.length > 1) {
      return `${mapFromAPIToDateShort(dates[0])} - ${mapFromAPIToDateShort(dates[dates.length - 1])}`;
    }
  };

  return (
    <>
      {warning && warning.length > 0 && (
        <AlertInfo backgroundWrapper={'orange2'}>
          {warning.map(w => (
            <div>
              {w.openHourExceptionType == OpenHourExceptionTypeEnum.CLOSED && (
                <>
                  {tr(`SupplierNotificationHeaderForm.exWarClosed`, `WARNING: closed on {{ dates }} {{exceptionName}}`, {
                    dates: printInterval(w.dates),
                    exceptionName: w.exceptionName,
                  })}
                </>
              )}
              {w.openHourExceptionType == OpenHourExceptionTypeEnum.OPEN && (
                <>
                  {tr(`SupplierNotificationHeaderForm.exWarOpenOnly`, `WARNING: on {{ dates }} it is open in {{intervals}} {{exceptionName}}`, {
                    dates: printInterval(w.dates),
                    exceptionName: w.exceptionName,
                    intervals: w.openingHours.map(item => `${getTime(mapFromAPIDateTime(item.since))} - ${getTime(mapFromAPIDateTime(item.till))}`).join(', '),
                  })}
                </>
              )}
            </div>
          ))}
        </AlertInfo>
      )}
    </>
  );
};

export default CompanyExceptionWarning;
