import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';
import { mapFromAPIToDateLong } from '@utils/date';

interface ComponentProps {
  createdAt: string;
  createdBy: string;
}

const CreatedBadge: React.FC<ComponentProps> = ({ createdAt, createdBy }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(createdAt) && (
        <Badge filled={false} value={`${createdBy}, ${mapFromAPIToDateLong(createdAt)}`} label={tr(`CreatedBadge.title`, `Created`)} highlightedValue={false}></Badge>
      )}
    </>
  );
};

export default CreatedBadge;
