import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import Layout from '@app/layout/Layout.tsx';
import { useTranslation } from 'react-i18next';
import AllNotificationsList from '@components/obsolete/AllNotificationsList/AllNotificationsList.tsx';

const NotificationListPage = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`notifications.notifications`, `Notifications`)}>
      {/*// @ts-ignore*/}
      <Card>
        <AllNotificationsList />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(NotificationListPage);
