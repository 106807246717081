import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface ComponentProps {
  btnAllMessages?: boolean;
  navCurrent?: any;
  onClickBtnAllMessage?: any;
}

const ChatNav: React.FC<ComponentProps> = ({ btnAllMessages, navCurrent, onClickBtnAllMessage }) => {
  const { t }: { t: any } = useTranslation('common');

  const className = clsx({
    'chat-nav': btnAllMessages === false,
    'chat-nav chat-nav-full': btnAllMessages === true,
  });

  return (
    <>
      <div className={className}>
        {btnAllMessages && (
          <Button label={t(`ChatNav.allMessages`, `all messages`)} icon="pi pi-chevron-left" className="btn-prev p-button-text" onClick={onClickBtnAllMessage} />
        )}
        <span className="chat-nav-current">{navCurrent}</span>
      </div>
    </>
  );
};
export default ChatNav;
