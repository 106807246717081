import classNames from 'clsx';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  locationName?: string;
  locationCity?: string;
  statusInfo?: string;
  status?: string;
}

const ExpeditionTimeLine: React.FC<PropsWithChildren<ComponentProps>> = ({ locationName, locationCity, statusInfo, status, children }) => {
  const { t } = useTranslation('common');
  const className = classNames('expedition-time-line timeslots-wrapper', {
    'p-d-flex picked': status === 'picked',
    'p-d-flex picked-dropped': status === 'picked-dropped',
    'p-d-flex waiting': status === 'waiting',
    'p-d-flex waiting-2': status === 'waiting-2',
  });

  const classNameIcon = classNames({
    'picked-icon pi pi-check-circle': status === 'picked',
    'picked-dropped-icon pi pi-check-circle': status === 'picked-dropped',
    'waiting-icon pi pi-clock': status === 'waiting',
    'waiting-2-icon pi pi-clock': status === 'waiting-2',
  });

  return (
    <div className={className}>
      <div className="p-col-12 p-xl-6">
        <div className="panel-with-icon">
          <div className="p-d-flex">
            <div className="expedition-time-line__icon">
              <i className={classNameIcon}></i>
            </div>
            <div className="expedition-time-line__description">
              <div className="expedition-time-line__title">
                <strong>{locationName}</strong>
              </div>
              <div className="expedition-time-line__route">
                <small>{locationCity}</small>
              </div>
              <div className="expedition-time-line__status">
                <small>{statusInfo}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-xl-6">{children}</div>
    </div>
  );
};

export default ExpeditionTimeLine;
