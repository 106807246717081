import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  companyName: string;
}

const RecieverBadge: React.FC<ComponentProps> = ({ companyName }) => {
  const { tr } = useTranslationLgs();
  return (
    <>{isNotNullOrUndefined(companyName) && <Badge filled={false} value={companyName} highlightedValue={false} label={tr(`RecieverBadge.label`, `Příjemce`)}></Badge>}</>
  );
};

export default RecieverBadge;
