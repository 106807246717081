import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { isNullOrUndefined } from '@utils/utils';
import { CenteredRowWithGap } from '@components/styles.tsx';

interface ComponentProps {
  disabled?: boolean;
}

export const useButtonEnablers = <T,>(
  buttons: {
    label: string;
    sectionCode: T;
    closable: boolean;
    expanded: boolean;
  }[],
  onVisibilityChanged: (section: T, visible: boolean) => void,
) => {
  interface ExpandedSection {
    sectionCode: T;
    expanded: boolean;
    closable?: boolean;
  }

  const [expandedSections, setExpandedSections] = useState<ExpandedSection[]>(
    buttons.map(t => {
      return {
        expanded: t.expanded,
        sectionCode: t.sectionCode,
        closable: t.closable,
      };
    }),
  );

  const showSection = (sectionCode: T) => {
    setExpandedSections(prev => {
      const section = prev.find(t => t.sectionCode === sectionCode);
      onVisibilityChanged(section.sectionCode, true);
      return [...prev.filter(t => t.sectionCode !== sectionCode), { expanded: true, sectionCode, closable: section.closable }];
    });
  };

  const isNotExpanded = (button: { label: string; sectionCode: T }) => {
    return !expandedSections.find(t => t.sectionCode === button.sectionCode).expanded;
  };
  const isClosable = (sectionCode: T) => {
    const value = expandedSections.find(t => t.sectionCode === sectionCode).closable;
    return isNullOrUndefined(value) ? true : value;
  };

  const setClosability = (sectionCode: T, closable: boolean) => {
    setExpandedSections(prev => {
      const section = prev.find(t => t.sectionCode === sectionCode);
      return [...prev.filter(t => t.sectionCode !== sectionCode), { expanded: section.expanded, sectionCode, closable }];
    });
  };

  const hideSection = (sectionCode: T) => {
    setExpandedSections(prev => {
      const section = prev.find(t => t.sectionCode === sectionCode);
      onVisibilityChanged(section.sectionCode, false);
      return [...prev.filter(t => t.sectionCode !== sectionCode), { expanded: false, sectionCode, closable: section.closable }];
    });
  };

  const ButtonEnablersComponent: React.FC<ComponentProps> = ({ disabled = false }) => {
    return (
      <CenteredRowWithGap>
        {buttons
          .filter(t => isNotExpanded(t))
          .map((t, tKey) => (
            <Button key={tKey} className={'p-button-outlined p-mt-2'} label={'+ ' + t.label} disabled={disabled} onClick={e => showSection(t.sectionCode)} />
          ))}
      </CenteredRowWithGap>
    );
  };

  return {
    ButtonEnablersComponent,
    expandedSections,
    setExpandedSections,
    hideSection,
    showSection,
    setClosability,
    isClosable,
  };
};
