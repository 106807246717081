import React from 'react';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { LovDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';

interface ComponentProps {
  subscribersIds: string[];
  onChange: (subscribersIds: string[]) => void;
  style?: any;
  disabled?: boolean;
}

export const InputSubscriberIdsPlain: React.FC<ComponentProps> = ({ subscribersIds, onChange, style, disabled = false }) => {
  const responsiblePersons = useResponsiblePersons();
  return (
    <>
      <MultiSelect
        value={subscribersIds}
        options={responsiblePersons}
        onChange={e => onChange(e.target.value)}
        optionLabel="name"
        optionValue={'id'}
        style={style}
        disabled={disabled}
        filter={true}
        itemTemplate={(option: LovDTO) => (
          <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
        )}
      />
    </>
  );
};
