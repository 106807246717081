import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgDriver from '@components/ringil3/icons/Driver';

interface ComponentProps {
  assignedDriverName: string;
}

const AssignedDriverTag: React.FC<ComponentProps> = ({ assignedDriverName }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonTag label={tr(`AssignedDriverTag.title`, `Driver`)} labelNote={assignedDriverName} icon={<SvgDriver />} setOpen={setOpen} open={open} />
    </>
  );
};

export default AssignedDriverTag;
