import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { AddMoreCarrierInDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { SelectCarriers } from '@components/ringil3/Input/InputCarriers/SelectCarriers';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  onContinue: () => void;
  customerId: string;
  applicationId: string;
}

export const useCreateAddMoreCarriers = () => {
  const [visible, setVisible] = useState(false);

  const AddMoreCarriers: React.FC<ComponentProps> = ({ onContinue, customerId, applicationId }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { cuAddMoreCarriersToDistribution } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    if (!visible) return null;

    const schema = yup.object().shape({
      carriers: yup.array().required().min(1),
    });

    const form = useForm<AddMoreCarrierInDTO>(schema, { carriers: [] }, d => onContinueHandler(d), false, false);

    const onContinueHandler = (data: AddMoreCarrierInDTO) => {
      cuAddMoreCarriersToDistribution(
        customerId,
        applicationId,
        { carriers: data.carriers },
        () => {
          setVisible(false);
          onContinue();
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          onContinue={() => form.validateAndSend()}
          heading={tr(`AddMoreCarriers.heading`, `Add more carriers`)}
        >
          <BackendValidationComponent />
          <VerticalFieldAndValueWithIcon
            label={tr(`AddMoreCarriers.forExpedition`, `For expedition`)}
            valueText={applicationId}
            iconPath={'/images/icons/ringil3/forExpedition.svg'}
          />

          <VerticalFieldAndValue
            label={tr('AddMoreCarriers.chooseCarriersAndCarrierGroupsToAdd', 'Choose carriers and/or carrier groups to add')}
            labelMode={'normal'}
            required={true}
          >
            <SelectCarriers
              value={form.values.carriers}
              onlyCarriers={false}
              onSelected={value => {
                form.setFieldValue(form.names.carriers, value);
              }}
              isDisabled={false}
              placeholder={tr(`RequestCarrier.selectACarrierOrGroup`, `Select a carrier or group`)}
            />
          </VerticalFieldAndValue>
        </RingilSidebar>
      </>
    );
  };

  return { AddMoreCarriers, setVisible };
};
