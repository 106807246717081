import React from 'react';

interface ComponentProps {
  children?: any;
}

const Chat: React.FC<ComponentProps> = ({ children }) => {
  return (
    <>
      <div className="chat-messages">{children}</div>
    </>
  );
};

export default Chat;
