import React from 'react';
import SvgMenuRingilSmall from '@components/icons/menu/MenuRingilSmall.tsx';
import SvgMenuRingil from '@app/icons/menu/MenuRingil.tsx';
import SvgMenuAnchorLocked from '@components/icons/menu/MenuAnchorLocked.tsx';
import SvgMenuAnchor from '@app/icons/menu/MenuAnchor.tsx';

interface ComponentProps {
  smallMenu: boolean;
  lockedMenu: boolean;
  setLockedMenu: (value: boolean) => void;
}

const MenuHeader: React.FC<ComponentProps> = ({ smallMenu, lockedMenu, setLockedMenu }) => {
  return (
    <>
      {smallMenu && !lockedMenu ? (
        <>
          <div className={'p-4 bg-base-quaternary flex justify-center h-[72px] items-center'}>
            <SvgMenuRingilSmall />
          </div>
        </>
      ) : (
        <div className={'p-4 bg-base-quaternary flex justify-between h-[72px] items-center'}>
          <SvgMenuRingil />
          <div className={'cursor-pointer'}>
            {lockedMenu ? <SvgMenuAnchorLocked onClick={() => setLockedMenu(false)} /> : <SvgMenuAnchor onClick={() => setLockedMenu(true)} />}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuHeader;
