import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M8.5 9.396a2.751 2.751 0 1 0-1.5 0V13h4.25v1.604a2.751 2.751 0 1 0 1.5 0V13H17V9.396a2.751 2.751 0 1 0-1.5 0V11.5h-7zM9 6.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0m8.5 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0m-4.25 10.5a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMenuContacts;
