interface ComponentProps {
  children?: any;
  label?: any;
  className?: any;
}

const LocationDetails: React.FC<ComponentProps> = ({ children }) => {
  return <div className="location-details p-mb-6">{children}</div>;
};

export default LocationDetails;
