import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CGCompanyOutDTO, CGLocationOutDTO, ContactTypeEnum } from '@api/logsteo-api.v2';
import { TreeSelect, TreeSelectSelectionKeys } from 'primereact/treeselect';

interface ComponentProps {
  value: Partial<CGLocationOutDTO>;
  onChange: (newValue: CGLocationOutDTO) => void;
}

interface LocationID {
  locationId: String;
  contactType: ContactTypeEnum;
}

const InputLocationAndPartnerLocation: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { getCGLocations } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [companies, setCompanies] = useState<CGCompanyOutDTO[]>([]);

  useEffect(() => {
    getCGLocations(d => {
      setCompanies(d);
    });
  }, []);

  const [selectedNodeKey3, setSelectedNodeKey3] = useState<TreeSelectSelectionKeys>();
  const nodes = companies.map((t, index) => ({
    key: `${index}`,
    label: t.companyName,
    selectable: false,
    icon: 'pi pi-fw pi-inbox',
    children: t.contacts.map(t => ({
      key: t.locationId,
      label: t.locationName,
      selectable: true,
      icon: 'pi pi-users',
    })),
  }));

  useEffect(() => {
    const found = companies.find(t => t.contacts.find(t2 => t2.locationId == selectedNodeKey3));
    const found2 = found?.contacts.find(t2 => t2.locationId == selectedNodeKey3);
    // @ts-ignore
    if (found2) onChange(found2);
  }, [selectedNodeKey3]);

  return (
    <>
      <TreeSelect
        value={selectedNodeKey3}
        options={nodes}
        onChange={e => setSelectedNodeKey3(e.value)}
        placeholder={tr(`InputLocationAndPartnerLocation.selectItem`, `Select Item`)}
        selectionMode={'single'}
        filter={true}
      ></TreeSelect>
    </>
  );
};

export default InputLocationAndPartnerLocation;
