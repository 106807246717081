import { useTranslation } from 'react-i18next';
import { useNavigation, useSearchParams } from 'react-router-dom';

export enum CUSTOMER_CONTENT {
  MODEL = 'model',
}

const useTranslationLgs = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const showNames = searchParams.get('i18nShowNames');

  return {
    tr: (key: string, defaultText: string = key, data?: any, count?: number): string => {
      if (showNames) {
        return `${key}:${t(key, {
          defaultValue: defaultText,
          count: count,
          ...data,
        })}`;
      } else {
        // @ts-ignore
        return t(key, { defaultValue: defaultText, count: count, ...data });
      }
    },
  };
};

export default useTranslationLgs;
