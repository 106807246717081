import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import { LovDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  carrierId: string;
  onChange: (carrierId: string) => void;
  style?: any;
  required?: boolean;
}

const InputCarrierPlain: React.FC<ComponentProps> = ({ carrierId, onChange, style, required }) => {
  const { cuLovCarriers } = useContext(ApiContext);
  const [carriers, setCarriers] = useState<LovDTO[]>([]);

  useEffect(() => {
    cuLovCarriers(null, d => {
      setCarriers(d);
      if (d?.length > 0 && required) onChange(d[0].id);
    });
  }, []);

  return (
    <>
      <Dropdown
        style={style}
        value={carrierId}
        options={carriers}
        optionLabel={'name'}
        optionValue={'id'}
        filter={true}
        onChange={e => {
          onChange(e.value);
        }}
      />
    </>
  );
};

export default InputCarrierPlain;
