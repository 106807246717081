import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Layout from '@app/layout/Layout.tsx';
import AllCarrierGroups from '@components/obsolete/customer/carriergroups/AllCarrierGroups.tsx';

const CarrierGroupsPage = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`CarrierGroup.carrierGroups`, `Skupiny dopravců`)}>
      {/*
        // @ts-ignore*/}
      <Card>
        <AllCarrierGroups />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(CarrierGroupsPage);
