import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { CreateTemplateHandlerContext } from './CreateTemplateHandler';
import { PageID, RouteType } from '../new-expedition/types';
import RouteTypeComponent from '../common/RouteTypeComponent';
import { InputText } from 'primereact/inputtext';
import { Location } from './types';
import useValidation from '@hooks/validation-hook/useValidation';
import { stateStep2 } from './validation';
import ValidationDiv from '@utils/validation';
import { LocationDetailComponent } from '@components/obsolete/CreateTemplate/components/LocationDetailComponent';
import { useTranslation } from 'react-i18next';
import ContainerWrapper from '@components/obsolete/ContainerWrapper/ ContainerWrapper.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  locations: Location[];
  routeType: RouteType;
}

const CreateTemplateStep2: React.FC<ComponentProps> = ({ locations, routeType }) => {
  const { t }: { t: any } = useTranslation('common');
  const { state, clickOnBreadCrumb, proceedToLoading, changeRouteType, changeTemplateName } = useContext(CreateTemplateHandlerContext);

  const { validationErrors, validateAndSend } = useValidation(stateStep2, () => {
    proceedToLoading();
  });

  return (
    <>
      <div className="panel panel-gray p-mb-3">
        <ContainerWrapper wrapper="SM">
          <div className="create-panel">
            <Label title={t(`CreateTemplateStep2.templateName`, `Template name`)} required={true} />
            <div className="p-d-flex p-ai-end">
              <div className="create-panel__input">
                <InputText type="text" value={state.templateName} onChange={e => changeTemplateName((e.target as HTMLInputElement).value)} />
              </div>
              <div className="create-panel__icon">
                <img src="/images/create.svg" alt="" />
              </div>
            </div>
            <ValidationDiv errors={validationErrors} path={'templateName'} />
          </div>
        </ContainerWrapper>
      </div>
      <ContainerWrapper>
        <Label title={t(`CreateTemplateStep2.routeType`, `Route type`)} />
        <div className="p-field">
          <RouteTypeComponent routeType={routeType} changeRouteType={changeRouteType} />
        </div>
      </ContainerWrapper>
      {locations.map((location, index) => (
        <LocationDetailComponent
          key={index}
          routeType={routeType}
          locationDetail={location}
          locationIndex={index}
          locationsLength={locations.length}
          validationErrors={validationErrors}
        />
      ))}
      <ButtonLayout>
        <Button label={t(`CreateTemplateStep2.continue`, `Continue`)} className="p-button-success p-mr-2" onClick={e => validateAndSend(state)} />
        <Button label={t(`CreateTemplateStep2.back`, `Back`)} className="p-button-text" onClick={e => clickOnBreadCrumb(PageID.STEP1)} />
      </ButtonLayout>
    </>
  );
};

export default CreateTemplateStep2;
