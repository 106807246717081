import { Outlet } from 'react-router-dom';

import { useModals, useNavigate } from '../router';
import AuthWrapper from '../components/auth/AuthWrapper.tsx';
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://d8c638f05cd024570133929e49c5b54e@o4508126704893952.ingest.de.sentry.io/4508126706597968',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  const navigate = useNavigate();
  const modals = useModals();

  const domain = import.meta.env.VITE_AUTH0_DOMAIN || 'logsteo-alpha.eu.auth0.com';
  const clientId = import.meta.env.VITE_AUTH0_CLIENTID || '60d4lAk7jWPIv6Qi8T8IwHohJGvUxHDO';

  console.log('Settings', domain, clientId);

  return (
    <>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{ redirect_uri: window.location.origin }}
        onRedirectCallback={(appState, user) => {
          // @ts-ignore
          navigate(appState.returnTo);
        }}>
        <AuthWrapper>
          <Outlet />
        </AuthWrapper>
      </Auth0Provider>
    </>
  );
}
