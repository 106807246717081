import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { LovDTO } from '@api/logsteo-api.v2';

const useCarrierDispatcher = () => {
  const [carrierDispatchers, setCarrierDispatchers] = useState<LovDTO[]>([]);
  const { caLovDispatcher } = useContext(ApiContext); // LovDTO

  useEffect(() => {
    caLovDispatcher(data => setCarrierDispatchers(data));
  }, []);

  return carrierDispatchers;
};

export default useCarrierDispatcher;
