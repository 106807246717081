import React from 'react';
import { Card } from 'primereact/card';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import MultiDemandDetail from '@components/pages/admin/carriers/MultiDemandDetail';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import { useParams, useSearchParams } from 'react-router-dom';

interface ComponentProps {}

const MultiDemandDetailPage: React.FC<ComponentProps> = () => {
  const { t } = useTranslation('common');

  const { id } = useParams();

  return (
    <Layout title={t(`MultiDemandDetail.title`, `Multi demands detail`)}>
      {/*// @ts-ignore*/}
      <Card>
        <MultiDemandDetail id={id} />
      </Card>
    </Layout>
  );
};

export default withAuthenticationRequired(MultiDemandDetailPage);
