import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SvgCloud from '@app/pages/attachment/Cloud.tsx';
import COText from '@app/pages/COText/COText.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgCoButtonPlus from '@components/framework/buttons/icons/CoButtonPlus.tsx';
import SvgLoader from '@app/icons/loader/Loader.tsx';

interface ComponentProps {
  onClick?: () => void;
  loading?: boolean;
  showDropZone?: boolean;
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
}

const UploadPanelVisual: React.FC<ComponentProps> = ({ onClick, onDrop, onDragOver, loading = false, showDropZone = false }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {!loading ? (
        <div className="w-full px-6 py-6  rounded-[8px] bg-white cursor-pointer" onClick={() => onClick()} onDrop={e => onDrop(e)} onDragOver={onDragOver}>
          <div className={'h-[32px] items-center flex flex-row justify-between'}>
            <span className={'text-content-brand'}>Nahrát soubor</span>
            <span className={'flex flex-row gap-3'}>
              {showDropZone && (
                <span className={'flex flex-row gap-4 items-center border border-dashed border-content-tertiary px-8 py-1 text-content-tertiary'}>
                  <SvgCloud />
                  <COText label={'Přetáhněte pro nahrání'}></COText>
                </span>
              )}
              <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} />
            </span>
          </div>
        </div>
      ) : (
        <div className="w-full px-6 py-6  rounded-[8px] bg-content-quaternary ">
          <div className={'h-[32px] items-center flex flex-row justify-between'}>
            <COText className={'text-content-secondary'} label={'Nahrávám soubor...'}></COText>
            <span className={'flex flex-row gap-3'}>
              <SvgLoader className={'animate-spin'} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadPanelVisual;
