import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';
import { ExtraCostsListPreview } from '@api/logsteo-api.v2';

interface ComponentProps {
  extraCosts?: ExtraCostsListPreview[];
  applicationId: string;
}

const ExtraCostIcon: React.FC<ComponentProps> = ({ extraCosts, applicationId }) => {
  return (
    <>
      {extraCosts && extraCosts.length > 0 && (
        <Icon>
          <span id={`extraMoney_${applicationId}`} className={'pi pi-money-bill'} />
          {/*
        // @ts-ignore*/}
          <Tooltip target={`#extraMoney_${applicationId}`} style={{ maxWidth: '530px' }}>
            <div>
              {extraCosts.map((extraCost, extraConstIndex) => (
                <div key={extraConstIndex}>
                  {extraCost.name} - {extraCost.description} - {extraCost.price} {extraCost.currency}
                </div>
              ))}
            </div>
          </Tooltip>
        </Icon>
      )}
    </>
  );
};

const Icon = styled.span`
  padding: 3px;
`;

export default ExtraCostIcon;
