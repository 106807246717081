import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { OnlyCompanyAdmin } from '@utils/security';
import Layout from '@app/layout/Layout.tsx';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import { CompanyProfile } from '@components/obsolete/CompanyProfile/CompanyProfile.tsx';

const PageCompanyProfile = () => {
  const { tr } = useTranslation();
  const { user } = useAuth0();

  return (
    <Layout title={tr(`company-profile.companyProfile`, `Profil společnosti`)}>
      <OnlyCompanyAdmin>
        <CompanyProfile />
      </OnlyCompanyAdmin>
    </Layout>
  );
};
export default withAuthenticationRequired(PageCompanyProfile);
