import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Layout from '@app/layout/Layout.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';

type Person = {
  id: number;
  name: string;
  age: number;
  email: string;
};

const table: React.FC = () => {
  const { tr } = useTranslationLgs();
  const { Table, Column } = useCreateTable<Person>();

  const data: Person[] = [
    { id: 1, name: 'Jan', age: 25, email: 'jan@example.com' },
    { id: 2, name: 'Petr', age: 30, email: 'petr@example.com' },
    // ... další data
  ];

  return (
    <Layout>
      <Table value={data}>
        <Column header="ID" fixed width={50} body={row => <span>{row.id}</span>} />
        <Column header="Jméno" fixed width={200} body={row => <span>{row.name}</span>} />
        <Column header="Věk" fixed width={100} body={row => <span>{row.age}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.email}</span>} />
        {/* ... další sloupce */}
      </Table>
    </Layout>
  );
};

export default withAuthenticationRequired(table);
