import { Button } from 'primereact/button';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { findTruckType, returnTruckDimensions, returnTruckLoadCapacity } from '@components/obsolete/customer/new-expedition/helpers';
import { TruckTypeWithoutIdDTO } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CardViewWrapper from '@components/obsolete/CardView/CardViewWrapper.tsx';
import CardView from '@components/obsolete/CardView/CardView.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  onChange: (truckCode: string) => void;
  value: string;
  required: boolean;
}

const LINE_LENGTH = 5;

export const TruckTypeSelector: React.FC<ComponentProps> = ({ value, onChange, required = true }) => {
  const { t } = useTranslation('common');
  const [showMoreTypes, setShowMoreTypes] = useState(false);
  const [selectedTruckType, setSelectedTruckType] = useState<string>(null);
  const [truckTypes, setTruckTypes] = useState<TruckTypeWithoutIdDTO[]>([]);
  const { lovTruckTypes } = useContext(ApiContext);

  useEffect(() => {
    lovTruckTypes(data => {
      setTruckTypes(data);
      if (required && isNullOrUndefined(value)) {
        onChange(data[0].code);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedTruckType(value);
  }, [value]);

  return (
    <>
      {selectedTruckType && truckTypes?.length > 0 ? (
        <div className="p-grid p-jc-between">
          <div className="p-col">
            <CardViewWrapper>
              <CardView
                title={findTruckType(selectedTruckType, truckTypes).name}
                option={returnTruckLoadCapacity(selectedTruckType, truckTypes)}
                value={returnTruckDimensions(selectedTruckType, truckTypes)}
              />
            </CardViewWrapper>
          </div>
          <div className="p-col-fixed">
            <ButtonRow>
              <Button
                label={t('labelChangeBtn')}
                iconPos="left"
                icon="pi pi-pencil"
                className="p-button-outlined p-btn-edit-relative"
                onClick={() => setSelectedTruckType(null)}
              />
              {!required && (
                <Button
                  label={t('labelRemove')}
                  iconPos="left"
                  icon="pi pi-trash"
                  className="p-button-outlined p-btn-edit-relative"
                  onClick={() => {
                    onChange(null);
                    setSelectedTruckType(null);
                  }}
                />
              )}
            </ButtonRow>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div>
              {truckTypes.map((truckType, index) => {
                if (index < LINE_LENGTH)
                  return (
                    <Button
                      key={index}
                      label={truckType.name}
                      badge={returnTruckLoadCapacity(truckType.code, truckTypes)}
                      className="button-info-badge p-button-outlined p-mr-2 p-mb-2"
                      icon={truckType.icon}
                      onClick={() => {
                        onChange(truckType.code);
                        setSelectedTruckType(truckType.code);
                      }}
                    />
                  );
              })}
              {truckTypes.length > LINE_LENGTH && !showMoreTypes && (
                <Button
                  label={t('loadDetailsPage.panelAutoType.buttonAllType')}
                  className="p-mb-2 p-button-outlined"
                  icon="pi pi-chevron-down"
                  iconPos="right"
                  onClick={() => setShowMoreTypes(true)}
                />
              )}
            </div>
            {showMoreTypes && (
              <div>
                {truckTypes.map((truckType, index) => {
                  if (index > 4)
                    return (
                      <Button
                        key={index}
                        label={truckType.name}
                        badge={returnTruckLoadCapacity(truckType.code, truckTypes)}
                        className="button-info-badge p-button-outlined p-mr-2 p-mb-2"
                        icon={truckType.icon}
                        onClick={() => {
                          onChange(truckType.code);
                          setSelectedTruckType(truckType.code);
                        }}
                      />
                    );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const ButtonRow = styled.div`
  display: flex;
  gap: 1rem;
`;
