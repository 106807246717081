import styled from 'styled-components';
import { Checkbox } from 'primereact/checkbox';

interface ComponentProps {
  checkboxLabel: string;
  checkBoxDescription: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
}

const CheckboxWithLabelAndDescription: React.FC<ComponentProps> = ({ checkboxLabel, checkBoxDescription, value, onChange, disabled = false }) => {
  return (
    <>
      <CheckboxWrapper>
        <Checkbox checked={value} onChange={e => onChange(e.checked)} disabled={disabled} />
        <Label>{checkboxLabel}</Label>
      </CheckboxWrapper>
      <LabelDescription>{checkBoxDescription}</LabelDescription>
    </>
  );
};

const CheckboxWrapper = styled.div`
  display: flex;
  padding: 5px 5px 5px 0px;
  align-items: center;
`;

const Label = styled.label`
  display: flex;
  font-weight: bold;
  margin-left: 0.4rem;
`;

const LabelDescription = styled.label`
  margin-left: 1.6rem;
`;

export default CheckboxWithLabelAndDescription;
