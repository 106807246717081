import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface ComponentProps {}

const RoundedInfoNote: React.FC<PropsWithChildren<ComponentProps>> = ({ children }) => {
  return (
    <>
      <InfoNoteWrapper>
        <img src={'/images/icons/info2.svg'} />
        <span>{children}</span>
      </InfoNoteWrapper>
    </>
  );
};

const InfoNoteWrapper = styled.div`
  display: flex;
  padding: 1rem;
  background-color: var(--ringil-base-color);
  align-items: center;
  gap: 1rem;
  margin: 0 0 1rem 0;
  color: var(--ringil-draft-color);
  border: 1px solid var(--ringil-draft-color);
  border-radius: 10px;
`;

export default RoundedInfoNote;
