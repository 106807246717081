import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { isNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  value: number;
  color?: string;
}

const ProgressBar: React.FC<ComponentProps> = ({ value, color = 'var(--ringil-processing-color)' }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarStripe width={value == 0 ? '8px' : `${value * 100}%`} color={color} />
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  height: 9px;
  background-color: lightgray;
`;

const ProgressBarStripe = styled.div<{ width: string }>`
  height: 100%;
  width: ${props => props.width};
  background-color: ${props => props.color};
`;

export default ProgressBar;
