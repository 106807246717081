import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import * as yup from 'yup';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgCoInlineMessageInfo from '@components/ringil3/COInlineMessage/icons/CoInlineMessageInfo.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';

interface ComponentProps {
  onCreateETransport: () => void;
}

const ETransportEmpty: React.FC<ComponentProps> = ({ onCreateETransport }) => {
  const { tr } = useTranslationLgs();
  return (
    <div className={'flex justify-center flex-row'}>
      <COInlineMessage
        variant={'info'}
        icon={<SvgCoInlineMessageInfo />}
        buttons={<RingilButton mode={'rect-small'} label={tr(`ETransportEmpty.createETransport`, `Create eTransport`)} onClick={() => onCreateETransport()} />}>
        {tr(`ETransportEmpty.noETransportDocument`, `U přepravy zatím neevidujete dokument pro E-transport.`)}
      </COInlineMessage>
    </div>
  );
};

export default ETransportEmpty;
