import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { CarrierListOutDTO, PageResponseCarrierListOutDTO } from '@api/logsteo-api.v2';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import AddNewCarrier from './sidebars/AddNewCarrier';
import { Dialog } from 'primereact/dialog';
import { useDebounce } from 'use-debounce';
import { InputText } from 'primereact/inputtext';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import useNavigation from '@hooks/useNavigation.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { Link } from 'react-router-dom';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import { Button } from 'primereact/button';

interface FilterType {
  q: string;
}

const AllCarriersList: React.FC = () => {
  const { t } = useTranslation('common');
  const { cuListCarriers, cuDeleteCarrier } = useContext(ApiContext);
  const [carriersData, setCarriersData] = useState<PageResponseCarrierListOutDTO>(undefined);
  const [visibleAddNewCarrier, setVisibleAddNewCarrier] = useState(false);
  const navigation = useNavigation();
  /*  const { pageNumber, pageSize, primeReactDataTablePros, updateUrl } = usePaging<Filter>(
    1,
    10,
    (pageSize1, pageNumber1, filter) => load(pageSize1, pageNumber1, filter.q),
  );*/
  const [deleteCarrierId, setDeleteCarrierId] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<FilterType>((page, filter) => {
    load(page.pageSize, page.pageNumber, filter.q);
  });

  const load = (pageSize: number, pageNumber: number, q = '') => {
    setLoading(true);
    cuListCarriers({ pageSize, pageNumber: pageNumber }, q, data => {
      setCarriersData(data);
      setLoading(false);
    });
  };

  const deleteCarrier = () => {
    cuDeleteCarrier(deleteCarrierId, () => {
      load(page.pageSize, page.pageNumber);
      setDeleteCarrierId(undefined);
    });
  };

  const navBodyTemplate = (rowData: CarrierListOutDTO) => {
    return (
      <div>
        <ButtonLayout>
          {/*<Link href={`/customer/carrier/${rowData.carrierId}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierDetail(rowData.carrierId))}>
            <a className="btn-detail p-button p-button-rounded p-button-outlined secondary-btn">{t(`AllCarriersList.detail`, `Detail`)}</a>
          </Link>

          <SplitButton
            icon="pi pi-ellipsis-h"
            model={[
              {
                label: t(`AllCarriersList.delete`, `Smazat`),
                icon: 'pi pi-trash',
                command: e => {
                  setDeleteCarrierId(rowData.carrierId);
                },
              },
            ]}
            className="btn-more-info p-button-rounded p-button-outlined p-ml-3"
          />
        </ButtonLayout>
      </div>
    );
  };

  const nameBodyTemplate = (rowData: CarrierListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarriersList.name`, `Název`)} />
        <span>
          {/*<Link href={`/customer/carrier/${rowData.carrierId}`}>{rowData.name}</Link>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierDetail(rowData.carrierId))}>{rowData.name}</Link>
        </span>
      </>
    );
  };
  const addressBodyTemplate = (rowData: CarrierListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarriersList.address`, `Adresa`)} />
        <ColumnContent
          content={
            <>
              {rowData.address.streetNr}, {rowData.address.city}, {rowData.address.zipCode} {rowData.address.country}
            </>
          }
        />
      </>
    );
  };
  const crnBodyTemplate = (rowData: CarrierListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarriersList.crn`, `IČ`)} />
        <ColumnContent content={rowData.crn} />
      </>
    );
  };
  const emailBodyTemplate = (rowData: CarrierListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarriersList.mainContact`, `Hlavní kontakt`)} />
        <ColumnContent content={rowData.mainContact} />
      </>
    );
  };
  const createdBodyTemplate = (rowData: CarrierListOutDTO) => {
    return (
      <>
        <ColumnTitle title={t(`AllCarriersList.created`, `Vytvořeno`)} />
        <ColumnContent content={dateFormatDateTime(mapFromAPIDateTime(rowData.createdAt))} />
      </>
    );
  };

  return (
    <>
      <NavTitle wrapper="PADDING-0">
        <h2 className="h2-title p-m-0">{t(`AllCarriersList.carriers`, `Dopravci`)}</h2>
        <Button
          label={t(`AllCarriersList.newCarriers`, `Nový dopravce`)}
          icon="icon pi pi-plus"
          className="p-btn-edit p-button-rounded"
          onClick={() => setVisibleAddNewCarrier(true)}
        />
      </NavTitle>
      <div className="filter-table p-mt-3 p-mb-2">
        <InputText
          id="fieldId"
          type="text"
          placeholder={t(`AllCarriersList.searchName`, `Search name`)}
          style={{ width: '100%' }}
          value={filter?.q}
          onChange={e => applyFilterValue('q', e.target.value)}
        />
      </div>
      {carriersData && (
        <>
          {/*
        // @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            value={carriersData.data}
            rows={carriersData.size}
            totalRecords={carriersData.total}
            className="same-table same-table responsive-table p-mt-4"
            loading={loading}
            onPage={onPage}
            lazy={true}
            paginator={true}
            first={(carriersData.page - 1) * carriersData.size}
          >
            <Column className="same-table__name" field="name" header={t('AllCarriersList.name', 'Název')} body={nameBodyTemplate}></Column>
            <Column className="same-table__address" field="address" header={t('AllCarriersList.address', 'Adresa')} body={addressBodyTemplate}></Column>
            <Column field="crn" header={t('AllCarriersList.crn', 'IČ')} body={crnBodyTemplate}></Column>
            <Column className="same-table__email" field="mainContact" header={t('AllCarriersList.mainContact', 'Hlavní kontakt')} body={emailBodyTemplate}></Column>
            <Column className="same-table__created" field="created" header={t('AllCarriersList.created', 'Vytvořeno')} body={createdBodyTemplate}></Column>
            <Column className="same-table__nav" header={t('AllCarriersList.actions', 'Akce')} body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}
      {/*
        // @ts-ignore*/}
      <Dialog
        header={t(`AllCarriersList.deleteThisCarriers`, `Smazat dopravce`)}
        visible={deleteCarrierId}
        className="modal-sm"
        onHide={() => setDeleteCarrierId(undefined)}
      >
        <div>
          <p className="p-mt-0">{t(`AllCarriersList.byDeleting`, `Smazáním dopravce budou smazány i navazující informace.`)}</p>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`AllCarriersList.delete`, `Smazat`)} className="p-mr-2 p-button-danger" onClick={deleteCarrier} />
          <Button label={t(`AllCarriersList.cancel`, `Zrušit`)} className="p-button-text" onClick={() => setDeleteCarrierId(undefined)} />
        </ButtonLayout>
      </Dialog>
      <AddNewCarrier
        visible={visibleAddNewCarrier}
        onHide={() => setVisibleAddNewCarrier(false)}
        onComplete={() => {
          setVisibleAddNewCarrier(false);
          load(page.pageSize, page.pageNumber);
        }}
      />
    </>
  );
};

export default AllCarriersList;
