import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  displayFull?: boolean;
  labelHidden?: boolean;
}

export const TextArea: React.FC<ComponentProps> = ({ value, onChange, displayFull = false, labelHidden = false }) => {
  const [isOpenNote, setIsOpenNote] = useState(false);
  const { t } = useTranslation('common');

  return (
    <>
      <div>
        {!value && !isOpenNote && (
          <ButtonLayout>
            <Button
              label={t('loadDetailsPage.panelAllAvailableEquipment.button')}
              className="p-button-outlined p-mt-4"
              onClick={() => {
                setIsOpenNote(true);
              }}
            />
          </ButtonLayout>
        )}
      </div>
      {(isOpenNote || value) && (
        <div className="p-fluid p-grid p-mb-3">
          <div className={`p-field p-col-12 p-md-8 ${displayFull ? 'p-lg-12' : 'p-lg-4'}`}>
            {!labelHidden && (
              <label htmlFor="text" className="inputLabel p-d-block p-mb-1">
                <strong>{t('loadDetailsPage.panelAllAvailableEquipment.textareaLabel')}</strong>
              </label>
            )}

            <InputTextarea
              onChange={e => {
                onChange(e.target.value);
              }}
              id="text"
              className={`p-col-8 p-md-8 ${displayFull ? 'p-lg-12' : 'p-lg-4'}`}
              rows={6}
              cols={30}
              value={value}
            />
          </div>
        </div>
      )}
    </>
  );
};
