import React, { useContext, useState } from 'react';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import SupplierNotificationInternalInfoForm, { SupplierNotificationInternalInfoData } from '../forms/SupplierNotificationInternalInfoForm';
import { mapToAPIDateTime } from '@utils/date';
import dayjs from 'dayjs';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';

interface ComponentProps {
  initialData?: SupplierNotificationInternalInfoData;
  supplierNotificationId: string;
  companyId: string;
}

export const useCreateEditSupplierNotificationInternalInfoSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditSupplierNotificationInternalInfoSidebar: React.FC<ComponentProps> = ({ initialData, supplierNotificationId, companyId }) => {
    const { updateNotificationSupplierUpdateDataForInternalInfo } = useContext(ApiContext);

    const form = useForm<SupplierNotificationInternalInfoData>(
      {},
      initialData,
      v => {
        updateNotificationSupplierUpdateDataForInternalInfo(
          supplierNotificationId,
          {
            internalData: {
              deliveryConditionsIncotermCode: form.values.deliveryConditionsIncotermCode,
              internalInvoiceNumber: form.values.internalInvoiceNumber,
              invoiceDueDate: form.values.invoiceDueDate ? mapToAPIDateTime(dayjs(form.values.invoiceDueDate)) : null,
            },
            notifyData: form.values.notifyData,
          },
          () => {
            setVisible(false);
            onComplete();
          },
        );
      },
      true,
      true,
    );

    const { tr } = useTranslationLgs();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditSupplierNotificationInternalInfoSidebar.updateSupplierNotification`, `Update supplier notification`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        <SupplierNotificationInternalInfoForm form={form} companyId={companyId} inSidebar={true} />
      </RingilSidebar>
    );
  };

  return { EditSupplierNotificationInternalInfoSidebar, setVisible };
};
