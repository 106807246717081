import React, { useState } from 'react';
import MenuItem from '@components/menu/MenuItem.tsx';
import { MenuItemModel } from '@components/menu/types.ts';
import { useSessionStorage } from '@react-hooks-library/core';
import { dumpVars, useLocalStorage } from '@utils/utils.tsx';

interface ComponentProps {
  smallMenu?: boolean;
  lockedMenu?: boolean;
  menu: MenuItemModel[];
  localStorageKey: string;
}

const Menu: React.FC<ComponentProps> = ({ smallMenu, menu, lockedMenu, localStorageKey }) => {
  //const [openedMenus, setOpenedMenus] = useSessionStorage<number[]>('Menu.opened', []);
  const [openedMenus, setOpenedMenus] = useLocalStorage<number[]>(localStorageKey, []);

  const toggleOpenMenu = (index: number) => {
    // If the menu is already opened, close it
    if (openedMenus.includes(index)) {
      setOpenedMenus(openedMenus.filter(i => i !== index));
    } else {
      setOpenedMenus([...openedMenus, index]);
    }
  };

  return (
    <>
      <ul className={''}>
        {menu
          .filter(t => t.render)
          .map((item, index) => {
            return (
              <MenuItem
                render={item.render}
                key={index}
                title={item.title}
                icon={item.icon}
                subMenu={item.subMenu}
                mode={item.mode}
                smallMenu={smallMenu && !lockedMenu}
                url={item.url}
                isOpen={openedMenus.includes(index)}
                onClick={() => {
                  toggleOpenMenu(index);
                }}
              />
            );
          })}
      </ul>
    </>
  );
};

export default Menu;
