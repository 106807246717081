/*
import React, { useContext, useEffect, useState } from 'react';
import {
  AdminCatalogCreatingInDTO,
  AdminGoogleAllSheetPreviewOutDTO,
  AdminGoogleSheetListsPreviewOutDTO,
  LovWithoutIdDTO,
  ScopeEnum,
  TruckTypeWithoutIdDTO,
  UserConnectionPreviewOutDTO,
} from '../../../../api/logsteo-api.v2';
import * as yup from 'yup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import styled from 'styled-components';
import { Dropdown } from 'primereact/dropdown';
import useCountries from '../../../../hooks/use-countries/useCountries';
import { InputText } from 'primereact/inputtext';
import { useImmer } from 'use-immer';
import dayjs, { Dayjs } from 'dayjs';
import { isNullOrUndefined, uniq } from '@utils/utils';
import { MultiSelect } from 'primereact/multiselect';
import { Mention } from 'primereact/mention';
import { useCountryRegion, useImportExportCountries, useLovTruckTypes } from '../../../../hooks/customer.hooks';
import { Button } from 'primereact/button';
import { listAdminUserGoogleConnections } from '../../../../hooks/admin/admin-hooks';
import useValidation from '../../../../hooks/validation-hook/useValidation';
import { Tooltip } from 'primereact/tooltip';
import { validate } from '@utils/validation';
import { ApiContext } from '../../../../api/api';

interface ComponentProps {}

enum RowState {
  DIRTY,
  SAVING,
}
interface DataRow extends AdminCatalogCreatingInDTO {
  frontendId: string;
  changedOnFEAt: Dayjs;
  savedOnBE: Dayjs;
  isValid: boolean;
  conflict: boolean;
}

interface InputCountryInplaceProps {
  country: string;
  onChange: (country: string) => void;
  countries: LovWithoutIdDTO[];
}

interface Mapping {
  sourceColumn: number;
  mapToProperty: string;
  operation?: Operation;
}

interface MappingEntry {
  id: number;
  name: string;
  mapping: Mapping[];
  skip: number;
}

enum Operation {
  SPLIT_AND_TRIM,
  SPLIT_AND_KEY_VALUE,
}

const InputCountryInplace: React.FC<InputCountryInplaceProps> = ({ country, onChange, countries }) => {
  return (
    <Dropdown
      value={country}
      options={countries}
      optionValue={'code'}
      optionLabel={'name'}
      style={{ width: '100%' }}
      itemTemplate={(item) => (
        <span>
          {item.name} ({item.code})
        </span>
      )}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

interface RowIndicator {
  row: DataRow;
}

const validationSchema = yup.object().shape({
  crn: yup.string().required(),
  name: yup.string().required(),
  country: yup.string().required(),
  exportCountries: yup.array().min(1),
  importCountries: yup.array().min(1),
  contactEmail: yup.string().required(),
});

const RowIndicator: React.FC<RowIndicator> = ({ row }) => {
  const { validationErrors, validateAndSend } = useValidation(validationSchema, () => {});

  useEffect(() => {
    validateAndSend(row);
  }, [row]);

  const isNotSaved = () => {
    if (isNullOrUndefined(row.savedOnBE)) {
      return true;
    }
    if (row.savedOnBE < row.changedOnFEAt) return true;
    return false;
  };
  return (
    <>
      {validationErrors?.length > 0 && (
        <>
          <Indicator color={'red'} id={`red_${row.frontendId}`} />
          <Tooltip target={`#red_${row.frontendId}`} style={{ width: '300px' }}>
            <h3>Nevalidni radek</h3>
            {validationErrors.map((e) => {
              return <div>{e.message}</div>;
            })}
          </Tooltip>
        </>
      )}
      {validationErrors?.length === 0 && isNotSaved() && (
        <>
          <Indicator color={'orange'} id={`red_${row.frontendId}`} />
          <Tooltip target={`#red_${row.frontendId}`}>Neulozeny radek</Tooltip>
        </>
      )}
      {row.conflict && (
        <>
          <Indicator color={'gray'} id={`red_${row.frontendId}`} />
          <Tooltip target={`#red_${row.frontendId}`}>Konflikt</Tooltip>
        </>
      )}
    </>
  );
};

const Indicator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: ${(props) => props.color};
  margin: auto;
`;

interface CarsProps {
  row: DataRow;
}
const Cars: React.FC<CarsProps> = ({ row }) => {
  const cars = useLovTruckTypes();
  const [suggestions, setSuggestions] = useState<TruckTypeWithoutIdDTO[]>([]);

  const search = (q: string) => {
    const suggestedCars = cars.filter((t) => t.code.toLowerCase().startsWith(q.toLowerCase()));
    setSuggestions(suggestedCars);
  };

  return <Mention field="code" onSearch={(e) => search(e.query)} suggestions={suggestions} />;
};

const AllCarriersList: React.FC<ComponentProps> = () => {
  const {
    adminListCarrier,
    adminLoadAllSheet,
    adminLoadListsInSheet,
    adminLoadSheetToMap,
    adminCreateUserGoogleConnections,
    resolveGoogleReturnPoint,
    adminCreateCarrier,
  } = useContext(ApiContext);

  const [rows, setRows] = useImmer<DataRow[]>([]);

  const mapping = {
    mapping: [
      {
        sourceColumn: 2,
        mapToProperty: 'name',
      },
      {
        sourceColumn: 0,
        mapToProperty: 'country',
      },
      {
        sourceColumn: 1,
        mapToProperty: 'crn',
      },
      {
        sourceColumn: 3,
        mapToProperty: 'wwwPage',
      },
      {
        sourceColumn: 5,
        mapToProperty: 'streetNr',
      },
      {
        sourceColumn: 6,
        mapToProperty: 'city',
      },
      {
        sourceColumn: 7,
        mapToProperty: 'zipCode',
      },
      {
        sourceColumn: 18,
        mapToProperty: 'contactEmail',
      },
      {
        sourceColumn: 19,
        mapToProperty: 'contactPhone',
      },
      {
        sourceColumn: 20,
        mapToProperty: 'contactName',
      },
      {
        sourceColumn: 15,
        mapToProperty: 'importCountries',
        operation: Operation.SPLIT_AND_TRIM,
      },
      {
        sourceColumn: 13,
        mapToProperty: 'exportCountries',
        operation: Operation.SPLIT_AND_TRIM,
      },

      {
        sourceColumn: 14,
        mapToProperty: 'exportNote',
      },

      {
        sourceColumn: 16,
        mapToProperty: 'importNote',
      },
      {
        sourceColumn: 17,
        mapToProperty: 'cars',
        operation: Operation.SPLIT_AND_KEY_VALUE,
      },
    ],
    name: 'testovaci mapping',
    skip: 2,
    id: 1,
  } as MappingEntry;

  const mappings = [mapping];

  const trucks = useLovTruckTypes();

  const [dynCols, setDynCols] = useState([]);

  const getCarProperty = (data1: DataRow, property: string) => {
    if (isNullOrUndefined(data1.cars)) {
      // @ts-ignore
      data1.cars = {};
      data1.cars[property] = 0;
    }
    return data1.cars[property];
  };

  useEffect(() => {
    const dc = trucks.map((t) => {
      return {
        body: (data1: DataRow) => (
          <InputText
            style={{ width: '180' }}
            value={getCarProperty(data1, t.code)}
            onChange={(e) => change(data1.frontendId, e.target.value, `cars[${t.code}]`)}
          />
        ),
        field: t.name,
      };
    });

    setDynCols([...dc]);
  }, [trucks]);

  const dynamicColumns = dynCols.map((col, i) => {
    return <Column key={i} body={col.body} header={col.field} />;
  });

  const change = (frontendId: string, value: any, property: string) => {
    setRows((draft) => {
      const editingEntity = draft.find((t) => t.frontendId === frontendId);
      editingEntity[property] = value;
      editingEntity.changedOnFEAt = dayjs();
    });
  };
  const countries = useCountries();
  const cars = useLovTruckTypes();
  const regions = useCountryRegion();
  const importExportCountries = useImportExportCountries();

  const { data: googleConnections, refresh, loading } = listAdminUserGoogleConnections();
  const [selectedConnection, setSelectedConnection] = useState<UserConnectionPreviewOutDTO>();
  const [googleSheets, setGoogleSheets] = useState<AdminGoogleAllSheetPreviewOutDTO[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<AdminGoogleAllSheetPreviewOutDTO>(undefined);
  const [sheetList, setSheetList] = useState<AdminGoogleSheetListsPreviewOutDTO[]>(undefined);
  const [selectedList, setSelectedList] = useState<AdminGoogleSheetListsPreviewOutDTO>();
  const [selectedMapping, setSelectedMapping] = useState(mappings[0]);

  useEffect(() => {
    if (selectedConnection?.id) {
      adminLoadAllSheet(selectedConnection.id, '', (res) => {
        setGoogleSheets(res);
      });
    }
  }, [selectedConnection]);

  useEffect(() => {
    if (selectedConnection?.id && selectedSheet?.gid) {
      adminLoadListsInSheet(selectedConnection.id, selectedSheet.gid, (res) => {
        setSheetList(res);
      });
    }
  }, [selectedSheet]);

  const searchGoogleSheets = (q: string) => {
    setTimeout(() => {
      adminLoadAllSheet(selectedConnection.id, q, (res) => {
        setGoogleSheets(res);
      });
    }, 500);
  };

  const importSheet = () => {
    console.log(`selectedList: ${JSON.stringify(selectedList)}`);
    adminLoadSheetToMap(
      selectedConnection.id,
      selectedSheet.gid,
      {
        listName: selectedList.name,
        range: 'A1:Z99',
      },
      (res) => {
        console.log(`${dayjs()} - starting...`);
        const mappedData = res.data
          .filter((t, position) => position >= importFrom && position < batchSize + importFrom)
          .map((data, index) => {
            let object = {} as DataRow;
            selectedMapping.mapping.forEach((m, i) => {
              const value = data[m.sourceColumn];
              if (m.operation === Operation.SPLIT_AND_TRIM) {
                const trimmed = value?.split(',').map((t) => t.trim());
                object[m.mapToProperty] = (trimmed || []).filter((t) =>
                  importExportCountries.some((tt) => tt.code === t),
                );
                //object[m.mapToProperty] = trimmed;
              } else if (m.operation === Operation.SPLIT_AND_KEY_VALUE) {
                object[m.mapToProperty] = {};
                if (value?.indexOf(':') > 0) {
                  const trimmed = value?.split(',').map((t) => t.trim());
                  const filtered = trimmed.filter((t) => (t.split(':') || []).length === 2);
                  const objects = filtered.map((t) => {
                    return { code: t.split(':')[0], amount: t.split(':')[1] };
                  });
                  objects.forEach((t) => (object[m.mapToProperty][t.code] = t.amount));
                }
              } else {
                object[m.mapToProperty] = data[m.sourceColumn];
              }
            });
            object.frontendId = uniq();
            object.changedOnFEAt = dayjs();
            return object;
          });
        console.log(`${dayjs()} - mapped...`);
        setRows((draft) => {
          return mappedData;
        });
      },
    );
  };

  const createConnection = () => {
    adminCreateUserGoogleConnections(
      {
        name: 'Test',
        scope: ScopeEnum.GOOGLE_SHEET,
        returnUrl: `${window.location.protocol}//${window.location.host}/google/oauth/redirected`,
      },
      (data) => {
        window.open(data.url, '_new', 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100');
        window.addEventListener('message', (event) => {
          if (event.data.source !== 'lgs-google-auth') return;

          const { source, code, state } = event.data;
          if (code)
            resolveGoogleReturnPoint(code, null, state, () => {
              refresh();
            });
        });
      },
    );
  };

  const saveRow = (row: DataRow) => {
    adminCreateCarrier(
      {
        ...row,
        cars: Object.keys(row.cars).map((t) => {
          return { code: t, count: row.cars[t] };
        }),
      },
      (data) => {
        setRows((draft) => {
          const changedRow = draft.find((t) => t.frontendId === row.frontendId);
          changedRow.savedOnBE = dayjs();
          changedRow.carrierId = data.id;
          changedRow.conflict = false;
        });
      },
      null,
      {
        onConflict: (conflictInfo: AdminCatalogCreatingInDTO) => {
          setRows((draft) => {
            const changedRow = draft.find((t) => t.frontendId === row.frontendId);
            changedRow.conflict = true;
            changedRow.carrierId = conflictInfo.carrierId;
            changedRow.version = conflictInfo.version;
          });
        },
      },
    );
  };

  const save = () => {
    const rowsToSave = rows.filter((t) => validate(validationSchema, t).length === 0);
    rowsToSave.forEach((t) => saveRow(t));
  };

  const [batchSize, setBatchSize] = useState(10);
  const [importFrom, setImportFrom] = useState(2);

  return (
    <>
      {countries?.length > 0 && (
        <>
          <ImportRow>
            <Dropdown
              placeholder={'Google OAuth connections'}
              options={googleConnections}
              optionLabel={'name'}
              value={selectedConnection}
              onChange={(e) => setSelectedConnection(e.target.value)}
            />
            <Button onClick={(e) => refresh()} loading={loading}>
              <span className={'pi pi-refresh'}></span>
            </Button>
            <Button onClick={(e) => createConnection()}>+</Button>
            <Dropdown
              placeholder={'Select document'}
              options={googleSheets}
              optionLabel={'name'}
              value={selectedSheet}
              filter={true}
              onChange={(e) => setSelectedSheet(e.value)}
              onFilter={(e) => searchGoogleSheets(e.filter)}
            />
            <Dropdown
              placeholder={'Select list'}
              options={sheetList}
              value={selectedList}
              optionLabel={'name'}
              onChange={(e) => setSelectedList(e.value)}
              /!*filter={true}
                onFilter={(e) => searchGoogleSheets(e.filter)}*!/
            />
            <Dropdown
              placeholder={'Load mapping'}
              options={mappings}
              optionLabel={'name'}
              value={selectedMapping}
              onChange={(e) => setSelectedMapping(e.value)}
            />
            <Button>+</Button>
            <InputText
              placeholder={'Import from row'}
              value={importFrom}
              onChange={(e) => setImportFrom(parseInt(e.target.value))}
            />
            <InputText
              placeholder={'Import batch size'}
              value={batchSize}
              onChange={(e) => setBatchSize(parseInt(e.target.value))}
            />
            <Button
              disabled={[selectedConnection, selectedSheet, selectedList, selectedMapping].some((t) => t === undefined)}
              onClick={(e) => importSheet()}
            >
              Importovat z googlesheet
            </Button>
            <Button onClick={(e) => save()}>Save</Button>
          </ImportRow>
          <Table>
            <DataTable
              value={rows}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              className="p-datatable-sm"
              editMode="cell"
              style={{ width: '3600px' }}
            >
              <Column header="id" body={(data1: DataRow) => <RowIndicator row={data1} />} style={{ width: '3rem' }} />
              <Column
                header="name"
                style={{ width: 250 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.name}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'name')}
                  />
                )}
              />
              <Column
                header="CRN"
                style={{ width: 90 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.crn}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'crn')}
                  />
                )}
              />
              <Column
                header="TIN"
                style={{ width: 90 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.tin}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'tin')}
                  />
                )}
              />
              <Column
                style={{ width: 250 }}
                header="StreetNr"
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.streetNr}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'streetNr')}
                  />
                )}
              />
              <Column
                header="Country"
                style={{ width: 250 }}
                body={(data1: DataRow) => (
                  <InputCountryInplace
                    country={data1.country}
                    onChange={(e) => change(data1.frontendId, e, 'country')}
                    countries={countries}
                  />
                )}
              />
              <Column
                header="ZIP"
                style={{ width: 70 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.zipCode}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'zipCode')}
                  />
                )}
              />
              <Column
                header="City"
                style={{ width: 250 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.city}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'city')}
                  />
                )}
              />
              <Column
                header="wwwPage"
                style={{ width: 250 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.wwwPage}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'wwwPage')}
                  />
                )}
              />
              <Column
                header="email"
                style={{ width: 150 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.contactEmail}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'contactEmail')}
                  />
                )}
              />
              <Column
                header="phone"
                style={{ width: 120 }}
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.contactPhone}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'contactPhone')}
                  />
                )}
              />
              <Column
                style={{ width: 200 }}
                header="c.name"
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.contactName}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'contactName')}
                  />
                )}
              />
              <Column
                header="export"
                style={{ width: 250 }}
                body={(data1: DataRow) => (
                  <MultiSelect
                    options={importExportCountries}
                    optionValue={'code'}
                    optionLabel={'name'}
                    style={{ width: '100%' }}
                    value={data1.exportCountries}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'exportCountries')}
                  />
                )}
              />
              <Column
                header="import"
                style={{ width: 250 }}
                body={(data1: DataRow) => (
                  <MultiSelect
                    options={importExportCountries}
                    optionValue={'code'}
                    optionLabel={'name'}
                    style={{ width: '100%' }}
                    value={data1.importCountries}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'importCountries')}
                  />
                )}
              />
              <Column
                header="exportNote"
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.exportNote}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'exportNote')}
                  />
                )}
              />
              <Column
                header="importNote"
                body={(data1: DataRow) => (
                  <InputText
                    style={{ width: '100%' }}
                    value={data1.importNote}
                    onChange={(e) => change(data1.frontendId, e.target.value, 'importNote')}
                  />
                )}
              />
              <Column
                header="region"
                style={{ width: 250 }}
                body={(data1: DataRow) => (
                  <Dropdown
                    options={regions}
                    optionLabel={'name'}
                    optionValue={'id'}
                    style={{ width: '100%' }}
                    value={data1.countryRegionId}
                    onChange={(e) => change(data1.frontendId, e.value, 'countryRegionId')}
                  />
                )}
              />
              {dynamicColumns}
            </DataTable>
          </Table>
        </>
      )}
    </>
  );
};

const ImportRow = styled.div`
  display: flex;
  margin: 5px 0px 15px 0px;
  button {
    margin-right: 15px;
  }
`;

const Table = styled.div`
  table {
    z-index: 1 !important;
  }
  tbody > tr > td:first-child {
    width: 40px !important;
  }
  tbody > tr > td {
    padding: 0 !important;
    z-index: 1 !important;
    position: relative !important;
  }

  tbody > tr > td > input {
    padding: 0 !important;
  }
  tbody > tr > td > .p-multiselect .p-multiselect-label {
    padding: 0;
  }
  * {
    font-size: small;
  }
`;
export default AllCarriersList;
*/

export default () => <div />;
