// Example usage
import React, { useState } from 'react';
import RingilDialog from '@components/framework/dialog/RingilDialog.tsx';

const App: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div className="p-4">
      <button onClick={() => setIsDialogOpen(true)} className="px-4 py-2 bg-blue-500 text-white rounded">
        Open Dialog
      </button>
      <RingilDialog visible={isDialogOpen} onHide={() => setIsDialogOpen(false)}>
        <h2 className="text-xl font-bold">Dialog Box</h2>
        <p>This is a centered dialog box using Tailwind, React, and TypeScript.</p>
      </RingilDialog>
    </div>
  );
};

export default App;
