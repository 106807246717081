import classNames from 'clsx';

interface ComponentProps {
  children?: any;
  className?: any;
}

const InfoProvided: React.FC<ComponentProps> = ({ children, className }) => {
  const classes = classNames(className);
  return <div className={'info-provided ' + classes}>{children}</div>;
};

export default InfoProvided;
