import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import { CompanyLocationWithOpeningHours } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  value: string;
  onChange: (value: CompanyLocationWithOpeningHours) => void;
  style?: any;
  required?: boolean;
  showOnlyWithRamp?: boolean;
}

const InputCompanyLocation: React.FC<ComponentProps> = ({ value, onChange, style, required, showOnlyWithRamp = false }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { getLoVCompanyLocations } = useContext(ApiContext);
  const [data, setData] = useState<CompanyLocationWithOpeningHours[]>();

  useEffect(() => {
    getLoVCompanyLocations(showOnlyWithRamp, d => {
      setData(d);
      if (required && d && d.length > 0) {
        onChange(d[0]);
      }
    });
  }, []);

  return (
    <>{data && <Dropdown style={style} options={data} optionLabel={'name'} dataKey={'id'} onChange={e => onChange(e.value)} value={{ id: value }} filter={true} />}</>
  );
};

export default InputCompanyLocation;
