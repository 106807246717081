import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { useImmer } from 'use-immer';
import dayjs from 'dayjs';
import { DistributionView } from '@type/types';
import { Calendar } from 'primereact/calendar';
import { setTime, shortDateFormat } from '@utils/date';
import { getTime } from '@utils/utils';
import useValidation from '@hooks/validation-hook/useValidation';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import { InputMask } from 'primereact/inputmask';
import useCustomerCarriersAndGroups from '@hooks/customer-carrier/customer-carriers-groups';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { DistributionTypeEnum } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import DistributionAndPrice from '@components/obsolete/DistributionAndPrice/DistributionAndPrice.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { SelectCarriers } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import { DelayedPublishingInputComponent, DelayedPublishingLabel } from '@components/obsolete/delayed-publishing';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  isExpeditionCanceled: boolean;
  isDeprecatedByManualMode: boolean;
}

const CreateExpeditionUncontrolled = ({
  // @ts-ignore
  onSubmit,
  // @ts-ignore
  isExpeditionCanceled,
  // @ts-ignore
  isDeprecatedByManualMode,
}) => {
  const { t }: { t: any } = useTranslation('common');
  const [state, setState] = useImmer<DistributionView>({
    distributionType: DistributionTypeEnum.SPOT,
    responseDeadline: dayjs().add(8, 'hour'),
    selectedItems: [],
    currency: 'CZK',
    enabled: true,
    isPublished: true,
  });
  const carriersAndGroups = useCustomerCarriersAndGroups();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const isDisabled = isExpeditionCanceled || isDeprecatedByManualMode || loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1;

  const validationScheme = yup.object().shape({
    selectedItems: yup.array().min(1),
    responseDeadline: yup
      .object()
      .required()
      .test(
        'is-future',
        'isFuture',
        // @ts-ignore
        value => value.isValid() && value.isAfter(dayjs(), 'minutes'),
      ),
    price: yup.number().when('distributionType', {
      is: DistributionTypeEnum.FIXED_PRICE,
      then: yup.number().defined().min(0),
      otherwise: yup.number().nullable().min(0),
    }),
    distributionType: yup.string().defined().oneOf([DistributionTypeEnum.SPOT, DistributionTypeEnum.FIXED_PRICE]),
  });

  const { validationErrors, validateAndSend } = useValidation(validationScheme, () => {
    onSubmit(state);
  });

  const { tr } = useTranslationLgs();

  return (
    <>
      <h3>{t(`CreateExpeditionUncontrolled.assignCarrier`, `Poptat dopravce`)}</h3>
      <div className="p-grid p-d-flex">
        <div className="p-col-12 p-lg-6 p-xl-4">
          <DistributionAndPrice
            validationErrors={validationErrors}
            distributionType={state.distributionType}
            changeDistributionType={v =>
              setState(draft => {
                draft.distributionType = v;
              })
            }
            price={state.price}
            changeDistributionPrice={v =>
              setState(draft => {
                draft.price = v;
              })
            }
            currency={state.currency}
            changeDistributionCurrency={v =>
              setState(draft => {
                draft.currency = v;
              })
            }
            disabled={isDisabled}
          />
          <div className="p-field p-grid">
            <div className="p-col-12">
              <Label title={t(`CreateExpeditionUncontrolled.whoDoYouWantToReach`, `Koho chcete oslovit`)} />
            </div>
            <div className="p-col-12">
              <SelectCarriers
                value={state.selectedItems}
                onlyCarriers={false}
                onSelected={value =>
                  setState(draft => {
                    draft.selectedItems = value;
                  })
                }
                isDisabled={isDisabled}
                placeholder={tr(`CreateExpeditionUncontrolled.choose`, `Vyberte`)}
              />
              <ValidationDiv errors={validationErrors} path={'selectedItems'} />
            </div>
          </div>

          <div className="p-field p-grid">
            <div className="p-col-12">
              <Label title={t(`CreateExpeditionUncontrolled.untilWhenDoYouNeedAPriceOffer`, `Do kdy potřebujete cenovou nabídku`)} />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <Calendar
                locale={'cs'}
                dateFormat={shortDateFormat}
                value={state.responseDeadline.toDate()}
                showIcon
                selectionMode="single"
                readOnlyInput
                onChange={e => {
                  setState(draft => {
                    draft.responseDeadline = dayjs(e.value as Date);
                  });
                }}
                style={{ width: '100%' }}
                disabled={isDisabled}
              />
              <ValidationDiv errors={validationErrors} path={'responseDeadline'} />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <InputMask
                mask={'99:99'}
                style={{ width: '100%' }}
                value={getTime(state.responseDeadline)}
                onComplete={e => {
                  setState(draft => {
                    draft.responseDeadline = setTime(draft.responseDeadline, e.value);
                  });
                }}
                disabled={isDisabled}
              />
              <ValidationDiv errors={validationErrors} path={'responseDeadline'} />
            </div>
          </div>
          <div className="p-field p-grid">
            <div className="p-col-12">
              <DelayedPublishingLabel />
            </div>
            <div className="p-field p-col-12">
              <DelayedPublishingInputComponent
                changeAutomaticPublishing={value => {
                  setState(draft => {
                    draft.isPublished = value;
                  });
                }}
                value={state.isPublished}
                disabled={isDisabled}
              />
            </div>
          </div>

          <ButtonLayout>
            <Button
              label={t('CreateExpeditionUncontrolled.sendToCarrer', 'Oslovit dopravce')}
              className="p-mr-2"
              onClick={e => validateAndSend(state)}
              disabled={isDisabled}
            />
          </ButtonLayout>
        </div>
      </div>
    </>
  );
};

export default CreateExpeditionUncontrolled;
