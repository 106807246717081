import React, { useEffect, useId } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import useCountries from '@hooks/use-countries/useCountries.tsx';
import clsx from 'clsx';
import { isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  id?: any;
  label?: string | object;
  className?: string;
  InputGroup?: boolean;
  classNameInput?: string;
  classNameWrapper?: string;
  error?: string;
  success?: string;
  warning?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  value?: any;
  onChange?: (country: string) => void;
  required?: boolean;
  descriptionOnClick?: () => unknown;
  disabled?: boolean;
}

const InputCountry: React.FC<ComponentProps> = ({
  value,
  className,
  label,
  required,
  description,
  placeholder,
  InputGroup,
  error,
  success,
  warning,
  classNameWrapper,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation('common');
  const coutries = useCountries();

  const classes = clsx('input', className);

  const classesWrapper = clsx('p-field', {
    'p-col-12 p-md-8 p-lg-4': classNameWrapper === undefined,
    'p-col-12': classNameWrapper === 'p-col-12',
    'p-col-6': classNameWrapper === 'p-col-6',
    'p-col-12 p-md-6': classNameWrapper === 'p-col-12 p-md-6',
    'p-col-12 p-md-6 p-ai-end': classNameWrapper === 'p-col-12 p-md-6 p-ai-end',
  });

  const id = useId().replace(/:/g, '');

  useEffect(() => {
    if (isNullOrUndefined(value) || value === '') {
      const preferedCountry = coutries.find(t => t.code === 'CZE');
      if (!isNullOrUndefined(preferedCountry)) {
        onChange(preferedCountry.code);
      }
    }
  }, [coutries]);

  return (
    <div className={classesWrapper ? classesWrapper : 'p-field p-col-12 p-md-8 p-lg-4'}>
      {label && (
        <label className="inputLabel p-d-block" htmlFor={id}>
          <strong>{`${label}${required ? '*' : ''}`}</strong>
        </label>
      )}
      <div className="p-inputgroup">
        <Dropdown
          options={coutries || []}
          optionLabel={'name'}
          optionValue={'code'}
          id={id}
          className={classes}
          disabled={disabled}
          onChange={e => onChange(e.value)}
          value={value}
          filter={true}
        />
        {InputGroup && (
          <span className="p-inputgroup-addon">
            <i className="pi pi-user"></i>
          </span>
        )}
      </div>
      {description && (
        <small className="p-d-block">
          {description}
          <span className="text-blue" style={{ marginLeft: '.5rem' }}>
            {t('wayPage.form.labelEnterManuallyAddresses')}
          </span>
        </small>
      )}
      {success && <div className="p-valid-feedback">{success}</div>}
      {warning && <small className="text-yellow p-d-block">{warning}</small>}
      {error && <div className="p-invalid-feedback">{error}</div>}
    </div>
  );
};

export default InputCountry;
