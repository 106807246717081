import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Clickable, RingilHeading, RowWithGap, RowWithSpaceBetween } from '@components/styles';
import {
  GroupDemandCarrierListPreviewOutDTO,
  GroupDemandListPreviewOutDTO,
  GroupDemandStateEnum,
  PageResponseGroupDemandListPreviewOutDTO,
  StateEnum,
  SupplierNotificationPreviewOutDTO,
  SupplierNotificationTypeEnum,
  TypeEnum,
} from '@api/logsteo-api.v2';
import { formatPercentage, formatPrice } from '@utils/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useNavigation from '@hooks/useNavigation';
import { mapFromAPIToDateShort } from '@utils/date';
import createGDNameDialog from './dialog/ChangeGDNameDialog';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterPage, useFilter } from '@hooks/useFilter/Filter.tsx';
import { Link } from 'react-router-dom';
import Badge, { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import EditButton from '@components/obsolete/Buttons/EditButton/EditButton.tsx';
import DeleteButton from '@components/obsolete/DeleteButton/DeleteButton.tsx';

interface ComponentProps {}

interface FilterType {
  applicationId?: string;
  groupDemandName?: string;
  groupDemandState?: GroupDemandStateEnum[];
  createdByUserName?: string;
}

const ListCustomerGroupDemand: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { listGroupDemands, cuDeleteGroupDemand } = useContext(ApiContext);
  const [data, setData] = useState<PageResponseGroupDemandListPreviewOutDTO>();
  const [editableGD, setEditableGD] = useState();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<FilterType>((page, filter) => {
    loadData(page, filter);
  });

  const navigation = useNavigation();

  const loadData = (page: FilterPage, filter: FilterType) => {
    listGroupDemands(page, filter, d => {
      setData(d);
    });
  };

  const renderSupplierNotifications = (supplierNotifications: SupplierNotificationPreviewOutDTO[]) => {
    return supplierNotifications.reduce((previousValue, currentValue) => {
      return [
        ...previousValue,
        previousValue.length > 0 ? <span> | </span> : <></>,
        <Link
          to={navigation.createNavigationLink(
            navigation.urlFunctions.createSupplierNotificationDetail(currentValue.supplierNotificationId, SupplierNotificationTypeEnum.FCA),
          )}>
          <Clickable>{currentValue.supplierNotificationApplicationId}</Clickable>
        </Link>,
      ];
    }, []);
  };

  const deleteGD = (id: string) => {
    cuDeleteGroupDemand(id, () => {
      loadData(page, filter);
    });
  };

  const changeState = (value: boolean) => {
    if (value) {
      applyFilterValue('groupDemandState', [GroupDemandStateEnum.NEW]);
    } else {
      applyFilterValue('groupDemandState', []);
    }
  };

  const { setGroupDemand, ChangeGDNameDialog } = createGDNameDialog();

  return (
    <>
      <RowWithSpaceBetween>
        <RingilHeading>{tr(`ListCustomerGroupDemand.groupDemands`, `Group demands`)}</RingilHeading>
      </RowWithSpaceBetween>

      {data && (
        <>
          <RowWithGap>
            <InputText
              value={filter?.applicationId}
              onChange={e => {
                applyFilterValue('applicationId', e.target.value);
              }}
              placeholder={tr(`ListCustomerGroupDemand.groupDemandNameAppId`, `Group demand ID`)}
            />
            <InputText
              value={filter?.groupDemandName}
              onChange={e => {
                applyFilterValue('groupDemandName', e.target.value);
              }}
              placeholder={tr(`ListCustomerGroupDemand.groupDemandName`, `Group demand name`)}
            />
            <InputText
              value={filter?.createdByUserName}
              onChange={e => {
                applyFilterValue('createdByUserName', e.target.value);
              }}
              placeholder={tr(`ListCustomerGroupDemand.createdBy`, `Created by`)}
            />
            <InputSwitch checked={filter?.groupDemandState?.indexOf(GroupDemandStateEnum.NEW) > -1} onChange={e => changeState(e.target.value)} />{' '}
            {tr(`ListCustomerGroupDemand.showActiveOnly`, `Show active only`)}
          </RowWithGap>

          {/*
        // @ts-ignore*/}
          <DataTable
            value={data.data}
            rows={data.size}
            paginator={true}
            lazy={true}
            totalRecords={data.total}
            onPage={onPage}
            first={(data.page - 1) * data.size}
            paginatorPosition={'both'}>
            <Column
              header={tr(`ListCustomerGroupDemand.groupDemandNameAppId`, `Group demand ID`)}
              body={(row: GroupDemandListPreviewOutDTO) => (
                <>
                  <a href={navigation.createNavigationLink(navigation.urlFunctions.createCustomerGroupDemandDetail(row.groupDemandId))}>
                    <Clickable>{row.groupDemandApplicationId}</Clickable>
                  </a>
                </>
              )}></Column>
            <Column
              header={tr(`ListCustomerGroupDemand.groupDemandName`, `Group demand name`)}
              body={(row: GroupDemandListPreviewOutDTO) => (
                <>
                  <a href={navigation.createNavigationLink(navigation.urlFunctions.createCustomerGroupDemandDetail(row.groupDemandId))}>
                    <Clickable>{row.groupDemandName}</Clickable>
                  </a>
                  {!row.isValid && row.state == StateEnum.NEW && (
                    <BadgeRow>
                      <Badge text={tr(`ListCustomerGroupDemand.late`, `Late`)} icon={<></>} color={'var(--ringil-error-color)'} />
                    </BadgeRow>
                  )}
                </>
              )}></Column>
            <Column
              header={tr(`ListCustomerGroupDemand.validTill`, `Valid till`)}
              body={(row: GroupDemandListPreviewOutDTO) => mapFromAPIToDateShort(row.validTill)}></Column>
            <Column header={tr(`ListCustomerGroupDemand.createdBy`, `Created by`)} body={(row: GroupDemandListPreviewOutDTO) => row.createdByUserName}></Column>
            <Column
              header={tr(`ListCustomerGroupDemand.supplierNotifications`, `Supplier notifications`)}
              body={(row: GroupDemandListPreviewOutDTO) => (
                <div>
                  {renderSupplierNotifications(row.supplierNotifications).map((t, index) => (
                    <span key={index}>{t}</span>
                  ))}
                </div>
              )}></Column>
            <Column
              header={tr(`ListCustomerGroupDemand.percentage`, `Percentage`)}
              body={(row: GroupDemandListPreviewOutDTO) => <>{row.state === StateEnum.FINISHED && <div>{formatPercentage(row.percentOfOrder)} %</div>}</>}></Column>
            <Column
              header={tr(`ListCustomerGroupDemand.price`, `Price`)}
              body={(row: GroupDemandListPreviewOutDTO) => (
                <>{row.state === StateEnum.FINISHED && <div>{formatPrice(row.sumFinalPrice.price, row.sumFinalPrice.currency)}</div>}</>
              )}></Column>
            <Column
              header={tr('ListCustomerGroupDemand.actions', 'Actions')}
              body={(row: GroupDemandCarrierListPreviewOutDTO) => (
                <>
                  {row.state === StateEnum.NEW && (
                    <div className={'flex flex-wrap flex-row gap-4'}>
                      <EditButton
                        label={''}
                        onClick={() => {
                          setGroupDemand({
                            groupDemandName: row.groupDemandName,
                            groupDemandId: row.groupDemandId,
                          });
                        }}
                      />
                      <DeleteButton
                        onDelete={() => {
                          deleteGD(row.groupDemandId);
                        }}
                      />
                    </div>
                  )}
                </>
              )}></Column>
          </DataTable>
          <ChangeGDNameDialog
            onCancel={() => {}}
            onCompleted={() => {
              loadData(page, filter);
            }}
          />
        </>
      )}
    </>
  );
};

const Row = styled.div`
  display: flex;
`;

export default ListCustomerGroupDemand;
