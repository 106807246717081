import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CompanyModuleEnum } from '@api/logsteo-api.v2.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: CompanyModuleEnum;
  permittedModules?: CompanyModuleEnum[];
  onChange: (value: CompanyModuleEnum) => void;
  required?: boolean;
  placeholder?: string;
}

const InputCompanyModule: React.FC<ComponentProps> = ({ value, onChange, permittedModules, required, placeholder }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Dropdown
        showClear={required ? false : true}
        options={permittedModules != null ? permittedModules : Object.keys(CompanyModuleEnum)}
        itemTemplate={v => <div>{tr(`InputModuleType.module${v}`)}</div>}
        valueTemplate={(v, props) => <>{v ? <div>{tr(`InputModuleType.module${v}`)}</div> : <span>{props.placeholder}</span>}</>}
        value={value}
        onChange={e => onChange(e.value)}
        placeholder={placeholder}
      />
    </>
  );
};

export default InputCompanyModule;
