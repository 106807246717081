import { useContext, useEffect, useState } from 'react';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useCargoTypes = () => {
  const [cargoTypes, setCargoTypes] = useState<LovWithoutIdDTO[]>([]);
  const { lovCargoType } = useContext(ApiContext); // LovDTO

  useEffect(() => {
    lovCargoType(data => setCargoTypes(data));
  }, []);

  return cargoTypes;
};

export default useCargoTypes;
