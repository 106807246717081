import { RadioButton } from 'primereact/radiobutton';
import { useId } from 'react';

interface ComponentProps {
  date?: string;
  time?: string;
  title?: string;
  inputValue?: string;
  inputName?: string;
  onCheck?: () => void;
  checked?: any;
  children?: any;
  errorMessage?: string;
  disabled?: boolean;
}

const TimeslotsProgressInput: React.FC<ComponentProps> = ({ date, time, title, onCheck, checked, children, errorMessage, disabled }) => {
  const uniqID = useId().replace(/:/g, '');
  return (
    <div className="timeslotsProgress-item">
      <div className="p-field-radiobutton">
        {/*<RadioButton inputId={uniqID} onChange={e => onCheck()} checked={checked} disabled={disabled} />*/}
        <input type={'radio'} id={uniqID} value={uniqID} checked={checked} onChange={onCheck} disabled={disabled} />
        {title ? (
          <label htmlFor={uniqID} className="radiobutton-label-wrapper">
            {title && <span className="title">{title}</span>}
            {children}
          </label>
        ) : (
          <label htmlFor={uniqID} className="p-d-flex p-flex-wrap ">
            {date && <span className="date">{date}</span>}
            {time && <span className="time">{time}</span>}
            {/*<div className="p-invalid-feedback">Hodnota musí být zadána</div>*/}
          </label>
        )}
      </div>
      {errorMessage && <div className="p-invalid-feedback">{errorMessage}</div>}
    </div>
  );
};
export default TimeslotsProgressInput;
