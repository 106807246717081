import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import { RingilH2 } from '@components/styles';
import useForm from '../../../../hooks/useForm/useForm';
import { CarrierGroupInDTO } from '@api/logsteo-api.v2';
import dayjs, { Dayjs } from 'dayjs';
import { Button } from 'primereact/button';
import { mapToAPIDateTime } from '@utils/date';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { SelectCarriers } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import InputDateAndTime from '@components/obsolete/Form/InputDateAndTime/InputDateAndTime2.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  onCompleted: () => void;
  groupDemandId: string;
}

interface FormData {
  selectedCarriersAndGroups: CarrierGroupInDTO[];
  distributionValidTill: Dayjs;
}
export const useCreateMDDistributionDialog = () => {
  const [visible, setVisible] = useState(false);

  const CreateMDDistributionDialog: React.FC<ComponentProps> = ({ groupDemandId, onCompleted }) => {
    const { tr } = useTranslationLgs();
    const { createGroupDemandsDistribution } = useContext(ApiContext);

    const form = useForm<FormData>(
      yup.object().shape({}),
      {
        selectedCarriersAndGroups: [],
        distributionValidTill: dayjs().add(4, 'hour'),
      },
      data => save(data),
      true,
      false,
    );

    const save = (data: FormData) => {
      createGroupDemandsDistribution(
        groupDemandId,
        {
          validTill: mapToAPIDateTime(form.values.distributionValidTill),
          selectedItems: form.values.selectedCarriersAndGroups,
        },
        () => {
          setVisible(false);
          onCompleted();
        },
      );
    };
    return (
      <>
        {/*
        // @ts-ignore*/}
        <Dialog onHide={() => setVisible(false)} visible={visible}>
          <RingilH2>{tr(`CreateMDDistrobutionDialog.inquireCarrier`, `Inquire carrier`)}</RingilH2>
          <HorizontalFieldValue
            label={tr(`CreateMDDistrobutionDialog.whoInquire`, `Who inquire`)}
            value={
              <SelectCarriers
                value={form.values.selectedCarriersAndGroups}
                onSelected={v => form.setFieldValue('selectedCarriersAndGroups', v)}
                onlyCarriers={false}
                isDisabled={false}
                placeholder={''}
              />
            }
          ></HorizontalFieldValue>
          <HorizontalFieldValue
            label={tr(`CreateMDDistrobutionDialog.untilWhenDoUouNeedAPriceOffer`, `Until when do uou need a price offer`)}
            value={<InputDateAndTime value={form.values.distributionValidTill} onChange={v => form.setFieldValue('distributionValidTill', v)} />}
          />
          <ButtonLayout wrapper="WITH_PADDING_TOP_0">
            <Button label={tr(`CreateMDDistributionDialog.confirm`, `Potvrdit`)} className="p-mr-3" onClick={() => form.validateAndSend()} />
            <Button label={tr(`CreateMDDistributionDialog.cancel`, `Zrušit`)} className="p-button-outlined p-button-text" onClick={() => setVisible(false)} />
          </ButtonLayout>
        </Dialog>
      </>
    );
  };

  return { CreateMDDistributionDialog, setVisible };
};
