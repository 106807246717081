import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import Label from '@components/obsolete/Form/Label/Label';
import { isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  value: string;
  adrWeight?: number;
  onChange: (v: string) => void;
  onChangeAdrWeight?: (adrWeight: number) => void;
  errorMessages?: string;
}

export const CargoTypeSelector: React.FC<ComponentProps> = ({ value, onChange, errorMessages, onChangeAdrWeight, adrWeight }) => {
  const { t } = useTranslation('common');
  const { lovCargoType } = useContext(ApiContext);

  const [cargoTypes, setCargoTypes] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovCargoType(data => {
      setCargoTypes(data);
      if (isNullOrUndefined(value)) {
        onChange(data[0].code);
      }
    });
  }, []);

  return (
    <>
      <div className="p-field p-fluid p-formgrid p-mt-3">
        <Label title={t(`CargoTypeSelector.cargoType`, `Cargo type`)} />
        <Dropdown value={value} options={cargoTypes} onChange={e => onChange(e.target.value)} optionLabel="name" optionValue="code" />
        {value === 'ADR_LQ' && onChangeAdrWeight != null && (
          <>
            <br />
            <Label title={t(`CargoTypeSelector.adrWeight`, `ADR weight`)}></Label>
            <InputNumber
              minFractionDigits={2}
              maxFractionDigits={2}
              mode="decimal"
              locale={'cs'}
              inputStyle={{ textAlign: 'right' }}
              value={adrWeight}
              onChange={e => onChangeAdrWeight(e.value)}
            />
          </>
        )}
      </div>
    </>
  );
};
