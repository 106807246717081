import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import SecurityRoles from '@components/obsolete/admin/security/roles/SecurityRoles.tsx';

interface ComponentProps {}

const SecurityRolesPage: React.FC<ComponentProps> = () => {
  return (
    <AdminLayout title={'Security roles'}>
      <SecurityRoles />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(SecurityRolesPage);
