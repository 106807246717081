import React, { PropsWithChildren } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import ButtonLayout from '../Form/ButtonLayout/ButtonLayout';

interface ComponentProps {
  headerText?: string;
  visible: boolean;
  onHide: () => void;
  onSave: () => void;
  showButtonBar?: boolean;
}

export const RingilSidebar: React.FC<PropsWithChildren<ComponentProps>> = ({ headerText, visible, onHide, onSave, children, showButtonBar = true }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        onHide={() => onHide()}
        visible={visible}
        closeOnEscape={false}
        blockScroll={true}
        dismissable={false}
        position={'right'}
        style={{ width: 'var(--default-sidebar-width)' }}
      >
        {headerText && <h2>{headerText}</h2>}

        {children}
        {showButtonBar && (
          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
            <Button label={tr(`RingilSidebar.save`, `Save`)} className="p-mr-2" onClick={e => onSave()} />
            <Button label={tr(`RingilSidebar.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
          </ButtonLayout>
        )}
      </Sidebar>
    </>
  );
};
