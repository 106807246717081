import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLoader = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <g clipPath="url(#Loader_svg__a)">
      <path
        stroke="#616061"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M14 2.333V7m0 14v4.666M5.752 5.751l3.301 3.302m9.894 9.893 3.301 3.302M2.333 14H7m14 0h4.667M5.752 22.248l3.301-3.302m9.894-9.893 3.301-3.302"
      />
    </g>
    <defs>
      <clipPath id="Loader_svg__a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLoader;
