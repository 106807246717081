import { InputSwitch } from 'primereact/inputswitch';
import React, { useContext } from 'react';
import { OrderViewData } from './types';
import { Button } from 'primereact/button';
import { OrdersComponent } from './OrdersComponent';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import PanelCard from '@components/obsolete/PanelCard/PanelCard.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  collapsed: boolean;
  orders: OrderViewData[];
  locationIndex: number;
  locationName: string;
}

export const LoadingComponent: React.FC<ComponentProps> = ({ collapsed, orders, locationIndex, locationName }) => {
  const { t } = useTranslation();
  const { toggleLoading, addLoadingUnit, addOrder } = useContext(NewExpeditionViewContext);

  return (
    <>
      <PanelCard>
        <div className="p-sm-6 p-mt-2 p-d-flex p-ai-center">
          <InputSwitch
            checked={collapsed}
            onChange={e => toggleLoading(locationIndex)}
            className="p-mr-2"
            /*disabled={locationIndex == 0 && collapsed}*/
          />
          <label htmlFor="binary-2">{t('loadDetailsPage.labelPickUp')}</label>
        </div>
        <div className="p-sm-6 p-mt-2 p-d-flex p-jc-end">
          <img src="/images/icon_pickup.png" alt="" style={{ width: '60px' }} />
        </div>
      </PanelCard>
      {collapsed &&
        orders?.length !== 0 &&
        orders.map((item, orderIndex, orders) => (
          <OrderWrapper key={orderIndex}>
            <OrdersComponent order={item} locationIndex={locationIndex} orderIndex={orderIndex} />

            {item.items.length > 0 && (
              <ButtonLayout wrapper="WITH_PADDING_BOTTOM_2_TOP_4">
                <Button
                  label={'+ ' + t('loadDetailsPage.panelBooking.buttonHandlingUnit')}
                  className="p-button-outlined p-mr-2"
                  onClick={() => {
                    addLoadingUnit(locationIndex, orderIndex);
                  }}
                />
              </ButtonLayout>
            )}
          </OrderWrapper>
        ))}
      {collapsed && (
        <ButtonLayout wrapper="WITH_PADDING_BOTTOM_2_TOP_4">
          <Button
            label={'+ ' + t('loadDetailsPage.labelBookingRef')}
            className="p-button-outlined"
            onClick={() => {
              addOrder(locationIndex);
            }}
          />
        </ButtonLayout>
      )}
    </>
  );
};

const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  padding: 1rem;
  margin: 1rem 0;
`;

export default LoadingComponent;
