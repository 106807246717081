export const exportExcel = (data: any, filename: string) => {
  import('xlsx').then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    /*const widths = computeWidths(data);
    alert(JSON.stringify(widths));*/
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAsExcelFile(excelBuffer, filename);
  });
};

/*const computeWidths = (data: any) => {
  debugger;
  const keys = Object.keys(data);

  return keys.reduce((previousValue, currentValue) => {
    const maxValue = data
      .map((t) => t[currentValue])
      .reduce((prev, cur) => {
        if (isNotBlank(cur) && prev < cur.length) return cur.length;
      }, 0);

    return [...previousValue, maxValue];
  }, []);
};*/

export const saveAsExcelFile = (buffer: any, fileName: string) => {
  import('file-saver').then(FileSaver => {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
};

export const CUSTOMIZATION_MODE_DISABLED = 'CUSTOMIZATION_MODE_DISABLED';
export const CUSTOMIZATION_MODE_REQUIRED = 'CUSTOMIZATION_MODE_REQUIRED';
export const CUSTOMIZATION_MODE_OPTIONAL = 'CUSTOMIZATION_MODE_OPTIONAL';
export const CUSTOMIZATION_MODE_READONLY = 'CUSTOMIZATION_MODE_READONLY';
