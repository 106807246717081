import React, { FormEvent, PropsWithChildren, useId } from 'react';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface ComponentProps {
  id?: any;
  label?: string | object;
  type: string;
  className?: string;
  InputGroup?: boolean;
  classNameInput?: string;
  classNameWrapper?: string;
  error?: string;
  success?: string;
  warning?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  value?: any;
  onChange?: (e: FormEvent<HTMLInputElement>) => unknown;
  required?: boolean;
  descriptionOnClick?: () => unknown;
  disabled?: boolean;
}

const InputWrapper: React.FC<PropsWithChildren<ComponentProps>> = ({
  type,
  value,
  className,
  label,
  required,
  description,
  placeholder,
  InputGroup,
  error,
  success,
  warning,
  classNameWrapper,
  disabled,
  onChange,
  children,
}) => {
  const { t } = useTranslation('common');

  const classes = clsx('input', className);

  const classesWrapper = clsx('p-field', {
    'p-col-12 p-md-8 p-lg-4': classNameWrapper === undefined,
    'p-col-12': classNameWrapper === 'p-col-12',
    'p-col-6': classNameWrapper === 'p-col-6',
    'p-col-12 p-md-6': classNameWrapper === 'p-col-12 p-md-6',
    'p-col-12 p-md-6 p-ai-end': classNameWrapper === 'p-col-12 p-md-6 p-ai-end',
  });

  const id = useId().replace(/:/g, '');

  return (
    <div className={classesWrapper ? classesWrapper : 'p-field p-col-12 p-md-8 p-lg-4'}>
      {label && (
        <label className="inputLabel p-d-block" htmlFor={id}>
          <strong>{`${label}${required ? '*' : ''}`}</strong>
        </label>
      )}

      <div className="p-inputgroup">
        <InputText
          name={id}
          type={type}
          id={id}
          placeholder={placeholder}
          className={classes}
          value={value}
          disabled={disabled && disabled}
          onChange={value => onChange(value)}
        />
        {InputGroup && (
          <span className="p-inputgroup-addon">
            <i className="pi pi-user"></i>
          </span>
        )}
      </div>

      {description && (
        <small className="p-d-block">
          {description}
          <span className="text-blue" style={{ marginLeft: '.5rem' }}>
            {t('wayPage.form.labelEnterManuallyAddresses')}
          </span>
        </small>
      )}
      {success && <div className="p-valid-feedback">{success}</div>}
      {warning && <small className="text-yellow p-d-block">{warning}</small>}
      {error && <div className="p-invalid-feedback">{error}</div>}
      {children}
    </div>
  );
};

export default InputWrapper;
