import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { SelectButton } from 'primereact/selectbutton';
import { LovWithoutIdDTO, ShipmentStateCodeEnum } from '@api/logsteo-api.v2';

interface ComponentProps {
  code: ShipmentStateCodeEnum;
  onChange: (code: ShipmentStateCodeEnum) => void;
  required?: boolean;
  disabled?: boolean;
}

const InputShipmentStatus: React.FC<ComponentProps> = ({ code, onChange, required = false, disabled = false }) => {
  const { lovShipmentStateStatus } = useContext(ApiContext);
  const [shipmentStateStatuses, setShipmentStateStatuses] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovShipmentStateStatus(d => {
      setShipmentStateStatuses(d);
    });
  }, []);

  return (
    <>
      <SelectButton
        options={shipmentStateStatuses}
        optionValue={'code'}
        optionLabel={'name'}
        value={code}
        disabled={disabled}
        onChange={e => {
          if (e.value != null || !required) onChange(e.value);
        }}
      />
    </>
  );
};

export default InputShipmentStatus;
