import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { formatPieces, isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  piecesCount: number;
}

const PiecesBadge: React.FC<ComponentProps> = ({ piecesCount }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(piecesCount) && (
        <Badge filled={false} value={formatPieces(piecesCount, tr)} highlightedValue={false} label={tr(`PiecesBadge.summed`, `Celkovy pocet`)}></Badge>
      )}
    </>
  );
};

export default PiecesBadge;
