import React, { useContext, useMemo, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ExtraCostsPreviewOutDTO } from '@api/logsteo-api.v2';
import { formatPrice } from '@utils/utils';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable';
import { mapFromAPIToDateLong } from '@utils/date';
import COBadge from '@components/ringil3/COBadge/COBadge';
import DeleteButton from '@components/ringil3/Buttons/DeleteButton';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import RingilSpinner from '@components/ringil3/RingilSpinner/RingilSpinner';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import SvgExtracosts from '@components/ringil3/icons/heading/Extracosts';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useCreateDeleteDialog } from '@components/ringil3/Dialog/DeleteDialog.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  companyId: string;
  applicationId: string;
  costs: ExtraCostsPreviewOutDTO[];
  onRemoved: () => void;
}

const ExtraCosts: React.FC<ComponentProps> = ({ costs, applicationId, companyId, onRemoved }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { cuDeleteExtraCostForExpedition } = useContext(ApiContext);
  const [apiRequest, setApiRequest] = useState(false);

  const { DeleteDialog, setObjToDelete } = useCreateDeleteDialog();
  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  const deleteExtraCost = (extraCostId: string) => {
    setApiRequest(true);
    cuDeleteExtraCostForExpedition(
      companyId,
      applicationId,
      extraCostId,
      () => {
        setApiRequest(false);
        onRemoved();
      },
      null,
      {
        onValidationFailed: originalResponse => {
          setApiRequest(false);
          setBeValidationErrors(originalResponse);
        },
      },
    );
  };

  const amountWithCurrency = useMemo(() => {
    const v = costs.reduce((acc, cost) => acc + cost.priceDTO?.companyComputedPrice, 0);
    return formatPrice(v, costs.length > 0 ? costs[0].priceDTO?.currency : '');
  }, [costs]);

  const renderDate = (row: ExtraCostsPreviewOutDTO) => {
    return <div>{mapFromAPIToDateLong(row.createdAt)}</div>;
  };
  const renderCategory = (row: ExtraCostsPreviewOutDTO) => {
    return (
      <COBadge type={'neutral'} variant={'filled'}>
        {row.type}
      </COBadge>
    );
  };
  const renderDescription = (row: ExtraCostsPreviewOutDTO) => {
    return <div>{row.description}</div>;
  };
  const renderInternal = (row: ExtraCostsPreviewOutDTO) => {
    return (
      <div>
        {row.internal ? (
          <>
            <COBadge variant={'filled'} type={'warning'}>
              {tr(`ExtraCosts.yes`, ``)}
            </COBadge>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const renderPrice = (row: ExtraCostsPreviewOutDTO) => {
    return (
      <div>
        <div>{formatPrice(row.priceDTO?.companyComputedPrice, row.priceDTO?.companyComputedCurrency)}</div>
      </div>
    );
  };
  const renderActions = (row: ExtraCostsPreviewOutDTO) => {
    return (
      <div>
        <DeleteButton onClick={() => setObjToDelete(row.id)} />
      </div>
    );
  };

  return (
    <>
      <RowWithSpaceBetween>
        <COHeadline variant={'h2'} title={tr(`ExtraCosts.heading`, `Total cost: {{amountWithCurrency}}`, { amountWithCurrency })} icon={<SvgExtracosts />}></COHeadline>
        <RingilSpinner isSpinning={apiRequest} />
      </RowWithSpaceBetween>
      <BackendValidationComponent />
      <RingilDataTable
        columns={[
          { name: tr('ExtraCosts.on', 'On') },
          { name: tr('ExtraCosts.category', 'Category') },
          { name: tr('ExtraCosts.description', 'Description') },
          { name: tr('ExtraCosts.internal', 'Internal') },
          { name: tr('ExtraCosts.expense', 'Expense') },
          { name: '' },
        ]}
        renderers={[renderDate, renderCategory, renderDescription, renderInternal, renderPrice, renderActions]}
        data={costs}
      />
      <DeleteDialog onDelete={objToDelete => deleteExtraCost(objToDelete)} />
    </>
  );
};

export default ExtraCosts;
