import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
import { EntityTypeEnum, LabelPreviewWithoutTypeOutDTO } from '@api/logsteo-api.v2';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  value: string[];
  entityType: EntityTypeEnum;
  onChange: (labelCodes: string[]) => void;
}
interface NewLabel {
  name: string;
  color: string;
}

const colors = ['#60BD4E', '#F1D600', '#FF9E19', '#EB5A46', '#C277E0', '#0179BF', '#00C2DF', '#51E898', '#334563'];

const LabelsControlled: React.FC<ComponentProps> = ({ value, entityType, onChange }) => {
  const { tr } = useTranslationLgs();

  const { getAllLabelsForCompanyEntityType, createNewLabelForCompany, archiveLabel } = useContext(ApiContext);
  const [labels, setLabels] = useState<LabelPreviewWithoutTypeOutDTO[]>([]);
  const overlayRef = useRef(undefined);
  const [showNewLabelUI, setShowNewLabelUI] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>();

  const [newLabelData, setNewLabelData] = useState<NewLabel>({
    color: '',
    name: '',
  });

  useEffect(() => {
    loadLabelsHandler();
  }, []);

  const loadLabelsHandler = () => {
    getAllLabelsForCompanyEntityType(entityType, '', d => setLabels(d));
  };

  const setColor = (color: string) => {
    setSelectedColor(color);
    setNewLabelData({ ...newLabelData, color });
  };

  const invalidForm = (data: NewLabel) => {
    if (data.name?.length == 0) return true;
    if (data.color?.length == 0) return true;
    return false;
  };

  const createLabelHandler = () => {
    createNewLabelForCompany(
      {
        labelValue: newLabelData.name,
        labelColor: newLabelData.color,
        entityType,
      },
      () => {
        loadLabelsHandler();
        setShowNewLabelUI(false);
      },
    );
  };

  useEffect(() => {
    setNewLabelData({ color: '', name: '' });
    setSelectedColor('');
  }, [showNewLabelUI]);

  const toggleLabel = (label: LabelPreviewWithoutTypeOutDTO) => {
    const existing = value.find(t => t === label.labelId);
    if (existing) {
      // action - remove
      const labelsIds = [...value.filter(t => t !== label.labelId)];
      onChange(labelsIds);
    } else {
      // action -add
      const labelsIds = [...value, label.labelId];
      onChange(labelsIds);
    }
  };

  const deleteLabel = (label: LabelPreviewWithoutTypeOutDTO) => {
    archiveLabel(label.labelId, () => {
      loadLabelsHandler();
    });
  };

  return (
    <>
      <LabelsInner>
        <LabelsWrapper>
          {value &&
            value.map((label, key) => {
              const labelObject = labels.find(t => t.labelId === label);
              return (
                <>
                  {labelObject && (
                    <>
                      <Chip
                        key={key}
                        label={labelObject.labelValue}
                        style={{
                          backgroundColor: labelObject.labelColor,
                          color: 'white',
                          borderRadius: '5px',
                        }}
                      />
                    </>
                  )}
                </>
              );
            })}
        </LabelsWrapper>
        <Button
          style={{ whiteSpace: 'nowrap' }}
          label={tr(`Labels.labels`, `Labels`)}
          onClick={e => {
            overlayRef.current?.toggle(e);
          }}
        ></Button>
      </LabelsInner>
      {/*
        // @ts-ignore*/}
      <OverlayPanel ref={overlayRef}>
        {showNewLabelUI ? (
          <>
            <NavigationPanel>
              <a onClick={e => setShowNewLabelUI(false)}>≤</a>
              <b>{tr(`Labels.addNewLabel`, `Add new label`)}</b>
              <a onClick={e => overlayRef.current?.hide()}>x</a>
            </NavigationPanel>
            <hr />
            <Row>
              <HorizontalFieldValue
                label={tr(`Labels.newLabel`, `New label`)}
                value={<InputText style={{ width: '100%' }} value={newLabelData.name} onChange={e => setNewLabelData({ ...newLabelData, name: e.target.value })} />}
              />
            </Row>
            <ColorRow>
              {colors.map((color, index) => {
                return (
                  <>
                    <ColorBox key={index} color={color} onClick={e => setColor(color)}>
                      {color === selectedColor && <>&#x2713;</>}
                    </ColorBox>
                  </>
                );
              })}
            </ColorRow>
            <CenteredButtonRow>
              <Button label={tr(`Labels.create`, `Create`)} disabled={invalidForm(newLabelData)} onClick={e => createLabelHandler()} />
            </CenteredButtonRow>
          </>
        ) : (
          <>
            <Row>{tr(`Labels.labels`, `Labels`)}</Row>
            <LabelsRow>
              {labels.map((label, index) => {
                return (
                  <LabelRow key={index}>
                    <Label color={label.labelColor} onClick={e => toggleLabel(label)}>
                      {label.labelValue}
                    </Label>
                    {/*<div className={'pi pi-trash'} onClick={(e) => deleteLabel(label)}></div>*/}
                  </LabelRow>
                );
              })}
            </LabelsRow>
            <CenteredButtonRow>
              <Button label={tr(`Labels.newLabel`, `New label`)} onClick={e => setShowNewLabelUI(true)}></Button>
            </CenteredButtonRow>
          </>
        )}
      </OverlayPanel>
    </>
  );
};

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LabelsWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
`;

const LabelsInner = styled.div`
  display: flex;
  height: 2rem;
  margin-right: 0.5rem;
  flex-direction: row;
  gap: 0.4rem;
`;

const CenteredButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.8rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 1rem;
`;

const LabelsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;

const Label = styled.div`
  display: flex;
  width: 200px;
  background-color: ${props => props.color};
  height: 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  color: white;
  border-radius: 4px;
`;

const ColorRow = styled.div`
  display: grid;
  gap: 0.4rem;
  grid-template-columns: auto auto auto;
`;
const ColorBox = styled.a`
  display: flex;
  background-color: ${props => props.color};
  width: 60px;
  height: 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 4px;
`;

const NavigationPanel = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    cursor: pointer;
  }

  b {
    margin: 0 1rem;
  }
`;

export default LabelsControlled;
