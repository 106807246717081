import { useContext, useEffect, useState } from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useExpeditionTemplates = () => {
  const { cuLovExpeditionTemplates } = useContext(ApiContext);
  const [expeditionTemplates, setExpeditionTemplates] = useState<LovDTO[]>([]);

  useEffect(() => {
    cuLovExpeditionTemplates('', data => {
      setExpeditionTemplates(data);
    });
  }, []);

  return expeditionTemplates;
};

export default useExpeditionTemplates;
