import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';

interface ComponentProps {
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  errorMessage?: string;
}

const ETransportInputDocumentType: React.FC<ComponentProps> = ({ value, onChange, errorMessage, disabled = false }) => {
  const { tr } = useTranslationLgs();

  const model = Object.keys(InputDocumentTypeEnum)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      label: tr(`ETransportInputDocumentType.${key}`, `ETransportInputDocumentType.${key}`),
      code: InputDocumentTypeEnum[key as keyof typeof InputDocumentTypeEnum].toString(),
    }));

  return (
    <div className={'flex flex-col gap-2'}>
      <InputSelect model={model} onSelect={v => onChange(v)} selectedItemCode={value} />
      {errorMessage && <COInlineMessage variant={'error'}>{errorMessage}</COInlineMessage>}
    </div>
  );
};

export enum InputDocumentTypeEnum {
  CMR = 10,
  Invoice = 20,
  Other = 9999,
}

export default ETransportInputDocumentType;
