import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import { SelectedObject } from '../types';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import useForm from '@hooks/useForm/useForm';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { RingilHeading } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  onComplete: () => void;
}

interface FormData {
  name: string;
}

export const useContructCreateMultiDemandComponent = () => {
  const { tr } = useTranslationLgs();
  const { createGroupDemand } = useContext(ApiContext);

  const [selectedSupplierNotifications, setSelectedSupplierNotifications] = useState<SelectedObject[]>([]);

  const CreateMultiDemandDialog: React.FC<ComponentProps> = ({ onComplete }) => {
    const form = useForm<FormData>(yup.object().shape({ name: yup.string().required() }), { name: '' }, values => save(values), true, false);

    const save = (values: FormData) => {
      createGroupDemand(
        {
          groupDemandName: form.values.name,
          supplierNotificationIds: selectedSupplierNotifications.map(t => t.id),
        },
        () => {
          setSelectedSupplierNotifications([]);
          onComplete();
        },
      );
    };
    return (
      <>
        {' '}
        {/*
        // @ts-ignore*/}
        <Dialog onHide={() => setSelectedSupplierNotifications([])} visible={selectedSupplierNotifications?.length > 0}>
          <RingilHeading>{tr(`CreateMultiDemand.createGroupedDemands`, `Create grouped demands`)}</RingilHeading>
          <HorizontalFieldValue
            label={tr(`CreateMultiDemand.groupedDemandName`, `Grouped demand name`)}
            value={<InputText value={form.values.name} onChange={e => form.setFieldValue('name', e.target.value)} />}
          />
          <ButtonLayout>
            <Button label={tr(`AdditionalCostsSidebar.save`, `Uložit`)} className="p-mr-2" onClick={e => form.validateAndSend()} />
            <Button label={tr(`AdditionalCostsSidebar.cancel`, `Zrušit`)} className="p-button-text" onClick={() => setSelectedSupplierNotifications([])} />
          </ButtonLayout>
        </Dialog>
      </>
    );
  };

  return { CreateMultiDemandDialog, setSelectedSupplierNotifications };
};
