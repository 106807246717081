import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
  leftIcon?: string;
  label: string;
  rightIcon?: string;
  onClick: () => void;
}

const LinkButton: React.FC<ComponentProps> = ({ label, leftIcon, rightIcon, onClick }) => {
  return (
    <LinkInner onClick={e => onClick()}>
      {leftIcon && <img src={leftIcon} />}
      {label}
      {rightIcon && <img src={rightIcon} />}
    </LinkInner>
  );
};

const LinkInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.8rem;
  color: var(--ringil3-brand-color);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 1rem 0;
  cursor: pointer;
`;

export default LinkButton;
