import { Button } from 'primereact/button';
import classNames from 'clsx';

interface ComponentProps {
  number?: any;
  title?: any;
  pretitle?: any;
  date?: any;
  className?: any;
  btnTitle?: any;
}

const AccordionTitle: React.FC<ComponentProps> = ({ number, title, pretitle, date, className, btnTitle }) => {
  const classes = classNames(className);

  return (
    <div className={'tab-title p-grid items-center ' + classes}>
      <div className="p-d-flex p-col-12 p-md-5 p-lg-4">
        <div className="tab-number">
          <span>{number}</span>
        </div>
        <div className="tab-info">
          <div className="p-mb-1">{title}</div>
          <div style={{ color: '#999' }}>
            <small>{pretitle}</small>
          </div>
        </div>
      </div>

      <div className="tab-date p-col-12 p-md-4">
        <span>{date}</span>
      </div>
      <div className="tab-btn p-col p-text-right">
        <Button label={btnTitle} icon="pi pi-chevron-down icon" iconPos="right" className="p-button-text" />
      </div>
    </div>
  );
};

export default AccordionTitle;
