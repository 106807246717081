import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Layout from '@app/layout/Layout.tsx';
import { OnlyCompanyAdmin } from '@utils/security.tsx';
import InheritedCompanies from '@app/pages/inherited-companies/InheritedCompanies.tsx';
import Intro from '@app/layout/Intro.tsx';
import React from 'react';

const InheritedCompaniesPage = () => {
  const { tr } = useTranslationLgs();

  return (
    <Layout title={tr(`inherited-companies.inheritedCompanies`, `Inherited companies`)}>
      <OnlyCompanyAdmin>
        <InheritedCompanies />
      </OnlyCompanyAdmin>
    </Layout>
  );
};

export default withAuthenticationRequired(InheritedCompaniesPage, {
  onRedirecting: () => <Intro />,
});
