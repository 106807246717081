import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { EntityTypeEnum, ShipmentPageR2SLocationOutDTO } from '@api/logsteo-api.v2';
import { formatPrice, getTime, Identifier, isNullOrUndefined } from '@utils/utils';
import { dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { resolveLoadingUnloadingStateText } from '@utils/handlers';
import LinkedResBadge from '@components/ringil3/Badges/LinkedResBadge';
import CanMakeReservation from '@components/ringil3/Features/Reservation/Helper/CanMakeReservation';
import { useTranslation } from 'react-i18next';
import { ExpeditionDetailHandlerContext } from '@app/pages/carrier/expedition-detail/ExpeditionDetailHandler.tsx';
import { useCreateChangeVehicleInfo } from '@app/pages/carrier/expedition-detail/ChangeVehicleInfo/ChangeVehicleInfo.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InfoNote from '@components/obsolete/InfoNote/InfoNote.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import { ButtonRowNoWrap } from '@components/styles.tsx';
import LoadingUnloadingConfirmDialog from '@app/pages/carrier/common/LoadingUnloadingConfirmDialog.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import TimeList from '@components/obsolete/TimeList/TimeList.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';
import { AdditionalCosts } from '@components/obsolete/AdditionalCosts/AdditionalCosts.tsx';
import CarrierChatComponent from '@app/pages/carrier/Chat/CarrierChatComponent.tsx';
import EditCarrierTimeslot from '@app/pages/carrier/expedition-detail/EditCarrierTimeslot/EditCarrierTimeslot.tsx';
import HereMaps from '@components/features/HereMap/HereMaps.tsx';

interface ComponentProps {
  toogleToExpeditionPlan: () => void;
  disabled?: boolean;
}

const TabShipmentActive2Finish: React.FC<ComponentProps> = ({ toogleToExpeditionPlan, disabled = false }) => {
  const { t }: { t: any } = useTranslation('common');
  const { state, confirmAction, loadTabActivePlus, changeCarrierSlots, changeCarInfo } = useContext(ExpeditionDetailHandlerContext);
  const [visibleChatDialog, setVisibleChatDialog] = useState(false);
  const [visibleSlotEditSidebar, setVisibleSlotEditSidebar] = useState(false);
  const { getMapDataForExpedition, getMapLastTruckPosition } = useContext(ApiContext);

  useEffect(() => {
    loadTabActivePlus();
  }, []);

  const resolveStateColor = (location: ShipmentPageR2SLocationOutDTO): string => {
    if (location.isComplete && location.inTime) return 'green';
    if (location.isComplete && !location.inTime) return 'red';
    if (!location.isComplete && location.inTime) return 'orange';
    if (!location.isComplete && !location.inTime) return 'orange';
  };

  const [visibleMarkAsFinishedDialog, setVisibleMarkAsFinishedDialog] = useState<{
    [key: string]: { visible: boolean; locationAction: string };
  }>({});

  const setVisibilityMarkAsFinishedDialog = (locationId: Identifier, metaData: { visible: boolean; locationAction: string }) => {
    setVisibleMarkAsFinishedDialog({
      ...visibleMarkAsFinishedDialog,
      [locationId]: metaData,
    });
  };

  const isVisibleMarkAsFinishedDialog = (locationId: Identifier): { visible: boolean; locationAction: string } => {
    if (isNullOrUndefined(visibleMarkAsFinishedDialog[locationId])) return null;
    return visibleMarkAsFinishedDialog[locationId];
  };

  const { tr } = useTranslationLgs();

  const { ChangeVehicleInfo, setVisible: setVisibleChangeVehicleInfo } = useCreateChangeVehicleInfo();
  const { CreateTimeslotSidebar, setCreateTimeslotRequest, createTimeslotRequest } = useCreateTimeslotSidebar();

  return (
    <>
      {state.activeStateData && (
        <>
          <div className="p-d-flex p-grid">
            <div className="p-col-12 p-md-6 p-lg-6 p-mr-6">
              {state.activeStateData.carrierNote && (
                <HorizontalFieldValue label={tr(`TabShipmentActive2Finish.carrierNote`, `Carrier note`)} value={<InfoNote text={state.activeStateData.carrierNote} />} />
              )}

              <NavTitle>
                <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                  {t(`TabShipmentActive2Finish.expedition`, `Expedice`)}
                </h3>
                <Button
                  label={t(`TabShipmentActive2Finish.change`, `Change`)}
                  iconPos="left"
                  icon="icon pi pi-pencil"
                  className="p-button-outlined p-btn-edit p-icon-hide "
                  onClick={() => setVisibleSlotEditSidebar(true)}
                  disabled={disabled}
                />
              </NavTitle>

              {state.activeStateData.locations.map((location, locationIndex) => {
                return (
                  <div className="p-grid expedition-time-line timeslots-wrapper" key={locationIndex}>
                    <div className="p-col-12 p-xl-6 p-flex p-flex-row">
                      <PanelWithIcon nameIcon="pi pi-clock" colorIcon={resolveStateColor(location)}>
                        <Col>
                          <p>
                            <strong>{`${location.zipCode}, ${location.city} (${location.country})`}</strong>
                          </p>
                          <p>
                            <small className="text-gray5">{location.name}</small>
                          </p>
                          <p>
                            <small className={`text-${resolveStateColor(location)}`}>
                              {resolveLoadingUnloadingStateText(
                                location.isComplete,
                                location.completedAt,
                                location.loadingActionIsFinished,
                                location.loadingActionFinishedAt,
                                location.unloadingActionIsFinished,
                                location.unloadingActionFinishedAt,
                                t,
                              )}
                            </small>
                          </p>
                        </Col>
                        <ButtonRowNoWrap>
                          {!location.isComplete && isNullOrUndefined(location.unloadingActionIsFinished) && isNullOrUndefined(location.loadingActionIsFinished) && (
                            <Button
                              label={tr(`TabShipmentActive2Finish.finishLocation`, `Finish location`)}
                              onClick={e =>
                                setVisibilityMarkAsFinishedDialog(location.locationId, {
                                  visible: true,
                                  locationAction: 'noActionFinishedAt',
                                })
                              }
                            />
                          )}
                          {location.loadingActionIsFinished === false && (
                            <Button
                              label={tr(`TabShipmentActive2Finish.finishLoading`, `Finish loading`)}
                              onClick={e =>
                                setVisibilityMarkAsFinishedDialog(location.locationId, {
                                  visible: true,
                                  locationAction: 'loadingLocationFinished',
                                })
                              }
                            />
                          )}
                          {location.unloadingActionIsFinished === false && (
                            <Button
                              label={tr(`TabShipmentActive2Finish.finishUnloading`, `Finish unloading`)}
                              onClick={e =>
                                setVisibilityMarkAsFinishedDialog(location.locationId, {
                                  visible: true,
                                  locationAction: 'unloadingLocationFinished',
                                })
                              }
                            />
                          )}
                        </ButtonRowNoWrap>

                        <LoadingUnloadingConfirmDialog
                          locationId={location.locationId}
                          zipCode={location.zipCode}
                          countryCode={location.country}
                          onConfirm={(locationId, when) => {
                            confirmAction(locationId, when, isVisibleMarkAsFinishedDialog(location.locationId)?.locationAction);
                            setVisibilityMarkAsFinishedDialog(location.locationId, null);
                          }}
                          onCancel={() => setVisibilityMarkAsFinishedDialog(location.locationId, null)}
                          visible={isVisibleMarkAsFinishedDialog(location.locationId)?.visible}
                          propertyName={isVisibleMarkAsFinishedDialog(location.locationId)?.locationAction}
                        />
                      </PanelWithIcon>
                    </div>
                    <div className="p-col-12 p-xl-6">
                      <PanelWithIcon srcIcon="/images/calendar.svg">
                        <Label title={t(`TabShipmentActive2Finish.carrierSTimeslot`, `Časové okno dopravce`)} />
                        <TimeList>
                          <>
                            {location.selectedTimeslots
                              .filter(s => s.isSelected)
                              .map((timeslot, timeslotIndex) => {
                                return (
                                  <TimeListItem
                                    key={timeslotIndex}
                                    date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                                    isWholeDay={timeslot.isNotSpecifiedTime}
                                    time={`${getTime(mapFromAPIDateTime(timeslot.sinceInUTC))} - ${getTime(mapFromAPIDateTime(timeslot.tillInUTC))}`}
                                  />
                                );
                              })}
                          </>
                          <div className={'p-mt-2'}>
                            {location.linkedReservation && <LinkedResBadge linkedReservation={location.linkedReservation} />}
                            {isNullOrUndefined(location.linkedReservation) && (
                              <CanMakeReservation companyLocationId={location.companyLocationId}>
                                <Button
                                  label={tr(`TabShipmentActive2Finish.createReservation`, `Create reservation`)}
                                  onClick={e => {
                                    setCreateTimeslotRequest({
                                      applicationId: state.header.applicationId,
                                      companyLocationId: location.companyLocationId,
                                      preferredDate: '',
                                      companyLocationCustomerId: location.companyLocationCustomerId,
                                      expeditionLocationId: location.locationId,
                                      expeditionId: state.header.id,
                                    });
                                  }}
                                />
                              </CanMakeReservation>
                            )}
                          </div>
                        </TimeList>
                      </PanelWithIcon>
                    </div>
                  </div>
                );
              })}

              <AdditionalCosts applicationId={state.header.applicationId} companyId={state.header.companyId} editable={false} />

              {createTimeslotRequest && (
                <CreateTimeslotSidebar
                  onContinue={() => {
                    loadTabActivePlus();
                  }}
                />
              )}

              <Button
                label={t(`TabShipmentActive2Finish.expeditionRoute`, `Plán expedice`)}
                iconPos="right"
                icon="pi pi-chevron-right"
                className="p-pl-0 p-pr-0 p-mt-2 p-mb-6 p-button-text"
                onClick={toogleToExpeditionPlan}
              />
              <NavTitle>
                <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                  {t(`TabShipmentActive2Finish.assignedCar`, `Assigned car`)}
                </h3>
                <Button
                  label={t(`TabShipmentActive2Finish.edit`, `Upravit`)}
                  iconPos="left"
                  icon="icon pi pi-pencil"
                  className="p-button-outlined p-btn-edit p-icon-hide "
                  onClick={() => setVisibleChangeVehicleInfo(true)}
                  disabled={disabled}
                />
              </NavTitle>
              <div className="timeslots-wrapper" style={{ position: 'relative' }}>
                <PanelWithIcon srcIcon="/images/contacts.svg">
                  <p>
                    <strong className="p-mr-3">{t(`TabShipmentActive2Finish.plates`, `SPZ`)}</strong>
                    {state.activeStateData.carInfo.carPlate}
                  </p>
                  <p>
                    <strong className="p-mr-3">{t(`TabShipmentActive2Finish.driver`, `Řidič`)}</strong>
                    {state.activeStateData.carInfo.driverName}
                  </p>
                  <p>
                    <strong className="p-mr-3">{t(`TabShipmentActive2Finish.dispatcher`, `Dispečer`)}</strong>
                    {state.activeStateData.carInfo.dispatcherName}
                  </p>
                </PanelWithIcon>
              </div>
              <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                {t(`TabShipmentActive2Finish.customer`, `Zákazník`)}
              </h3>
              <PanelWithIcon srcIcon="/images/contacts.svg">
                <Label title={state.activeStateData.customerInfo.name} />
                <p>{`${state.activeStateData.customerInfo.streetNr}, ${state.activeStateData.customerInfo.city}, (${state.activeStateData.customerInfo.country})`}</p>
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/user.svg">
                <p>{state.activeStateData.customerInfo.responsiblePersonFullName}</p>
                <p className="text-gray5">
                  <small>{t(`TabShipmentActive2Finish.responsiblePerson`, `Zodpovědná osoba`)}</small>
                </p>
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/envelope.svg">
                <Button
                  icon="pi pi-envelope"
                  className="p-button-outlined "
                  label={t(`TabShipmentActive2Finish.writeMessage`, `Napsat zprávu`)}
                  onClick={() => setVisibleChatDialog(true)}
                />
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/money.svg">
                <p>{t(`TabShipmentActive2Finish.agreedPrice`, `Smluvená cena`)}</p>
                <p>
                  <strong>{formatPrice(state.activeStateData.customerInfo.price, state.activeStateData.customerInfo.currency)}</strong>
                </p>
              </PanelWithIcon>
            </div>
            <div className="p-col-12 p-md-5">
              <Label title={tr(`TabShipmentActive2Finish.map`, `Map`)} />
              <HereMaps customerId={state.header.companyId} applicationId={state.header.applicationId} />
            </div>
          </div>
          <CarrierChatComponent
            companyId={state.header.companyId}
            entityId={state.header.id}
            entityType={EntityTypeEnum.EXPEDITION}
            visible={visibleChatDialog}
            onHide={() => {
              setVisibleChatDialog(false);
            }}
            customerName={state.header.customerName}
          />
          <EditCarrierTimeslot
            visible={visibleSlotEditSidebar}
            onHide={() => setVisibleSlotEditSidebar(false)}
            companyId={state.header.companyId}
            applicationId={state.header.applicationId}
            onUpdateSlot={data => {
              changeCarrierSlots(data);
              setVisibleSlotEditSidebar(false);
            }}
          />
          <ChangeVehicleInfo
            onComplete={() => {
              // reload
              loadTabActivePlus();
            }}
            data={{
              driverId: state.activeStateData.carInfo.driverId,
              carPlate: state.activeStateData.carInfo.carPlate,
              dispatcherId: state.activeStateData.carInfo.dispatcherId,
            }}
            expeditionId={state.header.id}
            showDispatcher={true}
            showDriver={true}
          />
        </>
      )}
    </>
  );
};
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;

export default TabShipmentActive2Finish;
