import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiContext } from '@api/api';
import { AutoComplete } from 'primereact/autocomplete';

interface ComponentProps {
  department: string;
  onChange: (department: string) => void;
  style?: any;
  disabled?: boolean;
}

const InputDepartmentPlain: React.FC<ComponentProps> = ({ style, onChange, department, disabled = false }) => {
  const { cuLovDepartments } = useContext(ApiContext);
  const [backendData, setBackendData] = useState<string[]>([]);
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    cuLovDepartments('', d => {
      setData(d);
    });
  }, []);

  const inputRef = useRef(undefined);

  const keyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      const value = inputRef.current.value;
      const existed = data.some(t => t === value);
      onChange(value);
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <AutoComplete
        disabled={disabled}
        suggestions={backendData}
        value={department ? [department] : []}
        inputRef={inputRef}
        multiple={true}
        onSelect={e => {
          onChange(e.value);
        }}
        selectedItemTemplate={(e: string) => {
          return <>{e}</>;
        }}
        itemTemplate={(e: string) => <div>{e}</div>}
        completeMethod={e => setBackendData(data.filter(t => t.indexOf(e.query) >= 0))}
        onKeyUp={e => keyUp(e)}
        onUnselect={e => {
          onChange(null);
        }}
        inputStyle={style}
        style={style}
      />
    </>
  );
};

export default InputDepartmentPlain;
