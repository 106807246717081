import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils';
import { PickList } from 'primereact/picklist';
import { LovDTO, ManageReportsAdminReportsResponse, ReportDefinitionCompanySettingResponse } from '@api/logsteo-api.v2.tsx';
import { ApiContext } from '@api/api.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';

interface ComponentProps {
  onComplete: () => void;
  onHide: () => void;
  selectedDefinition: ManageReportsAdminReportsResponse;
  companyId: string;
}

const ManageReportSettingDialog: React.FC<ComponentProps> = ({ onComplete, onHide, selectedDefinition, companyId }) => {
  const { lovUsersForCompany, updateReportPermission, getReportPermission } = useContext(ApiContext);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    lovUsersForCompany('', companyId, d => {
      setSource(d);
    });
  }, [companyId]);

  useEffect(() => {
    if (selectedDefinition?.companySettings) {
      getReportPermission(selectedDefinition.reportDefinitionId, companyId, companySettings => {
        form.overwriteValues(companySettings);
        setSource(s => s.filter(t => companySettings.permittedUsers.find(t2 => t2.id == t.id) == null));
      });
    }
  }, [selectedDefinition]);

  const form = useForm<ReportDefinitionCompanySettingResponse>(
    yup.object().shape({}),
    selectedDefinition?.companySettings ? selectedDefinition?.companySettings : { wholeCompany: false, permittedUsers: [] },
    d => save(d),
    false,
    false,
  );

  const save = (formData: ReportDefinitionCompanySettingResponse) => {
    updateReportPermission(
      selectedDefinition.reportDefinitionId,
      companyId,
      {
        wholeCompany: formData.wholeCompany,
        permittedUserIds: formData.permittedUsers.map(t => t.id),
      },
      () => {
        onComplete();
      },
    );
  };

  const [source, setSource] = useState([]);

  const onChange = (event: any) => {
    setSource(event.source);
    form.setFieldValue(form.names.permittedUsers, event.target);
  };

  const itemTemplate = (item: LovDTO) => {
    return item.name;
  };

  return (
    <>
      <RingilDialog
        visible={isNotNullOrUndefined(selectedDefinition)}
        onHide={() => onHide()}
        onSave={() => form.validateAndSend()}
        headerText={tr(`ManageReportSettingDialog.reportSettings`, `Report settings`)}
      >
        <HorizontalFieldValue
          label={tr(`ManageReportSettingDialog.visibleFowWholeCompany`, `Visible fow whole company`)}
          value={<InputYesNoPlain value={form.values.wholeCompany} onChange={v => form.setFieldValue(form.names.wholeCompany, v)} />}
        />
        {!form.values.wholeCompany && (
          <HorizontalFieldValue
            label={tr(`ManageReportSettingDialog.specificUsersOnly`, `Specific users only`)}
            value={
              <PickList
                source={source.sort()}
                target={form.values.permittedUsers}
                itemTemplate={itemTemplate}
                onChange={onChange}
                sourceHeader="Available"
                targetHeader="Selected"
                showSourceControls={false}
                showTargetControls={false}
              />
            }
          />
        )}
      </RingilDialog>
    </>
  );
};

export default ManageReportSettingDialog;
