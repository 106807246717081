import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@/hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';

interface ComponentProps {}

const SupplierManifestAd: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Inner>
        <h2>{tr(`SupplierManifestAd.supplierManifest`, `Avizace`)}</h2>
        <p>
          {tr(
            `SupplierManifestAd.description`,
            `Konec přepisování e-mailů dodavatelů. Informace o dostupnosti přehledně a s možností vytvořit přepravu jedním kliknutím.`,
          )}
        </p>
        <a
          onClick={e => {
            setVisible(true);
          }}
        >
          {tr(`SupplierManifestAd.interestButton`, `Mam zajem`)}
        </a>
      </Inner>
      {/*
        // @ts-ignore*/}
      <Dialog onHide={() => setVisible(false)} visible={visible} header={tr(`SupplierManifestAd.dialogTitle`, `Mám zájem o modul Avizace`)}>
        <p>
          {tr(
            `SupplierManifestAd.dialogLine1`,
            `Konec přepisování e-mailů dodavatelů. Informace o dostupnosti přehledně a s možností vytvořit přepravu jedním kliknutím.`,
          )}
        </p>
        <strong>{tr(`SupplierManifestAd.keyFeatures`, `Klíčové vlastnosti`)}</strong>
        <Ul>
          <li>{tr(`SupplierManifestAd.feature1`, `Dodavatelům dostupné zdarma`)}</li>
          <li>{tr(`SupplierManifestAd.feature2`, `Vytvoření přepravy z avizace kliknutím`)}</li>
          <li>{tr(`SupplierManifestAd.feature3`, `Avizace uvnitř společnosti`)}</li>
        </Ul>
        <br />
        <b>
          {tr(`SupplierManifestAd.contactUs`, `V případě zájmu o modul nás prosím kontaktujte na`)}{' '}
          <a
            href={'mailto:podpora@ringil.com?subject=Zájem o modul Avizace&body=Dobrý den, mám zájem používat modul Avizace. Prosím o poskytnutí více informací. Děkuji.'}
          >
            podpora@ringil.com
          </a>
        </b>
      </Dialog>
    </>
  );
};

const Ul = styled.ul`
  padding: 5px;
  list-style-position: inside;
  list-style-type: disc;
`;

const Inner = styled.div`
  display: flex;
  row-gap: 0.4rem;
  padding: 1rem 2rem;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgb(102 102 102 / 35%);
`;

export default SupplierManifestAd;
