import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Tab from '@components/framework/tabs/Tab.tsx';
import SvgSwapIcon from '@app/icons/cams/tabs/SwapIcon.tsx';
import SvgSettings from '@components/ringil3/icons/menu/Settings.tsx';
import styled from 'styled-components';
import useNavigation from '@hooks/useNavigation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  selectedPanel: 'log' | 'setting';
}

const CamsTabs: React.FC<ComponentProps> = ({ selectedPanel }) => {
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { hasPermission } = useContext(AuthenticatedUserContext);

  return (
    <TabsInner>
      {hasPermission([PermissionTypesEnum.PERMISSION_CAM_ENTRYLOG]) && (
        <Tab
          icon={<SvgSwapIcon />}
          label={tr(`CamsTabs.entryLog`, `Entry log`)}
          active={selectedPanel === 'log'}
          onClick={() => {
            nav.navigate(nav.urlFunctions.createCamsDetail());
          }}></Tab>
      )}

      {hasPermission([PermissionTypesEnum.PERMISSION_CAM_SETTINGS]) && (
        <Tab
          icon={<SvgSettings />}
          label={tr(`CamsTabs.settings`, `Nastavení`)}
          active={selectedPanel === 'setting'}
          onClick={() => {
            nav.navigate(nav.urlFunctions.createCamsSettings());
          }}></Tab>
      )}
    </TabsInner>
  );
};

const TabsInner = styled.div`
  display: flex;
  margin: 1.5rem 0 0.5rem 0;
  gap: 0.5rem;
  border-bottom: 2px solid var(--ringil3-brand-color);
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--ringil3-base-tertiary-color);
`;

export default CamsTabs;
