import React, { useContext, useState } from 'react';
import SupplierNotificationHeaderForm, { SupplierNotificationHeaderData } from '../forms/SupplierNotificationHeaderForm';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { SNConfiguration } from '@api/logsteo-api.v2';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';

interface ComponentProps {
  initialData?: SupplierNotificationHeaderData;
  supplierNotificationId: string;
  readonly configuration: SNConfiguration;
}

export const useCreateEditSupplierNotificationHeaderSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditSupplierNotificationHeaderSidebar: React.FC<ComponentProps> = ({ initialData, supplierNotificationId, configuration }) => {
    const { updateNotificationSupplierUpdateDataForLocationStatus } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors, clear } = useBEValidation();

    const form = useForm<SupplierNotificationHeaderData>(
      {},
      initialData,
      v => {
        updateNotificationSupplierUpdateDataForLocationStatus(
          supplierNotificationId,
          form.values,
          () => {
            setVisible(false);
            onComplete();
          },
          null,
          { onValidationFailed: setBeValidationErrors },
        );
      },
      true,
      true,
    );

    const { tr } = useTranslationLgs();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditSupplierNotificationHeaderSidebar.updateSupplierNotification`, `Update supplier notification`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        <BackendValidationComponent />
        <SupplierNotificationHeaderForm form={form} configuration={configuration} showTimeslots={false} />
      </RingilSidebar>
    );
  };

  return { EditSupplierNotificationHeaderSidebar, setVisible };
};
