import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Sidebar } from 'primereact/sidebar';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import { EntityTypeEnum } from '@api/logsteo-api.v2';
import { internalInfoPanelValidationSchema } from '@app/pages/customer/expedition-detail/InternalInfoTab/InternalInfoPanelForm.tsx';
import Section from '@components/obsolete/Section/Section.tsx';
import LabelsControlled from '@components/obsolete/Labels/LabelsControlled.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ExpeditionInfo {
  applicationId: string;
  expeditionId: string;
}

interface ComponentProps {
  onComplete: () => void;
  selectedExpeditions: ExpeditionInfo[];
}

interface Form {
  overwriteLabels: boolean;
  labels: string[];
}

export const useMAExp = () => {
  const [visible, setVisible] = useState(false);

  const MassActionExpedition: React.FC<ComponentProps> = ({ selectedExpeditions, onComplete }) => {
    const { tr } = useTranslationLgs();
    const { expUpdateMassAction } = useContext(ApiContext);

    const form = useForm<Form>(
      internalInfoPanelValidationSchema,
      { labels: [], overwriteLabels: false },
      d => {
        save(d);
      },
      true,
      false,
    );

    const save = (data: Form) => {
      expUpdateMassAction(
        {
          expIds: selectedExpeditions.map(t => t.expeditionId),
          internalData: data,
        },
        () => {
          setVisible(false);
          onComplete();
        },
      );
    };

    const names = proxiedPropertiesOf<Form>();

    return (
      <>
        {/*// @ts-ignore*/}
        <Sidebar
          onHide={() => setVisible(false)}
          visible={visible}
          closeOnEscape={false}
          blockScroll={true}
          position={'right'}
          style={{ width: 'var(--default-sidebar-width)' }}
        >
          <SidebarContent>
            <h2>{tr(`MAExp.massAction`, `Mass action`)}</h2>
            <Section
              label={tr(`MultiEditSupplierNotificationSidebar.overrideLabels`, `Override labels`)}
              showEnable={true}
              onVisibilityChanged={v => {
                form.setFieldValue(names.labels, []);
                form.setFieldValue(names.overwriteLabels, v);
              }}
            >
              <LabelsControlled
                entityType={EntityTypeEnum.EXPEDITION}
                value={form.values.labels}
                onChange={v => {
                  form.setFieldValue(names.labels, v);
                }}
              />
            </Section>
            <ButtonLayout wrapper={'WITH_PADDING_TOP_4'}>
              <Button label={tr(`MAExpUpdIntInformation.ulozit`, `Ulozit`)} className="p-mr-2" onClick={() => form.validateAndSend()} />
              <Button label={tr(`MAExpUpdIntInformation.cancel`, `Zrušit`)} className="p-button-text" onClick={e => setVisible(false)} />
            </ButtonLayout>
          </SidebarContent>
        </Sidebar>
      </>
    );
  };

  return { MassActionExpedition, setVisible };
};

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;
