import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={24} fill="none" {...props}>
    <path
      fill="#616061"
      d="M10.257 19a1.24 1.24 0 0 1-.889-.42 1.26 1.26 0 0 1 0-1.777L14.4 11.77 9.369 6.737a1.258 1.258 0 0 1 1.778-1.577l5.871 5.872a1.26 1.26 0 0 1 0 1.778l-5.87 5.77a1.24 1.24 0 0 1-.89.42"
    />
  </svg>
);
export default SvgMenuRight;
