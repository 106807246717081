import { useTranslation } from 'react-i18next';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import AdminLayout from '@app/layout/Layout.tsx';
import ModuleDashboard from '@app/pages/dashboard/ModuleDashboard.tsx';
import Intro from '@app/layout/Intro.tsx';
import React from 'react';

const IndexPage = () => {
  const { t } = useTranslation();
  const a = useAuth0();

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <AdminLayout>
      <ModuleDashboard />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(IndexPage, {
  onRedirecting: () => <Intro />,
});
