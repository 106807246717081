import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { TabPanel, TabView } from 'primereact/tabview';

import { EntityTypeEnum, ExpeditionStatusEnum, FeaturesEnum, LocationPreviewOutDTO, PropertyWithSubProperties } from '@api/logsteo-api.v2';
import { dumpVars, isNullOrUndefined } from '@utils/utils';
import TabCarrierManualAssignedPlus from './TabCarrierActive/TabCarrierManualAssignedPlus';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import InternalInfoTab from './InternalInfoTab/InternalInfoTab';
import TabDocuments from './TabDocuments/TabDocuments';
import styled from 'styled-components';
import { ExpeditionDetailView } from './types';
import { createInitialPickComponentData } from './TabCarrierPending/Pick/Pick';
import { createInitialRejectData } from './TabCarrierPending/Pick/Reject';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { CustomerExpeditionViewContext, CustomerExpeditionViewProvider } from '@app/pages/customer/expedition-detail/CustomerExpeditionView.tsx';
import ExpeditionLocation from '@components/obsolete/ExpeditionLocation/ExpeditionLocation.tsx';
import { formatTimeslotInterval } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import DynamicForm from '@components/obsolete/DynamicForm/DynamicForm.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import SubscribeComponent from '@components/obsolete/Subscribe/SubscribeComponent.tsx';
import { ButtonMoreNavigation } from '@components/obsolete/ButtonMoreNavigation/ButtonMoreNavigation.tsx';
import { Panel } from 'primereact/panel';
import ExpeditionDetailHeader from '@components/obsolete/ExpeditionDetailHeader/ExpeditionDetailHeader';
import { ExpeditionCostWrapper } from '@components/ExpeditionCost/ExpeditionCostWrapper.tsx';
import { ExpeditionCostItem } from '@components/ExpeditionCost/ExpeditionCostItem.tsx';
import CreateExpeditionUncontrolled from '@app/pages/customer/expedition-detail/CreateExpeditionUncontrolled/CreateExpeditionUncontrolled.tsx';
import TabCarrierPending from '@app/pages/customer/expedition-detail/TabCarrierPending/TabCarrierPending.tsx';
import TabCarrierActive from '@app/pages/customer/expedition-detail/TabCarrierActive/TabCarrierActive.tsx';
import TabExpeditionRoute from '@app/pages/customer/expedition-detail/TabExpeditionRoute/TabExpeditionRoute.tsx';
import TabHistory from '@app/pages/customer/expedition-detail/TabHistory/TabHistory.tsx';
import CustomerChatComponent from '@components/obsolete/Chat/CustomerChatComponent.tsx';
import CMRTableTab from '@components/obsolete/cmr/CMRTableTab.tsx';

const CustomerExpeditionDetailInternal: React.FC = () => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const [activeIndex, setActiveIndex] = useState(0);
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const nav = useNavigation();

  const disabled = loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1;

  const { state, createDistribution, hideCustomerChat, openCustomerChat } = useContext(CustomerExpeditionViewContext);

  const renderThrought = (locations: LocationPreviewOutDTO[]) => {
    const stops: LocationPreviewOutDTO[] = locations.filter((loc, locIndex, locs) => locIndex > 0 && locIndex < locs.length - 1);

    return (
      stops && (
        <ExpeditionLocation
          locationPoint={t('customerExpeditionDetail.labelThrough', 'Přes')}
          locationAddress={stops.length == 1 ? locations[1].name : t('customerExpeditionDetail.stops', { count: stops.length })}
          locationDate={formatTimeslotInterval(locations[1].timeslots)}
          locationDisabled={false}
          render={stops.length > 0}
        />
      )
    );
  };

  const customerExpeditionBreadCrumb = () => {
    return [
      {
        label: t('NewDetailExpedition.allExpeditions', 'All expeditions'),
        url: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionList()),
      },
      {
        label: t('NewDetailExpedition.expeditionDetail', 'Expedition detail'),
        disabled: true,
      },
    ];
  };

  /**
   * Creates new distribution is NEW state
   * @param customerId
   * @param applicationId
   * @param distribution
   */

  const isNew = !state.header.isDistributionCreated;
  const isAssigning = state.header.isDistributionCreated && !state.header.isCarrierAssigned;
  const isAsignedPlus = state.header.isDistributionCreated && state.header.isCarrierAssigned;

  const displayManualMode = state.header.isManualExpedition && isNullOrUndefined(state.header.expeditionToId);
  const navigation = useNavigation();

  const { getDescribeModel } = useContext(ApiContext);

  const [describedExpeditionModel, setDescribedExpeditionModel] = useState<PropertyWithSubProperties>(undefined);
  useEffect(() => {
    getDescribeModel('r', 'expedition', d => {
      setDescribedExpeditionModel(d);
    });
  }, []);

  const hasCustomerTab = describedExpeditionModel && describedExpeditionModel.innerProperty.find(t => t.name === 'additionalInvoicingInfo');

  const resolveAdditional = (attribute: string) => {
    if (hasCustomerTab) {
      return (
        <>
          {/*//
      @ts-ignore*/}
          <TabPanel header={tr(`NewDetailExpedition.additionalInfo`, `Additional info`)}>
            <ContentEditPanel>
              <DynamicForm
                contentModel={'expeditionInvoicing'}
                modelPrefix={'r'}
                attributeName={'additionalInvoicingInfo'}
                readMode={false}
                applicationId={state.header.applicationId}
                customerId={state.header.companyId}
              />
            </ContentEditPanel>
          </TabPanel>
        </>
      );
    }
    return null;
  };

  const resolveCMR = () => {
    if (loggedUser?.features?.some(t => t === FeaturesEnum.CMR))
      return (
        /*// @ts-ignore*/
        <TabPanel header={tr(`NewDetailExpedition.cmr`, `CMR`)}>
          <CMRTableTab expeditionId={state.header.id} />
        </TabPanel>
      );
    return null;
  };

  const additionalPanes = [resolveAdditional('additionalInvoicingInfo'), resolveCMR()].filter(t => t !== null);

  return (
    <>
      <div className="p-grid p-jc-between p-ai-center p-mb-5">
        <div className="p-col-12 p-md-8  ">
          <BreadCrumbComponent items={customerExpeditionBreadCrumb()} />
        </div>

        <div className="p-col-12 p-md-4 p-d-flex p-jc-md-end">
          <ButtonLayout wrapper="WITHOUT_PADDING_BOTTOM">
            <SubscribeComponent customerId={state.header.companyId} applicationId={state.header.applicationId} />
            {loggedUser.securityRoles.indexOf('ROLE_EXP_RW') >= 0 && (
              <ButtonMoreNavigation
                expeditionId={state.header.id}
                customerId={state.header.companyId}
                applicationId={state.header.applicationId}
                isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
                onCanceledExpedition={() => {
                  window.location.reload();
                }}
                onSwitchedToManualExpedition={(applicationId: string) => {
                  /*router.push(`/customer/expedition/${applicationId}`);*/
                  navigation.navigate(navigation.urlFunctions.createCustomerExpeditionDetail(applicationId));
                }}
                isDisabledByManualMode={state.header.isManualExpedition && !isNullOrUndefined(state.header.expeditionToId)}
                currency={state.header.expeditionCurrency}
                onAddedExtraCosts={() => {
                  window.location.reload();
                }}
                showExtraCosts={
                  state.header.expeditionStatus !== ExpeditionStatusEnum.NEW &&
                  state.header.expeditionStatus !== ExpeditionStatusEnum.ASSIGNING &&
                  state.header.expeditionStatus !== ExpeditionStatusEnum.CANCELED &&
                  !(state.header.isManualExpedition && !isNullOrUndefined(state.header.expeditionToId))
                }
                onManuallyAssignedCarrier={() => {
                  window.location.reload();
                }}
                inStateNew={state.header.expeditionStatus === ExpeditionStatusEnum.NEW}
                assignedDispatcherEmail={state.header.carrierDispatcherEmail}
                isArchived={state.header.isArchived}
                onArchivedChanged={() => {
                  window.location.reload();
                }}
                canBeFinished={state.header.expeditionStatus !== ExpeditionStatusEnum.FINISHED && state.header.expeditionStatus !== ExpeditionStatusEnum.CANCELED}
                isDistributionCreated={state.header.isDistributionCreated === true}
                linkedToSN={state.header.supplierNotificationLink != null}
              />
            )}
          </ButtonLayout>
        </div>
      </div>
      {/*//
      @ts-ignore*/}
      <Card>
        {/*//
      @ts-ignore*/}
        <Panel className="offset">
          <ExpeditionDetailHeader
            expeditionId={state.header.id}
            applicationId={state.header.applicationId}
            distance={state.header.distance}
            expeditionType={state.header.expeditionType}
            isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
            expeditionState={state.header.expeditionStatus}
            expeditionToId={state.header.expeditionToId}
            expeditionFromId={state.header.expeditionFromId}
            isManualExpedition={state.header.isManualExpedition}
            cancelationReasonText={state.header.cancellationReasonCode}
            supplierNotificationLink={state.header.supplierNotificationLink}
            handlingUnitCount={state.header.handlingUnitCount}
          />
          <div className="locations-expedition-main">
            <div className="p-grid p-mt-3 p-mb-3 locations">
              <ExpeditionLocation
                locationPoint={t('labelFrom')}
                locationAddress={state.header.locations[0].name}
                locationDate={formatTimeslotInterval(state.header.locations[0].timeslots)}
                locationDisabled={false}
              />
              {renderThrought(state.header.locations)}
              <ExpeditionLocation
                locationPoint={t('labelTo')}
                locationAddress={state.header.locations[state.header.locations.length - 1].name}
                locationDate={formatTimeslotInterval(state.header.locations[state.header.locations.length - 1].timeslots)}
                locationDisabled={false}
              />
            </div>

            {state.header.sumPrice && (
              <ExpeditionCostWrapper>
                <ExpeditionCostItem
                  name={tr(`NewDetailExpedition.agreedPrice`, `Smluvná cena za přepravu`)}
                  price={state.header.sumPrice.agreedPrice}
                  currency={state.header.sumPrice.agreedPriceCurrency}
                />
                <ExpeditionCostItem
                  name={tr(`NewDetailExpedition.extraCostSumPriceInternal`, `Total internal extra costs`)}
                  price={state.header.sumPrice.extraCostSumPriceInternal}
                  currency={state.header.sumPrice.extraCostSumCurrency}
                />
                <ExpeditionCostItem
                  name={tr(`NewDetailExpedition.extraCostSumPrice`, `Celkové vícenáklady`)}
                  price={state.header.sumPrice.extraCostSumPrice}
                  currency={state.header.sumPrice.extraCostSumCurrency}
                />
                <ExpeditionCostItem
                  name={tr(`NewDetailExpedition.finalPrice`, `Výsledna cena`)}
                  price={state.header.sumPrice.finalPrice}
                  currency={state.header.sumPrice.finalPriceCurrency}
                />
              </ExpeditionCostWrapper>
            )}
          </div>
          {/*// @ts-ignore*/}
          <TabView
            className="tabs"
            activeIndex={activeIndex}
            onTabChange={e => {
              if (e.index == 5) openCustomerChat();
              else setActiveIndex(e.index);
            }}>
            {/*// @ts-ignore*/}
            <TabPanel header={t('NewDetailExpedition.carrier', 'Dopravce')} leftIcon="pi pi-cog">
              <div className="tab-content">
                {!displayManualMode && isNew && (
                  <CreateExpeditionUncontrolled
                    onSubmit={(e: any) => {
                      createDistribution(state.header.companyId, state.header.applicationId, e);
                    }}
                    isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
                    isDeprecatedByManualMode={state.header.isManualExpedition && !isNullOrUndefined(state.header.expeditionToId)}
                  />
                )}

                {!displayManualMode && isAssigning && (
                  <TabCarrierPending
                    applicationId={state.header.applicationId}
                    customerId={state.header.companyId}
                    requirements={state.header.requirements}
                    // @ts-ignore
                    selectedHistory={() => {
                      setActiveIndex(4);
                    }}
                    isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
                    isDeprecatedByManualMode={state.header.isManualExpedition && !isNullOrUndefined(state.header.expeditionToId)}
                  />
                )}
                {!displayManualMode && isAsignedPlus && <TabCarrierActive />}

                {displayManualMode && (
                  <>
                    <span>
                      <TabCarrierManualAssignedPlus />
                    </span>
                  </>
                )}
              </div>
            </TabPanel>
            {/*// @ts-ignore*/}
            <TabPanel header={t('NewDetailExpedition.route', 'Plán expedice')} leftIcon="pi pi-image">
              <div className="tab-content">
                <TabExpeditionRoute
                  isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
                  isDeprecatedByManualMode={state.header.isManualExpedition && !isNullOrUndefined(state.header.expeditionToId)}
                />
              </div>
            </TabPanel>
            {/*// @ts-ignore*/}
            <TabPanel header={t(`NewDetailExpedition.internalInformation`, `Internal information`)} leftIcon="pi pi-image">
              <InternalInfoTab
                isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
                isDeprecatedByManualMode={state.header.isManualExpedition && !isNullOrUndefined(state.header.expeditionToId)}
                applicationId={state.header.applicationId}
                companyId={state.header.companyId}
              />
            </TabPanel>
            {/*// @ts-ignore*/}
            <TabPanel header={t('NewDetailExpedition.documents', 'Dokumenty')} leftIcon="pi pi-file-o">
              <TabDocuments
                documents={state.documents}
                onDelete={documentId => {}}
                applicationId={state.header.applicationId}
                companyId={state.header.companyId}
                isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
                isDeprecatedByManualMode={state.header.isManualExpedition && !isNullOrUndefined(state.header.expeditionToId)}
                expeditionId={state.header.id}
              />
            </TabPanel>
            {/*// @ts-ignore*/}
            <TabPanel header={t('NewDetailExpedition.auditlog', 'Historie')} leftIcon="pi pi-replay">
              <TabHistory />
            </TabPanel>
            <TabPanel header={t('NewDetailExpedition.messagesTab', 'Messages')} leftIcon="pi pi-envelope" disabled={displayManualMode}></TabPanel>
            {additionalPanes}
          </TabView>
          <CustomerChatComponent
            companyId={state.header.companyId}
            entityId={state.header.id}
            entityType={EntityTypeEnum.EXPEDITION}
            visible={state.customerChatData.visible}
            selectedCarrierId={state.customerChatData.selectedCarrierId}
            onHide={() => hideCustomerChat()}
            readOnly={disabled}
          />
        </Panel>
      </Card>
    </>
  );
};

const ContentEditPanel = styled.div`
  display: flex;
`;

interface ComponentProps {
  id: string;
  customerId: string;
}

const CustomerExpeditionDetail: React.FC<ComponentProps> = ({ id, customerId }) => {
  const [initialData, setInitialData] = useState<ExpeditionDetailView>(null);
  const { cuGetDetailExpeditionHeader } = useContext(ApiContext);

  useEffect(() => {
    cuGetDetailExpeditionHeader(customerId, id, data => {
      setInitialData({
        header: data,
        pickSidebarData: createInitialPickComponentData(),
        rejectSidebarData: createInitialRejectData(),
        customerChatData: { visible: false },
      });
    });
  }, [id]);

  return (
    <>
      {initialData && (
        <CustomerExpeditionViewProvider initialData={initialData}>
          <CustomerExpeditionDetailInternal />
        </CustomerExpeditionViewProvider>
      )}
    </>
  );
};

export default CustomerExpeditionDetail;
