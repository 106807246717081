import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RowWithSpaceBetween } from '@components/styles';
import RingilSpinner from '@components/ringil3/RingilSpinner/RingilSpinner';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable';
import { AuditLogOutPreview, PageResponseAuditLogOutPreview } from '@api/logsteo-api.v2';
import { mapFromAPIToDateLong } from '@utils/date';
import { isNullOrUndefined } from '@utils/utils';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import SvgAuditlog from '@components/ringil3/icons/heading/Auditlog';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  applicationId: string;
  customerId: string;
}

const AuditLog: React.FC<ComponentProps> = ({ applicationId, customerId }) => {
  const [apiRequest, setApiRequest] = useState(false);
  const { tr } = useTranslationLgs();
  const [history, setHistory] = useState<PageResponseAuditLogOutPreview>();
  const { cuListExpeditionHistory } = useContext(ApiContext);
  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  useEffect(() => {
    cuListExpeditionHistory(
      customerId,
      applicationId,
      { offset: 0, pageNumber: 0, pageSize: 1000 },
      data => {
        setHistory(data);
      },
      null,
      { onValidationFailed: setBeValidationErrors },
    );
  }, []);

  if (isNullOrUndefined(history)) return <></>;

  const renderDate = (row: AuditLogOutPreview) => {
    return <div>{mapFromAPIToDateLong(row.creationDate)}</div>;
  };

  const renderActivity = (row: AuditLogOutPreview) => {
    return <div>{row.type}</div>;
  };

  const renderPerformed = (row: AuditLogOutPreview) => {
    return <div>{row.userName}</div>;
  };

  const renderCompany = (row: AuditLogOutPreview) => {
    return <div>{row.companyName}</div>;
  };

  const renderDescription = (row: AuditLogOutPreview) => {
    return <div>{row.description}</div>;
  };

  return (
    <>
      <RowWithSpaceBetween>
        <COHeadline variant={'h2'} title={tr(`AuditLog.heading`, `Audit log`)} icon={<SvgAuditlog />}></COHeadline>
        <RingilSpinner isSpinning={apiRequest} />
      </RowWithSpaceBetween>
      {/*<BackendValidationComponent />*/}
      <RingilDataTable
        columns={[
          { name: tr('AuditLog.activity', 'Activity') },
          { name: tr('AuditLog.dateTime', 'Date and time') },
          { name: tr('AuditLog.performedBy', 'Performed by') },
          { name: tr('AuditLog.company', 'Company') },
          { name: tr('AuditLog.description', 'Description') },
        ]}
        renderers={[renderActivity, renderDate, renderPerformed, renderCompany, renderDescription]}
        data={history.data}
      />
    </>
  );
};

export default AuditLog;
