import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ReservationActionTypesEnum } from '@api/logsteo-api.v2';
import InputSelectMultiple from '@components/ringil3/Input/InputSelect/InputSelectMultiple';

interface ComponentProps {
  loadingTypes: ReservationActionTypesEnum[];
  onChange: (loadingTypes: ReservationActionTypesEnum[]) => void;
}

const InputManipulationType: React.FC<ComponentProps> = ({ loadingTypes, onChange }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <InputSelectMultiple
        model={[
          {
            label: tr('InputManipulationType.loading', 'Loading'),
            code: ReservationActionTypesEnum.LOADING,
          },
          {
            label: tr('InputManipulationType.unloading', 'Unloading'),
            code: ReservationActionTypesEnum.UNLOADING,
          },
        ]}
        selectedItemCodes={loadingTypes}
        onSelect={v => onChange(v.map(item => item as ReservationActionTypesEnum))}
      />
    </>
  );
};

export default InputManipulationType;
