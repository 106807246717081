import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { DataTable } from 'primereact/datatable';
import { ApiContext } from '@api/api';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import NewExceptionSidebar from './sidebars/NewExceptionSidebar';
import {
  CompanyLocationExceptionFilterDTO,
  CompanyLocationExceptionPreviewOutDTO,
  LocationListPreviewOutDTO,
  PageResponseCompanyLocationExceptionPreviewOutDTO,
} from '@api/logsteo-api.v2';
import { dateFormatOnlyDateShort, mapFromAPIDateTime, shortDateFormat } from '@utils/date';
import { getTime } from '@utils/utils';
import { useDebounce } from 'use-debounce';
import { InputText } from 'primereact/inputtext';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import InputCompanyLocations from '@components/obsolete/Form/InputCompanyLocation/InputCompanyLocations.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';
import { BlueIcon } from '@components/styles.tsx';
import { Clickable } from '@components/ringil3/styles.tsx';
import useNavigation from '@hooks/useNavigation.tsx';

interface ComponentProps {}

export interface ExceptionListFilter {
  dateFrom?: string;
  locationIds?: string[];
  createdByName?: string;
}

interface Interval {
  since: string;
  till: string;
}

interface Locality {
  localityName: string;
  ramps: string[];
}

export interface ExceptionListType {
  id: any;
  date: string;
  closed: boolean;
  openingTime?: Interval[];
  localities?: Locality[];
  createdAt: string;
  exceptionName: string;
  createdBy: string;
}

const ExceptionList: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const [visibleNewException, setVisibleNewException] = useState(false);
  const { getCompanyLocationExceptions, deleteCompanyLocationExceptions } = useContext(ApiContext);
  const [exceptions, setExceptions] = useState<PageResponseCompanyLocationExceptionPreviewOutDTO>();
  const [deletingCheckboxes, setDeletingCheckboxes] = useState([]);
  const [visibleMultipleDelete, setVisibleMultipleDelete] = useState(false);
  const [visibleDeleteDialogExceptionId, setVisibleDeleteDialogExceptionId] = useState(undefined);

  const { Filter, applyFilterValue, filter, applyPaging, onPage, page } = useFilter<CompanyLocationExceptionFilterDTO>((filter, pagging) => {
    load(page.pageSize, page.pageNumber);
  });

  const nav = useNavigation();

  const load = (pageSize: number, pageNumber: number) => {
    getCompanyLocationExceptions(
      { pageNumber, pageSize },
      {
        locationIds: filter?.locationIds || [],
        createdByName: filter?.createdByName,
        dateFrom: filter?.dateFrom,
      },
      data => {
        setExceptions(data);
      },
    );
  };

  const renderOpeningTime = (isClosed: boolean, openingTime?: Interval[]) => {
    if (isClosed) return tr('ExceptionList.closed', 'Closed');
    return openingTime.map((item, index) => `${getTime(mapFromAPIDateTime(item.since))} - ${getTime(mapFromAPIDateTime(item.till))}`).join(', ');
  };

  const renderLocalities = (localities: LocationListPreviewOutDTO[]) => {
    if (localities.length == 0) return tr('ExceptionList.allLocations', 'All locations');
    return localities.map((item, index) => <div key={index}>{item.localityName}</div>);
  };

  const renderRamps = (localities: LocationListPreviewOutDTO[]) => {
    if (localities.length == 0) return tr('ExceptionList.allRamps', 'All ramps');
    return localities.map((item, index) => (
      <RampList key={index}>
        {item.rampsNames.length == 0 ? tr('ExceptionList.allRamps', 'All ramps') : item.rampsNames.map((ramp, rIndex) => `${ramp}`).join(', ')}
      </RampList>
    ));
  };

  const deleteMultipleItems = () => {
    deleteCompanyLocationExceptions(deletingCheckboxes, () => {
      setVisibleMultipleDelete(false);
      setDeletingCheckboxes([]);
      load(page.pageSize, page.pageNumber);
    });
  };

  const deleteException = (exceptionId: string) => {
    deleteCompanyLocationExceptions([exceptionId], () => {
      setVisibleDeleteDialogExceptionId(undefined);
      load(page.pageSize, page.pageNumber);
    });
  };

  return (
    <>
      <BreadCrumbComponent
        items={[
          {
            label: tr('ExceptionList.exceptions', 'Exceptions'),
            url: /*"/customer/exceptions"*/ nav.createNavigationLink(nav.urlFunctions.createExceptionList()),
          },
        ]}
      />
      <Row>
        <Heading>{tr(`ExceptionList.openingTimeExceptions`, `Opening time exceptions`)}</Heading>
      </Row>
      <Row>
        <SpaceBetween>
          <Row>
            <Icon className={'pi pi-reply'}></Icon>
            <Button
              disabled={deletingCheckboxes.length == 0}
              label={tr(`ExceptionList.delete`, `Delete`)}
              className={'p-outlined-button p-rounded-button'}
              onClick={e => setVisibleMultipleDelete(true)}
            />
          </Row>
          <Button
            label={tr(`ExceptionList.newException`, `New Exception`)}
            className={'p-button-outlined'}
            icon={'pi pi-plus'}
            onClick={e => setVisibleNewException(true)}
          />
        </SpaceBetween>
      </Row>
      {exceptions && exceptions.data && (
        <Row>
          <TableWrapper>
            {/*// @ts-ignore*/}

            <DataTable
              value={exceptions.data}
              rows={exceptions.size}
              paginator={true}
              lazy={true}
              onPage={onPage}
              totalRecords={exceptions.total}
              first={(exceptions.page - 1) * exceptions.size}
            >
              <Column
                header={''}
                body={(row: CompanyLocationExceptionPreviewOutDTO) => (
                  <Checkbox
                    checked={deletingCheckboxes.indexOf(row.locationExceptionId) >= 0}
                    onChange={e => {
                      if (e.target.checked === true) {
                        setDeletingCheckboxes(d => [...d, row.locationExceptionId]);
                      } else {
                        setDeletingCheckboxes(d => d.filter(t => t !== row.locationExceptionId));
                      }
                    }}
                  />
                )}
                style={{ width: '4rem' }}
              ></Column>
              <Column
                style={{ width: '9rem' }}
                header={tr(`ExceptionList.dateFrom`, `Date from`)}
                body={(row: CompanyLocationExceptionPreviewOutDTO) => (
                  <div>
                    {row.dates.map((t, index) => (
                      <div key={index}>{dateFormatOnlyDateShort(mapFromAPIDateTime(t))}</div>
                    ))}
                  </div>
                )}
                filterElement={
                  <Calendar
                    locale={'cs'}
                    dateFormat={shortDateFormat}
                    onChange={e => applyFilterValue('dateFrom', e.target.value)}
                    value={mapFromAPIDateTime(filter.dateFrom)?.toDate()}
                  />
                }
                filter={true}
              ></Column>
              <Column
                header={tr(`ExceptionList.exception`, `Exception`)}
                body={(row: CompanyLocationExceptionPreviewOutDTO) => (
                  <div>
                    <div>{renderOpeningTime(row.closed, row.openingHours)}</div>
                    <div>{row.exceptionName}</div>
                  </div>
                )}
              ></Column>
              <Column
                header={tr(`ExceptionList.locality`, `Locality`)}
                body={(row: CompanyLocationExceptionPreviewOutDTO) => <div>{renderLocalities(row.localities)}</div>}
                filterElement={<InputCompanyLocations onChange={v => applyFilterValue('locationIds', v)} value={filter.locationIds} style={{ width: '100%' }} />}
                filter={true}
              ></Column>
              <Column
                header={tr(`ExceptionList.ramps`, `Ramps`)}
                body={(row: CompanyLocationExceptionPreviewOutDTO) => <div>{renderRamps(row.localities)}</div>}
              ></Column>
              <Column
                header={tr(`ExceptionList.createdBy`, `Created by`)}
                body={(row: CompanyLocationExceptionPreviewOutDTO) => (
                  <div>
                    <div>{row.createdBy}</div>
                    <div>{dateFormatOnlyDateShort(mapFromAPIDateTime(row.createdAt))}</div>
                  </div>
                )}
                filterElement={<InputText value={filter.createdByName} onChange={e => applyFilterValue('createdByName', e.target.value)} />}
                filter={true}
              ></Column>
              <Column
                style={{ width: '4rem' }}
                header={''}
                body={(row: CompanyLocationExceptionPreviewOutDTO) => (
                  <Link onClick={e => setVisibleDeleteDialogExceptionId(row.locationExceptionId)}>
                    <BlueIcon className={'pi pi-trash'} />
                  </Link>
                )}
              ></Column>
            </DataTable>
          </TableWrapper>
        </Row>
      )}

      <Row style={{ paddingTop: '1rem' }}>
        <Clickable onClick={e => setVisibleNewException(true)}>{tr(`ExceptionList.addNewException`, `+ Add new exception`)}</Clickable>
      </Row>
      <NewExceptionSidebar
        visible={visibleNewException}
        onHide={() => setVisibleNewException(false)}
        onAdded={() => {
          setVisibleNewException(false);
          load(page.pageSize, page.pageNumber);
        }}
      />
      <ConfirmDialog
        visible={visibleMultipleDelete}
        dialogTitle={tr('ExceptionList.doYouWantToDeleteMultipleItems', 'Do you want to delete multiple items?')}
        dialogMessage={tr('ExceptionList.doYouWantToDeleteMultipleItems', 'Do you want to delete multiple items?')}
        onCancel={() => setVisibleMultipleDelete(false)}
        onConfirm={() => deleteMultipleItems()}
        onAcceptButtonText={tr(`ExceptionList.confirm`, `Confirm`)}
        onCancelButtonText={tr(`ExceptionList.back`, `Back`)}
      />
      <ConfirmDialog
        visible={visibleDeleteDialogExceptionId}
        dialogTitle={tr('ExceptionList.confirmDeleteActions', 'Confirm delete actions')}
        dialogMessage={tr('ExceptionList.doYouWantToDeleteException', 'Do you want to delete exception?')}
        onCancel={() => setVisibleDeleteDialogExceptionId(undefined)}
        onConfirm={() => deleteException(visibleDeleteDialogExceptionId)}
        onAcceptButtonText={tr(`ExceptionList.confirm`, `Confirm`)}
        onCancelButtonText={tr(`ExceptionList.back`, `Back`)}
      />
    </>
  );
};
const Link = styled.a`
  cursor: pointer;
`;
const RampList = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
`;

const TableWrapper = styled.div`
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Icon = styled.i`
  margin: auto auto;
  padding: 0 1rem 0 0;
  margin-left: 1.5rem;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

const Heading = styled.h1`
  display: flex;
  color: black;
  font-size: 2rem;
`;

const Row = styled.div`
  display: flex;
`;

export default ExceptionList;
