import React from 'react';
import { CarrierExpeditionStartOutDTO } from '@api/logsteo-api.v2';
import { dateFormatOnlyDateShort, mapFromAPIDateTime, mapFromAPIToDateShort } from '@utils/date';
import { formatFromToAPITime } from '@utils/utils';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  label: string;
  expeditionStart?: CarrierExpeditionStartOutDTO;
}

const ExpeditionListStart: React.FC<ComponentProps> = ({ label, expeditionStart }) => {
  const { t }: { t: any } = useTranslation('common');

  return (
    <>
      <div>
        <div>
          <small>{label}</small>
        </div>

        {/* Expedition start is not exists => show predefined text = termin nezadan*/}
        {!expeditionStart && <div>{t(`ExpeditionListStart.no_time`, `Termín nezadán`)}</div>}

        {/* Expedition start is exists, till is null => show only since day = 10.05.2022*/}
        {expeditionStart && !expeditionStart.till && <div>{mapFromAPIToDateShort(expeditionStart.since)}</div>}

        {/* Expedition start is exists, till is not null, show dates is false => show only since day with time interval = 10.05.2022 14:00 - 15:00*/}
        {expeditionStart && expeditionStart.till && !expeditionStart.showDates && (
          <div>
            {dateFormatOnlyDateShort(mapFromAPIDateTime(expeditionStart.since))} {formatFromToAPITime(expeditionStart.since, expeditionStart.till)}
          </div>
        )}

        {/* Expedition start is exists, till is not null, show dates is true => show interval of days = 10.05.2022 - 12.05.2022*/}
        {expeditionStart && expeditionStart.showDates && (
          <div>
            {mapFromAPIToDateShort(expeditionStart.since)} - {mapFromAPIToDateShort(expeditionStart.till)}
          </div>
        )}
      </div>
    </>
  );
};

export default ExpeditionListStart;
