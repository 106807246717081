import React, { useContext, useEffect, useState } from 'react';
import { CustomerQuoteStateEnum, ExpeditionDetailCarrierQuotesFilter, LovDTO, QuotesPreviewOutDTO } from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Column } from 'primereact/column';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import { Button } from 'primereact/button';
import { Dayjs } from 'dayjs';
import { formatPrice, isNullOrUndefined } from '@utils/utils';
import { CustomerExpeditionViewContext } from '../CustomerExpeditionView';
import { MultiSelect } from 'primereact/multiselect';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { ApiContext } from '@api/api';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { carriersTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';

interface ComponentProps {
  applicationId: string;
  companyId: string;
  disabled: boolean;
}

const FixedQuoteDataTable: React.FC<ComponentProps> = ({ applicationId, companyId, disabled }) => {
  const {
    cuGetDetailExpeditionCarrierQuotes,

    cuLovCarrierForDetailExpedition,
    cuLovEmailStatus,
    cuLovDemandStateForDetailExpedition,
  } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [pageData, setPageData] = useState<QuotesPreviewOutDTO[]>([]);

  const [filter, setFilter] = useImmer<ExpeditionDetailCarrierQuotesFilter>({});
  const { state, showPickDialog, showRejectDialog, openCustomerChatWithCarrier } = useContext(CustomerExpeditionViewContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    cuGetDetailExpeditionCarrierQuotes(companyId, applicationId, filter, data => {
      setPageData(data.quotes);
    });
  }, [filter, state]);

  const DescriptionDistributionStatus = (emailStatus: string) => {
    return (
      <div className="descrip-distribution-status">
        <span>{tr(`SpotQuoteDataTable.emailStatus.${emailStatus}`, 'SpotQuoteDataTable.emailStatus.${emailStatus}')}</span>
      </div>
    );
  };
  const DescriptionDateTime = (timeStamp: Dayjs) => {
    return (
      <div className="descrip-value">
        <span className="descrip-date text-gray5">{dateFormatDateTime(timeStamp)}</span>
      </div>
    );
  };
  const DescriptionTooltip = (index: number, name: string, status: string, dateTime: Dayjs, email: string) => {
    return (
      <div className="descrip-detail" key={index}>
        <div className="descrip-detail__name">
          <strong>{name}</strong>
        </div>
        <div className="descrip-detail__name">
          <strong>{email}</strong>
        </div>
        <div className="p-d-flex p-jc-between">
          <small className="descrip-detail__status">{tr(`SpotQuoteDataTable.emailStatus.${status}`, 'SpotQuoteDataTable.emailStatus.${status}')}</small>
          <small className="descrip-detail__data">{dateFormatDateTime(dateTime)}</small>
        </div>
      </div>
    );
  };

  const carrierNameTemplate = (rowData: QuotesPreviewOutDTO) => <span>{rowData.name}</span>;
  const communicationTemplate = (rowData: QuotesPreviewOutDTO) => {
    return (
      <div>
        {rowData.emailStatus && (
          <>
            {DescriptionDistributionStatus(rowData.emailStatus)}
            <div className="p-d-flex p-flex-wrap">
              {DescriptionDateTime(mapFromAPIDateTime(rowData.emailStatusCreatedAt))}
              <div className="tooltip">
                <Button label="Detail" className="p-button-text tooltip-button p-p-0" disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1} />
                <div className="tooltip-container">
                  <div className="descrip-wrapper">
                    {rowData.emailStatusDetail?.map((item, index) =>
                      DescriptionTooltip(index, item.userName, item.emailStatus, mapFromAPIDateTime(item.time), item.email),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const spotPriceTemplate = (rowData: QuotesPreviewOutDTO) => {
    if (rowData.customerQuoteState === CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER)
      return (
        <>
          {!isNullOrUndefined(rowData.offeredPrice) && <div>{formatPrice(rowData.offeredPrice, rowData.currency)}</div>}
          <div>
            {tr(`FixedQuoteDataTable.acceptedByCarrier`, `Akceptováno {{date}}`, {
              date: dateFormatDateTime(mapFromAPIDateTime(rowData.carrierActionAt)),
            })}
          </div>
        </>
      );

    if (rowData.customerQuoteState === CustomerQuoteStateEnum.REJECTED_BY_CARRIER)
      return (
        <>
          <div>
            {tr(`FixedQuoteDataTable.rejectedByCarrier`, `Odmítnuto {{date}}`, {
              date: dateFormatDateTime(mapFromAPIDateTime(rowData.carrierActionAt)),
            })}
          </div>
        </>
      );

    return <></>;
  };

  const buttonBodyTemplate = (rowData: QuotesPreviewOutDTO) => {
    return (
      <>
        <Button
          label={
            rowData.messagesCount === 0
              ? tr('global.messageButtonZero', 'Napsat zprávu')
              : tr('global.messageButtonWithCount', 'Zprav {{count}}', {
                  count: rowData.messagesCount,
                })
          }
          className="p-button-text"
          onClick={() => {
            openCustomerChatWithCarrier(rowData.carrierId);
          }}
          disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1}
        />
      </>
    );
  };

  const [carriers, setCarriers] = useState<LovDTO[]>([]);
  const [demandStates, setDemandStates] = useState<string[]>([]);
  const [emailStatuses, setEmailStatuses] = useState<string[]>([]);

  useEffect(() => {
    cuLovCarrierForDetailExpedition(companyId, applicationId, data => {
      setCarriers(data);
    });
    cuLovDemandStateForDetailExpedition(companyId, applicationId, data => {
      setDemandStates(data);
    });
    cuLovEmailStatus(data => {
      setEmailStatuses(data);
    });
  }, [state]);

  return (
    <>
      <StyledTable>
        {/*//
      @ts-ignore*/}
        <DataTable
          emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
          value={pageData}
          lazy={true}
          filterDisplay="row"
          /*loading={loading['cuGetDetailExpeditionCarrierQuotes']}*/
        >
          <Column
            body={carrierNameTemplate}
            filter={true}
            filterElement={
              <MultiSelect
                showClear={true}
                options={carriers}
                optionValue={'id'}
                optionLabel={'name'}
                itemTemplate={carriersTemplate}
                style={{ maxWidth: '100%' }}
                value={filter.carrierIds}
                placeholder={tr('QuoteDataTable.chooseCarrieers', 'Vyberte dopravce')}
                onChange={(e: any) => {
                  setFilter(draft => {
                    if (e.value == null) {
                      draft.carrierIds = [];
                    } else {
                      draft.carrierIds = e.value;
                    }
                    /*e.value == null
                      ? (draft.carrierIds = [])
                      : (draft.carrierIds = e.value);*/
                  });
                }}
              />
            }
          ></Column>
          <Column
            body={communicationTemplate}
            filter={true}
            filterElement={
              <MultiSelect
                options={emailStatuses.map(t => {
                  return {
                    id: t,
                    name: tr(`EmailStatus.${t}`, `EmailStatus.${t}`),
                  };
                })}
                optionValue={'id'}
                optionLabel={'name'}
                style={{ maxWidth: '100%' }}
                value={filter.emailStatuses}
                placeholder={tr('QuoteDataTable.demandState', 'Stav odeslane poptavky')}
                onChange={e => {
                  setFilter(draft => {
                    draft.emailStatuses = e.value;
                  });
                }}
              />
            }
          ></Column>
          <Column
            body={spotPriceTemplate}
            filter={true}
            filterElement={
              <MultiSelect
                options={demandStates.map(t => {
                  return {
                    id: t,
                    name: tr(`EmailStatus.${t}`, `EmailStatus.${t}`),
                  };
                })}
                optionValue={'id'}
                optionLabel={'name'}
                style={{ maxWidth: '100%' }}
                value={filter.demandPseudoStates}
                placeholder={tr('QuoteDataTable.demand', 'Nabidka')}
                onChange={e => {
                  setFilter(draft => {
                    draft.demandPseudoStates = e.value;
                  });
                }}
              />
            }
          ></Column>
          <Column body={buttonBodyTemplate} className={'actions'} />
        </DataTable>
      </StyledTable>
    </>
  );
};

const StyledTable = styled.div`
  .p-datatable .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 1rem 1rem;
  }
  .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .actions {
    width: 300px;
  }
`;

export default FixedQuoteDataTable;
