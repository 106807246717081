import React, { useEffect } from 'react';
import { isNullOrUndefined } from '@utils/utils';
import { Dropdown } from 'primereact/dropdown';
import useCountries from '@hooks/use-countries/useCountries';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  codeValue: string;
  onChange: (code: string) => void;
  style?: any;
  placeholder?: string;
  showClear?: boolean;
  required?: boolean;
}

const InputCountry: React.FC<ComponentProps> = ({ codeValue, onChange, style, placeholder, showClear = false, required = true }) => {
  const { t } = useTranslation('common');
  const coutries = useCountries();

  useEffect(() => {
    if ((isNullOrUndefined(codeValue) || codeValue === '') && required) {
      const preferedCountry = coutries.find(t => t.code === 'CZE');
      if (!isNullOrUndefined(preferedCountry)) {
        onChange(preferedCountry.code);
      }
    }
  }, [coutries]);

  return (
    <Dropdown
      style={style}
      options={coutries || []}
      optionLabel={'name'}
      optionValue={'code'}
      onChange={e => onChange(e.value)}
      value={codeValue}
      filter={true}
      placeholder={placeholder}
      showClear={showClear}
    />
  );
};

export default InputCountry;
