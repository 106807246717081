import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { isNotNullOrUndefined } from '@utils/utils';
import { LinkTokenToUserOutDTO } from '@api/logsteo-api.v2';
import { ButtonRow } from '@components/styles.tsx';

interface ComponentProps {
  selectedUserId: string;
  onHide: () => void;
}

const GenerateNewIntegrationToken: React.FC<ComponentProps> = ({ selectedUserId, onHide }) => {
  const { tr } = useTranslationLgs();
  const { generateAndAttachNewIntegrationToken } = useContext(ApiContext);
  const [generatedToken, setGeneratedToken] = useState<LinkTokenToUserOutDTO>();

  useEffect(() => {
    if (selectedUserId != null) {
      generateAndAttachNewIntegrationToken(selectedUserId, d => {
        setGeneratedToken(d);
      });
    }
  }, [selectedUserId]);

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog
        visible={isNotNullOrUndefined(selectedUserId)}
        onHide={onHide}
        style={{ width: '450px' }}
        header={tr(`GenerateNewIntegrationToken.generateNewIntegrationToken`, `Generate new integration token`)}
      >
        <p>
          {tr(
            `GenerateNewIntegrationToken.warning`,
            `A new token has been generated. Save the token, it cannot be viewed again. If you forget it, you will have to generate a new token.`,
          )}
        </p>
        <pre>{generatedToken?.token}</pre>
        <ButtonRow>
          <Button label={tr(`GenerateNewIntegrationToken.ok`, `Ok`)} onClick={e => onHide()} />
        </ButtonRow>
      </Dialog>
    </>
  );
};

export default GenerateNewIntegrationToken;
