import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { SubscriberInfoOutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  customerId: string;
  applicationId: string;
}

const SubscribeComponent: React.FC<ComponentProps> = ({ customerId, applicationId }) => {
  const [open, setOpen] = useState(false);
  const node = useRef(null);

  const [subscriberInfo, setSubscriberInfo] = useState<SubscriberInfoOutDTO>({
    amISubscribed: false,
    subscriberUsers: [],
  });
  const { cuGetSubscriberInfo, cuSetOrUnsetMeAsSubscriber } = useContext(ApiContext);

  useEffect(() => {
    cuGetSubscriberInfo(customerId, applicationId, data => {
      setSubscriberInfo(data);
    });
    document.addEventListener('mousedown', e => handleClick(e));
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleSubscribeOrUnsubscribe = () => {
    cuSetOrUnsetMeAsSubscriber(customerId, applicationId, () => {
      cuGetSubscriberInfo(customerId, applicationId, data => {
        setSubscriberInfo(data);
      });
    });
  };

  const handleClick = (e: MouseEvent) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const { t } = useTranslation('common');

  return (
    <>
      <div className={`notification-wrapper ${open ? 'active' : ''}`} ref={node}>
        <Button icon="pi pi-bell" className={`p-button-rounded ${subscriberInfo.amISubscribed ? '' : 'p-button-outlined'}  p-mr-3`} onClick={event => setOpen(!open)} />
        <div className="notification-layout animated fadeInDown">
          <div>
            {!subscriberInfo.amISubscribed && (
              <>
                <h3>{t(`SubscribeComponent.notificationsAreOff`, `Notifications are off`)}</h3>
                <p>{t(`SubscribeComponent.youWonTReceiveAny`, `You won’t receive any emails or in app notifications regarding this expedition.`)}</p>
                <Button
                  label={t(`SubscribeComponent.turnNotificationsOn`, `Turn notifications on`)}
                  icon="pi pi-bell"
                  iconPos="left"
                  onClick={e => handleSubscribeOrUnsubscribe()}
                />
              </>
            )}
            {subscriberInfo.amISubscribed && (
              <Button
                label={t(`SubscribeComponent.turnNotificationsOff`, `Turn notifications off`)}
                icon="pi pi-bell"
                iconPos="left"
                onClick={e => handleSubscribeOrUnsubscribe()}
              />
            )}

            {subscriberInfo.subscriberUsers.length > 0 && (
              <>
                <h4>{t(`SubscribeComponent.otherUsersFollowing`, `Other users following this expedition`)}</h4>
                <ul className="list-user">
                  {subscriberInfo.subscriberUsers.map((user, userIndex) => {
                    return (
                      <li className="user-item" key={userIndex}>
                        <div className="user-item__icon">
                          <strong>{user.initials}</strong>
                        </div>
                        <div className="user-item__title">
                          <strong>{user.name}</strong>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribeComponent;
