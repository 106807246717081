import React from 'react';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { SelectButton } from 'primereact/selectbutton';
import ValidationDiv from '@utils/validation';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ValidationError } from 'yup';
import { DistributionTypeEnum } from '@api/logsteo-api.v2';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';

interface ComponentProps {
  validationErrors: ValidationError[];
  distributionType: DistributionTypeEnum;
  changeDistributionType: (type: DistributionTypeEnum) => void;
  price: number;
  changeDistributionPrice: (price: number) => void;
  currency: string;
  changeDistributionCurrency: (currency: string) => void;
  disabled: boolean;
  validationPrefix?: string;
}

const DistributionAndPrice: React.FC<ComponentProps> = ({
  validationErrors,
  distributionType,
  changeDistributionType,
  price,
  currency,
  changeDistributionCurrency,
  changeDistributionPrice,
  disabled,
  validationPrefix = '',
}) => {
  const { tr } = useTranslationLgs();

  const distributionTypes = [
    {
      label: tr(`DistributionAndPrice.spot`, `Spotová poptávka`),
      value: DistributionTypeEnum.SPOT,
    },
    {
      label: tr(`DistributionAndPrice.fixedPrice`, `Pevná cena`),
      value: DistributionTypeEnum.FIXED_PRICE,
    },
  ];

  return (
    <>
      <ButtonLayout label={tr(`NewExpeditionStep4.distributionType`, `Typ poptávky`)}>
        <SelectButton
          options={distributionTypes}
          value={distributionType}
          onChange={e => {
            changeDistributionType(e.value === DistributionTypeEnum.SPOT ? DistributionTypeEnum.SPOT : DistributionTypeEnum.FIXED_PRICE);
          }}
          disabled={disabled}
        />
      </ButtonLayout>
      <div className="p-field">
        <div className="p-inputgroup">
          <InputPriceWithCurrency
            price={price}
            currency={currency}
            disabled={disabled}
            changePrice={v => changeDistributionPrice(v)}
            changeCurrency={v => changeDistributionCurrency(v)}
          />
        </div>
        <ValidationDiv errors={validationErrors} path={`${validationPrefix}price`} />
      </div>
    </>
  );
};

export default DistributionAndPrice;
