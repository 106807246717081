import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPriceAlert3 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={19} fill="none" {...props}>
    <path
      fill="#FFD100"
      d="m11.5.013-2.86 10.68c-.34 1.04-.1 2.23.72 3.06a3.01 3.01 0 0 0 4.25 0c.79-.79 1.05-1.92.78-2.91m-.29-8.86.62 2.33a7.99 7.99 0 0 1 4.78 7.32c0 2.21-.89 4.21-2.34 5.65h-.01a.994.994 0 0 0 0 1.41c.39.39 1.03.39 1.42.01a9.97 9.97 0 0 0 2.93-7.07c0-4.62-3.14-8.5-7.4-9.65m-5.2.01c-4.25 1.14-7.4 5.04-7.4 9.64 0 2.76 1.12 5.26 2.93 7.07a1.005 1.005 0 0 0 1.626-.333 1 1 0 0 0-.216-1.087 7.95 7.95 0 0 1-2.34-5.65c0-3.35 1.94-6.14 4.79-7.35"
    />
  </svg>
);
export default SvgPriceAlert3;
