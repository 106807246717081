import React, { useContext, useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Clickable, Col, Label, RingilH3, RingilSectionPanel, RingilSubsectionPanel, Row, RowWithGap, RowWithSpaceBetween } from '@components/styles.tsx';

import styled from 'styled-components';
import { Order, OrderItem } from './types';
import { ClearButton } from '../Buttons/ClearButton/ClearButton';
import { InputNumber } from 'primereact/inputnumber';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { CargoTemplateWithoutIdDTO, WeightModeTypeOrderEnum } from '@api/logsteo-api.v2';
import produce from 'immer';
import { ValidationError } from 'yup';
import { findValidationMessage } from '@utils/validation';
import { InputTextarea } from 'primereact/inputtextarea';
import { ApiContext } from '@api/api';
import { CUSTOMIZATION_MODE_DISABLED, CUSTOMIZATION_MODE_OPTIONAL, CUSTOMIZATION_MODE_REQUIRED } from '@utils/exports';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import InputSKUPlain from '@components/obsolete/Form/InputSKUPlain/InputSKUPlain.tsx';
import InputQuantity from '@components/obsolete/Form/InputQuantity/InputQuantity.tsx';
import { Stackable } from '@components/obsolete/Stackable/Stackable.tsx';
import InputWeightWithDefault from '@components/obsolete/Form/InputWeightWithDefault/InputWeightWithDefault.tsx';
import InputPricePlain from '@components/obsolete/Form/InputPricePlain/InputPricePlain.tsx';

interface ComponentProps {
  order: Order;
  onChange: (order: Order) => void;
  canBeDeleted: boolean;
  onDelete: () => void;
  showStackable: boolean;
  validationErrors: ValidationError[];
  orderIndex: number;
  inSidebar: boolean;
  companyCode?: string;
  customerId: string;
  selectedSupplierId?: string;
  showExternalOrders: boolean;
  orderValueMode: string;
  manipulationUnitNoteMode: string;
  amountOfOrderMode: string;
  disabled?: boolean;
}

export const OrderComponent: React.FC<ComponentProps> = ({
  order,
  canBeDeleted,
  onChange,
  onDelete,
  showStackable,
  validationErrors,
  orderIndex,
  inSidebar,
  companyCode,
  customerId,
  selectedSupplierId,
  showExternalOrders = false,
  orderValueMode,
  amountOfOrderMode,
  manipulationUnitNoteMode,
  disabled = false,
}) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { getIntegrationOrderForSupplier } = useContext(ApiContext);
  const [filterExternalId, setFilterExternalId] = useState('');

  const deleteOrderItem = (itemKey: number) => {
    onChange({
      ...order,
      items: order.items.filter((_, index) => index !== itemKey),
    });
  };
  const addOrder = () => {};
  const addOrderItem = () => {
    const newOrder = {
      ...order,
      items: [...order.items, { quantityUnit: 'KS', stackable: false } as OrderItem],
    };
    onChange(newOrder);
  };

  const changeOrderItem = (itemKey: number, property: string, v: any) => {
    const newValue = produce(order, draft => {
      // @ts-ignore
      draft.items[itemKey][property] = v;
    });
    onChange(newValue);
  };

  const changeOrderItemSKU = (itemKey: number, property: string, v: CargoTemplateWithoutIdDTO) => {
    const newValue = produce(order, draft => {
      // @ts-ignore
      draft.items[itemKey][property] = v;
      draft.items[itemKey].width = v.width;
      draft.items[itemKey].height = v.height;
      draft.items[itemKey].length = v.length;
    });
    onChange(newValue);
  };

  const [ordersIntegrationList, setOrdersIntegrationList] = useState([]);
  useEffect(() => {
    if (isNotNullOrUndefined(selectedSupplierId)) {
      reloadOrders();
    }
  }, [selectedSupplierId, customerId]);

  const reloadOrders = () => {
    if (selectedSupplierId) {
      setOrdersIntegrationList([]);
      getIntegrationOrderForSupplier('SUPPLIER_NOTIFICATION', selectedSupplierId, customerId, d => {
        setOrdersIntegrationList(d);
      });
    }
  };

  const toggle = (externalOrder: string) => {
    //const newOrder = order.name.replaceAll(externalOrder, '');
    onChange({ ...order, name: order.name + externalOrder + ', ' });
    /*
                        if (order.name.includes(externalOrder)) {
                          onChange({ ...order, name: newOrder });
                        } else {
                          if (newOrder.length > 0) {
                          } else {
                            onChange({ ...order, name: externalOrder });
                          }
                        }*/
  };

  return (
    <>
      <RingilSectionPanel>
        <RowWithSpaceBetween>
          <RingilH3>{tr(`Orders.orderInfo`, `Order info`)}</RingilH3>
          {canBeDeleted && <ClearButton onClick={onDelete} disabled={disabled} />}
        </RowWithSpaceBetween>
        <Row>
          <HorizontalFieldValue
            label={tr(`Orders.orderNameCompany`, `{{companyCode}} Order name`, {
              companyCode,
            })}
            required={true}
            validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].name`, tr)}
            value={
              <Col>
                <InputTextarea
                  style={{ width: '100%' }}
                  rows={2}
                  value={order.name}
                  onChange={e => {
                    onChange({ ...order, name: e.target.value });
                  }}
                  disabled={disabled}
                />
                {/*<ValidationDiv errors={formik.validationErrors} path={`orders[${orderKey}].name`} />*/}
                {ordersIntegrationList && showExternalOrders && (
                  <Col className={'p-mt-2'}>
                    <RowWithGap>
                      <Label>{tr(`Orders.existingOrders`, `Existing orders`)}</Label>
                      {!disabled && (
                        <Clickable onClick={() => reloadOrders()}>
                          <span className={'pi pi-refresh'}></span>&#160;
                          <span>{tr(`Orders.refresh`, `Refresh`)}</span>
                        </Clickable>
                      )}
                    </RowWithGap>
                    {ordersIntegrationList.length > 0 && (
                      <InputText
                        value={filterExternalId}
                        onChange={e => setFilterExternalId(e.target.value)}
                        style={{ width: 150 }}
                        placeholder={tr(`Orders.filterExternalIds`, `Filter...`)}
                      />
                    )}

                    <div>
                      {ordersIntegrationList
                        .filter(t => filterExternalId.length == 0 || t.orderCode.indexOf(filterExternalId) != -1)
                        .map((externalOrder, eoIndex) => {
                          return (
                            <Button
                              key={eoIndex}
                              className={'p-button-outlined'}
                              label={externalOrder.orderCode}
                              icon={'pi pi-plus'}
                              iconPos={'right'}
                              style={{
                                height: '30px',
                                width: 'auto',
                                whiteSpace: 'nowrap',
                              }}
                              onClick={() => {
                                toggle(externalOrder.orderCode);
                              }}
                              disabled={order.name.indexOf(externalOrder.orderCode) !== -1 || disabled}
                            ></Button>
                          );
                        })}
                    </div>
                  </Col>
                )}
              </Col>
            }
          />
        </Row>
        {order.items.map((item, itemKey) => {
          return (
            <React.Fragment key={itemKey}>
              <RingilSubsectionPanel>
                <RowWithSpaceBetween>
                  <RingilH3>{tr(`Orders.manipulationUnit`, `Manipulation unit`)}</RingilH3>
                  {order.items.length > 1 && <ClearButton onClick={() => deleteOrderItem(itemKey)} disabled={disabled} />}
                </RowWithSpaceBetween>
                <RR>
                  <RC large={inSidebar ? 12 : 6} small={inSidebar ? 12 : 2} medium={inSidebar ? 12 : 2}>
                    <HorizontalFieldValue
                      validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemKey}].cargoItemType`, tr)}
                      label={tr('Orders.manipulationUnitType', 'Manipulation unit type')}
                      value={
                        <InputSKUPlain
                          value={item.cargoItemType}
                          onChange={v => changeOrderItemSKU(itemKey, 'cargoItemType', v)}
                          style={{ width: '100%' }}
                          disabled={disabled}
                        />
                      }
                    />
                  </RC>
                  <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 2} medium={inSidebar ? 12 : 2}>
                    <HorizontalFieldValue
                      required={true}
                      validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemKey}].quantity`, tr)}
                      label={tr('Orders.amount', 'Amount')}
                      warningText={
                        item?.cargoItemType?.code === 'EURO_PALLET' && item.quantity > 33
                          ? tr(`Orders.palet33warning`, `One vehicle can transport 33 EUR pallets as standard.`)
                          : ''
                      }
                      value={
                        <InputQuantity
                          quantity={item.quantity}
                          quantityUnitCode={item.quantityUnit}
                          onChangeQuantity={v => changeOrderItem(itemKey, 'quantity', v)}
                          onChangeQuantityUnitCode={v => changeOrderItem(itemKey, 'quantityUnit', v)}
                          disabled={disabled}
                        />
                      }
                    />
                  </RC>
                  {showStackable && (
                    <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 2} medium={inSidebar ? 12 : 2}>
                      <HorizontalFieldValue
                        required={true}
                        label={tr('Orders.stackable', 'Stackable')}
                        value={
                          <Stackable
                            value={item.stackable}
                            onChange={v => {
                              changeOrderItem(itemKey, 'stackable', v);
                            }}
                            disabled={disabled}
                          />
                        }
                      />
                    </RC>
                  )}
                </RR>
                <RR>
                  <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 3}>
                    <HorizontalFieldValue
                      label={tr('Orders.lengthCm', 'Length [cm]')}
                      required={true}
                      validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemKey}].length`, tr)}
                      value={
                        <InputNumber
                          style={{ width: '100%' }}
                          value={item.length}
                          minFractionDigits={0}
                          maxFractionDigits={2}
                          locale={'cs'}
                          disabled={disabled}
                          onChange={v => changeOrderItem(itemKey, 'length', v.value)}
                        />
                      }
                    />
                  </RC>
                  <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 3}>
                    <HorizontalFieldValue
                      label={tr('Orders.widthCm', 'Width [cm]')}
                      required={true}
                      validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemKey}].width`, tr)}
                      value={
                        <InputNumber
                          style={{ width: '100%' }}
                          value={item.width}
                          minFractionDigits={0}
                          maxFractionDigits={2}
                          locale={'cs'}
                          disabled={disabled}
                          onChange={v => changeOrderItem(itemKey, 'width', v.value)}
                        />
                      }
                    />
                  </RC>
                  <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 3}>
                    <HorizontalFieldValue
                      label={tr('Orders.height', 'Height [cm]')}
                      required={true}
                      validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemKey}].height`, tr)}
                      warningText={
                        (item?.cargoItemType?.code === 'AMERICAN_PALLET' || item?.cargoItemType?.code === 'EURO_PALLET') && item.height > 180
                          ? tr(`Orders.standardPalletHeightIs1800Mm`, `Standard pallet height is 1 800 mm`)
                          : ''
                      }
                      value={
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          value={item.height}
                          minFractionDigits={0}
                          maxFractionDigits={2}
                          locale={'cs'}
                          onChange={v => changeOrderItem(itemKey, 'height', v.value)}
                        />
                      }
                    />
                  </RC>

                  <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 3}>
                    <HorizontalFieldValue
                      label={tr('Orders.weight', 'Weight [kg]')}
                      required={!(order.weight.weightModeTypeOrder === WeightModeTypeOrderEnum.CUSTOM)}
                      validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemKey}].weight`, tr)}
                      warningText={
                        (item?.cargoItemType?.code === 'AMERICAN_PALLET' || item?.cargoItemType?.code === 'EURO_PALLET') &&
                        item.quantity > 0 &&
                        item.weight / item.quantity > 500
                          ? tr('Orders.standardPalletWeightIs500Kg', 'Standard pallet weight is 500 kg')
                          : ''
                      }
                      value={
                        <InputNumber
                          style={{ width: '100%' }}
                          value={item.weight}
                          minFractionDigits={0}
                          maxFractionDigits={2}
                          locale={'cs'}
                          placeholder={
                            order.weight.weightModeTypeOrder === WeightModeTypeOrderEnum.CUSTOM ? tr(`Orders.byTotalOrderWeight`, `By total order weight`) : ''
                          }
                          disabled={order.weight.weightModeTypeOrder === WeightModeTypeOrderEnum.CUSTOM || disabled}
                          onChange={v => changeOrderItem(itemKey, 'weight', v.value)}
                        />
                      }
                    />
                  </RC>
                </RR>
              </RingilSubsectionPanel>
            </React.Fragment>
          );
        })}
        <Row>
          <Button
            className={'p-button-outlined'}
            label={tr(`Orders.addManipulationUnit`, `+ Manipulation unit`)}
            onClick={e => addOrderItem()}
            disabled={disabled}
          ></Button>
        </Row>
        <RowWithSpaceBetween>
          <RingilH3>{tr(`Orders.cargoInfo`, `Cargo info`)}</RingilH3>
        </RowWithSpaceBetween>
        {amountOfOrderMode !== CUSTOMIZATION_MODE_DISABLED && (
          <RR>
            <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 3}>
              <HorizontalFieldValue
                required={amountOfOrderMode === CUSTOMIZATION_MODE_REQUIRED}
                validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].cargoAmount`, tr)}
                label={tr(`Orders.cargoAmount`, `Cargo amount`)}
                value={
                  <>
                    <InputNumber
                      minFractionDigits={0}
                      maxFractionDigits={2}
                      locale={'cs'}
                      value={order.cargoAmount}
                      style={{ width: '100%' }}
                      onChange={v => {
                        onChange({ ...order, cargoAmount: v.value });
                      }}
                      disabled={disabled}
                    />
                  </>
                }
              />
            </RC>
            <RC large={inSidebar ? 12 : 3} small={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 3}>
              <HorizontalFieldValue
                label={tr(`Orders.cargoDescription`, `Cargo description`)}
                value={
                  <>
                    <InputText
                      value={order.cargoDescription}
                      style={{ width: '100%' }}
                      onChange={v => onChange({ ...order, cargoDescription: v.target.value })}
                      disabled={disabled}
                    />
                    {/*<ValidationDiv errors={validationErrors} path={`orders[${orderIndex}].currencyOrderValue`} />*/}
                  </>
                }
              />
            </RC>
          </RR>
        )}
        <RowWithSpaceBetween>
          <RingilH3>{tr(`Orders.orderInfo`, `Order info`)}</RingilH3>
        </RowWithSpaceBetween>
        <Row>
          <HorizontalFieldValue
            required={true}
            label={tr(`Orders.totalOrderWeightKg`, `Total order weight [kg]`)}
            value={
              <>
                <InputWeightWithDefault
                  value={order.weight}
                  onChange={v => onChange({ ...order, weight: v })}
                  summedValue={order.items.reduce((a, b) => {
                    return a + (isNullOrUndefined(b.weight) ? 0 : b.weight);
                  }, 0)}
                  disabled={disabled}
                />
                {/*<ValidationDiv errors={formik.validationErrors} path={`orders[${orderKey}].currencyOrderValue`} />*/}
              </>
            }
          />
        </Row>
        {(orderValueMode === CUSTOMIZATION_MODE_REQUIRED || orderValueMode === CUSTOMIZATION_MODE_OPTIONAL) && (
          <Row>
            <HorizontalFieldValue
              required={orderValueMode === CUSTOMIZATION_MODE_REQUIRED}
              validationError={findValidationMessage(validationErrors, `orders[${orderIndex}].currencyOrderValue`, tr)}
              label={tr(`CreateSupplierManifest.orderValue`, `Order value`)}
              value={
                <>
                  <InputPricePlain
                    onChange={v => {
                      onChange({ ...order, currencyOrderValue: v });
                    }}
                    value={order.currencyOrderValue}
                    disabled={disabled}
                  />
                </>
              }
            />
          </Row>
        )}
      </RingilSectionPanel>
    </>
  );
};

const OrderItemGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;

  @media (max-width: 1024px) {
    grid-template-columns: auto auto;
  }
`;
