import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ChatListItemMessage from '../ChatListItemMessage';
import { EntityTypeEnum, ListGroupsMessagesOutV3DTO } from '@api/logsteo-api.v2';
import { getTime, isNullOrUndefined } from '@utils/utils';
import { mapFromAPIDateTime } from '@utils/date';
import { ApiContext } from '@api/api';

interface ComponentProps {
  companyId: string;
  entityId: string;
  entityType: EntityTypeEnum;
  switchToNewMessage: () => void;
  switchToCarrierMessageThread: (carrierId: string) => void;
  readOnly?: boolean;
}

const AllMessagesPanel: React.FC<ComponentProps> = ({ entityId, entityType, companyId, switchToNewMessage, switchToCarrierMessageThread, readOnly = false }) => {
  const { tr } = useTranslationLgs();
  const { cuGetGroupsMessagesV2 } = useContext(ApiContext);
  const [state, setState] = useState<ListGroupsMessagesOutV3DTO>();

  useEffect(() => {
    const refreshThread = () => {
      cuGetGroupsMessagesV2(companyId, entityId, entityType, data => {
        setState(data);
      });
    };

    refreshThread();
    const timer = setTimeout(refreshThread, 5000);

    return () => {
      console.log(`Clearing timeout: ${timer}`);
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <AllMessagesPanelColumnWrapper>
        <SidebarHeaderWrapper>{tr(`AllMessagesPanel.allMessages`, `Všechny zprávy`)}</SidebarHeaderWrapper>
        <AddMessageWrapper>
          <Button icon="pi pi-plus" className="button-white p-button-rounded p-button-outlined" onClick={() => switchToNewMessage()} disabled={readOnly} />
        </AddMessageWrapper>
        {!isNullOrUndefined(state?.groups) && (
          <ThreadMessagesWrapper>
            {state.groups.map((message, messageIndex) => {
              return (
                <ChatListItemMessage
                  key={messageIndex}
                  icon={'s'}
                  name={message.counterPartyName}
                  text={message.lastMessage}
                  time={getTime(mapFromAPIDateTime(message.lastInteracted))}
                  unreadMessage={message.isNewMessage}
                  onClick={() => switchToCarrierMessageThread(message.counterPartyId)}
                />
              );
            })}
          </ThreadMessagesWrapper>
        )}
      </AllMessagesPanelColumnWrapper>
    </>
  );
};

const ThreadMessagesWrapper = styled.div`
  padding-top: 10px;
`;

const AddMessageWrapper = styled.div`
  padding: 15px;
  background: rgba(222, 226, 230, 0.4);
  margin: 0 -15px;
`;

const SidebarHeaderWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: center;
`;
const AllMessagesPanelColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AllMessagesPanel;
