import React, { useEffect, useId, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Tooltip } from 'primereact/tooltip';
import { EmailDetailV3DTO } from '@api/logsteo-api.v2';
import sanitizeHtml from 'sanitize-html';

interface ComponentProps {
  messageSender?: string;
  text?: string;
  author?: string;
  time?: string;
  additionalText?: string;
  icon?: string;
  myMessage: boolean;
  otherSide: boolean;
  emailDetail: EmailDetailV3DTO;
  messageId: string;
}

const ChatMessage: React.FC<ComponentProps> = ({ messageId, myMessage, otherSide, messageSender, time, author, text, icon, emailDetail }) => {
  const { tr } = useTranslationLgs();

  const [shortContent, setShortContent] = useState(true);
  const ref = useRef(undefined);
  const [showMoreLink, setShowMoreLink] = useState(false);
  const [switched, setSwitched] = useState(false);

  const safeParse = (message: string) => {
    try {
      return sanitizeHtml(message);
    } catch (e) {
      return <div>{tr(`ChatMessage.messageContainsNotValidChars`, `Message contains not valid chars`)}</div>;
    }
  };

  useEffect(() => {
    if (ref.current) {
      setShowMoreLink(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }, [shortContent]);

  const expand = () => {
    setSwitched(true);
    setShortContent(prevState => !prevState);
  };

  return (
    <MessageBubble className="messageBuble">
      <MessageWrapper myMessage={myMessage} otherSide={otherSide}>
        <MessagePanel>
          <MessageDate className="message-date">
            <strong>{author}</strong>
            {icon && (
              <>
                <i className={`${icon} messageId_${messageId}`} style={{ marginLeft: 10 }}></i>
              </>
            )}
            {icon && (
              <>
                {/*
                  // @ts-ignore*/}
                <Tooltip target={`.messageId_${messageId}`} style={{ minWidth: 450 }}>
                  <div>{tr(`ChatMessage.sentAsEmail`, `Odesláno jako email`)}</div>
                  <div>
                    {tr(`ChatMessage.subject`, `Předmět: {{subject}}`, {
                      subject: emailDetail.subject,
                    })}
                  </div>
                  <div>
                    {tr(`ChatMessage.from`, `Od: {{from}}`, {
                      from: emailDetail.from,
                    })}
                  </div>
                  <div>
                    {tr(`ChatMessage.to`, `Komu: {{to}}`, {
                      to: emailDetail.to,
                    })}
                  </div>
                </Tooltip>
              </>
            )}
          </MessageDate>
          <small className="message-author">{time}</small>
        </MessagePanel>
        <Message
          ref={ref}
          shortContent={shortContent}
          ourCompanyNotMe={true}
          onClick={e => {
            if (showMoreLink || (!showMoreLink && switched)) expand();
          }}
          clickable={showMoreLink || (!showMoreLink && switched)}>
          <span dangerouslySetInnerHTML={{ __html: safeParse(text) }}></span>
        </Message>
        <ShowCompleteMessage>
          {showMoreLink && <Link onClick={e => expand()}>{tr(`ChatMessage.showCompleteMessage`, `Zobrazit celou zprávu`)}</Link>}
          {!showMoreLink && switched && <Link onClick={e => expand()}>{tr(`ChatMessage.hideMessageDetail`, `Skrýt detail zprávy`)}</Link>}
        </ShowCompleteMessage>
      </MessageWrapper>
    </MessageBubble>
  );
};
//rgba(217, 237, 247, 1)

const MessageDate = styled.small`
  text-align: center;
  padding: 5px;
  font-size: 14px;
`;

const MessagePanel = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;
const MessageBubble = styled.div`
  padding: 6px 0;
  display: flex;
`;

const MessageWrapper = styled.div<{ otherSide: boolean; myMessage: boolean }>`
  padding: 6px 12px;
  margin: 4px 0;
  border-radius: 4px;
  display: inline-block;
  width: 85%;

  ${props =>
    props.otherSide &&
    css`
      background-color: #dff0d8;
      border: solid 1px #d6e9c6;
    `}

  ${props =>
    props.myMessage &&
    css`
      background-color: #d9edf7;
      border: solid 1px #bce8f1;
      margin-left: auto;
    `}

  ${props =>
    !props.myMessage &&
    props.otherSide == false &&
    css`
      background-color: #eff8fb;
      border: solid 1px #eff8fb;
      margin-left: auto;
    `}
`;

const ShowCompleteMessage = styled.div`
  margin-top: 5px;
  font-size: 14px;

  a {
    color: #2196f3;
    font-weight: bold;
  }
`;

const Link = styled.a`
  cursor: pointer;
`;

const Message = styled.div<{
  shortContent: boolean;
  clickable: boolean;
  ourCompanyNotMe: boolean;
}>`
  font-size: 14px;

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  overflow-y: hidden;
  ${props =>
    props.shortContent &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export default ChatMessage;
