import React, { useEffect } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { RouteType } from '../new-expedition/types';
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  routeType: RouteType;
  changeRouteType: (routeType: RouteType) => void;
}

const RouteTypeComponent: React.FC<ComponentProps> = ({ routeType, changeRouteType }) => {
  const { t } = useTranslation();

  const routeTypeOptions = [
    {
      label: t('wayPage.buttonTopPanel.buttonTypeRoute'),
      value: RouteType.DIRECT,
    },
    {
      label: t('wayPage.buttonTopPanel.buttonTypeWheel'),
      value: RouteType.ROUND_TRIP,
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(routeType)) changeRouteType(RouteType.DIRECT);
  }, []);

  return (
    <SelectButton
      value={routeType}
      options={routeTypeOptions}
      onChange={e => {
        changeRouteType(e.value);
      }}
    ></SelectButton>
  );
};

export default RouteTypeComponent;
