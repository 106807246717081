import React, { useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ValidationError } from 'yup';
import ValidationDiv from '@utils/validation';
import { LovDTO } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import { ExpeditionDetailHandlerContext } from '@app/pages/carrier/expedition-detail/ExpeditionDetailHandler.tsx';
import useDispatchers from '@hooks/dispatchers/dispatchers.tsx';
import useDrivers from '@hooks/drivers/drivers.tsx';
import InputPlate from '@components/obsolete/Form/InputPlate/InputPlate.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  disabled?: boolean;
  validationErrors: ValidationError[];
}

const AssignVehicleComponent: React.FC<ComponentProps> = ({ disabled, validationErrors }) => {
  const { t } = useTranslation('common');
  const { state, changePlate, changeDriver, changeDispatcher } = useContext(ExpeditionDetailHandlerContext);

  const dispatchers = useDispatchers(state.header.id);
  const drivers = useDrivers(state.header.id);

  return (
    <>
      <h4 className="p-mt-0" style={{ fontWeight: 'normal' }}>
        {t(`AssignVehicleComponent.assignAVehicle`, `Assign a vehicle`)}
      </h4>
      <div className="group-wrapper group-assign-vehicle">
        <div className="p-field">
          <InputPlate
            label={t('ChangeVehicleInfo.plates', 'Plates')}
            value={state.createReadyToShipment.carPlate}
            disabled={false}
            onChange={e => {
              changePlate(e);
            }}
          />
          <ValidationDiv errors={validationErrors} path={'carPlate'} />
        </div>
        <div className="p-field">
          <Label title={t(`AssignVehicleComponent.driver`, `Driver`)} />
          <Dropdown
            options={drivers}
            onChange={e => changeDriver(e.value)}
            optionLabel="name"
            optionValue="id"
            value={state.createReadyToShipment.driverId}
            disabled={disabled}
            filter={true}
            itemTemplate={(option: LovDTO) => (
              <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
            )}
          />
          <ValidationDiv errors={validationErrors} path={'driverId'} />
        </div>
        <div className="p-field">
          <Label title={t(`AssignVehicleComponent.dispatcher`, `Dispatcher`)} />
          <Dropdown
            options={dispatchers}
            optionValue="id"
            onChange={e => changeDispatcher(e.value)}
            optionLabel="name"
            value={state.createReadyToShipment.dispatcherId}
            disabled={disabled}
          />
          <ValidationDiv errors={validationErrors} path={'dispatcherId'} />
        </div>
      </div>
    </>
  );
};

export default AssignVehicleComponent;
