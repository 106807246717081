import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useContext, useEffect, useState } from 'react';
import { formatPrice, isNullOrUndefined } from '@utils/utils';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import { ExtraCostsPreviewOutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import { ColumnContent } from '@components/obsolete/ResponsiveColumnTitle/ColumnContent.tsx';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';

interface ComponentProps {
  companyId: string;
  applicationId: string;
  onRemove?: () => void;
  editable: boolean;
}
export const AdditionalCosts: React.FC<ComponentProps> = ({ companyId, applicationId, onRemove = () => {}, editable }) => {
  const { tr } = useTranslationLgs();

  const { listExtraCostsForExpedition, cuDeleteExtraCostForExpedition } = useContext(ApiContext);
  const [state, setState] = useState<ExtraCostsPreviewOutDTO[]>();

  useEffect(() => {
    listExtraCostsForExpedition(companyId, applicationId, data => {
      setState(data);
    });
  }, []);

  const [extraCostToDelete, setExtraCostToDelete] = useState<string>(undefined);

  const deleteExtraCost = (extraCostId: string) => {
    cuDeleteExtraCostForExpedition(companyId, applicationId, extraCostId, () => {
      setExtraCostToDelete(undefined);
      onRemove();
    });
  };

  const dateBodyTemplate = (rowData: ExtraCostsPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`AdditionalCosts.date`, `Datum`)} />
        <ColumnContent content={dateFormatDateTime(mapFromAPIDateTime(rowData.createdAt))} />
      </>
    );
  };
  const descriptionBodyTemplate = (rowData: ExtraCostsPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`AdditionalCosts.description`, `Popis`)} />
        <ColumnContent content={rowData.description} />
      </>
    );
  };
  const categoryBodyTemplate = (rowData: ExtraCostsPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`AdditionalCosts.category`, `Kategorie`)} />
        <ColumnContent content={rowData.type} />
      </>
    );
  };
  const priceBodyTemplate = (rowData: ExtraCostsPreviewOutDTO) => {
    return (
      <>
        <ColumnTitle title={tr(`AdditionalCosts.price`, `Cena`)} />
        <ColumnContent content={formatPrice(rowData.price, rowData.currency)} />
      </>
    );
  };
  const navBodyTemplate = (rowData: ExtraCostsPreviewOutDTO) => {
    return (
      <>
        {editable && (
          <div className="p-d-flex p-jc-end">
            <Button className="p-button-rounded p-button-outlined" icon="pi pi-trash" onClick={() => setExtraCostToDelete(rowData.id)} />
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      {state && state.length > 0 && (
        <>
          <h3 className="p-mb-0">{tr(`AdditionalCosts.extraCostsTitle`, `Vícenáklady`)}</h3>
          {/*//
      @ts-ignore*/}
          <DataTable emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)} value={state} className="additional-costs-table">
            <Column header={tr(`AdditionalCosts.date`, `Datum`)} body={dateBodyTemplate}></Column>
            <Column className="additional-costs-table__description" header={tr(`AdditionalCosts.description`, `Popis`)} body={descriptionBodyTemplate}></Column>
            <Column className="additional-costs-table__category" header={tr(`AdditionalCosts.category`, `Kategorie`)} body={categoryBodyTemplate}></Column>
            <Column
              className="additional-costs-table__category"
              header={tr(`AdditionalCosts.internal`, `Internal`)}
              body={(row: ExtraCostsPreviewOutDTO) => <div>{row.internal ? tr(`AdditionalCosts.yes`, `Yes`) : tr(`AdditionalCosts.no`, `No`)}</div>}
            ></Column>
            <Column className="additional-costs-table__price" header={tr(`AdditionalCosts.price`, `Cena`)} body={priceBodyTemplate}></Column>
            <Column className="additional-costs-table__nav" body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}

      <ConfirmDialog
        visible={!isNullOrUndefined(extraCostToDelete)}
        dialogTitle={tr(`AdditionalCosts.remove`, `Odstranit změnu v expedice`)}
        dialogMessage={tr(`AdditionalCosts.removeNote`, `Změna bude odstraněna z expedice a dopravci bude odeslán e-mail`)}
        onCancel={() => setExtraCostToDelete(undefined)}
        onConfirm={() => deleteExtraCost(extraCostToDelete)}
        onAcceptButtonText={tr(`AdditionalCosts.yesDelete`, `Ano, smazat`)}
        onCancelButtonText={tr(`AdditionalCosts.close`, `Zavřít`)}
        loading={true}
      />
    </div>
  );
};
