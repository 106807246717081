import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPriceAlert1 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={19} fill="none" {...props}>
    <path fill="#00C65E" d="m.19 10.77 10.68 2.86c1.04.34 2.23.1 3.06-.72a3.01 3.01 0 0 0-2.91-5.03" />
    <path
      fill="#00C65E"
      d="M17.511 16.392c-.418.425-.469 1.12-.047 1.542.357.357.93.39 1.292.04a9.97 9.97 0 0 0 3.054-7.194c0-5.523-4.477-10-10-10-4.629 0-8.523 3.145-9.662 7.415L4.48 7.57a8.002 8.002 0 1 1 13.031 8.822M4.482 13.995a8 8 0 0 0 1.627 2.397c.418.425.47 1.12.047 1.542-.357.357-.93.39-1.292.04a10 10 0 0 1-2.715-4.604z"
    />
  </svg>
);
export default SvgPriceAlert1;
