import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useContext } from 'react';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useTranslation } from 'react-i18next';
import Layout from '@app/layout/Layout.tsx';
import AllExpedition from '@app/pages/customer/expedition-list/AllExpedition.tsx';

const CustomerExpeditionListPage = () => {
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const { t } = useTranslation('common');
  return (
    <Layout title={t(`expeditions.AllExpedition`, `Všechny expedice`)}>
      {/*// @ts-ignore*/}
      <Card>{loggedUser?.companyId && <AllExpedition customerId={loggedUser.companyId} />}</Card>
    </Layout>
  );
};

export default withAuthenticationRequired(CustomerExpeditionListPage);
