import React from 'react';
import { mapFromAPIDateTime } from '@utils/date';
import { getTime } from '@utils/utils';
import { ReservationsDataOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  reservation: ReservationsDataOutDTO;
}

const CFRSlotDuration: React.FC<ComponentProps> = ({ reservation }) => {
  const renderTimeAndDuration = (since: string, till: string) => {
    const duration = mapFromAPIDateTime(till).diff(mapFromAPIDateTime(since), 'minutes');

    return <div>{`${getTime(mapFromAPIDateTime(since))} - ${getTime(mapFromAPIDateTime(till))} (${duration} min)`}</div>;
  };

  return (
    <>
      <div>{renderTimeAndDuration(reservation.timeslot.since, reservation.timeslot.till)}</div>
    </>
  );
};

export default CFRSlotDuration;
