import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Dropdown } from 'primereact/dropdown';
import { DocumentTypeEnum, LovDocumentTypeOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  documentType: DocumentTypeEnum;
  placeholder?: string;
  style?: any;
  onChange: (documentType: LovDocumentTypeOutDTO) => void;
}

export const InputDocumentType: React.FC<ComponentProps> = ({ documentType, placeholder, onChange, style }) => {
  const { lovAttachmentDocumentType } = useContext(ApiContext);
  const [items, setItems] = useState<LovDocumentTypeOutDTO[]>([]);

  useEffect(() => {
    lovAttachmentDocumentType(d => {
      setItems(d);
    });
  }, []);

  return (
    <>
      <Dropdown
        style={style}
        value={{ documentType }}
        options={items}
        dataKey={'documentType'}
        optionLabel={'name'}
        placeholder={placeholder}
        onChange={e => {
          onChange(e.value);
        }}
      />
    </>
  );
};
