import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

interface TabProps {
  icon?: ReactElement<{ color?: string }>;
  label: string;
  active: boolean;
  onClick?: () => void;
}

const Tab: React.FC<TabProps> = ({ icon, label, active, onClick = () => {} }) => {
  return (
    <TabInner onClick={e => onClick()} active={active}>
      <TabInnerContent>
        {isNotNullOrUndefined(icon) && <>{React.cloneElement(icon, { color: 'var(--ringil3-gr4)' })}</>}
        <TabLabel>{label}</TabLabel>
      </TabInnerContent>
    </TabInner>
  );
};

const TabLabel = styled.div`
  display: flex;
  color: var(--ringil3-gr4);
`;

const TabInnerContent = styled.div`
  display: flex;
  padding: 0 24px;
  gap: 1rem;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

const TabInner = styled.div<Pick<TabProps, 'active'>>`
  display: flex;
  padding: 1rem 0;
  background-color: var(--ringil3-quaternary-color);
  cursor: pointer;

  * {
    text-transform: uppercase;
    font-weight: 600;
  }

  ${props =>
    props.active &&
    css`
      background-color: white;
      border-bottom: 2px solid var(--ringil3-brand-color);
      color: var(--ringil3-brand-color);

      ${TabLabel} {
        color: var(--ringil3-brand-color);
      }
    `}
`;

export default Tab;
