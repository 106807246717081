import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { DistributionTypeEnum, FindCarrierCarrierOrGroupDTO } from '@api/logsteo-api.v2';
import { ValidationError } from 'yup';
import ValidationDiv, { findValidationMessage } from '@utils/validation';
import useCustomerCarriersAndGroups from '@hooks/customer-carrier/customer-carriers-groups';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import DistributionAndPrice from '@components/obsolete/DistributionAndPrice/DistributionAndPrice.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { carriersAndGroupsTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import InputWrapper from '@components/obsolete/Form/InputWrapper/InputWrapper.tsx';
import { DelayedPublishingInputComponent, DelayedPublishingLabel } from '@components/obsolete/delayed-publishing';
import { DistributionView } from '@app/pages/customer/CreateTemplate/types.ts';

interface ComponentProps {
  data: DistributionView;
  changeDistributionType: (type: DistributionTypeEnum) => void;
  changeDistributionPrice: (price: number) => void;
  changeDistributionSelectedItems: (carriersAndGroups: FindCarrierCarrierOrGroupDTO[]) => void;
  changeDistributionIntervalHour: (hours: string) => void;
  changeCurrency: (currency: string) => void;
  changeAutomaticPublishing: (automaticallyPublish: boolean) => void;
  validationErrors?: ValidationError[];
}

const CreateDistributionTemplate: React.FC<ComponentProps> = ({
  data,
  changeDistributionType,
  changeDistributionPrice,
  changeDistributionSelectedItems,
  changeDistributionIntervalHour,
  changeAutomaticPublishing,
  changeCurrency,
  validationErrors = [],
}) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const carriersAndGroups = useCustomerCarriersAndGroups();

  return (
    <>
      <div className="p-grid p-d-flex">
        <div className="p-col-12 p-lg-6 p-xl-4">
          <DistributionAndPrice
            validationErrors={validationErrors}
            distributionType={data.distributionType}
            changeDistributionType={changeDistributionType}
            price={data.price}
            changeDistributionPrice={changeDistributionPrice}
            currency={data.currency}
            changeDistributionCurrency={changeCurrency}
            disabled={false}
          />

          <div className="p-field p-grid p-mb-4">
            <div className="p-col-12">
              <Label title={t(`CreateDistributionTemplate.toWhatCarriersYouWantToSendIt`, `To what carriers you want to send it`)} />
            </div>
            <div className="p-col-12">
              <MultiSelect
                value={data.selectedItems}
                options={carriersAndGroups}
                optionLabel="name"
                filter
                className="multiselect-custom"
                onChange={e => changeDistributionSelectedItems(e.value)}
                style={{ width: '100%' }}
                itemTemplate={carriersAndGroupsTemplate}
              />
            </div>
            <div className="p-col-12">
              <ValidationDiv errors={validationErrors} path={'selectedItems'} />
            </div>
          </div>
          <InputLayout>
            <InputWrapper
              label={t(`CreateDistributionTemplate.forHowLongShouldDistributionBeValid`, `For how long should distribution be valid?`)}
              type="text"
              required={true}
              value={data.intervalHour}
              onChange={e => changeDistributionIntervalHour((e.target as HTMLInputElement).value)}
              classNameWrapper="p-col-12"
              error={findValidationMessage(validationErrors, 'intervalHour', t)}
            />
          </InputLayout>
          <div className="p-field p-grid p-mb-4">
            <div className="p-col-12">
              <DelayedPublishingLabel />
            </div>
            <div className="p-col-12">
              <DelayedPublishingInputComponent changeAutomaticPublishing={changeAutomaticPublishing} value={data.isPublished} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDistributionTemplate;
