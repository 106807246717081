import clsx from 'clsx';

interface ComponentProps {
  title?: any;
  option?: any;
  value?: any;
  className?: any;
  children?: any;
}

const CardView: React.FC<ComponentProps> = ({ title, option, value, className, children }) => {
  const classes = clsx(className);

  return (
    <div className={'card-view ' + classes}>
      <div className="card__title p-mr-1">{title}</div>
      <div className="card__description p-d-flex p-jc-between p-flex-wrap text-gray5">
        <span>{option}</span>
        {value && <span>{value}</span>}
      </div>
      <div className="card__information">{children}</div>
    </div>
  );
};

export default CardView;
