import React from 'react';
import clsx from 'clsx';

interface ComponentProps {
  children?: any;
  className?: any;
}

const CheckboxWrapper: React.FC<ComponentProps> = ({ children, className }) => {
  const classes = clsx(className);
  return <>{className ? <div className={'p-grid ' + classes}>{children}</div> : <div className={'p-grid'}>{children}</div>}</>;
};

export default CheckboxWrapper;
