import React, { useContext, useState } from 'react';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import SupplierNotificationLoadingUnloadingForm, { SupplierNotificationLoadingUnloadingData } from '../forms/SupplierNotificationLoadingUnloadingForm';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import * as yup from 'yup';
import { timeslotValidationSchema } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';

interface ComponentProps {
  initialData?: SupplierNotificationLoadingUnloadingData;
  supplierNotificationId: string;
  companyId: string;
  supplierId: string;
  supplierLocationId: string;
  customerLocationId: string;
}

export const useCreateEditSupplierNotificationLoadingUnloadingSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditSupplierNotificationLoadingUnloadingSidebar: React.FC<ComponentProps> = ({
    initialData,
    supplierNotificationId,
    companyId,
    supplierId,
    customerLocationId,
    supplierLocationId,
  }) => {
    const { updateNotificationSupplierUpdateDataForTimeslots } = useContext(ApiContext);

    const form = useForm<SupplierNotificationLoadingUnloadingData>(
      yup.object().shape({
        supplierDeliverySlots: timeslotValidationSchema,
        customerDeliverySlots: timeslotValidationSchema,
      }),
      initialData,
      v => {
        updateNotificationSupplierUpdateDataForTimeslots(
          supplierNotificationId,
          {
            unloadingDeliverySlot: form.values.customerDeliverySlots,
            loadingDeliverySlot: form.values.supplierDeliverySlots,
          },
          () => {
            setVisible(false);
            onComplete();
          },
          null,
          { onValidationFailed: setBeValidationErrors },
        );
      },
      false,
      false,
    );

    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors, clear } = useBEValidation();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditSupplierNotificationLoadingUnloadingSidebar.updateSupplierNotification`, `Update supplier notification`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        {form.values && (
          <>
            <BackendValidationComponent />
            <SupplierNotificationLoadingUnloadingForm form={form} inSidebar={true} />
          </>
        )}
      </RingilSidebar>
    );
  };

  return { EditSupplierNotificationLoadingUnloadingSidebar, setVisible };
};
