import React, { useContext, useState } from 'react';
import SelectRecipientComponent from '../SelectRecipientComponent';
import { EntityTypeEnum, FindCarrierCarrierOrGroupDTO } from '@api/logsteo-api.v2';
import ChatNav from '../ChatNav';
import styled from 'styled-components';
import Chat from '../Chat';
import { InputTextarea } from 'primereact/inputtextarea';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  entityId: string;
  entityType: EntityTypeEnum;
  companyId: string;
  switchToAllMessagesPanel: () => void;
  readOnly?: boolean;
}

const NewMessagePanel: React.FC<ComponentProps> = ({ companyId, entityId, entityType, switchToAllMessagesPanel, readOnly = false }) => {
  const [selectedRecipients, setSelectedRecipients] = useState<FindCarrierCarrierOrGroupDTO[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const { cuSendMultipleMessagesV2 } = useContext(ApiContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const { tr } = useTranslationLgs();
  const sendMessage = () => {
    cuSendMultipleMessagesV2(
      companyId,
      entityId,
      entityType,
      {
        message: newMessage,
        couterPartyIds: selectedRecipients.map(t => t.id),
      },
      () => {
        switchToAllMessagesPanel();
      },
    );
  };
  return (
    <>
      <ChatNav btnAllMessages={true} navCurrent={tr(`NewMessagePanel.newMessage`, `Nová zpráva`)} onClickBtnAllMessage={() => switchToAllMessagesPanel()} />
      <ChatPanel>
        <SelectRecipientComponent
          selectedCarriers={selectedRecipients}
          onSelectedCarriers={e => setSelectedRecipients(e)}
          entityType={entityType}
          entityId={entityId}
          companyId={companyId}
        />
      </ChatPanel>
      <Chat>
        <div className="message-service message-service-group">
          <NewMessageInput>
            <MessageInput>
              <InputTextarea
                autoResize
                value={newMessage}
                placeholder={tr(`CustomerChatComponent.writeAMessage`, `Write a message`)}
                onChange={e => {
                  setNewMessage(e.target.value);
                }}
                disabled={readOnly}
              />
            </MessageInput>
            <SendButton>
              <Button onClick={e => sendMessage()} disabled={newMessage.length === 0 || readOnly}>
                {tr(`NewMessagePanel.send`, `Odeslat`)}
              </Button>
            </SendButton>
          </NewMessageInput>
          <small>
            {tr(
              `CustomerChatComponent.privacyWarning`,
              `Dbáme na soukromí, každému dopravci se proto hromadná zpráva zobrazí v jeho vlastním samostatném
                  vlákně, kde najdete i jejich odpovědi`,
            )}
          </small>
        </div>
      </Chat>
    </>
  );
};

const TextAreaAndSendButtonPanel = styled.div`
  display: flex;
`;

const MessageInput = styled.div`
  width: 100%;
  flex-grow: 3;
  margin-right: 15px;
  textarea {
    width: 100%;
    height: 100%;
  }
`;

const NewMessageInput = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: row;
  align-items: center;
`;

const SendButton = styled.div`
  button {
    word-break: keep-all;
  }
`;

const ChatPanel = styled.div`
  padding: 15px;
  background: rgba(222, 226, 230, 0.4);
  margin: 0 -15px;
`;
export default NewMessagePanel;
