import { VisitorEntry } from '@app/pages/area-visitors/AreaVisitorList.tsx';

export const sampleData: VisitorEntry[] = [
  {
    carPlate: '6AA0322',
    entryDateTime: '2024-11-11T22:30:00Z',
    personName: 'Jiří Nov',
  },
  {
    personName: 'Alena Růžičková',
    entryDateTime: '2024-11-10T09:20:00Z',
    exitDateTime: '2024-11-10T10:30:00Z',
    note: 'Krátká návštěva',
    personTypeAndId: 'OP 123243245',
  },
  {
    carPlate: '7BC1234',
    entryDateTime: '2024-11-09T18:45:00Z',
    personName: 'Petr Svoboda',
  },
  {
    entryDateTime: '2024-11-08T07:00:00Z',
    personName: 'Jan Novák',
    exitDateTime: '2024-11-08T12:00:00Z',
  },
  {
    carPlate: '8DE5678',
    entryDateTime: '2024-11-07T14:30:00Z',
    personName: 'Eva Malá',
    note: 'Zpoždění při příjezdu',
  },
  {
    carPlate: '9IJ8765',
    entryDateTime: '2024-11-06T09:40:00Z',
    personName: 'Tomáš Havel',
  },
  {
    personName: 'Karolína Novotná',
    entryDateTime: '2024-11-05T16:10:00Z',
    note: 'Bez SPZ',
  },
  {
    carPlate: '5MN6543',
    entryDateTime: '2024-11-04T11:25:00Z',
    personName: 'Lukáš Král',
  },
  {
    carPlate: '3AB1234',
    entryDateTime: '2024-10-28T08:00:00Z',
    personName: 'Václav Vlk',
    exitDateTime: '2024-10-28T16:00:00Z',
    note: 'Poznámka',
  },
  {
    entryDateTime: '2024-10-27T15:00:00Z',
    personName: 'Martin Doležal',
    exitDateTime: '2024-10-27T17:30:00Z',
    note: 'Návštěva',
  },
  {
    carPlate: '6XY9876',
    entryDateTime: '2024-10-26T10:20:00Z',
    personName: 'Zuzana Černá',
  },
  {
    personName: 'Ondřej Krejčí',
    entryDateTime: '2024-10-25T12:00:00Z',
    exitDateTime: '2024-10-25T13:15:00Z',
  },
  {
    carPlate: '4LM4321',
    entryDateTime: '2024-10-24T14:50:00Z',
    personName: 'Lucie Holá',
    note: 'Dlouhé čekání',
  },
  {
    entryDateTime: '2024-10-23T11:10:00Z',
    personName: 'Pavel Šimek',
  },
  {
    carPlate: '7QR6543',
    entryDateTime: '2024-10-22T08:35:00Z',
    personName: 'Marek Procházka',
    exitDateTime: '2024-10-22T09:45:00Z',
  },
  {
    carPlate: '2JK8765',
    entryDateTime: '2024-10-21T18:00:00Z',
    personName: 'Daniela Veselá',
    note: 'Pozdní odchod',
  },
  {
    personName: 'Ivana Marešová',
    entryDateTime: '2024-10-20T12:30:00Z',
    exitDateTime: '2024-10-20T13:45:00Z',
  },
  {
    carPlate: '3WX4321',
    entryDateTime: '2024-10-19T07:15:00Z',
    personName: 'Filip Bartoš',
  },
  {
    entryDateTime: '2024-10-18T10:45:00Z',
    personName: 'Adéla Kovářová',
    note: 'Bez záznamu SPZ',
  },
  {
    carPlate: '5OP5432',
    entryDateTime: '2024-10-17T17:25:00Z',
    personName: 'Miloslav Černý',
  },
];
