import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Button } from 'primereact/button';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';

interface ComponentProps {
  onDelete: () => void;
  deleteDialogTitle?: string;
  deleteDialogMessage?: string;
}

const DeleteButton: React.FC<ComponentProps> = ({ onDelete, deleteDialogTitle, deleteDialogMessage }) => {
  const { tr } = useTranslationLgs();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <RingilButton label={tr(`DeleteButton.delete`, `Delete`)} onClick={() => setVisible(true)} />
      <ConfirmDialog
        visible={visible}
        dialogTitle={deleteDialogTitle ? deleteDialogTitle : tr('DeleteButton.confirmDeteleAction', 'Confirm detele action')}
        dialogMessage={deleteDialogMessage ? deleteDialogMessage : tr('DeleteButton.doYouWantToDeleteTheReservation', 'Do you want to delete the selected object?')}
        onConfirm={() => {
          setVisible(false);
          onDelete();
        }}
        onCancel={() => setVisible(false)}
        onAcceptButtonText={tr(`DeleteButtonDialog.confirm`, `Delete`)}
        onCancelButtonText={tr(`DeleteButtonDialog.back`, `Back`)}
      />
    </>
  );
};

export default DeleteButton;
