import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency';
import { DirectAssigmentDataInDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import { isNullOrUndefined } from '@utils/utils';
import { findValidationMessage } from '@utils/validation.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { SelectCarrier } from '@components/ringil3/Input/InputCarriers/SelectCarrier';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox';
import { ColWithGap } from '@components/styles.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  onContinue: () => void;
  customerId: string;
  applicationId: string;
}

export const useCreateAssignCarrierSidebar = () => {
  const [visible, setVisible] = useState(false);

  const AssignCarrierSidebar: React.FC<ComponentProps> = ({ onContinue, customerId, applicationId }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { cuSetDirectAssignment } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [loading, setLoading] = useState(false);

    const schema = yup.object().shape({
      carrierId: yup.string().required(),
      informCarrier: yup.boolean().required(),
      priceAndCurrency: yup.mixed().test('required', tr(`validation.required`, 'Value is required'), (_, context) => {
        const value = context.parent;
        if (isNullOrUndefined(value.price) || isNullOrUndefined(value.currency)) return false;

        if (isNaN(value.price)) return false;

        return true;
      }),
    });

    const form = useForm<DirectAssigmentDataInDTO>(
      schema,
      { carrierId: null, informCarrier: false, currency: null, price: null },
      d => onContinueHandler(d),
      false,
      false,
      () => {},
      'none',
    );

    const onContinueHandler = (data: DirectAssigmentDataInDTO) => {
      setLoading(true);
      cuSetDirectAssignment(
        customerId,
        applicationId,
        data,
        () => {
          setVisible(false);
          setLoading(false);
          onContinue();
        },
        null,
        {
          onValidationFailed: errors => {
            setLoading(false);
            setBeValidationErrors(errors);
          },
        },
      );
    };

    if (!visible) return null;

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          onContinue={() => {
            form.validateAndSend();
          }}
          heading={tr(`AssignCarrierSidebar.heading`, `Assign carrier directly`)}
          loading={loading}
        >
          <ColWithGap>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr(`AssignCarrierSidebar.forExpedition`, `For expedition`)}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />

            <VerticalFieldAndValue
              label={tr('AddMoreCarriers.chooseCarriersAndCarrierGroupsToAdd', 'Choose carriers and/or carrier groups to add')}
              labelMode={'normal'}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, form.names.carrierId, tr)}
            >
              <SelectCarrier
                value={form.values.carrierId}
                onSelected={value => {
                  form.setFieldValue(form.names.carrierId, value);
                }}
                isDisabled={false}
                placeholder={tr(`AssignCarrierSidebar.selectCarrier`, `Select a carrier`)}
              />
            </VerticalFieldAndValue>
            <VerticalFieldAndValue
              label={tr('AddMoreCarriers.price', 'Price')}
              labelMode={'normal'}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, 'priceAndCurrency', tr)}
            >
              <InputPriceWithCurrency
                price={form.values.price}
                currency={form.values.currency}
                changePrice={v => form.setFieldValue(form.names.price, v)}
                changeCurrency={v => form.setFieldValue(form.names.currency, v)}
              />
            </VerticalFieldAndValue>
            <InputCheckbox
              value={form.values.informCarrier}
              onChange={(_, v) => form.setFieldValue(form.names.informCarrier, v)}
              label={'Informovat o přiřazení dopravce'}
              note={'Dopravci pošleme e-mail s podrobnostmi'}
            />
          </ColWithGap>
        </RingilSidebar>
      </>
    );
  };

  return { AssignCarrierSidebar, setVisible };
};
