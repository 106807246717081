import React from 'react';

export const countryTemplate = (option: any) => {
  return (
    <div className="country-item">
      <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" className="flag" />
      <div>{option.name}</div>
    </div>
  );
};

export const selectedItemTemplate = (option: any) => {
  if (option) {
    return (
      <div className="country-item country-item-value">
        <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" className="flag" />
        <div>{option.name}</div>
      </div>
    );
  }
};

export const DescriptionPrice = (price: string, isRed: boolean) => {
  if (status) {
    return <div className="descrip-price text-red">{price}</div>;
  } else {
    return <div className="descrip-price">{price}</div>;
  }
};

// @ts-ignore
export const DescriptionTooltip = (
  // @ts-ignore
  index,
  // @ts-ignore
  name,
  // @ts-ignore
  status,
  // @ts-ignore
  dateTime,
  // @ts-ignore
  email: string,
) => {
  return (
    <div className="descrip-detail" key={index}>
      <div className="descrip-detail__name">
        <strong>{name}</strong>
      </div>
      <div className="descrip-detail__name">
        <strong>{email}</strong>
      </div>
      <div className="p-d-flex p-jc-between">
        <small className="descrip-detail__status">{status}</small>
        <small className="descrip-detail__data">{dateTime}</small>
      </div>
    </div>
  );
};

// @ts-ignore
export const DescriptionValue = (status, dateTime, statusExpired) => {
  if (statusExpired) {
    return (
      <div className="descrip-value text-orange">
        {status && <span className="descrip-status">{status}</span>}
        <span className="descrip-date">{dateTime}</span>
      </div>
    );
  } else {
    return (
      <div className="descrip-value">
        {status && <span className="descrip-status">{status}</span>}
        <span className="descrip-date">{dateTime}</span>
      </div>
    );
  }
};
// @ts-ignore
export const DescriptionTimeslotsSubTooltip = (index, date, time) => {
  return (
    <div className="p-d-flex p-jc-between" key={index}>
      <small className="descrip-detail__status">{date}</small>
      <small className="descrip-detail__data">{time}</small>
    </div>
  );
};
