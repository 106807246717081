import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';

interface ComponentProps {}

const GlobalAdminTemplating: React.FC<ComponentProps> = () => {
  return <AdminLayout title={'Customer admin - dashboard'}></AdminLayout>;
};

export default withAuthenticationRequired(GlobalAdminTemplating);
