import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { Dropdown } from 'primereact/dropdown';
import {
  CompanyModuleEnum,
  CompanyNotificationValidityEnum,
  ListAllCustomerNotification,
  ListAllCustomerNotificationFilter,
  PageResponseListAllCustomerNotification } from
'@api/logsteo-api.v2.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { formatDateInterval, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable.tsx';
import { useCreateCreateCompanyNotificationSidebar } from '@components/features/CompanyNotification/Customer/sidebars/CreateCompanyNotificationSidebar.tsx';
import InputCompanyModule from '@components/framework/input/InputCompanyModule/InputCompanyModule.tsx';
import SvgDelete from '@components/ringil3/icons/buttons/Delete.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import messages from '@components/ringil3/icons/menu/Messages.tsx';
import { useCreateDetailCompanyNotificationSidebar } from '@components/features/CompanyNotification/Customer/sidebars/DetailCompanyNotificationSidebar.tsx';
import { Clickable } from '@components/styles.tsx';

interface ComponentProps {}

const CompanyNotificationList: React.FC<ComponentProps> = () => {
  const { listAllCustomerNotification, deleteCustomerNotification } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [data, setData] = useState<PageResponseListAllCustomerNotification>();

  const { CreateCompanyNotificationSidebar, visible, setVisible } = useCreateCreateCompanyNotificationSidebar();

  const { Table: CustomerNotificationTable, Column: CustomerColumn } = useCreateTable<ListAllCustomerNotification>();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, names } = useFilter<ListAllCustomerNotificationFilter>(
    (page, filter) => {
      loadData(page.pageSize, page.pageNumber, filter);
    },
    null,
    { companyNotificationValidity: CompanyNotificationValidityEnum.ALL }
  );

  const loadData = (pageSize: number, pageNumber: number, filter: ListAllCustomerNotificationFilter) => {
    listAllCustomerNotification(
      {
        pageSize,
        pageNumber
      },
      filter,
      (data) => {
        setData(data);
      }
    );
  };

  const { DetailCompanyNotificationSidebar, setVisibleNotificationId } = useCreateDetailCompanyNotificationSidebar();

  const deleteHandler = (ids: string[]) => {
    deleteCustomerNotification({ notificationIds: ids }, () => {
      loadData(page.pageSize, page.pageNumber, filter);
    });
  };

  if (isNullOrUndefined(data)) return <></>;

  return (
    <>
      <div>
        <div className={'flex flex-col gap-4'}>
          <BreadCrumbComponent
            items={[
            {
              label: tr(`CompanyNotificationList.companyNotification`, `Upozornění`)
            }]
            } />

          <div className={'flex flex-row justify-between mb-2'}>
            <COHeadline title={tr(`CompanyNotificationList.headline`, `Námi rozeslaná upozornění`)} variant={'h1'} />
            <RingilButton label={tr("CompanyNotificationList.nove", "Nové")} mode={'outlined-big'} onClick={() => setVisible(true)} />
          </div>
        </div>
        <div className={'flex flex-col gap-4'}>
          <div className={'flex flex-row gap-4'}>
            <Dropdown
              options={[CompanyNotificationValidityEnum.ALL, CompanyNotificationValidityEnum.ACTIVE]}
              itemTemplate={(v) => <div>{tr(`CompanyNotificationList.visibility${v}`)}</div>}
              valueTemplate={(v, props) => <>{v ? <div>{tr(`CompanyNotificationList.visibility${v}`)}</div> : <span>{props.placeholder}</span>}</>}
              value={filter?.companyNotificationValidity}
              onChange={(e) => {
                applyFilterValue(names.companyNotificationValidity, e.value);
              }}
              placeholder={tr(`CompanyNotificationList.placeholderAll`, `Všechna upozornění`)} />

            <InputCompanyModule
              value={filter?.companyModule}
              onChange={(e) => {
                applyFilterValue(names.companyModule, e);
              }}
              permittedModules={[CompanyModuleEnum.SUPPLIER_NOTIFICATION, CompanyModuleEnum.TIMESLOTS, CompanyModuleEnum.SHIPMENT, CompanyModuleEnum.EXPEDITION]}
              placeholder={tr(`CompanyNotificationList.allEntities`, `Všechny typy`)} />

          </div>

          <CustomerNotificationTable value={data.data}>
            <CustomerColumn
              header={tr('CompanyNotificationList.subject', 'Subject')}
              body={(row) =>
              <Clickable
                onClick={() => {
                  setVisibleNotificationId(row.id);
                }}>
                  {row.messages[0]?.subject}
                </Clickable>
              } />

            <CustomerColumn
              header={tr('CompanyNotificationList.entity', 'Entita')}
              body={(row) => <>{tr(`CompanyNotificationList.entity${row.companyModule}`, row.companyModule)}</>} />

            <CustomerColumn header={tr('CompanyNotificationList.published', 'Zveřejněno')} body={(row) => <>{formatDateInterval(row.since, row.till)}</>} />
            <CustomerColumn
              header={tr('CompanyNotificationList.sendUnread', 'Obesláno / Nepřečteno')}
              body={(row) =>
              <>
                  {row.notifiedCount} / {row.unreadCount}
                </>
              } />

            <CustomerColumn header={tr('CompanyNotificationList.language', 'Jazyk')} body={(row) => <>{row.messages.map((t) => t.language).join(', ')}</>} />
            <CustomerColumn header={''} body={(row) => <RingilButton mode={'rect-small'} leftIcon={<SvgDelete />} onClick={() => deleteHandler([row.id])} />} />
          </CustomerNotificationTable>
        </div>
      </div>
      <CreateCompanyNotificationSidebar onComplete={() => loadData(page.pageSize, page.pageNumber, filter)} />
      <DetailCompanyNotificationSidebar />
    </>);

};

export default CompanyNotificationList;