import React, { useContext, useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { EntityTypeEnum, FindCarrierCarrierOrGroupDTO, LovDTO } from '@api/logsteo-api.v2';
import { carriersTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  onSelectedCarriers: (carriers: FindCarrierCarrierOrGroupDTO[]) => void;
  selectedCarriers: FindCarrierCarrierOrGroupDTO[];
  companyId: string;
  entityId: string;
  entityType: EntityTypeEnum;
}

const SelectRecipientComponent: React.FC<ComponentProps> = ({ onSelectedCarriers, selectedCarriers, companyId, entityType, entityId }) => {
  const { t } = useTranslation('common');
  const { cuLoVAvailableCarriersForMessage } = useContext(ApiContext);

  const [carriers, setCarriers] = useState<LovDTO[]>();

  useEffect(() => {
    cuLoVAvailableCarriersForMessage(companyId, entityId, entityType, data => {
      setCarriers(data.carriers);
    });
  }, []);

  return (
    <>
      <div className="chat-new-message p-d-flex">
        <span className="p-pr-2 p-pt-2 chat-new-message-to">{t(`SelectRecipientComponent.to`, `To`)}: </span>
        {carriers && (
          <MultiSelect
            display="chip"
            optionLabel="name"
            value={selectedCarriers}
            options={carriers}
            onChange={e => onSelectedCarriers(e.value)}
            filter
            dataKey={'id'}
            className="multiselect-chat"
            placeholder={t(`SelectRecipientComponent.noRecipients`, `No recipients`)}
            itemTemplate={carriersTemplate}
          />
        )}
      </div>
    </>
  );
};

export default SelectRecipientComponent;
