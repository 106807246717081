import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { GroupDemandCarrierListPreviewOutDTO, PageResponseGroupDemandCarrierListPreviewOutDTO, StateEnum } from '@api/logsteo-api.v2';
import { Clickable, Note, RingilHeading, RowWithSpaceBetween } from '@components/styles';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { mapFromAPIDateTime, mapFromAPIToDateShort, shortDateFormat } from '@utils/date';
import { InputText } from 'primereact/inputtext';
import { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import styled from 'styled-components';
import { convertToArray, mapFromDateToDayjs } from '@utils/utils';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import useNavigation from '@hooks/useNavigation';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { Link } from 'react-router-dom';

interface ComponentProps {}

interface FilterType {
  applicationId?: string;
  customerName?: string;
  validTill?: string;
  groupDemandState?: string[];
}

const MultiDemands: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { caListGroupDemands } = useContext(ApiContext);
  const [data, setData] = useState<PageResponseGroupDemandCarrierListPreviewOutDTO>(undefined);

  const navigation = useNavigation();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<FilterType>((page, filter) => {
    loadData(page.pageSize, page.pageNumber, filter);
  });

  // @ts-ignore
  const loadData = (pageSize, pageNumber, filter) => {
    caListGroupDemands({ pageSize, pageNumber }, filter, d => {
      setData(d);
    });
  };

  const names = proxiedPropertiesOf<FilterType>();

  const renderButton = (row: GroupDemandCarrierListPreviewOutDTO) => {
    return (
      <>
        {row.state === StateEnum.NEW && row.isValid && (
          <Button
            label={tr(`MultiDemands.makeOffer`, `Make offer`)}
            onClick={e => {
              /*router.push(`/carrier/multi-demands/${row.groupDemandId}`);*/
              navigation.navigate(navigation.urlFunctions.createCarrierMultiDemandDetail(row.groupDemandId));
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <RowWithSpaceBetween>
        <RingilHeading>{tr(`MultiDemands.title`, `Multi demands`)}</RingilHeading>
      </RowWithSpaceBetween>
      <Filter />
      <FilterWrapper>
        <InputText
          value={filter?.applicationId}
          placeholder={tr('MultiDemands.filterByApplicationId', 'Filter by application ID')}
          onChange={e => applyFilterValue(names.applicationId, e.target.value)}
        />
        <InputText
          value={filter?.customerName}
          placeholder={tr(`MultiDemands.filterByCustomerName`, `Filter by customer name`)}
          onChange={e => applyFilterValue(names.customerName, e.target.value)}
        />
        <Calendar
          locale={'cs'}
          readOnlyInput
          showButtonBar={true}
          showIcon
          onClearButtonClick={() => applyFilterValue('arrivalAt', null)}
          value={mapFromAPIDateTime(filter?.validTill)?.toDate()}
          dateFormat={shortDateFormat}
          onChange={e => applyFilterValue(names.validTill, mapFromDateToDayjs(e.value as Date)?.toISOString())}
        />
        <MultiSelect
          options={Object.keys(GroupDemandStateEnum).map(t => {
            return { code: t, name: tr(`GroupDemandStateEnum.${t}`, t) };
          })}
          value={convertToArray(filter?.groupDemandState)}
          optionLabel={'name'}
          optionValue={'code'}
          onChange={e => applyFilterValue(names.groupDemandState, e.value)}
          placeholder={tr('MultiDemands.filterByState', 'Filter by state')}></MultiSelect>
      </FilterWrapper>
      {data && (
        <>
          {/*// @ts-ignore*/}
          <DataTable value={data.data} rows={data.size} paginator={true} lazy={true} totalRecords={data.total} onPage={onPage} first={(data.page - 1) * data.size}>
            <Column
              header={tr(`MultiDemands.id`, `ID`)}
              body={(row: GroupDemandCarrierListPreviewOutDTO) => (
                <div>
                  <Clickable>
                    {/*<Link href={`/carrier/multi-demands/${row.groupDemandId}`}>{row.groupDemandApplicationId}</Link>*/}
                    <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierMultiDemandDetail(row.groupDemandId))}>
                      <div className={'flex flex-col'}>
                        {row.groupDemandApplicationId}
                        <Note>{row.groupDemandName}</Note>
                        <Note>{row.groupDemandId}</Note>
                      </div>
                    </Link>
                  </Clickable>
                </div>
              )}></Column>
            <Column header={tr(`MultiDemands.company`, `Company`)} body={(row: GroupDemandCarrierListPreviewOutDTO) => <div>{row.createdByCompany}</div>}></Column>
            <Column
              header={tr(`MultiDemands.validTill`, `Valid till`)}
              body={(row: GroupDemandCarrierListPreviewOutDTO) => <div>{mapFromAPIToDateShort(row.validTill)}</div>}></Column>
            <Column header={''} body={(row: GroupDemandCarrierListPreviewOutDTO) => renderButton(row)}></Column>
          </DataTable>
        </>
      )}
    </>
  );
};

export enum GroupDemandStateEnum {
  NEW = 'NEW',
  FINISHED = 'FINISHED',
}

const FilterWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
`;

export default MultiDemands;
