import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '../../../../hooks/i18n/useTranslation';
import { ApiContext } from '../../../../api/api';
import styled from 'styled-components';
import { SelectButton } from 'primereact/selectbutton';
import { InputSwitch } from 'primereact/inputswitch';

interface ComponentProps {}

interface ContactEntryProps {
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  deliveringEnabled: boolean;
  onChange: (blocked: boolean) => void;
}

const ContactEntry: React.FC<ContactEntryProps> = ({ contactPhone, contactName, contactEmail, deliveringEnabled, onChange }) => {
  const [visibleContacts, setVisibleContacts] = useState(false);
  const { tr } = useTranslationLgs();

  return (
    <>
      <ContactEntryWrapper>
        <FirstColumn>
          <span>{contactName}</span>
          <>
            <span>{contactPhone}</span>
            <span className={'text-blue'}>
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </span>
          </>
        </FirstColumn>
        <SecondColumn>
          <AlignedRow>
            <InputSwitch checked={deliveringEnabled} onChange={e => onChange(e.value)} />
            <Label>{tr(`ContactEntry.sendDemandsToContact`, `Send demands to contact`)}</Label>
          </AlignedRow>
          {deliveringEnabled && <Note>{tr(`ContactEntry.newDemandsWillBeDelivering`, `New demands will be delivering`)}</Note>}
          {!deliveringEnabled && <Note>{tr(`ContactEntry.newDemandsWillBeNotDelivering`, `New demands will be not delivering`)}</Note>}
        </SecondColumn>
      </ContactEntryWrapper>
    </>
  );
};

const Label = styled.div`
  margin: auto 5px auto 5px;
`;
const AlignedRow = styled.div`
  display: flex;
`;

const Note = styled.div`
  font-size: small;
  color: gray;
  flex-grow: 1;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;
const ContactEntryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export default ContactEntry;
