import { translateQuantityCode } from '@utils/utils';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import { calculateOrderWeight } from '@components/obsolete/helpers.ts';
import React from 'react';
import { Location } from '../types';
import LoadInfoItem from '@components/obsolete/LoadDetails/LoadInfo/LoadInfoItem.tsx';

interface ComponentProps {
  loadingLocations: Location[];
}

const LoadInfo: React.FC<ComponentProps> = ({ loadingLocations }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();

  return (
    <div className="load-info">
      <strong>{t(`LoadInfo.load`, `Load`)}</strong>
      <div className="load-info-wrapper">
        {loadingLocations &&
          loadingLocations?.map((location: Location) => {
            return location.loadingOrders.map((order, index) => (
              <div className="p-grid" key={index}>
                <div className="p-col-12 p-md-3">
                  <LoadInfoItem bookingRef={order.name} className="p-mb-3" />
                </div>
                <div className="p-col-12 p-md-4">
                  {order.items.map((orderItem, orderIndex) => {
                    return (
                      <LoadInfoList
                        key={orderIndex}
                        title={`${orderItem.cargoItemType.name} (${orderItem.quantity} ${translateQuantityCode(orderItem.quantityUnit, tr)})`}
                        description={`${calculateOrderWeight(orderItem)} kg`}
                        stackable={orderItem.stackable}
                        cargoMessage={orderItem.cargoMessage}
                      />
                    );
                  })}
                </div>
              </div>
            ));
          })}
        {/*
        <div className="p-grid">
          <div className="p-col-12 p-md-3">
            <LoadInfoItem bookingRef="1223344" codeRef="Kód pro dopravce: 3R 445 H" />
          </div>
          <div className="p-col-12 p-md-4">
            <LoadInfoList title="Europaleta (8 ks)" description="3 980 kg" />
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default LoadInfo;
