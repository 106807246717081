import React, { useContext, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { PageResponseQuotePreviewOutDTO, PriceTypeEnum, PseudoStateEnum, QuotePreviewOutDTO } from '@api/logsteo-api.v2';
import { DemandListPseudoState } from '@type/enums';
import { dateFormatDateTime, mapFromAPIDateTime, shortDateFormat } from '@utils/date';
import { convertToDate, dumpVars, formatPrice, mapFromDateToDayjs } from '@utils/utils';
import { ApiContext } from '@api/api';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import useNavigation from '@hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DemandStateSpan from '@components/obsolete/StateSpan/DemandStateSpan.tsx';
import ExpeditionListStart from '@components/obsolete/ExpeditionListStart/ExpeditionListStart.tsx';
import ExpeditionLocation from '@components/obsolete/ExpeditionLocation/ExpeditionLocation.tsx';
import NoteIcon from '@components/obsolete/Icons/NoteIcon.tsx';
import ExtraCostIcon from '@components/obsolete/Icons/ExtraCostIcon.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { Tooltip } from 'primereact/tooltip';

const Demands = () => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { caListDemands } = useContext(ApiContext);
  const [pageData, setPageData] = useState<PageResponseQuotePreviewOutDTO>(undefined);

  const navigation = useNavigation();

  // @ts-ignore
  const loadData = (pageSize, pageNumber, filter: FilterType) => {
    caListDemands(
      {
        pseudoState: filter.pseudoState,
        loadingDateFromISO: mapFromDateToDayjs(filter.loadingDate)?.toISOString(),
        loadingDateToISO: mapFromDateToDayjs(filter.loadingDate)?.toISOString(),
        unloadingDateFromISO: mapFromDateToDayjs(filter.unloadingDate)?.toISOString(),
        unloadingDateToISO: mapFromDateToDayjs(filter.unloadingDate)?.toISOString(),
        locationName: filter.locationName,
      },
      { pageSize, pageNumber: pageNumber },
      data => {
        setPageData(data);
      },
    );
  };

  const onPage = (event: any) => {
    applyPaging({ pageNumber: event.page + 1, pageSize: event.rows });
  };

  const items = [
    {
      label: t(`Demands.Action`, `Akce`),
      icon: 'pi pi-refresh',
    },
  ];

  const stageBodyTemplate = (rowData: QuotePreviewOutDTO) => {
    return (
      <>
        <div className="id">
          {/*<Link href={`/carrier/demand/${rowData.companyId}-${rowData.applicationId}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierDemandDetail(rowData.companyId, rowData.applicationId))}>
            <a>
              <small>{rowData.applicationId}</small>
            </a>
          </Link>
        </div>
        {rowData.pseudoState && (
          <div className="stage">
            <DemandStateSpan demandState={rowData.pseudoState} isManual={rowData.isManualExpedition} />
          </div>
        )}
      </>
    );
  };

  const typePriceBodyTemplate = (rowData: QuotePreviewOutDTO) => {
    let typePrice = null;
    switch (rowData.priceType) {
      case PriceTypeEnum.AGREED:
        typePrice = <strong className="text-green">{t(`Demands.priceType.${rowData.priceType}`, rowData.priceType)}</strong>;
        break;

      case PriceTypeEnum.REJECTED:
        typePrice = <strong className="text-pink">{t(`Demands.priceType.${rowData.priceType}`, rowData.priceType)}</strong>;
        break;

      default:
        typePrice = <strong>{t(`Demands.priceType.${rowData.priceType}`, rowData.priceType)}</strong>;
    }

    return (
      <>
        {rowData.price ? (
          <div className="price">
            <span>{formatPrice(rowData.price, rowData.currency)}</span>
          </div>
        ) : (
          <span>—</span>
        )}

        {rowData.priceType && (
          <div className="type-price">
            <small>{typePrice}</small>
          </div>
        )}

        <div>
          {!rowData.isExpiredDistribution ? (
            <div className="distribution-wrapper">
              <small className="value">
                {t(`Demands.validUntil`, `ValidUntil`, {
                  date: dateFormatDateTime(mapFromAPIDateTime(rowData.distributionValidTill)),
                })}
              </small>
              ,<small className="place">{rowData.customerCompanyName}</small>
            </div>
          ) : (
            <div className="distribution-wrapper">
              <small className="value text-orange">
                {t(`Demands.validUntil`, `ValidUntil`, {
                  date: dateFormatDateTime(mapFromAPIDateTime(rowData.distributionValidTill)),
                })}
              </small>
              <small className="place">{rowData.customerCompanyName}</small>
            </div>
          )}
        </div>
      </>
    );
  };

  const expeditionStartBodyTemplate = (rowData: QuotePreviewOutDTO) => {
    return <ExpeditionListStart label={t(`Demands.expedition_start`, `Očekávaný začátek:`)} expeditionStart={rowData.expeditionStart} />;
  };

  const routeBodyTemplate = (rowData: QuotePreviewOutDTO) => {
    return (
      <div className="locations">
        <ExpeditionLocation
          locationPoint={t(`Demands.from`, `From`)}
          locationAddress={`${rowData.locations[0].locationName}, ${rowData.locations[0].zipCode}, ${rowData.locations[0].city}, (${rowData.locations[0].country})`}
          wrapper="LARGE"
        />
        <ExpeditionLocation
          locationPoint={t(`Demands.to`, `To`)}
          locationAddress={`${rowData.locations[rowData.locations.length - 1].locationName}, ${rowData.locations[rowData.locations.length - 1].zipCode}, ${
            rowData.locations[rowData.locations.length - 1].city
          }, (${rowData.locations[rowData.locations.length - 1].country})`}
          wrapper="LARGE"
        />
        <div className="p-d-flex p-flex-nowrap">
          <div className="p-mr-1 location-point"></div>
          <div className="p-d-flex p-flex-wrap">{`${rowData.distance} km`}</div>
        </div>
      </div>
    );
  };

  const typeShipmentsBodyTemplate = (rowData: QuotePreviewOutDTO) => {
    return (
      <>
        <div>
          <div className="flex flex-row gap-0.5 items-center">
            <span>{t(`Demands.${rowData.expeditionType}`, rowData.expeditionType)}</span>
            <NoteIcon applicationId={rowData.applicationId} note={rowData.note} />
            <ExtraCostIcon extraCosts={rowData.extraCosts} applicationId={rowData.applicationId} />
            <span id={`route_${rowData.applicationId}`} className={'pi pi-map'}></span>
            {/*// @ts-ignore*/}
            <Tooltip target={`#route_${rowData.applicationId}`} style={{ maxWidth: '530px' }}>
              {rowData.locations.map((location, locationIndex) => {
                return (
                  <div key={locationIndex}>
                    {tr(`AllExpedition.locationInfo`, `Location {{order}}: {{locationName}}`, {
                      order: locationIndex + 1,
                      locationName: location.locationName,
                    })}
                  </div>
                );
              })}
            </Tooltip>
          </div>
          {rowData.truckType && (
            <div className="p-pb-1 type-shipments">
              <small>{rowData.truckType}</small>
            </div>
          )}

          <div className="p-pb-1 type-shipments">
            <small>{rowData.cargoType}</small>
          </div>
        </div>
      </>
    );
  };

  const navBodyTemplate = (rowData: QuotePreviewOutDTO) => {
    return (
      <div>
        <ButtonLayout>
          {/*<Link href={`/carrier/demand/${rowData.companyId}-${rowData.applicationId}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCarrierDemandDetail(rowData.companyId, rowData.applicationId))}>
            <a className="btn-detail p-button p-button-rounded p-button-outlined secondary-btn">{t('Demands.detail', 'Detail')}</a>
          </Link>
        </ButtonLayout>
      </div>
    );
  };

  interface FilterType {
    pseudoState?: PseudoStateEnum;
    loadingDate?: Date;
    unloadingDate?: Date;
    locationName?: string;
  }

  const { Filter, applyFilterValue, filter, applyPaging, page } = useFilter<FilterType>((page, filter) => {
    loadData(page.pageSize, page.pageNumber, filter);
  });

  return (
    <>
      <NavTitle wrapper="PADDING-0">
        <h2 className="h2-title p-m-0">{t('Demands.demands', 'Demands')}</h2>
      </NavTitle>

      {/*      <div className="p-mt-3 p-mb-2">
        <Dropdown
          value={expeditionStageValue}
          options={Object.keys(DemandListPseudoState).map((state) => {
            return { code: state, name: t(`Demands.expeditionStage${state}`, state) };
          })}
          onChange={(e) => {
            setExpeditionStageValue(e.value);
          }}
          optionLabel={'name'}
          optionValue={'code'}
          placeholder={t(`Demands.expeditionStage`, `Expedition stage`)}
          className="filter-table"
          showClear={true}
        />
      </div>*/}

      <Filter />
      <Grid>
        <div className={'field'}>
          <Dropdown
            value={filter?.pseudoState}
            options={Object.keys(DemandListPseudoState).map(state => {
              return {
                code: state,
                name: t(`Demands.expeditionStage${state}`, state),
              };
            })}
            onChange={e => {
              applyFilterValue('pseudoState', e.value);
            }}
            placeholder={t(`Demands.expeditionStage`, `Expedition stage`)}
            className="filter-table"
            showClear={true}
            optionLabel={'name'}
            optionValue={'code'}
          />
        </div>

        <div className={'field'}>
          <Calendar
            value={convertToDate(filter?.loadingDate)}
            onChange={e => applyFilterValue('loadingDate', e.value)}
            dateFormat={shortDateFormat}
            placeholder={tr(`Demands.loading`, `Loading`)}
            showIcon={true}
            locale={'cs'}
            showButtonBar={true}
          />{' '}
        </div>
        <div className={'field'}>
          <Calendar
            value={convertToDate(filter?.unloadingDate)}
            onChange={e => applyFilterValue('unloadingDate', e.value)}
            dateFormat={shortDateFormat}
            placeholder={tr(`Demands.unloading`, `Unloading`)}
            showIcon={true}
            locale={'cs'}
            showButtonBar={true}
          />{' '}
        </div>
        <div className={'field'}>
          <InputText
            value={filter?.locationName}
            onChange={e => applyFilterValue('locationName', e.target.value)}
            placeholder={tr(`Demands.locationName`, `Location name`)}
          />{' '}
        </div>
      </Grid>

      {pageData && (
        <>
          {/*// @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            className="default-exp-table demands-table"
            value={pageData.data}
            rows={pageData.size}
            paginator={true}
            lazy={true}
            totalRecords={pageData.total}
            onPage={onPage}
            first={(pageData.page - 1) * pageData.size}>
            <Column className="demands-table__id text-blue" field="id" body={stageBodyTemplate}></Column>
            <Column className="demands-table__price" body={typePriceBodyTemplate}></Column>
            <Column className="demands-table__shipments" body={typeShipmentsBodyTemplate}></Column>
            <Column className="demands-table__route" body={routeBodyTemplate}></Column>
            <Column className="demands-table__expedition_start" body={expeditionStartBodyTemplate}></Column>
            <Column className="demands-table__nav" body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}
    </>
  );
};

const Grid = styled.div`
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export default Demands;
