import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SvgHeadingSwapIcon from '@app/icons/heading/HeadingSwapIcon.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import { mapFromAPIToDateLong } from '@utils/date.tsx';
import { ApiContext } from '@api/api.tsx';
import { ArrivalsDeparturesLogResponse, ListArrivalsDeparturesLogFilter, PageResponseArrivalsDeparturesLogResponse } from '@api/logsteo-api.v2.tsx';
import CamsTabs from '@app/pages/cams/tabs/CamsTabs.tsx';
import COText from '@app/pages/COText/COText.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { exportExcel } from '@utils/exports.ts';

interface ComponentProps {}

type IsAllowed = 'ALL' | 'ALLOWED' | 'DENIED';

const CamsDetail: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { listArrivalsDeparturesLog } = useContext(ApiContext);
  const [data, setData] = useState<PageResponseArrivalsDeparturesLogResponse>();
  const [loading, setLoading] = useState(false);

  const loadData = (pageSize: number, pageNumber: number, filter: ListArrivalsDeparturesLogFilter) => {
    setLoading(true);
    listArrivalsDeparturesLog(filter, { pageSize, pageNumber }, (d) => {
      setData(d);
      setLoading(false);
    });
  };
  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, names } = useFilter<ListArrivalsDeparturesLogFilter>(
    (page, filter) => {
      loadData(page.pageSize, page.pageNumber, filter);
    },
    null,
    {}
  );

  const { Table, Column } = useCreateTable<ArrivalsDeparturesLogResponse>();

  const resolveCode = (isAllowed: boolean | undefined): IsAllowed => {
    if (isAllowed === true) {
      return 'ALLOWED';
    } else if (isAllowed === false) {
      return 'DENIED';
    } else {
      return 'ALL';
    }
  };

  const reload = () => {
    loadData(page.pageSize, page.pageNumber, filter);
  };

  const mapToExport = (d: ArrivalsDeparturesLogResponse) => {
    return d;
  };

  const exportExcelHandler = () => {
    listArrivalsDeparturesLog(filter, { pageSize: 9999999, pageNumber: 1 }, (d) => {
      exportExcel(d.data.map(mapToExport), 'cameras-log');
    });
  };

  return (
    <div className={'flex flex-col gap-2'}>
      <BreadCrumbComponent items={[{ label: 'Rezervace', url: nav.createNavigationLink(nav.urlFunctions.createReservationList()) }]} />
      <COHeadline variant={'h2'} title={tr("CamsDetail.kamery", "Kamery")} />
      <CamsTabs selectedPanel={'log'} />

      <div className={'my-3'}>
        <COHeadline variant={'h2'} title={tr("CamsDetail.pohybyVozidelVArealu", "Pohyby vozidel v areálu")} icon={<SvgHeadingSwapIcon />} loading={loading}></COHeadline>
      </div>
      <div className={'flex flex-row gap-4 items-center'}>
        <RingilInputText placeholder={tr("CamsDetail.spz", "SPZ")} value={filter?.carPlate} onChange={(v) => applyFilterValue(names.carPlate, v)} />
        <RingilInputText placeholder={tr("CamsDetail.kamera", "Kamera")} value={filter?.cameraName} onChange={(v) => applyFilterValue(names.cameraName, v)} />
        <RingilInputText placeholder={tr("CamsDetail.osoba", "Osoba")} value={filter?.personName} onChange={(v) => applyFilterValue(names.personName, v)} />
        <InputSelect
          selectedItemCode={resolveCode(filter?.isAllowed)}
          model={[
          { code: 'ALLOWED', label: 'Povoleno' },
          { code: 'DENIED', label: 'Zamítnuto' },
          { code: 'ALL', label: 'Vše' }]
          }
          onSelect={(v) => applyFilterValue(names.isAllowed, v === 'ALLOWED' ? true : v === 'DENIED' ? false : undefined)} />

        <RingilButton onClick={() => reload()} label={tr("CamsDetail.nacist", "Načíst")}></RingilButton>
        <RingilButton onClick={() => exportExcelHandler()} label={tr("CamsDetail.export", "Export")}></RingilButton>
      </div>

      <Table value={data?.data} onPage={onPage} selectedPage={page.pageNumber} rows={data?.size} totalRecords={data?.total} paginator={true}>
        <Column
          header={tr("CamsDetail.spz", "SPZ")}
          body={(row) =>
          <div className={'flex flex-col gap-2'}>
              <div>{row.carPlate}</div>
              <div>{row.personName}</div>
            </div>
          } />

        <Column
          header={tr("CamsDetail.prijezd", "Příjezd")}
          body={(row) =>
          <div className={'flex flex-col gap-2'}>
              {row?.isArrival == true &&
            <>
                  <div>{mapFromAPIToDateLong(row.createdAt)}</div>
                  <div>{row.cameraName}</div>
                </>
            }
            </div>
          } />

        <Column
          header={tr("CamsDetail.odjezd", "Odjezd")}
          body={(row) =>
          <div className={'flex flex-col gap-2'}>
              {row?.isArrival == false &&
            <>
                  <div>{mapFromAPIToDateLong(row.createdAt)}</div>
                  <div>{row.cameraName}</div>
                </>
            }
            </div>
          } />

        <Column
          header={tr("CamsDetail.status", "Status")}
          body={(row) =>
          <div className={'flex flex-col gap-2'}>
              {row?.isAllowed === true ?
            <>
                  <COText label={tr("CamsDetail.povoleno", "Povoleno")} />
                </> :

            <>
                  <COText label={tr("CamsDetail.zamitnuto", "Zamítnuto")} />
                </>
            }
            </div>
          } />

      </Table>
    </div>);

};

export default CamsDetail;