import React, { useContext } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CompanyModuleEnum, TransportationTypeEnum } from '@api/logsteo-api.v2';
import { Form } from '../../../../hooks/useForm/useForm';
import { RingilH3 } from '@components/styles';
import { isNullOrUndefined } from '@utils/utils';
import { findValidationMessage } from '@utils/validation';
import { Calendar } from 'primereact/calendar';
import { shortDateFormat } from '@utils/date';
import InputAddress, { InputAddressData } from '@components/obsolete/Form/InputAddress/InputAddress.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import InputTransportationType from '@components/obsolete/Form/InputTransportationType/InputTransportationType.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputInheriterCompany from '@components/obsolete/Form/InputEntityOwner/InputInheriterCompany.tsx';
import InputEntityOwner from '@components/obsolete/Form/InputEntityOwner/InputEntityOwner.tsx';

export interface ShipmentPartiesData {
  transportationTypeCode: TransportationTypeEnum;
  companyId: string;
  supplierId: string;
  departureAt: Date;
  arrivalAt: Date;
  loadingAddress: InputAddressData;
  unloadingAddress: InputAddressData;
}

interface ComponentProps {
  form: Form<ShipmentPartiesData>;
  inSidebar: boolean;
  showParties: boolean;
  showLocations: boolean;
  showDates: boolean;
  showTransportationType: boolean;
}

export const supplierShipmentPartiesValidation = yup.object().shape({});

export const ShipmentPartiesForm: React.FC<ComponentProps> = ({ form, inSidebar, showParties, showDates, showLocations, showTransportationType }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      {/*<RingilHeading>{tr(`CreateShipment.newShipment`, `New shipment`)}</RingilHeading>*/}
      {showTransportationType && (
        <>
          <RingilH3>{tr(`CreateShipment.transportationType`, `Transportation type`)}</RingilH3>
          <InputTransportationType
            code={form.values.transportationTypeCode}
            onChange={code => form.setFieldValue('transportationTypeCode', code)}
            disabled={false}
            singleOption={false}
          />
        </>
      )}

      {showParties && (
        <RR>
          <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              label={tr(`CreateShipment.supplier`, `Supplier`)}
              required={true}
              validationError={findValidationMessage(form.validationErrors, 'supplierId', tr)}
              value={
                <InputInheriterCompany
                  companyId={form.values.companyId}
                  inheriterId={form.values.supplierId}
                  onChange={v => form.setFieldValue('supplierId', v)}
                  module={CompanyModuleEnum.SHIPMENT}
                  style={{ width: '100%' }}
                />
              }
            />
          </RC>
          <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              validationError={findValidationMessage(form.validationErrors, 'companyId', tr)}
              required={true}
              label={tr(`CreateShipment.customer`, `Customer`)}
              value={
                <InputEntityOwner
                  companyId={form.values.companyId}
                  onChange={c => form.setFieldValue('companyId', c)}
                  moduleType={CompanyModuleEnum.SHIPMENT}
                  style={{ width: '100%' }}
                  // TODO RGL-563
                  onChangeCompanyCode={c => form.setFieldValue('companyCode', c)}
                />
              }
            />
          </RC>
        </RR>
      )}

      {showLocations && (
        <>
          <RingilH3>{tr(`CreateShipment.transportationInfo`, `Transportation info`)}</RingilH3>
          <RR>
            <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
              <HorizontalFieldValue
                label={tr(`CreateShipment.locatityFrom`, `Locatity from`)}
                required={true}
                validationError={findValidationMessage(form.validationErrors, 'loadingAddress.city', tr)}
                value={<InputAddress address={form.values.loadingAddress} onChange={a => form.setFieldValue('loadingAddress', a)}></InputAddress>}
              />
            </RC>
            <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
              <HorizontalFieldValue
                label={tr(`CreateShipment.localityTo`, `Locatity to`)}
                required={true}
                validationError={findValidationMessage(form.validationErrors, 'unloadingAddress.city', tr)}
                value={<InputAddress address={form.values.unloadingAddress} onChange={a => form.setFieldValue('unloadingAddress', a)}></InputAddress>}
              />
            </RC>
          </RR>
        </>
      )}

      {showDates && (
        <RR>
          <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              label={tr(`CreateShipment.departure`, `Departure`)}
              required={true}
              validationError={findValidationMessage(form.validationErrors, 'departureAt', tr)}
              value={
                <Calendar
                  locale={'cs'}
                  className="p-mb-1"
                  showIcon
                  dateFormat={shortDateFormat}
                  value={form.values.departureAt}
                  onChange={e => form.setFieldValue('departureAt', e.value)}
                  placeholder={tr('CreateShipment.notSpecified', 'Not specified')}
                  style={{ width: '100%' }}
                />
              }
            />
          </RC>
          <RC large={inSidebar ? 12 : 6} medium={inSidebar ? 12 : 6} small={12}>
            <HorizontalFieldValue
              label={tr(`CreateShipment.arrival`, `Arrival`)}
              required={true}
              validationError={findValidationMessage(form.validationErrors, 'arrivalAt', tr)}
              value={
                <Calendar
                  locale={'cs'}
                  className="p-mb-1"
                  showIcon
                  dateFormat={shortDateFormat}
                  placeholder={tr('CreateShipment.notSpecified', 'Not specified')}
                  value={form.values.arrivalAt}
                  onChange={e => form.setFieldValue('arrivalAt', e.value)}
                  style={{ width: '100%' }}
                />
              }
            />
          </RC>
        </RR>
      )}
    </>
  );
};
