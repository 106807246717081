import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { GetWebAppAlertResponse } from '@api/logsteo-api.v2';

interface ComponentProps {}

interface ApplicationInfo {
  commit_sha: string;
  commit_message: string;
}

export const useTopInformation = () => {
  const [versionInfo, setVersionInfo] = useState<ApplicationInfo>(undefined);
  const TopInformation: React.FC<ComponentProps> = () => {
    const [newVersion, setNewVersion] = useState<ApplicationInfo>();
    const { tr } = useTranslationLgs();
    const { getWebAppAlert } = useContext(ApiContext);
    const [alerts, setAlerts] = useState<GetWebAppAlertResponse[]>();

    const setAndCheck = (data: ApplicationInfo) => {
      if (isNullOrUndefined(versionInfo)) {
        setVersionInfo(data);
      } else {
        if (data.commit_sha !== versionInfo.commit_sha) {
          setNewVersion(data);
        }
      }
    };

    const fetchAndCheck = (controller: AbortController) => {
      fetch('/version.json', { signal: controller.signal })
        .then(response => response.json())
        .then(data => setAndCheck(data))
        .catch(e => console.log(e));

      getWebAppAlert(d => {
        if (!controller.signal.aborted) setAlerts(d);
      });
    };

    useEffect(() => {
      const controller = new AbortController();
      const refreshInterval = window.location.origin.indexOf('localhost') != -1 ? null : window.location.origin.indexOf('app.ringil.com') != -1 ? 60000 : 5000;
      if (refreshInterval == null)
        return () => {
          controller.abort();
        };

      const interval = setInterval(() => fetchAndCheck(controller), refreshInterval);

      return () => {
        controller.abort();
        if (interval) clearInterval(interval);
      };
    }, [versionInfo]);

    return (
      <>
        {isNotNullOrUndefined(newVersion) && (
          <div className="absolute flex bg-complementary-warning w-full justify-between  p-3">
            <div className="top-information__text">
              {tr(`TopInformation.versionDetected`, `Version detected...`)} - {newVersion?.commit_message}
            </div>
            <Button
              label={tr('TopInformation.reload', 'Reload')}
              onClick={e => {
                location.reload();
              }}
            />
          </div>
        )}
        {isNotNullOrUndefined(alerts) && alerts.length > 0 && (
          <div className="top-information-wrapper">
            {alerts.map(t => {
              return <div className="top-information__text">{t.text}</div>;
            })}
          </div>
        )}
      </>
    );
  };
  return { TopInformation, versionInfo };
};
