import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useContext, useEffect } from 'react';
import { AddCarrierUserInDTO } from '@api/logsteo-api.v2.tsx';
import { useImmer } from 'use-immer';

import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import { ApiContext } from '@api/api';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: () => void;
  carrierId: string;
}

export const AddCarrierUserSidebar: React.FC<ComponentProps> = ({ visible, onHide, onComplete, carrierId }) => {
  const { t } = useTranslation('common');
  const { cuCreateCarrierUserByCustomer } = useContext(ApiContext);
  const [state, setState] = useImmer<AddCarrierUserInDTO>({
    firstname: '',
    surname: '',
    email: '',
    phone: '',
  });
  const { setBeValidationErrors, BackendValidationComponent } = useBEValidation();

  useEffect(() => {
    if (visible) {
      clear();

      setState(draft => {
        return {
          firstname: '',
          surname: '',
          email: '',
          phone: '',
        };
      });
    }
  }, [visible]);

  const save = () => {
    cuCreateCarrierUserByCustomer(
      carrierId,
      state,
      () => {
        onComplete();
      },
      null,
      {
        onValidationFailed: e => {
          setBeValidationErrors(e);
        },
      },
    );
  };

  const validationScheme = yup.object().shape({
    firstname: yup.string().defined().required(),
    email: yup.string().email().defined().required(),
    phone: yup.string().defined().required(),
    surname: yup.string().defined().required(),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(validationScheme, () => {
    save();
  });

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} onHide={onHide} position="right" baseZIndex={1000000} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        <h3 className="p-mb-4">{t('AddCarrierUserSidebar.createUser', 'Vytvořit uživatele')}</h3>

        <BackendValidationComponent />

        <div className="p-field p-fluid">
          <Label title={`${t('AddCarrierUserSidebar.firstName', 'Křestní jméno')}*`} />
          <InputText
            type="text"
            value={state.firstname}
            name="firstname"
            onChange={e => {
              const { name, value } = e.target as HTMLInputElement;
              setState(draft => {
                draft.firstname = value;
              });
            }}
          />
          <ValidationDiv errors={validationErrors} path={'firstname'} />
        </div>
        <div className="p-field p-fluid">
          <Label title={`${t('AddCarrierUserSidebar.surName', 'Příjmení')}*`} />
          <InputText
            type="text"
            value={state.surname}
            name="surName"
            onChange={e => {
              const { name, value } = e.target as HTMLInputElement;
              setState(draft => {
                draft.surname = value;
              });
            }}
          />
          <ValidationDiv errors={validationErrors} path={'surname'} />
        </div>
        <div className="p-field p-fluid">
          <Label title={`${t('AddCarrierUserSidebar.email', 'Email')}*`} />
          <InputText
            type="text"
            value={state.email}
            name="email"
            onChange={e => {
              const { name, value } = e.target as HTMLInputElement;
              setState(draft => {
                draft.email = value;
              });
            }}
          />
          <ValidationDiv errors={validationErrors} path={'email'} />
        </div>
        <div className="p-field p-fluid">
          <Label title={`${t('AddCarrierUserSidebar.phone', 'Telefon')}*`} />
          <InputText
            type="text"
            value={state.phone}
            name="phoneNumber"
            onChange={e => {
              const { name, value } = e.target as HTMLInputElement;
              setState(draft => {
                draft.phone = value;
              });
            }}
          />
          <ValidationDiv errors={validationErrors} path={'phone'} />
        </div>
        <ButtonLayout>
          <Button label={t(`AddCarrierUserSidebar.save`, `Uložit`)} className="p-mr-2" onClick={() => validateAndSend(state)} />
          <Button label={t(`AddCarrierUserSidebar.cancel`, `Zrušit`)} className="p-button-text" onClick={onHide} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};
