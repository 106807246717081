import clsx from 'clsx';

interface ComponentProps {
  icon?: string;
  name?: string;
  text?: string;
  time?: any;
  unreadMessage?: boolean;
  onClick?: any;
}

const ChatListItemMessage: React.FC<ComponentProps> = ({ icon, name, text, time, unreadMessage, onClick }) => {
  const className = clsx({
    'chat-list-item-message unread': unreadMessage === true,
    'chat-list-item-message': unreadMessage === false,
  });

  return (
    <div className={className} onClick={onClick}>
      <div className="icon">
        <strong>{icon}</strong>
      </div>
      <div className="description">
        <div className="name">{name}</div>
        <div className="text">{text}</div>
      </div>
      <div className="time-status">
        <div className="time">{time}</div>
        {unreadMessage && <div className="status"></div>}
      </div>
    </div>
  );
};

export default ChatListItemMessage;
