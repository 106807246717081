import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import { SelectButton } from 'primereact/selectbutton';
import { DisplayLocationRenderMode } from '@components/obsolete/DisplayLocation/DisplayLocation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { RingilHeading, RowWithSpaceBetween } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {}

interface InputHowToRenderLocationProps {}

const InputHowToRenderLocation: React.FC<InputHowToRenderLocationProps> = () => {
  const { getCompanySettings, saveCompanySettings } = useContext(ApiContext);
  const [value, setValue] = useState();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    getCompanySettings({ identifier: 'LOCATION_DISPLAY_PATTERN' }, d => {
      setValue(d.payload);
    });
  };

  // @ts-ignore
  const onChange = value => {
    saveCompanySettings({ identifier: 'LOCATION_DISPLAY_PATTERN', payload: value, inheritToPartners: true }, () => {
      load();
    });
  };

  const options = [
    { name: 'Zip, City, Country', code: DisplayLocationRenderMode.ZIP_CITY_COUNTRY },
    {
      name: 'Location name, Street, City, Zip, Country',
      code: DisplayLocationRenderMode.NAME_STREET_CITY_ZIP_COUNTRY,
    },
  ];
  return (
    <>
      <SelectButton options={options} optionValue={'code'} optionLabel={'name'} value={value} onChange={e => onChange(e.value)} />
    </>
  );
};

export const CompanySettings: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  return (
    <>
      {/*// @ts-ignore*/}
      <Card>
        <RowWithSpaceBetween>
          <RingilHeading>{tr('CompanySettings.companySettings', 'Company settings')}</RingilHeading>
        </RowWithSpaceBetween>
        {loggedUser && (
          <>
            {loggedUser.securityRoles.find(t => t === 'ROLE_CUSTOMER_ADMIN') && (
              <HorizontalFieldValue label={tr('CompanySettings.carrierAddressFormat', 'Carrier address format')} value={<InputHowToRenderLocation />} />
            )}
          </>
        )}
      </Card>
    </>
  );
};
