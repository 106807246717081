import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
  note?: string;
  applicationId: string;
}

const NoteIcon: React.FC<ComponentProps> = ({ note, applicationId }) => {
  return (
    <>
      {note && (
        <Icon>
          <span id={`note_${applicationId}`} className={'pi pi-align-justify'} />
          {/*
        // @ts-ignore*/}
          <Tooltip target={`#note_${applicationId}`} style={{ maxWidth: '530px' }}>
            <div>
              <div>{note}</div>
            </div>
          </Tooltip>
        </Icon>
      )}
    </>
  );
};

const Icon = styled.span`
  padding: 3px;
`;

export default NoteIcon;
