import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { isNullOrUndefined } from '@utils/utils';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { useParams } from 'react-router-dom';
import Layout from '@app/layout/Layout.tsx';
import CustomerMultiDemandDetail from '@components/obsolete/multi-demand/CustomerMultiDemandDetail.tsx';

interface ComponentProps {}

const MultiDemandDetailPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { multiDemandGroupId } = useParams();

  if (isNullOrUndefined(multiDemandGroupId)) return <></>;

  return (
    <>
      <Layout title={tr(`MultiDemandDetail.multiDemandDetail`, `Multi Demand detail`)}>
        <CustomerMultiDemandDetail multiDemandGroupId={multiDemandGroupId} />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(MultiDemandDetailPage);
