import React from 'react';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  mode: 'round' | 'button';
}

export const RecycleButton: React.FC<ComponentProps> = ({ onClick, mode, disabled = false }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {mode == 'button' && <Button onClick={e => onClick(e)} disabled={disabled} icon={'pi pi-calendar-plus'} label={tr(`RecycleButton.opakovat`, `Opakovat`)}></Button>}

      {mode == 'round' && (
        <Button
          onClick={e => onClick(e)}
          disabled={disabled}
          className={'p-button p-component p-button-rounded p-button-outlined  p-mr-3 c'}
          icon={'pi pi-calendar-plus'}
        ></Button>
      )}
    </>
  );
};
