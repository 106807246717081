import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { translateQuantityCode } from '@utils/utils';

interface ComponentProps {
  name: string;
  amount: number;
  unit: string;
  weight: number;
  length: number;
  height: number;
  width: number;
  cargoMessage?: string;
  stackable: boolean;
}

const OrderInfoComponent: React.FC<ComponentProps> = ({ unit, name, amount, stackable, length, height, weight, width, cargoMessage }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Grid>
        <ColWithIcon>
          <Icon src={'/images/box.svg'} style={{ width: '20px', height: '18px' }}></Icon>
        </ColWithIcon>
        <Description>
          <Heading>
            {name} ({amount} {translateQuantityCode(unit, tr)})
          </Heading>
          {weight ? (
            <Note>
              {tr(`OrderInfoComponent.dimensions`, `{{weight}} kg, dimensions: {{length}} x {{height}} x {{width}} (l x h x w)`, { weight, height, width, length })}
            </Note>
          ) : (
            <Note>{tr(`OrderInfoComponent.dimensionsWithoutWeight`, `dimensions: {{length}} x {{height}} x {{width}} (l x h x w)`, { height, width, length })}</Note>
          )}

          {stackable && (
            <Note>
              {tr(`OrderInfoComponent.stackable`, `Stackable: {{yesNo}}`, {
                yesNo: stackable ? tr(`global.yes`, `Yes`) : tr(`global.no`, `No`),
              })}
            </Note>
          )}

          {cargoMessage && (
            <Note>
              {tr(`OrderInfoComponent.note`, `Note: {{cargoMessage}}`, {
                cargoMessage,
              })}
            </Note>
          )}
        </Description>
      </Grid>
    </>
  );
};

const Heading = styled.div`
  font-size: 1rem;
  font-weight: normal;
  line-height: 21px;
  color: var(--ringil-primary-color);
`;

const Note = styled.div`
  display: flex;
  color: var(--ringil-note-color);
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
`;
const ColWithIcon = styled.div`
  display: flex;
`;
const Icon = styled.img``;
const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export default OrderInfoComponent;
