import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FindResolvedEmailFilter, PageResponseResolvedEmailEntity, ResolvedEmailEntity } from '@api/logsteo-api.v2.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { mapFromAPIToDateLong } from '@utils/date.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import SvgLoader from '@app/icons/loader/Loader.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { dumpVars, isNotNullOrUndefined, parseNumberOrUndefined } from '@utils/utils.tsx';
import SvgRepeatIcon from '@app/icons/admin/icon/RepeatIcon.tsx';
import { FilterSort, useFilter } from '@hooks/useFilter/Filter.tsx';
import Pagination from '@components/framework/paging/Pagination.tsx';
import SvgIconFile from '@components/framework/icons/IconFile.tsx';
import RingilDialog from '@components/framework/dialog/RingilDialog.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';
import { InputText } from 'primereact/inputtext';

interface ComponentProps {}

export interface TurnData {
  turnOff: boolean;
  sleep: string;
}

const FindResolvedEmails: React.FC<ComponentProps> = () => {
  const { findResolvedEmails, getInfoTurnOffResolvedEmails, turnOffResolvedEmails } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [data, setData] = useState<PageResponseResolvedEmailEntity>();
  const [runningApi, setRunningApi] = useState(false);
  const [visualiseEmailHtml, setVisualiseEmailHtml] = useState(null);
  const [turnOffData, setTurnOffData] = useState<TurnData>({ turnOff: false, sleep: '0' });

  const { Table, Column } = useCreateTable<ResolvedEmailEntity>();

  const abortControllerRef = useRef<AbortController | null>(null);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort } = useFilter<FindResolvedEmailFilter>(
    (page, filter) => {
      console.log({
        Filter,
        applyFilterValue,
        filter,
        applyPaging,
        page,
        onPage,
      });
      load(page.pageSize, page.pageNumber, filter, sort);
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  useEffect(() => {
    const ac = new AbortController();
    abortControllerRef.current = ac;

    getInfoTurnOffResolvedEmails(d => {
      setTurnOffData({
        turnOff: d.turnOff,
        sleep: d.sleep.toString(),
      });
    });

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const load = (pageSize: number, pageNumber: number, filter: FindResolvedEmailFilter, sort: FilterSort) => {
    setRunningApi(true);
    findResolvedEmails({ pageSize, pageNumber }, filter, d => {
      setRunningApi(false);
      if (abortControllerRef.current.signal.aborted) return;
      setData(d);
    });
  };

  return (
    <AdminLayout title={'Resolved Emails Monitoring'}>
      <BreadCrumbComponent items={[{ label: 'Resolved Emails', url: null }]} />
      <div className={'flex flex-row gap-8 my-4'}>
        <div className={'flex flex-row gap-4 items-center'}>
          <InputYesNoPlain value={turnOffData?.turnOff} onChange={v => setTurnOffData({ ...turnOffData, turnOff: v })} />
          Sending is disabled
        </div>
        <div>
          <InputText value={turnOffData?.sleep} onChange={e => setTurnOffData({ ...turnOffData, sleep: e.target.value })} />
        </div>
        <RingilButton
          label={'Save'}
          onClick={() =>
            turnOffResolvedEmails(
              {
                sleep: parseNumberOrUndefined(turnOffData.sleep),
                turnOff: turnOffData.turnOff,
              },
              d => {
                getInfoTurnOffResolvedEmails(data => {
                  setTurnOffData({
                    turnOff: data.turnOff,
                    sleep: data.sleep.toString(),
                  });
                });
              },
            )
          }
        />
      </div>
      <div className={'flex flex-row gap-4'}>
        <RingilInputText placeholder={'recipientEmail'} value={filter?.recipientEmail} onChange={v => applyFilterValue('recipientEmail', v)} />
        <RingilInputText placeholder={'subject'} value={filter?.subject} onChange={v => applyFilterValue('subject', v)} />
      </div>
      <div className={'flex flex-row justify-between py-4'}>
        <COHeadline title={'In Progress Resolved Emails'} variant={'h2'} icon={<SvgRepeatIcon />} />
        {runningApi === true && <SvgLoader className={'animate-spin'} />}
      </div>

      <div className={'flex flex-col gap-4'}>
        <Table value={data?.data}>
          <Column
            header={'recipientEmail'}
            body={r => (
              <div className={'flex flex-col gap-4'}>
                <div>{r.subject}</div>
                <div className={'text-s text-content-tertiary'}>{r.recipientEmail}</div>
              </div>
            )}
          />
          <Column header={'subject'} body={r => <></>} />
          <Column header={'retryCount'} body={r => <>{r.retryCount}</>} />
          <Column header={'emailCreatedAt'} body={r => <>{mapFromAPIToDateLong(r.emailCreatedAt)}</>} />
          <Column header={'emailLastRetryAt'} body={r => <>{mapFromAPIToDateLong(r.emailLastRetryAt)}</>} />
          <Column header={'lastException'} body={r => <>{r.lastException}</>} />
          <Column
            header={''}
            body={r => (
              <>
                <SvgIconFile onClick={() => setVisualiseEmailHtml(r.fullHtml)} />
              </>
            )}
          />
        </Table>
      </div>
      <Pagination
        pageSize={data?.size}
        selectedPage={data?.page}
        total={data?.total}
        onPageChange={pageNomber => {
          applyPaging({ pageNumber: pageNomber, pageSize: data?.size });
        }}
        onPageSizeChange={pageSize => {
          applyPaging({ pageNumber: 1, pageSize: pageSize });
        }}
      />
      <RingilDialog visible={isNotNullOrUndefined(visualiseEmailHtml)} onHide={() => setVisualiseEmailHtml(null)}>
        {visualiseEmailHtml && <div dangerouslySetInnerHTML={{ __html: visualiseEmailHtml }} style={{ border: '1px solid #ddd', padding: '10px', marginTop: '10px' }} />}
      </RingilDialog>
    </AdminLayout>
  );
};

export default withAuthenticationRequired(FindResolvedEmails);
