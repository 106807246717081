import { useContext, useEffect, useState } from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useDispatchers = (expeditionId: string) => {
  const [dispatchers, setDispatchers] = useState<LovDTO[]>([]);
  const { lovExpeditionDispatcher } = useContext(ApiContext); // LovDTO

  useEffect(() => {
    if (expeditionId) {
      lovExpeditionDispatcher(expeditionId, data => setDispatchers(data));
    }
  }, [expeditionId]);

  return dispatchers;
};

export default useDispatchers;
