import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tag/ButtonTag.tsx';
import InputPlate from '@components/obsolete/Form/InputPlate/InputPlate.tsx';
import SvgEtransportCarPlate from '@components/features/eTransport/icons/EtransportCarPlate.tsx';

interface ComponentProps {
  value: string; // comma separated values
  onChange: (value: string) => void;
  readonly?: boolean;
  errorMessage?: string;
}

const ETransportLicencePlateTag: React.FC<ComponentProps> = ({ value, onChange, readonly, errorMessage }) => {
  const [internalValue, setInternalValue] = useState(value);
  const { tr } = useTranslationLgs();

  return (
    <>
      <ButtonTag
        label={tr(`ETransportLicencePlate.licencePlate`, `Licence plate`)}
        icon={<SvgEtransportCarPlate />}
        required={true}
        value={value}
        readonly={readonly}
        errorMessage={errorMessage}
        onSave={onSuccess => {
          onSuccess();
          onChange(internalValue);
        }}>
        <>
          <InputPlate label={tr(`ETransportLicencePlate.carPlate`, `Car plate`)} value={internalValue} onChange={v => setInternalValue(v)} />
        </>
      </ButtonTag>
    </>
  );
};

export default ETransportLicencePlateTag;
