import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge.tsx';
import COText from '@app/pages/COText/COText.tsx';
import RingilForEntitySidebar from '@components/ringil3/Sidebar/RingilForEntitySidebar.tsx';
import { CompanyModuleEnum, EntityTypeEnum, GlobalTimeslotsDTO, LovCompanyLocationWithOpeningHours, OpeningEntityTypeEnum } from '@api/logsteo-api.v2.tsx';

import * as yup from 'yup';
import { Field } from '@components/framework/formfield/Field.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import InputCompanyLocation from '@components/obsolete/Form/InputEntityOwner/InputCompanyLocation.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import InputEntityOwner from '@components/obsolete/Form/InputEntityOwner/InputEntityOwner.tsx';

interface ComponentProps {
  supplierNotificationId: string;
  applicationId: string;
  onSaved: () => void;
  companyId: string;
}

interface Form {
  customerId: string;
  customerLocationId: string;
  unloadingGlobalTimeslots: GlobalTimeslotsDTO;
  customerLocation?: LovCompanyLocationWithOpeningHours;
}

export const useCreateEditDAPCustomerSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditDAPCustomerSidebar: React.FC<ComponentProps> = ({ supplierNotificationId, applicationId, companyId, onSaved }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [apiRequest, setApiRequest] = useState(false);
    const { detailDAPSupplierNotification, updateCustomerLocationDAPSupplierNotification } = useContext(ApiContext);

    const form = useForm<Form>(
      yup.object().shape({}),
      { customerLocation: null, customerLocationId: null, customerId: null, unloadingGlobalTimeslots: null },
      (d) => save(d),
      false,
      false
    );

    const save = (values: Form) => {
      updateCustomerLocationDAPSupplierNotification(
        supplierNotificationId,
        {
          companyId: form.values.customerId,
          locationId: form.values.customerLocationId,
          timeslots: form.values.unloadingGlobalTimeslots
        },
        () => {
          setVisible(false);
          onSaved();
        }
      );
    };

    useEffect(() => {
      const ac = new AbortController();

      detailDAPSupplierNotification(
        supplierNotificationId,
        (d) => {
          if (ac.signal.aborted) return;
          form.overwriteValues({
            customerLocation: null,
            customerLocationId: d.customerLocation.locationId,
            customerId: d.customerCompany.id,
            unloadingGlobalTimeslots: d.customerDeliverySlots
          });
        },
        null,
        { onValidationFailed: setBeValidationErrors }
      );
      return () => {
        ac.abort();
      };
    }, [supplierNotificationId]);

    return (
      <>
        <RingilForEntitySidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
          <RowWithSpaceBetween>
              <COText label={tr("EditDAPCustomerSidebar.vykladka", "Vykládka")} />
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map((t) => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}
          entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
          applicationId={applicationId}>
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <Field label={tr("EditDAPCustomerSidebar.prijemce", "Příjemce")} errorMessage={findValidationMessage(form.validationErrors, 'customerId', tr)} readOnly={true}>
              <InputEntityOwner
                readonly={true}
                companyId={companyId}
                onChange={(c) => form.setFieldValue('customerId', c)}
                moduleType={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                style={{ width: '100%' }}
                onChangeCompanyCode={(v) => {}} />

            </Field>
            <Field label={tr("EditDAPCustomerSidebar.nazevLokace", "Název lokace")} errorMessage={findValidationMessage(form.validationErrors, 'customerLocationId', tr)}>
              <InputCompanyLocation
                moduleName={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                companyId={form.values.customerId}
                companyLocationId={form.values.customerLocationId}
                onChange={(v) => {
                  form.setFieldValue(form.names.customerLocationId, v?.id);
                  form.setFieldValue(form.names.customerLocation, v);
                }}
                style={{ width: '100%' }} />

            </Field>
            <Field label={tr("EditDAPCustomerSidebar.datumDodani", "Datum dodání")} errorMessage={findValidationMessage(form.validationErrors, 'loadingGlobalTimeslots', tr)}>
              <ManageTimeslot
                externalLabel
                entityType={OpeningEntityTypeEnum.LOCATION}
                entityId={form.values.customerLocationId}
                onChange={(v) => {
                  form.setFieldValue(form.names.unloadingGlobalTimeslots, v);
                }}
                timeslot={form.values.unloadingGlobalTimeslots}
                noOpeningHours={false}
                validationErrors={form.validationErrors}
                validationPrefix={'loadingGlobalTimeslots.'} />

            </Field>
          </div>
        </RingilForEntitySidebar>
      </>);

  };

  return { EditDAPCustomerSidebar, visible, setVisible };
};