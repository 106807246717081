import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import Layout from '@app/layout/Layout.tsx';
import { useParams } from 'react-router-dom';
import ShipmentDetail from '@components/obsolete/shipment/ShipmentDetail.tsx';

interface ComponentProps {}

const ShipmentDetailPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const { shipmentId } = useParams();

  return (
    <>
      <Layout title={tr(`ShipmentDetailPage.title`, `Shipment detail`)}>
        {/*
        // @ts-ignore*/}
        <Card>
          <ShipmentDetail shipmentId={shipmentId} />
        </Card>
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(ShipmentDetailPage);
