import * as React from 'react';
import type { SVGProps } from 'react';
const SvgButtonTagAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g clipPath="url(#ButtonTagAdd_svg__a)">
      <path fill="#FE5000" d="M8.707 8.707H16V7.293H8.707V0H7.293v7.293H0v1.414h7.293V16h1.414z" />
    </g>
    <defs>
      <clipPath id="ButtonTagAdd_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgButtonTagAdd;
