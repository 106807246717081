import React from 'react';

interface ComponentProps {
  label: string;
  className?: string;
}

const COText: React.FC<ComponentProps> = ({ label, className }) => {
  return <div className={className}>{label}</div>;
};

export default COText;
