import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {
  title?: any;
  children?: any;
}

const InfoProvidedItem: React.FC<ComponentProps> = ({ title, children }) => {
  return (
    <div className="info-provided-item">
      <Label title={title} />
      <div className="info-provided-item__description">{children}</div>
    </div>
  );
};
export default InfoProvidedItem;
