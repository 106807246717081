import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import Badge from '@components/framework/badge/Badge.tsx';
import { isNotBlank } from '@utils/utils.tsx';

interface ComponentProps {
  value: string;
}

const UITBadge: React.FC<ComponentProps> = ({ value }) => {
  const {} = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  return <>{isNotBlank(value) && <Badge title={tr('UITBadge.label', 'UIT')} value={value} />}</>;
};

export default UITBadge;
