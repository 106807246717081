import React, { useContext } from 'react';
import LocationDetailItem from './LocationDetailsItem';
import { Location, PageID } from './types';
import LoadingComponent from './LoadingComponent';
import { UnloadOrderComponent } from './UnloadOrderComponent';
import { Button } from 'primereact/button';
import { isNullOrUndefined } from '@utils/utils';
import { ExpeditionTypeEnum } from '@api/logsteo-api.v2';
import ValidationDiv from '@utils/validation';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import LocationDetails from '@components/obsolete/common/LocationDetails.tsx';
import Alert from '@components/obsolete/Alert/Alert.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import { CarrierCodeSelector } from '@components/obsolete/new-expedition/CarrirerCodeSelector/CarrierCodeSelector.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  locations: Location[];
  unloadLocationId?: number;
  expeditionType: ExpeditionTypeEnum;
}

const NewExpeditionStep3Header: React.FC<ComponentProps> = ({ expeditionType, locations, unloadLocationId }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { getExpeditionTimeslots } = useContext(ApiContext);

  const { changeTimeslots, clickOnBreadCrumb, changeCarrierCode, proceedToOverview, validation, addOrder, state, applyTimeslotsForLocation } =
    useContext(NewExpeditionViewContext);

  const resolveTitle = (index: number, length: number) => {
    if (index === 0) return t('NewExpeditionStep3Manipulation.routeBegin', 'Začátek');
    if (index === length - 1) return t('NewExpeditionStep3Manipulation.routeEnd', 'Konec');
    return t('NewExpeditionStep3Manipulation.routeMiddle', 'Mezizastávka');
  };

  const copyPreviousSlots = (locationIndex: number) => {
    getExpeditionTimeslots(state.duplicatedFromExpeditionId, locationIndex, d => {
      applyTimeslotsForLocation(locationIndex, d);
    });
  };

  return (
    <>
      {locations.map((item, locationIndex, collection) => (
        <LocationDetails key={locationIndex}>
          <LocationDetailItem
            count={locationIndex + 1}
            company={item.name}
            city={item.city}
            locationDescription={resolveTitle(locationIndex, collection.length)}
            buttonTitle={t(`NewExpeditionStep3Manipulation.edit`, `Upravit`)}
          />
          <ValidationDiv errors={validation} path={`locations[${locationIndex}].timeslots`} />
          {state.duplicatedFromExpeditionId && (
            <Alert className="p-mb-4">
              <i>
                {tr(`TimeSlotsComponentInner.youCanSetSlotFromPrevious`, `Muzete zkopirovat sloty z predchozi expedice`)}
                <Button
                  className="p-ml-4 p-button-text p-p-0 p-text-italic"
                  label={tr('TimeslotWithDefaults.applyPreviousTimeslots', 'Kopiruj predchozi sloty')}
                  onClick={() => copyPreviousSlots(locationIndex)}
                />
              </i>
            </Alert>
          )}
          <SmallCol>
            <ManageTimeslot
              entityId={item.openingHoursCoordinated?.entityId}
              entityType={item.openingHoursCoordinated?.entityType}
              timeslot={item.timeslots}
              onChange={v => {
                changeTimeslots(locationIndex, v);
              }}
              noOpeningHours={isNullOrUndefined(item.locationId)}
              validationErrors={validation}
              validationPrefix={`locations[${locationIndex}].timeslots.`}
            />
          </SmallCol>

          {/*<TimeSlotComponent
            data={item.timeslots}
            onChange={(e) => changeTimeslots(locationIndex, e)}
            validation={validation}
            locationIndex={locationIndex}
          />*/}
          <CarrierCodeSelector carrierCode={item.carrierCode} setCarrierCode={e => changeCarrierCode(locationIndex, e)} />
          {locationIndex < collection.length - 1 && (
            <>
              <LoadingComponent collapsed={item.loadingLocation || false} orders={item.loadingOrders} locationIndex={locationIndex} locationName={item.name} />
            </>
          )}
          {locationIndex !== 0 && (
            <UnloadOrderComponent
              key={locationIndex}
              isLastLocation={locationIndex == collection.length - 1}
              collapsed={item.unloadingLocation || false}
              ltlOrders={locations
                .filter((item, index) => item.id < locationIndex)
                .map((item, index) => item.loadingOrders)
                .flat(1)
                .filter((item, index) => item.unloadingLocationId === locationIndex || item.unloadingLocationId === null)}
              locationId={locationIndex}
              unloadAll={item.unloadAll}
            />
          )}
          <ValidationDiv errors={validation} path={`locations[${locationIndex}].loadingLocation`} />
        </LocationDetails>
      ))}
      <ButtonLayout>
        <Button
          label={t('wayPage.form.buttonContinue')}
          className="p-button-success p-mr-2"
          onClick={e => {
            proceedToOverview();
          }}
        />
        <Button
          label={t('wayPage.form.buttonBack')}
          className="p-button-text"
          onClick={e => {
            clickOnBreadCrumb(PageID.STEP2);
          }}
        />
      </ButtonLayout>
    </>
  );
};

const SmallCol = styled.div`
  width: 50%;
`;

export default NewExpeditionStep3Header;
