import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { TransportationTypeEnum } from '@api/logsteo-api.v2';
import { Form } from '@hooks/useForm/useForm';
import { RingilH3, RowWithIcon } from '@components/styles.tsx';
import { isNullOrUndefined } from '@utils/utils';
import { InputText } from 'primereact/inputtext';
import { CargoType, CargoTypeComponent } from '@components/obsolete/Form/CargoType/CargoType.tsx';
import { ContainerInfo, ContainerInfoForm } from '@components/obsolete/ContainerInfo/ContainerInfo.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useButtonEnablers } from '@components/obsolete/Buttons/ButtonEnablers/ButtonEnablers.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { CustomsTypeComponent } from '@components/obsolete/CloComponent/CloComponent.tsx';
import { ClearButton } from '@components/obsolete/Buttons/ClearButton/ClearButton.tsx';

export interface ShipmentCargoInfoData {
  specialRequirementIds: string[];
  cargoType: CargoType;
  containerInfo: ContainerInfoForm;
  customsTypeCode: string;
  referralCode: string;
  shipmentId: string;
}

interface ComponentProps {
  form: Form<ShipmentCargoInfoData>;
  readonly companyId: string;
  inSidebar: boolean;
  readonly transportationTypeCode: TransportationTypeEnum;
}

export const supplierNotificationValidation = yup.object().shape({});

export enum TTEnums {
  CONTAINER_SPEC_CODE = 'CONTAINER_SPEC_CODE',
  REFERRAL_CODE = 'REFERRAL_CODE',
  SHIPMENT_CODE = 'SHIPMENT_CODE',
}

const ShipmentOrdersForm: React.FC<ComponentProps> = ({ form, companyId, transportationTypeCode, inSidebar }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const resolveLabel = () => {
    switch (transportationTypeCode) {
      case TransportationTypeEnum.GROUND:
        return tr('CreateShipment.cmrID', 'CMR ID');
      case TransportationTypeEnum.TRAIN:
        return tr('CreateShipment.blID', 'B/L ID');
      case TransportationTypeEnum.AIR:
        return tr('CreateShipment.awbID', 'AWB ID');
      case TransportationTypeEnum.BOAT:
        return tr('CreateShipment.blID', 'B/L ID');
    }
  };

  useEffect(() => {
    switch (transportationTypeCode) {
      case TransportationTypeEnum.GROUND:
        setClosability(TTEnums.CONTAINER_SPEC_CODE, true);
        break;
      case TransportationTypeEnum.TRAIN:
        showSection(TTEnums.CONTAINER_SPEC_CODE);
        showSection(TTEnums.SHIPMENT_CODE);
        setClosability(TTEnums.CONTAINER_SPEC_CODE, false);
        break;
      case TransportationTypeEnum.AIR:
        setClosability(TTEnums.CONTAINER_SPEC_CODE, true);
        break;
      case TransportationTypeEnum.BOAT:
        showSection(TTEnums.SHIPMENT_CODE);
        showSection(TTEnums.CONTAINER_SPEC_CODE);
        setClosability(TTEnums.CONTAINER_SPEC_CODE, false);
        break;
    }
  }, [transportationTypeCode]);

  const { ButtonEnablersComponent, expandedSections, setExpandedSections, hideSection, showSection, setClosability, isClosable } = useButtonEnablers<TTEnums>(
    [
      {
        sectionCode: TTEnums.REFERRAL_CODE,
        label: tr('CreateShipment.referralCode', 'Referral code'),
        expanded: true,
        closable: false,
      },
      {
        sectionCode: TTEnums.CONTAINER_SPEC_CODE,
        label: tr('CreateShipment.containerSpecification', 'Container specification'),
        expanded: false,
        closable: true,
      },
      {
        sectionCode: TTEnums.SHIPMENT_CODE,
        label: resolveLabel(),
        expanded: false,
        closable: true,
      },
    ],
    (section, visible) => {
      switch (section) {
        case TTEnums.CONTAINER_SPEC_CODE:
          form.setFieldValue('containerInfo', visible ? form.values.containerInfo : null);
          return;
        case TTEnums.SHIPMENT_CODE:
          form.setFieldValue('shipmentId', visible ? form.values.shipmentId : null);
          return;
      }
    },
  );

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      <RingilH3>{tr(`CreateShipment.cargoInfo`, `Cargo info`)}</RingilH3>
      {/*      <SpecialRequirementsSelector
        selectedIds={form.values.specialRequirementIds}
        onChange={(v) => form.setFieldValue('specialRequirementIds', v)}
      />*/}
      <CargoTypeComponent value={form.values.cargoType} onChange={v => form.setFieldValue('cargoType', v)} inSidebar={inSidebar} />
      <RR>
        <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 2} small={inSidebar ? 12 : 1}>
          <HorizontalFieldValue
            required={true}
            label={tr('CreateShipment.customsType', 'Customs type')}
            value={<CustomsTypeComponent value={form.values.customsTypeCode} onChange={v => form.setFieldValue('customsTypeCode', v)} />}
          />
        </RC>
      </RR>
      {expandedSections.find(t => t.sectionCode === TTEnums.SHIPMENT_CODE).expanded && (
        <RR>
          <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 2} small={inSidebar ? 12 : 12}>
            <HorizontalFieldValue
              label={resolveLabel()}
              required={true}
              value={
                <RowWithIcon>
                  <InputText value={form.values.shipmentId} onChange={v => form.setFieldValue('shipmentId', v.target.value)} style={{ width: '100%' }} />
                  {isClosable(TTEnums.SHIPMENT_CODE) && <ClearButton onClick={() => hideSection(TTEnums.SHIPMENT_CODE)} />}
                </RowWithIcon>
              }
            />
          </RC>
        </RR>
      )}
      {expandedSections.find(t => t.sectionCode === TTEnums.CONTAINER_SPEC_CODE).expanded && (
        <ContainerInfo
          containerInfo={form.values.containerInfo}
          onChange={v => form.setFieldValue('containerInfo', v)}
          onClear={() => hideSection(TTEnums.CONTAINER_SPEC_CODE)}
          clearable={isClosable(TTEnums.CONTAINER_SPEC_CODE)}
          inSidebar={true}
          validationErrors={form.validationErrors}
        />
      )}
      {expandedSections.find(t => t.sectionCode === TTEnums.REFERRAL_CODE).expanded && (
        <RR>
          <RC large={inSidebar ? 12 : 3} medium={inSidebar ? 12 : 6} small={inSidebar ? 12 : 12}>
            <HorizontalFieldValue
              label={tr('CreateShipment.referralCode', 'Referral code')}
              value={
                <RowWithIcon>
                  <InputText value={form.values.referralCode} onChange={v => form.setFieldValue('referralCode', v.target.value)} style={{ width: '100%' }} />
                  {isClosable(TTEnums.REFERRAL_CODE) && <ClearButton onClick={() => hideSection(TTEnums.REFERRAL_CODE)} />}
                </RowWithIcon>
              }
            />
          </RC>
        </RR>
      )}
      <ButtonEnablersComponent></ButtonEnablersComponent>
    </>
  );
};

export default ShipmentOrdersForm;
