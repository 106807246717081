import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Shipments from '@app/pages/carrier/shipments-list/Shipments.tsx';
import Layout from '@app/layout/Layout.tsx';

const PageShipments = () => {
  const { t } = useTranslation('common');
  return (
    <Layout title={t(`shipments.shipments`, `Shipments`)}>
      <Shipments />
    </Layout>
  );
};

export default withAuthenticationRequired(PageShipments);
