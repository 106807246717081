import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined } from '@utils/utils';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation';

interface ComponentProps {
  carrierNote?: string;
}

const CarrierNoteTag: React.FC<ComponentProps> = ({ carrierNote }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      {isNotNullOrUndefined(carrierNote) && (
        <ButtonTag label={tr(`CarrierNoteTag.title`, `Note for carrier`)} labelNote={carrierNote} icon={<SvgYellowExclamation />} setOpen={setOpen} open={open} />
      )}
    </>
  );
};

export default CarrierNoteTag;
