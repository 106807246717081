import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';
import { mapFromAPIToDateLong } from '@utils/date';

interface ComponentProps {
  updatedAt: string;
  updatedBy: string;
}

const UpdatedBadge: React.FC<ComponentProps> = ({ updatedAt, updatedBy }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(updatedAt) && (
        <Badge filled={false} value={`${updatedBy}, ${mapFromAPIToDateLong(updatedAt)}`} label={tr(`UpdatedBadge.title`, `Updated`)} highlightedValue={false}></Badge>
      )}
    </>
  );
};

export default UpdatedBadge;
