import { ActionTypeEnum, AssignedCarrierLocationOutDTO, ExpeditionStatusEnum, LastExpeditionStatusEnum } from '../api/logsteo-api.v2';
import { dateFormatDateTime, mapFromAPIDateTime } from './date';
import { isNullOrUndefined } from './utils';

// OBSOLETE
export const isExpeditionActive = (expeditionState: string) => {
  switch (expeditionState) {
    case ExpeditionStatusEnum.READY_TO_SHIPMENT:
      return true;
    case ExpeditionStatusEnum.ACTIVE:
      return true;
    case ExpeditionStatusEnum.WAITING_FOR_DOCS:
      return true;
    case ExpeditionStatusEnum.FINISHED:
      return true;
    default:
      return false;
  }
};

export const isExpeditionActiveByLast = (lastExpeditionState: LastExpeditionStatusEnum) => {
  switch (lastExpeditionState) {
    case LastExpeditionStatusEnum.READY_TO_SHIPMENT:
      return true;
    case LastExpeditionStatusEnum.ACTIVE:
      return true;
    case LastExpeditionStatusEnum.WAITING_FOR_DOCS:
      return true;
    case LastExpeditionStatusEnum.FINISHED:
      return true;
    default:
      return false;
  }
};

export const resolveLocationStopName = (index: number, locationCount: number, tFunc: any) => {
  if (index == 0) return tFunc('global.route.begin');
  if (index - 1 == locationCount) return tFunc('global.route.end');

  return tFunc('global.route.stop', { count: index + 1 });
};

/**
 * Used to show "Ceka se na nakladku/vykladku, Nalozeno v XXX, Vylozeno XXX
 * @param locationActionType
 * @param isCompletedLocation
 * @param completedAt
 * @param t
 */
export const resolveLoadingUnloadingStateText = (
  isCompletedLocation: boolean,
  completedAt: string,
  loadingActionIsFinished: boolean,
  loadingActionFinishedAt: string,
  unloadingActionIsFinished: boolean,
  unloadingActionFinishedAt: string,
  t: any,
): string => {
  if (isNullOrUndefined(loadingActionIsFinished) && isNullOrUndefined(unloadingActionIsFinished)) {
    // only confirm both
    if (isCompletedLocation) {
      return t('TabCarrierActive.bothFinished', {
        date: dateFormatDateTime(mapFromAPIDateTime(completedAt)),
      });
    } else {
      return t('TabCarrierActive.bothNotFinished');
    }
  }

  if (isNullOrUndefined(loadingActionIsFinished) && !isNullOrUndefined(unloadingActionIsFinished)) {
    // only unloading
    if (isCompletedLocation) {
      return t('TabCarrierActive.unloadingFinished', {
        date: dateFormatDateTime(mapFromAPIDateTime(unloadingActionFinishedAt)),
      });
    } else {
      return t('TabCarrierActive.unloadingNotFinished');
    }
  }

  if (!isNullOrUndefined(loadingActionIsFinished) && isNullOrUndefined(unloadingActionIsFinished)) {
    // only loading
    if (isCompletedLocation) {
      return t('TabCarrierActive.loadingFinished', {
        date: dateFormatDateTime(mapFromAPIDateTime(loadingActionFinishedAt)),
      });
    } else {
      return t('TabCarrierActive.loadingNotFinished');
    }
  }

  if (!isNullOrUndefined(loadingActionIsFinished) && !isNullOrUndefined(unloadingActionIsFinished)) {
    // loading and unloading
    if (isCompletedLocation) {
      return t('TabCarrierActive.loadingAndUnloadingFinished', {
        date: dateFormatDateTime(mapFromAPIDateTime(completedAt)),
      });
    } else {
      return t('TabCarrierActive.loadingUnloadingNotFinished');
    }
  }
};
