import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/framework/badge/Badge.tsx';
import { SupplierNotificationTypeEnum, TypeEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  snType: SupplierNotificationTypeEnum;
}

const SNTypeBadge: React.FC<ComponentProps> = ({ snType }) => {
  const { tr } = useTranslationLgs();

  const createValue = (snType: SupplierNotificationTypeEnum) => {
    return tr(`SNTypeBadge.${snType}`, `${snType}`);
  };

  return (
    <>
      <Badge filled={false} value={createValue(snType)} title={tr(`SNTypeBadge.title`, `SNType`)} highlightedValue={false}></Badge>
    </>
  );
};

export default SNTypeBadge;
