import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tag/ButtonTag.tsx';
import SvgETraReference from '@components/features/eTransport/tags/ETraReference.tsx';
import { InputText } from 'primereact/inputtext';

interface ComponentProps {
  value: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
  errorMessage?: string;
}

const ETransportReferenceNoTag: React.FC<ComponentProps> = ({ value, onChange, readonly, errorMessage }) => {
  const [internalValue, setInternalValue] = useState(value);
  const { tr } = useTranslationLgs();
  return (
    <>
      <ButtonTag
        label={tr("ETransportReferenceNoTag.transportReferenceNo", "Transport reference no.")}
        icon={<SvgETraReference />}
        required={true}
        value={value}
        onSave={(onSuccess) => {
          onChange(internalValue);
          onSuccess();
        }}
        errorMessage={errorMessage}
        readonly={readonly}>
        <InputText value={internalValue} onChange={(e) => setInternalValue(e.target.value)} />
      </ButtonTag>
    </>);

};

export default ETransportReferenceNoTag;