import { useContext, useEffect, useState } from 'react';
import { FindCarrierCarrierOrGroupDTO, LovDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useCustomerCarriersAndGroups = (): FindCarrierCarrierOrGroupDTO[] => {
  const [carrierAndGroups, setCarrierAndGroups] = useState<FindCarrierCarrierOrGroupDTO[]>([]);
  const { cuFindCarriersOrCarrierGroups } = useContext(ApiContext);

  useEffect(() => {
    cuFindCarriersOrCarrierGroups('', data => setCarrierAndGroups(data));
  }, []);

  return carrierAndGroups;
};

export default useCustomerCarriersAndGroups;
