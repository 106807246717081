import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ExpeditionDetail from '@components/ringil3/Features/Expedition/ExpeditionDetail';
import { useParams } from 'react-router-dom';
import Layout from '@app/layout/Layout.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {}

const ExpeditionTestPage: React.FC<ComponentProps> = () => {
  const { expeditionId } = useParams();
  const { tr } = useTranslationLgs();

  return (
    <>
      <Layout title={tr('ExpeditionTestPage.detailExpedice', 'Detail expedice')}>
        <ExpeditionDetail expeditionId={expeditionId} />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(ExpeditionTestPage);
