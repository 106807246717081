import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { PickList } from 'primereact/picklist';
import { LovDTO } from '@api/logsteo-api.v2';
import { dumpVars } from '@utils/utils.tsx';

interface ComponentProps {
  value: string[];
  onChange: (ids: string[]) => void;
}

const InputPickSecurityRole: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { lovSecurityRoles } = useContext(ApiContext);

  const [sourceSecurityRoles, setSourceSecurityRoles] = useState<LovDTO[]>();

  useEffect(() => {
    const ac = new AbortController();
    lovSecurityRoles(d => {
      if (!ac.signal.aborted) {
        setSourceSecurityRoles(d);
      }
    });
    return () => {
      ac.abort();
    };
  }, []);

  return (
    <>
      <PickList
        source={sourceSecurityRoles?.filter(p => !value.includes(p.id))}
        target={sourceSecurityRoles?.filter(p => value.includes(p.id))}
        onChange={v => {
          onChange(v.target.map((p: LovDTO) => p.id));
        }}
        sourceHeader="Available"
        targetHeader="Selected"
        itemTemplate={(item: LovDTO) => <div>{item.name}</div>}
        showSourceControls={false}
        showTargetControls={false}
        dataKey={'id'}
      />
    </>
  );
};

export default InputPickSecurityRole;
