import { Button } from 'primereact/button';
import { useContext } from 'react';
import { CreateTemplateHandlerContext } from '../CreateTemplateHandler';
import { PageID } from '../../new-expedition/types';
import ButtonWrapper from '@components/obsolete/Form/ButtonWrapper/ButtonWrapper.tsx';

interface ComponentProps {
  count: number;
  company?: string | number;
  city?: string | number;
  locationDescription?: string | number;
  buttonTitle?: string;
}

const LocationDetailItem: React.FC<ComponentProps> = ({ count, company, city, locationDescription, buttonTitle }) => {
  const { clickOnBreadCrumb } = useContext(CreateTemplateHandlerContext);
  return (
    <div className="location-details-item p-pb-4 p-mb-5 p-d-flex p-grid p-ai-center">
      <div className="p-d-flex  p-ai-center p-md-5 p-col-12">
        <div className="location-details-item__number p-mr-4">
          <span>{count}</span>
        </div>
        <div className="location-details-item__info">
          <strong>{company}</strong>
          <br />
          {city}
        </div>
      </div>
      <div className="location-details-item__route p-md-3 p-col-12">{locationDescription}</div>
      {buttonTitle && (
        <ButtonWrapper className="p-col-12 p-md-4  p-d-flex">
          <Button label={buttonTitle} className="p-button-outlined location-details-item__button" icon="pi pi-pencil" onClick={e => clickOnBreadCrumb(PageID.STEP2)} />
        </ButtonWrapper>
      )}
    </div>
  );
};
export default LocationDetailItem;
