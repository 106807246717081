import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from './Badge';
import { FiFile } from 'react-icons/fi';
import { mapFromAPIDateTime } from '@utils/date';

interface ComponentProps {
  updatedAt: string;
  updatedBy: string;
  updatedEmail: string;
}

const UpdatedBadge: React.FC<ComponentProps> = ({ updatedAt, updatedBy, updatedEmail }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <a href={`mailto:${updatedEmail}`}>
        <Badge
          text={tr('UpdatedBadge.updates', '{{fullName}} ({{dateWithTimeDash}})', {
            fullName: updatedBy,
            dateWithTimeDash: mapFromAPIDateTime(updatedAt).format('DD.MM.YYYY - HH:mm'),
          })}
          icon={<FiFile />}
        />
      </a>
    </>
  );
};

export default UpdatedBadge;
