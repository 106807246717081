import clsx from 'clsx';

interface ComponentProps {
  children?: any;
  label?: any;
  className?: any;
  wrapper?: string;
}

const ButtonLayout: React.FC<ComponentProps> = ({ children, label, wrapper }) => {
  const className = clsx({
    'p-mb-0': wrapper === 'WITHOUT_PADDING_BOTTOM',
    'p-mb-3': wrapper === 'WITHOUT_PADDING_BOTTOM_3',
    'p-mt-2': wrapper === 'WITH_PADDING_TOP_2',
    'p-mt-4': wrapper === 'WITH_PADDING_TOP_4',
    'p-mt-6': wrapper === 'WITH_PADDING_TOP_6',
    'p-mb-2 p-mt-4': wrapper === 'WITH_PADDING_BOTTOM_2_TOP_4',
    'button-body-table': wrapper === 'BUTTON_BODY_TABLE',
    'p-mt-4 p-mb-0': wrapper === 'WITH_PADDING_TOP_4_WITHOUT_PADDING_BOTTOM',
    'p-mt-3 p-mb-5': wrapper === 'WITH_PADDING_TOP_3_WITH_PADDING_5',
  });

  return (
    <div className={'button-layout ' + className} style={{ whiteSpace: 'nowrap' }}>
      {label && (
        <label className="p-d-block p-mb-2">
          <strong>{label}</strong>
        </label>
      )}
      <div className="button-row p-d-flex p-flex-wrap"> {children} </div>
    </div>
  );
};
export default ButtonLayout;
