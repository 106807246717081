import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import AdminLayout from '@app/layout/Layout.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Intro from '@app/layout/Intro.tsx';
import AreaVisitorList from '@app/pages/area-visitors/AreaVisitorList.tsx';

interface ComponentProps {}

const VisitorListPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <AdminLayout title={tr(`VisitorListPage.title`, `Návštevy`)} oneOfPermission={[]}>
        <AreaVisitorList />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(VisitorListPage, {
  onRedirecting: () => <Intro />,
});
