import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { LovDTO } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputResponsiblePersonPlain from '@components/obsolete/Form/InputResponsiblePerson/InputResponsiblePersonPlain.tsx';
import { InputSubscriberIdsPlain } from '@components/obsolete/Form/InputSubscriberPlain/InputSubscriberIdsPlain.tsx';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';
import InfoNote from '@components/obsolete/InfoNote/InfoNote.tsx';

interface ComponentProps {
  responsiblePersonId: string;
  subscribersIds: string[];
  notifyUsersDependsOnRole: boolean;
  onChangeResponsiblePerson: (responsiblePersonId: string) => void;
  onChangeUubscribersIds: (subscribersIds: string[]) => void;
  onChangeNotifyUsersDependsOnRole: (notifyUsersDependsOnRole: boolean) => void;
  notifyRole: string;
  disabled?: boolean;
}

const InputResponsiblePersonAndSubscribers: React.FC<ComponentProps> = ({
  responsiblePersonId,
  subscribersIds,
  notifyUsersDependsOnRole,
  onChangeResponsiblePerson,
  onChangeNotifyUsersDependsOnRole,
  onChangeUubscribersIds,
  notifyRole,
  disabled = false,
}) => {
  const { lovUsersWithSpecificRole } = useContext(ApiContext);
  const { tr } = useTranslationLgs();

  const [usersInRole, setUsersInRole] = useState<LovDTO[]>(undefined);

  useEffect(() => {
    lovUsersWithSpecificRole('', notifyRole, d => {
      setUsersInRole(d);
    });
  }, []);

  const forcedByRole = isNullOrUndefined(responsiblePersonId) && (isNullOrUndefined(subscribersIds) || subscribersIds.length === 0);

  useEffect(() => {
    if (forcedByRole) {
      onChangeNotifyUsersDependsOnRole(true);
    }
  }, [forcedByRole]);

  if (isNullOrUndefined(usersInRole)) return <></>;

  const allInCompany = (usersInRole.length === 0 || notifyUsersDependsOnRole === false) && isNullOrUndefined(responsiblePersonId) && subscribersIds.length === 0;

  return (
    <>
      <HorizontalFieldValue
        label={tr(`InputResponsiblePersonAndSubscribers.responsiblePerson`, `Responsible person`)}
        value={<InputResponsiblePersonPlain responsiblePersonId={responsiblePersonId} onChange={onChangeResponsiblePerson} disabled={disabled} />}
      />
      <HorizontalFieldValue
        label={tr(`InputResponsiblePersonAndSubscribers.notifyPersons`, `Notify persons`)}
        value={<InputSubscriberIdsPlain subscribersIds={subscribersIds} onChange={onChangeUubscribersIds} disabled={disabled} />}
      />
      <CheckboxWithLabel
        checkboxLabel={tr(
          `InputResponsiblePersonAndSubscribers.usersDependsOnRole`,
          `Notify users depends on role {{notifyRole}} ({{count}} users)`,
          { count: usersInRole.length, notifyRole: notifyRole },
          usersInRole.length,
        )}
        value={notifyUsersDependsOnRole}
        onChange={onChangeNotifyUsersDependsOnRole}
        disabled={forcedByRole || disabled}
      />
      {allInCompany && (
        <Wrapper>
          <InfoNote
            text={tr(`InputResponsiblePersonAndSubscribers.notificationWillBeSentToAllPersonsInCompany`, `Notification will be sent to all persons in company`)}
          />
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

export default InputResponsiblePersonAndSubscribers;
