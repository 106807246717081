import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgCarPlate from '@components/ringil3/icons/CarPlate';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';
import { InputText } from 'primereact/inputtext';
import { isNotNullOrUndefined } from '@utils/utils';
import SvgTagPlus from '@components/ringil3/icons/TagPlus';

interface ComponentProps {
  carPlate: string;
  onChange: (carPlate: string) => void;
}

const CarPlateTag: React.FC<ComponentProps> = ({ carPlate, onChange }) => {
  const { tr } = useTranslationLgs();
  const [carPlateInternal, setCarPlateInternal] = useState(carPlate);
  const [open, setOpen] = useState(false);

  const carPlatePane = () => {
    return (
      <>
        <InputText value={carPlateInternal} onChange={e => setCarPlateInternal(e.target.value)} />
      </>
    );
  };

  const save = () => {
    setOpen(false);
    onChange(carPlateInternal);
  };

  return (
    <>
      {isNotNullOrUndefined(carPlate) ? (
        <ButtonTag
          label={tr(`CarPlateTag.title`, `Car plate`)}
          labelNote={carPlate}
          icon={<SvgCarPlate />}
          actionPane={carPlatePane()}
          actionIcon={<SvgTagEdit />}
          onSave={() => save()}
          setOpen={setOpen}
          open={open}
        />
      ) : (
        <ButtonTag
          label={tr(`CarPlateTag.title`, `Car plate`)}
          labelNote={carPlate}
          actionPane={carPlatePane()}
          actionIcon={<SvgTagPlus />}
          onSave={() => save()}
          variant={'empty'}
          setOpen={setOpen}
          open={open}
        />
      )}
    </>
  );
};

export default CarPlateTag;
