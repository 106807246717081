import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { SupplierNotificationPreviewOutDTO } from '@api/logsteo-api.v2';
import { dumpVars, formatDistance, formatPieces, isNotNullOrUndefined } from '@utils/utils';
import { Dayjs } from 'dayjs';
import { mapFromAPIDateTime, mapFromAPIToDateLong } from '@utils/date';
import Tag from '@components/ringil3/Tags/Tag';
import ButtonTag from '@components/ringil3/Tags/ButtonTag';
import SvgCargotype from '@components/ringil3/icons/Cargotype';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';
import { Calendar } from 'primereact/calendar';
import InputDateAndTime from '@components/ringil3/Input/InputDateAndTime/InputDateAndTime';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';

interface ComponentProps {
  validTillISO8601: string;
  expeditionId: string;
  onChanged: () => void;
}

interface Form {
  validTillLocalDateTime: string;
  validTillTimeZone: string;
  price: string;
  currency: string;
}

const DistributionValidTillTag: React.FC<ComponentProps> = ({ validTillISO8601, expeditionId, onChanged }) => {
  const { tr } = useTranslationLgs();
  const { changeExpeditionDistributionDate } = useContext(ApiContext);

  const createLocalDateTime = (iso8601: string, timeZone: string) => {
    const localDateTime = mapFromAPIDateTime(iso8601).tz(timeZone).format(`YYYY-MM-DDTHH:mm`);
    const splitted = localDateTime.split(`T`);
    const localDate = splitted[0];
    const time = splitted[1];
    return { localDate, time, timeZone, localDateTime };
  };

  const { localDate, time, timeZone, localDateTime } = createLocalDateTime(validTillISO8601, 'Europe/Prague');
  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZone);
  const [selectedLocalDateTime, setSelectedLocalDateTime] = useState(localDateTime);
  const [open, setOpen] = useState(false);

  const save = () => {
    changeExpeditionDistributionDate(
      expeditionId,
      {
        timeZone: selectedTimeZone,
        localDateTime: selectedLocalDateTime,
      },
      () => {
        setOpen(false);
        onChanged();
      },
    );
  };
  const pane = (
    <>
      <HorizontalFieldValue
        label={tr(`DistributionValidTillTag.validTill`, `Valid till`)}
        value={
          <InputDateAndTime
            timeZone={selectedTimeZone}
            value={selectedLocalDateTime}
            onChange={v => {
              setSelectedLocalDateTime(v);
            }}
            onChangeTimeZone={v => {
              setSelectedTimeZone(v);
            }}
          />
        }
      />
    </>
  );
  return (
    <>
      {isNotNullOrUndefined(validTillISO8601) && (
        <>
          <ButtonTag
            label={tr(`DistributionValidTillTag.title`, `Valid till`)}
            labelNote={mapFromAPIToDateLong(validTillISO8601)}
            icon={<SvgCargotype />}
            actionPane={pane}
            actionIcon={<SvgTagEdit />}
            onSave={() => {
              save();
            }}
            setOpen={setOpen}
            open={open}
          />
        </>
      )}
    </>
  );
};

export default DistributionValidTillTag;
