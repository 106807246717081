import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import { CreateTemplateHandlerView, Location } from './types';
import {
  AddressBookTemplateOutDTO,
  CargoTemplateWithoutIdDTO,
  DistributionTypeEnum,
  ExpeditionTypeEnum,
  FindCarrierCarrierOrGroupDTO,
  LoadingTypesEnum,
  OpeningHourDefinitionsDTO,
  OpeningHourDefinitionsInDTO,
  UserLovOutDTO,
} from '@api/logsteo-api.v2';
import { createEmptyOrder, createEmptyOrderItem, PageID, RouteType } from '../new-expedition/types';
import { Draft } from 'immer';
import { isNullOrUndefined } from '@utils/utils';
import { ValidationError } from 'yup';
import { ApiContext } from '@api/api';
import { validate } from '@utils/validation';
import { stage3Validation } from './validation';

interface CreateTemplateHandlerContextType {
  state: CreateTemplateHandlerView;
  clickOnBreadCrumb: (page: PageID) => void;
  proceedToLoading: () => void;
  changeRouteType: (routeType: RouteType) => void;
  locationChanged: (locationIndex: number, fieldName: string, value: any) => void;
  removeLocation: (locationIndex: number) => void;
  addLocation: () => void;
  changeTemplateName: (name: string) => void;
  changeExpeditionType: (expeditionType: ExpeditionTypeEnum) => void;
  selectTruck: (truck: string) => void;
  selectManipulationTypes: (manipulationTypes: LoadingTypesEnum[]) => void;
  selectSpecialRequirement: (requirement: string[]) => void;
  selectCargoType: (cargoType: string) => void;
  changeOrderItemProperty: (locationIndex: number, orderIndex: number, orderItemIndex: number, propertyName: string, value: any) => void;
  changeCarrierNote: (note: string) => void;
  addOrder: (locationIndex: number) => void;
  toggleLoading: (locationIndex: number) => void;
  toggleUnloading: (locationIndex: number) => void;
  addLoadingUnit: (locationIndex: number, orderIndex: number) => void;
  unloadOrder: (orderId: string, unloadingLocationId: number, unload: boolean) => void;
  toggleUnloadAll: (locationIndex: number, checked: boolean) => void;
  deleteOrder: (orderInternalId: string) => void;
  changeOrderName: (locationIndex: number, orderIndex: number, orderName: string) => void;
  deleteLoadingUnit: (locationIndex: number, orderIndex: number, orderItemIndex: number) => void;
  changeDistributionType: (distributionType: DistributionTypeEnum) => void;
  changeDistributionPrice: (price: number) => void;
  changeDistributionSelectedItems: (items: FindCarrierCarrierOrGroupDTO[]) => void;
  toggleCreateDistribution: () => void;
  changeResponsiblePerson: (person: UserLovOutDTO) => void;
  changeSubscribers: (persons: UserLovOutDTO[]) => void;
  changeCarrierCode: (locationIndex: number, code: string) => void;
  changeSinceInDay: (locationId: number, dayOfWeek: string, index: number, value: string) => void;
  toggleEnableDay: (locationId: number, dayOfWeek: string) => void;
  changeTillInDay: (locationId: number, dayOfWeek: string, index: number, value: string) => void;
  addIntervalInDay: (locationId: number, dayOfWeek: string) => void;
  deleteIntervalInDay: (locationId: number, dayOfWeek: string, index: number) => void;
  changeDistributionIntervalHour: (value: string) => void;
  applyAddressBook: (locationIndex: number, partnerLocation: AddressBookTemplateOutDTO) => void;
  changeCargoItem: (locationIndex: number, orderIndex: number, orderItemIndex: number, value: CargoTemplateWithoutIdDTO) => void;
  changeCurrency: (currency: string) => void;
  setValidationErrorsToState: (validationErrors: ValidationError[]) => void;
  changeAutomaticPublishing: (automaticallyPublish: boolean) => void;
  changeInternalNote: (note: string) => void;
  changeOpeningHours: (locationIndex: number, openingHours: OpeningHourDefinitionsDTO | OpeningHourDefinitionsInDTO) => void;
}

export const CreateTemplateHandlerContext = createContext<CreateTemplateHandlerContextType>(undefined);

interface ComponentProps {
  initialData: CreateTemplateHandlerView;
}

const CreateTemplateHandlerProvider: React.FC<PropsWithChildren<ComponentProps>> = ({ initialData, children }) => {
  const [state, setState] = useImmer<CreateTemplateHandlerView>(initialData);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

  const recomputeUnloadAll = (draft: Draft<CreateTemplateHandlerView>): Draft<CreateTemplateHandlerView> => {
    const unloadingLocationId = draft.locations.findIndex(l => l.unloadAll);
    if (unloadingLocationId >= 0) {
      draft.locations
        .map(l => l.loadingOrders)
        .flat(1)
        .forEach(o => (o.unloadingLocationId = unloadingLocationId));
    }
    return draft;
  };

  const validateStep3 = (): boolean => {
    const yupValidations = validate(stage3Validation, state);
    const errors = [...yupValidations];
    setValidationErrors(errors);
    return errors.length === 0;
  };

  const createEmptyLocation = (locationIndex: number, loadingLocation: boolean = false, unloadingLocation: boolean = false): Location => {
    return {
      id: locationIndex,
      fromAddressBookId: null,
      name: null,
      loadingLocation,
      unloadingLocation,
      loadingOrders: [],
      country: 'CZE',
      unloadAll: false,
      enabledEmailNotificationForContactPerson: true,
    };
  };

  const methods: CreateTemplateHandlerContextType = {
    state,

    proceedToLoading: () => {
      setState(draft => {
        // check if the first loading location have order
        const hasOrder = draft.locations[0].loadingOrders?.length > 0;
        if (!hasOrder) {
          draft.locations[0].loadingOrders = [createEmptyOrder(0, draft.locations[0].name, 1)];
          if (isNullOrUndefined(draft.locations[0].timeslots)) draft.locations[0].timeslots = [];
        }
        // check if it is roundtrip
        if (draft.routeType === RouteType.ROUND_TRIP) {
          const fl = draft.locations[0];

          draft.locations.push({
            timeslots: fl.timeslots,
            fromAddressBookId: fl.fromAddressBookId,
            loadingOrders: [],
            unloadAll: false,
            name: fl.name,
            zipCode: fl.zipCode,
            contactEmail: fl.contactEmail,
            streetNr: fl.streetNr,
            country: fl.country,
            carrierCode: fl.carrierCode,
            id: draft.locations.length,
            contactPhone: fl.contactPhone,
            contactName: fl.contactName,
            city: fl.city,
            unloadingLocation: true,
            loadingLocation: false,
            enabledEmailNotificationForContactPerson: fl.enabledEmailNotificationForContactPerson,
            openingHourDefinitions: fl.openingHourDefinitions,
          });

          draft.routeType = RouteType.DIRECT;
        }
        recomputeUnloadAll(draft);
        draft.currentPage = PageID.STEP3;
      });
    },
    clickOnBreadCrumb: (page: PageID) => {
      setState(draft => {
        draft.currentPage = page;
      });
    },
    changeRouteType: (routeType: RouteType) => {
      setState(draft => {
        draft.routeType = routeType;
      });
    },
    locationChanged: (locationIndex: number, fieldName: string, value: any) => {
      setState(draft => {
        // @ts-ignore
        draft.locations[locationIndex][fieldName] = value;
      });
    },

    removeLocation: (locationIndex: number) => {
      /*      setState((draft) => {
              draft.locations = draft.locations.filter((_, index) => index !== locationIndex);
            });*/
      setState(draft => {
        // all order that are unloaded on the removing location are not unloaded
        draft.locations
          .map(l => l.loadingOrders)
          .flat(1)
          .forEach(lo => {
            if (lo.unloadingLocationId === locationIndex) lo.unloadingLocationId = null;
            if (lo.unloadingLocationId > locationIndex) lo.unloadingLocationId--;
          });
        // lower all unloading order behind the removed order
        // draft.locations.map(l=>l.loadingOrders).flat(1).filter(t=>t.unloadingLocationId >= )
        draft.locations = draft.locations.filter((_, index) => index !== locationIndex);
        draft.locations.filter(l => l.id > locationIndex).forEach(l => l.id--);
      });
    },

    addLocation: () => {
      /*      setState((draft) => {
              const previouslyLastLocationIndex = draft.locations.length - 1;
              draft.locations.splice(draft.locations.length - 1, 0, createEmptyLocation(previouslyLastLocationIndex));
              draft.locations[draft.locations.length - 1].id = draft.locations[draft.locations.length - 1].id + 1;
              draft.locations
                .map((item) => item.loadingOrders)
                .flat(1)
                .filter((order) => order.unloadingLocationId >= previouslyLastLocationIndex)
                .forEach((order) => {
                  order.unloadingLocationId = order.unloadingLocationId + 1;
                });
            });*/
      setState(draft => {
        if (state.routeType === RouteType.DIRECT) {
          const previouslyLastLocationIndex = draft.locations.length - 1;
          draft.locations.splice(draft.locations.length - 1, 0, createEmptyLocation(previouslyLastLocationIndex));
          draft.locations
            .map(item => item.loadingOrders)
            .flat(1)
            .filter(order => order.unloadingLocationId >= previouslyLastLocationIndex)
            .forEach(order => {
              order.unloadingLocationId = order.unloadingLocationId + 1;
            });
          draft.locations[draft.locations.length - 1].id++;
        } else {
          draft.locations.splice(draft.locations.length, 0, createEmptyLocation(draft.locations.length));
        }
      });
    },
    changeTemplateName: (name: string) => {
      setState(draft => {
        draft.templateName = name;
      });
    },
    changeExpeditionType: (expeditionType: ExpeditionTypeEnum) => {
      setState(draft => {
        draft.expeditionType = expeditionType;
      });
    },

    selectTruck: (truck: string) => {
      setState(draft => {
        draft.truckType = truck;
      });
    },

    selectManipulationTypes: (manipulationTypes: LoadingTypesEnum[]) => {
      setState(draft => {
        draft.manipulationTypes = manipulationTypes;
      });
    },

    selectSpecialRequirement: (requirement: string[]) => {
      setState(draft => {
        draft.specialRequirements = [...requirement];
      });
    },

    selectCargoType: (cargoType: string) => {
      setState(draft => {
        draft.cargoType = cargoType;
      });
    },

    changeOrderItemProperty: (locationIndex: number, orderIndex: number, orderItemIndex: number, propertyName: string, value: any) => {
      setState(draft => {
        // @ts-ignore
        draft.locations[locationIndex].loadingOrders[orderIndex].items[orderItemIndex][propertyName] = value;
      });
    },

    changeCarrierNote: (note: string) => {
      setState(draft => {
        draft.carrierNote = note;
      });
    },
    addOrder: (locationIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders.push(
          createEmptyOrder(locationIndex, draft.locations[locationIndex].name, draft.locations[locationIndex].loadingOrders.length + 1),
        );
        recomputeUnloadAll(draft);
      });
    },

    toggleLoading: (locationIndex: number) => {
      setState(draft => {
        const newState = !draft.locations[locationIndex].loadingLocation;
        draft.locations[locationIndex].loadingLocation = newState;
        if (newState) {
          // if there is no order in the loading map, then create first
          if (!(draft.locations[locationIndex].loadingOrders?.length > 0)) {
            draft.locations[locationIndex].loadingOrders = [createEmptyOrder(locationIndex, draft.locations[locationIndex].name, 1)];
          }
        } else {
          // delete all
          draft.locations[locationIndex].loadingOrders = [];
        }
      });
    },

    addLoadingUnit: (locationIndex: number, orderIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders[orderIndex].items.push(createEmptyOrderItem());
      });
    },

    toggleUnloading: (locationIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].unloadingLocation = !draft.locations[locationIndex].unloadingLocation;
      });
    },

    unloadOrder: (orderId: string, unloadingLocationId: number, unload: boolean) => {
      setState(draft => {
        // cancel unload for all locations
        draft.locations.filter((location, index, obj) => location.unloadAll).forEach(loc => (loc.unloadAll = false));
        const unloadedOrder = draft.locations
          .map((loc, index) => loc.loadingOrders)
          .flat(1)
          .find(order => order.internalId === orderId);
        unloadedOrder.unloadingLocationId = unload ? unloadingLocationId : null;
      });
    },

    toggleUnloadAll: (locationIndex: number, checked: boolean) => {
      setState(draft => {
        draft.locations.filter((location, index, obj) => location.unloadAll).forEach(loc => (loc.unloadAll = false));

        draft.locations[locationIndex].unloadAll = checked;

        if (checked) {
          draft.locations
            .map(l => l.loadingOrders)
            .flat(1)
            .forEach(order => (order.unloadingLocationId = locationIndex));
        }
      });
    },
    changeCarrierCode: (locationIndex: number, code: string) => {
      setState(draft => {
        draft.locations[locationIndex].carrierCode = code;
      });
    },

    deleteOrder: (orderInternalId: string) => {
      setState(draft => {
        const order = draft.locations
          .map(l => l.loadingOrders)
          .flat(1)
          .find(o => o.internalId === orderInternalId);
        draft.locations[order.loadingLocationId].loadingOrders = draft.locations[order.loadingLocationId].loadingOrders.filter(o => o.internalId !== orderInternalId);
      });
    },
    changeOrderName: (locationIndex: number, orderIndex: number, orderName: string) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders[orderIndex].name = orderName;
      });
    },
    deleteLoadingUnit: (locationIndex: number, orderIndex: number, orderItemIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders[orderIndex].items = draft.locations[locationIndex].loadingOrders[orderIndex].items.filter(
          (orderItem, index) => index !== orderItemIndex,
        );
      });
    },
    toggleCreateDistribution: () => {
      setState(draft => {
        if (isNullOrUndefined(draft.distribution)) draft.distribution = { enabled: false, isPublished: true };
        draft.distribution.enabled = !draft.distribution.enabled;
        if (draft.distribution.enabled) {
          if (isNullOrUndefined(draft.distribution.intervalHour)) {
            draft.distribution.intervalHour = 8;
          }
          if (isNullOrUndefined(draft.distribution.selectedItems)) draft.distribution.selectedItems = [];
          if (isNullOrUndefined(draft.distribution.distributionType)) draft.distribution.distributionType = DistributionTypeEnum.SPOT;
        }
      });
    },
    changeDistributionType: (distributionType: DistributionTypeEnum) => {
      setState(draft => {
        draft.distribution.distributionType = distributionType;
      });
    },

    changeDistributionPrice: (price: number) => {
      setState(draft => {
        draft.distribution.price = price;
      });
    },
    changeDistributionSelectedItems: (items: FindCarrierCarrierOrGroupDTO[]) => {
      setState(draft => {
        draft.distribution.selectedItems = items;
      });
    },
    changeResponsiblePerson: (person: UserLovOutDTO) => {
      setState(draft => {
        draft.responsiblePerson = person;
      });
    },
    changeSubscribers: (persons: UserLovOutDTO[]) => {
      setState(draft => {
        draft.subscribers = persons;
      });
    },
    changeSinceInDay: (locationId: number, dayOfWeek: string, index: number, value: string) => {
      setState(draft => {
        const loc = draft.locations.find(l => l.id === locationId);
        const ts = loc.timeslots.find(t => t.dayOfWeek === dayOfWeek);
        ts.intervals[index].sinceTimeUtc = value;
      });
    },
    toggleEnableDay: (locationId: number, dayOfWeek: string) => {
      setState(draft => {
        const loc = draft.locations.find(l => l.id === locationId);
        let ts = loc.timeslots?.find(t => t.dayOfWeek === dayOfWeek);
        if (isNullOrUndefined(ts)) {
          ts = { dayOfWeek, intervals: [] };
          loc.timeslots = [...(loc.timeslots || []), ts];
        }

        if (ts.intervals.length === 0) {
          ts.intervals.push({ tillTimeUtc: null, sinceTimeUtc: null });
        } else {
          ts.intervals = [];
        }
      });
    },
    changeTillInDay: (locationId: number, dayOfWeek: string, index: number, value: string) => {
      setState(draft => {
        const loc = draft.locations.find(l => l.id === locationId);
        const ts = loc.timeslots.find(t => t.dayOfWeek === dayOfWeek);
        ts.intervals[index].tillTimeUtc = value;
      });
    },
    addIntervalInDay: (locationId: number, dayOfWeek: string) => {
      setState(draft => {
        const loc = draft.locations.find(l => l.id === locationId);
        const ts = loc.timeslots.find(t => t.dayOfWeek === dayOfWeek);
        ts.intervals.push({ tillTimeUtc: null, sinceTimeUtc: null });
      });
    },
    deleteIntervalInDay: (locationId: number, dayOfWeek: string, index: number) => {
      setState(draft => {
        const loc = draft.locations.find(l => l.id === locationId);
        const ts = loc.timeslots.find(t => t.dayOfWeek === dayOfWeek);
        ts.intervals = ts.intervals.filter((_, i) => i !== index);
      });
    },
    changeDistributionIntervalHour: (value: string) => {
      setState(draft => {
        const number = parseInt(value);
        draft.distribution.intervalHour = isNaN(number) ? draft.distribution.intervalHour : number;
      });
    },
    changeAutomaticPublishing: (automaticallyPublish: boolean) => {
      setState(draft => {
        draft.distribution.isPublished = automaticallyPublish;
      });
    },
    applyAddressBook: (locationIndex: number, partnerLocation: AddressBookTemplateOutDTO) => {
      setState(draft => {
        const location = draft.locations[locationIndex];

        location.fromAddressBookId = partnerLocation.id;
        location.country = partnerLocation.addressTemplate.country;
        location.city = partnerLocation.addressTemplate.city;
        location.streetNr = partnerLocation.addressTemplate.streetNr;
        location.zipCode = partnerLocation.addressTemplate.postalCode;
        location.contactEmail = partnerLocation.defaultContact.contactEmail;
        location.contactName = partnerLocation.defaultContact.contactName;
        location.contactPhone = partnerLocation.defaultContact.contactPhone;
        location.openingHourDefinitions = partnerLocation.openingHourDefinitions;
        /*location.timeslots = partnerLocation.timeslots.reduce((ret, item) => {
          const dayOfWeek = item.dayOfWeek;
          const currentDay = ret.find((t) => t.dayOfWeek === dayOfWeek);
          if (isNullOrUndefined(currentDay)) {
            return [...ret, { dayOfWeek, intervals: [{ sinceTimeUtc: item.since, tillTimeUtc: item.till }] }];
          } else {
            return [
              ...ret.filter((t) => t.dayOfWeek !== dayOfWeek),
              {
                dayOfWeek: dayOfWeek,
                intervals: [
                  ...currentDay.intervals,
                  {
                    sinceTimeUtc: item.since,
                    tillTimeUtc: item.till,
                  },
                ],
              },
            ];
          }
        }, [] as IntervalDefinitionView[]);*/
      });
    },
    changeCargoItem: (locationIndex: number, orderIndex: number, orderItemIndex: number, value: CargoTemplateWithoutIdDTO) => {
      setState(draft => {
        const orderItem = draft.locations[locationIndex].loadingOrders[orderIndex].items[orderItemIndex];

        orderItem.cargoItemType = value;
        /*orderItem.viewDisabledProperties = [];*/
        if (!isNullOrUndefined(value.length)) {
          /*orderItem.viewDisabledProperties.push('length');*/
          orderItem.length = value.length;
        }
        if (!isNullOrUndefined(value.height)) {
          /*orderItem.viewDisabledProperties.push('height');*/
          orderItem.height = value.height;
        }
        if (!isNullOrUndefined(value.width)) {
          /*orderItem.viewDisabledProperties.push('width');*/
          orderItem.width = value.width;
        }
      });
    },
    changeCurrency: (currency: string) => {
      setState(draft => {
        draft.distribution.currency = currency;
      });
    },
    setValidationErrorsToState: (validationErrors: ValidationError[]) => {
      setState(draft => {
        draft.validationErrors = validationErrors;
      });
    },
    changeInternalNote: (note: string) => {
      setState(draft => {
        draft.internalNote = note;
      });
    },
    changeOpeningHours: (locationIndex: number, openingHours: OpeningHourDefinitionsDTO | OpeningHourDefinitionsInDTO) => {
      setState(draft => {
        draft.locations[locationIndex].openingHourDefinitions = openingHours as OpeningHourDefinitionsDTO;
      });
    },
  };

  return <CreateTemplateHandlerContext.Provider value={methods}>{children}</CreateTemplateHandlerContext.Provider>;
};

export default CreateTemplateHandlerProvider;
