import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { Tooltip } from 'primereact/tooltip';
import { mapFromAPIToDateShort } from '@utils/date';
import { GroupDemandPreviewOutDTO } from '@api/logsteo-api.v2';
import useNavigation from '@hooks/useNavigation.tsx';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import { Link } from 'react-router-dom';
import { Clickable } from '@components/ringil3/styles.tsx';
import ProgressBar from '@components/obsolete/ProgressBar/ProgressBar.tsx';

interface ComponentProps {
  groupDemandCreated?: GroupDemandPreviewOutDTO;
  snId: string;
}

export const ProgressSNGD: React.FC<ComponentProps> = ({ groupDemandCreated, snId }) => {
  const { tr } = useTranslationLgs();
  const navigation = useNavigation();

  return (
    <>
      <>
        <RowWithSpaceBetween>
          {/*<Link href={`/multi-demands/${groupDemandCreated.groupDemandId}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createMultiDemanDetail(groupDemandCreated.groupDemandId))}>
            <Clickable>
              <div>{groupDemandCreated?.groupDemandName}</div>
            </Clickable>
          </Link>
          {isNotNullOrUndefined(groupDemandCreated.isValid) && (
            <>
              {groupDemandCreated.isValid ? (
                <>
                  <img id={`cal_${snId}`} src={'/images/icons/progressbar/calendar.svg'} />
                  {/*
                  // @ts-ignore*/}
                  <Tooltip target={`#cal_${snId}`}>
                    {tr(`SupplierNotificationList.validTillDate`, `Valid till {{date}}`, {
                      date: mapFromAPIToDateShort(groupDemandCreated.validTill),
                    })}
                  </Tooltip>
                </>
              ) : (
                <>
                  <img id={`cal_${snId}`} src={'/images/icons/progressbar/calendar_red.svg'} />
                  {/*
                  // @ts-ignore*/}
                  <Tooltip target={`#cal_${snId}`}>
                    {tr(`SupplierNotificationList.validTillDate`, `Valid till {{date}}`, {
                      date: mapFromAPIToDateShort(groupDemandCreated.validTill),
                    })}
                  </Tooltip>
                </>
              )}
            </>
          )}
        </RowWithSpaceBetween>
        {isNullOrUndefined(groupDemandCreated.assignedCarriersCount) ? (
          <>
            <ProgressBar value={0} color={'var(--ringil-warning-color)'} />
            <Small color={'var(--ringil-warning-color)'}>{tr(`SupplierNotificationList.carriersAreNotAssigned`, `Carriers are not assigned`)}</Small>
          </>
        ) : (
          <>
            <ProgressBar value={groupDemandCreated.repliedCarriersCount / groupDemandCreated.assignedCarriersCount} />
            <Small color={'var(--ringil-processing-color)'}>
              <RowWithSpaceBetween>
                <div>
                  {tr(
                    `SupplierNotificationList.fromCountCarriers`,
                    `from {{count}} carriers`,
                    { count: groupDemandCreated.repliedCarriersCount },
                    groupDemandCreated.repliedCarriersCount,
                  )}
                </div>
                <div>
                  {tr(
                    `SupplierNotificationList.fromAllCarrier`,
                    `from {{count}}`,
                    { count: groupDemandCreated.assignedCarriersCount },
                    groupDemandCreated.assignedCarriersCount,
                  )}
                </div>
              </RowWithSpaceBetween>
            </Small>
          </>
        )}
      </>
    </>
  );
};

const Small = styled.div`
  font-size: 0.75rem;
  color: ${p => p.color};
`;
