import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { EntityTypeEnum, MessageOutV3DTO } from '@api/logsteo-api.v2';
import { getTime } from '@utils/utils';
import { dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { ApiContext } from '@api/api';
import ChatMessage from '@components/obsolete/Chat/ChatMessage.tsx';

interface ComponentProps {
  entityId: string;
  entityType: EntityTypeEnum;
  companyId: string;
  readOnly?: boolean;
}

const ThreadPanel: React.FC<ComponentProps> = ({ companyId, entityId, entityType, readOnly = false }) => {
  const { tr } = useTranslationLgs();
  const { caGetMessagesV2, caSendMessageV2 } = useContext(ApiContext);
  const [state, setState] = useState<{ [key: string]: MessageOutV3DTO[] }>(undefined);
  const [conversation, setConversation] = useState<string>();
  const [newMessage, setNewMessage] = useState('');
  const [isAtBottom, setIsAtBottom] = useState(true);

  const sendMessage = () => {
    caSendMessageV2(companyId, entityId, entityType, { message: newMessage }, data => {
      setNewMessage('');
      const groupedByDay = groupByDate(data.messages);
      setState(groupedByDay);
    });
  };

  useEffect(() => {
    if (isAtBottom) document.querySelector('.messageBuble:last-child')?.scrollIntoView();
  }, [state]);

  const groupByDate = (messages: MessageOutV3DTO[]) =>
    messages.reduce((r, a) => {
      r[dateFormatOnlyDateShort(mapFromAPIDateTime(a.created))] = [...(r[dateFormatOnlyDateShort(mapFromAPIDateTime(a.created))] || []), a];
      return r;
    }, {} as { [key: string]: MessageOutV3DTO[] });

  const scrollableRef = useRef(undefined);

  useEffect(() => {
    const refreshThread = () => {
      const scrollableWrapper = scrollableRef.current;
      if (scrollableWrapper) {
        const isAtBottom = scrollableWrapper.scrollHeight - scrollableWrapper.scrollTop === scrollableWrapper.clientHeight;
        setIsAtBottom(isAtBottom);
      }
      caGetMessagesV2(companyId, entityId, entityType, data => {
        const groupedByDay = groupByDate(data.messages);
        setState(groupedByDay);
        setConversation(data.conversationName);
      });
    };

    refreshThread();
    const timer = setTimeout(refreshThread, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {state && (
        <>
          <ConversationTo>
            {tr(`ThreadPanel.conversationTo`, 'Do: {{conversation}}', {
              conversation,
            })}
          </ConversationTo>
          <ChatWrapper>
            <ChatMessagesWrapper ref={scrollableRef}>
              {Object.keys(state).map((key, keyIndex) => {
                return (
                  <React.Fragment key={keyIndex}>
                    <div className="message-service-date">
                      <small>{key}</small>
                    </div>
                    {state[key].map((message, messageIndex) => {
                      return (
                        <ChatMessage
                          key={messageIndex}
                          messageId={message.id}
                          otherSide={message.isOtherSide}
                          myMessage={message.isMine}
                          time={getTime(mapFromAPIDateTime(message.created))}
                          author={message.fullName}
                          text={
                            message.isEmail
                              ? `${message.emailDetail.wholeEmailHtml ? message.emailDetail.wholeEmailHtml : message.emailDetail.wholeEmail}`
                              : `${message.message}`
                          }
                          icon={message.isEmail ? 'pi pi-envelope' : undefined}
                          emailDetail={message.emailDetail}
                        />
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </ChatMessagesWrapper>

            <NewMessageInput>
              <MessageInput>
                <InputTextarea
                  autoResize
                  value={newMessage}
                  placeholder={tr(`CustomerChatComponent.writeAMessage`, `Write a message`)}
                  onChange={e => {
                    setNewMessage(e.target.value);
                  }}
                  disabled={readOnly}
                />
              </MessageInput>
              <SendButton>
                <Button onClick={e => sendMessage()} disabled={newMessage.length === 0 || readOnly}>
                  {tr(`ThreadPanel.send`, `Odeslat`)}
                </Button>
              </SendButton>
            </NewMessageInput>
          </ChatWrapper>
        </>
      )}
    </>
  );
};

const ChatMessagesWrapper = styled.div`
  overflow-y: auto;
`;

const ChatWrapper = styled.div`
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Note = styled.div``;

const SendButton = styled.div`
  button {
    word-break: keep-all;
  }
`;

const MessageInput = styled.div`
  width: 100%;
  flex-grow: 3;
  margin-right: 15px;

  textarea {
    width: 100%;
    height: 100%;
  }
`;

const NewMessageInput = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: row;
  align-items: center;
`;

const ConversationTo = styled.div`
  padding: 15px;
  background: rgba(222, 226, 230, 0.4);
  margin: 0 -15px;
`;

export default ThreadPanel;
