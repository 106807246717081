import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage';
import SvgNoteInfo from '@components/ringil3/icons/NoteInfo';
import { ColWithGap } from '@components/styles.tsx';

interface ComponentProps {
  isDelayedSend: boolean;
  onChange: (isDelayedSend: boolean) => void;
}

const InputDelayedSend: React.FC<ComponentProps> = ({ isDelayedSend, onChange }) => {
  const { tr } = useTranslationLgs();

  return (
    <ColWithGap>
      <InputSelect
        model={[
          {
            label: tr('InputDelayedSend.immediately', 'Immediately'),
            code: 'true',
          },
          { label: tr('InputDelayedSend.later', 'Later'), code: 'false' },
        ]}
        selectedItemCode={isDelayedSend ? 'true' : 'false'}
        onSelect={v => onChange(v === 'true' ? true : false)}
      />
      <COInlineMessage variant={'info'} icon={<SvgNoteInfo />}>
        <p>
          Po vytvoření přepravu dopravci neuvidí a nebude odeslán notifikační e-mail. Zveřejnit ji můžete manuálně kdykoliv později v detailu nebo ve výpisu všech
          přeprav.
        </p>
        <p>Upozornění: Pokud přepravu zveřejníte až po datu, kdy potřebujete cenovou nabídku, nebudou dopravci moci nabídnout cenu.</p>
      </COInlineMessage>
    </ColWithGap>
  );
};

export default InputDelayedSend;
