import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: number;
  onChange: (value: number) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const model = [
  { label: 'Alba', value: 1 },
  { label: 'Arad', value: 2 },
  { label: 'Argeș', value: 3 },
  { label: 'Bacău', value: 4 },
  { label: 'Bihor', value: 5 },
  { label: 'Bistrița-Năsăud', value: 6 },
  { label: 'Botoșani', value: 7 },
  { label: 'Brașov', value: 8 },
  { label: 'Brăila', value: 9 },
  { label: 'Buzău', value: 10 },
  { label: 'Caraș-Severin', value: 11 },
  { label: 'Cluj', value: 12 },
  { label: 'Constanța', value: 13 },
  { label: 'Covasna', value: 14 },
  { label: 'Dâmbovița', value: 15 },
  { label: 'Dolj', value: 16 },
  { label: 'Galați', value: 17 },
  { label: 'Gorj', value: 18 },
  { label: 'Harghita', value: 19 },
  { label: 'Hunedoara', value: 20 },
  { label: 'Ialomița', value: 21 },
  { label: 'Iași', value: 22 },
  { label: 'Ilfov', value: 23 },
  { label: 'Maramureș', value: 24 },
  { label: 'Mehedinți', value: 25 },
  { label: 'Mureș', value: 26 },
  { label: 'Neamț', value: 27 },
  { label: 'Olt', value: 28 },
  { label: 'Prahova', value: 29 },
  { label: 'Satu Mare', value: 30 },
  { label: 'Sălaj', value: 31 },
  { label: 'Sibiu', value: 32 },
  { label: 'Suceava', value: 33 },
  { label: 'Teleorman', value: 34 },
  { label: 'Timiș', value: 35 },
  { label: 'Tulcea', value: 36 },
  { label: 'Vaslui', value: 37 },
  { label: 'Vâlcea', value: 38 },
  { label: 'Vrancea', value: 39 },
  { label: 'București', value: 40 },
  { label: 'Călărași', value: 51 },
  { label: 'Giurgiu', value: 52 },
];

export const eTransportItemRegionLabel = (value: number) => {
  return model.find(item => item.value === value)?.label;
};

const ETransportInputRegion: React.FC<ComponentProps> = ({ value, onChange, fullWidth = false, disabled = false }) => {
  const { tr } = useTranslationLgs();

  const style = fullWidth ? { width: '100%' } : {};

  return (
    <>
      <Dropdown
        options={model}
        onChange={e => onChange(e.target.value)}
        value={value}
        optionLabel={'label'}
        optionValue={'value'}
        style={{ width: '100%' }}
        disabled={disabled}
      />
    </>
  );
};

export default ETransportInputRegion;
