import React from 'react';
import styled from 'styled-components';
import { InputNumber } from 'primereact/inputnumber';
import CurrencyDropdown from '@components/ringil3/Input/InputPriceWithCurrency/CurrencyDropdown';

interface ComponentProps {
  price: number;
  currency: string;
  disabled?: boolean;
  disabledCurrency?: boolean;
  changePrice: (price: number) => void;
  changeCurrency: (currencyCode: string) => void;
  code?: string;
}

const InputPriceWithCurrency: React.FC<ComponentProps> = ({ currency, price, changePrice, changeCurrency, code, disabled = false, disabledCurrency = false }) => {
  return (
    <>
      <div className={'flex flex-row'}>
        <InputNumber
          className="p-text-right"
          minFractionDigits={2}
          maxFractionDigits={2}
          value={price}
          onChange={e => changePrice(e.value)}
          mode="decimal"
          disabled={disabled}
          locale={'cs'}
          inputStyle={{ textAlign: 'right', borderRight: 'none' }}
        />
        <CurrencyDropdown selectedCurrenry={currency} onSelected={value => changeCurrency(value)} disabled={disabled || disabledCurrency} required={true} code={code} />
      </div>
    </>
  );
};

export default InputPriceWithCurrency;
